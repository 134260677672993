import React, { ReactNode } from 'react';

export type WdiPanelProps = {
  defaultCollapsed?: boolean;
  collapsed?: boolean;
  title?: ReactNode;
  extra?: ReactNode;
};

enum style {
  collapsed = "none",
  expanded = "block",
};

const WdiPanel: React.FC<WdiPanelProps> = (props) => {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(props.defaultCollapsed ? props.defaultCollapsed : false);
  return (
    <div className='wdi-panel'>
      <div
        className="ant-pro-card-header ant-pro-card-header-border ant-pro-card-header-collapsible"
        style={{ height: '40px', padding: '0px' }}
        onClick={() => {
          props.collapsed ? setIsCollapsed(!isCollapsed) : null;
        }}>
        <div className="ant-pro-card-title" style={{ width: '100%', fontSize: '15px' }}>
          <span>{props.title}</span>
          <div className='ant-btn-group ant-dropdown-button' style={{ float: 'right' }}>
            {props.extra}
          </div>
        </div>
      </div>
      <div className="ant-pro-card-col">
        <div className="ant-pro-card ant-pro-card-contain-card ant-pro-card-ghost">
          <div className="ant-pro-card-body">
            <div
              className="collapse-content"
              style={{ width: '100%', display: (isCollapsed ? style.collapsed : style.expanded) }}
              aria-expanded={isCollapsed}
            >
              <div style={{ marginTop: '10px' }}>
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export { WdiPanel }
