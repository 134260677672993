import { useEffect, useRef, useState } from 'react';
import { FieldProps, wdiCore, WdiModalAppProps } from '@/components/WdiControls';
import { ProFormInstance } from '@ant-design/pro-form';
import { EC } from '@/services/mdm/ec';
import { WdiSelect, WdiSelectFormat, WdiTreeSelect } from '@/components/WdiControls/WdiSelect';
import { Form, message } from 'antd';
import { HorzLayoutOpts, WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { responseIsSuccess, ResponseStatus } from '@/utils/request';
import { SoldTo } from '@/services/mdm/soldto';
import { Enum } from '@/enums/system';
import { FormSa } from '@/services/sales/sa';
import { CustSys } from '@/services/mdm/custsys';

const FormSaDetailPrevApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [soldToOpts, setSoldToOpts] = useState<any[]>();
  const [ecTreeData, setEcTreeData] = useState<any[]>();
  const [ecDict, setEcDict] = useState<any[]>();

  const { row, rowIndex, formSa } = props.data;

  const handleCustSysChange = (custSysCode: any) => {
    formRef.current?.setFieldsValue({ boId: null });
    SoldTo.search({
      custSysCode: custSysCode,
      isActive: true
    }).then(response => {
      if (responseIsSuccess(response)) {
        setSoldToOpts(response.data.map((item) => ({ value: item.soldToCode, label: item.codeOrName })));
        if (response.data.length === 1) {
          formRef.current?.setFieldsValue({ boId: response.data[0].soldToCode });
        }
      }
    });
  };

  const handleSoldToChange = (soldToCode: any) => {
    formRef.current?.setFieldsValue({ boId: null });

    EC.searchEc3({
      toParentLevel: Enum.ECLevel.EC2,
      bohqId: formSa.bohqId,
      pageTypeIds: String(formSa.pageTypeId),
      soldToCode: soldToCode,
      isActive: true
    }).then(response => {
      if (responseIsSuccess(response)) {
        const { treeData, dict } = FieldProps.TreeSelect.formatTreeData({
          textField: "ecName",
          valueField: "ecId",
          id: "ecId",
          pId: "parentId",
          level: "ecLevel",
          disabledLevel: [Enum.ECLevel.EC2],
        }, response.data);
        setEcTreeData(treeData);
        // @ts-ignore
        setEcDict(dict);
      }
    });
  };

  useEffect(() => {
    if (row) {
      if (row.ecId) {
        handleSoldToChange(row.soldToCode);
        formRef.current?.setFieldsValue({ ecId: row.ecId });
      }

      if (row.custSysCode) {
        formRef.current?.setFieldsValue({
          custSysCode: { value: row.custSysCode, label: wdiCore.String.Join(row.custSysCode, row.custSysName) }
        });
        handleCustSysChange(row.custSysCode);
        if (!formRef.current?.getFieldValue("soldToCode")) {
          formRef.current?.setFieldsValue({ soldToCode: row.soldToCode });
        }
      }

      if (row.soldToCode) {
        formRef.current?.setFieldsValue({
          soldToCode: { value: row.soldToCode, label: wdiCore.String.Join(row.soldToCode, row.soldToName) }
        });
      }
    }
  }, [row]);

  return (
    <WdiModalForm
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      modalProps={{
        onCancel: () => {
          props.onClose?.()
        },
      }}
      submitter={{ searchConfig: { submitText: '下一步', }, }}
      onFinish={async (values) => {
        const { productPolicy, ecId, custSysCode, soldToCode, ...entity } = values;

        entity.ecId = WdiSelectFormat.getvalue(ecId);
        entity.custSysCode = WdiSelectFormat.getvalue(custSysCode);
        entity.soldToCode = WdiSelectFormat.getvalue(soldToCode);
       
        const response = await FormSa.Buttons.validatePicker({
          rowIndex: rowIndex,
          formSaDetail: entity,
          formSa: { ...formSa },
        });
        if (!responseIsSuccess(response)) {
          message.error(response.message);
        } else if (props.onSave) {
          const formSaDetail = Object.assign({}, row, {
            ecId: response.data.ecId,
            ecName: response.data.ecName,
            custSysCode: response.data.custSysCode,
            custSysName: response.data.custSysName,
            soldToCode: response.data.soldToCode,
            soldToName: response.data.soldToName
          });

          formSaDetail.revenueType = response.data.revenueType;
          formSaDetail.revenueTypeName = response.data.revenueTypeName;

          props.onSave({
            code: ResponseStatus.SUCCESS,
            data: Object.assign({}, { formSaDetail: formSaDetail }, props.data),
          });
        }
      }}
      {...HorzLayoutOpts}
    >

      <Form.Item name="custSysCode" label="客户集团" rules={[{ required: true, message: '客户集团不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择客户集团",
          showSearch: true,
          remote: true,
          onChange: handleCustSysChange,
          dataLoading: async (params) => {
            const response = await CustSys.ajaxSearch(Object.assign({
              withPermissions: true,
              isActive: true,
              bohqId: formSa.bohqId
            }, params));
            return response.data.map((item) => {
              return { value: item.custSysCode, label: item.codeOrName };
            });
          }
        }} />
      </Form.Item>
      <Form.Item name="soldToCode" label="售达方" rules={[{ required: true, message: '售达方不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择售达方",
          showSearch: true,
          onChange: handleSoldToChange,
          options: soldToOpts,
        }} />
      </Form.Item>
      <Form.Item name="ecId" label="费用项" rules={[{ required: true, message: '费用项不能为空!' }]}>
        <WdiTreeSelect {...{
          placeholder: "请选择费用项",
          treeData: ecTreeData,
          dict: ecDict
        }} />
      </Form.Item>
    </WdiModalForm>
  );
};
export default FormSaDetailPrevApp;
