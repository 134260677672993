import { FormLog } from "@/services/basic/logs";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import DataStore from "../GridWidget/DataStore";
import { WdiModalAppProps, wdiCore } from "../WdiControls";
import { WdiModal } from "../WdiControls/WdiModalForm";
import { WdiTable } from "../WdiControls/WdiTable";

export const FormLogViewApp: React.FC<WdiModalAppProps> = (props) => {
  const [dataSource, setDataSource] = useState<any[]>([]);

  const columns: ColumnsType<any> = [
    {
      title: '操作类型',
      dataIndex: 'optTypeName',
      width: 80,
      ellipsis: true
    },
    {
      title: '操作内容',
      dataIndex: 'message',
      width: 120,
      ellipsis: true
    },
    {
      title: '操作日期',
      dataIndex: 'optDate',
      width: 150,
      ellipsis: true,
      render: (value: any, record: any, index: number) => { return wdiCore.Date.MinuteToString(value) }
    },
    {
      title: '操作人',
      dataIndex: 'staffName',
      width: 100,
      ellipsis: true
    },
    {
      title: '目标源',
      dataIndex: 'destination',
      width: 80,
      ellipsis: true
    },
    {
      title: '状态',
      dataIndex: 'isSuccess',
      width: 80,
      ellipsis: true,
      render: (value: any, record: any, index: number) => { return value ? "成功" : "失败"; }
    },
    {
      title: '错误信息',
      dataIndex: 'errorInfo',
      ellipsis: true
    },
  ];

  useEffect(() => {
    if (props.id) {
      FormLog.queryByFormNo(String(props.id)).then(response => {
        setDataSource(DataStore.init(response.data));
      })
    }
  }, [props.id]);

  return (
    <WdiModal
      title={props.title}
      visible={props.visible}
      width={1000}
      bodyStyle={{ height: 600 }}
      maskClosable={false}
      destroyOnClose
      onCancel={() => { props.onClose?.() }}
      footer={[
        <Button key="back" onClick={() => { props.onClose?.() }}>
          取消
        </Button>,
      ]}
    >
      <WdiTable
        columns={columns}
        rowKey="uid"
        dataSource={dataSource}
        pagination={false}
      />
    </WdiModal>
  );
}