import {formatter, request} from '@/utils/request';

export namespace Shop {
  export const Path = {
    Search: '/mdmapi/shop/search',
    AjaxSearch: '/mdmapi/shop/ajaxSearch',
    Query: '/mdmapi/shop/query',
    Export: '/mdmapi/shop/export',
    Get: '/mdmapi/shop/get',
    GetByCode: '/mdmapi/shop/getByCode',
    ExportShop: '/mdmapi/shop/exportShop',
    getBySoldToCode: '/mdmapi/shop/getBySoldToCode',
  };

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Export, formatter(options));
  }

  export async function search(body: API.ShopFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Shop[]>>(Path.Search, formatter(options));
  }

  export async function getBySoldToCode(soldToCode: string) {
    const options = {
      method: 'Get',
      body: {
        soldToCode: soldToCode
      }
    };
    return request<API.ResponseResult<API.Shop[]>>(Path.getBySoldToCode, formatter(options));
  }

  export async function ajaxSearch(body: API.ShopFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Shop[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.Shop>>(Path.Get, formatter(options));
  }

  export async function getByCode(shopCode: string) {
    const options = {
      method: 'GET',
      body: {
        shopCode: shopCode
      }
    }
    return request<API.ResponseResult<API.Shop>>(Path.GetByCode, formatter(options));
  }

  export async function exportShop(body: API.ShopFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Shop>>(Path.ExportShop, formatter(options));
  }
}
;
