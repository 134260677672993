import React, { useState } from 'react';
import { Input, message } from 'antd';
import { useIntl } from 'umi';
import PageControl from '@/components/WdiBus/FormPage'
import { FormApi } from '@/services/sales';
import { ResponseStatus } from '@/utils/request';

const QuickLaunch: React.FC = () => {
  const [value, setValue] = useState<string>("");
  const intl = useIntl();

  const onSearch = (value: any) => {
    if (value) {
      FormApi.getByFormNo(value).then(formResponse => {
        if (formResponse.code == ResponseStatus.SUCCESS) {
          const form = formResponse?.data;
          if (form) {
            PageControl.approval({
              formTypeId: form.formTypeId,
              pageTypeId: form.pageTypeId,
              data: { formId: form.formId }
            });
            setValue("");
          }
        } else {
          message.error(formResponse.message)
        }
      })
    } else {
      message.error("搜索内容不能为空！")
    }
  };
  return (
    <Input.Search
      placeholder={
        intl.formatMessage({
          id: 'component.globalHeader.search',
          defaultMessage: '请输入完整的单据编号',
        })
      }
      onChange={(v) => setValue(v.target.value)}
      onSearch={onSearch}
      value={value}
      style={{ width: 200, marginTop: 8 }}
    />
  );
};

export default QuickLaunch;