import React, {useEffect, useRef} from "react";
import {WdiModalForm} from "@/components/WdiControls/WdiModalForm";
import {ResponseStatus} from "@/utils/request";
import {Form, Input, message} from "antd";
import {ProFormInstance} from "@ant-design/pro-form";
import {PoolPrepayReverse} from "@/services/sales/financial/poolprepayreverse";

const CancelApp: React.FC<any> = (props: any) => {
  const formRef = useRef<ProFormInstance>();
  useEffect(() => {
    if (props.id) {
      const formId = Number(props.id)
      formRef.current?.setFieldsValue({
        formId: formId,
      })
    }

  }, [props.id])
  return (
    <>
      <WdiModalForm
        formRef={formRef}
        labelCol={{span: 6}}
        wrapperCol={{span: 12}}
        labelAlign='left'
        layout="horizontal"
        title={props.title}
        visible={props.visible}
        modalProps={{
          onCancel: () => {
            props.onClose ? props.onClose() : null
          },
          destroyOnClose: true,
          maskClosable: false
        }}
        onFinish={async (values) => {
          const {staffId, ...entity} = values;

          const response = await PoolPrepayReverse.cancel(entity);
          if (response.code != ResponseStatus.SUCCESS) {
            message.error(response.message);
            return false;
          }
          message.success('作废成功!');
          if (props.onSave) {
            props.onSave();
          }
          return true;
        }}
      >
        <Form.Item name='formId' hidden={true}>
        </Form.Item>
        <Form.Item name="cancelReason" label="作废原因" rules={[{required: true, message: '作废原因不能为空!'}]}>
          <Input.TextArea style={{resize: 'both'}}/>
        </Form.Item>
      </WdiModalForm>
    </>
  )
}
export default CancelApp;
