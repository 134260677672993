import DataStore from "@/components/GridWidget/DataStore";
import { WdiCardTitle, WdiModalAppProps, wdiCore } from "@/components/WdiControls";
import { WdiModal } from "@/components/WdiControls/WdiModalForm";
import { WdiPanel } from "@/components/WdiControls/WdiPanel";
import { WdiTable } from "@/components/WdiControls/WdiTable";
import { Enum } from "@/enums/system";
import { FormSa } from "@/services/sales/sa";
import { Button, TableColumnsType } from "antd";
import { useEffect, useState } from "react";

const FormSaDetailCompareModalApp: React.FC<WdiModalAppProps> = (props) => {
  const [addData, setAddData] = useState<API.FormSaDetailCompare[]>([]);
  const [deleteData, setDeleteData] = useState<API.FormSaDetailCompare[]>([]);
  const [modifyData, setModifyData] = useState<API.FormSaDetailCompare[]>([]);
  const [loading, setLoading] = useState(false);
  const [columns1, setColumns1] = useState<TableColumnsType<API.FormSaDetailCompare>>([]);
  const [columns2, setColumns2] = useState<TableColumnsType<API.FormSaDetailCompare>>([]);

  const form = props.data?.form;

  const columnsGeneral1: TableColumnsType<API.FormSaDetailCompare> = [
    {
      title: 'No.',
      width: 50,
      dataIndex: 'rowNumber',
      render(text, record, index) { return `${index + 1}`; }
    },
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.soldToCode, record.soldToName)}</span>
      ),
    },
    {
      title: '客户集团',
      dataIndex: 'custSysCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.custSysCode, record.custSysName)}</span>
      ),
    },
    {
      title: '产品别',
      dataIndex: 'productBuyNames',
      ellipsis: true,
      width: 240,
    },
    {
      title: '费用项',
      dataIndex: 'ecName',
      width: 150,
      ellipsis: true,
    },
    {
      title: '分子平台',
      dataIndex: 'platformCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.platformCode, record.platformName)}</span>
      ),
    },
    {
      title: '省份',
      dataIndex: 'districtName',
      ellipsis: true,
      width: 200,      
    },
    {
      title: '申请金额',
      dataIndex: 'amount',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    }
  ];
  const columnsGeneral2: TableColumnsType<API.FormSaDetailCompare> = [
    {
      title: 'No.',
      width: 50,
      dataIndex: 'rowNumber',
      render(text, record, index) { return `${index + 1}`; }
    },
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.soldToCode, record.soldToName)}</span>
      ),
    },
    {
      title: '客户集团',
      dataIndex: 'custSysCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.custSysCode, record.custSysName)}</span>
      ),
    },
    {
      title: '产品别',
      dataIndex: 'productBuyNames',
      ellipsis: true,
      width: 240,
    },
    {
      title: '费用项',
      dataIndex: 'ecName',
      width: 150,
      ellipsis: true,
    },
    {
      title: '分子平台',
      dataIndex: 'platformCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.platformCode, record.platformName)}</span>
      ),
    },
    {
      title: '省份',
      dataIndex: 'districtName',
      ellipsis: true,
      width: 200,      
    },
    {
      title: '原申请金额',
      dataIndex: 'originalAmount',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
    {
      title: '新申请金额',
      dataIndex: 'amount',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    }
  ];
  const columnsDealer1: TableColumnsType<API.FormSaDetailCompare> = [
    {
      title: 'No.',
      width: 50,
      dataIndex: 'rowNumber',
      render(text, record, index) { return `${index + 1}`; }
    },
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.soldToCode, record.soldToName)}</span>
      ),
    },   
    {
      title: '产品别',
      dataIndex: 'productBuyNames',
      ellipsis: true,
      width: 240,
    },
    {
      title: '费用项',
      dataIndex: 'ecName',
      width: 150,
      ellipsis: true,
    },
    {
      title: '分子平台',
      dataIndex: 'platformCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.platformCode, record.platformName)}</span>
      ),
    },
    {
      title: '省份',
      dataIndex: 'districtName',
      ellipsis: true,
      width: 200,      
    },
    {
      title: '申请金额',
      dataIndex: 'amount',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    }
  ];
  const columnsDealer2: TableColumnsType<API.FormSaDetailCompare> = [
    {
      title: 'No.',
      width: 50,
      dataIndex: 'rowNumber',
      render(text, record, index) { return `${index + 1}`; }
    },
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.soldToCode, record.soldToName)}</span>
      ),
    },   
    {
      title: '产品别',
      dataIndex: 'productBuyNames',
      ellipsis: true,
      width: 240,
    },
    {
      title: '费用项',
      dataIndex: 'ecName',
      width: 150,
      ellipsis: true,
    },
    {
      title: '分子平台',
      dataIndex: 'platformCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.platformCode, record.platformName)}</span>
      ),
    },
    {
      title: '省份',
      dataIndex: 'districtName',
      ellipsis: true,
      width: 200,      
    },
    {
      title: '原申请金额',
      dataIndex: 'originalAmount',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
    {
      title: '新申请金额',
      dataIndex: 'amount',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    }
  ];

  useEffect(() => {
    if (form) {
      setLoading(true);
      FormSa.Buttons.getFormSaDetailCompareCollections({ formId: form.formId, relatedFormId: form.relatedFormId }).then((response) => {
        if (response && response.data) {
          setAddData(DataStore.init(response.data.add));
          setDeleteData(DataStore.init(response.data.delete));
          setModifyData(DataStore.init(response.data.modify));
          setLoading(false);
        }
      });

      if (form.pageTypeId == Enum.PageType.ACTIVITY_APPLICATION) {
        setColumns1(columnsGeneral1);
        setColumns2(columnsGeneral2);
      } 
      else if (form.pageTypeId == Enum.PageType.ACTIVITY_APPLICATION_DEALER) {
        setColumns1(columnsDealer1);
        setColumns2(columnsDealer2);
      } 
    }
  }, [form]);

  return (
    <WdiModal
      title={props.title}
      visible={props.visible}
      width={1350}
      onCancel={() => { props.onClose?.(); }}
      footer={[
        <Button key="back" onClick={() => { props.onClose?.(); }}>
          关闭
        </Button>
      ]}
    >
      <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='新增明细' />}>
        <WdiTable
          showPageRows={10}
          columns={columns1}
          dataSource={addData}
          pagination={{ showSizeChanger: false }}
          loading={loading}
          aggrColumns={["revenueSellinForecast", "originalAmount", "amount"]}
        />
      </WdiPanel>
      <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='删除明细' />}>
        <WdiTable
          showPageRows={10}
          columns={columns1}
          dataSource={deleteData}
          pagination={{ showSizeChanger: false }}
          loading={loading}
          aggrColumns={["revenueSellinForecast", "originalAmount", "amount"]}
        />
      </WdiPanel>
      <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='金额变更' />}>
        <WdiTable
          showPageRows={10}
          columns={columns2}
          dataSource={modifyData}
          pagination={{ showSizeChanger: false }}
          loading={loading}
          aggrColumns={["revenueSellinForecast", "originalAmount", "amount"]}
        />
      </WdiPanel>
    </WdiModal>
  );
};

export default FormSaDetailCompareModalApp;