import {formatter, request} from "@/utils/request";

export namespace FormSp {
  export const Path = {
    get: "/activityapi/formSp/getFormSp",
    getPreFormSp: "/activityapi/formSp/getPreFormSp",
    paymentPrev: "/activityapi/formSp/paymentPrev",
    ExistsFormSPByRebateInfo: "/activityapi/formSp/existsFormSPByRebateInfo",
    getFormSpForExpenseNonTpmId: "/activityapi/formSp/getFormSpForExpenseNonTpmId",
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormSp>>(Path.get, formatter(options));
  }

  export async function getFormSpForExpenseNonTpmId(expenseNonTpmId: string) {
    const options = {
      method: 'GET',
      body: {
        expenseNonTpmId: expenseNonTpmId
      },
    };
    return request<API.ResponseResult<any>>(Path.getFormSpForExpenseNonTpmId, formatter(options));
  }

  export async function paymentPrev(body: API.PaymentPrev) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.PaymentPrev>>(Path.paymentPrev, formatter(options));
  }

  export async function existsFormSPByRebateInfo(rebateInfoId: number) {
    const options = {
      method: 'GET',
      body: {
        id: rebateInfoId
      },
    };
    return request<API.ResponseResult<boolean>>(Path.ExistsFormSPByRebateInfo, formatter(options));
  }

  export async function getPreFormSp(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormSp>>(Path.getPreFormSp, formatter(options));
  }

  export namespace Buttons {
    export const Path = {
      ValidateDetail: '/activityapi/spButtons/validateDetail',
      Apply: '/activityapi/spButtons/apply',
      BatchApply: '/activityapi/spButtons/batchApply',
      Delete: '/activityapi/spButtons/delete',
      Get: '/activityapi/spButtons/get',
      BUDGET_INFOS: '/activityapi/spButtons/budgetInfos',
      WARNING_INFOS: '/activityapi/spButtons/warningInfos',
      COUNT_OF_WARNING: '/activityapi/spButtons/countOfWarning',
      DYNAMIC_INFOS: '/activityapi/spButtons/dynamicInfos',
      CompleteDelivery: '/activityapi/spButtons/completeDelivery',
      ModifyAmount: '/activityapi/spButtons/modifyAmount',
      getSfaResultUrl: '/activityapi/spButtons/getSfaResultUrl',
      ModifyPrepay: '/activityapi/spButtons/modifyPrepay',
    };

    export async function get(body: API.ButtonArgs) {
      const options = {
        method: 'POST',
        body: {
          ...body
        }
      };

      return request<API.ResponseResult<API.Button>>(Path.Get, formatter(options));
    };

    export async function modifyAmount(body: { pageTypeId: number, formSpDetail: any, }) {
      const options = {
        method: 'POST',
        body: {
          ...body
        }
      };
      return request<API.ResponseResult<any>>(Path.ModifyAmount, formatter(options));
    };

    export async function modifyPrepay(body: any) {
      const options = {
        method: 'POST',
        body: {
          ...body
        }
      };
      return request<API.ResponseResult<any>>(Path.ModifyPrepay, formatter(options));
    };

    export async function validateDetail(body: {
      rowIndex: number,
      formSp: any,
      formSpDetail: any,
      formSpDetails: any[],
    }) {
      const options = {
        method: 'POST',
        body: body
      };

      return request<API.ResponseResult<API.FormSpDetailApprovalView>>(Path.ValidateDetail, formatter(options));
    };

    export async function apply(body: {
      batchFormSpParam: any,
      formSpDetails: API.FormSpDetail[],
      forceSubmit: boolean
    }) {
      const options = {
        method: 'POST',
        body: {
          ...body
        },
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.Apply, formatter(options));
    };

    export async function batchApply(body: {
      batchFormSp: any,
      formSpDetails: API.FormSpDetail[],
      forceSubmit: boolean
    }) {
      const options = {
        method: 'POST',
        body: {
          ...body
        },
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.BatchApply, formatter(options));
    };

    export async function del(formId: number) {
      const options = {
        method: 'POST',
        body: {
          formId: formId
        },
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.Delete, formatter(options));
    };

    export async function budgetInfos(formId: number) {
      const options = {
        method: 'GET',
        body: {formId}
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.BUDGET_INFOS, formatter(options));
    };

    export async function warningInfos(formId: number) {
      const options = {
        method: 'GET',
        body: {
          formId: formId,
        },
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.WARNING_INFOS, formatter(options));
    }

    export async function countOfWarning(formId: number) {
      const options = {
        method: 'GET',
        body: {
          formId: formId,
        },
      };
      return request<API.ResponseResult<number>>(Path.COUNT_OF_WARNING, formatter(options));
    }

    export async function dynamicInfos(formId: number) {
      const options = {
        method: 'Get',
        body: {formId}
      };
      return request<API.DynamicInformation[]>(Path.DYNAMIC_INFOS, formatter(options));
    };

    export async function completeDelivery(body: API.ButtonArgs) {
      const options = {
        method: 'POST',
        body: body
      };

      return request<API.ResponseResult<any>>(Path.CompleteDelivery, formatter(options));
    };

    export async function getSfaResultUrl(formId: number, formSpDetailId: number) {
      const options = {
        method: 'GET',
        body: {formId, formSpDetailId}
      };
      return request<API.ResponseResult<string>>(Path.getSfaResultUrl, formatter(options));
    };
  }
}

export namespace FormSpDetail {
  const Path = {
    GetFormSpDetailsForSaApply: "/activityapi/formSp/getFormSpDetailsForSaApply",
    GetFormSpDetailsForSaApproval: "/activityapi/formSp/getFormSpDetailsForSaApproval",
    getsForFreeGoodsApply: "/activityapi/formSp/getFormSpDetailsForFreeGoodsApply",
    getsForFreeGoodsApproval: "/activityapi/formSp/getFormSpDetailsForFreeGoodsApproval",
    getsForRebate: "/activityapi/formSp/getFormSpDetailsForRiApply",
    getsForExpenseNonTpm: "/activityapi/formSp/getFormSpDetailsForEiApply",
    getSoldToCodes: "/activityapi/formSp/getSoldToCodes",
    exportFormSpDetailForPayment: "/activityapi/formSp/exportFormSpDetailForPayment",
  }

  export async function getFormSpDetailsForSaApply(body: API.FormSpDetailApplyParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.FormSpDetailApplyView[]>>(Path.GetFormSpDetailsForSaApply, formatter(options));
  }

  export async function getFormSpDetailsForSaApproval(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormSpDetailApprovalView[]>>(Path.GetFormSpDetailsForSaApproval, formatter(options));
  }

  export async function exportFormSpDetailForPayment(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormSpDetailApprovalView[]>>(Path.exportFormSpDetailForPayment, formatter(options));
  }

  export async function getsForFreeGoodsApply(body: API.FormSpDetailApplyParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.FormSpDetailApplyView[]>>(Path.getsForFreeGoodsApply, formatter(options));
  }

  export async function getsForFreeGoodsApproval(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormSpDetailApprovalView[]>>(Path.getsForFreeGoodsApproval, formatter(options));
  }

  export async function getsForRebate(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormSpDetailRebateView[]>>(Path.getsForRebate, formatter(options));
  }

  export async function getsForExpenseNonTpm(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormSpDetailRebateView[]>>(Path.getsForExpenseNonTpm, formatter(options));
  }

  export async function getSoldToCodes(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<string[]>>(Path.getSoldToCodes, formatter(options));
  }
}

export namespace FormGoods {
  export const Path = {
    getFormGoodsForApply: "/activityapi/formSp/getFormGoodsForApply"
  }

  export async function getForApply(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormGoodsVo[]>>(Path.getFormGoodsForApply, formatter(options));
  }
}

export namespace FormGoodsShipping {
  export const Path = {
    getFormGoodsShipping: "/activityapi/formSp/getFormGoodsShipping",
    getFormGoodsShippings: "/activityapi/formSa/getFormGoodsShippings"
  }

  export async function getFormGoodsShipping(formGoodsId: number) {
    const options = {
      method: 'GET',
      body: {
        formGoodsId: formGoodsId
      },
    };
    return request<API.ResponseResult<API.FormGoodsShippingView[]>>(Path.getFormGoodsShipping, formatter(options));
  }

  export async function getFormGoodsShippings(formSaDetailId: number) {
    const options = {
      method: 'GET',
      body: {
        formSaDetailId: formSaDetailId
      },
    };
    return request<API.ResponseResult<API.FormGoodsShippingView[]>>(Path.getFormGoodsShippings, formatter(options));
  }
}
