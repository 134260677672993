import { formatter, request } from "@/utils/request";

export namespace RevenueType {
  export const Path = {
    Search: '/mdmapi/revenueType/search',
    Get: '/mdmapi/revenueType/get',
    post: '/mdmapi/revenueType/post',
  }
  export async function search(body: API.RevenueTypeSearchParameter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.RevenueType[]>>(Path.Search, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.RevenueType>>(Path.Get, formatter(options));
  }

  export async function post(body: API.RevenueType) {
    const options = {
      method: 'POST',
      body: body,
    };
    return request<API.ResponseResult<any>>(Path.post, formatter(options));
  }
}