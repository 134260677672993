import React, {useEffect, useRef, useState} from 'react';
import {Badge, Col, Form, message, Row, Tabs} from 'antd';
import ProForm, {ProFormInstance} from '@ant-design/pro-form';
import {FileUploadUtility, WdiFileList} from '@/components/WdiControls/WdiFileUpload';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import {FormPageProps, WdiFormPageTitle} from '@/components/WdiBus/FormPage';
import {initApprovalPageContext, ViewEntityProps} from '../../components/data';
import {ResponseStatus} from '@/utils/request';
import DataStore from '@/components/GridWidget/DataStore';
import {DataOptions} from '@/services/basic/metadata';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {WdiCardTitle, WdiModalProps, WdiStaffCard, WdiTextArea} from '@/components/WdiControls';
import {Enum} from '@/enums/system';
import FormSaInfosApp from '@/pages/sales/sa/components/FormSaInfos';
import DynamicInformation from '../../components/DynamicInformation';
import PrePayDetail from "@/pages/sales/sp/components/PrePayDetail";
import {FormSs} from "@/services/sales/ss";
import {SoldToSettleDetailsForApprovalPanel} from "@/pages/sales/ss/SoldToSettleApply/components/details";
import {SoldToSsWarningApprovalApp} from "@/pages/sales/ss/components/SoldToSsWarningInformation";

const moreConfig: any = {
  successParams: [
    {
      url: "/basicapi/favorite/getButtons",
      keys: []
    }, {
      url: "/activityapi/ssButtons/get",
      keys: [
        "UndoButton",
        "ReeditButton",
        "CancelButton",
        "InterveneFlowButton",
        "ExportLockRemoveButton",
        "ModifyPrepayButton",
      ]
    }
  ],
  errorParams: [
    {
      url: "/activityapi/ssButtons/get",
      keys: [
        "RepairButton",
        "InterveneFlowButton"
      ]
    }
  ]
};

const approvalConfig: any = {
  params: {
    url: "/activityapi/ssButtons/get",
    keys: [
      "ApprovalButton"
    ]
  }
};

const SoldToSettleApprovalApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [entity, setEntity] = useState<ViewEntityProps>();
  const [warningCount, setWarningCount] = useState<number>(0);
  const [modalProp, setModalProp] = useState<WdiModalProps>({visible: false});

  const onDetailsChange = (data: any[]) => {
    setDataSource(data);
  };
  const onClose = () => {
    setModalProp({visible: false, title: "", id: null});
  }

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApprovalPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;

        setEntity(viewEntity);

        if (viewEntity.formSs) {
          formRef.current?.setFieldsValue({
            remark: viewEntity.formSs.remark,
            attachment: FileUploadUtility.parse(viewEntity.formSs.fileName, viewEntity.formSs.filePath),
          });
          FormSs.Buttons.countOfWarning(Number(viewEntity?.formId)).then(response => {
            setWarningCount(response.data);
          });
        }

        if (viewEntity.formSsDetails) {
          setDataSource(DataStore.init(viewEntity.formSsDetails));
        }
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = `活动核销单【${entity.form?.formNo}】`;
      DataOptions.search({typeGroup: 3, value: entity.form?.statusId}).then(response => {
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (response.data && response.data.length > 0) {
          if (entity.form?.statusId != Enum.FormStatus.SCRAP && entity.form?.exportStatus == Enum.ExportStatus.EXPORT_SUCCESS) {
            title += `，已导出`;
          } else {
            title += `，${response.data[0].name}`;
          }
          if (props.setPageTitle) props.setPageTitle(
            <WdiFormPageTitle
              title={title}
              onClose={props.onClose}
              callback={props.callback}
              form={entity?.form}
              useCaseId={Enum.UseCase.SP}
              moreConfig={moreConfig}
              approvalConfig={approvalConfig}/>
          );
        }
      });
    }
  }, [props.setPageTitle, entity]);

  return (
    <>
      <WdiStaffCard formId={entity?.form?.formId}/>
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab={<span>核销信息</span>} key="1">
          <ProForm
            formRef={formRef}
            submitter={{
              render: () => {
                return (<></>)
              },
            }}
          >
            <FormSaInfosApp formRef={formRef} defaultCollapsed={true} formSaId={entity?.formSs?.formSaId}/>
            <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.FORM_SP} title='活动核销单信息'/>}
                      collapsed={true}>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={16}>
                  <Form.Item name="remark" label="核销备注">
                    <WdiTextArea rows={4} readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="attachment"
                    label="核销附件"
                    valuePropName='fileList'
                    getValueFromEvent={(e: any) => {
                      return e.fileList || [];
                    }}
                  >
                    <WdiFileList/>
                  </Form.Item>
                </Col>
              </Row>
            </WdiPanel>
          </ProForm>
          <SoldToSettleDetailsForApprovalPanel
            dataSource={dataSource}
            data={{
              ecFieldConfigs: entity?.ecFieldConfigs,
              formSs: entity?.formSs,
            }}
            onChange={onDetailsChange}/>
        </TabPane>
        <TabPane
          tab={
            <>
              <span>警示信息</span>
              <Badge count={warningCount}
                     style={{
                       color: "black",
                       boxShadow: "none",
                       background: "none",
                       top: "-20px",
                       right: "-15px",
                       position: 'absolute'
                     }}>
              </Badge>
            </>
          }
          key="3"
        >
          <SoldToSsWarningApprovalApp formId={entity?.form?.formId}/>
        </TabPane>
        <TabPane tab={<span>动态信息</span>} key="4">
          <DynamicInformation formId={entity?.form?.formId} formTypeId={entity?.form?.formTypeId}
                              pageTypeId={entity?.form?.pageTypeId} useCaseId={Enum.UseCase.SS}/>
        </TabPane>
      </Tabs>
      {modalProp.visible &&
        <PrePayDetail
          {...modalProp}
          onClose={onClose}
        />}
    </>
  );
};
export default SoldToSettleApprovalApp;
