import {Key, useEffect, useState} from 'react';
import {wdiCore, WdiModalAppProps} from '@/components/WdiControls';
import {EC} from '@/services/mdm/ec';
import {RebateInfo} from '@/services/sales/rebateinfo';
import {WdiModal} from '@/components/WdiControls/WdiModalForm';
import {ButtonColumn, FilterColumn, GridFilterForm} from '@/components/GridWidget/GridFilterForm';
import {DatePicker, message} from 'antd';
import {ColumnsType, TablePaginationConfig} from 'antd/lib/table';
import {WdiSelect} from '@/components/WdiControls/WdiSelect';
import {SorterResult} from 'antd/lib/table/interface';
import {Enum} from '@/enums/system';
import moment from 'moment';
import {WdiTable} from '@/components/WdiControls/WdiTable';
import {ResponseStatus} from '@/utils/request';
import {toExcel} from '@/components/WdiControls/WdiExcel';
import {CustSys} from '@/services/mdm/custsys';
import {PageType} from '@/services/basic/pagetype';

const {RangePicker} = DatePicker;

type TableParams = {
  pagination: TablePaginationConfig;
  sorter?: SorterResult<any> | null | undefined;
  filterOptions: any,
}

const baseColumns = [
  {name: "rebateInfoId", displayName: "返利单编号*"},
  {name: "soldToCode", displayName: "售达方编号"},
  {name: "soldToName", displayName: "售达方名称"},
  {name: "custSysCode", displayName: "客户集团编号"},
  {name: "custSysName", displayName: "客户集团名称"},
  {name: "ecName", displayName: "费用项"},
  {name: "productLineCode", displayName: "产品别编号"},
  {name: "productLineName", displayName: "产品别名称"},
  {name: "speriod", displayName: "销售月份", type: Enum.FieldType.NUMBER, dataType: Enum.FieldDataType.INT},
  {name: "fperiod", displayName: "预提月份", type: Enum.FieldType.NUMBER, dataType: Enum.FieldDataType.INT},
  {name: "revSoldTo", displayName: "实际销售额"},
  {name: "rateFinal", displayName: "结案比例/单位费用"},
  {name: "amountSs", displayName: "结案金额"},
];

const RebateInfoSelectApp: React.FC<WdiModalAppProps> = (props) => {
  const {data} = props;


  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      defaultPageSize: 10,
      total: 0,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
    },
    sorter: null,
    filterOptions: null,
  });
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const buttonColumns: ButtonColumn[] = [
    {
      title: '导出',
      key: 'Export',
      doClick: (filterOptions: any) => {
        let columns: any[] = [];
        columns = baseColumns.concat([
          {name: "amount", displayName: "核销金额(不含税)*"},
          {name: "remark", displayName: "核销备注"},
        ]);
        const req: API.QueryForPageReq = {
          filterOptions: JSON.stringify(getFilterOptions(Object.assign({}, tableParams, {filterOptions}))),
          pagenum: 0,
          pagesize: 0
        };
        RebateInfo.queryReimbursementForSelect(req).then(response => {
          const sheet = {
            data: response?.data?.records,
            names: columns,
            sheetName: "RebateInfo"
          };
          toExcel({
            name: "RebateInfo_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
            sheetProps: [sheet]
          })
        });
      }
    },
  ]
  const filterColumns: FilterColumn[] = [
    {
      title: '单据类型',
      dataIndex: 'pageTypeId',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            mode: "multiple",
            allowClear: true,
            placeholder: "请选择单据类型",
            showSearch: true,
            dataLoading: async (params) => {
              const response = await PageType.search(Object.assign({
                pageTypeIds: [Enum.PageType.CONTRACT_REBATE, Enum.PageType.CONTRACT_REBATE_LEVEL_2].join()
              }, params));

              return response.data.map((item) => {
                return {value: item.pageTypeId, label: item.pageTypeName};
              });
            }
          }} />
        )
      },
    },
    {
      title: '客户集团',
      dataIndex: 'custSysCode',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            allowClear: true,
            placeholder: "请选择客户集团",
            showSearch: true,
            remote: true,
            dataLoading: async (params) => {
              const response = await CustSys.ajaxSearch(Object.assign({withPermissions: true}, params));
              return response.data.map((item) => {
                return {value: item.custSysCode, label: item.codeOrName};
              });
            }
          }} />
        )
      },
    },
    {
      title: '销售月份',
      dataIndex: 'speriod',
      span: 8,
      render: () => {
        return <RangePicker picker="month" style={{width: '100%'}} allowEmpty={[true, true]}/>
      }
    },
    {
      title: '费用项',
      dataIndex: 'ecId',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            mode: "multiple",
            allowClear: true,
            placeholder: "请选择费用项",
            showSearch: true,
            dataLoading: async (params) => {
              const response = await EC.searchEc3(Object.assign({
                pageTypeIds: [Enum.PageType.CONTRACT_REBATE, Enum.PageType.CONTRACT_REBATE_LEVEL_2].join()
              }, params));

              return response.data.map((item) => {
                return {value: item.ecId, label: item.ecName};
              });
            }
          }} />
        )
      },
    }
  ];
  const columns: ColumnsType<any> = [
    {
      title: '返利单编号',
      width: 80,
      dataIndex: 'rebateInfoId',
      ellipsis: true,
    },
    {
      title: '售达方',
      width: 120,
      dataIndex: 'soldToCode',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.String.CodeOrName(record.soldToCode, record.soldToName)
      ),
    },
    {
      title: '客户集团',
      width: 120,
      dataIndex: 'custSysCode',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.String.CodeOrName(record.custSysCode, record.custSysName)
      ),
    },
    {
      title: '费用项',
      width: 100,
      dataIndex: 'ecId',
      ellipsis: true,
      render: (_, record) => (
        record.ecName
      ),
    },
    {
      title: '产品别',
      width: 100,
      dataIndex: 'productLineId',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.String.CodeOrName(record.productLineCode, record.productLineName)
      ),
    },
    {
      title: '销售月份',
      width: 60,
      dataIndex: 'speriod',
      ellipsis: true,
    },
    {
      title: '实际销售额',
      dataIndex: 'revSoldTo',
      width: 80,
      ellipsis: true,
    },
    {
      title: '结案金额',
      width: 60,
      dataIndex: 'amountSs',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.Number.AmountToString(record.amountSs, 2)
      ),
    },
  ];

  useEffect(() => {
    fetchData(Object.assign({}, tableParams));
  }, []);

  const getFilterOptions = (params: TableParams) => {
    const filterOptions: any = {soldToCode: data.soldToCode};

    if (params.filterOptions) {
      const {speriod, ecId, custSysCode, pageTypeId} = params.filterOptions;
      if (!wdiCore.Object.IsNullOrUndefined(speriod)) {
        if (!wdiCore.Object.IsNullOrUndefined(speriod[0])) {
          filterOptions.startDate = moment(speriod[0]).format('YYYYMM');
        }
        if (!wdiCore.Object.IsNullOrUndefined(speriod[1])) {
          filterOptions.endDate = moment(speriod[1]).format('YYYYMM');
        }
      }
      if (!wdiCore.Object.IsNullOrUndefined(pageTypeId) && pageTypeId.length > 0) {
        filterOptions.pageTypeId = pageTypeId.join();
      }
      if (!wdiCore.Object.IsNullOrUndefined(ecId) && ecId.length > 0) {
        filterOptions.ecId = ecId.join();
      }
      if (!wdiCore.Object.IsNullOrUndefined(custSysCode)) {
        filterOptions.custSysCode = custSysCode;
      }
    }

    return filterOptions;
  }

  const fetchData = (params: TableParams) => {
    setLoading(true);
    const filterOptions = getFilterOptions(params);
    const req: API.QueryForPageReq = {
      filterOptions: JSON.stringify(filterOptions),
      pagenum: params.pagination.current ? (params.pagination.current - 1) : 0,
      pagesize: params.pagination.pageSize ? params.pagination.pageSize : 10,
    };
    RebateInfo.queryReimbursementForSelect(req).then(responseResult => {
      const data = responseResult.data;
      if (data) {
        setDataSource(data.records);
        setTableParams({
          pagination: {
            ...params.pagination,
            total: data.totalCount,
          },
          sorter: params.sorter,
          filterOptions: params.filterOptions
        });
        setLoading(false);
        setSelectedRowKeys([]);
      }
    });
  };
  const doSearch = (filterOptions: any) => {
    fetchData(Object.assign({}, tableParams, {filterOptions}));
  }

  const onSelectChange = (newSelectedRowKeys: Key[], newSelectedRows: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };
  return (
    <WdiModal
      title={props.title}
      visible={props.visible}
      width={1200}
      onCancel={() => {
        props.onClose ? props.onClose() : null
      }}
      onOk={() => {
        if (selectedRows.length == 0) {
          message.error('请至少选择一条数据');
        } else {
          props.onSave?.({
            code: ResponseStatus.SUCCESS,
            data: selectedRows
          })
        }
      }}
    >
      <GridFilterForm doSeach={doSearch} columns={filterColumns} buttonColumns={buttonColumns}/>
      <WdiTable
        showPageRows={10}
        rowSelection={{selectedRowKeys, onChange: onSelectChange}}
        columns={columns}
        size='small'
        dataSource={dataSource}
        pagination={{
          ...tableParams.pagination,
          onChange: (page, pageSize) => {
            fetchData(Object.assign({}, tableParams, {
              pagination: {
                ...tableParams.pagination,
                current: page,
                pageSize: pageSize
              }
            }))
          }
        }}
        loading={loading}
      />
    </WdiModal>
  );
};
export default RebateInfoSelectApp;
