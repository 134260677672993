import React from 'react';
import {ColumnsType} from 'antd/lib/table';
import PanelForWarningApp, {WarningInfoAppProps, WdiGridApp, WdiObjApp} from '@/components/WdiBus/PanelForWarning';

const HQBudgetAmount: Record<string, any> = {
  bohqId: (_: any, record: any) => {
    return record['bohqName'];
  },
  boId: (_: any, record: any) => {
    return record['boName'];
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  },
  amount: (_: any, record: any) => {
    if (record['amount'] > record['AmountRemain']) {
      return <span style={{color: '#ff0000'}}>{record['Amount']}</span>;
    }
    return record['amount'];
  },
  rowNumbers: (_: any, record: any) => {
    if (record['amount'] > record['amountRemain']) {
      return <span style={{color: '#ff0000'}}>{record['rowNumbers']}</span>;
    }
    return record['rowNumbers'];
  },
};

const renderWdiGridApp = (props: API.WdiGrid, index: number) => {
  const columns: ColumnsType<any> = [];
  props.wdiColumns.map((wdiColumn) => {
    if (!wdiColumn.isHidden) {
      const column = {
        title: wdiColumn.displayName,
        dataIndex: wdiColumn.name,
        width: wdiColumn.width,
        ellipsis: true,
        render: HQBudgetAmount[wdiColumn.name]
      };
      columns.push(column);
    }
  });
  return (
    <WdiGridApp wdiGrid={props} columns={columns} dataSource={props.items} isExport={false} exportColumns={[]}/>
  );
};

const renderWdiObjApp = (props: API.WdiObj, index: number) => {
  return (
    <WdiObjApp wdiObj={props} message={props.target}/>
  );
};

const BPWarningInfoApp: React.FC<WarningInfoAppProps> = (props) => {
  let status: 0 | 1 | 2 = 0;
  props.wdiPanelList?.items.map((item) => {
    if (item.status === 2) {
      status = 2;
    }
    if (status !== 2 && item.status === 1) {
      status = 1;
    }
  });
  return (
    <PanelForWarningApp
      title={props.title}
      visible={props.visible}
      status={status}
      onClose={props.onClose}
      onSave={props.onSave}
    >
      {props.wdiPanelList?.items.map((item, index) => {
        return (
          <>
            {item.type === 'WdiGrid' && renderWdiGridApp(item, index)}
            {item.type === 'WdiObj' && renderWdiObjApp(item, index)}
          </>
        );
      })}
    </PanelForWarningApp>
  );
}
export default BPWarningInfoApp;
