import React, { useEffect, useState } from 'react';
import { DetailGrid, Utility } from '@/components/GridWidget';
import { WdiCardTitle, wdiCore, WdiModalAppResult, WdiModalProps } from '@/components/WdiControls';
import { Button, Input, message } from 'antd';
import { initFieldDisplayFormater, TableColumnRanderFormats } from '@/components/WdiBus/TableColumnRanderFormats';
import { NsGrid } from '@/components/GridWidget/GridDefProps';
import DataStore from '@/components/GridWidget/DataStore';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { ImportModalApp } from '@/components/WdiControls/WdiImport';
import { ResponseStatus } from '@/utils/request';
import { Enum } from '@/enums/system';
import { GridApi } from '@/services/gridapi';
import { toExcel } from '@/components/WdiControls/WdiExcel';
import { Position } from '@/services/basic/position';
import FormDetailEditApp from "./detail-edit";
import FormSaDetailSelect from "@/pages/sales/sp/GeneralPaymentApply/components/FormSaDetailSelect";
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { WdiSelect, WdiSelectFormat } from '@/components/WdiControls/WdiSelect';
import { DataOptions } from '@/services/basic/metadata';
import { VatType } from '@/services/mdm/vattype';

const ModalTypeEnums = {
  None: 0,
  Prev: -1,
  Import: -2,
  //FIXED: 1,
  PRICE: 2,
  NO_PRICE: 1,
  SELLIN: 3,
  AUTO_CALC_FOR_ACTIVITY: 5,
};

export type DetailForApplyProps = {
  data?: any,
  dataSource: any[],
  onChange?: (dataSource: any) => void;
}
export const initApplyRowFieldConfigs = (record: any, ecFieldConfigs: API.EcFieldConfig[] | undefined) => {
  return ["rowNumber", "soldToCode", "custSysCode",
    "productBuyNames", "ecName", "paymentMethodName", "invoiceTypeName", "vatTypeName",
    "amountNotPaid", "amountExVat", "remark", "soldToMasterCode", "formSaNo", "activityName",
    "activityStartDate", "activityEndDate", "platformName", "districtName", "promotionDesc", "amountSa", "amountSs", "amountPaid"];
};


const GeneralPaymentDetailsForApplyPanel: React.FC<DetailForApplyProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [importModalProps, setImportModalProps] = useState<WdiModalProps>({ visible: false });
  const { data, dataSource, onChange } = props;
  const [modalProps, setModalProps] = useState<WdiModalProps>({ visible: false });
  const [exportModalProps, setExportModalProps] = useState<WdiModalProps>({ visible: false });
  const [vatTypeOptions, setVatTypeOptions] = useState<any[]>([]);
  const [invoiceTypeOptions, setInvoiceTypeOptions] = useState<any[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<any[]>([]);


  const buttonHidden = data.hidden;


  const initOptions = async (params?: any) => {
    const paymentResponse = await DataOptions.search({
      typeGroup: 5
    });
    setPaymentMethodOptions(paymentResponse.data.map((item) => {
      return { value: item.value, label: item.name };
    }));

    const invoiceTypeResponse = await DataOptions.search({
      typeGroup: 106
    });
    setInvoiceTypeOptions(invoiceTypeResponse.data.map((item) => {
      return { value: item.value, label: item.name };
    }));

    const vatTypeResponse = await VatType.search({});
    setVatTypeOptions(vatTypeResponse.data.map((item) => {
      return { value: item.vatTypeId, label: item.vatTypeName };
    }));
  }

  const onImportClose = () => {
    setImportModalProps({ visible: false, title: "", id: null });
  }
  const onModalClose = () => {
    setModalProps({ visible: false, title: "", type: ModalTypeEnums.None, id: null });
  };
  const onDetailClose = () => {
    setExportModalProps({ visible: false, title: '', id: null, data: null });
  };

  const onDetailSave = (result?: WdiModalAppResult) => {
    const rows = result?.data;
    let resultData: any[] = [];
    if ((rows.length + dataSource.length) > 2000) {
      message.error("明细数量过多请分开申请");
      return;
    }
    rows.forEach((item: any) => {
      if (dataSource.findIndex(row => row.formSaDetailId == item.formSaDetailId) < 0) {
        item.amountSa = item.amount;
        item.amountExVat = null;
        item.amountInVat = null;
        item.amount = null;
        item.remark = null;

        resultData.push(item);
      }
    });
    if (resultData.length > 0) {
      onChange?.(DataStore.addMulti(dataSource, resultData));
    }

    setExportModalProps({ visible: false, title: '', id: null, data: null });
  };

  const onEditSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      const row = result?.data;
      if (result?.data.rowIndex > -1) {
        const record = modalProps.data.row;
        const newItem = { ...row };
        newItem.uid = record.uid;
        const newDataSouce = DataStore.edit(dataSource, newItem);
        onChange?.(newDataSouce);
      } else {
        const newItem = { ...row };
        onChange?.(DataStore.add(dataSource, newItem));
      }
    }
    setModalProps({ visible: false, title: "", type: ModalTypeEnums.None, id: null });
  };



  const onImportSave = (uploadResult: any) => {
    if (uploadResult.code == ResponseStatus.SUCCESS) {
      const result = uploadResult?.data?.data;
      if (result && result.length > 0) {
        if (uploadResult?.data?.exProperty) {
          //是否清空
          onChange?.(DataStore.init(result));
        } else {
          let resultData = [...dataSource];
          if (result && result.length > 0) {
            result.forEach((item: any) => {
              let record = resultData.find(row => row.formSaDetailId == item.formSaDetailId);
              if (record) {
                record.amountExVat = wdiCore.Number.ToNumber(item?.amountExVat, 2);
                record.remark = item?.remark;
                record.paymentMethodId = item?.paymentMethodId;
                record.invoiceTypeId = item?.invoiceTypeId;
                record.vatTypeId = item?.vatTypeId;
                record.paymentMethodName = item?.paymentMethodName;
                record.invoiceTypeName = item?.invoiceTypeName;
                record.vatTypeName = item?.vatTypeName;
                resultData = DataStore.edit(resultData, record);
              } else {
                resultData = DataStore.add(resultData, item);
              }
            });
            onChange?.(resultData);
          }
        }
      }
    }
    setImportModalProps({ visible: false, title: "", id: null });
  }

  useEffect(() => {
    initOptions();

    GridApi.searchConfig({ id: 'PaymentDetailApply', allowModifyColumns: true }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,
        showExpandedRow: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.amountExVat.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.ToNumber(record.amountExVat, 2);
      }
      settings.formSchema.allFields.soldToMasterCode.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.String.CodeOrName(record.soldToMasterCode, record.soldToMasterName);
      }

      settings.formSchema.allFields.amountNotPaid.aggr = TableColumnRanderFormats.sumAggr('amountNotPaid', 2);
      settings.formSchema.allFields.amountExVat.aggr = TableColumnRanderFormats.sumAggr('amountExVat', 2);
      settings.formSchema.allFields.soldToCode.width = 200;
      settings.formSchema.allFields.custSysCode.width = 200;
      settings.formSchema.allFields.productBuyNames.width = 220;
      settings.formSchema.allFields.ecName.width = 150;
      settings.formSchema.allFields.amountExVat.width = 120;
      settings.formSchema.allFields.paymentMethodName.width = 100;
      settings.formSchema.allFields.invoiceTypeName.width = 100;
      settings.formSchema.allFields.vatTypeName.width = 100;
      settings.formSchema.allFields.amountNotPaid.width = 120;
      settings.formSchema.allFields.formSaDetailId.width = 100;
      Utility.setActionCallback(settings.formSchema.actions,
        'Update',
        async (grid: any, action: any, record: any) => {
          const context = grid.getContext();
          setModalProps({
            visible: true,
            title: '费用付款',
            id: record.formSaDetailId,
            data: {
              row: record,
              rows: context.dataSource,
              rowIndex: DataStore.getRowIndex(context.dataSource, record.uid),
              formSp: data,
            }
          });
        },
      );
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, [data, dataSource]);
  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return initApplyRowFieldConfigs(record, data?.ecFieldConfigs);
    },
    pagination: {
      pageSize: 10,
      showSizeChanger: true
    },
  };

  const editColumn = {
    vatTypeId: (_t: any, record: any, index: any) => {
      return <WdiSelect {...{
        style: { width: '100%' },
        value: record.vatTypeId ? {
          label: record.vatTypeName,
          value: record.vatTypeId,
        } : null,
        placeholder: '请选择税率',
        remote: false,
        labelInValue: true,
        options: vatTypeOptions,
        onChange: (value) => {
          const { ...newRecord } = record;
          newRecord.vatTypeId = null;
          newRecord.vatTypeName = null;
          if (value) {
            newRecord.vatTypeId = WdiSelectFormat.getvalue(value);
            newRecord.vatTypeName = WdiSelectFormat.gettext(value);
          }
          onChange?.(DataStore.edit(dataSource, newRecord));
        },
      }} />;
    },
    paymentMethodId: (_t: any, record: any, index: any) => {
      return <WdiSelect {...{
        style: { width: '100%' },
        value: record.paymentMethodId ? {
          label: record.paymentMethodName,
          value: record.paymentMethodId,
        } : null,
        placeholder: '请选择支付方式',
        remote: false,
        options: paymentMethodOptions,
        labelInValue: true,
        onChange: (value) => {
          const { ...newRecord } = record;
          newRecord.paymentMethodId = null;
          newRecord.paymentMethodName = null;
          if (value) {
            newRecord.paymentMethodId = WdiSelectFormat.getvalue(value);
            newRecord.paymentMethodName = WdiSelectFormat.gettext(value);
          }

          onChange?.(DataStore.edit(dataSource, newRecord));
        },
      }} />;
    },
    invoiceType: (_t: any, record: any, index: any) => {
      return <WdiSelect {...{
        style: { width: '100%' },
        value: record.invoiceType ? {
          label: record.invoiceTypeName,
          value: record.invoiceType,
        } : null,
        placeholder: '请选择发票类型',
        remote: false,
        labelInValue: true,
        options: invoiceTypeOptions,
        onChange: (value) => {
          const { ...newRecord } = record;
          newRecord.invoiceType = null;
          newRecord.invoiceTypeName = null;
          if (value) {
            newRecord.invoiceType = WdiSelectFormat.getvalue(value);
            newRecord.invoiceTypeName = WdiSelectFormat.gettext(value);
          }
          onChange?.(DataStore.edit(dataSource, newRecord));
        },
      }} />;
    },
    amountExVat: (_t: any, record: any, index: any) => {
      return <WdiInputNumber format='d' digits={2} value={record.amountExVat} onBlur={(e: any) => {
        const { ...newRecord } = record;
        newRecord.amount = wdiCore.Number.ToString(e.target.value, 2);
        newRecord.amountExVat = wdiCore.Number.ToString(e.target.value, 2);
        newRecord.amountInVat = wdiCore.Number.ToString(e.target.value, 2);
        newRecord.tax = 0.00;
        onChange?.(DataStore.edit(dataSource, newRecord));
      }} />
    },
    remark: (_t: any, record: any, index: any) => {
      return <Input value={record.remark} onChange={(e: any) => {
        const {...newRecord} = record;
        newRecord.remark = e.target.value;
        onChange?.(DataStore.edit(dataSource, newRecord));
      }}/>;      
    },
    quantityActual1: (_t: any, record: any, index: any) => {
      return record.quantityActual1;
    },
    quantityActual2: (_t: any, record: any, index: any) => {
      return record.quantityActual2;
    },
  };

  return (
    <>
      <WdiPanel
        title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用明细信息' />}
        extra={
          <>
            <Button type='primary' onClick={() => {
              const newDataSource = [...dataSource];
              newDataSource.forEach(item => {
                item.amountExVat = wdiCore.Number.ToString(item.amountNotPaid, 2);
              })
              onChange?.(newDataSource);
            }} style={{ float: 'right', marginRight: 10 }}>
              全部付款
            </Button>
            <Button hidden={buttonHidden} type='primary' onClick={() => {
              setExportModalProps({
                visible: true,
                title: `导出需要付款的活动明细`,
                data: { bohqId: data.bohqId }
              });
            }}
              style={{ float: 'right', marginRight: 10 }}>
              导出活动明细
            </Button>
            <Button hidden={buttonHidden} type='primary' onClick={() => {
              setImportModalProps({
                title: '付款信息导入',
                visible: true,
                data: { details: dataSource, bohqId: data.bohqId, pageTypeId: data.pageTypeId }
              });
            }}
              style={{ float: 'right', marginRight: 10 }}>
              付款信息导入
            </Button>

          </>
        }
      >
        {
          isLoaded &&
          importModalProps.visible &&
          <ImportModalApp
            title={importModalProps.title}
            visible={importModalProps.visible}
            settingsUrl="/activityapi/spButtons/getImportSettings"
            uploadingUrl="/activityapi/spButtons/importUploading"
            onClose={onImportClose}
            onSave={onImportSave}
            clientParameters={{
              bohqId: data.bohqId,
              pageTypeId: Enum.FormType.ACTIVITY_PAYMENT,
              details: JSON.stringify(dataSource)
            }} />
        }
        {isLoaded && <DetailGrid {...pageGridSettings} params={data} editColumn={editColumn} />}
      </WdiPanel>
      {isLoaded && modalProps.visible &&
        <FormDetailEditApp
          {...modalProps}
          onSave={onEditSave}
          onClose={onModalClose}
        />}
      {isLoaded && exportModalProps.visible &&
        <FormSaDetailSelect
          {...exportModalProps}
          onClose={onDetailClose}
          onSave={onDetailSave}
        />
      }
    </>
  );
}
export default GeneralPaymentDetailsForApplyPanel;

export const initApprovalRowFieldConfigs = (record: any,) => {
  return ["rowNumber", "soldToCode", "custSysCode",
    "productBuyNames", "ecName", "paymentMethodName", "invoiceTypeName", "vatTypeName",
    "amountNotPaid", "amountExVat", "remark", "soldToMasterCode", "formSaNo", "activityName",
    "activityStartDate", "activityEndDate", "platformName", "districtName", "promotionDesc", "amountSa", "amountSs", "amountPaid"
    , "formSpDetailId", "invoiceNo", "isReverse", "poolPrepayReverseId"
  ];
};
export const GeneralPaymentDetailsForApprovalPanel: React.FC<DetailForApplyProps> = (props) => {

  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const { data, dataSource, } = props;

  useEffect(() => {
    Promise.all([GridApi.searchConfig({ id: "PaymentDetailApproval", allowModifyColumns: true })
      , Position.access({ useCaseId: Enum.UseCase.SP, operateTypeIds: [Enum.OperateType.MODIFY_AMOUNT] })
    ]).then((dataArray: any[]) => {
      const responseResult = dataArray[0];


      const settings: any = Object.assign({}, responseResult.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,
        showExpandedRow: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.amountExVat.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Number.ToString(record.amountExVat, 2);
      }
      settings.formSchema.allFields.soldToMasterCode.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.String.CodeOrName(record.soldToMasterCode, record.soldToMasterName);
      }


      settings.formSchema.allFields.amountNotPaid.aggr = TableColumnRanderFormats.sumAggr('amountNotPaid', 2);
      settings.formSchema.allFields.amountExVat.aggr = TableColumnRanderFormats.sumAggr('amountExVat', 2);
      settings.formSchema.allFields.soldToCode.width = 200;
      settings.formSchema.allFields.custSysCode.width = 200;
      settings.formSchema.allFields.productBuyNames.width = 220;
      settings.formSchema.allFields.ecName.width = 150;
      settings.formSchema.allFields.amountExVat.width = 120;
      settings.formSchema.allFields.paymentMethodName.width = 100;
      settings.formSchema.allFields.invoiceTypeName.width = 100;
      settings.formSchema.allFields.vatTypeName.width = 100;
      settings.formSchema.allFields.amountNotPaid.width = 120;
      settings.formSchema.allFields.formSaDetailId.width = 100;
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, [data]);

  const columns = [
    { name: "formSaDetailId", displayName: "活动明细编号" },
    { name: "soldToCode", displayName: "售达方编号" },
    { name: "soldToName", displayName: "售达方名称" },
    { name: "custSysCode", displayName: "客户集团编号" },
    { name: "custSysName", displayName: "客户集团名称" },
    { name: "productBuyNames", displayName: "产品别" },
    { name: "ecName", displayName: "费用项" },
    { name: "paymentMethodName", displayName: "支付方式" },
    { name: "invoiceTypeName", displayName: "发票类型" },
    { name: "vatTypeName", displayName: "税率类型" },
    { name: "amountNotPaid", displayName: "可付款金额" },
    { name: "amountExVat", displayName: "本次付款金额" },
    { name: "remark", displayName: "付款备注" },
    { name: "soldToMasterCode", displayName: "售达方主户编号" },
    { name: "soldToMasterName", displayName: "售达方主户名称" },
    { name: "formSaNo", displayName: "活动单号" },
    { name: "activityName", displayName: "活动名称" },
    { name: "activityStartDate", displayName: "活动开始日期", type: Enum.FieldType.DATE_TIME },
    { name: "activityEndDate", displayName: "活动截止日期", type: Enum.FieldType.DATE_TIME },
    { name: "platformName", displayName: "分子平台" },
    { name: "districtName", displayName: "省份" },
    { name: "promotionDesc", displayName: "活动机制" },
    { name: "amountSa", displayName: "申请金额" },
    { name: "amountSs", displayName: "核销金额" },
    { name: "amountPaid", displayName: "已付款金额" },
    { name: "formSpDetailId", displayName: "付款明细编号" },
    { name: "invoiceNo", displayName: "发票号码" },
    { name: "isReverse", displayName: "是否已冲销", type: Enum.FieldType.BOOLEAN },
    { name: "poolPrepayReverseId", displayName: "冲销编号" }
  ];
  const exportDetails = () => {
    const formSpSheet = {
      data: dataSource,
      names: columns,
      sheetName: "费用明细信息",
    };
    toExcel({
      name: "活动付款费用明细导出_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
      sheetProps: [formSpSheet]
    });
  }


  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return initApprovalRowFieldConfigs(record);
    },
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true
    },
  };

  return (
    <>
      <WdiPanel title={< WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用明细信息' />}
        collapsed={false}
        extra={
          <>
            <Button type='primary' onClick={(e) => {
              e.stopPropagation();
              exportDetails();
            }}>
              导出
            </Button>
          </>
        }
      >
        {isLoaded && <DetailGrid params={data} {...pageGridSettings} />}
      </WdiPanel>
    </>
  );
};
