import { formatter, request } from '@/utils/request';

export namespace SimpleStaff {

  export const Path = {
    Current: '/basicapi/simpleStaff/current',
    Get: '/basicapi/simpleStaff/get',
    Search: '/basicapi/simpleStaff/search',
    AjaxSearch: '/basicapi/simpleStaff/ajaxSearch',
  };

  export async function current() {
    const options = {
      method: 'GET',
      body: {}
    };
    return request<API.ResponseResult<API.SimpleStaff>>(Path.Current, formatter(options));
  }


  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.SimpleStaff>>(Path.Get, formatter(options));
  }

  export async function search(body: API.StaffSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SimpleStaff[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.StaffSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SimpleStaff[]>>(Path.AjaxSearch, formatter(options));
  }

}

