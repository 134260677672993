import { ParamsProps } from '@/components/WdiBus/FormPage';
import { FormBudgetProject } from '@/services/sales/bp/formBudgetProject';
import { BudgetProject } from '@/services/sales/bp/budgetProject';
import { Enum } from '@/enums/system';
import { ResponseStatus } from '@/utils/request';
import { WdiModalAppResult } from '@/components/WdiControls';
import { FormApi } from '@/services/sales';

export type ViewEntityProps = {
  budgetProject?: API.BudgetProject,
  form?: API.Form,
  formBudgetProject?: API.FormBudgetProject,
  relationType?: number,
  relatedFormId?: number,
  formId?: number;
  statusId?: number;
  formTypeId?: number;
  pageTypeId?: number;
  bohqId?: number;
}

export const initApplyPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.budgetProjectId) {
    // 变更申请
    const budgetProjectResponse = await BudgetProject.get(params.budgetProjectId);
    viewEntity.budgetProject = budgetProjectResponse.data;
    if (!viewEntity.budgetProject) {
      result.message = "无效的专项项目";
      return result;
    }
    viewEntity.relationType = Enum.FormRelationType.MODIFIED;
    viewEntity.relatedFormId = viewEntity.budgetProject.formBudgetProjectId;
  } else if (params.formId) {
    // 从草稿打开
    const formBudgetProjectResponse = await FormBudgetProject.get(params.formId);
    viewEntity.formBudgetProject = formBudgetProjectResponse.data;
    if (!viewEntity.formBudgetProject) {
      result.message = "无效的草稿单";
      return result;
    }
    viewEntity.formId = viewEntity.formBudgetProject.formBudgetProjectId;
    viewEntity.statusId = Enum.FormStatus.DRAFT;
  } else if (params.relatedFormId) {
    // 退回待修改
    const formBudgetProjectResponse = await FormBudgetProject.get(params.relatedFormId);
    viewEntity.formBudgetProject = formBudgetProjectResponse.data;
    if (!viewEntity.formBudgetProject) {
      result.message = "无效的退回待修改单";
      return result;
    }
    viewEntity.relationType = Enum.FormRelationType.REJECTED;
    viewEntity.relatedFormId = viewEntity.formBudgetProject.formBudgetProjectId;
  } else {
    // 直接申请
    viewEntity.bohqId = params.bohqId;
    viewEntity.pageTypeId = params.pageTypeId;
    viewEntity.formTypeId = params.formTypeId;
  }

  if (viewEntity.budgetProject) {
    viewEntity.bohqId = viewEntity.budgetProject.bohqId;
    viewEntity.pageTypeId = viewEntity.budgetProject.pageTypeId;
    viewEntity.formTypeId = viewEntity.budgetProject.formTypeId;
  } else if (viewEntity.formBudgetProject) {
    viewEntity.bohqId = viewEntity.formBudgetProject.bohqId;
    viewEntity.pageTypeId = viewEntity.formBudgetProject.pageTypeId;
    viewEntity.formTypeId = viewEntity.formBudgetProject.formTypeId;
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
};

export const initApprovalPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId) {
    // 从草稿打开
    const formBudgetProjectResponse = await FormBudgetProject.get(params.formId);
    viewEntity.formBudgetProject = formBudgetProjectResponse.data;
    if (!viewEntity.formBudgetProject) {
      result.message = "无效的草稿单";
      return result;
    }

    const formResponse = await FormApi.get(params.formId);
    viewEntity.form = formResponse.data;

    if (viewEntity.formBudgetProject.budgetProjectId) {
      const budgetProjectResponse = await BudgetProject.get(viewEntity.formBudgetProject.budgetProjectId);
      viewEntity.budgetProject = budgetProjectResponse.data;
    }
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
};

export const initDisplyPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.budgetProjectId) {
    const budgetProjectResponse = await BudgetProject.get(params.budgetProjectId);
    viewEntity.budgetProject = budgetProjectResponse.data;
    if (!viewEntity.budgetProject) {
      result.message = "无效的专项项目";
      return result;
    }
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
};
