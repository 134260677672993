import { request, formatter } from '@/utils/request';

export namespace Position {
  export const Path = {
    Get: '/basicapi/position/get',
    Search: '/basicapi/position/search',
    Post: '/basicapi/position/post',
    DeleteBatch: '/basicapi/position/deleteBatch',
    Copy: '/basicapi/position/copy',
    Query: '/basicapi/position/query',
    SearchForSwitch: '/basicapi/position/searchForSwitch',
    Access: '/basicapi/position/access'
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.Position>>(Path.Get, formatter(options));
  }

  export async function search(body: API.PositionSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Position[]>>(Path.Search, formatter(options));
  }

  export async function post(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Position>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'Post',
      body: {
        positionIds: `${id}`,
      },
    };
    return request<API.ResponseResult<any>>(Path.DeleteBatch, formatter(options));
  }

  export async function dels(ids: string) {
    const options = {
      method: 'Post',
      body: {
        positionIds: ids,
      },
    };
    return request<API.ResponseResult<any>>(Path.DeleteBatch, formatter(options));
  }

  export async function copy(body: API.PositionCopyParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Copy, formatter(options));
  }

  export async function query(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        sortdatafield: 'PositionName',
        sortorder: 'ASC',
        ...body,
      }
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }

  export async function searchForSwitch() {
    const options = {
      method: 'POST',
      body: {

      },
    };
    return request<API.ResponseResult<any>>(Path.SearchForSwitch, formatter(options));
  }

  export async function access(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      }
    };
    return request<API.ResponseResult<boolean>>(Path.Access, formatter(options));
  }
}
