import { formatter, request } from '@/utils/request';

export namespace SoldToGroup {
  export const Path = {
    Search: '/mdmapi/soldToGroup/search',
    AjaxSearch: '/MDMApi/SoldToGroup/AjaxSearch',
    Query: '/mdmapi/soldToGroup/query',
    Get: '/mdmapi/soldToGroup/get',
    Post: '/mdmapi/soldToGroup/post',
    Delete: '/mdmapi/soldToGroup/delete',
    GetSoldToGroupToExcel:'/mdmapi/soldToGroup/getSoldToGroupToExcel',
    Export: '/mdmapi/soldToGroup/export',
  };

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }

  export async function search(body: API.SoldToGroupFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SoldToGroup[]>>(Path.Search, formatter(options));
  }
  export async function ajaxSearch(body: API.SoldToGroupFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SoldToGroup[]>>(Path.AjaxSearch, formatter(options));
  }
  export async function get(id: string) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.SoldToGroup>>(Path.Get, formatter(options));
  }
  export async function post(body: API.SoldToGroupEntity) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }
  export async function del(id: string) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }

  export async function getSoldToGroupToExcel(body: API.SoldToGroupFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SoldToGroup[]>>(Path.GetSoldToGroupToExcel, formatter(options));
  }


  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Export, formatter(options));
  }
}
