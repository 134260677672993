import { formatter, defaultRequest, nonBuRequest } from '@/utils/request';
import qs from 'qs';
import { ResponseStatus } from '@/utils/request';
import { BU } from '@/services/basic/bu';

export namespace NSToken {

  function save(token: API.TokenBody) {
    const refreshTime = new Date().getTime() + token.expires_in * 1000;
    localStorage.setItem('refreshTime', refreshTime.toString());
    localStorage.setItem('accessToken', token.access_token);
    localStorage.setItem('refreshToken', token.refresh_token);
  }

  export function clearToken() {
    localStorage.removeItem('refreshTime');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  export function accessToken() {
    return localStorage.getItem('accessToken');
  }

  export function validate() {
    const lastTokenRefresh = Number(localStorage.getItem("refreshTime"));
    const current = new Date().getTime();
    let res = true;
    if (Number.isNaN(lastTokenRefresh)) {
      res = false;
    } else if (current > lastTokenRefresh) {
      res = false;
    }
    if (!accessToken()) {
      res = false;
    }
    return res;
  }

  export async function fetch(body: any) {
    return defaultRequest('/identityapi/oauth/token', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(body),
      method: 'POST'
    }).then((res) => {
      let tokenResult: API.TokenResult = {
        status: ResponseStatus.UNKNOWN,
        message: res.message
      };

      if (res.code == 200) {
        tokenResult.data = {
          access_token: res.data.access_token,
          expires_in: res.data.expires_in,
          refresh_token: res.data.refresh_token
        }
        tokenResult.status = ResponseStatus.SUCCESS;
        save(tokenResult.data)
      } else {
        clearToken();
      }
      return tokenResult;
    }).catch((error) => {
      const response: API.TokenResult = {
        status: ResponseStatus.UNKNOWN,
        message: error
      }
      return response;
    });
  }

  export async function get(body: API.LoginParams) {
    return fetch({
      ...body,
      grant_type: 'password',
      client_id: 'apiclient',
      client_secret: 'apisecret',
    });
  }

  export async function refresh() {
    return fetch({
      grant_type: 'refresh_token',
      client_id: 'apiclient',
      client_secret: 'apisecret',
      refresh_token: localStorage.getItem('refreshToken'),
    });
  }

  // 根据token获取用户信息，不需要检验token和bu一致
  export async function getUserinfo() {
    return nonBuRequest('/identityapi/oauth/userinfo', formatter({ method: "GET" })).then((res) => {
      const user: API.SysUser = {
        userId: res.userId,
        roleId: res.roleId,
        loginName: res.loginName,
        lcId: res.lcId,
        deptId: res.deptId,
        isManager: res.isManager,
        buId: res.buId
      };
      return {
        status: ResponseStatus.SUCCESS,
        data: user
      }
    });
  }

  // 标准模式用户名密码登录
  export async function login1(body: API.LoginParams) {
    const tokenResult: API.TokenResult = await get({ ...body });
    let loginResult: API.LoginResult;
    if (tokenResult.status === ResponseStatus.SUCCESS) {
      const res = await getUserinfo();
      // 获取用户之后设置当前页面的BU信息，后续所有请求需要在header中绑定bu
      if(res && res.data){
        NSToken.setBu(res.data.buId);
        const bu = await BU.get(res.data.buId)
        NSToken.setBuName(bu.data.buName)
      } else {
        NSToken.clearBu();
      }
      loginResult = {
        status: ResponseStatus.SUCCESS,
        data: res.data
      }
    } else {
      loginResult = {
        status: ResponseStatus.UNKNOWN,
        message: '用户名或密码不正确'
      }
    }
    return loginResult;
  }

  // 切换职务或者bu，重新获取token
  export function switchPosition(positionId: number) {
    return fetch({
      customAuthType: "1",
      positionId: positionId,
      grant_type: 'custom',
      client_id: 'apiclient',
      client_secret: 'apisecret',
      token: localStorage.getItem('accessToken'),
    });
  }

  // 根据当前token重新获取token，如用户修改了语言配置信息
  export function reloadToken() {
    return fetch({
      customAuthType: "0",
      grant_type: 'custom',
      client_id: 'apiclient',
      client_secret: 'apisecret',
      token: localStorage.getItem('accessToken'),
    });
  }

  export async function loginOut() {
    nonBuRequest('/identityapi/oauth/logout', formatter({ method: "POST" })).then((res) => {
      if (res.code == 200) {
        clearToken();
      }
    });
  }
  export function getSAMLToken(code: string) {
    return fetch({
      customAuthType: "2",
      grant_type: 'custom',
      client_id: 'apiclient',
      client_secret: 'apisecret',
      code:code,
      token: localStorage.getItem('accessToken'),
    });
  }
  export function getSsoUrl() {
    return defaultRequest('/identityapi/oauth/getSsoUrl', formatter({ method: "GET" })).then((res) => {
      return {
        status: ResponseStatus.SUCCESS,
        data: res.data, // 假设后端返回的数据是单点登录 URL
      };
    });
  }
  //Sso登录
  export async function samlLogin(code: string) {
    const tokenResult: API.TokenResult = await getSAMLToken(code);

    let loginResult: API.LoginResult;
    if (tokenResult.status === ResponseStatus.SUCCESS) {
      const res = await getUserinfo();
      loginResult = {
        status: ResponseStatus.SUCCESS,
        data: res.data,
      };
    } else {
      loginResult = {
        status: ResponseStatus.UNKNOWN,
        message:tokenResult.message
     //   message: '登陆账号[**]在本系统用户中不存在,用户认证失败',
      };
    }
    return loginResult;
  }


  export function setBuName(buName: String) {
    window["__TPM_BU_NAME__"] = buName
  }

  export function getBuName() {
    return window["__TPM_BU_NAME__"];
  }

  export function setBu(buId: any) {
    window["__TPM_BU_ID__"] = buId;
  }

  export function getBu() {
    return window["__TPM_BU_ID__"];
  }

  export function clearBu() {
    window["__TPM_BU_NAME__"] = null;
    window["__TPM_BU_ID__"] = null;
  }
}
