import {formatter, request} from "@/utils/request";

export namespace SoldToMaster {
  export const Path = {
    Search: '/mdmapi/soldToMaster/search',
    AjaxSearch: '/mdmapi/soldToMaster/ajaxSearch',
    Query: '/mdmapi/soldToMaster/query',
    Export: '/mdmapi/soldToMaster/export',
    Get: '/mdmapi/soldToMaster/get',
    Post: '/mdmapi/soldToMaster/post',
  };

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }

  export async function search(body: API.SoldToMasterSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SoldToMaster[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.SoldToMasterSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SoldToMaster[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function get(id: string) {
    const options = {
      method: 'GET',
      body: {
        code: id,
      },
    };
    return request<API.ResponseResult<API.SoldToMaster>>(Path.Get, formatter(options));
  }

  export async function post(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

}
