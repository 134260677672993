import {useEffect, useState} from 'react';
import {wdiCore, WdiFormCardTitle, WdiInput, WdiTextArea} from '@/components/WdiControls';
import {Col, Form, Row} from 'antd';
import {FileUploadUtility, WdiFileList} from '@/components/WdiControls/WdiFileUpload';
import type {WdiPanelProps} from '@/components/WdiControls/WdiPanel';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {FormSa} from '@/services/sales/sa';
import {FormApi} from '@/services/sales';

export type DetailForApplyProps = {
  formRef: any;
  formSaId?: number;
} & WdiPanelProps;

const FormSaInfosApp: React.FC<DetailForApplyProps> = (props) => {
  const [saForm, setSAForm] = useState<API.Form>();
  useEffect(() => {
    if (props.formSaId && props.formRef) {
      FormSa.get(props.formSaId).then(formSAResponse => {
        const formSa = formSAResponse?.data;
        props.formRef.current?.setFieldsValue({
          SAActivityName: formSa.activityName,
          SAActivityDate: wdiCore.Date.Join(formSa.activityStartDate, formSa.activityEndDate),
          SASupplyDate: wdiCore.Date.Join(formSa.supplyStartDate, formSa.supplyEndDate),
          SARemark: formSa.remark,
          SAAttachment: FileUploadUtility.parse(formSa.fileName, formSa.filePath),
        })
      })
      FormApi.get(props.formSaId).then(saFormResponse => {
        setSAForm(saFormResponse?.data);
      })
    }
  }, [props.formSaId]);
  return (
    <>
      <WdiPanel collapsed={true} {...props} title={<WdiFormCardTitle title='活动申请单信息' form={saForm} isJump={true}/>}>
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" span={8}>
            <Form.Item name="SAActivityName" label="活动名称">
              <WdiInput readOnly/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={4}>
            <Form.Item name="SAActivityDate" label="活动期间">
              <WdiInput readOnly/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={4}>
            <Form.Item name="SASupplyDate" label="供货期间">
              <WdiInput readOnly/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" span={16}>
            <Form.Item name="SARemark" label="方案简述">
              <WdiTextArea rows={4} readOnly/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item
              name="SAAttachment"
              label="附件"
              valuePropName='fileList'
              getValueFromEvent={(e: any) => {
                return e.fileList || [];
              }}
            >
              <WdiFileList/>
            </Form.Item>
          </Col>
        </Row>
      </WdiPanel>
    </>
  );
}
export default FormSaInfosApp;

