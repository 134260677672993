import { useEffect, useState, useRef } from 'react';
import { DetailGrid, Utility } from '@/components/GridWidget';
import { NsGrid } from '@/components/GridWidget/GridDefProps';
import { WdiModalProps, WdiModalAppResult, WdiCardTitle } from '@/components/WdiControls';
import { Dropdown, Menu, message } from 'antd';
import { TableColumnRanderFormats, initFieldDisplayFormater } from '@/components/WdiBus/TableColumnRanderFormats'
import DataStore from '@/components/GridWidget/DataStore'
import { ResponseStatus } from '@/utils/request';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import FormSADetailEditApp, { FormSADetailViewApp } from './detail-edit';
import FreeGoodsPickerApp from './detail-prev';
import { ImportModalApp } from '@/components/WdiControls/WdiImport';
import { Enum } from '@/enums/system';
import { GridApi } from '@/services/gridapi';
import { ToExcelProps } from '@/components/WdiControls/WdiExcel';
import { ShipTo } from '@/services/mdm/shipto';
import { Product } from '@/services/mdm/product';
import { Position } from '@/services/basic/position';
import { FormSa, FormSaDetail } from '@/services/sales/sa';

const ModalAppTypeEnums = {
  None: 0,
  Insert: 1,
  Edit: 2,
  Import: 3,
  Picker: 4,
  View: 5
};

export type DetailForApplyProps = {
  pid?: number | string | null,
  data?: {
    formSa?: API.FormSa,
    ecId?: Number,
  },
  dataSource: any[],
  onChange?: (dataSource: any) => void;
};

const FormSADetailsForApplyPanel: React.FC<DetailForApplyProps> = (props) => {

  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [importExcels, setImportExcels] = useState<ToExcelProps[]>([]);
  const { data, dataSource, onChange } = props;

  //获取GridWdiget实例
  const gridRef: any = useRef();
  const getGridRef = (el: any) => {
    gridRef.current = el
  };

  const [editModalProps, setEditModalProps] = useState<WdiModalProps>({ visible: false });
  const [importModalProps, setImportModalProps] = useState<WdiModalProps>({ visible: false });

  const onImportClose = () => {
    setImportModalProps({ visible: false, title: "", id: null });
  }
  const onImportSave = (uploadResult: any) => {
    if (uploadResult.code == ResponseStatus.SUCCESS) {
      const newDataSource = [...dataSource];
      const result = uploadResult?.data?.data;

      if (result && result.length > 0) {
        if (uploadResult?.data?.exProperty) {
          //是否清空
          onChange?.(DataStore.init(result));
        }
        else {
          onChange?.(DataStore.addMulti(newDataSource, result));
        }
      }
    }
    setImportModalProps({ visible: false, title: "", id: null });
  }
  const onPrevClose = () => {
    setEditModalProps({ visible: false, title: "", type: ModalAppTypeEnums.None, id: null });
  };
  const onPrevSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      setEditModalProps({
        visible: true, title: "新建申领明细", type: ModalAppTypeEnums.Insert, id: null,
        data: {
          rowIndex: -1,
          formSaDetail: result?.data,
          formSaDetails: dataSource,
          ecId: data?.ecId,
          formSa: {
            pageTypeId: data?.formSa?.pageTypeId,
            budgetProjectId: data?.formSa?.budgetProjectId
          }
        }
      });
    }
  };
  const onEditClose = () => {
    setEditModalProps({ visible: false, title: "", type: ModalAppTypeEnums.None, id: null });
  };
  const onEditSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      const row = result?.data;

      if (editModalProps.type === ModalAppTypeEnums.Edit) {
        const record = editModalProps.data.formSaDetail;
        const newItem = { ...row };
        newItem.uid = record.uid;
        const newDataSouce = DataStore.edit(dataSource, newItem);
        onChange?.(newDataSouce);
      } else if (editModalProps.type === ModalAppTypeEnums.Insert) {
        const newItem = { ...row };
        onChange?.(DataStore.add(dataSource, newItem));
      }
    }
    setEditModalProps({ visible: false, title: "", id: null });
  };

  const initImportExcel = () => {
    const productColumns = [
      { name: "productCode5", displayName: "产品编号" },
      { name: "productName5", displayName: "产品名称" },
      { name: "productCode4", displayName: "L4编号" },
      { name: "productName4", displayName: "L4名称" },
      { name: "productCode3", displayName: "L3编号" },
      { name: "productName3", displayName: "L3名称" },
      { name: "productCode2", displayName: "品类品牌编号" },
      { name: "productName2", displayName: "品类品牌名称" },
      { name: "productCode1", displayName: "品类编号" },
      { name: "productName1", displayName: "品类名称" },
    ];
    const productSheet = {
      dataLoading: async () => {
        return await Product.getProductStructures({ isActive: true, productLevels: String(Enum.ProductLevel.PRODUCT5) });
      },
      data: null,
      names: productColumns,
      sheetName: "产品",
    };
    const shipToColumns = [
      { name: "shipToCode", displayName: "送达方编号" },
      { name: "shipToName", displayName: "送达方名称" },
      { name: "soldToCode", displayName: "售达方编号" },
      { name: "soldToName", displayName: "售达方名称" },
    ];
    const shipToSheet = {
      dataLoading: async (params: any) => {
        return await ShipTo.searchExcel({ withPermissions: true });
      },
      data: null,
      names: shipToColumns,
      sheetName: "送达方",
    };
    const importExcels: ToExcelProps[] = [];
    importExcels.push({
      name: "全部下载",
      sheetProps: [productSheet, shipToSheet]
    });
    importExcels.push({
      name: "产品列表",
      sheetProps: [productSheet]
    });
    importExcels.push({
      name: "送达方列表",
      sheetProps: [shipToSheet]
    });
    setImportExcels(importExcels);
  }

  useEffect(() => {
    GridApi.searchConfig({ id: 'FormSADetailsForFG', allowModifyColumns: false }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: false,
      });

      initFieldDisplayFormater(settings.formSchema.allFields)

      settings.formSchema.allFields.amount.aggr = TableColumnRanderFormats.sumAggr('amount', 2);

      Utility.setActionCallback(
        settings.formSchema.actions,
        'Update',
        async (grid: any, action: any, record: any) => {
          const context = grid.getContext();
          setEditModalProps({
            visible: true,
            title: '编辑申领明细',
            type: ModalAppTypeEnums.Edit,
            id: record.formSaDetailId,
            data: {
              rowIndex: DataStore.getRowIndex(context.dataSource, record.uid),
              formSaDetail: record,
              formSaDetails: context.dataSource,
              ecId: context.params.ecId,
              formSa: {
                // formTypeId: data.formTypeId,
                pageTypeId: context.params.formSa.pageTypeId,
                budgetProjectId: context.params.formSa.budgetProjectId
              }
            }
          });
        },
      );

      Utility.setActionCallback(
        settings.formSchema.actions,
        'SystemDelete',
        async (grid: any, action: any, record: any) => {
          if (window.confirm("确定要删除此免费货申领信息吗？")) {
            const context = grid.getContext();
            onChange?.(DataStore.del(context.dataSource, record.uid));
            message.success("删除成功");
          }
        },
      );

      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.remark.width = 100;
      settings.formSchema.allFields.amount.width = 100;
      settings.formSchema.allFields.deliveryDate.width = 120;

      initImportExcel();
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource
  };

  return (
    <WdiPanel
      title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='免费货申领信息' />}
      extra={
        <Dropdown.Button
          type='primary'
          onClick={() => {
            if (!data?.ecId) {
              message.error("请选择费用项");
              return;
            }
            setEditModalProps({
              visible: true,
              title: '选择售达方',
              type: ModalAppTypeEnums.Picker,
              id: null,
              data: {
                formSaDetail: null,
                formSaDetails: dataSource,
                pageTypeId: data.formSa?.pageTypeId,
                ecId: data.ecId,
                budgetProjectId: data.formSa?.budgetProjectId
              }
            });
          }}
          overlay={
            <Menu items={[{
              key: 'import',
              label: '导入',
              onClick: () => {
                if (!props.data?.ecId) {
                  message.error("请选择费用项");
                  return;
                }

                setImportModalProps({
                  title: '申领明细导入', visible: true
                });
                return true;
              }
            }]} />
          }
          style={{ float: 'right' }}
        >新建</Dropdown.Button>
      }
    >
      {isLoaded &&
        importModalProps.visible &&
        <ImportModalApp
          title={importModalProps.title}
          visible={importModalProps.visible}
          settingsUrl="/activityapi/saButtons/getImportSettings"
          uploadingUrl="/activityapi/saButtons/importUploading"
          onClose={onImportClose}
          onSave={onImportSave}
          clientParameters={{
            ecId: props.data?.ecId,
            formSa: data?.formSa,
            formSaDetails: JSON.stringify(dataSource)
          }}
          importExcels={importExcels}
        />
      }
      {isLoaded &&
        editModalProps.visible &&
        (editModalProps.type === ModalAppTypeEnums.Picker) &&
        <FreeGoodsPickerApp
          {...editModalProps}
          onClose={onPrevClose}
          onSave={onPrevSave}
        />
      }
      {
        isLoaded &&
        editModalProps.visible &&
        (editModalProps.type === ModalAppTypeEnums.Insert || editModalProps.type === ModalAppTypeEnums.Edit) &&
        <FormSADetailEditApp
          {...editModalProps}
          onClose={onEditClose}
          onSave={onEditSave}
        />
      }
      {
        isLoaded &&
        <DetailGrid {...pageGridSettings} params={data} autoColumn={['rowNumber']} />
      }
    </WdiPanel>
  );
};
export default FormSADetailsForApplyPanel;

export const FormSADetailsForApprovalPanel: React.FC<DetailForApplyProps> = (props) => {

  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [dispModalProps, setDispModalProps] = useState<WdiModalProps>({ visible: false });

  const onClose = () => {
    setDispModalProps({ visible: false, title: "", id: null });
  };

  useEffect(() => {
    Promise.all([GridApi.searchConfig({ id: 'FormSADetailsForFGApproval', allowModifyColumns: true }),
    Position.access({ useCaseId: Enum.UseCase.SA, operateTypeIds: [Enum.OperateType.COMPLETE_DELIVERY] })]).then((dataArray: any[]) => {
      const response = dataArray[0];
      const allowCompleteDelivery = dataArray[1].data;
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: false,
        filterMenuActions: (actions: any, record: any) => {
          const newActions: any[] = [];
          actions.forEach((item: any) => {
            if (item.name == 'ViewGoodsDetail') {
              newActions.push(item);
            }
            if (item.name == 'CompleteDelivery' && allowCompleteDelivery && !record.isDelivered) {
              newActions.push(item);
            }
          });
          return newActions;
        }
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.amount.aggr = TableColumnRanderFormats.sumAggr('amount', 2);
      settings.formSchema.allFields.amountDelivered.aggr = TableColumnRanderFormats.sumAggr('amountDelivered', 2);
      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.soldToCode.width = 250;
      settings.formSchema.allFields.shipToCode.width = 250;
      settings.formSchema.allFields.amount.width = 100;
      settings.formSchema.allFields.deliveryDate.width = 150;
      settings.formSchema.allFields.isDelivered.width = 100;
      settings.formSchema.allFields.amountDelivered.width = 100;

      Utility.setActionCallback(
        settings.formSchema.actions,
        'ViewGoodsDetail',
        async (grid: any, action: any, record: any) => {
          setDispModalProps({
            visible: true,
            title: '免费货申领明细信息',
            type: ModalAppTypeEnums.Edit,
            id: record.formSaDetailId,
            data: record
          });
        },
      );

      // if (allowCompleteDelivery) {
      //   Utility.setActionCallback(settings.formSchema.actions,
      //     'CompleteDelivery',
      //     (grid: any, action: any, record: any) => {
      //       if (window.confirm("确定要设置发货完成？")) {
      //         const args: API.ButtonArgs = {
      //           formId: record.formSaId,
      //           key: "CompleteDelivery",
      //           items: [{ name: "formSaDetailId", value: String(record.formSaDetailId) }]
      //         }
      //         FormSa.Buttons.completeDelivery(args).then(responseresult => {
      //           if (responseresult.code == ResponseStatus.SUCCESS) {
      //             message.success('操作成功!');
      //             FormSaDetail.getFgEx(Number(props.pid)).then((response) => {
      //               if (props.onChange) {
      //                 props?.onChange(response.data);
      //               }
      //             });
      //           }
      //           else {
      //             message.error(responseresult.message);
      //           }

      //         })
      //       }
      //     });
      // }

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  const { dataSource } = props;
  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    pagination: false,
    dataSource
  };

  return (
    <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='免费货申领信息' />} collapsed={true} >
      {isLoaded && <DetailGrid {...pageGridSettings} />}
      {
        isLoaded &&
        dispModalProps.visible &&
        <FormSADetailViewApp
          {...dispModalProps}
          onClose={onClose}
        />
      }
    </WdiPanel>
  );
};

