import {useEffect, useState} from 'react';
import {DetailGrid, Utility} from '@/components/GridWidget';
import {NsGrid} from '@/components/GridWidget/GridDefProps';
import type {WdiModalProps, WdiModalAppResult} from '@/components/WdiControls';
import {WdiCardTitle} from '@/components/WdiControls';
import {Button, message} from 'antd';
import {initFieldDisplayFormater} from '@/components/WdiBus/TableColumnRanderFormats';
import DataStore from '@/components/GridWidget/DataStore';
import {ResponseStatus} from '@/utils/request';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {SalesContractTerms} from '@/services/contract/salescontractterms';
import SalesContractTermsEditApp from './detail-edit';
import {Enum} from '@/enums/system';
import SalesContractTermsPrevApp from './detail-prev';
import SalesContractTermsAutoEditApp from './detail-edit-auto';
import {GridApi} from '@/services/gridapi';
import {isContractType1, isContractType2} from "@/pages/contract/SalesContract/components/data";
import {ResponseCode} from "@/utils/response";
import SalesContractTermsNonAutoEditApp from "@/pages/contract/SalesContractApply/components/detail-edit-non-auto";

export const ModalAppTypeEnums = {
  None: 0,
  Picker: 1,
  // 自动生成
  InsertAuto: 2,
  EditAuto: 3,
  // 手动申请:
  InsertApply: 4,
  EditApply: 5,
  // 手动结案:
  InsertNonAuto: 6,
  EditNonAuto: 7,
  // 外部导入
  InsertImport: 8,
  EditImport: 9,
};

const convertToModalType = (processMode: number, isEdit: boolean) => {
  const responseResult = {
    code: ResponseCode.SUCCESS,
    data: ModalAppTypeEnums.None,
    message: ""
  };
  if (isEdit) {
    if (processMode == Enum.ProcessMode.AUTO_ACCRUAL_AND_SETTLE) {
      responseResult.data = ModalAppTypeEnums.EditAuto;
    } else if (processMode == Enum.ProcessMode.NONE_AUTO_ACCRUAL_AND_SETTLE) {
      responseResult.data = ModalAppTypeEnums.EditNonAuto;
    } else if (processMode == Enum.ProcessMode.NON_TPM_IMPORT) {
      responseResult.data = ModalAppTypeEnums.EditImport;
    } else {
      responseResult.code = ResponseCode.UNKNOWN;
      responseResult.message = '无效的处理方式!';
    }
  } else {
    if (processMode == Enum.ProcessMode.AUTO_ACCRUAL_AND_SETTLE) {
      responseResult.data = ModalAppTypeEnums.InsertAuto;
    } else if (processMode == Enum.ProcessMode.NONE_AUTO_ACCRUAL_AND_SETTLE) {
      responseResult.data = ModalAppTypeEnums.InsertNonAuto;
    } else if (processMode == Enum.ProcessMode.NON_TPM_IMPORT) {
      responseResult.data = ModalAppTypeEnums.InsertImport;
    } else {
      responseResult.code = ResponseCode.UNKNOWN;
      responseResult.message = '无效的处理方式!';
    }
  }
  return responseResult;
};

export type DetailForApplyProps = {
  pid?: string | number;
  contractType?: number;
  getHeaderInfo?: () => any;
  dataSource?: any[];
  onChange?: (dataSource: any) => void;
};

const SalesContractTermsDetails: React.FC<DetailForApplyProps> = (props) => {

  // Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const {contractType, getHeaderInfo, dataSource, onChange} = props;

  /**合同条款明细 */
  const [modalProps, setModalProps] = useState<WdiModalProps>({visible: false});

  const onClose = () => {
    setModalProps({visible: false, type: ModalAppTypeEnums.None});
  };

  const onPrevSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      const {processMode} = result.data.row;
      const responseModalResult = convertToModalType(processMode, false);
      if (responseModalResult.code !== ResponseCode.SUCCESS) {
        message.error(responseModalResult.message);
        return;
      }
      setModalProps({
        visible: true,
        title: '新建明细合同条款',
        type: responseModalResult.data,
        id: 0, //新增传0
        data: result.data,
      });
    }
  };

  const onEditSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      const row = result?.data;
      if (
        modalProps.type === ModalAppTypeEnums.EditAuto ||
        modalProps.type === ModalAppTypeEnums.EditApply ||
        modalProps.type === ModalAppTypeEnums.EditNonAuto ||
        modalProps.type === ModalAppTypeEnums.EditImport
      ) {
        const record = modalProps.data.row;
        const newItem = {...row};
        newItem.uid = record.uid;
        const newDataSouce = DataStore.edit(dataSource, newItem);
        onChange?.(newDataSouce);
      } else if (
        modalProps.type === ModalAppTypeEnums.InsertAuto ||
        modalProps.type === ModalAppTypeEnums.InsertApply ||
        modalProps.type === ModalAppTypeEnums.InsertNonAuto ||
        modalProps.type === ModalAppTypeEnums.InsertImport
      ) {
        const newItem = {...row};
        onChange?.(DataStore.add(dataSource, newItem));
      }
    }
    setModalProps({visible: false, type: ModalAppTypeEnums.None});
  };

  const onPreModal = () => {
    const headerInfo = getHeaderInfo?.();

    if (isContractType1(contractType) && (!headerInfo || !headerInfo.soldToCode)) {
      message.error('请先选择售达方');
      return;
    }

    if (isContractType2(contractType) && (!headerInfo || !headerInfo.custSysCode)) {
      message.error('请先选择客户集团');
      return;
    }

    setModalProps({
      visible: true,
      type: ModalAppTypeEnums.Picker,
      title: '新建明细合同条款',
      id: null,
      data: {
        row: null,
        rowIndex: -1,
        rows: dataSource,
        contractType: contractType,
        soldToCode: headerInfo.soldToCode,
        custSysCode: headerInfo.custSysCode,
      },
    });
  };

  useEffect(() => {
    const formDefID = isContractType2(contractType) ? 'SalesContractTerms2' : 'SalesContractTerms';
    GridApi.searchConfig({id: formDefID, allowModifyColumns: false}).then(response => {
      const settings: any = Object.assign({}, response.data, {
        rowKey: 'uid',
        actionsAlign: NsGrid.ActionsAlign.Left,
        showExpandedRow: true,
      });
      Utility.setActionCallback(
        settings.formSchema.actions,
        'update',
        async (grid: any, action: any, record: any) => {
          const context = grid.getContext();
          const ctype = context.params?.contractType;
          const headerInfo = context.params?.getHeaderInfo?.();
          if (isContractType1(ctype) && (!headerInfo || !headerInfo.soldToCode)) {
            message.error('请先选择售达方');
            return;
          }

          if (isContractType2(ctype) && (!headerInfo || !headerInfo.custSysCode)) {
            message.error('请先选择客户集团');
            return;
          }
          const {processMode, salesContractTermsId} = record;
          const responseModalResult = convertToModalType(processMode, true);
          if (responseModalResult.code !== ResponseCode.SUCCESS) {
            message.error(responseModalResult.message);
            return;
          }
          setModalProps({
            visible: true,
            title: '编辑明细合同条款',
            type: responseModalResult.data,
            id: salesContractTermsId,
            data: {
              row: record,
              rows: context.dataSource,
              rowIndex: DataStore.getRowIndex(context.dataSource, record.uid),
              contractType: ctype,
              soldToCode: headerInfo.soldToCode,
              custSysCode: headerInfo.custSysCode,
            }
          });
        },
      );

      Utility.setActionCallback(
        settings.formSchema.actions,
        'delete',
        async (grid: any, action: any, record: any) => {
          if (record && record.salesContractTermsId) {
            const responseResult = await SalesContractTerms.existsRefDetail(
              record.salesContractTermsId,
            );
            if (ResponseStatus.SUCCESS === responseResult.code) {
              if (Boolean(responseResult.data)) {
                message.error('该条款已经被引用，不能删除');
                return;
              }
            } else {
              message.error(responseResult.message);
              return;
            }
          }
          const context = grid.getContext();
          onChange?.(DataStore.del(context.dataSource, record.uid));
          message.success('删除成功');
        },
      );

      settings.formSchema.allFields.rowNumber.getDisplayFormater = (_t: any, record: any, index: any) => {
        return `${record.rowNumber || index + 1}`;
      };
      initFieldDisplayFormater(settings.formSchema.allFields);
      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.ecName.width = 150;
      settings.formSchema.allFields.processModeName.width = 100;
      settings.formSchema.allFields.revenueTypeName.width = 160;
      settings.formSchema.allFields.productNamesRebate.width = 300;
      settings.formSchema.allFields.settlementFrequencyName.width = 80;
      settings.formSchema.allFields.settlementRateDesc.width = 180;
      settings.formSchema.allFields.termsAmount.width = 100;
      settings.formSchema.allFields.rateOriginal.width = 100;
      settings.formSchema.allFields.productNamesReach.width = 100;
      settings.formSchema.allFields.createTypeName.width = 100;
      settings.formSchema.allFields.remark.width = 100;
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  let maxAllowedFields = ['rateOriginal', 'productNamesRebate', 'productNamesReach', 'remark'];
  if (contractType == Enum.SalesContractType.SELLOUT) {
    maxAllowedFields = [
      'rateOriginal',
      'productNamesRebate',
      'productNamesReach',
      'remark',
    ];
  }

  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return maxAllowedFields;
    },
  };

  return (
    <WdiPanel
      title={<WdiCardTitle iconType={Enum.IconType.CONTRACT_TERM} title="合同条款"/>}
      extra={
        <Button
          type="primary"
          onClick={() => {
            onPreModal()
          }}
        >
          新建
        </Button>
      }
    >
      {isLoaded && modalProps.type === ModalAppTypeEnums.Picker && (
        <SalesContractTermsPrevApp
          {...modalProps}
          onClose={onClose}
          onSave={onPrevSave}
        />
      )}
      {isLoaded &&
        (modalProps.type === ModalAppTypeEnums.InsertAuto ||
          modalProps.type === ModalAppTypeEnums.EditAuto) && (
          <SalesContractTermsAutoEditApp
            {...modalProps}
            onClose={onClose}
            onSave={onEditSave}
          />
        )}
      {isLoaded &&
        (modalProps.type === ModalAppTypeEnums.InsertNonAuto ||
          modalProps.type === ModalAppTypeEnums.EditNonAuto) && (
          <SalesContractTermsNonAutoEditApp
            {...modalProps}
            onClose={onClose}
            onSave={onEditSave}
          />
        )}
      {isLoaded &&
        (modalProps.type === ModalAppTypeEnums.InsertImport ||
          modalProps.type === ModalAppTypeEnums.EditImport) && (
          <SalesContractTermsEditApp
            {...modalProps}
            onClose={onClose}
            onSave={onEditSave}
          />
        )}
      {isLoaded && (
        <DetailGrid
          {...pageGridSettings}
          params={{contractType, getHeaderInfo}}
          autoColumn={['rowNumber']}
        />
      )}
    </WdiPanel>
  );
};
export default SalesContractTermsDetails;

