import {formatter, request} from "@/utils/request";

export namespace FormRs {
  export const Path = {
    get: "/activityapi/formRs/getFormRs",
    ReimbursementPrev: "/activityapi/formRs/reimbursementPrev"
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormRs>>(Path.get, formatter(options));
  }

  export async function ReimbursementPrev(body: API.ReimbursementPrev) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.ReimbursementPrev>>(Path.ReimbursementPrev, formatter(options));
  }

  export namespace Buttons {
    export const Path = {
      ValidateDetail: '/activityapi/rsButtons/validateDetail',
      Apply: '/activityapi/rsButtons/apply',
      Delete: '/activityapi/rsButtons/delete',
      Get: '/activityapi/rsButtons/get',
      WARNING_INFOS: '/activityapi/rsButtons/warningInfos',
      COUNT_OF_WARNING: '/activityapi/rsButtons/countOfWarning',
      DYNAMIC_INFOS: '/activityapi/rsButtons/dynamicInfos'
    };

    export async function get(body: API.ButtonArgs) {
      const options = {
        method: 'POST',
        body: {
          ...body
        }
      };

      return request<API.ResponseResult<API.Button>>(Path.Get, formatter(options));
    };


    export async function validateDetail(body: {
      rowIndex: number,
      formSp: any,
      formSpDetail: any,
      formSpDetails: any[],
    }) {
      const options = {
        method: 'POST',
        body: body
      };

      return request<API.ResponseResult<API.FormSpDetailApprovalView>>(Path.ValidateDetail, formatter(options));
    };

    export async function apply(body: {
      form: API.Form,
      formRs: API.FormRs,
      formRsDetails: API.FormRsDetail[]
    }) {
      const options = {
        method: 'POST',
        body: {
          ...body
        },
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.Apply, formatter(options));
    };

    export async function del(formId: number) {
      const options = {
        method: 'POST',
        body: {
          formId: formId
        },
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.Delete, formatter(options));
    };

    export async function warningInfos(formId: number) {
      const options = {
        method: 'GET',
        body: {
          formId: formId,
        },
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.WARNING_INFOS, formatter(options));
    }

    export async function countOfWarning(formId: number) {
      const options = {
        method: 'GET',
        body: {
          formId: formId,
        },
      };
      return request<API.ResponseResult<number>>(Path.COUNT_OF_WARNING, formatter(options));
    }

    export async function dynamicInfos(formId: number) {
      const options = {
        method: 'Get',
        body: {formId}
      };
      return request<API.DynamicInformation[]>(Path.DYNAMIC_INFOS, formatter(options));
    };

  }
}

export namespace FormRsDetail {
  const Path = {
    getDetailsForRebate: "/activityapi/formRs/getFormRsDetailsForRebate",
    getDetailForOninvoice: "/activityapi/formRs/getFormRsDetailsForOninvoice",
    getDetailForExpenseNonTpmId: '/activityapi/formRs/getDetailForExpenseNonTpmId'
  }

  export async function getDetailsForRebate(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormRsDetailRebateView[]>>(Path.getDetailsForRebate, formatter(options));
  }

  export async function getDetailForExpenseNonTpmId(id: string) {
    const options = {
      method: 'GET',
      body: {
        expenseNonTpmId: id
      },
    };
    return request<API.ResponseResult<any>>(Path.getDetailForExpenseNonTpmId, formatter(options));
  }

  export async function getDetailForOninvoice(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FormRsDetailOninvoiceView[]>>(Path.getDetailForOninvoice, formatter(options));
  }

}


