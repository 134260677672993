import {request, formatter} from '@/utils/request';

export namespace EC {
  export const Path = {
    Search: '/mdmapi/ec/search',
    SearchForEc3: '/mdmapi/ec/searchEc3',
    Get: '/mdmapi/ec/get',
    Delete: '/mdmapi/ec/delete',
    Post: '/mdmapi/ec/post',
    GetEcStructures: '/mdmapi/ec/getEcStructures',
    GetEcStructuresWithBohq: '/mdmapi/ec/getEcStructuresWithBohq',
    QueryForSheet: '/mdmapi/ec/queryForSheet',
    ExportEc: '/mdmapi/ec/exportEc'
  }

  export async function searchEc3(body: API.EcItemSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.EC[]>>(Path.SearchForEc3, formatter(options));
  }

  export async function search(body: API.EcSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.EC[]>>(Path.Search, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.EC>>(Path.Get, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }

  export async function post(body: API.EC) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.EC>>(Path.Post, formatter(options));
  }

  export async function getPageTypes(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.PageType[]>>('/mdmapi/ec/getPageTypes', formatter(options));
  }


  export async function getPaymentMethods(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.DataOptions[]>>('/mdmapi/ec/getPaymentMethods', formatter(options));
  }

  export async function getExtendsBTs(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.DataOptions[]>>('/mdmapi/ec/getExtendsBts', formatter(options));
  }

  export async function getEcFieldConfigs() {
    const options = {
      method: 'GET',
      body: {},
    };
    return request<API.ResponseResult<API.EcFieldConfig[]>>('/mdmapi/ec/getEcFieldConfigs', formatter(options));
  }

  export async function getEcFieldConfigsByMetaType(metaType?: number) {
    const options = {
      method: 'GET',
      body: {
        metaType: metaType,
      },
    };
    return request<API.ResponseResult<API.EcFieldConfig[]>>('/mdmapi/ec/getEcFieldConfigsByMetaType', formatter(options));
  }

  export async function GetEcStructures(ecLevel?: number, pageTypeId?: number) {
    const options = {
      method: 'GET',
      body: {
        ecLevel: ecLevel,
        pageTypeId: pageTypeId
      },
    };
    return request<API.ResponseResult<API.EcStructure[]>>(Path.GetEcStructures, formatter(options));
  }

  export async function GetEcStructuresWithBohq(body: API.EcStructureSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.GetEcStructuresWithBohq, formatter(options));
  }

  export async function queryForSheet(body: { ecLevel?: number, pageTypeId?: number, bohqId?: number, budgetProjectId?: number }) {
    const options = {
      method: 'GET',
      body: body,
    };
    return request<API.ResponseResult<API.EcStructure[]>>(Path.QueryForSheet, formatter(options));
  }

  export async function exportEc() {
    const options = {
      method: 'GET',
      body: {},
    };
    return request<API.ResponseResult<Array<any>>>(Path.ExportEc, formatter(options));
  }
}
