import { useState } from 'react'

//打包分离 避免引入antv库
export const uuidv4 = () => {
  return (
    ([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g,
      (c: any) =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}


export interface DataStore {
  // 初始化数, 为所有数据添加uid
  init: (dataSource: any[] | undefined) => any[];
  get: (dataSource: any[] | undefined, index: number) => any;
  getByUid: (dataSource: any[] | undefined, uid: string) => any;
  getRowIndex: (dataSource: any[] | undefined, uid: string) => number;
  edit: (dataSource: any[] | undefined, data: any) => any[];
  add: (dataSource: any[] | undefined, data: any) => any[];
  addMulti: (dataSource: any[] | undefined, source: any[] | undefined) => any[];
  del: (dataSource: any[] | undefined, uid: string) => any[];
}

const api: DataStore = {
  init: (dataSource: any[] | undefined) => {
    if (!dataSource) {
      return [];
    }
    return dataSource.map(item => {
      if (item.uid === undefined || item.uid === null) {
        item.uid = uuidv4();
      }
      return item;
    })
  },
  get: (dataSource: any[] | undefined, index: number) => {
    if (dataSource && index >= 0 && dataSource.length > index) {
      return dataSource[index];
    }
    return;
  },
  getByUid: (dataSource: any[] | undefined, uid: string) => {
    if (dataSource) {
      return dataSource.find(item => item.uid == uid);
    }
    return;
  },
  getRowIndex: (dataSource: any[] | undefined, uid: string) => {
    if (dataSource) {
      return dataSource.findIndex(item => item.uid == uid);
    }
    return -1;
  },
  add: (dataSource: any[] | undefined, data: any) => {
    const newDataSource: any[] = (dataSource || []).slice(0);
    if (data) {
      data.uid = uuidv4();
      newDataSource.push(data);
    }
    return newDataSource;
  },
  addMulti: (dataSource: any[] | undefined, source: any[] | undefined) => {
    const newDataSource: any[] = (dataSource || []).slice(0);
    if (source) {
      source.forEach(item => {
        item.uid = uuidv4();
        newDataSource.push(item);
      })
    }
    return newDataSource;
  },
  edit: (dataSource: any[] | undefined, data: any) => {
    const newDataSource: any[] = [];
    if (dataSource) {
      dataSource.forEach(item => {
        if (item.uid == data.uid) {
          newDataSource.push(data);
        } else {
          newDataSource.push(item);
        }
      })
    }
    return newDataSource;
  },
  del: (dataSource: any[] | undefined, uid: string) => {
    const newDataSource: any[] = [];
    if (dataSource) {
      dataSource.forEach(item => {
        console.log(item, uid);
        if (item.uid != uid) {
          newDataSource.push(item);
        }
      })
    }
    return newDataSource;
  }
}

export default api;

export const useDataStore = (initState?: any) => {
  const [value, setValue] = useState(() => {
    if (!initState) {
      return []
    }
    return initState;
  });
  const boundMethods: any = {
    init: (dataSource: any[] | undefined) => {
      setValue(api.init(dataSource));
    },
    add: (data: any) => {
      setValue(api.add(value, data))
    },
    addMulti: (source: any[] | undefined) => {
      setValue(api.addMulti(value, source))
    },
    edit: (data: any) => {
      setValue(api.edit(value, data))
    },
    del: (uid: string) => {
      setValue(api.del(value, uid))
    },
    getAll: () => {
      return value;
    },
    get: (index: number) => {
      return api.get(value, index);
    },
    getByUid: (uid: string) => {
      return api.getByUid(value, uid);
    }
  }
  return [value, boundMethods];
};
