import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Input, DatePicker, message, Row } from 'antd';
import { ProForm, ProFormInstance } from '@ant-design/pro-form';
import { WdiModalAppResult, WdiCardTitle } from '@/components/WdiControls';
import { FormPageProps } from '@/components/WdiBus/FormPage';
import { ApplyFooter, ApplyFooterType } from '@/components/WdiBus/ApplyFooter';
import moment from 'moment';
import { FileUploadUtility, WdiFileUpload } from '@/components/WdiControls/WdiFileUpload';
import { Enum } from '@/enums/system';
import { ResponseStatus } from '@/utils/request';
import { initApplyPageContext, ViewEntityProps } from '../../components/data';
import DataStore from '@/components/GridWidget/DataStore'
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { FormSaDetailsForApplyPanel } from './details';
import SAWarningInfoApp from '../../components/WarningInformation';
import { FormSa, FormSaDetail } from '@/services/sales/sa';
import { WarningInfoAppProps } from '@/components/WdiBus/PanelForWarning';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const getPageResult = (values: any, viewEntity: ViewEntityProps, statusId: number, dataSource: any[]) => {
  const result: WdiModalAppResult = { code: ResponseStatus.UNKNOWN };

  const { activityDate, supplyDate, fileInfo, ...entity } = values;

  const req: { form: any; formSa: any; formSaDetails: any[] } = {
    form: {
      statusId: statusId,
      pageTypeId: viewEntity.pageTypeId,
      formTypeId: viewEntity.formTypeId,
      bohqId: viewEntity.bohqId,
      // budgetProjectId: viewEntity?.budgetProject?.budgetProjectId,
      userId: viewEntity?.userId,
      positionId: viewEntity?.positionId
    },
    formSa: {},
    formSaDetails: [],
  };

  entity.bohqId = viewEntity.bohqId;
  
  if (viewEntity.formId) {
    //草稿打开
    req.form.formId = viewEntity.formId;
  } else if (viewEntity.relatedFormId) {
    if (viewEntity.relationType === Enum.FormRelationType.REJECTED
      || viewEntity.relationType === Enum.FormRelationType.MODIFIED) {
      // 退回待修改,修改单据
      req.form.relatedFormId = viewEntity.relatedFormId;
      req.form.relationType = viewEntity.relationType;
    }
    req.form.formId = 0;
  }

  if (activityDate && activityDate.length == 2) {
    entity.activityStartDate = moment(activityDate[0]).format("YYYY-MM-DD");
    entity.activityEndDate = moment(activityDate[1]).format("YYYY-MM-DD");
  } else {
    result.message = "无效的活动期间";
    return result;
  }
  if (supplyDate && supplyDate.length == 2) {
    entity.supplyStartDate = moment(supplyDate[0]).format("YYYY-MM-DD");
    entity.supplyEndDate = moment(supplyDate[1]).format("YYYY-MM-DD");
  } else {
    result.message = "无效的供货期间";
    return result;
  }

  const info = FileUploadUtility.serialize(fileInfo);
  entity.fileName = info?.names;
  entity.filePath = info?.urls;

  req.formSa = entity;
  if (dataSource && dataSource.length) {
    dataSource.forEach((item, itemIndex) => {
      item.rowNumber = itemIndex + 1;
    });
  } else {
    result.message = "请填写费用明细!";
    return result;
  }

  req.formSaDetails = dataSource;
  result.data = req;
  result.code = ResponseStatus.SUCCESS;
  return result;
};

const DealerActivityApplyApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const [entity, setEntity] = useState<ViewEntityProps>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [warningSettings, setWarningSettings] = useState<WarningInfoAppProps>({ visible: false });
  const [formSaDate, setFormSaDate] = useState<{ activityStartDate?: Date, activityEndDate?: Date, supplyStartDate?: Date, supplyEndDate?: Date }>();

  const onDetailsChange = (data: any) => {
    setDataSource(data);
  };

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApplyPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;

        if (viewEntity.formSa) {
          formRef.current?.setFieldsValue({
            activityName: viewEntity.formSa?.activityName,
            activityDate: [moment(viewEntity.formSa?.activityStartDate), moment(viewEntity.formSa?.activityEndDate)],
            supplyDate: [moment(viewEntity.formSa?.supplyStartDate), moment(viewEntity.formSa?.supplyEndDate)],
            remark: viewEntity.formSa?.remark,
            fileInfo: FileUploadUtility.parse(viewEntity.formSa?.fileName, viewEntity.formSa?.filePath),
          });

          setFormSaDate({
            activityStartDate: viewEntity.formSa.activityStartDate,
            activityEndDate: viewEntity.formSa.activityEndDate,
            supplyStartDate: viewEntity.formSa.supplyStartDate,
            supplyEndDate: viewEntity.formSa.supplyEndDate
          });
        }
       
        setEntity(viewEntity);

        if (viewEntity.formSa?.formSaId) {
          FormSaDetail.getsEx(viewEntity.formSa?.formSaId).then((response) => {
            setDataSource(DataStore.init(response.data));
          });
        } else {
          setDataSource(DataStore.init([]));
        }
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = "活动申请";
      if (entity.formSa) {
        title += '【编辑】'
      }
      props.setPageTitle(title);
    }
  }, [props.setPageTitle, entity]);

  return (
    <>
      <SAWarningInfoApp {...warningSettings} />
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (
              <ApplyFooter
                viewEntity={entity}
                setLoading={props.setLoading}
                submitBackUrl="/sales/sa/myactivity"
                doSubmit={async (type: number, forceSubmit: boolean) => {
                  if (!entity || !dataSource) {
                    return;
                  }
                  const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                  if (values && values.errorFields && values.errorFields.length) {
                    return;
                  }
                  const statusId = type === ApplyFooterType.Draft ? Enum.FormStatus.DRAFT : Enum.FormStatus.AWAITING;
                  const req = getPageResult(values, entity, statusId, dataSource);
                  if (req.code !== ResponseStatus.SUCCESS) {
                    message.error(req.message);
                    return;
                  }
                  props?.setLoading?.(true);
                  if (type === ApplyFooterType.Validate) {
                    return await FormSa.Buttons.validate(req.data);
                  } else {
                    return await FormSa.Buttons.apply({
                      forceSubmit: forceSubmit,
                      ...req.data
                    });
                  }
                }}
                del={async () => {
                  if (!entity || !entity.formId) {
                    return;
                  }
                  return await FormSa.Buttons.del(entity.formId);
                }}
                showWarning={(result: WdiModalAppResult, submitCallback: any) => {
                  setWarningSettings({
                    visible: true,
                    title: "警示信息",
                    wdiPanelList: result.data,
                    onClose: () => {
                      setWarningSettings({ visible: false });
                    },
                    onSave: async () => {
                      return await submitCallback();
                    },
                  });
                }}
              />
            )
          }
        }}
      >
        <WdiPanel title={<WdiCardTitle title='活动信息' />} >
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="activityName" label="活动名称" rules={[{ required: true, message: '活动名称不能为空!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item required name="activityDate" label="活动期间" rules={[{ required: true, message: '活动期间不能为空!' }]}>
                <RangePicker onChange={(values) => {
                  const dateRange = { activityStartDate: '', activityEndDate: '', supplyStartDate: '', supplyEndDate: '' };
                  if (values && values.length === 2) {
                    formRef.current?.setFieldsValue({ supplyDate: values });
                    dateRange.activityStartDate = moment(values[0]).format("YYYY-MM-DD");
                    dateRange.activityEndDate = moment(values[1]).format("YYYY-MM-DD");
                    dateRange.supplyStartDate = moment(values[0]).format("YYYY-MM-DD");
                    dateRange.supplyEndDate = moment(values[1]).format("YYYY-MM-DD");
                  }
                  setFormSaDate(Object.assign({}, formSaDate, dateRange));
                }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item required name="supplyDate" label="供货期间" rules={[{ required: true, message: '供货期间不能为空!' }]}>
                <RangePicker onChange={(values) => {
                  const dateRange = { supplyStartDate: '', supplyEndDate: '' };
                  if (values && values.length === 2) {
                    dateRange.supplyStartDate = moment(values[0]).format("YYYY-MM-DD");
                    dateRange.supplyEndDate = moment(values[1]).format("YYYY-MM-DD");
                  }
                  setFormSaDate(Object.assign({}, formSaDate, dateRange));
                }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16}>
              <Form.Item name="remark" label="方案简述">
                <TextArea rows={4} maxLength={2000} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="fileInfo"
                label="附件"
                valuePropName='fileList'
                getValueFromEvent={(e: any) => { return e.fileList || []; }}
              >
                <WdiFileUpload leftTopInfo="请选择文件并上传" />
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <FormSaDetailsForApplyPanel
          data={{
            formSa: {
              pageTypeId: entity?.pageTypeId,
              formTypeId: entity?.formTypeId,
              bohqId: entity?.bohqId,
              //budgetProjectId: entity?.budgetProjectId,
              ...formSaDate
            },
            ecFieldConfigs: entity?.ecFieldConfigs
          }}
          dataSource={dataSource}
          onChange={onDetailsChange}
        />
      </ProForm>
    </>
  );
};
export default DealerActivityApplyApp;
