import { ParamsProps } from '@/components/WdiBus/FormPage';
import { Enum } from '@/enums/system';
import { ResponseStatus } from '@/utils/request';
import { WdiModalAppResult } from '@/components/WdiControls';
import { FormApi } from '@/services/sales';
import { FormSp, FormSpDetail } from '@/services/sales/sp';
import { SoldTo } from '@/services/mdm/soldto';

export type ViewEntityProps = {
  form?: API.Form,
  formSp?: API.FormSp,
  // paymentMethod?: API.DataOptions,
  // supplier?: API.Supplier,
  formSpDetails?: API.FormSpDetail[],
  // vatType?: API.VatType;
  soldTo?: API.SoldTo,
  relationType?: number,
  relatedFormId?: number,
  formId?: number;
  statusId?: number;
  formTypeId?: number;
  pageTypeId?: number;
  // paymentMethodId?: number,
  soldToCode?: string,
  // supplierId?: number,
  reimburseType?: number,
  invoiceStatuss?: API.DataOptions[],
  userId?: number;
  positionId?: number;
  deptId?: number;
}

export const initApplyPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId || params.relatedFormId) {
    // 退回待修改
    const formResponse = await FormApi.get(params.formId || params.relatedFormId || 0);
    viewEntity.form = formResponse.data;
    if (!viewEntity.form) {
      result.message = "无效的退回待修改单";
      return result;
    }
    if (params.formId) {
      viewEntity.formId = params.formId;
    } else {
      viewEntity.relationType = Enum.FormRelationType.REJECTED;
      viewEntity.relatedFormId = params.relatedFormId;
    }
    const formSPResponse = await FormSp.get(viewEntity.form.formId);
    viewEntity.formSp = formSPResponse.data;
    viewEntity.reimburseType = viewEntity.formSp.reimburseType;
    viewEntity.soldToCode = viewEntity.formSp.soldToCode
    // viewEntity.paymentMethodId = viewEntity.formSp.paymentMethodId;
    // viewEntity.supplierId = viewEntity.formSp.supplierId;
    viewEntity.userId = viewEntity.form.userId;
    viewEntity.positionId = viewEntity.form.positionId;
    viewEntity.deptId = viewEntity.form.deptId;
    viewEntity.pageTypeId = viewEntity.form.pageTypeId;
    viewEntity.formTypeId = viewEntity.form.formTypeId;
  } else {
    // 直接申请
    // viewEntity.paymentMethodId = params.paymentMethodId;
    // viewEntity.supplierId = params.supplierId;
    viewEntity.soldToCode = params.soldToCode;
    viewEntity.pageTypeId = params.pageTypeId;
    viewEntity.formTypeId = params.formTypeId;
    viewEntity.reimburseType = params.reimburseType;
    viewEntity.userId = params.userId;
    viewEntity.positionId = params.positionId;
    viewEntity.deptId = params.deptId;
  }

  if (viewEntity.formId) {
    const formSPDetailsResponse = await FormSpDetail.getsForExpenseNonTpm(viewEntity.formId);
    viewEntity.formSpDetails = formSPDetailsResponse.data;
  } else if (viewEntity.relatedFormId) {
    const formSPDetailsResponse = await FormSpDetail.getsForExpenseNonTpm(viewEntity.relatedFormId);
    viewEntity.formSpDetails = formSPDetailsResponse.data;
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
}

export const initApprovalPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId) {
    const formSPResponse = await FormSp.get(params.formId);
    viewEntity.formSp = formSPResponse.data;
    if (!viewEntity.formSp) {
      result.message = "无效的单据";
      return result;
    }
    const formResponse = await FormApi.get(params.formId);
    viewEntity.form = formResponse.data;

    if (viewEntity.formSp.soldToCode) {
      const soldTo = await SoldTo.get(viewEntity.formSp.soldToCode);
      viewEntity.soldTo = soldTo?.data;
    }

    const formSPDetailsResponse = await FormSpDetail.getsForExpenseNonTpm(params.formId);
    viewEntity.formSpDetails = formSPDetailsResponse.data;


  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
};

