import {wdiCore, WdiModalAppProps} from "@/components/WdiControls";
import React, {useEffect, useRef, useState} from "react";
import {WdiModal} from "@/components/WdiControls/WdiModalForm";
import {Button} from "antd";
import {GridApi} from "@/services/gridapi";
import {NsGrid} from "@/components/GridWidget/GridDefProps";
import {FilterUtility, PageGrid, Utility} from "@/components/GridWidget";
import {SoldTo} from "@/services/mdm/soldto";
import {toExcel} from "@/components/WdiControls/WdiExcel";
import {SA} from "@/services/report/sa";
import {CustSys} from "@/services/mdm/custsys";


const FormSaDetailSelect: React.FC<WdiModalAppProps> = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  //获取GridWdiget实例
  const gridRef: any = useRef();
  const getGridRef = (el: any) => {
    gridRef.current = el;
  };
  useEffect(() => {
    Promise.all([GridApi.searchConfig({
      id: 'FormSaDetailSelect',
      allowModifyColumns: true
    })]).then((dataArray: any[]) => {
      const responseResult = dataArray[0];
      const bohqId = props.data.bohqId;
      const pageTypeId = props.data.pageTypeId;
      const data = responseResult.data;
      const settings: any = Object.assign({}, data, {
        dataLoading: async (params: any) => {
          return await SA.queryForPicker(Object.assign({}, params));
        },
        rowKey: 'formSaDetailId',
        actionsAlign: NsGrid.ActionsAlign.None,
        filterFormat: NsGrid.FilterFormat.Default,
        viewMode: NsGrid.ViewMode.Simple,
        antdProps: {
          pagination: {
            pageSize: 10,
            current: 1,
            showSizeChanger: false
          }
        },
        fixedQueryValues: [
          {
            title: "bohqId",
            field: "bohqId",
            type: "Number",
            opt: NsGrid.FilterOptType.EQ,
            value: bohqId
          },
          {
            title: "pageTypeId",
            field: "pageTypeId",
            type: "Number",
            opt: NsGrid.FilterOptType.EQ,
            value: pageTypeId
          },
        ]
      });
      settings.formSchema.allFields.activityStartDate.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.Date.Join(record.activityStartDate, record.activityEndDate);
      }
      settings.formSchema.allFields.soldTo.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: async (params: any) => {
          return await SoldTo.ajaxSearch(Object.assign({}, params)).then(res => {
            return res.data?.map(item => {
              return {
                value: item.soldToCode,
                label: item.codeOrName,
              }
            })
          });
        },
        defaultParamKey: 'soldToCodes',
        remote: true
      });
      settings.formSchema.allFields.custSys.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: async (params: any) => {
          return await CustSys.ajaxSearch(Object.assign({}, params)).then(res => {
            return res.data?.map(item => {
              return {
                value: item.custSysCode,
                label: item.codeOrName,
              }
            })
          });
        },
        defaultParamKey: 'custSysCodes',
        remote: true
      });

      Utility.setActionCallback(settings.formSchema.toolbarActions, 'Export',
        (grid: any, action: any) => {
          if (grid && grid.getGridArgs) {
            const jqxGridArgs = grid.getGridArgs();
            const productColumns = [
              {name: 'formSaDetailId', displayName: '活动明细编号*'},
              {name: 'formNo', displayName: '活动单号*'},
              {name: 'activityName', displayName: '活动名称'},
              {name: 'saStartDate', displayName: '活动开始日期'},
              {name: 'saEndDate', displayName: '活动截止日期'},
              {name: 'staffName', displayName: '申请人'},
              {name: 'soldToCode', displayName: '售达方编号'},
              {name: 'soldToName', displayName: '售达方名称'},
              {name: 'custSysCode', displayName: '客户集团编号'},
              {name: 'custSysName', displayName: '客户集团名称'},
              {name: 'platformName', displayName: '分子平台'},
              {name: 'districtName', displayName: '省份'},
              {name: 'productBuyNames', displayName: '产品别'},
              {name: 'ecName', displayName: '费用项'},
              {name: 'promotionDesc', displayName: '活动机制'},
              {name: 'unitExpense1', displayName: '一促单听补贴'},
              {name: 'quantityForecast1', displayName: '一促预估销量'},
              {name: 'unitExpense2', displayName: '二促单听补贴'},
              {name: 'quantityForecast2', displayName: '二促预估销量'},
              {name: 'unitExpenseSellin', displayName: 'sellin单听补贴'},
              {name: 'quantityForecastSellin', displayName: 'sellin预估销量'},
              {name: 'amount', displayName: '申请金额'},
              {name: 'quantityActual', displayName: '系统计算实际销量'},
              {name: 'salesTypeName', displayName: '销量类型'},
              {name: 'amountSp', displayName: '核销金额(非price类则必填)'},
              {name: 'remarkSp', displayName: '核销备注(用户填写)'},
              {name: 'quantityActual1', displayName: '一促实际销量(用户填写)'},
              {name: 'quantityActualAmount1', displayName: '一促实际单听补贴(用户填写)'},
              {name: 'quantityActual2', displayName: '二促实际销量(用户填写)'},
              {name: 'quantityActualAmount2', displayName: '二促实际单听补贴(用户填写)'},
            ];

            SA.exportFormSaDetailsForPicker(Object.assign({}, jqxGridArgs)).then((response) => {
              const productSheet = {
                data: response?.data,
                names: productColumns,
                sheetName: '活动明细',
                cells: {}
              };
              toExcel({
                name: '需要核销的客户投资类活动明细_' + wdiCore.Date.ToString(new Date()) + '.xlsx',
                sheetProps: [productSheet],
              });
            });
          }
        },
      );

      settings.formSchema.allFields.formNo.width = 150;
      settings.formSchema.allFields.activityName.width = 200;
      settings.formSchema.allFields.pageTypeName.width = 200;
      settings.formSchema.allFields.soldTos.width = 250;
      settings.formSchema.allFields.custSyss.width = 250;
      settings.formSchema.allFields.activityStartDate.width = 180;
      settings.formSchema.allFields.amount.width = 100;
      settings.formSchema.allFields.submitDate.width = 150;
      settings.formSchema.allFields.statusName.width = 100;
      settings.formSchema.allFields.approvedDate.width = 150;
      settings.formSchema.allFields.ec1s.width = 150;
      settings.formSchema.allFields.ec2s.width = 150;
      settings.formSchema.allFields.ec3s.width = 150;
      settings.formSchema.allFields.staffName.width = 100;

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, [props.data]);
  return (
    <>
      <WdiModal
        title={props.title}
        visible={props.visible}
        width={1400}
        footer={[
          <Button key='onCancel' onClick={() => {
            props.onClose?.();
          }}>
            关闭
          </Button>,
        ]}
        onCancel={() => {
          props.onClose ? props.onClose() : null;
        }}
        maskClosable={false}
        destroyOnClose
      >
        {isLoaded && <PageGrid {...gridSettings} getInstance={getGridRef}/>}
      </WdiModal>
    </>
  )
}
export default FormSaDetailSelect;
