import {formatter, request} from "@/utils/request";


export namespace SalesContract {
  const Path = {
    Query: '/contractapi/contract/query',//查询合同页面数据
    Export: '/contractapi/contract/export',//导出Excel
    Get: '/contractapi/contract/get',
    Post: '/contractapi/contract/post',
    Delete: '/contractapi/contract/delete',
    Deactive: "/contractapi/contract/deactive",
    Confirm: "/contractapi/contract/confirm"
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.QueryForSalesContractData>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.QueryForSalesContractData>>(Path.Export, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.SalesContract>>(Path.Get, formatter(options));
  }

  export async function post(body: { salesContract: API.SalesContract, salesContractTermsList: API.SalesContractTermsVo[] }) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'POST',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }

  export function deactive(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<any>>(Path.Deactive, formatter(options));
  }

  export function confirm(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<any>>(Path.Confirm, formatter(options));
  }
}

