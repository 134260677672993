import React, { useEffect, useState } from 'react';
import type { FormInstance } from 'antd';
import { Button, Checkbox, DatePicker, Form, Input, message, Progress, Space, Steps, Typography } from 'antd';
import { formatter, request, ResponseStatus } from '@/utils/request';
import download from '@/utils/download';
import type { WdiUploadProps } from './WdiFileUpload';
import { FileUploadUtility, WdiFileUpload } from './WdiFileUpload';
import { WdiModal } from './WdiModalForm';
import { wdiCore } from './wdiCore';
import moment from 'moment';
import type { WdiModalAppProps } from '.';
import { WdiTable } from './WdiTable';
import { WdiSelect } from './WdiSelect';
import type { ToExcelProps } from './WdiExcel';
import { toExcel } from './WdiExcel';
import { Enum } from '@/enums/system';

export type ValidateResult = {
  // { isOk: true, message: null }
  status: number;
  message: string | null;
};

export type ImportRowProps = {
  required: boolean;
  isReadOnly: boolean;
  key: string;
  uid: string; // 控件赋值的名称
  init?: (props: any) => any;
  format?: (props: any) => any;
  validate?: (props: any, form: FormInstance) => ValidateResult;
  get?: (props: any, form: FormInstance) => any;
};

type ImportTemplateFileInfoProps = {
  parameter: any;
  fileName: string;
  filePath: string;
  // 下载注入的JS函数
  jsFunc: string;
  method: string;
};

type ImportTemplateProps = {
  title: string;
  templateFileInfos: ImportTemplateFileInfoProps[];
  events?: any;
  getParameters?: () => void;
} & ImportRowProps;

const ImportTemplate: React.FC<ImportTemplateProps> = (props) => {
  return (
    <Space size="large">
      {props.title && <Typography.Text>{props.title}</Typography.Text>}
      {props.templateFileInfos?.map((item, itemIndex) => {
        return (
          <Typography.Link
            onClick={() => {
              if (props.events && item.jsFunc) {
                const func = props.events[item.jsFunc];
                if (func) {
                  const params = props?.getParameters?.();
                  func(params);
                }
              } else if (item.filePath) {
                download(item.filePath, formatter({ method: item.method, body: item.parameter }));
              }
            }}
            key={itemIndex}
          >
            {item.fileName}
          </Typography.Link>
        );
      })}
    </Space>
  );
};

type UploadInfoProps = {
  fileName: string | null;
  filePath: string | null;
  sheetName: string | null;
  startRowNum: number;
};

type ImportAttachmentProps = {
  uploadTextInfo: string;
  width: number;
  accept: string;
  allowMultiFilesUpload: boolean;
  addToAttachment: boolean;
  parameterName: string;
  fileInfo: UploadInfoProps;
} & ImportRowProps;

const ImportAttachment = {
  init: (props: ImportAttachmentProps) => {
    const newProps: WdiUploadProps = {
      addToAttachment: props.addToAttachment,
    };
    if (props.accept) {
      newProps.accept = props.accept;
    }
    if (props.allowMultiFilesUpload) {
      newProps.multiple = true;
    } else {
      newProps.maxCount = 1;
    }
    if (props.uploadTextInfo) {
      newProps.leftTopInfo = props.uploadTextInfo;
    }
    return newProps;
  },
  format: (props: ImportAttachmentProps) => {
    const fileInfo = props.fileInfo;
    return FileUploadUtility.parse(fileInfo.fileName, fileInfo.filePath);
  },
  validate: (props: ImportAttachmentProps, form: FormInstance) => {
    const validateResult: ValidateResult = { status: ResponseStatus.SUCCESS, message: null };
    const fileList = form.getFieldValue(props.uid);
    //是否有文件正在上传
    if (FileUploadUtility.isUploading(fileList)) {
      validateResult.status = ResponseStatus.UNKNOWN;
      validateResult.message = '文件正在上传';
      return validateResult;
    }

    //是否有文件正在上传
    if (FileUploadUtility.isError(fileList)) {
      validateResult.status = ResponseStatus.UNKNOWN;
      validateResult.message = '文件上传失败';
      return validateResult;
    }

    if (props.required) {
      if (fileList && fileList.length) {
      } else {
        validateResult.status = ResponseStatus.UNKNOWN;
        validateResult.message = '请先上传文件！';
        return validateResult;
      }
    }

    return validateResult;
  },
  get: (props: ImportAttachmentProps, form: FormInstance) => {
    const fileList = form.getFieldValue(props.uid);
    const fileInfo: UploadInfoProps = {
      fileName: null,
      filePath: null,
      sheetName: props.fileInfo.sheetName,
      startRowNum: props.fileInfo.startRowNum,
    };
    const files = FileUploadUtility.serialize(fileList);
    if (files && files.names && files.urls) {
      fileInfo.fileName = files.names;
      fileInfo.filePath = files.urls;
    }
    let parameterName = 'fileInfo';
    if (props.parameterName) {
      parameterName = props.parameterName;
    }
    const result: any = {};
    result[parameterName] = JSON.stringify(fileInfo);
    return result;
  },
};

type InputControlProps = {
  type: string;
  title: string | null;
  value: string | null;
  text: string | null;
  name: string;
} & ImportRowProps;

const InputControl = {
  init: (props: InputControlProps, form: FormInstance) => {
    const newProps: any = {};
    if (props.type === 'checkbox') {
    } else {
      if (props.isReadOnly) {
        newProps.readOnly = true;
      }
    }
    if (props.value) {
      form.setFieldsValue({
        [props.uid]: props.value,
      });
    }
    return newProps;
  },
  format: (props: InputControlProps) => {
    let value: any;
    if (props.type === 'checkbox') {
      value = wdiCore.String.EqIngoreCase('true', props.value);
    } else {
      value = props.value;
    }
    return value;
  },
  validate: (props: InputControlProps, form: FormInstance) => {
    const validateResult: ValidateResult = { status: ResponseStatus.SUCCESS, message: null };
    const value = form.getFieldValue(props.uid);
    if (props.required) {
      if (props.type === 'checkbox') {
      } else {
        if (wdiCore.String.IsNullOrEmpty(value)) {
          validateResult.status = ResponseStatus.UNKNOWN;
          validateResult.message = props.title + '不能为空！';
          return validateResult;
        }
      }
    }
    return validateResult;
  },
  get: (props: InputControlProps, form: FormInstance) => {
    const value = form.getFieldValue(props.uid);
    const result: any = {};
    result[props.name] = value;
    return result;
  },
};
type SelectControlProps = {
  type: string;
  title: string | null;
  value: string | null;
  text: string | null;
  name: string;
  multiple: boolean;
} & ImportRowProps;

const SelectControl = {
  init: (props: SelectControlProps, form: FormInstance) => {
    const newProps: any = {};
    // if (props.type === 'checkbox') {
    // } else {
    //   if (props.isReadOnly) {
    //     newProps.readOnly = true;
    //   }
    // }
    if (props.isReadOnly) {
      newProps.disabled = true;
    }
    if (props.multiple) {
      newProps.mode = "multiple";
    }
    if (props.value) {
      form.setFieldsValue({
        [props.uid]: props.value,
      });
    }
    return newProps;
  },
  format: (props: InputControlProps) => {
    const value: any = props.value;
    return value;
  },
  validate: (props: InputControlProps, form: FormInstance) => {
    const validateResult: ValidateResult = { status: ResponseStatus.SUCCESS, message: null };
    const value = form.getFieldValue(props.uid);
    if (props.required) {
      // if (props.type === 'checkbox') {
      // } else {
      //   if (wdiCore.String.IsNullOrEmpty(value)) {
      //     validateResult.status = ResponseStatus.UNKNOWN;
      //     validateResult.message = props.title + "不能为空！";
      //     return validateResult;
      //   }
      // }
      if (wdiCore.String.IsNullOrEmpty(value)) {
        validateResult.status = ResponseStatus.UNKNOWN;
        validateResult.message = props.title + '不能为空！';
        return validateResult;
      }
    }
    return validateResult;
  },
  get: (props: InputControlProps, form: FormInstance) => {
    const value = form.getFieldValue(props.uid);
    const result: any = {};
    result[props.name] = value;
    return result;
  },
};

type YearControlProps = {
  title: string | null;
  value: number | null;
  maxValue: number | null;
  minValue: number | null;
  name: string;
} & ImportRowProps;

const YearControl = {
  init: (props: YearControlProps, form: FormInstance) => {
    const newProps: any = {
      mode: 'year',
      picker: 'year',
      format: 'YYYY',
    };

    newProps.disabledDate = (current: any) => {
      if (current) {
        const period = Number(current.format('YYYY'));
        if (props.minValue && period < props.minValue) {
          return true;
        }
        if (props.maxValue && period > props.maxValue) {
          return true;
        }
      }
      return false;
    };
    if (props.value) {
      form.setFieldsValue({
        [props.uid]: moment(String(props.value), 'YYYY'),
      });
    }
    return newProps;
  },
  format: (props: YearControlProps) => {
    let value: any;
    if (props.value) {
      value = moment(String(props.value), 'YYYY');
    }
    return value;
  },
  validate: (props: YearControlProps, form: FormInstance) => {
    const validateResult: ValidateResult = { status: ResponseStatus.SUCCESS, message: null };
    const value = form.getFieldValue(props.uid);
    if (props.required) {
      if (wdiCore.Object.IsNullOrUndefined(value)) {
        validateResult.status = ResponseStatus.UNKNOWN;
        validateResult.message = props.title + '不能为空！';
        return validateResult;
      }
    }
    return validateResult;
  },
  get: (props: YearControlProps, form: FormInstance) => {
    const value = form.getFieldValue(props.uid);
    const result: any = {};
    if (wdiCore.Object.IsNullOrUndefined(value)) {
    } else {
      result[props.name] = value.format('YYYY');
    }
    return result;
  },
};

type PeriodControlProps = {
  title: string | null;
  value: number | null;
  maxValue: number | null;
  minValue: number | null;
  name: string;
} & ImportRowProps;

const PeriodControl = {
  init: (props: PeriodControlProps, form: FormInstance) => {
    const newProps: any = {
      picker: 'month',
      format: 'YYYYMM',
    };

    newProps.disabledDate = (current: any) => {
      if (current) {
        const period = Number(current.format('YYYYMM'));
        if (props.minValue && period < props.minValue) {
          return true;
        }
        if (props.maxValue && period > props.maxValue) {
          return true;
        }
      }
      return false;
    };
    if (props.value) {
      form.setFieldsValue({
        [props.uid]: moment(String(props.value), 'YYYYMM'),
      });
    }
    return newProps;
  },
  format: (props: PeriodControlProps) => {
    let value: any;
    if (props.value) {
      value = moment(String(props.value), 'YYYYMM');
    }
    return value;
  },
  validate: (props: PeriodControlProps, form: FormInstance) => {
    const validateResult: ValidateResult = { status: ResponseStatus.SUCCESS, message: null };
    const value = form.getFieldValue(props.uid);
    if (props.required) {
      if (wdiCore.Object.IsNullOrUndefined(value)) {
        validateResult.status = ResponseStatus.UNKNOWN;
        validateResult.message = props.title + '不能为空！';
        return validateResult;
      }
    }
    return validateResult;
  },
  get: (props: PeriodControlProps, form: FormInstance) => {
    const value = form.getFieldValue(props.uid);
    const result: any = {};
    if (wdiCore.Object.IsNullOrUndefined(value)) {
    } else {
      result[props.name] = value.format('YYYYMM');
    }
    return result;
  },
};

type DateTimeControlProps = {
  title: string | null;
  value: string | null;
  maxValue: string | null;
  minValue: string | null;
  name: string;
} & ImportRowProps;

const DateTimeControl = {
  init: (props: DateTimeControlProps, form: FormInstance) => {
    const newProps: any = {
      format: 'YYYY-MM-DD',
    };

    newProps.disabledDate = (current: any) => {
      if (current) {
        const period = current.format('YYYY-MM-DD');
        if (props.minValue && period < props.minValue) {
          return true;
        }
        if (props.maxValue && period > props.maxValue) {
          return true;
        }
      }
      return false;
    };
    if (props.value) {
      form.setFieldsValue({
        [props.uid]: moment(props.value, 'YYYY-MM-DD'),
      });
    }
    return newProps;
  },
  format: (props: PeriodControlProps) => {
    let value: any;
    if (props.value) {
      value = moment(props.value, 'YYYY-MM-DD');
    }
    return value;
  },
  validate: (props: PeriodControlProps, form: FormInstance) => {
    const validateResult: ValidateResult = { status: ResponseStatus.SUCCESS, message: null };
    const value = form.getFieldValue(props.uid);
    if (props.required) {
      if (wdiCore.Object.IsNullOrUndefined(value)) {
        validateResult.status = ResponseStatus.UNKNOWN;
        validateResult.message = props.title + '不能为空！';
        return validateResult;
      }
    }
    return validateResult;
  },
  get: (props: PeriodControlProps, form: FormInstance) => {
    const value = form.getFieldValue(props.uid);
    const result: any = {};
    if (wdiCore.Object.IsNullOrUndefined(value)) {
    } else {
      result[props.name] = value.format('YYYY-MM-DD');
    }
    return result;
  },
};

type ImportTipsProps = {
  title: string | null;
  tips: string[] | null;
} & ImportRowProps;

const ImportTips: React.FC<ImportTipsProps> = (props) => {
  return (
    <Space direction="vertical">
      {props.title && <Typography.Text>{props.title}</Typography.Text>}
      {props.tips?.map((item, itemIndex) => {
        return (
          <Typography.Text type="secondary" key={itemIndex}>
            {item}
          </Typography.Text>
        );
      })}
    </Space>
  );
};

// type ImportMessageProps = {
//     Message: string | null;
// } & ImportRowProps;

export const controls: Record<string, any> = {
  ImportAttachment: ImportAttachment,
  InputControl: InputControl,
  SelectControl: SelectControl,
  PeriodControl: PeriodControl,
  YearControl: YearControl,
  DateTimeControl: DateTimeControl,
};

const ImportRunningProgress: React.FC = () => {
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setPercent((prevPercent) => {
        if (prevPercent > 97) {
          return prevPercent;
        }
        return prevPercent + 1;
      });
    }, 50);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return <Progress key="uploadding" percent={percent} strokeWidth={30} strokeColor="#52c41a" />;
};

const columns = [
  {
    title: '行号',
    dataIndex: 'rowNumber',
    width: 50,
  },
  {
    title: '信息',
    dataIndex: 'errorInfo',
  },
];

type ErrorItem = { key: number; rowNumber: number; errorInfo: string };

type ImportComplatePanelProps = {
  resultType?: number;
  items?: any;
  data?: any;
  totalCount?: number;
  successCount?: number;
  failCount?: number;
  isSuccess?: boolean;
  exProperty?: any;
  tooltip?: string;
  message?: string;
  download?: (data: any, params?: any) => void;
  getParameters?: () => void;
};

const ImportComplatePanel: React.FC<ImportComplatePanelProps> = (props) => {
  const onDownload = (e: any) => {
    if (props.download) {
      const params = props?.getParameters?.();
      props.download(props, params);
    } else {
      const errorInfoColumns = [
        {
          name: 'rowNumber',
          displayName: '行号',
          type: Enum.FieldType.NUMBER,
          dataType: Enum.FieldDataType.INT,
        },
        { name: 'errorInfo', displayName: '信息' },
      ];
      const sheet = {
        data: props.items,
        names: errorInfoColumns,
        sheetName: '错误信息',
      };
      toExcel({
        name: '错误信息_' + wdiCore.Date.ToString(new Date()) + '.xlsx',
        sheetProps: [sheet],
      });
    }
  };
  return (
    <>
      {props.resultType === 2 && (
        <div>
          {props.tooltip && <span>{props.tooltip}</span>}
          {!props.tooltip && <span>{props?.message}</span>}
        </div>
      )}
      {props.resultType === 1 && (
        <div>
          <div style={{ marginBottom: '5px' }}>
            {props.tooltip && <span>{props.tooltip}</span>}
            {!props.tooltip && props.message && <span>{props.message}</span>}
            {!props.tooltip && !props.message && (
              <span>
                执行完成，共{props?.totalCount}条，成功
                {<Typography.Text type={'success'}>{props?.successCount}</Typography.Text>}条，失败
                {<Typography.Text type={'danger'}>{props?.failCount}</Typography.Text>}条
              </span>
            )}
          </div>
          <div style={{ marginBottom: '5px' }}>
            <Typography.Text>错误信息</Typography.Text>
            <Typography.Link style={{ float: 'right' }} onClick={onDownload}>
              导出
            </Typography.Link>
          </div>
          <div style={{ marginBottom: '5px' }}>
            <WdiTable
              showPageRows={5}
              columns={columns}
              dataSource={props?.items?.map((item: any) => {
                const row: ErrorItem = {
                  key: item.rowNumber,
                  rowNumber: item.rowNumber,
                  errorInfo: item.errorInfo,
                };
                return row;
              })}
            />
          </div>
        </div>
      )}
    </>
  );
};

export type ImportSettings = {
  limitedFileSize: number;
  limitedItemsCount: number;
  steps: string[] | null;
  buttonTexts: string[] | null;
  start: any[] | null;
  uploading: any[] | null;
  complate: any[] | null;
};

export type CustomProps = {
  comp: any;
  api: any;
};

export type ImportUploadProps = {
  settingsUrl?: string;
  uploadingUrl?: string;
  clientParameters?: any;
  doValidate?: (values: any) => any;
  events?: any;
  download?: (data: any, params?: any) => void;
  importExcels?: ToExcelProps[];
  jqxGridArgs?: any;
  customProps?: CustomProps;
} & ImportSettings & WdiModalAppProps;

const getStepText = (steps: string[] | null, index: number, defaultText: string) => {
  if (steps && steps.length > index) {
    return steps[index];
  }
  return defaultText;
};

export const addClientParameter = (items: any[], dictParameter: any) => {
  if (dictParameter) {
    for (const key in dictParameter) {
      items.push({
        name: key,
        value: wdiCore.String.ObjectToString(dictParameter[key]),
      });
    }
  }
  return items;
};

export const ImportUpload: React.FC<ImportUploadProps> = (props) => {
  const [current, setCurrent] = useState<number>(0);
  const [form] = Form.useForm();
  const [isRunning, setIsRunning] = useState(false);
  const [uploadResult, setUploadResult] = useState<any>();

  const onCancel = (e: any) => {
    if (props.onClose) {
      props.onClose();
    }
  };
  const isOkDo = (responseResult: any, parameters: any) => {
    let url = '';
    if (props.uploadingUrl) {
      url = props.uploadingUrl;
    }
    setIsRunning(true);
    setCurrent(1);

    request<API.ResponseResult<any>>(
      url,
      formatter({
        method: 'POST',
        body: parameters
      }),
    ).then((responseResult) => {
      setIsRunning(false);
      setCurrent(2);
      if (responseResult.code != ResponseStatus.SUCCESS) {
        message.error(responseResult.message);
      }
      setUploadResult(responseResult);
    });
  }
  const doUploading = async (e: any) => {
    const validateResultList: ValidateResult[] = [];
    props?.start?.forEach((item: any) => {
      if (item.validate) {
        const validateResult = item.validate(item, form);
        if (validateResult.status !== ResponseStatus.SUCCESS) {
          validateResultList.push(validateResult);
        }
      }
    });
    if (validateResultList.length) {
      message.error(validateResultList[0].message);
      return;
    }

    const parameters: any = {
      items: [],
    };
    addClientParameter(parameters.items, props.clientParameters);
    props?.start?.forEach((item: any) => {
      if (item.get) {
        const value = item.get(item, form);
        addClientParameter(parameters.items, value);
      }
    });
    if (!wdiCore.Object.IsNullOrUndefined(props.limitedFileSize)) {
      parameters['limitedFileSize'] = props.limitedFileSize;
    }
    if (!wdiCore.Object.IsNullOrUndefined(props.limitedItemsCount)) {
      parameters['limitedItemsCount'] = props.limitedItemsCount;
    }
    let isOk = true;
    let responseResult;
    if (props.doValidate) {
      responseResult = await props.doValidate(parameters)();
      isOk = responseResult.code == ResponseStatus.SUCCESS;
      if (!isOk) {
        message.error(responseResult.message);
      }
    }
    if (isOk) {
      if (responseResult && responseResult.data && responseResult.data.tooltip) {
        if (confirm(responseResult.data.tooltip)) {
          isOkDo(responseResult, parameters);
        }
      } else {
        isOkDo(responseResult, parameters);
      }
    }
  };

  const handleClick = (e: any) => {
    if (current === 0) {
      doUploading(e);
    } else if (current === 1) {
      // setCurrent(2);
    } else if (current === 2) {
      if (props.onSave) {
        props.onSave(uploadResult);
      }
    }
  };

  const getParameters = () => {
    const parameters: any[] = [];
    props?.start?.forEach((item: any) => {
      if (item.get) {
        const value = item.get(item, form);
        addClientParameter(parameters, value);
      }
    });
    return parameters;
  }

  return (
    <WdiModal
      destroyOnClose
      maskClosable={false}
      title={props.title}
      width={800}
      onCancel={(e) => {
        if (current == 2) {
          props.onSave?.(uploadResult);
        } else {
          onCancel(e);
        }
      }}
      visible={props.visible}
      footer={
        <>
          {current === 0 && <Button onClick={onCancel}>取消</Button>}
          {(current === 0 || current === 1 || current === 2) && (
            <Button type="primary" onClick={handleClick} loading={isRunning}>
              {getStepText(
                props.buttonTexts,
                current,
                current === 0 ? '开始导入' : current === 1 ? '导入中...' : '完成',
              )}
            </Button>
          )}
        </>
      }
    >
      <Steps size="small" current={current} style={{ marginBottom: '20px' }}>
        <Steps.Step key="start" title={getStepText(props.steps, 0, '开始')} />
        <Steps.Step key="uploading" title={getStepText(props.steps, 1, '执行')} />
        <Steps.Step key="complete" title={getStepText(props.steps, 2, '完成')} />
      </Steps>
      <div className="steps-action">
        {current === 0 && (
          <Form form={form}>
            {props?.start?.map((item: any) => {
              if (item.key == 'ImportTemplate') {
                const itemProps: any = {};
                if (props.events) {
                  itemProps.events = props.events;
                }
                itemProps.getParameters = () => {
                  return getParameters();
                }
                return (
                  <Form.Item style={{ marginBottom: '5px' }} key={item.uid}>
                    <ImportTemplate {...item} {...itemProps} />
                  </Form.Item>
                );
              } else if (item.key == 'ImportAttachment') {
                return (
                  <Form.Item
                    style={{ marginBottom: '5px' }}
                    name={item.uid}
                    valuePropName="fileList"
                    getValueFromEvent={(e: any) => {
                      return e.fileList || [];
                    }}
                    key={item.uid}
                  >
                    <WdiFileUpload {...item.init(item)} />
                  </Form.Item>
                );
              } else if (item.key == 'InputControl') {
                if (item.type === 'checkbox') {
                  let checked: boolean = false;

                  if (item.value && item.value == "true") {
                    checked = true;
                    delete item.value;
                  }
                  return (
                    <Form.Item
                      style={{ marginBottom: '5px' }}
                      name={item.uid}
                      label={item.title}
                      valuePropName="checked"
                      initialValue={checked}
                      key={item.uid}
                      labelCol={{ style: { marginLeft: '-12px' } }}
                    >
                      <Checkbox {...item.init(item)} />
                    </Form.Item>
                  );
                } else {
                  return (
                    <Form.Item
                      style={{ marginBottom: '5px' }}
                      name={item.uid}
                      label={item.title}
                      key={item.uid}
                      labelCol={{ style: { marginLeft: '-12px' } }}
                    >
                      <Input {...item.init(item)} />
                    </Form.Item>
                  );
                }
              } else if (item.key == 'SelectControl') {
                const items: any[] = [];
                item.items.map((rowItem: any) => {
                  items.push({ value: rowItem.value, label: rowItem.text });
                });

                return (
                  <Form.Item
                    style={{ marginBottom: '5px', width: '400px' }}
                    name={item.uid}
                    label={item.title}
                    key={item.uid}
                    labelCol={{ style: { marginLeft: '-12px' } }}
                    initialValue={item.Value}
                  >
                    <WdiSelect {...item.init(item)} options={items} />
                  </Form.Item>
                );
              } else if (item.key == 'YearControl' || item.key == 'PeriodControl' || item.key == 'DateTimeControl') {
                return (
                  <Form.Item
                    style={{ marginBottom: '5px', width: '400px' }}
                    name={item.uid}
                    label={item.title}
                    key={item.uid}
                    labelCol={{ style: { marginLeft: '-12px' } }}
                  >
                    <DatePicker {...item.init(item, form)} />
                  </Form.Item>
                );
              } else if (item.key == 'CustomControl') {
                const Comp = props?.customProps?.comp;
                if (Comp) {
                  return (
                    <Form.Item
                      style={{ marginBottom: "5px" }}
                      key={item.uid}
                      name={item.uid}
                    >
                      <Comp />
                    </Form.Item>
                  )
                } else {
                  return <></>;
                }
              } else if (item.key == 'ImportTips') {
                return (
                  <Form.Item style={{ marginBottom: '5px' }} key={item.uid}>
                    <ImportTips {...item} />
                  </Form.Item>
                );
              } else {
                return <></>;
              }
            })}
            {props?.importExcels && props?.importExcels.length > 0 && (
              <Form.Item style={{ marginBottom: '5px' }} key={'control-excel'}>
                <Space size="large">
                  {(props?.importExcels || [])?.map((item, itemIndex: number) => {
                    return (
                      <Typography.Link
                        onClick={async () => {
                          const sheetProps = [...item.sheetProps];
                          for (const sheet of sheetProps) {
                            const response = await sheet?.dataLoading?.();
                            sheet.data = response?.data;
                          }
                          toExcel({
                            name: item.name + '.xlsx',
                            sheetProps: sheetProps,
                          });
                        }}
                        key={itemIndex}
                      >
                        {item.name}
                      </Typography.Link>
                    );
                  })}
                </Space>
              </Form.Item>
            )}
          </Form>
        )}
        {current === 1 && (
          <>
            <div style={{ marginBottom: '5px' }} key="-1">
              <ImportRunningProgress />
            </div>
            {props?.uploading?.map((item: any, itemIndex: number) => {
              if (item.key == 'ImportMessage') {
                return (
                  <div style={{ marginBottom: '5px' }} key={itemIndex}>
                    <Typography.Title level={5}>{item.Message}</Typography.Title>
                  </div>
                );
              } else if (item.key == 'ImportTips') {
                return (
                  <div style={{ marginBottom: '5px' }} key={itemIndex}>
                    <ImportTips {...item} />
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </>
        )}
        {current === 2 && (
          <>
            <Progress
              key="complete"
              percent={100}
              strokeWidth={30}
              style={{ marginBottom: '5px' }}
            />
            <ImportComplatePanel
              {...(uploadResult && uploadResult.data ? uploadResult.data : {})}
              download={props.download}
              getParameters={getParameters}
            />
          </>
        )}
      </div>
    </WdiModal>
  );
};

export type ImportModalProps = {
  limitedFileSize?: number;
  limitedItemsCount?: number;
  settingsUrl?: string;
  uploadingUrl?: string;
  clientParameters?: any;
  doValidate?: (values: any) => any;
  events?: any;
  download?: (data: any, params?: any) => void;
  importExcels?: ToExcelProps[];
  jqxGridArgs?: any;
  customProps?: CustomProps;
} & WdiModalAppProps;

export const ImportModalApp: React.FC<ImportModalProps> = (props) => {
  const [importSettings, setImportSettings] = useState<ImportSettings>({
    limitedFileSize: -1,
    limitedItemsCount: -1,
    steps: [],
    buttonTexts: [],
    start: [],
    uploading: [],
    complate: [],
  });

  useEffect(() => {
    let url = '';
    if (props.settingsUrl) {
      url = props.settingsUrl;
    }
    const parameters: any = {
      items: [],
    };
    addClientParameter(parameters.items, props.clientParameters);
    request<any>(
      url,
      formatter({
        method: 'POST',
        body: {
          ...parameters,
        }
      }),
    ).then((responseResult) => {
      if (responseResult.code === ResponseStatus.SUCCESS) {
        const data = responseResult.data;
        if (data && data.start) {
          data.start.forEach((item: any, itemIndex: number) => {
            const control = controls[item.key];
            if (control) {
              item.init = control.init;
              item.format = control.format;
              item.validate = control.validate;
              item.get = control.get;
            } else if (item.key == 'CustomControl' && props.customProps?.api) {
              item.init = props.customProps?.api.init;
              item.format = props.customProps?.api.format;
              item.validate = props.customProps?.api.validate;
              item.get = props.customProps?.api.get;
            }
            item.uid = 'control-' + itemIndex;
          });
        }
        setImportSettings(data);
      } else {
        message.error(responseResult.message);
      }
    });
  }, []);

  return <ImportUpload {...props} {...importSettings} />;
};







