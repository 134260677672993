import { formatter, request } from '@/utils/request';

export namespace Message {
  export const Path = {
    Query: "/basicapi/message/query",
    CountForMy: "/basicapi/message/countForMy",
    PostToRead: "/basicapi/message/postToRead"
  }
  export async function countForMy() {
    const options = {
      method: 'GET',
      body: {

      },
    };
    return request<API.ResponseResult<API.MessageCount>>(Path.CountForMy, formatter(options));
  }

  export async function query(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.MessageView>>(Path.Query, formatter(options));
  }

  export async function postToRead(messageId?: number, messageType?: number) {
    const options = {
      method: 'POST',
      body: {
        messageId: messageId,
        messageType: messageType
      },
    };
    return request<API.ResponseResult<any>>(Path.PostToRead, formatter(options));
  }
}
