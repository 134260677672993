import React, {useCallback, useEffect, useState} from 'react';
import {LogoutOutlined, SettingOutlined, StarOutlined} from '@ant-design/icons';
import {Menu} from 'antd';
import {history, useModel} from 'umi';
import {stringify} from 'querystring';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import type {MenuInfo} from 'rc-menu/lib/interface';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {NSToken} from '@/services/identity';
import {WdiIcon} from '../WdiControls/WdiIcon';
import PositionSwitchApp from './PositionSwitch';
import {WdiModalAppResult, WdiModalProps} from '../WdiControls';
import {ResponseStatus} from '@/utils/request';
import {Enum} from '@/enums/system';
import {Staff} from "@/services/basic/staff";
import { SimpleStaff } from '@/services/basic/simplestaff';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

/**
 * 退出登录，并且将当前的 url 保存
 */
const loginOut = async () => {

  const {query = {}, search, pathname} = history.location;
  const {redirect} = query;

  NSToken.loginOut();
  // Note: There may be security issues, please note
  if (window.location.pathname !== '/user/login' && !redirect) {
    //uat和prod进行单点登录注销 REACT_APP_ENV ==='uat' ||
    if ((REACT_APP_ENV === 'uat' || REACT_APP_ENV === 'prod') && IS_SSO) {
      window.location.href = LOGOUT_URL;
    }else {
      history.replace({
        pathname: '/user/login',
        search: stringify({
          redirect: pathname + search,
        }),
      });
    }

  }
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({menu}) => {
  const {initialState, setInitialState} = useModel('@@initialState');
  const [switchModalProps, setSwitchModalProps] = useState<WdiModalProps>({visible: false});
  const [staff, setStaff] = useState<API.SimpleStaff>();
  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const {key} = event;
      if (key === 'logout') {
        setInitialState((s) => ({...s, user: undefined}));
        loginOut();
        return;
      } else if (key === 'settings') {
        history.push('/basic/personalsettings');
        return;
      } else if (key === 'favorite') {
        history.push('/basic/favoritemenus');
        return;
      } else if (key === 'approval') {
        history.push('/basic/agentapprover');
        return;
      } else if (key === 'positionSwitch') {
        setSwitchModalProps({visible: true, title: "系统职务切换"});
        return;
      }
    },
    [setInitialState],
  );
  useEffect(() => {
    SimpleStaff.get(Number(initialState?.user?.userId)).then(res => {
      setStaff(res.data);
    })
  }, [initialState])
  const menuItems = (menu: boolean | undefined) => {
    const pageMenuModel = initialState?.pageMenuModel;
    const items: ItemType[] = [];
    if (menu) {
      items.push(
        {
          key: 'settings',
          icon: <SettingOutlined/>,
          title: '个人设置',
          label: '个人设置'
        },
        {
          key: 'favorite',
          icon: <StarOutlined/>,
          title: '常用菜单',
          label: '常用菜单'
        }
      );
      if (pageMenuModel?.isMultiPosition) {
        items.push(
          {
            key: 'positionSwitch',
            icon: <WdiIcon type={Enum.IconType.SWITCH}/>,
            title: '职务切换',
            label: '职务切换'
          }
        )
      }
      if (pageMenuModel?.allowAgentApprover) {
        items.push(
          {
            key: 'approval',
            icon: <WdiIcon type={Enum.IconType.PROXY}/>,
            title: '代理审批',
            label: '代理审批'
          }
        )
      }
    }
    if (pageMenuModel?.allowLogout) {
      items.push(
        {
          type: 'divider'
        },
        {
          key: 'logout',
          icon: <LogoutOutlined/>,
          title: '退出登录',
          label: '退出登录'
        });
    }

    return items;
  };

  const onSwitchClose = () => {
    setSwitchModalProps({visible: false, title: ""});
  }

  const onSwitchSave = (result?: WdiModalAppResult) => {
    setSwitchModalProps({visible: false, title: ""});
    if (result && result.code == ResponseStatus.SUCCESS) {
      NSToken.switchPosition(result?.data?.RoleID).then(resp => {
        window.location.reload();
      });
    }
  }

  return (
    <>
      {switchModalProps.visible &&
        <PositionSwitchApp
          {...switchModalProps}
          onClose={onSwitchClose}
          onSave={onSwitchSave}
        />
      }
      <HeaderDropdown overlay={
        <Menu
          className={styles.menu}
          selectedKeys={[]}
          onClick={onMenuClick}
          items={menuItems(menu)}>
        </Menu>
      }>
        <span className={`${styles.action} ${styles.account}`}>
          <span className={`${styles.name} anticon`}>{staff?.staffName}</span>
          <span>({initialState?.pageMenuModel?.positionName})</span>
        </span>
      </HeaderDropdown>
    </>

  );
};

export default AvatarDropdown;
