import {ParamsProps} from '@/components/WdiBus/FormPage';
import {Enum} from '@/enums/system';
import {ResponseStatus} from '@/utils/request';
import {wdiCore, WdiModalAppResult} from '@/components/WdiControls';
import {FormSa} from '@/services/sales/sa';
import {FormApi} from '@/services/sales';
import {FormSs} from '@/services/sales/ss';
import {FormSp, FormSpDetail} from '@/services/sales/sp';
import DataStore from '@/components/GridWidget/DataStore';
import {VatType} from '@/services/mdm/vattype';
import {EC} from '@/services/mdm/ec';
import {FieldConfigUtility, getEcDefFields} from '@/components/WdiBus/EcFieldConfig';
import {getAllHasValueColumns, getHasValueColumns, getRateColumns} from '../../sa/components/data';
import {BOHQ} from "@/services/mdm/bohq";

export type ViewEntityProps = {
  form?: API.Form,
  formSp?: API.FormSp,
  preForm?: API.Form,
  formSpDetails?: API.FormSpDetail[],
  vatType?: API.VatType;
  relationType?: number,
  relatedFormId?: number,
  formId?: number;
  statusId?: number;
  formTypeId?: number;
  pageTypeId?: number;
  paymentMethodIds?: string,
  soldToCode?: string,
  custSysCodes?: string,
  formSaId?: number,
  formSsId?: number,
  reimburseType?: number,
  userId?: number;
  positionId?: number;
  deptId?: number;
  ecFieldConfigs?: API.EcFieldConfig[];
  bohq?: API.BOHQ;
  startYear?: number;
  endYear?: number;
}

export const initApplyPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId || params.relatedFormId) {
    // 退回待修改
    const formResponse = await FormApi.get(params.formId || params.relatedFormId || 0);
    viewEntity.form = formResponse.data;
    if (!viewEntity.form) {
      result.message = "无效的退回待修改单";
      return result;
    }
    if (params.formId) {
      viewEntity.formId = params.formId;
    } else {
      viewEntity.relationType = Enum.FormRelationType.REJECTED;
      viewEntity.relatedFormId = params.relatedFormId;
    }
    const formSPResponse = await FormSp.get(viewEntity.form.formId);
    viewEntity.formSp = formSPResponse.data;
    viewEntity.formSaId = viewEntity.formSp.formSaId;
    viewEntity.formSsId = viewEntity.formSp.formSsId;
    viewEntity.reimburseType = viewEntity.formSp.reimburseType;
    viewEntity.userId = viewEntity.form.userId;
    viewEntity.positionId = viewEntity.form.positionId;
    viewEntity.deptId = viewEntity.form.deptId;
    viewEntity.pageTypeId = viewEntity.form.pageTypeId;
    viewEntity.formTypeId = viewEntity.form.formTypeId;
  } else {
    // 直接申请
    viewEntity.formTypeId = Enum.FormType.ACTIVITY_PAYMENT;
    viewEntity.userId = params.userId;
    viewEntity.positionId = params.positionId;
    viewEntity.deptId = params.deptId;
  }

  if (viewEntity.formSaId) {
    const formSaResponse = await FormSa.get(viewEntity.formSaId);
    if (!viewEntity.formSsId && formSaResponse?.data?.settlementMode != Enum.SettlementMode.NO_SETTLE) {
      const formSsResponse = await FormSs.getByFormSaId(viewEntity.formSaId);
      viewEntity.formSsId = formSsResponse?.data?.formSsId;
    }
    if (formSaResponse.data.bohqId) {
      const bohqResponse = await BOHQ.get(formSaResponse.data.bohqId);
      viewEntity.bohq = bohqResponse.data;
    }
  }
  if (params.bohqId) {
    const bohqResponse = await BOHQ.get(params.bohqId);
    viewEntity.bohq = bohqResponse.data;
  }

  const ecFieldConfigsResponse = await EC.getEcFieldConfigs();
  viewEntity.ecFieldConfigs = ecFieldConfigsResponse.data;

  const formSPDetailsResponse = await FormSpDetail.getFormSpDetailsForSaApply({
    formSaId: viewEntity.formSaId,
    formSpId: viewEntity.formSp?.formSpId,
  });
  viewEntity.formSpDetails = DataStore.init(formSPDetailsResponse.data);

  if (viewEntity.pageTypeId == Enum.PageType.PAYMENT_THIRD_PART_PRE) {
    const preFormSpResponse = await FormSp.getPreFormSp(viewEntity.formSaId || 0);
    if (preFormSpResponse.data) {
      const preFormResponse = await FormApi.get(preFormSpResponse.data.formSpId);
      viewEntity.preForm = preFormResponse.data;
    }
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
}

export const initApprovalPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId) {
    // 从草稿打开
    const formSPResponse = await FormSp.get(params.formId);
    viewEntity.formSp = formSPResponse.data;
    if (!viewEntity.formSp) {
      result.message = "无效的草稿单";
      return result;
    }
    const formResponse = await FormApi.get(params.formId);
    viewEntity.form = formResponse.data;
    viewEntity.formId = formResponse.data.formId;

    const formSPDetailsResponse = await FormSpDetail.getFormSpDetailsForSaApproval(params.formId);
    viewEntity.formSpDetails = DataStore.init(formSPDetailsResponse.data);

    if (!viewEntity.paymentMethodIds) {
      viewEntity.paymentMethodIds = [...new Set(viewEntity.formSpDetails.map(item => item.paymentMethodId))].join();
    }
    if (viewEntity.formSpDetails && viewEntity.formSpDetails.length > 0 && viewEntity.formSpDetails[0].soldToCode) {
      viewEntity.soldToCode = viewEntity.formSpDetails[0].soldToCode;
    }

    if (viewEntity.formSpDetails && viewEntity.formSpDetails.length > 0 && viewEntity.formSpDetails[0].vatTypeId) {
      const vatTypeResponse = await VatType.get(viewEntity.formSpDetails[0].vatTypeId);
      viewEntity.vatType = vatTypeResponse?.data;
    }

    const ecFieldConfigsResponse = await EC.getEcFieldConfigs();
    viewEntity.ecFieldConfigs = ecFieldConfigsResponse.data;

    //获取结案单ID（FormSP未存就拿FormSAID查）
    if (viewEntity.formSp.formSsId) {
      viewEntity.formSsId = viewEntity.formSp.formSsId;
    } else {
      const formSSResponse = await FormSs.getByFormSaId(viewEntity.formSp.formSaId ?? 0);
      viewEntity.formSsId = formSSResponse?.data?.formSsId;
    }
    if (viewEntity.formSp.formSaId) {
      const formSaResponse = await FormSa.get(viewEntity.formSp.formSaId);
      viewEntity.startYear = wdiCore.Date.ToString(formSaResponse.data.activityStartDate, 'yyyy');
      viewEntity.endYear = wdiCore.Date.ToString(formSaResponse.data.activityEndDate, 'yyyy');
    }
    if (viewEntity.formSp.preFormSpId) {
      const formResponse = await FormApi.get(viewEntity.formSp.preFormSpId ?? 0);
      viewEntity.preForm = formResponse?.data;
    }
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
};

const initRowFieldConfigs = (record: any, ecFieldConfigs: API.EcFieldConfig[] | undefined) => {
  const columns = ["boId", "soldToCode", "custSysCode", "shopId", "productBuyIds", "ecId", "paymentMethodId", "convertRate", "amountSa", "remarkSa", "amountActual", "amountSs", "amountPaid", "amountNotPaid", "amountReversePending", "amountPrePay", "amountReverseDone", "amountAccrual",];
  columns.push(...getAllHasValueColumns(record));
  if (!wdiCore.Object.IsNullOrUndefined(record.revenueType)) {
    columns.push("revenueType");
  }
  if (ecFieldConfigs && ecFieldConfigs.length > 0) {
    if (record.extendFieldsSa || record.revExtendFieldsSa) {
      columns.push(...getEcDefFields(ecFieldConfigs, FieldConfigUtility.merge(record.extendFieldsSa, record.revExtendFieldsSa)));
    }
  }
  return columns;
};

export const initApprovalRowFieldConfigs = (record: any, ecFieldConfigs: API.EcFieldConfig[] | undefined) => {
  const columns = initRowFieldConfigs(record, ecFieldConfigs);
  columns.push(...getHasValueColumns(record));
  columns.push(...getRateColumns(record, columns));
  if (record.toSfa) {
    columns.push("sfaResult")
  }
  if (Enum.RevenueType.POS_QUANTITY == record.revenueType) {
    columns.push("quantityPosActualKeyin")
  }
  if (Enum.RevenueType.POS_REVENUE == record.revenueType) {
    columns.push("revenuePosActualKeyin")
  }
  return columns;
};
