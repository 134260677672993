import {Badge, Collapse, message, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {FormBudgetProject} from '@/services/sales/bp/formBudgetProject';
import styles from './DynamicTarget.less';
import {TableColumnRanderFormats} from './TableColumnRanderFormats';
import {WdiTable} from '../WdiControls/WdiTable';
import {Enum} from '@/enums/system';
import {FormSs} from '@/services/sales/ss';
import {FormSp} from '@/services/sales/sp';
import {getFormUrlMapping} from '@/components/WdiBus/FormPage';
import {FormSa} from '@/services/sales/sa';
import {WdiPanel} from '../WdiControls/WdiPanel';
import type {WdiModalProps} from '../WdiControls';
import {WdiCardTitle, wdiCore} from '../WdiControls';
import {FormLogViewApp} from './ExportFormLog';
import {FormApi} from '@/services/sales';
import {ResponseStatus} from '@/utils/request';
import {FormRs} from '@/services/sales/rs';

type DynamicTargetProps = {
  dataItem: any;
}
/**动态信息 表格 列表 */
const DynamicTarget: React.FC<DynamicTargetProps> = (props) => {
  const [dynamicInfo, setDynamicInfo] = useState<any>();
  const [modalProps, setModalProps] = useState<WdiModalProps>({visible: false, title: "", id: null});
  const onFormLogClose = () => {
    setModalProps({visible: false, title: "", id: null});
  };
  const onFormLogClick = (evt: any) => {
    const clickType = evt.target.getAttribute("clicktype");
    const formNo = evt.target.getAttribute("formno");
    if (formNo) {
      if (clickType == 'export') {
        setModalProps({visible: true, title: "单据导出日志", id: formNo});
      } else {
        FormApi.getByFormNo(formNo).then(formResponse => {
          const form = formResponse?.data;
          if (form) {
            FormApi.doesAccess(form.formId).then(response => {
              if (response.code == ResponseStatus.SUCCESS && response.data) {
                window.open(getFormUrlMapping(form.pageTypeId, form.formTypeId) + `?formId=${form.formId}&pageTypeId=${form.pageTypeId}&formTypeId=${form.formTypeId}`);
              } else {
                message.error("没有权限查看单据");
              }
            })
          }
        })
      }
    }
  }

  const getIconType = (info: API.DynamicInformation) => {
    let iconType = Enum.IconType.BASE_INFO;
    if (info.items && Array.isArray(info.items)) {
      if (info.items.findIndex(item => item.target?.Key == 'ApprovalHistory') > -1) {
        iconType = Enum.IconType.APPROVAL_FLOW;
      } else if (info.items.findIndex(item => item.target?.Key == 'PaymentInfos') > -1) {
        iconType = Enum.IconType.FORM_SP;
      }
    }
    return iconType;
  }

  useEffect(() => {
    (async () => {
      const {objectId, formTypeId, pageTypeId} = props.dataItem;
      let ret = null;
      if (formTypeId == Enum.FormType.ACTIVITY_APPLICATION) {
        ret = await FormSa.Buttons.dynamicInfos(objectId);
      } else if (formTypeId == Enum.FormType.ACTIVITY_SETTLEMENT) {
        ret = await FormSs.Buttons.getDynamicInformation(objectId);
      } else if (formTypeId == Enum.FormType.CONTRACT_REBATE_SETTLEMENT) {
        ret = await FormRs.Buttons.dynamicInfos(objectId);
      } else if (formTypeId == Enum.FormType.ACTIVITY_PAYMENT ||
        formTypeId == Enum.FormType.ACTIVITY_PRE_PAYMENT ||
        formTypeId == Enum.FormType.CONTRACT_REBATE_PAYMENT) {
        ret = await FormSp.Buttons.dynamicInfos(objectId);
      } else {
        ret = await FormBudgetProject.Buttons.getDynamicInformation(objectId, formTypeId, pageTypeId);
      }
      setDynamicInfo(ret);
    })();
  }, [props.dataItem]);

  return (
    <>
      {
        modalProps.visible &&
        <FormLogViewApp
          {...modalProps}
          onClose={onFormLogClose}
        />
      }
      <Collapse defaultActiveKey={[0]} className={styles.collapse} ghost>
        {
          dynamicInfo?.map((d: any, i: number) => {
            const itemIsArrary = (d.items && Array.isArray(d.items));
            return (
              <WdiPanel key={i}
                        title={<>
                          <WdiCardTitle iconType={getIconType(d)} title={d.title}/>
                          {(d.items[0].target.key == 'PrePaymentInfos' || d.items[0].target.key == 'PaymentInfos') &&
                            <Badge count={d.items[0].target.items.length}
                                   style={{
                                     color: "black",
                                     boxShadow: "none",
                                     background: "none",
                                     top: "-20px",
                                     right: "-15px",
                                     position: 'absolute'
                                   }}>
                            </Badge>
                          }
                        </>}
                        defaultCollapsed={!wdiCore.Object.IsNullOrUndefined(d.isOpen) && !d.isOpen}
                        collapsed={true}>
                {itemIsArrary && (builderDynamicItem(d.items, onFormLogClick))}
              </WdiPanel>
            );
          })
        }
      </Collapse>
    </>
  );
};

function builderDynamicItem(items: API.DynamicInformationItem[], onFormLogClick?: any) {
  return items.map(
    (item, index) => {
      switch (item.type) {
        case 0: {
          //Object
          return (<></>);
        }
        case 1: {
          //string
          return (
            <div key={index}
                 onClick={(evt: any) => {
                   onFormLogClick?.(evt);
                 }}
                 dangerouslySetInnerHTML={{__html: item.target}}
                 className={styles.string}>
            </div>
          );
        }
        case 2: {
          //WdiGrid
          const itemTarget = item.target as API.DynamicInformationItemWdiGridTarget;
          const columns = gridColumnsMap[itemTarget.key];
          return (
            <WdiTable
              key={index}
              rowKey="__RowNumber"
              bordered
              columns={columns}
              dataSource={itemTarget.items.map((v, i) => ({...v, __RowNumber: `${i + 1}`}))}
              pagination={false}
            />
          );
        }
        default:
          return (<></>);
      }
    }
  )

}

export default DynamicTarget;

const approvalHistoryGrid = [
  {
    key: "__RowNumber",
    dataIndex: "__RowNumber",
    title: "行号",
    width: 50,
    align: "center",
    cellsalign: "center"
  },
  {
    key: "approver",
    dataIndex: "approver",
    title: "操作人",
    width: 250,
    ellipsis: {
      showTitle: false,
    },
    render: (value: any) => (
      <Tooltip placement="bottomLeft" title={value}>
        {value}
      </Tooltip>
    ),
  },
  {
    key: "approveType",
    dataIndex: "approveType",
    title: "审批类型",
    width: 80
  },
  {
    key: "approvedDate",
    dataIndex: "approvedDate",
    title: "操作时间",
    width: 200,
    render: TableColumnRanderFormats.ToMinutes,
  },
  {
    key: "remark",
    dataIndex: "remark",
    title: "审批意见",
    ellipsis: {
      showTitle: false,
    },
    render: (value: any) => (
      <Tooltip placement="bottomLeft" title={value}>
        {value}
      </Tooltip>
    ),
  }
];

const sendNotifyInfosGrid = [
  {
    key: "soldTo",
    dataIndex: "soldTo",
    title: "售达方"
  },
  {
    key: "sendUser",
    dataIndex: "sendUser",
    title: "发送人",
    width: 100
  },
  {
    key: "sendDate",
    dataIndex: "sendDate",
    title: "发送时间",
    width: 120
  },
  {
    key: "attachment",
    dataIndex: "attachment",
    title: "告知函"
  }
];

const adDynamicInfosGrid = [
  {
    key: "formNo",
    dataIndex: "formNo",
    title: "预发单编号",
    width: 150
  },
  {
    key: "statusName",
    dataIndex: "statusName",
    title: "单据状态",
    width: 100
  },
  {
    key: "isTransferred",
    dataIndex: "isTransferred",
    title: "是否预发完成",
    width: 100
  },
  {
    key: "applyUserName",
    dataIndex: "applyUserName",
    title: "申请人"
  },
  {
    key: "applyDate",
    dataIndex: "applyDate",
    title: "申请日期",
    width: 80,
    render: TableColumnRanderFormats.ToDate
  },
  {
    key: "amount",
    dataIndex: "amount",
    title: "申领金额",
    width: 150
  },
  {
    key: "amountTransfer",
    dataIndex: "amountTransfer",
    title: "移库金额",
    width: 150
  }
];

const amDynamicInfosGrid = [
  {
    key: "formNo",
    dataIndex: "formNo",
    title: "单据编号"
  },
  {
    key: "statusName",
    dataIndex: "statusName",
    title: "单据状态"
  },
  {
    key: "fperiod",
    dataIndex: "fperiod",
    title: "预提月份"
  },
  {
    key: "applyUserName",
    dataIndex: "applyUserName",
    title: "申请人"
  },
  {
    key: "applyDate",
    dataIndex: "applyDate",
    title: "申请日期",
    width: 80,
    render: TableColumnRanderFormats.ToDate
  },
  {
    key: "amount",
    dataIndex: "amount",
    title: "累计确认金额"
  }
];

const showDispModal = (evt: any) => {
  if (evt && evt.target) {
    // const dataKey = evt.target.getAttribute("data-key");
    const record = JSON.parse(evt.target.getAttribute("data-record"));
    FormApi.doesAccess(record.formId).then(response => {
      if (response.code == ResponseStatus.SUCCESS && response.data) {
        window.open(getFormUrlMapping(record.pageTypeId, record.formTypeId) + `?formId=${record.formId}&pageTypeId=${record.pageTypeId}&formTypeId=${record.formTypeId}`);
      } else {
        message.error("没有权限查看单据");
      }
    })
  }
};
const paymentInfosGrid = [
  {
    key: "formNo",
    dataIndex: "formNo",
    title: "付款单编号",
    render: (_: any, record: any) => (
      <a onClick={showDispModal} data-key={record.formId} data-record={JSON.stringify(record)}>
        {record.formNo}
      </a>
    )
  },
  // {
  //   key: "paymentMethodName",
  //   dataIndex: "paymentMethodName",
  //   title: "支付方式",
  // },
  {
    key: "statusName",
    dataIndex: "statusName",
    title: "单据状态",
  },
  {
    key: "applyUserName",
    dataIndex: "applyUserName",
    title: "申请人",
  },
  {
    key: "amountExVat",
    dataIndex: "amountExVat",
    title: "付款金额（不含税）",
    width: 150,
    render: (_: any, record: any) => (
      wdiCore.Number.AmountToString(record.amountExVat, 2)
    )
  },
];

const readerUserInfosGrid = [
  {
    key: "readerUser",
    dataIndex: "readerUser",
    title: "已阅用户"
  },
  {
    key: "readerTime",
    dataIndex: "readerTime",
    title: "已阅时间"
  }
];

const prePaymentInfosGrid = [
  {
    key: "formNo",
    dataIndex: "formNo",
    title: "预付单编号",
    render: (_: any, record: any) => (
      <a onClick={showDispModal} data-key={record.formId} data-record={JSON.stringify(record)}>
        {record.formNo}
      </a>
    )
  },
  {
    key: "paymentMethodName",
    dataIndex: "paymentMethodName",
    title: "支付方式",
  },
  {
    key: "statusName",
    dataIndex: "statusName",
    title: "单据状态",
  },
  {
    key: "applyUserName",
    dataIndex: "applyUserName",
    title: "申请人",
  },
  {
    key: "applyDate",
    dataIndex: "applyDate",
    title: "申请日期",
  },
  {
    key: "amount",
    dataIndex: "amount",
    title: "预付金额",
    width: 150
  },
  {
    key: "amountReverse",
    dataIndex: "amountReverse",
    title: "已冲销金额",
    width: 150
  },
  {
    key: "amountReversePending",
    dataIndex: "amountReversePending",
    title: "待冲销金额",
    width: 150
  }
];

const gridColumnsMap: Record<string, any> = {
  "ApprovalHistory": approvalHistoryGrid,
  "SendNotify": sendNotifyInfosGrid,
  "ADDynamicInfos": adDynamicInfosGrid,
  "AMDynamicInfos": amDynamicInfosGrid,
  "PaymentInfos": paymentInfosGrid,
  "ReaderUserInfos": readerUserInfosGrid,
  "PrePaymentInfos": prePaymentInfosGrid,
};
