import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Popover, Table, Typography} from 'antd';
import type {TableProps} from 'antd/lib/table';
import DataStore from '../GridWidget/DataStore';
import {TableColumnRanderFormats} from '../WdiBus/TableColumnRanderFormats';
import {Resizable} from 'react-resizable';
import _omit from 'lodash/omit';
import {Utility} from '../GridWidget/GridWidgetUtility';
import {MinusSquareOutlined, PlusSquareOutlined} from '@ant-design/icons';

//showPageRows：table默认行高39，通过 显示行数*39 调整table高度
export type WdiTableProps<RecordType> = {
  dataSource: any[],
  height?: number,
  aggrColumns?: (string | aggrColumnProps)[],
  showPageRows?: number,
  extraFooterSummary?: () => void,
  footerSummaryFixed?: boolean;
  showExpandedRow?: boolean;
} & TableProps<RecordType>;


export type aggrColumnProps = {
  columnName: string;
  formatter: (result: string) => any;
  digits?: number;
}

const FooterSummary = (columns: any, dataSource: any, aggrColumns?: (string | aggrColumnProps)[], extraFooterSummary?: any, footerSummaryFixed?: boolean, showExpandedRow?: boolean) => {
  return (data: any) => {
    return (
      <Table.Summary fixed={footerSummaryFixed}>
        {aggrColumns && aggrColumns.length > 0 && <Table.Summary.Row style={{background: '#fafafa'}}>
          {showExpandedRow && <Table.Summary.Cell index={-1} key="__ExpandedRow"/>}
          {columns?.map((col: any, itemIndex: any) => {
            let aggrValue = '';
            aggrColumns.forEach((item: any) => {
              if (col.dataIndex == item || col.dataIndex == item.columnName) {
                aggrValue = TableColumnRanderFormats.sumAggr(col.dataIndex, (item?.digits) ?? 2)(col.dataIndex, dataSource, {});
              }
              if (typeof item != 'string' && col.dataIndex == item.columnName) {
                aggrValue = item.formatter(aggrValue)
              }
            })
            if (itemIndex === 0) {
              return (
                <Table.Summary.Cell index={itemIndex} key={col.dataIndex}>
                  合计: {aggrValue}
                </Table.Summary.Cell>
              );
            } else {
              return (
                <Table.Summary.Cell index={itemIndex} key={col.dataIndex}>
                  <Typography.Paragraph ellipsis={{rows: 1,}} title={aggrValue} style={{margin: 0}}>
                    {aggrValue}
                  </Typography.Paragraph>
                </Table.Summary.Cell>
              );
            }
          })}
        </Table.Summary.Row>}
        {extraFooterSummary && extraFooterSummary()}
      </Table.Summary>
    );
  };
};

const ResizableTitle = (props: any) => {
  const {onResize, width, ...restProps} = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false,
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export const WdiTable: React.FC<WdiTableProps<any>> = (props) => {
  const ref: any = useRef(null);
  const [width, setWidth] = useState<number>();
  const [showAllExpanded, setShowAllExpanded] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  useLayoutEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, []);

  const {...newProps} = Object.assign({}, props);
  const [columns, setColumns] = useState<any[]>(newProps.columns || []);

  const dataSource = DataStore.init(newProps.dataSource);

  if (!newProps.rowKey) {
    newProps.rowKey = 'uid';
  }

  if (newProps.pagination !== false) {
    //添加默认pageSize
    const pagination = newProps.pagination;
    const pageSizeOptions = [10, 20, 50, 100];
    if (pagination && pagination.defaultPageSize && !pageSizeOptions.includes(pagination.defaultPageSize)) {
      pageSizeOptions.push(pagination.defaultPageSize);
      pageSizeOptions.sort((a, b) => {
        return a - b;
      });
    }
    newProps.pagination = Object.assign({}, {
      size: 'small',
      pageSizeOptions: pageSizeOptions,
      showSizeChanger: true,
      showQuickJumper: true,
      total: dataSource.length,
      showTotal: ((total: any) => {
        return `共 ${total} 条`
      })
    }, pagination ?? {});
  }
  if ((newProps.aggrColumns && newProps.aggrColumns.length > 0) || newProps.extraFooterSummary) {
    newProps.summary = FooterSummary(
      newProps.columns,
      dataSource,
      newProps.aggrColumns,
      newProps.extraFooterSummary,
      newProps.footerSummaryFixed,
      newProps.showExpandedRow
    );
  }

  useEffect(() => {
    if (props.columns) {
      setColumns(props.columns);
    }
  }, [props.columns]);

  const handleResize = (index: number) => (_: any, {size}: any) => {
    const newColumns = [...columns];
    if (size.width) {
      newColumns[index] = {...newColumns[index], width: size.width};
    }
    setColumns(newColumns);
  };

  const fitWidgetTable: any = Utility.fitColumnWidth(columns, width);
  let mergeColumns = fitWidgetTable.columns.map((col: any, index: number) => {
    return {
      ...col,
      onHeaderCell: (column: any) => ({
        width: column.width,
        onResize: handleResize(index),
      }),
    }
  });
  //设置 xy 轴
  newProps.scroll = {}
  if (newProps.height) {
    newProps.scroll = {y: newProps.height, ...newProps.scroll};
  } else if (newProps.showPageRows) {
    newProps.scroll = {y: newProps.showPageRows * 39, ...newProps.scroll};
  }
  if (fitWidgetTable.scrollX) {
    newProps.scroll = {x: fitWidgetTable.scrollX, ...newProps.scroll};
  }
  const components = newProps.components;
  newProps.components = Object.assign({}, {
    header: {
      cell: ResizableTitle,
    }
  }, components ?? {});

  newProps.locale = {emptyText: "暂无数据"};

  const handleExpandedAllRow = (showAllExpanded: boolean) => {
    if (showAllExpanded) {
      setExpandedRowKeys((dataSource).map((record: any) => record.uid))
    } else {
      setExpandedRowKeys([]);
    }
    setShowAllExpanded(showAllExpanded);
  };

  const toggleExpandedRowKeys = (key: string) => {
    const newExpandedRowKeys = [...expandedRowKeys];
    const record = dataSource.filter((item) => item.uid == key);
    if (newExpandedRowKeys.includes(key)) {
      setExpandedRowKeys(newExpandedRowKeys.filter((e) => e !== key));
      newProps.expandable?.onExpand?.(false, record);
    } else {
      newExpandedRowKeys.push(key);
      setExpandedRowKeys(newExpandedRowKeys);
      newProps.expandable?.onExpand?.(true, record);
    }
  };

  const expendedRowColumn = () => {
    return {
      dataIndex: '__ExpandedRow',
      key: '__ExpandedRow',
      title: () =>
        showAllExpanded ? (
          <MinusSquareOutlined onClick={(e) => handleExpandedAllRow(false)}/>
        ) : (
          <PlusSquareOutlined onClick={(e) => handleExpandedAllRow(true)}/>
        ),
      render: (_: any, record: any) =>
        expandedRowKeys.includes(record.uid) ? (
          <MinusSquareOutlined onClick={() => toggleExpandedRowKeys(record.uid)}/>
        ) : (
          <PlusSquareOutlined onClick={() => toggleExpandedRowKeys(record.uid)}/>
        ),
      width: 30,
    }
  }

  if (newProps.showExpandedRow) {
    mergeColumns = [expendedRowColumn(), ...mergeColumns];
    newProps.expandable = Object.assign({
      expandedRowKeys: expandedRowKeys,
      expandIconColumnIndex: -1,
    }, {...newProps.expandable});
  }

  const antdProps = Object.assign({}, _omit(newProps, 'columns'), {dataSource});

  mergeColumns.map((column: any) => {
    if (column.ellipsis && (column.ellipsis == true || (column.ellipsis.showTitle && column.ellipsis.showTitle == true))) {
      if (!column.render) {
        column.ellipsis = {showTitle: false};
        column.render = (text: any, record: any, index: number) => {
          if (text && text.length > 10) {
            return <Popover placement="topLeft" content={<div style={{maxWidth: 800}}>{text}</div>}>
              {text}
            </Popover>
          } else {
            return <span title={text}>{text}</span>
          }
        }
      } else {
        const newColumn = {...column};
        column.ellipsis = {showTitle: false};
        column.render = (text: any, record: any, index: number) => {
          const title = newColumn.render(text, record, index);
          if (typeof title == 'string') {
            if (title && title.length > 10) {
              return <Popover placement="topLeft" content={<div style={{maxWidth: 800}}>{title}</div>}>
                {title}
              </Popover>
            } else {
              return <span title={title}>{title}</span>
            }
          } else {
            return title;
          }
        }
      }
    }
  })
  return (
    <div className='wdi-table-panel' ref={ref}>
      <Table
        size='small'
        columns={mergeColumns}
        {...antdProps}
      />
    </div>
  )
}
