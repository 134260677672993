import {formatter, request} from '@/utils/request';

export namespace BOHQ {
  export const Path = {
    Query: '/mdmapi/bohq/query',
    Search: '/mdmapi/bohq/search',
    AjaxSearch: '/mdmapi/bohq/ajaxSearch',
    SearchMkt: '/mdmapi/bohq/searchMkt',
    AjaxSearchMkt: '/mdmapi/bohq/ajaxSearchMkt',
    SearchAll: '/mdmapi/bohq/searchAll',
    AjaxSearchAll: '/mdmapi/bohq/ajaxSearchAll',
    Get: '/mdmapi/bohq/get',
    Post: '/mdmapi/bohq/post',
    Delete: '/mdmapi/bohq/delete',
  };

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.QueryForBOHQData>>(Path.Query, formatter(options));
  }

  export async function search(body: API.BOHQFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.BOHQ[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.BOHQFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.BOHQ[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function searchMkt(body: API.BOHQFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.BOHQ[]>>(Path.SearchMkt, formatter(options));
  }

  export async function ajaxSearchMkt(body: API.BOHQFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.BOHQ[]>>(Path.AjaxSearchMkt, formatter(options));
  }

  export async function searchAll(body: API.BOHQFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.BOHQ[]>>(Path.SearchAll, formatter(options));
  }

  export async function ajaxSearchAll(body: API.BOHQFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.BOHQ[]>>(Path.AjaxSearchAll, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.BOHQ>>(Path.Get, formatter(options));
  }

  export async function post(body: API.BOHQ) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }
}
