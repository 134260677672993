import React, {useEffect, useRef, useState} from 'react';
import {Tabs, message, Row, Col, Form, Checkbox} from 'antd';
import ProForm, {ProFormInstance} from '@ant-design/pro-form';
import {FileUploadUtility, WdiFileList} from '@/components/WdiControls/WdiFileUpload';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import {FormPageProps} from '@/components/WdiBus/FormPage';
import {initPageContext, ViewEntityProps} from '../../SalesContract/components/data';
import {ResponseStatus} from '@/utils/request';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {WdiCardTitle, wdiCore, WdiInput, WdiTextArea} from '@/components/WdiControls';
import SalesContractTermsForApproval from './details';
import {SalesContractTerms} from '@/services/contract/salescontractterms';
import DataStore from '@/components/GridWidget/DataStore';
import {WdiButtonContainer} from '@/components/WdiBus/Buttons';
import {SalesContractMoreWapper} from '../../SalesContract/components/Button';
import SalesContractHistoryForPanel from './history';
import {SalesContractHistory} from '@/services/contract/salescontracthistory';
import {Enum} from '@/enums/system';

const SalesContractApproval: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>();
  const [salesContractHistorys, setSalesContractHistorys] = useState<any[]>();
  const [entity, setEntity] = useState<ViewEntityProps>();

  const [contractType, setContractType] = useState<number>();

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity = result.data;
        setContractType(viewEntity.contractType);
        const {salesContract, soldTo, custSys, bohq, modifiedUser} = viewEntity;
        if (salesContract) {
          formRef.current?.setFieldsValue({
            contractCode: salesContract.contractCode,
            contractName: salesContract.contractName,
            contractDate: wdiCore.Date.ToString(salesContract.beginDate, "yyyyMM") + ' ~ ' + wdiCore.Date.ToString(salesContract.endDate, "yyyyMM"),
            owner: salesContract.owner,
            signDate: wdiCore.Date.ToString(salesContract.signDate, "yyyy-MM-dd"),
            customerSignatory: salesContract.customerSignatory,
            modifiedDate: salesContract.modifiedDate,
            contractVersion: salesContract.contractVersion,
            remark: salesContract.remark,
            isAutoAppend: salesContract.isAutoAppend,
            isOfficial: salesContract.isOfficial,
            fileInfo: FileUploadUtility.parse(salesContract.fileName, salesContract.filePath),
          });
          SalesContractTerms.getSalesContractTerms(salesContract.salesContractId).then((response) => {
            setDataSource(DataStore.init(response.data));
          });
          SalesContractHistory.query(salesContract.salesContractId).then((response) => {
            setSalesContractHistorys(DataStore.init(response.data));
          });
        }
        if (soldTo) {
          formRef.current?.setFieldsValue({
            soldToCode: wdiCore.String.CodeOrName(soldTo.soldToCode, soldTo.soldToName),
          });
        }
        if (custSys) {
          formRef.current?.setFieldsValue({
            custSysCode: wdiCore.String.CodeOrName(custSys.custSysCode, custSys.custSysName),
          });
        }
        if (bohq) {
          formRef.current?.setFieldsValue({
            bohqId: bohq.bohqName
          });
        }
        if (modifiedUser) {
          console.log(modifiedUser);
          formRef.current?.setFieldsValue({
            modifiedUserId: modifiedUser.name
          });
        }
        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      var title = "";
      if (contractType != null && contractType == Enum.SalesContractType.SELLIN) {
        title = "一级客户合同信息";
      } else {
        title = "二级客户合同信息";
      }
      if (!entity?.salesContract?.isActive) {
        title += "，已无效";
      } else if (entity?.salesContract?.statusId == Enum.SalesContractStatus.CONFIRMED) {
        title += "，有效";
      }
      // else if (entity?.salesContract?.statusId == Enum.SalesContractStatus.UNCONFIRMED) {
      //   title += "，已确认";
      // }


      if (props.setPageTitle) props.setPageTitle(<div>
        <span>{title}</span>
        <WdiButtonContainer>
          <SalesContractMoreWapper
            onClose={props.onClose}
            callback={props.callback}
            salesContractId={Number(entity?.salesContract?.salesContractId)}
            contractType={Number(entity?.contractType)}
            isActive={entity?.salesContract?.isActive}
            statusId={Number(entity?.salesContract?.statusId)}
          />
        </WdiButtonContainer>
      </div>);

    }
  }, [props.setPageTitle, entity]);

  return (
    <>

      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab={<span>资料</span>} key="1">
          <ProForm
            formRef={formRef}
            submitter={{
              render: () => {
                return (<></>)
              },
            }}
          >
            <WdiPanel collapsed={true} title={<WdiCardTitle iconType={Enum.IconType.CONTRACT} title='合同信息'/>}>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={4}
                     hidden={!(contractType != null && contractType == Enum.SalesContractType.SELLOUT)}>
                  <Form.Item
                    name="custSysCode"
                    label="客户集团"
                    rules={[{required: false, message: '客户集团不能为空!'}]}
                  >
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    name="soldToCode"
                    label="售达方"
                    rules={[{required: false, message: '售达方不能为空!'}]}
                  >
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item
                    name="bohqId"
                    label="费用归属"
                    rules={[{required: false, message: '费用归属不能为空!'}]}>
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="contractCode" label="合同编号">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="contractName" label="合同名称">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="contractDate" label="合同期间" rules={[{required: false, message: '活动期间不能为空!'}]}>
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}
                     hidden={contractType != null && contractType == Enum.SalesContractType.SELLOUT}>
                  <Form.Item name="owner" label="合同负责人">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={4}
                     hidden={!(contractType != null && contractType == Enum.SalesContractType.SELLOUT)}>
                  <Form.Item name="owner" label="合同负责人">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="signDate" label="签约日期">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="customerSignatory" label="客户方签约人">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}
                     hidden={contractType != null && contractType == Enum.SalesContractType.SELLOUT}>
                  <Form.Item name="modifiedUserId" label="合同修改人">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="modifiedDate" label="合同修改时间">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="contractVersion" label="合同版本号">
                    <WdiInput readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="isAutoAppend" label="是否自动费用追溯" valuePropName="checked">
                    <Checkbox disabled></Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={12}>
                  <Form.Item name="remark" label="合同备注" rules={[{required: false, message: '合同备注不能为空!'}]}>
                    <WdiTextArea rows={4} readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="isOfficial" label="是否正式合同" valuePropName="checked">
                    <Checkbox disabled></Checkbox>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="fileInfo"
                    label="附件"
                    valuePropName='fileList'
                    getValueFromEvent={(e: any) => {
                      return e.fileList || [];
                    }}
                  >
                    <WdiFileList/>
                  </Form.Item>
                </Col>
              </Row>
            </WdiPanel>
          </ProForm>
          {dataSource &&
            <SalesContractTermsForApproval
              dataSource={dataSource} data={{contractType: contractType}}/>
          }
        </TabPane>
        <TabPane tab={<span>动态信息</span>} key="2">
          {salesContractHistorys &&
            <SalesContractHistoryForPanel
              dataSource={salesContractHistorys}/>
          }
        </TabPane>
      </Tabs>

    </>
  );
};
export default SalesContractApproval;
