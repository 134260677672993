import React, {useEffect, useState} from 'react';
import {ColumnsType} from 'antd/lib/table';
import PanelForWarningApp, {
  WarningInfoAppProps,
  WarningInfoApprovalProps,
  WdiGridApp,
  WdiObjApp
} from '@/components/WdiBus/PanelForWarning';
import {Empty} from "antd";
import {FormSs} from "@/services/sales/ss";
import {wdiCore} from "@/components/WdiControls";
import {Enum} from "@/enums/system";

const SsBudget: Record<string, any> = {
  bgId: (_: any, record: any) => {
    return record['bgName'];
  },
  productLineId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  },
  ym: (value: any, record: any) => {
    return value;
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amount']);
  },
  revenueMtd: (value: any, record: any) => {
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      return <span style={{color: '#ff0000'}}>{record['revenueMtd']}</span>;
    }

    return wdiCore.Number.AmountToString(value);
  },
  expenseMtd: (value: any, record: any) => {
    return wdiCore.Number.AmountToString(value);
  },
  rateActualMtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      isError = true;
    }

    if (record['rateBaselineMtd'] == null) {
      hasStandard = false;
    }

    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      let isRed: boolean = false;
      if (record['revenueMtd'] == null || record['revenueMtd'] == 0
        || record['rateBaselineMtd'] == null || record['rateActualMtd'] > record['rateBaselineMtd']) {
        isRed = true;
      }

      return <>
        <span
          style={isRed ? {color: "#ff0000"} : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualMtd']) : "error"}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineMtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span
        style={isError ? {color: "#ff0000"} : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualMtd']) : "error"}</span>;
    }

    return "";
  },
  rateActualMtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxMtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  revenueYtd: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{
            color: isError ? "#ff0000" : "#000000"
          }}>{wdiCore.Number.AmountToString(record['revenueYtd'])}</span>
    </>;
  },
  expenseYtd: (value: any, record: any) => {
    return wdiCore.Number.AmountToString(value);
  },
  rateActualYtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      isError = true;
    }

    if (record['rateBaselineYtd'] == null) {
      hasStandard = false;
    }

    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      let isRed: boolean = false;
      if (record['revenueYtd'] == null || record['revenueYtd'] == 0
        || record['rateBaselineYtd'] == null || record['rateActualYtd'] > record['rateBaselineYtd']) {
        isRed = true;
      }

      return <>
        <span
          style={isRed ? {color: "#ff0000"} : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualYtd']) : "error"}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineYtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span
        style={isError ? {color: "#ff0000"} : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualYtd']) : "error"}</span>;
    }

    return "";
  },
  rateActualYtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxYtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdLyTotal: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{
            color: isError ? "#ff0000" : "#000000"
          }}>{isError ? 'error' : wdiCore.Number.RateToString(record['rateActualYtdLyTotal'])}</span>
    </>;
  },
}
const SsBudgetImportant: Record<string, any> = {

  bgId: (_: any, record: any) => {
    return record['bgName'];
  },
  productLineId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amount']);
  },
  revenueMtd: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{wdiCore.Number.AmountToString(value)}</span>
    </>;
  },
  expenseMtd: (value: any, record: any) => {
    return wdiCore.Number.AmountToString(value);
  },
  rateActualMtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      isError = true;
    }

    if (record['rateBaselineMtd'] == null) {
      hasStandard = false;
    }

    let isRed: boolean = false;
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0
      || record['rateBaselineMtd'] == null || record['rateActualMtd'] > record['rateBaselineMtd']) {
      isRed = true;
    }

    return <>
      <span
        style={isRed ? {color: "#ff0000"} : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualMtd']) : "error"}</span>
      <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineMtd']) : "未设置"})</span>
    </>;
  },
  rateActualMtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
}
const OverApplyPayment: Record<string, any> = {
  formSaDetailId: (_: any, record: any) => {
    return record['formSaDetailId'];
  },
  soldTo: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['soldToCode'], record['soldToName']);
  },
  custSys: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['custSysCode'], record['custSysName']);
  },
  product: (_: any, record: any) => {
    return record['productBuyNames'];
  },
  ec: (_: any, record: any) => {
    return record['ecName'];
  },
  platform: (_: any, record: any) => {
    return record['platformName'];
  },
  district: (_: any, record: any) => {
    return record['districtName'];
  },
  promotionDesc: (_: any, record: any) => {
    return record['promotionDesc'];
  },
  amountSa: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amountSa']);
  },
  amountSs: (_: any, record: any) => {
    return <span style={{color: '#ff0000'}}>{wdiCore.Number.AmountToString(record['amountSs'])}</span>;
  },
}
const SsExpenseRate: Record<string, any> = {
  custSys: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['custSysCode'], record['custSysName']);
  },
  productLine: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ec: (_: any, record: any) => {
    return record['ecName'];
  },
  ym: (value: any, record: any) => {
    return value;
  },
  amountSa: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amountSa']);
  },
  revenueForecast: (_: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueForecast'] == null || record['revenueForecast'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{wdiCore.Number.AmountToString(record['revenueForecast'])}</span>
    </>;
  },
  rateForecast: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueForecast'] == null || record['revenueForecast'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{!isError ? wdiCore.Number.RateToString(value) : 'error'}</span>
    </>;
  },
  amountSs: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amountSs']);
  },

  amountSsSubmitted: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amountSsSubmitted']);
  },
  revenueActual: (_: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueActual'] == null || record['revenueActual'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{wdiCore.Number.AmountToString(record['revenueActual'])}</span>
    </>;
  },
  rateActual: (value: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;
    if (record['revenueActual'] == null || record['revenueActual'] == 0 || record['rateActual'] > record['rateForecast']) {
      isError = true;
    }
    if (record['revenueActual'] == null || record['revenueActual'] == 0) {
      hasStandard = false;
    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{hasStandard ? wdiCore.Number.RateToString(value) : 'error'}</span>
    </>;
  },
}
const OverPromotionRate: Record<string, any> = {
  formNo: (_: any, record: any) => {
    return record['formSaNo'];
  },
  custSys: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['custSysCode'], record['custSysName']);
  },
  productGroup: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productGroupCode'], record['productGroupName']);
  },
  ec: (_: any, record: any) => {
    return record['ecName'];
  },
  ym: (value: any, record: any) => {
    return value;
  },
  quantityActual: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['quantityActual'], 0);
  },
  rateStandard2: (value: any, record: any) => {
    return <>
      <span>{wdiCore.Number.RateToString(value)}</span>
    </>;
  },
  ratePlan2: (value: any, record: any) => {
    return <>
      <span>{wdiCore.Number.RateToString(value)}</span>
    </>;
  },
  rateStandardTotal: (value: any, record: any) => {
    return <>
      <span>{wdiCore.Number.RateToString(value)}</span>
    </>;
  },
  ratePlanTotal: (value: any, record: any) => {
    return <>
      <span>{wdiCore.Number.RateToString(value)}</span>
    </>;
  },
  quantityActual1: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['quantityActual1'], 0);
  },
  quantityActual2: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['quantityActual2'], 0);
  },
  rateActual2: (value: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;
    if (record['rateActual2'] > record['rateStandard2'] || record['rateActual2'] > record['ratePlan2']) {
      isError = true;
    }
    if (record['quantityActual'] == null || record['quantityActual'] == 0) {
      isError = true;
      hasStandard = false;

    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{hasStandard ? wdiCore.Number.RateToString(value) : 'error'}</span>
    </>;
  },
  rateActualTotal: (value: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;
    if (record['rateActualTotal'] > record['rateStandardTotal'] || record['rateActual2'] > record['ratePlanTotal']) {
      isError = true;
    }
    if (record['quantityActual'] == null || record['quantityActual'] == 0) {
      hasStandard = false;
      isError = true;
    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{hasStandard ? wdiCore.Number.RateToString(value) : 'error'}</span>
    </>;
  },
}
const OverStandardRate: Record<string, any> = {
  saDetailId: (_: any, record: any) => {
    return record['saDetailId'];
  },
  custSys: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['custSysCode'], record['custSysName']);
  },
  soldTo: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['soldToCode'], record['soldToName']);
  },
  product: (_: any, record: any) => {
    return record['productBuyNames'];
  },
  ec: (_: any, record: any) => {
    return record['ecName'];
  },
  ym: (value: any, record: any) => {
    return value;
  },
  unitExpenseStandard: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['unitExpenseStandard']);
  },
  unitExpenseForecast1: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['unitExpenseForecast1']);
  },
  unitExpenseForecast2: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['unitExpenseForecast2']);
  },
  quantityActual1: (_: any, record: any) => {
    return wdiCore.Number.ToNumber(record['quantityActual1'], 0);
  },
  unitExpenseActual1: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['unitExpenseActual1'] > record['unitExpenseStandard'] || record['unitExpenseActual1'] > record['unitExpenseForecast1']) {
      isError = true;
    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{wdiCore.Number.AmountToString(value)}</span>
    </>;
  },
  quantityActual2: (_: any, record: any) => {
    return wdiCore.Number.ToNumber(record['quantityActual2'], 0);
  },
  unitExpenseActual2: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['unitExpenseActual2'] > record['unitExpenseStandard'] || record['unitExpenseActual2'] > record['unitExpenseForecast2']) {
      isError = true;
    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{wdiCore.Number.AmountToString(value)}</span>
    </>;
  },

}
const OverRateStandard: Record<string, any> = {
  bgId: (_: any, record: any) => {
    return record['bgName'];
  },
  productLineId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  },
  ym: (value: any, record: any) => {
    return value;
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amount']);
  },
  revenueMtd: (value: any, record: any) => {
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      return <span style={{color: '#ff0000'}}>{record['revenueMtd']}</span>;
    }

    return wdiCore.Number.AmountToString(value);
  },
  expenseMtd: (value: any, record: any) => {
    return wdiCore.Number.AmountToString(value);
  },
  rateActualMtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      isError = true;
    }

    if (record['rateBaselineMtd'] == null) {
      hasStandard = false;
    }

    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      let isRed: boolean = false;
      if (record['revenueMtd'] == null || record['revenueMtd'] == 0
        || record['rateBaselineMtd'] == null || record['rateActualMtd'] > record['rateBaselineMtd']) {
        isRed = true;
      }

      return <>
        <span
          style={isRed ? {color: "#ff0000"} : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualMtd']) : "error"}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineMtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span
        style={isError ? {color: "#ff0000"} : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualMtd']) : "error"}</span>;
    }

    return "";
  },
  rateActualMtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxMtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  revenueYtd: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{
            color: isError ? "#ff0000" : "#000000"
          }}>{wdiCore.Number.AmountToString(record['revenueYtd'])}</span>
    </>;
  },
  expenseYtd: (value: any, record: any) => {
    return wdiCore.Number.AmountToString(value);
  },
  rateActualYtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      isError = true;
    }

    if (record['rateBaselineYtd'] == null) {
      hasStandard = false;
    }

    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      let isRed: boolean = false;
      if (record['revenueYtd'] == null || record['revenueYtd'] == 0
        || record['rateBaselineYtd'] == null || record['rateActualYtd'] > record['rateBaselineYtd']) {
        isRed = true;
      }

      return <>
        <span
          style={isRed ? {color: "#ff0000"} : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualYtd']) : "error"}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineYtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span
        style={isError ? {color: "#ff0000"} : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualYtd']) : "error"}</span>;
    }

    return "";
  },
  rateActualYtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxYtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdLyTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
}

const OverQuantityActual: Record<string, any> = {
  soldToCode: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['soldToCode'], record['soldToName']);
  },
  custSysCode: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['custSysCode'], record['custSysName']);
  },
  productId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productCode'], record['productName']);
  },
  platformId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['platformCode'], record['platformName']);
  },
  ym: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  salesTypeName: (value: any, record: any) => {
    return value;
  },
  quantityActualTotal: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  quantityApproved1: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  quantityApproved2: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  quantityActual1: (value: any, record: any) => {
    return <span style={{ color: "#ff0000" }}>{wdiCore.Number.ToNumber(value, 0)}</span>;
  },
  quantityActual2: (value: any, record: any) => {
    return <span style={{ color: "#ff0000" }}>{wdiCore.Number.ToNumber(value, 0)}</span>;
  }
}


const columnsRenders: Record<string, Record<string, any>> = {
  OverQuantityActual:OverQuantityActual,
  SsBudget: SsBudget,
  SsBudgetImportant: SsBudgetImportant,
  OverApplyPayment: OverApplyPayment,
  SsExpenseRate: SsExpenseRate,
  OverPromotionRate: OverPromotionRate,
  OverStandardRate: OverStandardRate,
  OverRateStandard: OverRateStandard,
};
const SsBudgetExportColumns: any[] = [
  {name: 'bgName', displayName: '预算组'},
  {name: 'productLineCode', displayName: '产品线编号'},
  {name: 'productLineName', displayName: '产品线名称'},
  {name: 'ecName', displayName: '费用小类'},
  {name: 'ym', displayName: '年月'},
  {name: 'amount', displayName: '本次核销金额'},
  {name: 'revenueMtd', displayName: 'MTD SSV'},
  {name: 'expenseMtd', displayName: 'MTD TS'},
  {name: 'rateActualMtd', displayName: 'MTD费率', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'rateMaxMtd', displayName: 'MTD浮动上限', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'revenueYtd', displayName: 'YTD SSV'},
  {name: 'expenseYtd', displayName: 'YTD TS'},
  {name: 'rateActualYtd', displayName: 'YTD费率', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'rateMaxYtd', displayName: 'YTD浮动上限', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'rateActualYtdTotal', displayName: 'YTD总费率', type: Enum.FieldType.NUMBER, format: 'p2'},
]

const SsBudgetImportantExportColumns: any[] = [
  {name: 'bgName', displayName: '预算组'},
  {name: 'productLineCode', displayName: '产品线编号'},
  {name: 'productLineName', displayName: '产品线名称'},
  {name: 'ecName', displayName: '费用项'},
  {name: 'ym', displayName: '年月'},
  {name: 'amount', displayName: '本次核销金额'},
  {name: 'revenueMtd', displayName: 'MTD SSV'},
  {name: 'expenseMtd', displayName: 'MTD TS'},
  {name: 'rateActualMtd', displayName: 'MTD费率'},
]
const OverApplyPaymentExportColumns: any[] = [
  {name: 'saDetailId', displayName: '活动明细编号'},
  {name: 'soldToCode', displayName: '售达方编号'},
  {name: 'soldToName', displayName: '售达方名称'},
  {name: 'custSysCode', displayName: '客户集团编号'},
  {name: 'custSysName', displayName: '客户集团名称'},
  {name: 'productBuyNames', displayName: '产品别'},
  {name: 'ecName', displayName: '费用项'},
  {name: 'platformName', displayName: '分子平台'},
  {name: 'districtName', displayName: '省份'},
  {name: 'promotionDesc', displayName: '活动机制'},
  {name: 'amountSa', displayName: '申请金额'},
  {name: 'amountSs', displayName: '本次核销金额'},
]
const SsExpenseRateExportColumns: any[] = [
  {name: 'custSysCode', displayName: '客户集团编号'},
  {name: 'custSysName', displayName: '客户集团名称'},
  {name: 'productLineCode', displayName: '产品线编号'},
  {name: 'productLineName', displayName: '产品线名称'},
  {name: 'ecName', displayName: '费用小类'},
  {name: 'ym', displayName: '年月'},
  {name: 'amountSa', displayName: '申请金额'},
  {name: 'revenueForecast', displayName: '预估销售额'},
  {name: 'rateForecast', displayName: '计划费率', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'amountSs', displayName: '本次核销金额'},
  {name: 'amountSsSubmitted', displayName: '已核销金额'},
  {name: 'revenueActual', displayName: '实际销售额'},
  {name: 'rateActual', displayName: '实际费率', type: Enum.FieldType.NUMBER, format: 'p2'},
]
const OverPromotionRateExportColumns: any[] = [
  {name: 'formSaNo', displayName: '活动编号'},
  {name: 'custSysCode', displayName: '客户集团编号'},
  {name: 'custSysName', displayName: '客户集团名称'},
  {name: 'productGroupCode', displayName: '产品组编号'},
  {name: 'productGroupName', displayName: '产品组名称'},
  {name: 'ecName', displayName: '费用项'},
  {name: 'ym', displayName: '年月'},
  {name: 'quantityActual', displayName: '实际总销量'},
  {name: 'rateStandard2', displayName: '二促建议占比', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'ratePlan2', displayName: '二促计划占比', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'rateStandardTotal', displayName: '总促销建议占比', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'ratePlanTotal', displayName: '总促销计划占比', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'quantityActual1', displayName: '本次一促实际销量累计'},
  {name: 'quantityActual2', displayName: '本次二促实际销量累计'},
  {name: 'rateActual2', displayName: '本次二促占比', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'rateActualTotal', displayName: '本次促销总销量占比', type: Enum.FieldType.NUMBER, format: 'p2'},
]
const OverStandardRateExportColumns: any[] = [
  {name: 'saDetailId', displayName: '活动明细编号'},
  {name: 'custSysCode', displayName: '客户集团编号'},
  {name: 'custSysName', displayName: '客户集团名称'},
  {name: 'soldToCode', displayName: '售达方编号'},
  {name: 'soldToName', displayName: '售达方名称'},
  {name: 'productGroupCode', displayName: '产品组编号'},
  {name: 'productGroupName', displayName: '产品组名称'},
  {name: 'ecName', displayName: '费用项'},
  {name: 'ym', displayName: '年月'},
  {name: 'unitExpenseStandard', displayName: '补贴标准'},
  {name: 'unitExpenseForecast1', displayName: '一促计划单听补贴'},
  {name: 'unitExpenseForecast2', displayName: '二促计划单听补贴'},
  {name: 'quantityActual1', displayName: '一促实际销量'},
  {name: 'unitExpenseActual1', displayName: '一促实际单听补贴'},
  {name: 'quantityActual2', displayName: '二促实际销量'},
  {name: 'unitExpenseActual2', displayName: '二促实际单听补贴'},
]
const OverRateStandardExportColumns: any[] = [
  {name: 'bgName', displayName: '预算组'},
  {name: 'productLineCode', displayName: '产品线编号'},
  {name: 'productLineName', displayName: '产品线名称'},
  {name: 'ecName', displayName: '费用小类'},
  {name: 'ym', displayName: '年月'},
  {name: 'amount', displayName: '本次核销金额'},
  {name: 'revenueMtd', displayName: 'MTD SSV'},
  {name: 'expenseMtd', displayName: 'MTD TS'},
  {name: 'rateActualMtd', displayName: 'MTD费率', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'rateMaxMtd', displayName: 'MTD浮动上限', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'revenueYtd', displayName: 'YTD SSV'},
  {name: 'expenseYtd', displayName: 'YTD TS'},
  {name: 'rateActualYtd', displayName: 'YTD费率', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'rateMaxYtd', displayName: 'YTD浮动上限', type: Enum.FieldType.NUMBER, format: 'p2'},
  {name: 'rateActualYtdTotal', displayName: 'YTD总费率', type: Enum.FieldType.NUMBER, format: 'p2'},
]
const exportColumnsRenders: Record<string, any[]> = {
  SsBudget: SsBudgetExportColumns,
  SsBudgetImportant: SsBudgetImportantExportColumns,
  OverApplyPayment: OverApplyPaymentExportColumns,
  SsExpenseRate: SsExpenseRateExportColumns,
  OverPromotionRate: OverPromotionRateExportColumns,
  OverStandardRate: OverStandardRateExportColumns,
  OverRateStandard: OverRateStandardExportColumns
}

const renderWdiGridApp = (props: API.WdiGrid, index: number, isExport: boolean) => {
  const columns: ColumnsType<any> = [];
  const columnsRender = columnsRenders[props.key];
  const exportColumnsRender = exportColumnsRenders[props.key];
  props.wdiColumns.map((wdiColumn) => {
    if (!wdiColumn.isHidden) {
      const column: any = {
        title: wdiColumn.displayName,
        dataIndex: wdiColumn.name,
        width: wdiColumn.width,
        ellipsis: true,
      };
      if (columnsRender && columnsRender[wdiColumn.name]) {
        column.render = columnsRender[wdiColumn.name];
      }
      columns.push(column);
    }
  });

  return (
    (props.status != 0 || props.showAlways == true)
    && <WdiGridApp key={props.key} wdiGrid={props} columns={columns} dataSource={props.items} isExport={isExport}
                   exportColumns={exportColumnsRender}/>
  );
};
const renderWdiObjApp = (props: API.WdiObj, index: number) => {
  return (
    (props.status != 0 || props.showAlways == true)
    && <WdiObjApp key={index} wdiObj={props} message={props.target}/>
  );
};

const SsWarningInfoApp: React.FC<WarningInfoAppProps> = (props) => {
  let status: 0 | 1 | 2 = 0;
  props.wdiPanelList?.items.map((item) => {
    if (item.status === 2) {
      status = 2;
    }
    if (status !== 2 && item.status === 1) {
      status = 1;
    }
  });
  return (
    <PanelForWarningApp
      title={props.title}
      visible={props.visible}
      status={status}
      onClose={props.onClose}
      onSave={props.onSave}
    >
      {props.wdiPanelList?.items.map((item, index) => {
        if (item.type === 'WdiGrid') {
          return renderWdiGridApp(item, index, true);
        } else if (item.type === 'WdiObj') {
          return renderWdiObjApp(item, index);
        } else {
          return <></>;
        }
      })}
    </PanelForWarningApp>
  );
}
export default SsWarningInfoApp;

export const SsWarningApprovalApp: React.FC<WarningInfoApprovalProps> = (props) => {
  const [isWarning, setIsWarning] = useState(false);
  const [paneList, setPaneList] = useState<API.WdiPanelList>();
  useEffect(() => {
    if (props?.formId) {
      FormSs.Buttons.warningInfos(props?.formId).then(response => {
        if (response?.data && response?.data.items && response?.data.items.length > 0) {
          setIsWarning(true);
        }
        setPaneList(response?.data);
      });
    }
  }, [props.formId]);

  return (
    <>
      {isWarning && paneList != undefined && paneList.items.map((item, index) => {
        if (item.type === 'WdiGrid') {
          return renderWdiGridApp(item, index, true);
        } else if (item.type === 'WdiObj') {
          return renderWdiObjApp(item, index);
        } else {
          return <></>;
        }
      })}
      {!isWarning &&
        <div>
          <div style={{marginTop: "200px", textAlign: "center"}}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="没有任何警示信息"/>
          </div>
        </div>
      }
    </>
  );
};
