import {formatter, request} from '@/utils/request';

export namespace Staff {

  export const Path = {
    Current: '/basicapi/staff/current',
    Get: '/basicapi/staff/get',
    Post: '/basicapi/staff/post',
    Search: '/basicapi/staff/search',
    AjaxSearch: '/basicapi/staff/ajaxSearch',
    Query: '/basicapi/staff/query',
    Export: '/basicapi/staff/export',
    Reset: '/basicapi/staff/reset',
    modifyPassword: '/basicapi/staff/modifyPassword',
    ExportDept: '/basicapi/staff/exportDept',
    ExportPermissions: '/basicapi/staff/exportStaffPermissions',
    StaffCardInfo: '/basicapi/staff/getStaffCardInfo',
    CurrentStaffAndBu: '/basicapi/staffBu/getCurrentStaffAndBu',
  };

  export async function current() {
    const options = {
      method: 'GET',
      body: {}
    };
    return request<API.ResponseResult<API.Staff>>(Path.Current, formatter(options));
  }

  export async function reset(ids: string) {
    const options = {
      method: 'Post',
      body: {
        staffIds: ids
      },
    };
    return request<API.ResponseResult<any>>(Path.Reset, formatter(options));
  }

  export async function modifyPassword(body: any) {
    const options = {
      method: 'post',
      body: {
        ...body
      }
    }
    return request<API.ResponseResult<any>>(Path.modifyPassword, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.Staff>>(Path.Get, formatter(options));
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any[]>>(Path.Export, formatter(options));
  }

  export async function exportDeptEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any[]>>(Path.ExportDept, formatter(options));
  }

  export async function exportPermissionsEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any[]>>(Path.ExportPermissions, formatter(options));
  }

  export async function search(body: API.StaffSearchParam) {  
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Staff[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.StaffSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Staff[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function post(body: API.StaffEntity) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function staffCardInfo(body: API.StaffCard) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.StaffCardResult>>(Path.StaffCardInfo, formatter(options));
  }

  export async function getCurrentStaffAndBu(userId: number) {
    const options = {
      method: 'GET',
      body: {
        userId: userId
      },
    };
    return request<API.ResponseResult<API.StaffAndBu>>(Path.CurrentStaffAndBu, formatter(options));
  }
}


export async function modifyUserInfo(body: API.StaffUpdate) {
  const options = {
    method: 'POST',
    body: {
      ...body
    },
  };
  return request<API.ResponseResult<any>>('/basicapi/staff/postUserInfo', formatter(options));
}

