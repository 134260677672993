import {formatter, request} from '@/utils/request';

export namespace WFTemplate {

  const Path = {
    Get: '/flowapi/template/get',
    Query: '/flowapi/template/query',
    Post: '/flowapi/template/post',
    Delete: '/flowapi/template/delete'
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.WFTemplate>>(Path.Get, formatter(options));
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.WFTemplateQuery>>(Path.Query, formatter(options));
  }

  export async function post(body: API.WfTemplatePostParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.WFTemplate>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<boolean>>(Path.Delete, formatter(options));
  }
}

export namespace WFProcessTemplate {
  export const Path = {
    Empty: '/flowapi/processTemplate/empty',
    Get: '/flowapi/processTemplate/get',
    GetItem: "/flowapi/processTemplate/getItem",
    Query: '/flowapi/processTemplate/query',
    Post: '/flowapi/processTemplate/post',
    Delete: '/flowapi/processTemplate/delete',
    ValidateExpression: '/flowapi/processTemplate/validateExpression',
  }

  export async function empty(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.Chart>>(Path.Empty, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.Chart>>(Path.Get, formatter(options));
  }

  export async function getItem(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.WfProcessTemplate>>(Path.GetItem, formatter(options));
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.WFProcessTemplateQuery>>(Path.Query, formatter(options));
  }

  export async function post(body: API.WfProcessTemplatePostParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.WfProcessTemplate>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<boolean>>(Path.Delete, formatter(options));
  }

  export async function validateExpression(body: API.ValidateExpressionReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<boolean>>(Path.ValidateExpression, formatter(options));
  }
}


export namespace ProcessInstance {
  export const Path = {
    GET: "/flowapi/processInstance/get",
    GetFlowUsers: '/flowapi/processInstance/getFlowUsers',
    GetFlowTrace: '/flowapi/processInstance/getFlowTrace'
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.WFProcessInstance>>(Path.GET, formatter(options));
  }

  export async function getFlowUsers(id: number) {
    const options = {
      method: 'Get',
      body: {
        processId: id
      },
    };
    return request<API.ResponseResult<API.FlowUser[]>>(Path.GetFlowUsers, formatter(options));
  }

  export async function getFlowTrace(id: number) {
    const options = {
      method: 'Get',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.FlowNode[]>>(Path.GetFlowTrace, formatter(options));
  }

}


export namespace Variable {
  export const Path = {
    Search: '/flowapi/variable/search',
    GetVariables: '/flowapi/variable/getVariables',
  }

  export async function search(body: API.WFVariableSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.WFVariable[]>>(Path.Search, formatter(options));
  }

  export async function getVariables(id: number) {
    const options = {
      method: 'Get',
      body: {
        templateId: id
      },
    };
    return request<API.ResponseResult<API.WFVariable[]>>(Path.GetVariables, formatter(options));
  }
}

export namespace ErrorInfo {

  const Path = {
    // API改动标准化时可以改动
    Search: "/flowapi/wfErrorInfo/getErrorInfos"
  };

  export async function Search(body: API.ErrorInfoFilter) {
    const options = {
      method: 'Get',
      body: {
        processId: body.processId
      },
    };
    return request<API.ResponseResult<API.ErrorInfo[]>>(Path.Search, formatter(options));
  }
}
