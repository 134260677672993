import {useEffect, useMemo, useRef} from 'react';
import type {WdiModalAppProps} from '@/components/WdiControls';
import type {ProFormInstance} from '@ant-design/pro-form';
import {EC} from '@/services/mdm/ec';
import {WdiSelect, WdiTreeSelect} from '@/components/WdiControls/WdiSelect';
import {Form, Input, message} from 'antd';
import {WdiModalForm} from '@/components/WdiControls/WdiModalForm';
import {ResponseStatus} from '@/utils/request';
import {WdiInputNumber} from '@/components/WdiControls/WdiInputNumber';
import {Enum} from '@/enums/system';
import {SalesContractTerms} from '@/services/contract/salescontractterms';
import {DataOptions} from '@/services/basic/metadata';
import {isDupSalesContractTerms} from "@/pages/contract/SalesContractApply/components/data";
import {FieldProps} from "@/components/WdiControls";
import {Product} from "@/services/mdm/product";
import { SoldTo } from '@/services/mdm/soldto';

const {TextArea} = Input;

const ecDataLoadingFactory = (contractType: number) => {
  return (params: any) => {
    return EC.searchEc3(
      Object.assign(
        {
          withPermissions: true,
          isActive: true,
          pageTypeIds: [contractType == Enum.SalesContractType.SELLIN
            ? String(Enum.PageType.CONTRACT_REBATE)
            : String(Enum.PageType.CONTRACT_REBATE_LEVEL_2)].join()
        },
        params,
      ),
    ).then((response) => {
      return response.data.map((item) => ({value: item.ecId, label: item.ecName}));
    });
  };
};

const processModeDataLoading = (params: any) => {
  return DataOptions.search(Object.assign({typeGroup: 13}, params)).then((response) => {
    return response.data.map((item) => ({value: item.value, label: item.name}));
  });
};

const productTreeDataLoading = (params: any) => {
  return Product.searchAll(Object.assign({
    productLevels: [Enum.ProductLevel.ROOT, Enum.ProductLevel.PRODUCT1, Enum.ProductLevel.PRODUCT2, Enum.ProductLevel.PRODUCT3, Enum.ProductLevel.PRODUCT4].join(),
    isActive: true
  }, params)).then(
    (response) => {
      const {treeData, dict} = FieldProps.TreeSelect.formatTreeData(
        {
          textField: 'productName',
          valueField: 'productId',
          id: 'productId',
          pId: 'parentId',
          level: 'productLevel',
        },
        response.data,
      );
      return {treeData, dict};
    },
  );
};

const SalesContractTermsNonAutoEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const ecDataLoading = useMemo(() => {
    return ecDataLoadingFactory(props.data?.contractType);
  }, [props.data?.contractType]);

  useEffect(() => {
    formRef.current?.setFieldsValue({
      processMode: props?.data?.row?.processMode,
    });
    if (props.data.row) {
      const detail = props.data.row;
      formRef.current?.setFieldsValue({
        salesContractTermsId: detail.salesContractTermsId,
        salesContractId: detail.salesContractId,
        ecId: detail.ecId,
        termsAmount: detail.termsAmount,
        remark: detail.remark,
      });

      if (detail.productIdsRebate) {
        Product.searchAll({productIds: detail.productIdsRebate}).then((response) => {
          if (response.data && response.data.length > 0) {
            formRef.current?.setFieldsValue({
              productIdsRebate: response.data.map((product) => ({
                value: product.productId,
                label: product.codeOrName,
              })),
            });
          }
        });
      }
    }
  }, [props.data.row, props.type]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{span: 6}}
      wrapperCol={{span: 12}}
      labelAlign="left"
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      preserve={true}
      modalProps={{
        onCancel: () => {
          props.onClose ? props.onClose() : null;
        },
        destroyOnClose: true,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        const {productIdsRebate, ...entity} = values;
        if (productIdsRebate) {
          entity.productIdsRebate = productIdsRebate.map((item: any) => item.value).join();
        }

        const response = await SalesContractTerms.validateDetail({
          salesContractTerms: entity,
          contractType:props.data?.contractType,
          soldToCode:props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode
        });

        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
        } else {
          if (isDupSalesContractTerms(props.data.rows, response.data, props.data.rowIndex)) {
            message.error("合同条款已存在");
          } else {
            if (props.onSave) {
              props.onSave({
                code: ResponseStatus.SUCCESS,
                message: '',
                data: response.data,
              });
            }
          }
        }
      }}
    >
      <Form.Item name="salesContractTermsId" label="salesContractTermsId" hidden>
        <Input/>
      </Form.Item>
      <Form.Item name="salesContractId" label="salesContractId" hidden>
        <Input/>
      </Form.Item>
      <Form.Item
        required
        name="ecId"
        label="费用项"
        rules={[{required: true, message: '费用项不能为空!'}]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择费用项',
            dataLoading: ecDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        name="processMode"
        label="处理方式"
        rules={[{required: true, message: '处理方式不能为空!'}]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择处理方式',
            disabled: true,
            dataLoading: processModeDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        name="productIdsRebate"
        label="返利产品"
        rules={[{required: true, message: '返利产品不能为空!'}]}
      >
        <WdiTreeSelect
          {...{
            multiple: true,
            placeholder: '请选择返利产品',
            dataLoading: async (params) => {
              const soldto = await SoldTo.get(props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode);

              return Product.searchAll(Object.assign({
                productLevels: [Enum.ProductLevel.ROOT, Enum.ProductLevel.PRODUCT1, Enum.ProductLevel.PRODUCT2, Enum.ProductLevel.PRODUCT3, Enum.ProductLevel.PRODUCT4].join(),
                isActive: true,
                bohqId: soldto.data.defaultBohqId
              }, params)).then(
                (response) => {
                  const {treeData, dict} = FieldProps.TreeSelect.formatTreeData(
                    {
                      textField: 'productName',
                      valueField: 'productId',
                      id: 'productId',
                      pId: 'parentId',
                      level: 'productLevel',
                    },
                    response.data,
                  );
                  return {treeData, dict};
                },
              );              
            },
            // dataLoading: productTreeDataLoading,
            onExSelect: (value: any, node: any, dict: any) => {
              const values = formRef.current?.getFieldValue('productIdsRebate');
              const rtv = FieldProps.TreeSelect.labeledValuesMeger(dict, values, {value});
              formRef.current?.setFieldsValue({
                productIdsRebate: rtv,
              });
            },
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="termsAmount"
        label="固定金额"
        rules={[{required: true, message: '固定金额不能为空!'}]}
      >
        <WdiInputNumber format="d" digits={2} min={0}/>
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <TextArea rows={3} style={{width: '100%'}}/>
      </Form.Item>
    </WdiModalForm>
  );
};

export default SalesContractTermsNonAutoEditApp;
