import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Menu, Tree } from "antd";
import { getTargetDataSource, TransferItem, isChecked } from "@/components/GridWidget/GridViewColumnTranser";
import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { BiMetaTransferProps } from './BiViewSettings';
import { MetaValueProps } from '../GridWidget/ViewSettings';

type TransferKeys = {
  left: string[],
} & MetaValueProps;

type DataSources = {
  headers: TransferItem[],
  rows: TransferItem[],
  columns: TransferItem[],
};

type LeftDataSource = {
  checked: boolean,
} & TransferItem;

type ChkState = {
  checked: boolean,
  indeterminate: boolean,
};

type ChkStates = {
  left: ChkState,
  headers: ChkState,
  rows: ChkState,
  columns: ChkState,
};

const metaTypeEnum = {
  left: 'left',
  headers: 'headers',
  rows: 'rows',
  columns: 'columns'
};

const spliceSelectCount = (count: number, total: number) => {
  if (count > 0) {
    return `${count}/${total} 项`;
  }
  return `${total} 项`;
};

const BiTransfer: React.FC<BiMetaTransferProps> = (props) => {
  const [selectKeys, setSelectKeys] = useState<TransferKeys>({ left: [], headers: [], rows: [], columns: [] });
  const [dataSources, setDataSources] = useState<DataSources>({ headers: [], rows: [], columns: [] });
  const [leftDataSource, setLeftDataSource] = useState<LeftDataSource[]>([]);
  const [chkStates, setChkStates] = useState<ChkStates>({
    left: { checked: false, indeterminate: false },
    headers: { checked: false, indeterminate: false },
    rows: { checked: false, indeterminate: false },
    columns: { checked: false, indeterminate: false },
  });

  const handleLeftSelect = (key: string, checked: boolean) => {
    const keys = { ...selectKeys };
    const newChkStates = { ...chkStates };
    const dataSource = [...leftDataSource];
    const chkState = { checked: false, indeterminate: false };
    if (!checked) {
      keys.left.push(key);
    } else {
      keys.left = keys.left.filter(m => m != key);
    }
    dataSource.map(m => {
      if (m.key == key) {
        m.checked = !checked;
      }
      return m;
    });
    chkState.indeterminate = keys.left.length > 0 && keys.left.length !== dataSource.length;
    chkState.checked = keys.left.length === dataSource.length;
    newChkStates.left = chkState;
    setSelectKeys(keys);
    setChkStates(newChkStates);
    setLeftDataSource(dataSource);
  };

  const handelRightSelect = (key: string, checked: boolean, metaType: string) => {
    const keys = { ...selectKeys };
    const newChkStates = { ...chkStates };
    const chkState = { checked: false, indeterminate: false };
    if (checked) {
      keys[metaType].push(key);
    } else {
      keys[metaType] = keys[metaType].filter((m: string) => m != key);
    }
    chkState.indeterminate = keys[metaType].length > 0 && keys[metaType].length !== dataSources[metaType].length;
    chkState.checked = keys[metaType].length === dataSources[metaType].length;
    newChkStates[metaType] = chkState;
    setSelectKeys(keys);
    setChkStates(newChkStates);
  };

  const handleLetfToRight = (metaType: string) => {
    const keys: string[] = [];
    const newSelectKeys = { ...selectKeys };
    if (newSelectKeys.left.length > 0) {
      newSelectKeys.left.map(key => {
        keys.push(key);
        newSelectKeys.left = newSelectKeys.left.filter(m => m != key);
      });
    }
    setSelectKeys(newSelectKeys);

    setChkStates(Object.assign(chkStates, { left: { checked: false, indeterminate: false } }));

    const mate = { ...props.value };
    mate[metaType].push(...keys);
    if (props.onChange) {
      props.onChange(mate);
    }
  };

  const handleRightTpLeft = (metaType: string) => {
    const keys: string[] = [];
    const newSelectKeys = { ...selectKeys };
    if (newSelectKeys[metaType].length > 0) {
      newSelectKeys[metaType].map((key: any) => {
        keys.push(key);
        newSelectKeys[metaType] = newSelectKeys[metaType].filter((m: string) => m != key);
      });
    }
    setSelectKeys(newSelectKeys);
    setChkStates(Object.assign(chkStates, { [metaType]: { checked: false, indeterminate: false } }));

    const mate = { ...props.value };
    mate[metaType] = mate[metaType].filter((m: string) => !keys.includes(m));
    if (props.onChange) {
      props.onChange(mate);
    }
  };

  const handleDrop = (info: any, metaType: string) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPosition = info.dropPosition;
    const targetKeys = props.value?.[metaType];
    const newTargetKeys: string[] = [];
    if (dropPosition == -1) {
      newTargetKeys.push(dragKey);
    }
    (targetKeys ? targetKeys : []).forEach((item: any) => {
      if (dragKey != item) {
        newTargetKeys.push(item);
        if (dropPosition != -1 && dropKey == item) {
          newTargetKeys.push(dragKey);
        }
      }
    });
    const mate = { ...props.value };
    mate[metaType] = newTargetKeys;
    if (props.onChange) {
      props.onChange(mate);
    }
  };

  const handleRightSelectAllChange = (checked: boolean, metaType: string) => {
    const keys = { ...selectKeys };
    const states = { ...chkStates };
    if (checked) {
      keys[metaType] = dataSources[metaType].map((m: TransferItem) => (m.key));
    } else {
      keys[metaType] = [];
    }
    states[metaType] = { checked: checked, indeterminate: false };
    setSelectKeys(keys);
    setChkStates(states);
  };

  const handleRightSelectAll = (metaType: string) => {
    const keys = { ...selectKeys };
    const states = { ...chkStates };
    keys[metaType] = dataSources[metaType].map((m: TransferItem) => (m.key));
    states[metaType] = { checked: true, indeterminate: false };
    setSelectKeys(keys);
    setChkStates(states);
  };

  const handleRightInvertAll = (metaType: string) => {
    const keys = { ...selectKeys };
    const newChkStates = { ...chkStates };
    const chkState = { checked: false, indeterminate: false };
    keys[metaType] = dataSources[metaType].filter((m: TransferItem) => keys[metaType].findIndex((n: string) => n == m.key) < 0).map((m: TransferItem) => (m.key));
    chkState.indeterminate = keys[metaType].length > 0 && keys[metaType].length !== dataSources[metaType].length;
    chkState.checked = keys[metaType].length === dataSources[metaType].length;
    newChkStates[metaType] = chkState;
    setSelectKeys(keys);
    setChkStates(newChkStates);
  };

  const handleLeftSelectAllChange = (checked: boolean) => {
    const keys = { ...selectKeys };
    const states = { ...chkStates };
    const dataSource = [...leftDataSource];
    if (checked) {
      keys.left = dataSource.map((m) => (m.key));
    } else {
      keys.left = [];
    }
    dataSource.map((m) => (m.checked = checked));
    states.left = { checked: checked, indeterminate: false };
    setSelectKeys(keys);
    setChkStates(states);
    setLeftDataSource(dataSource);
  };

  const handleLeftSelectAll = () => {
    const keys = { ...selectKeys };
    const states = { ...chkStates };
    const dataSource = [...leftDataSource];
    keys.left = dataSource.map((m) => (m.key));
    states.left = { checked: true, indeterminate: false };
    dataSource.map((m) => (m.checked = true));
    setSelectKeys(keys);
    setChkStates(states);
    setLeftDataSource(dataSource);
  };

  const handleLeftInvertAll = () => {
    const keys = { ...selectKeys };
    const newChkStates = { ...chkStates };
    const dataSource = [...leftDataSource];
    const chkState = { checked: false, indeterminate: false };

    keys.left = dataSource.filter((m) => keys.left.findIndex((n: string) => n == m.key) < 0).map((m) => { return m.key });
    dataSource.map(m => (m.checked = keys.left.findIndex(n => n == m.key) > -1));

    chkState.indeterminate = keys.left.length > 0 && keys.left.length !== dataSource.length;
    chkState.checked = keys.left.length === dataSource.length;
    newChkStates.left = chkState;
    setSelectKeys(keys);
    setChkStates(newChkStates);
  };

  useEffect(() => {
    if (props.dataSource && props.value) {
      const dataSource = [...props.dataSource];
      const headersDataSource = getTargetDataSource(dataSource, props.value.headers ? props.value.headers : []);
      const columnsDataSource = getTargetDataSource(dataSource, props.value.columns ? props.value.columns : []);
      const rowsDataSource = getTargetDataSource(dataSource, props.value.rows ? props.value.rows : []);
      const leftDataSource = dataSource.filter(m =>
        headersDataSource.findIndex(a => a.key == m.key) < 0
        && columnsDataSource.findIndex(b => b.key == m.key) < 0
        && rowsDataSource.findIndex(c => c.key == m.key) < 0);
      setDataSources({ headers: headersDataSource, columns: columnsDataSource, rows: rowsDataSource });
      setLeftDataSource(leftDataSource.map(m => ({ checked: false, ...m })));
    }
  }, [props.dataSource, props.value]);

  return (
    <div className="ant-transfer ant-transfer-customize-list ant-transfer-status-success">
      <div className='ant-transfer-list'>
        <div className="ant-transfer-list-header">
          <Checkbox checked={chkStates.left.checked} indeterminate={chkStates.left.indeterminate} onChange={(e) => { handleLeftSelectAllChange(e.target.checked) }} />
          <Dropdown overlay={
            <Menu items={[
              { key: 'selectAll', label: '全选所有', onClick: handleLeftSelectAll },
              { key: 'invertAll', label: '反选当页', onClick: handleLeftInvertAll, },
            ]} />
          }>
            <DownOutlined />
          </Dropdown>
          <span className="ant-transfer-list-header-selected">{spliceSelectCount(selectKeys.left.length, leftDataSource.length)}</span>
          <span className="ant-transfer-list-header-title">隐藏项</span>
        </div>
        <div className='ant-transfer-list-body'>
          <ul className="ant-transfer-list-content">
            {
              leftDataSource.map(m => {
                return (
                  <li key={m.key} className="ant-transfer-list-content-item" title={m.title} onClick={() => handleLeftSelect(m.key, m.checked)}>
                    <Checkbox checked={m.checked} key={m.key} name={m.key} >
                      <span onClick={() => handleLeftSelect(m.key, m.checked)}>
                        {m.title}
                      </span>
                    </Checkbox>
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ position: 'relative', top: '12%' }}>
          <div className="ant-transfer-operation" >
            <Button onClick={() => { handleLetfToRight(metaTypeEnum.headers) }} disabled={selectKeys.left.length > 0 ? false : true} type='primary' size='small' icon={<RightOutlined />} />
            <Button onClick={() => { handleRightTpLeft(metaTypeEnum.headers) }} disabled={selectKeys.headers.length > 0 ? false : true} type='primary' size='small' icon={<LeftOutlined />} />
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div className="ant-transfer-operation">
            <Button onClick={() => { handleLetfToRight(metaTypeEnum.rows) }} disabled={selectKeys.left.length > 0 ? false : true} type='primary' size='small' icon={<RightOutlined />} />
            <Button onClick={() => { handleRightTpLeft(metaTypeEnum.rows) }} disabled={selectKeys.rows.length > 0 ? false : true} type='primary' size='small' icon={<LeftOutlined />} />
          </div>
        </div>
        <div style={{ position: 'relative', top: '-12%' }}>
          <div className="ant-transfer-operation">
            <Button onClick={() => { handleLetfToRight(metaTypeEnum.columns) }} disabled={selectKeys.left.length > 0 ? false : true} type='primary' size='small' icon={<RightOutlined />} />
            <Button onClick={() => { handleRightTpLeft(metaTypeEnum.columns) }} disabled={selectKeys.columns.length > 0 ? false : true} type='primary' size='small' icon={<LeftOutlined />} />
          </div>
        </div>
      </div>
      <div className='ant-transfer-list' style={{ border: 'none' }}>
        <TransferList
          metaType={metaTypeEnum.headers}
          headerTitle='筛选字段'
          chkStates={chkStates}
          selectKeys={selectKeys}
          dataSources={dataSources}
          handleSelectAllChange={handleRightSelectAllChange}
          handleDropdownSelect={handleRightSelectAll}
          handleDropdownInvert={handleRightInvertAll}
          handleSelect={handelRightSelect}
          handleDrop={handleDrop}
        />
        <TransferList
          metaType={metaTypeEnum.rows}
          headerTitle='汇总行'
          chkStates={chkStates}
          selectKeys={selectKeys}
          dataSources={dataSources}
          handleSelectAllChange={handleRightSelectAllChange}
          handleDropdownSelect={handleRightSelectAll}
          handleDropdownInvert={handleRightInvertAll}
          handleSelect={handelRightSelect}
          handleDrop={handleDrop}
          style={{ marginTop: '5px', marginBottom: '5px' }}
        />
        <TransferList
          metaType={metaTypeEnum.columns}
          headerTitle='汇总列'
          chkStates={chkStates}
          selectKeys={selectKeys}
          dataSources={dataSources}
          handleSelectAllChange={handleRightSelectAllChange}
          handleDropdownSelect={handleRightSelectAll}
          handleDropdownInvert={handleRightInvertAll}
          handleSelect={handelRightSelect}
          handleDrop={handleDrop}
        />
      </div>
    </div >
  );
};

type TransferListProps = {
  metaType: string,
  headerTitle: string,
  chkStates: ChkStates,
  selectKeys: TransferKeys,
  dataSources: DataSources,
  handleSelectAllChange: (checked: boolean, metaType: string) => void,
  handleDropdownSelect: (metaType: string) => void,
  handleDropdownInvert: (metaType: string) => void,
  handleSelect: (key: string, checked: boolean, metaType: string) => void,
  handleDrop: (info: any, metaType: string) => void,
  style?: React.CSSProperties
};

const TransferList: React.FC<TransferListProps> = (props) => {
  const { metaType, headerTitle, chkStates, selectKeys, dataSources,
    handleSelectAllChange, handleDropdownSelect, handleDropdownInvert, handleSelect, handleDrop
  } = props;
  return (
    <div className='ant-transfer-list' style={{ maxHeight: '200px', ...props.style }}>
      <div className='ant-transfer-list-header'>
        <Checkbox
          checked={chkStates[metaType].checked}
          indeterminate={chkStates[metaType].indeterminate}
          onChange={(e) => { handleSelectAllChange(e.target.checked, metaType) }}
        />
        <Dropdown overlay={
          <Menu items={[
            { key: 'selectAll', label: '全选所有', onClick: () => handleDropdownSelect(metaType), },
            { key: 'invertAll', label: '反选当页', onClick: () => handleDropdownInvert(metaType), },
          ]} />
        }>
          <DownOutlined />
        </Dropdown>
        <span className="ant-transfer-list-header-selected">{spliceSelectCount(selectKeys[metaType].length, dataSources[metaType].length)}</span>
        <span className="ant-transfer-list-header-title">{headerTitle}</span>
      </div>
      <div className='ant-transfer-list-body'>
        <div className="ant-transfer-list-body-customize-wrapper">
          <Tree
            blockNode
            checkable
            checkStrictly
            draggable
            defaultExpandAll
            checkedKeys={selectKeys[metaType]}
            treeData={dataSources[metaType]}
            onCheck={(_, { node: { key } }) => {
              handleSelect(String(key), !isChecked(selectKeys[metaType], String(key)), metaType);
            }}
            onSelect={(_, { node: { key } }) => {
              handleSelect(String(key), !isChecked(selectKeys[metaType], String(key)), metaType);
            }}
            onDrop={(info) => { handleDrop(info, metaType) }}
          />
        </div>
      </div>
    </div>
  );
};

export { BiTransfer } 