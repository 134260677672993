import React, {useEffect, useState} from 'react';
import {ColumnsType} from 'antd/lib/table';
import PanelForWarningApp, {
    WarningInfoAppProps,
    WarningInfoApprovalProps,
    WdiGridApp,
    WdiObjApp
} from '@/components/WdiBus/PanelForWarning';
import {Empty} from "antd";
import {FormSs} from "@/services/sales/ss";
import {wdiCore} from "@/components/WdiControls";

const OverApplyPayment: Record<string, any> = {
    formSaDetailId: (_: any, record: any) => {
        return record['formSaDetailId'];
    },
    soldTo: (_: any, record: any) => {
        return wdiCore.String.CodeOrName(record['soldToCode'], record['soldToName']);
    },
    custSys: (_: any, record: any) => {
        return wdiCore.String.CodeOrName(record['custSysCode'], record['custSysName']);
    },
    product: (_: any, record: any) => {
        return record['productBuyNames'];
    },
    ec: (_: any, record: any) => {
        return record['ecName'];
    },
    promotionDesc: (_: any, record: any) => {
        return record['promotionDesc'];
    },
    amountSa: (_: any, record: any) => {
        return wdiCore.Number.AmountToString(record['amountSa']);
    },
    amountSs: (_: any, record: any) => {
        return <span style={{color: '#ff0000'}}>{wdiCore.Number.AmountToString(record['amountSs'])}</span>;
    },
}
const columnsRenders: Record<string, Record<string, any>> = {
    OverApplyPayment: OverApplyPayment,
};

const renderWdiGridApp = (props: API.WdiGrid, index: number, isExport: boolean) => {
    const columns: ColumnsType<any> = [];
    const columnsRender = columnsRenders[props.key];
    props.wdiColumns.map((wdiColumn) => {
        if (!wdiColumn.isHidden) {
            const column: any = {
                title: wdiColumn.displayName,
                dataIndex: wdiColumn.name,
                width: wdiColumn.width,
                ellipsis: true,
            };
            if (columnsRender && columnsRender[wdiColumn.name]) {
                column.render = columnsRender[wdiColumn.name];
            }
            if (wdiColumn.name != 'platform' && wdiColumn.name != 'district') {
                columns.push(column);
            }
        }
    });

    return (
        (props.status != 0 || props.showAlways == true)
        && <WdiGridApp key={props.key} wdiGrid={props} columns={columns} dataSource={props.items} isExport={false}
                       exportColumns={[]}/>
    );
};
const renderWdiObjApp = (props: API.WdiObj, index: number) => {
    return (
        (props.status != 0 || props.showAlways == true)
        && <WdiObjApp key={index} wdiObj={props} message={props.target}/>
    );
};

const SoldToSsWarningInfoApp: React.FC<WarningInfoAppProps> = (props) => {
    let status: 0 | 1 | 2 = 0;
    props.wdiPanelList?.items.map((item) => {
        if (item.status === 2) {
            status = 2;
        }
        if (status !== 2 && item.status === 1) {
            status = 1;
        }
    });
    return (
        <PanelForWarningApp
            title={props.title}
            visible={props.visible}
            status={status}
            onClose={props.onClose}
            onSave={props.onSave}
        >
            {props.wdiPanelList?.items.map((item, index) => {
                if (item.type === 'WdiGrid') {
                    return renderWdiGridApp(item, index, true);
                } else if (item.type === 'WdiObj') {
                    return renderWdiObjApp(item, index);
                } else {
                    return <></>;
                }
            })}
        </PanelForWarningApp>
    );
}
export default SoldToSsWarningInfoApp;

export const SoldToSsWarningApprovalApp: React.FC<WarningInfoApprovalProps> = (props) => {
    const [isWarning, setIsWarning] = useState(false);
    const [paneList, setPaneList] = useState<API.WdiPanelList>();
    useEffect(() => {
        if (props?.formId) {
            FormSs.Buttons.warningInfos(props?.formId).then(response => {
                if (response?.data && response?.data.items && response?.data.items.length > 0) {
                    setIsWarning(true);
                }
                setPaneList(response?.data);
            });
        }
    }, [props.formId]);

    return (
        <>
            {isWarning && paneList != undefined && paneList.items.map((item, index) => {
                if (item.type === 'WdiGrid') {
                    return renderWdiGridApp(item, index, true);
                } else if (item.type === 'WdiObj') {
                    return renderWdiObjApp(item, index);
                } else {
                    return <></>;
                }
            })}
            {!isWarning &&
                <div>
                    <div style={{marginTop: "200px", textAlign: "center"}}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="没有任何警示信息"/>
                    </div>
                </div>
            }
        </>
    );
};
