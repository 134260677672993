import {wdiCore, WdiInput, WdiModalAppProps} from "@/components/WdiControls";
import {HorzLayoutOpts, WdiModalForm} from "@/components/WdiControls/WdiModalForm";
import {ResponseStatus} from "@/utils/request";
import {Form, Input, message} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {ProFormInstance} from "@ant-design/pro-form";
import {WdiInputNumber} from "@/components/WdiControls/WdiInputNumber";
import {WdiSelect, WdiSelectFormat} from "@/components/WdiControls/WdiSelect";
import {Platform} from "@/services/mdm/platform";
import {District} from "@/services/mdm/district";
import {FormSs} from "@/services/sales/ss";


const FormSsDetailPriceEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const {row, rowIndex, pageTypeId} = props.data;
  const [showPlatform, setShowPlatform] = useState<boolean>(false);
  const [showDistrict, setShowDistrict] = useState<boolean>(false);
  const [unitExpenseActual1, setUnitExpenseActual1] = useState<number>();
  const [unitExpenseActual2, setUnitExpenseActual2] = useState<number>();
  const [quantityActual1, setQuantityActual1] = useState<number>();
  const [quantityActual2, setQuantityActual2] = useState<number>();
  const [q1, setQ1] = useState<number>();
  const [q2, setQ2] = useState<number>();
  const [u1, setU1] = useState<number>();
  const [u2, setU2] = useState<number>();
  const [amount, setAmount] = useState<number>();


  useEffect(() => {
    if (row) {
      formRef.current?.setFieldsValue({
        promotionDesc: row.promotionDesc,
        unitExpense1: row.unitExpense1,
        quantityForecast1: row.quantityForecast1,
        pricePosAfter1: row.pricePosAfter1,
        unitExpense2: row.unitExpense2,
        quantityForecast2: row.quantityForecast2,
        pricePosAfter2: row.pricePosAfter2,
        productBuyNames: row.productBuyNames,
        amountSa: row.amountSa,
        amountPaid: row.amountPaid,
        ecName: row.ecName,
        revenueTypeName: row.revenueTypeName,
        soldToName: wdiCore.String.Join(row.soldToCode, row.soldToName),
        custSysName: wdiCore.String.Join(row.custSysCode, row.custSysName),
        remark: row.remark,
        salesType: row.salesTypeName,
        quantityActual: row.quantityActual == null ? 0 : row.quantityActual,
        quantityActual1: row.quantityActual1,
        quantityActual2: row.quantityActual2,
        amountExVat: row.amountExVat,
        unitExpenseActual2: row.unitExpenseActual2,
        unitExpenseActual1: row.unitExpenseActual1
      });
      setUnitExpenseActual1(row.unitExpenseActual1);
      setUnitExpenseActual2(row.unitExpenseActual2);
      setQuantityActual1(row.quantityActual1);
      setQuantityActual2(row.quantityActual2);
      setU1(row.unitExpenseActual1);
      setU2(row.unitExpenseActual2);
      setQ1(row.quantityActual1);
      setQ2(row.quantityActual2);
      setAmount(row.amountExVat);

      if (row.platformId) {
        setShowPlatform(true);
        formRef.current?.setFieldsValue({platformId: row.platformId});
      }
      if (row.districtId) {
        setShowDistrict(true);
        formRef.current?.setFieldsValue({districtId: row.districtId});
      }
    }
  }, [props.data]);

  return (
    <>
      <WdiModalForm
        formRef={formRef}
        initialValues={{undertakeRate: 1}}
        title={props.title}
        visible={props.visible}
        modalProps={{
          onCancel: () => {
            setQuantityActual1(q1);
            setQuantityActual2(q2);
            setUnitExpenseActual1(u1);
            setUnitExpenseActual1(u2);
            formRef.current?.setFieldsValue({
              quantityActual1: q1,
              quantityActual2: q2,
              amountExVat: amount,
              unitExpenseActual2: u2,
              unitExpenseActual1: u1
            });
            row.quantityActual1 = q1;
            row.quantityActual2 = q2;
            row.unitExpenseActual1 = u1;
            row.unitExpenseActual2 = u2;
            row.amountExVat = amount;
            props.onClose?.()
          },
        }}
        onFinish={async (values) => {
          const formSsDetail = row;
          formSsDetail.remark = values.remark;
          formSsDetail.quantityActual1 = values.quantityActual1;
          formSsDetail.quantityActual2 = values.quantityActual2;
          formSsDetail.unitExpenseActual1 = values.unitExpenseActual1;
          formSsDetail.unitExpenseActual2 = values.unitExpenseActual2;
          formSsDetail.amountExVat = values.amountExVat;
          formSsDetail.amount = values.amountExVat;

          const response = await FormSs.Buttons.validateDetail({
            pageTypeId: pageTypeId,
            formSsDetail: formSsDetail
          });
          if (response.code !== ResponseStatus.SUCCESS) {
            message.error(response.message);
            return;
          }
          if (props.onSave) {
            response.data = Object.assign({}, {rowIndex: rowIndex}, response.data);
            props.onSave(response);
          }
        }}
        {...HorzLayoutOpts}
      >
        <Form.Item name="soldToName" label="售达方">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="custSysName" label="客户集团">
          <Input readOnly/>
        </Form.Item>
        {
          showPlatform &&
          <Form.Item name="platformId" label="分子平台">
            <WdiSelect {...{
              placeholder: "请选择分子平台",
              showSearch: true,
              remote: true,
              disabled: true,
              dataLoading: async (params) => {
                const response = await Platform.ajaxSearch(Object.assign({}, params));
                return response.data.map((item) => ({value: item.platformId, label: item.codeOrName}));
              }
            }} />
          </Form.Item>
        }
        {
          showDistrict &&
          <Form.Item name="districtId" label="省份">
            <WdiSelect {...{
              placeholder: "请选择省份",
              showSearch: true,
              remote: true,
              disabled: true,
              dataLoading: async (params) => {
                const response = await District.search(Object.assign({}, params));
                return response.data.map((item) => ({value: item.districtId, label: item.districtName}));
              }
            }} />
          </Form.Item>
        }
        <Form.Item name="productBuyNames" label="产品">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="ecName" label="费用项">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="promotionDesc" label="活动机制">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="unitExpense1" label="一促单听补贴">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="quantityForecast1" label="一促预估销量">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="unitExpense2" label="二促单听补贴">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="quantityForecast2" label="二促预估销量">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="amountSa" label="申请金额">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="quantityActual" label="系统计算实际销量">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="salesType" label="销量类型">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="unitExpenseActual1" label="一促实际单听补贴" rules={[{required: true}]}>
          <WdiInputNumber format='d' digits={2} min={0} {...{
            onChange: (params) => {
              setUnitExpenseActual1(WdiSelectFormat.getvalue(params))
              const amount1 = Number(quantityActual1) * Number(WdiSelectFormat.getvalue(params));
              const amount2 = Number(unitExpenseActual2) * Number(quantityActual2);
              formRef.current?.setFieldsValue({
                amountExVat: amount1 + amount2
              });
            },
          }}/>
        </Form.Item>
        <Form.Item name="quantityActual1" label="一促实际销量" rules={[{required: true}]}>
          <WdiInputNumber format='d' digits={2} min={0} {...{
            onChange: (params) => {
              setQuantityActual1(WdiSelectFormat.getvalue(params));
              const amount1 = Number(unitExpenseActual1) * Number(WdiSelectFormat.getvalue(params));
              const amount2 = Number(unitExpenseActual2) * Number(quantityActual2);
              formRef.current?.setFieldsValue({
                amountExVat: amount1 + amount2
              });
            },
          }}/>
        </Form.Item>
        <Form.Item name="unitExpenseActual2" label="二促实际单听补贴" rules={[{required: true}]}>
          <WdiInputNumber format='d' digits={2} min={0} {...{
            onChange: (params) => {
              setUnitExpenseActual2(WdiSelectFormat.getvalue(params));
              const amount1 = Number(unitExpenseActual1) * Number(quantityActual1);
              const amount2 = Number(WdiSelectFormat.getvalue(params)) * Number(quantityActual2);
              formRef.current?.setFieldsValue({
                amountExVat: amount1 + amount2
              });
            },
          }}/>
        </Form.Item>
        <Form.Item name="quantityActual2" label="二促实际销量" rules={[{required: true}]}>
          <WdiInputNumber format='d' digits={2} min={0} {...{
            onChange: (params) => {
              setQuantityActual2(WdiSelectFormat.getvalue(params));
              const amount1 = Number(unitExpenseActual1) * Number(quantityActual1);
              const amount2 = Number(WdiSelectFormat.getvalue(params)) * Number(unitExpenseActual2);
              formRef.current?.setFieldsValue({
                amountExVat: amount1 + amount2
              });
            },
          }}/>
        </Form.Item>
        <Form.Item name="amountExVat" label="核销金额" rules={[{required: true}]}>
          <WdiInputNumber readOnly format='d' digits={2} min={0}/>
        </Form.Item>
        <Form.Item name="remark" label="核销备注">
          <Input.TextArea rows={3}/>
        </Form.Item>
      </WdiModalForm>

    </>
  )
}
export default FormSsDetailPriceEditApp;


export const FormSsDetailNoPriceEditApp: React.FC<WdiModalAppProps> = (props) => {

  const formRef = useRef<ProFormInstance>();
  const {row, rowIndex, pageTypeId} = props.data;
  const [showPlatform, setShowPlatform] = useState<boolean>(false);
  const [showDistrict, setShowDistrict] = useState<boolean>(false);


  useEffect(() => {

    if (row) {
      formRef.current?.setFieldsValue({
        promotionDesc: row.promotionDesc,
        productBuyNames: row.productBuyNames,
        amountSa: wdiCore.Number.ToNumber(row.amountSa, 2),
        amountPaid: row.amountPaid,
        ecName: row.ecName,
        revenueTypeName: row.revenueTypeName,
        soldToName: wdiCore.String.Join(row.soldToCode, row.soldToName),
        custSysName: wdiCore.String.Join(row.custSysCode, row.custSysName),
        remark: row.remark,
        quantityActual: row.quantityActual == null ? 0 : row.quantityActual,
        amountExVat: row.amountExVat,
      });

      if (row.platformId) {
        setShowPlatform(true);
        formRef.current?.setFieldsValue({platformId: row.platformId});
      }
      if (row.districtId) {
        setShowDistrict(true);
        formRef.current?.setFieldsValue({districtId: row.districtId});
      }
    }
  }, [props.data]);

  return (
    <>
      <WdiModalForm
        formRef={formRef}
        initialValues={{undertakeRate: 1}}
        title={props.title}
        visible={props.visible}
        modalProps={{
          onCancel: () => {
            props.onClose?.()
          },
        }}
        onFinish={async (values) => {
          row.amountExVat = values.amountExVat;
          row.remark = values.remark;
          row.amount = values.amountExVat;
          const response = await FormSs.Buttons.validateDetail({
            pageTypeId: pageTypeId,
            formSsDetail: row
          });
          if (response.code !== ResponseStatus.SUCCESS) {
            message.error(response.message);
            return;
          }
          if (props.onSave) {
            response.data = Object.assign({}, {rowIndex: rowIndex}, response.data);
            props.onSave(response);
          }
        }}
        {...HorzLayoutOpts}
      >
        <Form.Item name="soldToName" label="售达方">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="custSysName" label="客户集团">
          <Input readOnly/>
        </Form.Item>
        {
          showPlatform &&
          <Form.Item name="platformId" label="分子平台">
            <WdiSelect {...{
              placeholder: "请选择分子平台",
              showSearch: true,
              remote: true,
              disabled: true,
              dataLoading: async (params) => {
                const response = await Platform.ajaxSearch(Object.assign({}, params));
                return response.data.map((item) => ({value: item.platformId, label: item.codeOrName}));
              }
            }} />
          </Form.Item>
        }
        {
          showDistrict &&
          <Form.Item name="districtId" label="省份">
            <WdiSelect {...{
              placeholder: "请选择省份",
              showSearch: true,
              remote: true,
              disabled: true,
              dataLoading: async (params) => {
                const response = await District.search(Object.assign({}, params));
                return response.data.map((item) => ({value: item.districtId, label: item.districtName}));
              }
            }} />
          </Form.Item>
        }
        <Form.Item name="productBuyNames" label="产品别">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="ecName" label="费用项">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="promotionDesc" label="活动机制">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="amountSa" label="申请金额">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="amountExVat" label="核销金额" rules={[{required: true}]}>
          <WdiInputNumber format='d' digits={2}/>
        </Form.Item>
        <Form.Item name="remark" label="核销备注">
          <Input.TextArea rows={3}/>
        </Form.Item>
      </WdiModalForm>
    </>
  )
}
export const FormSsDetailSellinEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const {row, pageTypeId, rowIndex} = props.data;
  const [showPlatform, setShowPlatform] = useState<boolean>(false);
  const [showDistrict, setShowDistrict] = useState<boolean>(false);
  useEffect(() => {
    if (row) {
      formRef.current?.setFieldsValue({
        promotionDesc: row.promotionDesc,
        productBuyNames: row.productBuyNames,
        amountSa: row.amountSa,
        amountPaid: row.amountPaid,
        ecName: row.ecName,
        revenueTypeName: row.revenueTypeName,
        soldToName: wdiCore.String.Join(row.soldToCode, row.soldToName),
        custSysName: wdiCore.String.Join(row.custSysCode, row.custSysName),
        remark: row.remark,
        quantityActual: row.quantityActual == null ? 0 : row.quantityActual,
        unitExpenseSellin: row.unitExpenseSellin,
        quantityForecastSellin: row.quantityForecastSellin,
        amountActual: wdiCore.Number.ToNumber(row.unitExpenseSellin * row.quantityActual, 2),
        amountExVat: row.amountExVat,
      });

      if (row.platformId) {
        setShowPlatform(true);
        formRef.current?.setFieldsValue({platformId: row.platformId});
      }
      if (row.districtId) {
        setShowDistrict(true);
        formRef.current?.setFieldsValue({districtId: row.districtId});
      }
    }
  }, [props.data]);
  return (
    <>
      <WdiModalForm
        formRef={formRef}
        initialValues={{undertakeRate: 1}}
        title={props.title}
        visible={props.visible}
        modalProps={{
          onCancel: () => {
            props.onClose?.()
          },
        }}
        onFinish={async (values) => {
          if (values.amountExVat <= 0) {
            message.error("核销金额必须大于0");
            return;
          }
          row.amountExVat = values.amountExVat;
          row.amount = values.amountExVat;
          row.remark = values.remark;
          const response = await FormSs.Buttons.validateDetail({
            pageTypeId: pageTypeId,
            formSsDetail: row
          });
          if (response.code !== ResponseStatus.SUCCESS) {
            message.error(response.message);
            return;
          }
          if (props.onSave) {
            response.data = Object.assign({}, {rowIndex: rowIndex}, response.data);
            props.onSave(response);
          }
        }}
        {...HorzLayoutOpts}
      >
        <Form.Item name="soldToName" label="售达方">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="custSysName" label="客户集团">
          <Input readOnly/>
        </Form.Item>
        {
          showPlatform &&
          <Form.Item name="platformId" label="分子平台">
            <WdiSelect {...{
              placeholder: "请选择分子平台",
              showSearch: true,
              remote: true,
              disabled: true,
              dataLoading: async (params) => {
                const response = await Platform.ajaxSearch(Object.assign({}, params));
                return response.data.map((item) => ({value: item.platformId, label: item.codeOrName}));
              }
            }} />
          </Form.Item>
        }
        {
          showDistrict &&
          <Form.Item name="districtId" label="省份">
            <WdiSelect {...{
              placeholder: "请选择省份",
              showSearch: true,
              remote: true,
              disabled: true,
              dataLoading: async (params) => {
                const response = await District.search(Object.assign({}, params));
                return response.data.map((item) => ({value: item.districtId, label: item.districtName}));
              }
            }} />
          </Form.Item>
        }
        <Form.Item name="productBuyNames" label="产品">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="ecName" label="费用项">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="promotionDesc" label="活动机制">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="unitExpenseSellin" label="sellin单听补贴">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="quantityForecastSellin" label="sellin预估销量">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="amountSa" label="申请金额">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="quantityActual" label="系统计算实际销量">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="amountActual" label="系统计算金额">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="amountExVat" label="核销金额" rules={[{required: true}]}>
          <WdiInputNumber format='d' digits={2} min={0}/>
        </Form.Item>
        <Form.Item name="remark" label="核销备注">
          <Input.TextArea rows={3}/>
        </Form.Item>
      </WdiModalForm>
    </>
  )
}

