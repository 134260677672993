import {formatter, request} from "@/utils/request";

export namespace RelationCust {
  export const Path = {
    QueryForSheet: '/mdmapi/relationCust/queryForSheet',
    QueryForTemplate: '/mdmapi/relationCust/queryForTemplate',
  };

  export async function queryForSheet(body: API.RelationCustFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.QueryForSheet, formatter(options));
  }

  export async function queryForTemplate(body: API.RelationCustFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.QueryForTemplate, formatter(options));
  }

}
