import React, {useEffect, useState} from 'react';
import {wdiCore} from '@/components/WdiControls';
import {ColumnsType} from 'antd/lib/table';
import PanelForWarningApp, {
  WarningInfoAppProps,
  WarningInfoApprovalProps,
  WdiGridApp,
  WdiObjApp,
} from '@/components/WdiBus/PanelForWarning';
import {Empty} from 'antd';
import {Enum} from "@/enums/system";
import {FormSs} from "@/services/sales/ss";

const SpBudget: Record<string, any> = {
  bgId: (_: any, record: any) => {
    return record['bgName'];
  },
  productLineId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.ToNumber(record['amount'], 0);
  },
  revenueMtd: (value: any, record: any) => {
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      return <span style={{color: '#ff0000'}}>{record['revenueMtd']}</span>;
    }

    return wdiCore.Number.ToNumber(value, 0);
  },
  expenseMtd: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  rateActualMtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueMtd'] == null || record['revenueMtd'] == 0
      || record['rateBaselineMtd'] == null || record['rateActualMtd'] > record['rateBaselineMtd']) {
      isError = true;
    }
    if (record['rateBaselineMtd'] == null) {
      hasStandard = false;
    }
    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      return <>
        <span style={isError ? {color: "#ff0000"} : {}}>{wdiCore.Number.RateToString(record['rateActualMtd'])}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineMtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span style={{color: '#ff0000'}}>{wdiCore.Number.RateToString(record['rateActualMtd'])}</span>;
    }
    return "";
  },
  rateActualMtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxMtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  revenueYtd: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{
            color: isError ? "#ff0000" : "#000000"
          }}>{wdiCore.Number.ToString(record['revenueYtd'], 0)}</span>
    </>;
  },
  expenseYtd: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  rateActualYtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;
    if (record['revenueYtd'] == null || record['revenueYtd'] == 0
      || record['rateBaselineYtd'] == null || record['rateActualYtd'] > record['rateBaselineYtd']) {
      isError = true;
    }
    if (record['rateBaselineYtd'] == null) {
      hasStandard = false;
    }
    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      return <>
        <span
          style={{
            color: isError ? "#ff0000" : "#000000"
          }}>{wdiCore.Number.RateToString(record['rateActualYtd'])}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineYtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span style={{color: '#ff0000'}}>{wdiCore.Number.RateToString(record['rateActualYtd'])}</span>;
    }
    return "";
  },
  rateActualYtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxYtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdLyTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
};

const SpBudgetImportant: Record<string, any> = {
  bgId: (_: any, record: any) => {
    return record['bgName'];
  },
  productLineId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.ToNumber(record['amount'], 0);
  },
  revenueMtd: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{wdiCore.Number.ToNumber(value, 0)}</span>
    </>;
  },
  expenseMtd: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  rateActualMtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0
      || record['rateBaselineMtd'] == null || record['rateActualMtd'] > record['rateBaselineMtd']) {
      isError = true;
    }
    if (record['rateBaselineMtd'] == null) {
      hasStandard = false;
    }
    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      return <>
        <span style={isError ? {color: "#ff0000"} : {}}>{wdiCore.Number.RateToString(record['rateActualMtd'])}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineMtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span style={{color: '#ff0000'}}>{wdiCore.Number.RateToString(record['rateBaselineMtd'])}</span>;
    }
    return "";
  },
  rateActualMtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
};

const OverApplyPayment: Record<string, any> = {
  rowNumber: (_: any, record: any) => {
    return record['rowNumber'];
  },
  soldTo: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['soldToCode'], record['soldToName']);
  },
  custSys: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['custSysCode'], record['custSysName']);
  },
  product: (_: any, record: any) => {
    return record['productBuyNames'];
  },
  ec: (_: any, record: any) => {
    return record['ecName'];
  },
  platform: (_: any, record: any) => {
    return record['platformName'];
  },
  district: (_: any, record: any) => {
    return record['districtName'];
  },
  amountSa: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amountSa']);
  },
  amountPaid: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amountPaid']);
  },
  amountSp: (_: any, record: any) => {
    return <span style={{color: 'red'}}>{wdiCore.Number.AmountToString(record['amountSp'])}</span>;
  },
};

const OverApplyRPayment: Record<string, any> = {
  rebateInfoId: (_: any, record: any) => {
    return record['rebateInfoId'];
  },
  soldTo: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['soldToCode'], record['soldToName']);
  },
  custSys: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['custSysCode'], record['custSysName']);
  },
  product: (_: any, record: any) => {
    return record['productBuyNames'];
  },
  ec: (_: any, record: any) => {
    return record['ecName'];
  },
  amountSs: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amountSs']);
  },
  amountPaid: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amountPaid']);
  },
  amountSp: (_: any, record: any) => {
    return <span style={{color: 'red'}}>{record['amountSp']}</span>;
  },
};

const columnsRenders: Record<string, Record<string, any>> = {
  SpBudget: SpBudget,
  SpBudgetImportant: SpBudgetImportant,
  OverApplyPayment: OverApplyPayment,
  OverApplyRPayment: OverApplyRPayment,
  OverRateStandard: SpBudget,
};

const renderWdiGridApp = (props: API.WdiGrid, index: number) => {
  const columns: ColumnsType<any> = [];
  const columnsRender = columnsRenders[props.key];
  props.wdiColumns.map((wdiColumn) => {
    if (!wdiColumn.isHidden) {
      const column: any = {
        title: wdiColumn.displayName,
        dataIndex: wdiColumn.name,
        width: wdiColumn.width,
        ellipsis: true,
      };
      if (columnsRender && columnsRender[wdiColumn.name]) {
        column.render = columnsRender[wdiColumn.name];
      }
      columns.push(column);
    }
  });
  return (
    (props.status != 0 || props.showAlways == true)
    && <WdiGridApp key={props.key} wdiGrid={props} columns={columns} dataSource={props.items} isExport={false}
                   exportColumns={[]}/>
  );
};

const renderWdiObjApp = (props: API.WdiObj, index: number) => {
  return (
    (props.status != 0 || props.showAlways == true)
    && <WdiObjApp key={index} wdiObj={props} message={props.target}/>
  );
};

const SPWarningInfoApp: React.FC<WarningInfoAppProps> = (props) => {
  let status: 0 | 1 | 2 = 0;
  props.wdiPanelList?.items.map((item) => {
    if (item.status === 2) {
      status = 2;
    }
    if (status !== 2 && item.status === 1) {
      status = 1;
    }
  });
  return (
    <PanelForWarningApp
      title={props.title}
      visible={props.visible}
      status={status}
      onClose={props.onClose}
      onSave={props.onSave}
    >
      {props.wdiPanelList?.items.map((item, index) => {
        if (item.type === 'WdiGrid') {
          return renderWdiGridApp(item, index);
        } else if (item.type === 'WdiObj') {
          return renderWdiObjApp(item, index);
        } else {
          return <></>;
        }
      })}
    </PanelForWarningApp>
  );
};
export default SPWarningInfoApp;

export const SPWarningApprovalApp: React.FC<WarningInfoApprovalProps> = (props) => {
  const [isWarning, setIsWarning] = useState(false);
  const [paneList, setPaneList] = useState<API.WdiPanelList>();
  useEffect(() => {
    if (props?.formId) {
      FormSs.Buttons.warningInfos(props?.formId).then(response => {
        if (response?.data && response?.data.items && response?.data.items.length > 0) {
          setIsWarning(true);
        }
        setPaneList(response?.data);
      });
    }
  }, [props.formId]);

  return (
    <>
      {isWarning && paneList != undefined && paneList.items.map((item, index) => {
        if (item.type === 'WdiGrid') {
          return renderWdiGridApp(item, index);
        } else if (item.type === 'WdiObj') {
          return renderWdiObjApp(item, index);
        } else {
          return <></>;
        }
      })}
      {!isWarning &&
        <div>
          <div style={{marginTop: '200px', textAlign: 'center'}}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='没有任何警示信息'/>
          </div>
        </div>
      }
    </>
  );
};
