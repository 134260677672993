import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Input, message, Row, Checkbox, DatePicker} from 'antd';
import type {ProFormInstance} from '@ant-design/pro-form';
import {ProForm} from '@ant-design/pro-form';
import {WdiCardTitle} from '@/components/WdiControls';
import type {FormPageProps} from '@/components/WdiBus/FormPage';
import {ApplyFooter, ApplyFooterType} from '@/components/WdiBus/ApplyFooter';
import {FileUploadUtility, WdiFileUpload} from '@/components/WdiControls/WdiFileUpload';
import {ResponseStatus} from '@/utils/request';
import type {ViewEntityProps} from '../../SalesContract/components/data';
import {getPageResult, initPageContext} from '../../SalesContract/components/data';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import SalesContractTermsDetails from './details';
import {WdiSelect} from '@/components/WdiControls/WdiSelect';
import {CustSys} from '@/services/mdm/custsys';
import {SalesContract} from '@/services/contract/salescontract';
import moment from 'moment';
import {SalesContractTerms} from '@/services/contract/salescontractterms';
import DataStore from '@/components/GridWidget/DataStore';
import {Enum} from '@/enums/system';
import {SoldTo} from "@/services/mdm/soldto";

const {RangePicker} = DatePicker;
const {TextArea} = Input;

const SalesContractApply: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  // 合同类型
  const [contractType, setContractType] = useState<number>();
  // 当前合同是否为编辑模式
  const [isEdit, setIsEdit] = useState<boolean | undefined>();

  const [selectCustSysCode, setSelectCustSysCode] = useState<string>();

  const [entity, setEntity] = useState<ViewEntityProps>();

  const [dataSource, setDataSource] = useState<any[]>();
  const onDetailsChange = (data: any) => {
    setDataSource(data);
  };

  const onCustSysSelectChange = (value: string) => {
    setSelectCustSysCode(value);
  };

  const soldToDataLoading = (params: any) => {
    return SoldTo.ajaxSearch(Object.assign({
      isActive: true,
      withBoHqPermissions: true,
      custSysCode: selectCustSysCode
    }, params)).then((response) => {
        return response.data.map((item) => ({
          value: item.soldToCode,
          label: item.codeOrName,
        }));
      },
    );
  };

  const custSysDataLoading = (params: any) => {
    return CustSys.ajaxSearch(Object.assign({
      isActive: true,
      withBoHqPermissions: true
    }, params)).then((response) => {
      return response.data.map((item) => ({value: item.custSysCode, label: item.codeOrName}));
    });
  };

  const getHeaderInfo = () => {
    const data: any = {};
    if (formRef && formRef.current) {
      data.soldToCode = formRef.current?.getFieldValue("soldToCode");
    }
    if (formRef && formRef.current) {
      data.custSysCode = formRef.current?.getFieldValue("custSysCode");
    }
    return data;
  };

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity = result.data;
        const {salesContract, contractType} = viewEntity;
        setContractType(contractType);
        if (salesContract) {
          setIsEdit(true);

          SoldTo.get(salesContract.soldToCode).then(response => {
            formRef.current?.setFieldsValue({
              soldToCode: {value: response.data.soldToCode, label: response.data.codeOrName}
            });
          });

          if (salesContract.custSysCode) {
            CustSys.get(salesContract.custSysCode).then(response => {
              formRef.current?.setFieldsValue({
                custSysCode: {value: response.data.custSysCode, label: response.data.codeOrName}
              });
            });
          }

          formRef.current?.setFieldsValue({
            contractCode: salesContract.contractCode,
            contractName: salesContract.contractName,
            contractDate: [moment(salesContract.beginDate), moment(salesContract.endDate)],
            owner: salesContract.owner,
            signDate: salesContract.signDate ? moment(salesContract.signDate) : null,
            customerSignatory: salesContract.customerSignatory,
            isAutoAppend: salesContract.isAutoAppend,
            isOfficial: salesContract.isOfficial,
            remark: salesContract.remark,
            fileInfo: FileUploadUtility.parse(salesContract.fileName, salesContract.filePath),
          });
          SalesContractTerms.getSalesContractTerms(salesContract.salesContractId).then(
            (response) => {
              setDataSource(DataStore.init(response.data));
            },
          );
        }
        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      const {contractType} = entity;
      let title = contractType == Enum.SalesContractType.SELLIN ? '创建一级客户合同' : '创建二级客户合同';
      if (entity.salesContract) {
        title = contractType == Enum.SalesContractType.SELLIN ? '编辑一级客户合同' : '编辑二级客户合同';
      }
      props.setPageTitle(title);
    }
  }, [props.setPageTitle, entity]);

  return (
    <>
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (
              <ApplyFooter
                setLoading={props.setLoading}
                hiddenButton={[ApplyFooterType.Validate, ApplyFooterType.Draft]}
                viewEntity={entity}
                submitBackUrl={
                  contractType != null && contractType == Enum.SalesContractType.SELLIN
                    ? '/contract/salescontract'
                    : '/contract/salescontractindirect'
                }
                doSubmit={async (type: number, forceSubmit: boolean) => {
                  const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                  if (values && values.errorFields && values.errorFields.length) {
                    return;
                  }
                  const dataResult = getPageResult(values, entity, dataSource);
                  if (dataResult.code !== ResponseStatus.SUCCESS) {
                    message.error(dataResult.message);
                    return;
                  }
                  dataResult.data.salesContract.contractType = contractType;
                  if (dataResult.data.salesContract.soldToCode?.value) {
                    dataResult.data.salesContract.soldToCode = dataResult.data.salesContract.soldToCode.value;
                  }
                  if (dataResult.data.salesContract.custSysCode?.value) {
                    dataResult.data.salesContract.custSysCode = dataResult.data.salesContract.custSysCode.value;
                  }
                  return await SalesContract.post({
                    ...dataResult.data,
                  });
                }}
              />
            );
          },
        }}
      >
        <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.BASE_INFO} title="基本信息"/>}>
          <Row gutter={[16, 24]}>
            {contractType != null && contractType == Enum.SalesContractType.SELLOUT && (
              <Col className="gutter-row" span={4}>
                <Form.Item
                  name="custSysCode"
                  label="客户集团"
                  rules={[{required: true, message: '客户集团不能为空!'}]}
                >
                  <WdiSelect
                    placeholder="请选择客户集团"
                    showSearch={true}
                    disabled={isEdit}
                    remote={true}
                    dataLoading={custSysDataLoading}
                    onChange={onCustSysSelectChange}
                  />
                </Form.Item>
              </Col>
            )}
            <Col className="gutter-row" span={4}>
              <Form.Item
                name="soldToCode"
                label="售达方"
                rules={[{required: true, message: '售达方不能为空!'}]}
              >
                <WdiSelect
                  placeholder="请选择售达方"
                  showSearch={true}
                  disabled={isEdit}
                  remote={true}
                  dataLoading={soldToDataLoading}
                  reloadKey={selectCustSysCode}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="contractCode" label="合同编号">
                <Input/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="contractName" label="合同名称">
                <Input/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                required
                name="contractDate"
                label="合同期间"
                rules={[{required: true, message: '合同期间不能为空!'}]}
              >
                <RangePicker picker={'month'} format={'YYYY-MM'}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="owner" label="合同负责人">
                <Input/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="customerSignatory" label="客户方签约人">
                <Input/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="signDate" label="签约日期">
                <DatePicker/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="isAutoAppend" label="是否自动费用追溯" valuePropName="checked">
                <Checkbox/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="isOfficial" label="是否正式合同" valuePropName="checked">
                <Checkbox/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16}>
              <Form.Item
                name="remark"
                label="合同备注"
                rules={[{required: false, message: '合同备注不能为空!'}]}
              >
                <TextArea rows={4}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="fileInfo"
                label="附件"
                valuePropName="fileList"
                getValueFromEvent={(e: any) => {
                  return e.fileList || [];
                }}
              >
                <WdiFileUpload leftTopInfo="请选择文件并上传" multiple={true}/>
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        {contractType && (
          <SalesContractTermsDetails
            pid={entity?.salesContractId}
            contractType={contractType}
            getHeaderInfo={getHeaderInfo}
            dataSource={dataSource}
            onChange={onDetailsChange}
          />
        )}
      </ProForm>
    </>
  );
};
export default SalesContractApply;
