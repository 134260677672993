import { request, formatter } from "@/utils/request";

export namespace FormBudgetProject {
  export const Path = {
    Get: '/projectapi/formBudgetProject/get',
    GetHistory: '/projectapi/formBudgetProject/queryForBudgetProjectForHistory',
    Query: '/reportapi/bp/queryForFormBudgetProject'
  };

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };

    return request<API.ResponseResult<API.QueryResult<any>>>(Path.Query, formatter(options));
  };

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      }
    };

    return request<API.ResponseResult<API.FormBudgetProject>>(Path.Get, formatter(options));
  };

  export async function getHistory(budgetProjectId: number, buId: number) {
    const options = {
      method: 'GET',
      body: {
        budgetProjectId: budgetProjectId,
        buId: buId
      }
    };

    return request<API.ResponseResult<any>>(Path.GetHistory, formatter(options));
  };

  export namespace Buttons {
    export const Path = {
      ValidateDetail: '/projectapi/buttons/validateDetail',
      Apply: '/projectapi/buttons/apply',
      Validate: '/projectapi/buttons/validate',
      Delete: '/projectapi/buttons/delete',
      GetFormBudgetInfos: '/projectapi/buttons/getFormBudgetInfos',
      GetDynamicInformation: '/projectapi/buttons/getDynamicInformation',
    };

    export async function getButtons(body: API.ButtonArgs) {
      const options = {
        method: 'POST',
        body: body
      };

      return request<API.ResponseResult<API.Button>>('/bpapi/Buttons/Get', formatter(options));
    };

    export async function validateDetail(body: {
      rowIndex: number,
      formBudgetProject: any,
      formBudgetProjectDetail: any,
      formBudgetProjectDetails: any[],
    }) {
      const options = {
        method: 'POST',
        body: body
      };

      return request<API.ResponseResult<API.FormBudgetProjectDetailView>>(Path.ValidateDetail, formatter(options));
    };

    export async function validate(body: { form: any, formBudgetProject: any, formBudgetProjectDetails: any[] }) {
      const options = {
        method: 'POST',
        body: body
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.Validate, formatter(options));
    };

    export async function apply(body: { form: any, formBudgetProject: any, formBudgetProjectDetails: any[] }) {
      const options = {
        method: 'POST',
        body: body
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.Apply, formatter(options));
    };

    export async function del(formId: number) {
      const options = {
        method: 'POST',
        body: { formId: formId }
      };
      return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
    };

    export async function getFormBudgetInfos(formId: number) {
      const options = {
        method: 'GET',
        body: { formId }
      };
      return request<API.ResponseResult<{ current: API.WdiGrid, submit: API.FormBudgetInfoView[] }>>(Path.GetFormBudgetInfos, formatter(options));
    };

    export async function getDynamicInformation(formId: number, formType: number, pageType: number) {
      const options = {
        method: 'Get',
        body: { formId }
      };
      return request<API.DynamicInformation[]>(Path.GetDynamicInformation, formatter(options));
    };
  }
};

export namespace FormBudgetProjectDetail {
  export const Path = {
    query: '/projectapi/formBudgetProjectDetail/query'
  };

  export async function query(formBudgetProjectId: number, buId: number) {
    const options = {
      method: 'GET',
      body: {
        formBudgetProjectId: formBudgetProjectId,
        buId: buId
      }
    };

    return request<API.ResponseResult<API.FormBudgetProjectDetailView[]>>(Path.query, formatter(options));
  };
};
