import { request, formatter } from '@/utils/request';

export namespace DataOptions {
  export const Path = {
    Search: '/basicapi/dataOptions/search',
    Get: '/basicapi/dataOptions/get',
    Post: '/basicapi/dataOptions/post',
    Delete: '/basicapi/dataOptions/delete',
    Query: '/basicapi/dataOptions/query',
    Export: '/basicapi/dataOptions/export',
    GetPaymentMethods: '/basicapi/dataOptions/getPaymentMethods',
    GetReimburseMaterials: '/basicapi/dataOptions/getReimburseMaterials',
    GetSettlementMaterials: '/basicapi/dataOptions/getSettlementMaterials'
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.DataOptionsQuery>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.DataOptionsQuery[]>>(Path.Export, formatter(options));
  }

  export async function search(body: API.DataOptionsFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.DataOptions[]>>(Path.Search, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.DataOptions>>(Path.Get, formatter(options));
  }

  export async function post(body: API.DataOptions) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }

  export async function getPaymentMethodsByFormSaId(id: number) {
    const options = {
      method: 'GET',
      body: {
        formSaId: id
      },
    };
    return request<API.ResponseResult<API.DataOptions[]>>(Path.GetPaymentMethods, formatter(options));
  }

  export async function GetReimburseMaterials(body: any) {
    const options = {
      method: 'GET',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.DataOptions[]>>(Path.GetReimburseMaterials, formatter(options));
  }

  export async function GetSettlementMaterials(id: number) {
    const options = {
      method: 'GET',
      body: {
        formSaId: id
      },
    };
    return request<API.ResponseResult<API.DataOptions[]>>(Path.GetSettlementMaterials, formatter(options));
  }
}

export namespace Resources {
  export const Path = {
    Get: '/gridapi/resource/get',
    Post: '/gridapi/resource/post',
    Delete: '/gridapi/resource/delete',
    Query: '/gridapi/resource/query',
    Export: '/gridapi/resource/export'
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.Resources>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Resources[]>>(Path.Export, formatter(options));
  }

  export async function get(key: string) {
    const options = {
      method: 'GET',
      body: {
        key: key
      },
    };
    return request<API.ResponseResult<API.Resources>>(Path.Get, formatter(options));
  }

  export async function post(body: API.Resources) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function del(key: string) {
    const options = {
      method: 'GET',
      body: {
        key: key
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }
}