import { useEffect, useState } from 'react';
import { NsGrid } from '@/components/GridWidget/GridDefProps';
import { WdiCardTitle } from '@/components/WdiControls';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { GroupDetailGrid } from '@/components/GridWidget/GroupDetailGrid';
import { FormSa } from '@/services/sales/sa';
import { Enum } from '@/enums/system';
import { GridApi } from '@/services/gridapi';

type FormSaDetailGroupProps = {
  formId: number
};
export const DealerFormSaDetailGroupPanel: React.FC<FormSaDetailGroupProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});

  useEffect(() => {
    GridApi.searchConfig({ id: 'FormSADetailsGroupForDealer', allowModifyColumns: true }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        dataLoading: async (params: any) => {
          return await FormSa.getFormSaDetailGroup(Object.assign({}, params));
        },
        actionsAlign: NsGrid.ActionsAlign.None,
        queryParamaters: {
          formId: props.formId
        },
        additionalColumns: ["amount"],
      });

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);
  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true
    }
  };

  return (
    <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_COUNT_INFO} title='费用统计信息' />} collapsed={true} >
      {isLoaded && <GroupDetailGrid {...pageGridSettings} />}
    </WdiPanel>
  );
};

export default DealerFormSaDetailGroupPanel;


