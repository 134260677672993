import { useEffect, useRef } from 'react';
import { WdiSelect, WdiSelectFormat } from '@/components/WdiControls/WdiSelect';
import { Form, Input, message } from 'antd';
import { ResponseStatus } from '@/utils/request';
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { Product } from '@/services/mdm/product';
import { ProFormInstance } from '@ant-design/pro-form';
import { WdiModalAppProps, wdiCore } from '@/components/WdiControls';
import { HorzLayoutOpts, WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { FormSa } from '@/services/sales/sa';
import { ProductPrice } from '@/services/mdm/productprice';
import { ShipTo } from '@/services/mdm/shipto';
import { getDetailPrevData } from '../../components/data';
import { Enum } from '@/enums/system';

const PdGiveawayDetailApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const { formSaDetail, formSa, rowIndex, rows } = props.data;

  const handleProductBuyChange = (value: any) => {
    formRef.current?.setFieldsValue({ priceSellinBefore: null });
    if (value && formSaDetail.soldToCode) {
      ProductPrice.getProductPrice({ productId: value, soldToCode: formSaDetail.soldToCode }).then(response => {
        if (response?.code === ResponseStatus.SUCCESS && response.data) {
          formRef.current?.setFieldsValue({ priceSellinBefore: response.data.priceSellin });
          handleCalcRevenueSellin();
        } else {
          message.error('找不到购买产品供货价！');
        }
      });
    }
  };
  const handleProductFreeChange = (value: any) => {
    formRef.current?.setFieldsValue({ priceSellinFreeProduct: null });
    if (value && formSaDetail.soldToCode) {
      Product.get(value).then(response => {
        if (response?.code === ResponseStatus.SUCCESS && response.data) {
          formRef.current?.setFieldsValue({ priceSellinFreeProduct: response.data.priceCostExVat });
          handleCalcAmount();
        } else {
          message.error('找不到赠送产品成本价！');
        }
      });
    }
  };
  const handleCalcRevenueSellin = () => {
    const fields = formRef.current?.getFieldsValue();
    formRef.current?.setFieldsValue({ revenueSellinForecast: null });
    if (fields.priceSellinBefore && fields.quantityBuy && fields.quantityBomForecast) {
      formRef.current?.setFieldsValue({
        revenueSellinForecast: wdiCore.Number.ToNumber(fields.priceSellinBefore * fields.quantityBuy * fields.quantityBomForecast, 2)
      });
    }
  };
  const handleCalcAmount = () => {
    const fields = formRef.current?.getFieldsValue();
    formRef.current?.setFieldsValue({ amount: null });
    if (fields.priceSellinFreeProduct && fields.quantityFree && fields.quantityBomForecast) {
      formRef.current?.setFieldsValue({
        amount: wdiCore.Number.ToNumber(fields.priceSellinFreeProduct * fields.quantityFree * fields.quantityBomForecast, 2)
      });
    }
  };

  useEffect(() => {
    if (formSaDetail) {
      formRef.current?.setFieldsValue({
        ecName: formSaDetail.ecName,
        soldToName: wdiCore.String.Join(formSaDetail.soldToCode, formSaDetail.soldToName),
        revenueTypeName: formSaDetail.revenueTypeName,
        boName: formSaDetail.boName,
        productBuyIds: formSaDetail.productBuyIds,
        productFreeIds: formSaDetail.productFreeIds,
        quantityBuy: formSaDetail.quantityBuy,
        quantityFree: formSaDetail.quantityFree,
        quantityBomForecast: formSaDetail.quantityBomForecast,
        revenueSellinForecast: formSaDetail.revenueSellinForecast,
        amount: formSaDetail.amount,
        remark: formSaDetail.remark,
      });
      if (formSaDetail.productBuyIds) {
        Product.get(formSaDetail.productBuyIds).then(response => {
          if (response?.code === ResponseStatus.SUCCESS && response.data) {
            formRef.current?.setFieldsValue({ productBuyIds: { value: response.data.productId, label: response.data.codeOrName } });
          }
        });
        handleProductBuyChange(formSaDetail.productBuyIds);
      }
      if (formSaDetail.productFreeIds) {
        Product.get(formSaDetail.productFreeIds).then(response => {
          if (response?.code === ResponseStatus.SUCCESS && response.data) {
            formRef.current?.setFieldsValue({ productFreeIds: { value: response.data.productId, label: response.data.codeOrName } });
          }
        });
        handleProductFreeChange(formSaDetail.productFreeIds);
      }
      if (formSaDetail.shipToCodes) {
        ShipTo.search({ shipToCodes: formSaDetail.shipToCodes }).then(response => {
          if (response.data) {
            formRef.current?.setFieldsValue({ shipToCode: response.data.map(item => ({ value: item.shipToCode, label: item.codeOrName })) });
          }
        });
      }
    }
  }, [formSaDetail]);

  return (
    <WdiModalForm
      {...HorzLayoutOpts}
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      modalProps={{ onCancel: () => { props.onClose?.() }, }}
      onFinish={async (values) => {
        const { shipToCodes, productBuyIds, productFreeIds, ...entity } = values;
        if (!values.amount || values.amount <= 0) {
          message.error("申请金额必须大于等于0!");
          return;
        }
        entity.shipToCodes = WdiSelectFormat.getvalue(shipToCodes);
        entity.productBuyIds = WdiSelectFormat.getvalue(productBuyIds);
        entity.productFreeIds = WdiSelectFormat.getvalue(productFreeIds);
        const response = await FormSa.Buttons.validateDetail({
          rowIndex: rowIndex,
          formSa: { ...formSa },
          formSaDetail: Object.assign({}, getDetailPrevData(formSaDetail), entity),
          formSaDetails: rows
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (props.onSave) {
          const data = Object.assign({}, { rowIndex: rowIndex }, response.data);
          response.data = data;
          props.onSave(response);
        }
        return;
      }}
    >
      <Form.Item name="soldToName" label="售达方">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="shipToCodes" label="送达方">
        <WdiSelect {...{
          placeholder: "请选择送达方",
          remote: true,
          showSearch: true,
          mode: 'multiple',
          dataLoading: async (params) => {
            const responseResult = await ShipTo.search(Object.assign({ soldToCode: formSaDetail.soldToCode, isActive: true }, params));
            return responseResult.data.map((item) => { return { value: item.shipToCode, label: item.codeOrName }; });
          }
        }} />
      </Form.Item>
      <Form.Item name="productBuyIds" label="购买产品" rules={[{ required: true, message: '购买产品不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择购买产品",
          showSearch: true,
          defaultParamKey: "productIds",
          remote: true,
          defaultParamKey:"productIds",
          onChange: handleProductBuyChange,
          dataLoading: async (params) => {
            const response = await Product.ajaxSearchSku(Object.assign({ productType: Enum.ProductType.SKU, isActive: true }, params));
            return response.data.map((item) => { return { value: item.productId, label: item.codeOrName }; });
          }
        }} />
      </Form.Item>
      <Form.Item name="priceSellinBefore" label="priceSellinBefore" hidden>
        <WdiInputNumber />
      </Form.Item>
      <Form.Item name="productFreeIds" label="赠送产品" rules={[{ required: true, message: '赠送产品不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择赠送产品",
          showSearch: true,
          defaultParamKey: "productIds",
          remote: true,
          defaultParamKey:"productIds",
          onChange: handleProductFreeChange,
          dataLoading: async (params) => {
            const response = await Product.ajaxSearchSku(Object.assign({ productType: Enum.ProductType.SKU, isActive: true }, params));
            return response.data.map((item) => { return { value: item.productId, label: item.codeOrName }; });
          }
        }} />
      </Form.Item>
      <Form.Item name="priceSellinFreeProduct" label="priceSellinFreeProduct" hidden>
        <WdiInputNumber />
      </Form.Item>
      <Form.Item name="quantityBuy" label="每套购买数量(EA)" rules={[{ required: true, message: '每套购买数量不能为空!' }]}>
        <WdiInputNumber min={0} digits={0} onChange={() => { handleCalcRevenueSellin(); }} />
      </Form.Item>
      <Form.Item name="quantityFree" label="每套赠送数量(EA)" rules={[{ required: true, message: '每套赠送数量不能为空!' }]}>
        <WdiInputNumber min={0} digits={0} onChange={() => { handleCalcAmount(); }} />
      </Form.Item>
      <Form.Item name="quantityBomForecast" label="预计购买套数" rules={[{ required: true, message: '预计购买套数不能为空!' }]}>
        <WdiInputNumber min={0} digits={0} onChange={() => {
          handleCalcRevenueSellin();
          handleCalcAmount();
        }} />
      </Form.Item>
      <Form.Item name="revenueSellinForecast" label="预计销售额" rules={[{ required: true, message: '预计销售额不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="amount" label="申请金额" rules={[{ required: true, message: '申请金额不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <Input maxLength={2000} />
      </Form.Item>
      <Form.Item name="ecName" label="费用项">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="revenueTypeName" label="核算类型">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="boName" label="四级预算中心">
        <Input readOnly />
      </Form.Item>
    </WdiModalForm>
  );
};

export default PdGiveawayDetailApp;
