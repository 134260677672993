import { formatter, request } from '@/utils/request';

export namespace Supplier {
  export const Path = {
    Query: '/mdmapi/supplier/query',
    Search: '/mdmapi/supplier/search',
    AjaxSearch: '/mdmapi/supplier/ajaxSearch',
    Get: '/mdmapi/supplier/get',    
    Export: "/mdmapi/supplier/export",
  };

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.QueryForSupplierData>>(Path.Query, formatter(options),);
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.Supplier>>(Path.Get, formatter(options));
  }

  export async function search(body: API.SupplierFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Supplier[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.SupplierFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Supplier[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function exportEx(body: any) {
    const param = {
      method: 'POST',
      body: {
        "id": "Supplier",       
        ...body
      }
    }
    return request<API.ResponseResult<any>>(Path.Export, formatter(param));
  }
}