import { WdiModalAppResult } from "@/components/WdiControls";
import { BudgetProject } from "@/services/sales/bp/budgetProject";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, message, Space } from "antd";
import { useEffect, useState } from "react";
import PageControl, { ModuleType } from '@/components/WdiBus/FormPage';
import { Enum } from "@/enums/system";
import { ResponseStatus } from "@/utils/request";

type MoreWapperProps = {
  budgetProjectId?: number;
  onClose?: (result?: WdiModalAppResult) => void;
  callback?: (result?: WdiModalAppResult) => void;
};

const UpdateApplyButton: React.FC<MoreWapperProps> = (props: MoreWapperProps) => {
  const { budgetProjectId, onClose } = props;

  const handleClick = (e: any) => {
    if (onClose) {
      onClose();
      PageControl.apply({
        formTypeId: Enum.FormType.PROJECT_APPLICATION,
        pageTypeId: Enum.PageType.PROJECT_GENERAL,
        data: {
          budgetProjectId: budgetProjectId
        }
      });
    } else {
      window.open(`/project/generalprojectapply/?formId=${budgetProjectId}`);
    }
  }
  return (
    <>
      <div onClick={handleClick} >
        修改申请
      </div >
    </>
  )
}
const ClosedButton: React.FC<MoreWapperProps> = (props: MoreWapperProps) => {
  const { budgetProjectId, onClose } = props;
  const handleClick = (e: any) => {
    if (window.confirm('该专项关闭后将不能进行新的活动申请了，已经申请的活动可以继续核销，确定要关闭吗？')) {
      BudgetProject.Buttons.close(budgetProjectId || 0).then(response => {
        if (response.code == ResponseStatus.SUCCESS) {
          message.success("专项项目已关闭");
          if (onClose) {
            onClose();
            PageControl.show({
              data: { budgetProjectId: budgetProjectId },
              moduleType: ModuleType.Project
            });
          } else {
            window.location.reload();
          }
        } else {
          message.error(response.message);
        }
      });
    }
  }
  return (
    <div onClick={handleClick}>
      关闭专项
    </div>
  )
}
const OpenedButton: React.FC<MoreWapperProps> = (props: MoreWapperProps) => {
  const { budgetProjectId, onClose } = props;
  const handleClick = (e: any) => {
    if (window.confirm('确定要重新打开此专项吗？')) {
      BudgetProject.Buttons.opened(budgetProjectId || 0).then(response => {
        if (response.code == ResponseStatus.SUCCESS) {
          message.success("专项项目已重新打开");
          if (onClose) {
            onClose();
            PageControl.show({
              data: { budgetProjectId: budgetProjectId },
              moduleType: ModuleType.Project
            });
          } else {
            window.location.reload();
          }
        } else {
          message.error(response.message);
        }
      });
    }
  }
  return (
    <div onClick={handleClick}>
      重新打开
    </div>
  )
}
const ReportButton: React.FC<MoreWapperProps> = (props: MoreWapperProps) => {
  const { budgetProjectId } = props;
  const handleClick = (e: any) => {
    window.open(`/report/mutiproject/?budgetProjectId=${budgetProjectId}`);
  }
  return (
    <div onClick={handleClick}>
      预算使用分析
    </div>
  )
}
export const BPMoreWapper: React.FC<MoreWapperProps> = (props: MoreWapperProps) => {
  const [menuItems, setMenuItems] = useState<any[]>([]);

  useEffect(() => {
    if (props.budgetProjectId) {
      BudgetProject.Buttons.get(props.budgetProjectId).then(response => {
        const buttonAccess = response.data;
        const newMenuItems: any[] = [];
        if (buttonAccess.allowUpdateApply) {
          newMenuItems.push({
            label: (
              <UpdateApplyButton {...props} />
            ),
            key: "UpdateApply"
          })
        }
        if (buttonAccess.allowClosed) {
          newMenuItems.push({
            label: (
              <ClosedButton {...props} />
            ),
            key: "Closed"
          })
        }
        if (buttonAccess.allowOpened) {
          newMenuItems.push({
            label: (
              <OpenedButton {...props} />
            ),
            key: "Opened"
          })
        }
        if (buttonAccess.allowBudgetProjectDetailReport) {
          newMenuItems.push({
            label: (
              <ReportButton {...props} />
            ),
            key: "Report"
          })
        }
        setMenuItems(newMenuItems);
      })
    }
  }, [props.budgetProjectId]);

  return (
    <>
      <Dropdown overlay={
        <Menu
          items={menuItems}
        />
      }>
        <a onClick={e => e.preventDefault()}>
          <Space>
            More <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </>
  )
}
