import { useEffect, useRef, useState } from 'react';
import { WdiModalAppProps, wdiCore } from '@/components/WdiControls';
import { ProFormInstance } from '@ant-design/pro-form';
import { WdiSelect, WdiSelectFormat } from '@/components/WdiControls/WdiSelect';
import { Form, Input, message } from 'antd';
import { WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { BO } from '@/services/mdm/bo';
import { ResponseStatus } from '@/utils/request';
import { SoldTo } from '@/services/mdm/soldto';
import { FormSa } from '@/services/sales/sa';
import { BudgetProject } from '@/services/sales/bp/budgetProject';
import { Enum } from '@/enums/system';

const FreeGoodsPickerApp: React.FC<WdiModalAppProps> = (props) => {

  const formRef = useRef<ProFormInstance>();
  const [budgetProject, setBudgetProject] = useState<API.BudgetProject>();
  const [boOptions, setBoOptions] = useState<any[]>([]);

  const handleSoldToCodeChange = (value: any) => {
    formRef.current?.setFieldsValue({ BOID: null });
    initBODataSource(value);
  };
  const initBODataSource = (soldToCode: any) => {
    formRef.current?.setFieldsValue({
      boId: null
    });
    if (soldToCode) {
      BO.search({ soldToCode: soldToCode, boLevels: `${Enum.BOLevel.BO4}`, withPermissions: true, isActive: true }).then(response => {
        setBoOptions(response?.data.map((item) => { return { value: item.boId, label: item.boName } }));
        if (response.data && response?.data.length == 1) {
          formRef.current?.setFieldsValue({
            boId: response.data[0].boId
          });
        }
      });
    } else {
      setBoOptions([]);
    }
  }

  useEffect(() => {
    if (props.data?.budgetProjectId) {
      BudgetProject.get(props.data.budgetProjectId).then(response => {
        setBudgetProject(response.data);
      })
    }
  }, [props.data.budgetProjectId]);

  useEffect(() => {
    if (props.data?.formSaDetail) {
      const detail = props.data?.formSaDetail;
      formRef.current?.setFieldsValue({
        formSaDetailId: detail.formSaDetailId,
        rowNumber: detail.rowNumber,
      });
      if (detail.soldToCode) {
        formRef.current?.setFieldsValue({ soldToCode: { value: detail.soldToCode, label: wdiCore.String.Join(detail.soldToCode, detail.soldToName) } });
        initBODataSource(detail.soldToCode);
        formRef.current?.setFieldsValue({ boId: detail.boId });
      }
    }
  }, [props.data?.formSaDetail]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      labelAlign='left'
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      modalProps={{
        onCancel: () => { props.onClose ? props.onClose() : null },
        destroyOnClose: true,
        maskClosable: false,
        okText: '下一步'
      }}
      onFinish={async (values) => {
        const { soldToCode, ...entity } = values;
        entity.soldToCode = WdiSelectFormat.getvalue(soldToCode);

        const response = await FormSa.Buttons.validatePicker({
          rowIndex: props.data.rowIndex,
          ecId: props.data?.ecId,
          formSaDetail: {
            ecId: props.data?.ecId,
            soldToCode: entity.soldToCode,
            boId: entity.boId
          },
          formSa: {
            pageTypeId: props.data?.pageTypeId,
            budgetProjectId: props.data?.budgetProjectId,
          }
        });

        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
        } else {
          if (props.onSave) {
            props.onSave(response);
          }
        }
      }}
    >
      <Form.Item name="formSaDetailId" label="formSaDetailId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="rowNumber" label="rowNumber" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="soldToCode"
        label="售达方"
        rules={[{ required: true, message: '售达方不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择售达方",
          showSearch: true,
          remote: true,
          onChange: handleSoldToCodeChange,
          dataLoading: async (params) => {
            const response = await SoldTo.ajaxSearch(Object.assign({ withPermissions: true, isActive: true, budgetProjectId: props.data?.budgetProjectId, soldToCodes: budgetProject?.limitedSoldToCodes, }, params));
            const soldToOptions = response.data?.map((item) => { return { value: item.soldToCode, label: item.codeOrName } });
            if (soldToOptions && soldToOptions.length === 1) {
              formRef.current?.setFieldsValue({ soldToCode: { value: soldToOptions[0].value, label: soldToOptions[0].label } });
              initBODataSource(soldToOptions[0].value);
            }
            return soldToOptions;
          },
          reloadKey: budgetProject?.limitedSoldToCodes
        }} />
      </Form.Item>
      <Form.Item
        name="boId"
        label="四级预算中心"
        rules={[{ required: true, message: '四级预算中心不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择四级预算中心",
          showSearch: true,
          options: boOptions
        }} />
      </Form.Item>
    </WdiModalForm>
  );
}
export default FreeGoodsPickerApp;