import type {ParamsProps} from '@/components/WdiBus/FormPage';
import type {WdiModalAppResult} from '@/components/WdiControls';
import {SoldToGroup} from '@/services/mdm/soldtogroup';
import {CustSys} from '@/services/mdm/custsys';
import {Staff} from '@/services/basic/staff';
import {SalesContract} from '@/services/contract/salescontract';
import {SalesContractHistory} from '@/services/contract/salescontracthistory';
import type {StatusEnum} from '@/enums/status';
import {SoldTo} from "@/services/mdm/soldto";
import {ResponseCode} from "@/utils/response";
import {ResponseStatus} from "@/utils/request";
import moment from "moment";
import {FileUploadUtility} from "@/components/WdiControls/WdiFileUpload";
import {Enum} from "@/enums/system";
import {BOHQ} from "@/services/mdm/bohq";
import { SimpleStaff } from '@/services/basic/simplestaff';

export type ViewEntityProps = {
  statusId?: StatusEnum.FormStatus;
  contractType?: number;
  salesContractId?: number;
  salesContract?: API.SalesContract;
  soldToGroup?: API.SoldToGroup;
  soldTo?: API.SoldTo;
  custSys?: API.CustSys;
  modifiedUser?: API.SimpleStaff;
  bohq?: API.BOHQ;
}

export type ViewEntityHistoryProps = {
  salesContractHistoryId?: number;
  contractType?: number;
  salesContractHistory?: API.SalesContractHistory;
  soldToGroup?: API.SoldToGroup;
  soldTo?: API.SoldTo;
  custSys?: API.CustSys;
  modifiedUser?: API.Staff;
  bohq?: API.BOHQ;
}

export const isContractType1 = (contractType?: number) => {
  if (contractType && contractType == Enum.SalesContractType.SELLIN) {
    return true;
  }
  return false;
};

export const isContractType2 = (contractType?: number) => {
  if (contractType && contractType == Enum.SalesContractType.SELLOUT) {
    return true;
  }
  return false;
};

export const initPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseCode.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.contractType) {
    viewEntity.contractType = params.contractType;
  }
  if (params.salesContractId) {
    viewEntity.salesContractId = params.salesContractId;
    viewEntity.salesContract = (await SalesContract.get(params.salesContractId)).data;
    if (viewEntity.salesContract) {
      viewEntity.contractType = viewEntity.salesContract.contractType;
      if (viewEntity.salesContract.soldToGroupCode) {
        viewEntity.soldToGroup = (await SoldToGroup.get(String(viewEntity.salesContract.soldToGroupCode))).data;
      }
      if (viewEntity.salesContract.custSysCode) {
        viewEntity.custSys = (await CustSys.get(String(viewEntity.salesContract.custSysCode))).data;
      }
      if (viewEntity.salesContract.soldToCode) {
        viewEntity.soldTo = (await SoldTo.get(viewEntity.salesContract.soldToCode)).data;
      }
      if (viewEntity.salesContract.bohqId) {
        viewEntity.bohq = (await BOHQ.get(viewEntity.salesContract.bohqId)).data;
      }
      viewEntity.modifiedUser = (await SimpleStaff.get(Number(viewEntity.salesContract.modifiedUserId))).data;
    }
  }

  result.code = ResponseCode.SUCCESS;
  result.data = viewEntity;
  return result;
}

export const initHistoryPageContext = async (id: number) => {
  const result: WdiModalAppResult = {
    code: ResponseCode.UNKNOWN
  };

  const viewEntity: ViewEntityHistoryProps = {};
  if (id) {
    viewEntity.salesContractHistoryId = id;
    viewEntity.salesContractHistory = (await SalesContractHistory.get(id)).data;
    if (viewEntity.salesContractHistory) {
      viewEntity.contractType = viewEntity.salesContractHistory.contractType;
      if (viewEntity.salesContractHistory.soldToGroupCode) {
        viewEntity.soldToGroup = (await SoldToGroup.get(String(viewEntity.salesContractHistory.soldToGroupCode))).data;
      }
      if (viewEntity.salesContractHistory.custSysCode) {
        viewEntity.custSys = (await CustSys.get(String(viewEntity.salesContractHistory.custSysCode))).data;
      }
      if (viewEntity.salesContractHistory.soldToCode) {
        viewEntity.soldTo = (await SoldTo.get(viewEntity.salesContractHistory.soldToCode)).data;
      }
      if (viewEntity.salesContractHistory.bohqId) {
        viewEntity.bohq = (await BOHQ.get(viewEntity.salesContractHistory.bohqId)).data;
      }
      viewEntity.modifiedUser = (await Staff.get(Number(viewEntity.salesContractHistory.modifiedUserId))).data;
    }
  }

  result.code = ResponseCode.SUCCESS;
  result.data = viewEntity;
  return result;
}

export const getPageResult = (values: any, viewEntity?: ViewEntityProps, dataSource?: any[]) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };

  const {contractDate, fileInfo, signDate, isAutoAppend, custSysCode, ...entity} = values;

  if (contractDate && contractDate.length == 2) {
    entity.beginDate = moment(contractDate[0]).format('YYYY-MM-DD');
    entity.endDate = moment(contractDate[1]).format('YYYY-MM-DD');
  } else {
    result.message = '无效的合同期间';
    return result;
  }

  if (signDate) {
    entity.signDate = moment(signDate).format('YYYY-MM-DD');
  }

  if (FileUploadUtility.isUploading(fileInfo)) {
    result.message = '附件正在上传中!';
    return result;
  }

  if (FileUploadUtility.isError(fileInfo)) {
    result.message = '附件存在错误文件!';
    return result;
  }

  const info = FileUploadUtility.serialize(fileInfo);
  entity.fileName = info?.names;
  entity.filePath = info?.urls;

  const data: API.SalesContractPostParam = {
    salesContract: {},
    salesContractTermsList: []
  };

  entity.isAutoAppend = Boolean(isAutoAppend);
  entity.custSysCode = custSysCode;

  if (viewEntity && viewEntity.salesContract) {
    const salesContract = viewEntity.salesContract;
    entity.salesContractId = salesContract.salesContractId;
    entity.isActive = salesContract.isActive;
    entity.contractVersion = salesContract.contractVersion;
  } else {
    entity.salesContractId = 0;
    entity.isActive = true;
    entity.contractVersion = 1;
  }

  data.salesContract = entity;
  if (dataSource && dataSource.length) {
    dataSource.forEach((item, itemIndex) => {
      data.salesContractTermsList.push({
        ...item,
        ...{
          rowNumber: itemIndex + 1
        }
      });
    });
  } else {
    result.message = '合同明细至少填写一条!';
    return result;
  }
  result.data = data;
  result.code = ResponseStatus.SUCCESS;
  return result;
};
