import {formatter, request} from "@/utils/request";

export namespace SalesContractTerms {
  const Path = {
    ExistsRefDetail: '/contractapi/contract/existsRefDetail',
    ValidateDetail: '/contractapi/contract/validateDetail',
    GetSalesContractTerms: '/contractapi/contract/getSalesContractTerms',
  }

  export async function existsRefDetail(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<Boolean>>(Path.ExistsRefDetail, formatter(options));
  }

  export async function validateDetail(body: API.SalesContractTermsPostParam) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.ResponseResult<API.SalesContractTermsVo>>(Path.ValidateDetail, formatter(options));
  }

  export async function getSalesContractTerms(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.SalesContractTermsVo[]>>(Path.GetSalesContractTerms, formatter(options));
  }
}

