import { request, formatter } from '@/utils/request';

export namespace Persistence {
  export const Path = {
    search: '/gridapi/persistence/search',
    get: '/gridapi/persistence/get',
    query: '/gridapi/persistence/query',
    getPersistenceObjectByCode: '/gridapi/persistence/getPersistenceObjectByCode',
    post: '/gridapi/persistence/post',
    delete: '/gridapi/persistence/delete',
    getBuConfig: '/gridapi/persistence/getBuConfig'
  }

  export async function search() {
    const options = {
      method: 'POST',
      body: {

      },
    };
    return request<API.ResponseResult<API.CFPersistenceDef[]>>(Path.search, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.CFPersistenceDef>>(Path.get, formatter(options));
  }

  export async function query(type: number,) {
    const options = {
      method: 'GET',
      body: {
        type: type,
      },
    };
    return request<API.ResponseResult<API.CFPersistenceDef[]>>(Path.query, formatter(options));
  }

  export async function getPersistenceObjectByCode(code: string) {
    const options = {
      method: 'GET',
      body: {
        code: code,
      },
    };
    return request<API.ResponseResult<API.CFPersistenceDef>>(Path.getPersistenceObjectByCode, formatter(options));
  }

  export async function post(body: API.CFPersistenceDef) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.CFPersistenceDef>>(Path.post, formatter(options));
  }

  export async function del(id: string) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<any>>(Path.delete, formatter(options));
  }

  export async function getBuConfig() {
    const options = {
      method: 'GET',
    };
    return request<API.ResponseResult<API.BuConfig>>(Path.getBuConfig, formatter(options));
  }
}
