import useWindowSize from '@/utils/useWindowSize';
import {EllipsisOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import React, {Component} from 'react';
import {initColumnStates, initDefaultFilterValues, initFilterStates, initViewState, ViewState,} from './dataConvert';
import {NsGrid} from './GridDefProps';
import {calcFilterPanelHeight, GridFilterPanel} from './GridFilterPanel';
import {PageTable, PageTableProps, PaginationValue, ShowAggrValue, SortValue} from './PageTable';
import {NsGridFilter} from './GridWidgetFilter';
import {ViewColumnSettingApp} from './ViewSettings';

type GridWidgetProps = {
  // 表格查询需要的数据源
  dataLoading: (args: API.JQXGridArgs, exParams?: any) => Promise<API.QueryResult<any>>;
  // 自定义过滤条件
  defaultFilterValues?: NsGridFilter.FilterItemValue[];
  // 固定查询条件, 所有查询都会包含当前查询条件
  fixedQueryValues?: NsGridFilter.FilterItemValue[];
  // 操作栏平铺操作展示数量, 默认平铺一个
  showItemCount?: number;
  // 操作栏 actions 函数不确定类型
  actionsCellsRenderer?: any;
  // 操作栏 actions 根据 record 过滤
  filterMenuActions?: (actions: API.ActionObject[], record: any) => API.ActionObject[];
  // 控件的宽度
  width?: number;
  // 控件高度
  height?: number;
  // 附件聚合字段, 不支持
  additionallNames?: number;
  // 是否展开过滤面板
  showFilterPanel?: boolean;
  // 展示聚合内容
  showAggr?: false | ShowAggrValue;
  rowKey?: string;
  // 默认的数据排序规则
  extendSortFields?: any;
  // 数据是否延迟加载，报表使用
  isLazyLoad?: boolean;
  // Antd Table Props
  antdProps?: any;
  // 扩展参数和方法
  params?: any;
  // 列表显示红色警示语
  warningText?: string;
  //是否有扩展列，配置这个不能配置rowKey，或者配置成uid,否则全部展开不起作用
  showExpandedRow?: boolean;
  //每行是否展示展开图标
  showExpendIcon?: (record: any) => boolean;
  //扩展列是否默认展开，默认为false
  defaultShowAllExpanded?: boolean;
  expandable?: any;
} & API.GridConfig;

type GridWidgetStates = {
  // 视图定义
  viewState: ViewState;
  // 过滤列
  filterStates: NsGridFilter.IBasicFilter[];
  // 数据表展示列
  columnStates: any[];
  // 过滤面板高度, 根据过滤条件计算
  filterPanelHeight: number;
  // 是否展开过滤面板
  showFilterPanel: boolean;
  // 列表宽度
  width?: number;
  // 弹出数据列配置窗口
  showModifyModal: boolean;
};

const formatPageTableProps: (props: GridWidgetProps, state: GridWidgetStates) => PageTableProps = (
  props: GridWidgetProps,
  state: GridWidgetStates,
): PageTableProps => {
  const settings: PageTableProps = {
    formSchema: props.formSchema,
    filterStates: state.filterStates,
    columnStates: state.columnStates,
    rowKey: props.rowKey,
    dataLoading: props.dataLoading,
    height: props.height,
    width: props.width,
    fixedQueryValues: props.fixedQueryValues,
    extendSortFields: props.extendSortFields,
    showFilterPanel: state.showFilterPanel,
    filterPanelHeight: state.filterPanelHeight,
    hiddenToolbar: props.hiddenToolbar,
    isLazyLoad: props.isLazyLoad,
    // 固定写死
    //       heightHeader: props.heightHeader, // Antd Table 表头高度默认39
    //       heightFooter: props.heightFooter, // Antd Table 底部分页高度默认 56
    showAggr: props.showAggr,
    showExpandedRow: props.showExpandedRow ? props.showExpandedRow : false,
    showExpendIcon: props.showExpendIcon ? props.showExpendIcon : () => true,
    defaultShowAllExpanded: props.defaultShowAllExpanded ? props.defaultShowAllExpanded : false,
    expandable: props.expandable,
    antdProps: Object.assign({}, props.antdProps),
  };
  return settings;
};

const formatGridPanelProps = (props: GridWidgetProps, state: GridWidgetStates) => {
  const settings = {
    // grid
    formSchema: props.formSchema,
    viewState: state.viewState,
    filterStates: state.filterStates,
    showFilterPanel: state.showFilterPanel,
    filterFormat: props.filterFormat,
    warningText: props.warningText,
  };
  return settings;
};

class GridWidget extends Component<GridWidgetProps> {
  state: GridWidgetStates;
  tableRef: any;

  constructor(props: GridWidgetProps) {
    super(props);

    // 初始化视图对象
    const viewState = initViewState({
      viewMode: props.viewMode,
      viewObject: props.viewObject,
      viewDefs: props.viewDefs,
    });

    // 初始化过滤控件
    const filterStates = initFilterStates(
      {
        filterFormat: props.filterFormat,
        formSchema: props.formSchema,
        viewObject: props.viewObject,
      },
      this,
    );

    /// 根据视图初始化过滤控件默认值
    if (props.viewObject && props.viewObject.storageType == NsGrid.StorageType.All) {
      initDefaultFilterValues(filterStates, props.viewObject.filterOptions);
    }

    /// 根据页面设置默认值, 初始化过滤控件默认值, 会覆盖掉控件出事化时的默认值
    if (props.defaultFilterValues) {
      initDefaultFilterValues(filterStates, props.defaultFilterValues);
    }

    // 初始化默认数据展示列, 和操作按钮
    const columnStates = initColumnStates(
      {
        formSchema: props.formSchema,
        actionsAlign: props.actionsAlign,
        showItemCount: props.showItemCount,
        actionsCellsRenderer: props.actionsCellsRenderer,
        filterMenuActions: props.filterMenuActions,
        width: props.width,
        additionallNames: props.additionallNames,
      },
      props.viewObject,
      this,
    );

    this.state = {
      viewState: viewState,
      filterStates: filterStates,
      columnStates: columnStates,
      filterPanelHeight: 0,
      showFilterPanel: false,
      showModifyModal: false,
    };

    // 计算过滤面板高度
    if (
      props.filterFormat === NsGrid.FilterFormat.Default ||
      props.filterFormat === NsGrid.FilterFormat.Expand
    ) {
      this.state.filterPanelHeight = calcFilterPanelHeight(filterStates);
    }

    // 视图中配置展开过滤面板
    if (props.viewObject && props.viewObject.isFilterExpand) {
      this.state.showFilterPanel = props.viewObject.isFilterExpand;
    }

    // 列表配置强行展开过滤面板, 覆盖掉视图配置
    if (props.showFilterPanel) {
      this.state.showFilterPanel = props.showFilterPanel;
    }

    if (props.width) {
      this.state.width = props.width;
    }
    this.tableRef = React.createRef();

    this.viewToggle = this.viewToggle.bind(this);
    this.viewColumnToggle = this.viewColumnToggle.bind(this);
    this.expandFilterPanelToggle = this.expandFilterPanelToggle.bind(this);
    this.handleViewColumnClick = this.handleViewColumnClick.bind(this);
    this.reload = this.reload.bind(this);
    this.byFilter = this.byFilter.bind(this);
    this.byFilterOptions = this.byFilterOptions.bind(this);
    this.byReset = this.byReset.bind(this);
    this.getGridArgs = this.getGridArgs.bind(this);
  }

  doSearch = (e: any) => {
    let key = e?.key;
    let tagName = e?.target?.tagName;
    if ('input' == tagName.toLocaleLowerCase()) {
      var className = e?.target?.className;
      if ('ant-input' == className.toLocaleLowerCase()) {
        if ('enter' == key.toLocaleLowerCase()) {
          this.byFilter();
        }
      }
    }
  };

  componentDidMount() {
    document.body.addEventListener('keyup', this.doSearch);
  }

  componentWillUnmount() {
    document.body.removeEventListener('keyup', this.doSearch);
  }

  /// 视图切换, 编辑, 新建
  viewToggle = (viewObject: API.ViewObject, viewDefs: API.ViewDef[]) => {
    this.setState((prevState: GridWidgetStates, prevProps: GridWidgetProps) => {
      const viewState = initViewState({
        viewMode: prevProps.viewMode,
        viewObject,
        viewDefs,
      });
      //更新视图强制刷新
      viewState.viewKey = prevState.viewState.viewKey + 1;

      // 初始化过滤条件
      const filterStates = initFilterStates(
        {
          filterFormat: prevProps.filterFormat,
          formSchema: prevProps.formSchema,
          viewObject,
        },
        this,
      );

      /// 根据视图初始化控件默认值
      if (viewObject && viewObject.storageType == NsGrid.StorageType.All) {
        initDefaultFilterValues(filterStates, viewObject.filterOptions);
      }
      /// 根据页面设置默认值, 初始化过滤控件默认值, 会覆盖掉控件出事化时的默认值
      if (this.props.defaultFilterValues) {
        initDefaultFilterValues(filterStates, this.props.defaultFilterValues);
      }

      // 根据过滤条件估算过滤面板高度，设置Grid自适应
      let filterPanelHeight = 0;
      // 计算过滤面板高度
      if (
        prevProps.filterFormat === NsGrid.FilterFormat.Default ||
        prevProps.filterFormat === NsGrid.FilterFormat.Expand
      ) {
        filterPanelHeight = calcFilterPanelHeight(filterStates);
      }

      const columnStates = initColumnStates(
        {
          formSchema: prevProps.formSchema,
          actionsAlign: prevProps.actionsAlign,
          showItemCount: prevProps.showItemCount,
          actionsCellsRenderer: prevProps.actionsCellsRenderer,
          filterMenuActions: prevProps.filterMenuActions,
          width: prevState.width,
          additionallNames: prevProps.additionallNames,
        },
        viewObject,
        this,
      );
      return {
        viewState,
        filterStates,
        columnStates,
        showModifyModal: false,
        filterPanelHeight,
        showFilterPanel: viewObject?.isFilterExpand,
      };
    });
  };

  /// 更新视图切换数据列, 只更新数据展示列
  viewColumnToggle = (viewObject: API.ViewObject, viewDefs: API.ViewDef[]) => {
    const filterOptions = this.byFilterOptions();

    this.setState((prevState: GridWidgetStates, prevProps: GridWidgetProps) => {
      const viewState = initViewState({
        viewMode: prevProps.viewMode,
        viewObject,
        viewDefs,
      });
      //更新视图强制刷新
      viewState.viewKey = prevState.viewState.viewKey + 1;

      // 初始化过滤条件
      const filterStates = initFilterStates(
        {
          filterFormat: prevProps.filterFormat,
          formSchema: prevProps.formSchema,
          viewObject,
        },
        this,
      );

      /// 过滤面板刷新需要根据当前过滤条件进行查询，重新渲染当前查询条件对应的值
      if (viewObject && viewObject.storageType == NsGrid.StorageType.All) {
        initDefaultFilterValues(filterStates, filterOptions);
      }

      // 根据过滤条件估算过滤面板高度，设置Grid自适应
      let filterPanelHeight = 0;
      // 计算过滤面板高度
      if (
        prevProps.filterFormat === NsGrid.FilterFormat.Default ||
        prevProps.filterFormat === NsGrid.FilterFormat.Expand
      ) {
        filterPanelHeight = calcFilterPanelHeight(filterStates);
      }

      const columnStates = initColumnStates(
        {
          formSchema: prevProps.formSchema,
          actionsAlign: prevProps.actionsAlign,
          showItemCount: prevProps.showItemCount,
          actionsCellsRenderer: prevProps.actionsCellsRenderer,
          filterMenuActions: prevProps.filterMenuActions,
          width: prevState.width,
          additionallNames: prevProps.additionallNames,
        },
        viewObject,
        this,
      );

      return {
        viewState,
        filterStates,
        columnStates,
        showModifyModal: false,
        filterPanelHeight,
        showFilterPanel: viewObject?.isFilterExpand,
      };
    });
  };

  expandFilterPanelToggle = () => {
    this.setState((prevState: GridWidgetStates) => ({
      showFilterPanel: !prevState.showFilterPanel,
    }));
  };

  closeViewColumnModal = () => {
    this.setState({showModifyModal: false});
  };

  handleViewColumnClick = () => {
    this.setState({showModifyModal: true});
  };

  getTableRef = () => {
    return this.tableRef && this.tableRef.current ? this.tableRef.current : null;
  };

  // 后期删掉
  //   /**表格插入新记录 */
  //   insertRecords = (...records) => {
  //     this.getTableRef()?.insertRecords?.(...records);
  //   };
  //   /**更新表格对应记录 */
  //   updateRecords = (...records) => {
  //     this.getTableRef()?.updateRecords?.(...records);
  //   };
  //   /**删除表格对应记录 */
  //   deleteRecords = (...records) => {
  //     this.getTableRef()?.deleteRecords?.(...records);
  //   };
  //   /**更新表格对应记录 */
  //   updateRecords = (...records) => {
  //     this.getTableRef()?.updateRecords?.(...records);
  //   };
  //   /**获取数据源 */
  //   getDataSource = () => {
  //     return this.getTableRef()?.getDataSource?.();
  //   };
  getRowSelection = () => {
    return this.getTableRef()?.getRowSelection?.();
  };

  reload = (p?: {
    filterOptions?: NsGridFilter.FilterItemValue[];
    pagination?: PaginationValue | false;
    sorter?: SortValue;
  }) => {
    if (this.getTableRef()?.reload) {
      this.getTableRef()?.reload(p);
    }
  };

  byFilter = () => {
    if (this.getTableRef()?.applyFilter) {
      this.getTableRef().applyFilter();
    }
  };

  byFilterOptions = () => {
    if (this.getTableRef()?.filterOptions) {
      const responseResult = this.getTableRef().filterOptions();
      return responseResult.data;
    }
  };

  // 重置筛选条件
  byReset = () => {
    this.setState((prevState: GridWidgetStates, prevProps: GridWidgetProps) => {
      const filterStates = [...prevState.filterStates];
      (filterStates ? filterStates : []).forEach((item: any) => {
        item.clearDefaultValue();
      });
      /// 根据视图初始化过滤控件默认值
      if (this.props.viewObject && this.props.viewObject.storageType == NsGrid.StorageType.All) {
        initDefaultFilterValues(filterStates, this.props.viewObject.filterOptions);
      }
      /// 根据页面设置默认值, 初始化过滤控件默认值, 会覆盖掉控件出事化时的默认值
      if (this.props.defaultFilterValues) {
        initDefaultFilterValues(filterStates, this.props.defaultFilterValues);
      }
      (filterStates ? filterStates : []).forEach((item) => {
        item.resetValue();
      });
      return {filterStates: filterStates};
    });
    // if (this.getTableRef()?.resetFilter) {
    //   this.getTableRef().resetFilter();
    // }
  };

  getGridArgs = (p?: {
    filterOptions?: NsGridFilter.FilterItemValue[];
    pagination?: PaginationValue | false;
    sorter?: SortValue;
  }) => {
    let jqxGridArgs = null;
    if (this.getTableRef()?.getGridArgs) {
      jqxGridArgs = this.getTableRef().getGridArgs(p);
    }
    return jqxGridArgs;
  };

  getGridArgsForExport = (p?: {
    filterOptions?: NsGridFilter.FilterItemValue[];
    pagination?: PaginationValue | false;
    sorter?: SortValue;
  }) => {
    var jqxGridArgs = this.getGridArgs(p);
    if (jqxGridArgs) {
      const columns: string[] = [];
      this.tableRef.current.state.columns.forEach((item: any) => {
        columns.push(item.key);
      });
      jqxGridArgs = Object.assign({}, jqxGridArgs, {
        columns: columns.join(','),
        viewId: this.state.viewState.viewObject.id
      });
    }
    return jqxGridArgs;
  };

  getContext = () => {
    return {
      dataSource: this.props.antdProps?.dataSource,
      params: this.props.params,
    };
  };

  render() {
    const tableSettings = formatPageTableProps(this.props, this.state);
    const panelSettings = formatGridPanelProps(this.props, this.state);
    const showToolbar = !this.props.hiddenToolbar;
    return (
      <div>
        {showToolbar && (
          <GridFilterPanel
            {...panelSettings}
            grid={this}
            key={'filter' + this.state.viewState.viewKey}
          />
        )}
        <div className="grid-body">
          <PageTable
            {...tableSettings}
            ref={this.tableRef}
            key={'body' + this.state.viewState.viewKey}
          />
          {this.props.allowModifyColumns && (
            <div className="grid-body-modifycloumns">
              <Button
                type="default"
                icon={<EllipsisOutlined/>}
                size={'small'}
                onClick={this.handleViewColumnClick}
              />
              {this.state.showModifyModal && (
                <ViewColumnSettingApp
                  {...{
                    visible: this.state.showModifyModal,
                    viewObject: this.state.viewState.viewObject,
                    formSchema: this.props.formSchema,
                  }}
                  grid={this}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

type PageGridProps = {
  getInstance?: any;
  ref?: any;
} & GridWidgetProps;

const PageGrid = React.forwardRef((props: PageGridProps, ref: any) => {
  // 这里重新定义一个props的原因是:
  // 你直接去修改this.props.ref在react开发模式下会报错，不允许你去修改
  const newProps = {
    ...props,
  };

  // 在这里把getInstance赋值给ref，
  // 传给`GridWidget`，这样就getInstance能获取到`GridWidget`实例
  newProps.ref = (el: any) => {
    if (props.getInstance && 'function' === typeof props.getInstance) {
      props.getInstance(el);
    }
    if (ref) {
      ref.current = el;

      console.log("ref.current ", ref.current)

    }
  };

  const windowSize = useWindowSize();
  const containerSize = {
    width: windowSize.width - 228,
    height:
      newProps.antdProps?.pagination == false ? windowSize.height - 74 : windowSize.height - 50,
  };
  return (
    <div className="ant-pro-page-container">
      <div className="ant-pro-grid-content">
        <div className="ant-pro-grid-content-children">
          <div className="ant-pro-page-container-children-content">
            <GridWidget {...containerSize} {...newProps} />
          </div>
        </div>
      </div>
    </div>
  );
});

export {GridWidget, PageGrid};
