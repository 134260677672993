export namespace NsGrid {
  export const ActionsAlign = {
    // 不设置操作
    None: 0,
    // 左侧操作
    Left: 1,
    // 右侧操作
    Right: 2,
  };

  export const FilterFormat = {
    // 不设置过滤栏
    None: 0,
    // 默认过滤栏
    Default: 1,
    // 展开过滤栏, 不能收缩
    Expand: 2,
    // 自定义过滤方式(无效)
    Custom: 3,
  };

  export const ViewMode = {
    /**0 不设置过滤模式 */
    None: 0,
    /**1 简单过滤模式, 只有编辑视图, 不能新建删除 */
    Simple: 1,
    /**2 高级过滤模式, 支持视图编辑, 新建, 删除 */
    Advance: 2,
  };

  export const FilterOptType = {
    // 等于
    EQ: 1,
    // 不等于
    NEQ: 2,
    // 大于
    GT: 3,
    // 大于等于
    EGT: 4,
    // 小于
    LT: 5,
    // 小于等于
    ELT: 6,
    // 包含
    CONTAINS: 7,
    // IN
    IN: 8,
  };

  export const ViewDefType = {
    // 不保存查询条件
    None: 1,
    // 保存查询条件
    ByFilter: 2,
    // 系统自动生成
    Auto: 99,
  };

  export const FilterColumnDataType = {
    Int: 'Int',
    Long: 'Long',
    String: 'String',
    Decimal: 'Decimal',
    DateTime: 'DateTime',
    Boolean: 'Boolean',
  };

  export const DisplayType = {
    Pivot: 1,
    Table: 2,
    Calendar: 3
  };

  export const StorageType = {
    // 保存查询条件和设置默认值
    All: 1,
    // 仅保存查询条件
    OnlyConfig: 2,
  };

  export const ScopeType = {
    // 系统默认配置
    Default: 1,
    // 用户自定义
    Custom: 2,
  };

  export const DisplayTypeItems = [
    {value: 1, name: "交叉表"},
    {value: 2, name: "表格"},
    {value: 3, name: "日历"},
  ];

  export const BiViewType = {
    Table: 1,
    Pivot: 2,
  };

  export const BiColumnType = {
    None: 0,
    Indicator: 1,
    Dimension: 2,
  };

  export const ColumnTypeItems = [
    {value: 1, name: "量度"},
    {value: 2, name: "维度"},
  ];

  // 视图创建类型
  export const CreateType = {
    // 用户创建
    None: 0,
    // 系统自动生成
    Temp: 1,
    // 用户创建
    Custom: 2,
    // 系统自动创建
    Auto: 99
  };
}
