import React, {useState} from 'react';
import {wdiCore, WdiModalProps} from '@/components/WdiControls';
import {WdiModalForm} from '@/components/WdiControls/WdiModalForm';
import {WdiIcon} from '@/components/WdiControls/WdiIcon';
import {Button, Typography} from 'antd';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {WdiTable} from '../WdiControls/WdiTable';
import {ResponseStatus} from '@/utils/request';
import {Enum} from '@/enums/system';
import {toExcel} from "@/components/WdiControls/WdiExcel";

export type WarningInfoAppProps = {
  reqEntity?: any;
  wdiPanelList?: API.WdiPanelList;
  onClose?: () => void;
  onSave?: () => any;
} & WdiModalProps;

export type WarningInfoApprovalProps = {
  formId?: number;
};

export type PanelForWarningProps = {
  status?: 0 | 1 | 2;
  onClose?: () => void;
  onSave?: () => any;
} & WdiModalProps;

const warning_type: Record<number, any> = {
  0: Enum.IconType.SUCCESS,
  1: Enum.IconType.WARNING,
  2: Enum.IconType.ERROR,
};

const WarningTitle: React.FC<{ title: string, status: number }> = (props: any) => {
  return (
    <span>
      <span style={{paddingRight: 5}}><WdiIcon type={warning_type[props.status]}/></span>
      {props.title}
    </span>
  )
};

export const WdiGridApp: React.FC<{
  wdiGrid: API.WdiGrid,
  columns: any[],
  dataSource: any[],
  isExport: any,
  exportColumns: any[]
}> = (props) => {
  const {wdiGrid, columns, isExport, dataSource, exportColumns} = props;
  const exportDetails = () => {
    const formSpSheet = {
      data: dataSource,
      names: exportColumns,
      sheetName: '',
      cells: {
        rateActualMtd: (fieldObject: any, row: any, value: any) => {
          if (wdiGrid.key == "SsBudget" || wdiGrid.key == "SsBudgetImportant" || wdiGrid.key == "OverRateStandard") {
            let hasStandard: boolean = true;
            let isError: boolean = false;
            if (row.rateBaselineMtd == null) {
              hasStandard = false;
            }
            if (row.revenueMtd == null || row.revenueMtd == 0) {
              isError = true;
            }
            if (row.budgetControlType == Enum.BudGetControlType.CONTROL) {
              return (isError ? 'error' : wdiCore.Number.RateToString(row.rateActualMtd)) + "(" + (hasStandard ? wdiCore.Number.RateToString(row.rateBaselineMtd) : "未设置") + ")";
            } else if (row.budgetControlType == Enum.BudGetControlType.SPECIAL) {
              return isError ? 'error' : wdiCore.Number.RateToString(row.rateActualMtd);
            }
          }
          return "";
        },
        rateActualYtd: (fieldObject: any, row: any, value: any) => {
          if (wdiGrid.key == "SsBudget" || wdiGrid.key == "OverRateStandard") {
            let hasStandard: boolean = true;
            let isError: boolean = false;
            if (row.rateBaselineYtd == null) {
              hasStandard = false;
            }
            if (row.revenueYtd == null || row.revenueYtd == 0) {
              isError = true;
            }
            if (row.budgetControlType == Enum.BudGetControlType.CONTROL) {
              return (isError ? 'error' : wdiCore.Number.RateToString(row.rateActualYtd)) + "(" + (hasStandard ? wdiCore.Number.RateToString(row.rateBaselineYtd) : "未设置") + ")";
            } else if (row.budgetControlType == Enum.BudGetControlType.SPECIAL) {
              return isError ? 'error' : wdiCore.Number.RateToString(row.rateActualYtd);
            }
          }
          return "";
        },
        rateActualYtdLyTotal: (fieldObject: any, row: any, value: any) => {
          if (wdiGrid.key == "SsBudget" || wdiGrid.key == "OverRateStandard") {
            let isError = false;
            if (row.revenueYtd == null || row.revenueYtd == 0) {
              isError = true;
            }
            return isError ? "error" : wdiCore.Number.RateToString(row.rateActualYtdLyTotal);
          }
          return "";
        },
        rateActual: (fieldObject: any, row: any, value: any) => {
          if (wdiGrid.key == "SsExpenseRate") {
            let hasStandard: boolean = true;
            if (row.revenueActual == null || row.revenueActual == 0) {
              hasStandard = false;
            }
            return hasStandard ? wdiCore.Number.RateToString(value) : 'error';
          }
          return "";
        },
        rateForecast: (fieldObject: any, row: any, value: any) => {
          if (wdiGrid.key == "SsExpenseRate") {
            let hasStandard: boolean = true;
            if (row.revenueForecast == null || row.revenueForecast == 0) {
              hasStandard = false;
            }
            return hasStandard ? wdiCore.Number.RateToString(value) : 'error';
          }
          return "";
        },
        rateStandard2: (fieldObject: any, row: any, value: any) => {
          if (wdiGrid.key == "OverPromotionRate") {
            let hasStandard: boolean = true;
            if (row.rateStandard2 == null || row.rateStandard2 == 0) {
              hasStandard = false;
            }
            return hasStandard ? wdiCore.Number.RateToString(value) : 'error';
          }
          return "";
        },
        rateStandardTotal: (fieldObject: any, row: any, value: any) => {
          if (wdiGrid.key == "OverPromotionRate") {
            let hasStandard: boolean = true;
            if (row.rateStandardTotal == null || row.rateStandardTotal == 0) {
              hasStandard = false;
            }
            return hasStandard ? wdiCore.Number.RateToString(value) : 'error';
          }
          return "";
        },

        rateActual2: (fieldObject: any, row: any, value: any) => {
          if (wdiGrid.key == "OverPromotionRate") {
            let hasStandard: boolean = true;
            if (row.rateActual2 == null || row.rateActual2 == 0) {
              hasStandard = false;
            }
            return hasStandard ? wdiCore.Number.RateToString(value) : 'error';
          }
          return "";
        },
        rateActualTotal: (fieldObject: any, row: any, value: any) => {
          if (wdiGrid.key == "OverPromotionRate") {
            let hasStandard: boolean = true;
            if (row.rateActualTotal == null || row.rateActualTotal == 0) {
              hasStandard = false;
            }
            return hasStandard ? wdiCore.Number.RateToString(value) : 'error';
          }
          return "";
        },
      }
    };
    toExcel({
      name: wdiGrid.displayName + wdiCore.Date.ToString(new Date()) + ".xlsx",
      sheetProps: [formSpSheet]
    });
  }
  return (
    <WdiPanel
      key={wdiGrid.key}
      title={<WarningTitle title={wdiGrid.displayName} status={wdiGrid.status}/>}
      collapsed={true}
      extra={
        <>
          <Button hidden={!isExport} type='primary'
                  style={{float: 'right', marginRight: 10}} onClick={(e) => {
            e.stopPropagation();
            exportDetails();
          }}>
            导出
          </Button>
        </>
      }
    >
      <WdiTable columns={columns} dataSource={props.dataSource} pagination={{defaultPageSize: 5}}/>
    </WdiPanel>
  );
};

export const WdiObjApp: React.FC<{ wdiObj: API.WdiObj, message: any }> = (props) => {
  const {wdiObj, message} = props;
  return (
    <WdiPanel
      key={wdiObj.key}
      title={<WarningTitle title={wdiObj.displayName} status={wdiObj.status}/>}
      collapsed={true}
    >
      <div>
        <Typography.Paragraph>{message}</Typography.Paragraph>
      </div>
    </WdiPanel>
  );
};

const PanelForWarningApp: React.FC<PanelForWarningProps> = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <WdiModalForm
      title={props.title}
      visible={props.visible}
      width={1350}
      modalProps={{
        onCancel: () => {
          if (props.onClose) {
            setLoading(false);
            props.onClose();
          }
        },
        destroyOnClose: true,
        maskClosable: false
      }}
      submitter={{
        render: () => {
          return [
            <Button key="back" onClick={() => {
              if (props.onClose) {
                setLoading(false);
                props.onClose();
              }
            }}>
              返回修改
            </Button>,
            <Button hidden={props.status === 2} type='primary' key="submit" loading={loading}
                    onClick={async () => {
                      setLoading(true);
                      if (props.onSave) {
                        const response = await props.onSave();
                        if (response && response.code !== ResponseStatus.SUCCESS) {
                          setLoading(false);
                        }
                      }
                    }}
            >
              确认提交
            </Button>,
          ];
        },
      }}
    >
      {props.children}
    </WdiModalForm>
  );
};

export default PanelForWarningApp;
