import { formatter, request } from '@/utils/request';

export namespace PageGroup {

  export const Path = {
    Get: '/basicapi/pageGroup/get',
    Search: '/basicapi/pageGroup/search',
  }

  export async function search(body: API.PageGroupFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.PageGroup[]>>(Path.Search, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.PageGroup>>(Path.Get, formatter(options));
  }

}