import {formatter, request} from "@/utils/request";


export namespace FormSs {
  export const Path = {
    getFormSs: '/activityapi/formSs/getFormSs',
    getFormSsDetailsForApply: '/activityapi/formSs/getFormSsDetailsForApply',
    getValidFormSs: '/activityapi/formSs/getValidFormSs',
    getFormSsDetailsForApproval: '/activityapi/formSs/getFormSsDetailsForApproval',
    getFormSsDetailsGroup: '/activityapi/formSs/getFormSsDetailsGroup',
    warningInfos: '/activityapi/formSs/warningInfos'
  };

  export function warningInfos(formId: number) {
    const options = {
      method: 'GET',
      body: {
        formId: formId,
      },
    };
    return request<API.ResponseResult<API.WdiPanelList>>(Path.warningInfos, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.FormSs>>(Path.getFormSs, formatter(options));
  };

  export async function getByFormSaId(id: number) {
    const options = {
      method: 'GET',
      body: {
        formSaId: id
      },
    };
    return request<API.ResponseResult<API.FormSs>>(Path.getValidFormSs, formatter(options));
  };

  export async function getFormSsDetailsForApply(body: API.FormSsParamater) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any[]>>(Path.getFormSsDetailsForApply, formatter(options));
  };

  export async function getFormSsDetailsForApproval(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<any[]>>(Path.getFormSsDetailsForApproval, formatter(options));
  };

  export async function getFormSsDetailsGroup(body: API.FormSsParamater) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any[]>>(Path.getFormSsDetailsGroup, formatter(options));
  };

  export namespace Buttons {
    export const Path = {
      apply: '/activityapi/ssButtons/apply',
      del: '/activityapi/ssButtons/delete',
      getDynamicInformation: '/activityapi/ssButtons/dynamicInfos',
      ValidateDetail: '/activityapi/ssButtons/validateDetail',
      ModifyAmount: '/activityapi/ssButtons/modifyAmount',
      getSfaResultUrl: '/activityapi/ssButtons/getSfaResultUrl',
      formSsDetailsImportValidate: '/activityapi/ssButtons/formSsDetailsImportValidate',
      formSsDetailsImport: '/activityapi/ssButtons/formSsDetailsImport',
      batchValidate: '/activityapi/ssButtons/batchValidate',
      batchApply: '/activityapi/ssButtons/batchApply',
      WARNING_INFOS: '/activityapi/ssButtons/warningInfos',
      COUNT_OF_WARNING: '/activityapi/ssButtons/countOfWarning',
      BUDGET_INFOS: '/activityapi/ssButtons/budgetInfos',
    };

    export async function formSsDetailsImportValidate(body: any) {
      const options = {
        method: 'POST',
        body: {
          ...body
        },
      };
      return request<API.ResponseResult<API.ImportResult>>(Path.formSsDetailsImportValidate, formatter(options));
    }

    export async function formSsDetailsImport(body: any) {
      const options = {
        method: 'POST',
        body: {
          ...body
        },
      };
      return request<API.ResponseResult<any>>(Path.formSsDetailsImport, formatter(options));
    }

    export async function validateDetail(body: { pageTypeId: number, formSsDetail: any, }) {
      const options = {
        method: 'POST',
        body: {
          ...body
        }
      };

      return request<API.ResponseResult<API.FormSsDetailParam>>(Path.ValidateDetail, formatter(options));
    };

    export async function modifyAmount(body: { pageTypeId: number, formSsDetail: any, }) {
      const options = {
        method: 'POST',
        body: {
          ...body
        }
      };

      return request<API.ResponseResult<API.FormSsDetailParam>>(Path.ModifyAmount, formatter(options));
    };

    export async function apply(body: {
      forceSubmit: boolean,
      form: API.Form,
      formSs: API.FormSs,
      FormSSDetails: API.FormSsDetail[]
    }) {
      const options = {
        method: 'POST',
        body: body,
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.apply, formatter(options));
    };

    export async function batchApply(body: {
      forceSubmit: boolean,
      batchFormSsParam: any;
      formSsDetailParamList: API.FormSsDetail[]
    }) {
      const options = {
        method: 'POST',
        body: body,
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.batchApply, formatter(options));
    };

    export async function batchValidate(body: {
      forceSubmit: boolean,
      batchFormSsParam: any;
      formSsDetailParamList: API.FormSsDetail[]
    }) {
      const options = {
        method: 'POST',
        body: body,
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.batchValidate, formatter(options));
    };

    export async function del(formId: number) {
      const options = {
        method: 'POST',
        body: {
          formId: formId
        },
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.del, formatter(options));
    };

    export async function getDynamicInformation(formId: number) {
      const options = {
        method: 'Get',
        body: {
          formId: formId
        }
      };
      return request<API.DynamicInformation[]>(Path.getDynamicInformation, formatter(options));
    };

    export async function getSfaResultUrl(formId: number, formSsDetailId: number) {
      const options = {
        method: 'GET',
        body: {formId, formSsDetailId}
      };
      return request<API.ResponseResult<string>>(Path.getSfaResultUrl, formatter(options));
    };

    export async function warningInfos(formId: number) {
      const options = {
        method: 'GET',
        body: {
          formId: formId,
        },
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.WARNING_INFOS, formatter(options));
    }

    export async function countOfWarning(formId: number) {
      const options = {
        method: 'GET',
        body: {
          formId: formId,
        },
      };
      return request<API.ResponseResult<number>>(Path.COUNT_OF_WARNING, formatter(options));
    }

    export async function budgetInfos(formId: number) {
      const options = {
        method: 'GET',
        body: {formId}
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.BUDGET_INFOS, formatter(options));
    };
  }
}
