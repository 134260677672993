import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, message, Row, } from 'antd';
import ProForm, { ProFormInstance } from '@ant-design/pro-form';
import { WdiCardTitle, wdiCore, WdiInput, WdiTextArea } from '@/components/WdiControls';
import { BOHQ } from '@/services/mdm/bohq';
import { FileUploadUtility, WdiFileList } from '@/components/WdiControls/WdiFileUpload';
import DataStore from '@/components/GridWidget/DataStore';
import { FormPageProps } from '@/components/WdiBus/FormPage';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { DataOptions } from '@/services/basic/metadata';
import { EC } from '@/services/mdm/ec';
import { Product } from '@/services/mdm/product';
import { ResponseStatus } from '@/utils/request';
import ApproverUserApp, { ApproverUserIds } from '../../components/ApproverUser';
import { ViewEntityProps, initDisplyPageContext } from '../../components/data';
import { FormBPDetailsForApprovalPanel } from '../../GeneralProjectApply/components/details';
import { BudgetProjectDetail } from '@/services/sales/bp/budgetProject';
import FormBPHistoryPanel from '../../components/history';
import { FormBudgetProject } from '@/services/sales/bp/formBudgetProject';
import { WdiButtonContainer } from '@/components/WdiBus/Buttons';
import { BPMoreWapper } from './Button';
import { Enum } from '@/enums/system';
import { NSToken } from '@/services/identity';
import { Channel } from '@/services/mdm/channel';
import { ShopLevel } from '@/services/mdm/shoplevel';
import { SoldTo } from '@/services/mdm/soldto';

const BudgetProjectDisplayApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [budgetProjectDetail, setBudgetProjectDetail] = useState<any[]>([]);
  const [formBPHistory, setFormBPHistory] = useState<any[]>([]);
  const [entity, setEntity] = useState<ViewEntityProps>();

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initDisplyPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;
        const { budgetProject } = viewEntity;
        if (budgetProject) {
          formRef.current?.setFieldsValue({
            projectName: budgetProject?.projectName,
            projectCode: budgetProject?.projectCode,
            projectDate: wdiCore.Date.Join(budgetProject?.projectStartDate, budgetProject?.projectEndDate),
            totalBudget: wdiCore.Number.AmountToString(budgetProject?.totalBudget),
            fileInfo: FileUploadUtility.parse(budgetProject?.fileName, budgetProject?.filePath),
            projectRemark: budgetProject?.projectRemark,
            applyDate: wdiCore.Date.Join(budgetProject?.applyStartDate, budgetProject?.applyEndDate),
          });
        }
        setEntity(viewEntity);

        if (budgetProject?.projectApproverIds) {
          ApproverUserIds.fetchUserForDataSource(budgetProject?.projectApproverIds, (data) => {
            formRef.current?.setFieldsValue({
              projectApproverIds: data
            });
          });
        }
        if (budgetProject?.bohqId) {
          BOHQ.get(budgetProject.bohqId).then((response) => {
            formRef.current?.setFieldsValue({
              bohqName: response.data.bohqName
            });
          });
        }

        if (budgetProject?.limitedChannelIds) {
          Channel.search({ channelIds: budgetProject.limitedChannelIds }).then(response => {
            if (response.data && response.data.length > 0) {
              formRef.current?.setFieldsValue({
                limitedChannelIds: response.data.map(channel => channel.codeOrName).join()
              });
            }
          });
        }
        if (budgetProject?.limitedEcIds) {
          EC.search({ ecIds: budgetProject.limitedEcIds }).then((response) => {
            formRef.current?.setFieldsValue({
              limitedEcIds: response.data.map(item => item.ecName).join()
            });
          });
        }
        if (budgetProject?.limitedProductIds) {
          Product.searchAll({ productIds: budgetProject.limitedProductIds }).then((response) => {
            formRef.current?.setFieldsValue({
              limitedProductIds: response.data.map(item => item.productName).join()
            });
          });
        }
        if (budgetProject?.limitedShopLevelIds) {
          ShopLevel.search({ shopLevelIds: budgetProject.limitedShopLevelIds }).then(response => {
            if (response.data && response.data.length > 0) {
              formRef.current?.setFieldsValue({
                limitedShopLevelIds: response.data.map(shopLevel => shopLevel.codeOrName).join()
              });
            }
          });
        }
        if (budgetProject?.limitedSoldToCodes) {
          SoldTo.search({ soldToCodes: budgetProject?.limitedSoldToCodes }).then(response => {
            if (response.data && response.data.length > 0) {
              formRef.current?.setFieldsValue({
                limitedSoldToCodes: response.data.map(soldTo => soldTo.codeOrName).join()
              });
            }
          });
        }

        if (budgetProject) {
          BudgetProjectDetail.query(budgetProject?.budgetProjectId, NSToken.getBu()).then((response) => {
            setBudgetProjectDetail(DataStore.init(response.data));
          });
          FormBudgetProject.getHistory(budgetProject?.budgetProjectId, budgetProject?.buId).then((response) => {
            setFormBPHistory(DataStore.init(response.data));
          });
        }
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = `专项项目详情`;
      DataOptions.search({ typeGroup: 20, value: entity.budgetProject?.projectStatusId }).then(response => {
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (response.data && response.data.length > 0) {
          title += `【${response.data[0].name}】`;
          if (props.setPageTitle) props.setPageTitle(<div>
            <span>{title}</span>
            <WdiButtonContainer>
              <BPMoreWapper
                onClose={props.onClose}
                callback={props.callback}
                budgetProjectId={entity?.budgetProject?.budgetProjectId}
              />
            </WdiButtonContainer>
          </div>);
        }
      });
    }
  }, [props.setPageTitle, entity]);

  return (
    <>

      <ProForm
        formRef={formRef}
        submitter={{
          render: () => { return <></> },
        }}
      >
        <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.BASE_INFO} title='项目基本信息' />} collapsed={true} >
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={4}>
              <Form.Item name="projectName" label="项目名称" >
                <WdiInput readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="projectCode" label="项目编号" >
                <WdiInput readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="projectDate" label="项目日期" >
                <WdiInput readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="totalBudget" label="项目总额">
                <WdiInput readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="bohqName" label="费用归属">
                <WdiInput readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} style={{ display: 'none' }}>
              <Form.Item name="projectApproverIds" label="附加审批人">
                <ApproverUserApp readOnly={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16}>
              <Form.Item name="remark" label="方案简述">
                <WdiTextArea rows={4} readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="fileInfo"
                label="附件"
                valuePropName='fileList'
                getValueFromEvent={(e: any) => { return e.fileList || []; }}
              >
                <WdiFileList fileList={FileUploadUtility.parse(entity?.formBudgetProject?.fileName, entity?.formBudgetProject?.filePath)} />
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.LIMITED_INFO} title='维度信息' />} collapsed={true} >
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={4}>
              <Form.Item name="applyDate" label="活动申请时间维度" >
                <WdiInput readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="limitedChannelIds" label="渠道类型维度">
                <WdiInput readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="limitedEcIds" label="费用项维度">
                <WdiInput readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="limitedProductIds" label="产品别维度">
                <WdiInput readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="limitedShopLevelIds" label="门店等级维度">
                <WdiInput readOnly />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="limitedSoldToCodes" label="售达方维度">
                <WdiInput readOnly />
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <FormBPDetailsForApprovalPanel dataSource={budgetProjectDetail} />
        <FormBPHistoryPanel dataSource={formBPHistory} />
      </ProForm>
    </>
  );
};
export default BudgetProjectDisplayApp;
