import { formatter, request } from "@/utils/request";

export namespace ShopLevel {
  export const Path = {
    Search: '/mdmapi/shopLevel/search',
    AjaxSearch: '/mdmapi/shopLevel/ajaxSearch',
    Get: '/mdmapi/shopLevel/get', 
    Post: '/mdmapi/shopLevel/post',
    Delete: '/mdmapi/shopLevel/delete',
  };


  export async function search(body: API.ShopLevelFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ShopLevel[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.ShopLevelFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ShopLevel[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.ShopLevel>>(Path.Get, formatter(options));
  } 
  export async function post(body: API.ShopLevel) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }
}