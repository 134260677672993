import { request, formatter } from '@/utils/request';

export namespace ShipTo {
  export const Path = {
    Search: '/mdmapi/shipTo/search',
    AjaxSearch: '/mdmapi/shipTo/ajaxSearch',
    Query: '/mdmapi/shipTo/query',
    Export: '/mdmapi/shipTo/export',
    Get: '/mdmapi/shipTo/get',
    SearchExcel: '/mdmapi/shipTo/searchExcel',
    QueryWithBu: '/mdmapi/shipTo/queryWithBu',
    QuerySheetWithBu: '/mdmapi/shipTo/querySheetWithBu',


  };

  export async function queryWithBu(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.QueryResult<any>>>(Path.QueryWithBu, formatter(options));
  }
  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.QueryResult<any>>>(Path.Query, formatter(options));
  }
  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.QueryResult<any>>>(Path.Export, formatter(options));
  }

  export async function search(body: API.ShipToFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ShipTo[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.ShipToFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ShipTo[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function get(id: string) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.ShipTo>>(Path.Get, formatter(options));
  }

  export async function searchExcel(body: API.ShipToFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ShipToExcel[]>>(Path.SearchExcel, formatter(options));
  }

  export async function querySheetWithBu(body: API.ShipToFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ShipToExcel[]>>(Path.QuerySheetWithBu, formatter(options));
  }





};
