import {FormPageProps} from "@/components/WdiBus/FormPage";
import React, {useEffect, useRef, useState} from "react";
import ProForm, {ProFormInstance} from "@ant-design/pro-form";
import {Button, Col, Form, Input, message, Row} from "antd";
import {WdiPanel} from "@/components/WdiControls/WdiPanel";
import {WdiCardTitle, wdiCore, WdiModalAppResult} from "@/components/WdiControls";
import {Enum} from "@/enums/system";
import {SoldToMaster} from "@/services/mdm/soldtomaster";
import {WdiSelect, WdiSelectFormat} from "@/components/WdiControls/WdiSelect";
import moment from "moment";
import {SoldTo} from "@/services/mdm/soldto";
import {EC} from "@/services/mdm/ec";
import {Product} from "@/services/mdm/product";
import PoolPrepayApplyPanel, {
  FormSpDetailApplyPanel
} from "@/pages/financial/PoolPrepayReverseApply/components/details";
import {calcPositiveVoucherSummary, DataSummary} from "@/pages/financial/PoolPrepayReverseApply/components/data";
import TextArea from "antd/lib/input/TextArea";
import {ResponseStatus} from "@/utils/request";
import {PoolPrepayReverse} from "@/services/sales/financial/poolprepayreverse";
import {DataOptions} from "@/services/basic/metadata";
import {VatType} from "@/services/mdm/vattype";

const detailType = {
  POOLPREPAY: 1,
  FORMSPDETAIL: 2,
}
const getPageResult = (values: any, poolPrepayDetails: any[], formSpDetails: any[], soldToMasterCode: any) => {
  const result: WdiModalAppResult = {code: ResponseStatus.UNKNOWN};
  values.amountPrepay = wdiCore.Number.ToNumber(values.amountPrepay, 2);
  values.amountReimbursement = wdiCore.Number.ToNumber(values.amountReimbursement, 2);
  values.amountPayment = wdiCore.Number.ToNumber(values.amountPayment, 2);
  const {...entity} = values;
  entity.soldToMasterCode = soldToMasterCode;
  const req: { poolPrepayReverse: any, poolPrepays: any[], formSpDetails: any[] } = {
    poolPrepayReverse: {},
    poolPrepays: poolPrepayDetails,
    formSpDetails: formSpDetails,
  };
  req.poolPrepayReverse = {...entity};
  if (req.poolPrepays.length == 0 && req.formSpDetails.length == 0) {
    result.message = "预付信息和核销信息不能全部为空";
    return result;
  }
  if (req.poolPrepays.length != 0) {
    req.poolPrepays.forEach((item: { detailType: number; relativeId: any; poolPrepayId: any; }) => {
      item.detailType = detailType.POOLPREPAY;
      item.relativeId = Number(item.poolPrepayId);
    })
  }
  if (req.formSpDetails.length != 0) {
    req.formSpDetails.forEach((item: { detailType: number; relativeId: any; formSpDetailId: any; }) => {
      item.detailType = detailType.FORMSPDETAIL;
      item.relativeId = Number(item.formSpDetailId);
    })
  }


  result.data = req;
  result.code = ResponseStatus.SUCCESS;
  return result;
};

const PoolPrepayReverseApply: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const data = props.data;
  const [poolPrepayDataSource, setPoolPrepayDataSource] = useState<any[]>([]);
  const [formSpDetailDataSource, setFormSpDetailDataSource] = useState<any[]>([]);
  const [dataSummary, setDataSummary] = useState<DataSummary>({
    amountPrepay: 0,
    amountReimbursement: 0,
    amountPayment: 0,
  });
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const onPoolPrepayDetailsChange = (data: any) => {
    setDataSummary(calcPositiveVoucherSummary(formSpDetailDataSource, data));
    setPoolPrepayDataSource(data);
  };
  const onFormSpDetailsChange = (data: any) => {
    setDataSummary(calcPositiveVoucherSummary(data, poolPrepayDataSource));
    setFormSpDetailDataSource(data)
  };

  useEffect(() => {
    formRef.current?.setFieldsValue({
      amountPrepay: wdiCore.Number.AmountToString(dataSummary.amountPrepay, 2),
      amountReimbursement: wdiCore.Number.AmountToString(dataSummary.amountReimbursement, 2),
      amountPayment: wdiCore.Number.AmountToString(dataSummary.amountPayment, 2),
    });
  }, [dataSummary]);


  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        SoldToMaster.get(String(params?.soldToMasterCode)).then(res => {
          formRef.current?.setFieldsValue({
            soldToMasterCode: res?.data?.codeOrName,
            prepayType: params.prepayType,
            year: moment(params.year).format("YYYY")
          })
        })
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle) {
      props.setPageTitle('创建冲销信息');
    }
  }, [props.setPageTitle]);
  return (
    <>
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (
              <>
                <div style={{marginTop: 20, float: 'right', paddingBottom: 20}}>
                  <div className='ant-space ant-space-horizontal ant-space-align-center'
                       style={{gap: 16}}>
                    <Button type='primary' key='submit' onClick={async () => {
                      const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                      if (values && values.errorFields && values.errorFields.length) {
                        return;
                      }
                      const req = getPageResult(values, poolPrepayDataSource, formSpDetailDataSource, data?.soldToMasterCode);
                      if (req.code !== ResponseStatus.SUCCESS) {
                        message.error(req.message);
                        return;
                      }
                      props?.setLoading?.(true);
                      const responseResult = await PoolPrepayReverse.apply(req.data);
                      props?.setLoading?.(false);
                      if (responseResult.code != ResponseStatus.SUCCESS) {
                        message.error(responseResult.message);
                      } else {
                        message.success('创建成功');
                        if (responseResult.code == ResponseStatus.SUCCESS) {
                          props?.onClose?.(responseResult);
                          props.callback?.();
                        }
                      }
                    }}>
                      创建
                    </Button>
                  </div>
                </div>
              </>
            );
          },
        }}
      >
        <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.FORM_SP} title='汇总信息'/>}>
          <Row gutter={[16, 24]}>
            <Col className='gutter-row' span={4}>
              <Form.Item name='soldToMasterCode' label='售达方主户'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='prepayType' label='预付类型'>
                <WdiSelect disabled={true} {...{
                  placeholder: '请选择凭证类型',
                  showSearch: true,
                  dataLoading: async () => {
                    return [
                      {label: '合同预付', value: 1},
                      {label: '活动预付', value: 2},
                    ];
                  },
                }} />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='year' label='冲销年份'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='soldToCodePaid' label='售达方' rules={[{required: true}]}>
                <WdiSelect {...{
                  placeholder: '请选择售达方',
                  showSearch: true,
                  remote: true,
                  dataLoading: async (params: any) => {
                    return SoldTo.ajaxSearch(Object.assign({soldToMasterCode: String(data?.soldToMasterCode)}, params)).then(res => {
                      return res.data.map((item) => {
                        return {value: item.soldToCode, label: item.codeOrName}
                      })
                    })
                  },
                }} />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='ecId' label='费用项' rules={[{required: true}]}>
                <WdiSelect {...{
                  placeholder: '请选择费用项',
                  showSearch: true,
                  remote: true,
                  dataLoading: async (params: any) => {
                    return SoldTo.ajaxSearch(Object.assign({soldToMasterCode: String(data?.soldToMasterCode)})).then(result => {
                      return EC.search(Object.assign({
                        isActive: true,
                        ecLevel: 3,
                        bohqId: result?.data[0]?.defaultBohqId
                      }, params)).then(res => {
                        return res.data.map((item) => {
                          return {value: item.ecId, label: item.ecName}
                        })
                      })
                    })
                  },
                }} />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='productLineId' label='产品线' rules={[{required: true}]}>
                <WdiSelect {...{
                  placeholder: '请选择产品线',
                  showSearch: true,
                  remote: true,
                  dataLoading: async (params: any) => {
                    return SoldTo.ajaxSearch(Object.assign({soldToMasterCode: String(data?.soldToMasterCode)})).then(result => {
                      return Product.ajaxSearchAll(Object.assign({
                        productLevel: 2,
                        bohqId: result?.data[0]?.defaultBohqId
                      }, params)).then(res => {
                        return res.data.map((item) => {
                          return {value: item.productId, label: item.codeOrName}
                        })
                      })
                    })
                  },
                }} />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={[16, 24]}>
            <Col className='gutter-row' span={4}>
              <Form.Item name='paymentMethodId' label='支付方式*' rules={[{required: true}]}>
                <WdiSelect {...{
                  placeholder: '请选择支付方式',
                  showSearch: true,
                  dataLoading: async (params: any) => {
                    return DataOptions.search(Object.assign({typeGroup: 5, inValues: '2,3,5,6'}, params)).then(res => {
                      return res.data.map((item) => {
                        return {value: item.value, label: item.name}
                      })
                    })
                  },
                  onChange: (params: any) => {
                    if (WdiSelectFormat.getvalue(params) !== Enum.PaymentMethod.BILL_DEDUCT) {
                      setReadOnly(true);
                    } else {
                      setReadOnly(false);
                      formRef.current?.setFieldsValue({
                        vatTypeId: null,
                        invoiceType: null,
                      })
                    }
                  }
                }} />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='invoiceType' label='发票类型' rules={[{required: readOnly}]}>
                <WdiSelect disabled={!readOnly} {...{
                  placeholder: '请选择发票类型',
                  showSearch: true,
                  dataLoading: async (params: any) => {
                    return DataOptions.search(Object.assign({typeGroup: 106}, params)).then(res => {
                      return res.data.map((item) => {
                        return {value: item.value, label: item.name}
                      })
                    })
                  },
                }} />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='vatTypeId' label='税率类型' rules={[{required: readOnly}]}>
                <WdiSelect disabled={!readOnly} {...{
                  placeholder: '请选择税率类型',
                  showSearch: true,
                  dataLoading: async (params: any) => {
                    return VatType.search(Object.assign({isActive: true}, params)).then(res => {
                      return res.data.map((item) => {
                        return {value: item.vatTypeId, label: item.vatTypeName}
                      })
                    })
                  },
                }} />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='amountPrepay' label='预付金额合计'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='amountReimbursement' label='可冲销付款金额合计'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='amountPayment' label='本次付款金额'>
                <Input readOnly/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16}>
              <Form.Item name="remark" label="备注">
                <TextArea rows={4}/>
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <PoolPrepayApplyPanel onChange={onPoolPrepayDetailsChange}
                              dataSource={poolPrepayDataSource}
                              data={{
                                soldToMasterCode: data?.soldToMasterCode,
                                year: data?.year,
                                prepayType: data?.prepayType
                              }}
        />
        <FormSpDetailApplyPanel onChange={onFormSpDetailsChange}
                                dataSource={formSpDetailDataSource}
                                data={{
                                  soldToMasterCode: data?.soldToMasterCode,
                                  year: data?.year,
                                  prepayType: data?.prepayType
                                }}

        />
      </ProForm>
    </>
  )
}
export default PoolPrepayReverseApply;
