import { formatter, request } from "@/utils/request";

export namespace ProductPolicy {
  export const Path = {
    Get: '/activityapi/productPolicy/get',
    GetVo: '/activityapi/productPolicy/getVo',
    GetHistoryVo: '/activityapi/productPolicy/getHistoryVo',
    GetEx: '/activityapi/productPolicy/getEx',
    Search: '/activityapi/productPolicy/search',
    searchForActivity: '/activityapi/productPolicy/searchForActivity',
    searchForPicker: '/reportapi/productPolicy/searchForPicker',
    Query: '/activityapi/productPolicy/query',
    Post: '/activityapi/productPolicy/post',
    GetProductPolicyTerms: '/activityapi/productPolicy/getProductPolicyTerms',
    QueryProductPolicySoldTo: '/activityapi/productPolicy/queryProductPolicySoldTo',
    QueryProductPolicySoldToHistory: '/activityapi/productPolicy/queryProductPolicySoldToHistory',
    SearchProductPolicyRate: '/activityapi/productPolicy/searchProductPolicyRate',
    SearchProductPolicyProduct: '/activityapi/productPolicy/searchProductPolicyProduct',
    SearchProductPolicyRateHistory: '/activityapi/productPolicy/searchProductPolicyRateHistory',
    GetProductPolicyProducts: '/activityapi/productPolicy/getProductPolicyProducts',
    SearchProductPolicyHistory: '/activityapi/productPolicy/searchHistory',
    ExportEx: '/activityapi/productPolicy/export',
    searchProductPolicyParameter: '/activityapi/productPolicy/searchProductPolicyParameter',
    searchProductPolicyResult: '/activityapi/productPolicy/searchProductPolicyResult',
  };

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.ProductPolicy>>(Path.Get, formatter(options));
  }

  export async function getVo(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.ProductPolicyVo>>(Path.GetVo, formatter(options));
  }

  export async function getHistoryVo(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.ProductPolicyHistoryVo>>(Path.GetHistoryVo, formatter(options));
  }


  export async function search(body: API.ProductPolicySearchFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ProductPolicy[]>>(Path.Search, formatter(options));
  }

  export async function searchForActivity(body: API.ProductPolicyFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ProductPolicyPicker[]>>(Path.searchForActivity, formatter(options));
  }

  export async function searchForPicker(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ProductPolicy[]>>(Path.searchForPicker, formatter(options));
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.ExportEx, formatter(options));
  }


  export async function post(body: API.ProductPolicyPostParam) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }


  export async function QueryProductPolicySoldTo(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.ProductPolicySoldToQueryPo[]>>(Path.QueryProductPolicySoldTo, formatter(options));
  }

  export async function QueryProductPolicySoldToHistory(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.ProductPolicySoldToHistoryQueryPo[]>>(Path.QueryProductPolicySoldToHistory, formatter(options));
  }

  export async function SearchProductPolicyRate(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.ProductPolicyRateVo[]>>(Path.SearchProductPolicyRate, formatter(options));
  }

  export async function SearchProductPolicyProduct(body : API.ProductPolicyProductSearchParam) {
    const options = {
      method: 'POST',
      body: body,
    };
    return request<API.ResponseResult<API.ProductPolicyProductVo[]>>(Path.SearchProductPolicyProduct, formatter(options));
  }

  export async function SearchProductPolicyRateHistory(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.ProductPolicyRateVo[]>>(Path.SearchProductPolicyRateHistory, formatter(options));
  }

  export async function getProductPolicyProducts(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.ProductPolicyProductVo[]>>(Path.GetProductPolicyProducts, formatter(options));
  }


  export async function searchProductPolicyHistory(body: API.ProductPolicyHistorySearchFilter) {
    const options = {
      method: 'Post',
      body: body,
    };
    return request<API.ResponseResult<API.ProductPolicyHistory[]>>(Path.SearchProductPolicyHistory, formatter(options));
  }

  export async function searchProductPolicyParameter(body: API.PolicyParameterSearchParam) {
    const options = {
      method: 'POST',
      body: body
    };
    return request<API.ResponseResult<API.ProductPolicyParameter[]>>(Path.searchProductPolicyParameter, formatter(options));
  }

  export async function searchProductPolicyResult(body: API.ProductPolicyResultSearchParam) {
    const options = {
      method: 'POST',
      body: body
    };
    return request<API.ResponseResult<API.ProductPolicyResultQueryPo[]>>(Path.searchProductPolicyResult, formatter(options));
  }



  export namespace Buttons{

    export const Path={
      validateProductDetail: '/activityapi/policyButtons/validateProductDetail',
      validateRebateStandard: '/activityapi/policyButtons/validateRebateStandard'
    }

    export async function validateProductDetail(body: any) {
      const options = {
        method: 'POST',
        body: body
      };
      return request<any>(Path.validateProductDetail, formatter(options));
    }

    export async function validateRebateStandard(body:{
      productPolicy:API.ProductPolicy,
      productPolicyRates: API.ProductPolicyRate[]
    }){
      const options = {
        method: 'POST',
        body: body
      };
      return request<any>(Path.validateRebateStandard, formatter(options));
    }
  }
}
