import { useEffect, useRef, useState } from 'react';
import { WdiSelect, WdiSelectFormat } from '@/components/WdiControls/WdiSelect';
import { Form, Input, message } from 'antd';
import { ResponseStatus } from '@/utils/request';
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { ProFormInstance } from '@ant-design/pro-form';
import { HorzLayoutOpts, WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { FormSa } from '@/services/sales/sa';
import { WdiModalAppProps, wdiCore } from '@/components/WdiControls';
import { ShipTo } from '@/services/mdm/shipto';
import { getDetailPrevData } from '../../components/data';
import { Persistence } from '@/services/sales/financial/periodconfig';
import { ProductPolicy } from '@/services/sales/productpolicy';

const PolicyPdDiscountDetailApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const { formSaDetail, formSa, rowIndex, rows } = props.data;
  const [buConfig, setConfig] = useState<API.BuConfig>();

  const handleCalcRevenueSellinForecast = () => {
    const fields = formRef.current?.getFieldsValue();
    formRef.current?.setFieldsValue({ revenueSellinForecast: null });
    if (fields.priceSellinAfter && fields.quantitySellinForecast) {
      formRef.current?.setFieldsValue({
        revenueSellinForecast: wdiCore.Number.ToNumber(fields.priceSellinAfter * fields.quantitySellinForecast, 2)
      });
    }
  };
  const handleCalcAmount = () => {
    const fields = formRef.current?.getFieldsValue();
    formRef.current?.setFieldsValue({ amount: null });
    if (fields.priceSellinBefore && fields.priceSellinAfter && fields.quantitySellinForecast) {
      formRef.current?.setFieldsValue({
        amount: wdiCore.Number.ToNumber((fields.priceSellinBefore - fields.priceSellinAfter) * fields.quantitySellinForecast, 2)
      });
    }
  };

  useEffect(() => {
    Persistence.getBuConfig().then(response => { setConfig(response.data); });
    if (formSaDetail) {
      formRef.current?.setFieldsValue({
        policyName: wdiCore.String.Join(formSaDetail.policyCode, formSaDetail.policyName),
        revenueTypeName: formSaDetail.revenueTypeName,
        ecName: formSaDetail.ecName,
        soldToName: wdiCore.String.Join(formSaDetail.soldToCode, formSaDetail.soldToName),
        boName: formSaDetail.boName,
        priceSellinBefore: formSaDetail.priceSellinBefore,
        priceSellinAfter: formSaDetail.priceSellinAfter,
        unitExpense: formSaDetail.priceSellinBefore - formSaDetail.priceSellinAfter,
        pricePosBefore: formSaDetail.pricePosBefore,
        pricePosAfter: formSaDetail.pricePosAfter,
        quantitySellinForecast: formSaDetail.quantitySellinForecast,
        revenueSellinForecast: formSaDetail.revenueSellinForecast,
        amount: formSaDetail.amount,
        remark: formSaDetail.remark,
      });

      handleCalcRevenueSellinForecast();
      handleCalcAmount();

      if (formSaDetail.shipToCodes) {
        ShipTo.search({ shipToCodes: formSaDetail.shipToCodes }).then(response => {
          if (response.data) {
            formRef.current?.setFieldsValue({ shipToCodes: response.data.map(item => ({ value: item.shipToCode, label: item.codeOrName })) });
          }
        });
      }
      ProductPolicy.get(formSaDetail.productPolicyId).then(response => {
        formRef.current?.setFieldsValue({ productBuyNames: response.data.productNamesRebate });
      });
    }
  }, [formSaDetail]);

  return (
    <WdiModalForm
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      modalProps={{ onCancel: () => { props.onClose?.() }, }}
      onFinish={async (values) => {
        const { shipToCodes, ...entity } = values;
        if (!entity.amount || entity.amount <= 0) {
          message.error("申请金额填写不正确!");
          return;
        }
        if (entity.priceSellinAfter >= entity.priceSellinBefore) {
          message.error("促销供货价必须小于原供货价!");
          return;
        }
        entity.shipToCodes = WdiSelectFormat.getvalue(shipToCodes);
        const response = await FormSa.Buttons.validateDetail({
          rowIndex: rowIndex,
          formSa: { ...formSa },
          formSaDetail: Object.assign({}, getDetailPrevData(formSaDetail), entity),
          formSaDetails: rows
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (props.onSave) {
          const data = Object.assign({}, { rowIndex: rowIndex }, response.data);
          response.data = data;
          props.onSave(response);
        }
        return;
      }}
      {...HorzLayoutOpts}
    >
      <Form.Item name="policyName" label="产品政策" rules={[{ required: true, message: '产品政策不能为空!' }]}>
        <Input readOnly />
      </Form.Item>
      <Form.Item name="soldToName" label="售达方">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="shipToCodes" label="送达方">
        <WdiSelect {...{
          placeholder: "请选择送达方",
          remote: true,
          showSearch: true,
          mode: 'multiple',
          dataLoading: async (params) => {
            const responseResult = await ShipTo.search(Object.assign({ soldToCode: formSaDetail.soldToCode, isActive: true }, params));
            return responseResult.data.map((item) => { return { value: item.shipToCode, label: item.codeOrName }; });
          }
        }} />
      </Form.Item>
      <Form.Item name="productBuyNames" label="购买产品" rules={[{ required: true, message: '购买产品不能为空!' }]}>
        <Input readOnly />
      </Form.Item>
      <Form.Item name="priceSellinBefore" label="原到岸价(EA)" rules={[{ required: true, message: '原到岸价不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="priceSellinAfter" label="促销到岸价(EA)" rules={[{ required: true, message: '促销到岸价不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="unitExpense" label="单件费用(EA)" rules={[{ required: true, message: '单件费用不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="pricePosBefore" label="终端原售价" rules={[{ required: buConfig?.isPricePosRequired, message: "终端原售价不能为空" }]}>
        <WdiInputNumber min={0} />
      </Form.Item>
      <Form.Item name="pricePosAfter" label="终端促销售价" rules={[{ required: buConfig?.isPricePosRequired, message: "终端促销售价不能为空" }]}>
        <WdiInputNumber min={0} />
      </Form.Item>
      <Form.Item name="quantitySellinForecast" label="预计销量(EA)" rules={[{ required: true, message: '预计销量不能为空!' }]}>
        <WdiInputNumber min={0} digits={0} onChange={() => {
          handleCalcRevenueSellinForecast();
          handleCalcAmount();
        }} />
      </Form.Item>
      <Form.Item name="revenueSellinForecast" label="预计销售额" rules={[{ required: true, message: '预计销售额不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="amount" label="申请金额" rules={[{ required: true, message: '申请金额不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <Input  maxLength={2000}/>
      </Form.Item>
      <Form.Item name="ecName" label="费用项">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="revenueTypeName" label="核算类型">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="boName" label="四级预算中心">
        <Input readOnly />
      </Form.Item>
    </WdiModalForm>
  );
};

export default PolicyPdDiscountDetailApp;
