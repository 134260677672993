import {Key, useEffect, useState} from 'react';
import {wdiCore, WdiModalAppProps} from '@/components/WdiControls';
import {WdiModal} from '@/components/WdiControls/WdiModalForm';
import {ButtonColumn, FilterColumn, GridFilterForm} from '@/components/GridWidget/GridFilterForm';
import {DatePicker, message} from 'antd';
import {ColumnsType, TablePaginationConfig} from 'antd/lib/table';
import {WdiSelect} from '@/components/WdiControls/WdiSelect';
import {SorterResult} from 'antd/lib/table/interface';
import moment from 'moment';
import {WdiTable} from '@/components/WdiControls/WdiTable';
import {ResponseStatus} from '@/utils/request';
import {ExpenseNonTPM} from '@/services/contract/expensenontpm';
import {Product} from '@/services/mdm/product';
import {SoldTo} from '@/services/mdm/soldto';
import { toExcel } from '@/components/WdiControls/WdiExcel';
import { Enum } from '@/enums/system';


const {RangePicker} = DatePicker;

type TableParams = {
  pagination: TablePaginationConfig;
  sorter?: SorterResult<any> | null | undefined;
  filterOptions: any,
}

const baseColumns = [
  {name: "expenseNonTpmId", displayName: "返利单编号*"},
  {name: "soldToCode", displayName: "售达方编号"},
  {name: "soldToName", displayName: "售达方名称"},  
  {name: "ecName", displayName: "费用项"},
  {name: "productLineCode", displayName: "产品线编号"},
  {name: "productLineName", displayName: "产品线名称"},
  {name: "fperiod", displayName: "销售月份", type: Enum.FieldType.NUMBER, dataType: Enum.FieldDataType.INT}, 
  {name: "amount", displayName: "结案金额"}
 
];


const RebateInfoSelectApp: React.FC<WdiModalAppProps> = (props) => {
  const {} = props;


  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      defaultPageSize: 10,
      total: 0,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
    },
    sorter: null,
    filterOptions: null,
  });
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);


const buttonColumns: ButtonColumn[] = [
    {
      title: '导出',
      key: 'Export',
      doClick: (filterOptions: any) => {
        let columns: any[] = [];
        columns = baseColumns.concat([
          {name: "amountExVat", displayName: "核销金额(不含税)*"},
          {name: "remark", displayName: "核销备注"},
        ]);
        const req: API.QueryForPageReq = {
          filterOptions: JSON.stringify(getFilterOptions(Object.assign({}, tableParams, {filterOptions}))),
          pagenum: 0,
          pagesize: 0
        };
        ExpenseNonTPM.queryReimbursementForSelect(req).then(response => {
          const sheet = {
            data: response?.data?.records,
            names: columns,
            sheetName: "RebateInfo"
          };
          toExcel({
            name: "RebateInfo_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
            sheetProps: [sheet]
          })
        });
      }
    },
  ]

  const filterColumns: FilterColumn[] = [
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            allowClear: true,
            placeholder: "请选择售达方",
            showSearch: true,
            remote: true,
            dataLoading: async (params) => {
              const response = await SoldTo.ajaxSearch(Object.assign({
                withBoHqPermissions: true
              }, params));
              return response.data.map((item) => {
                return {value: item.soldToCode, label: item.codeOrName};
              });
            }
          }} />
        )
      },
    },
    {
      title: '销售月份',
      dataIndex: 'fperiod',
      span: 8,
      render: () => {
        return <RangePicker picker="month" style={{width: '100%'}} allowEmpty={[true, true]}/>
      }
    },
    {
      title: '产品线',
      dataIndex: 'productId',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            allowClear: true,
            placeholder: "请选择产品线",
            showSearch: true,
            remote: true,
            dataLoading: async (params) => {
              const response = await Product.ajaxSearchLevel2(Object.assign({
                isActive: true
              }, params));
              return response.data.map((item) => {
                return {value: item.productId, label: item.codeOrName};
              });
            }
          }} />
        )
      },
    }
  ];
  const columns: ColumnsType<any> = [
    {
      title: '编号',
      width: 80,
      dataIndex: 'expenseNonTpmId',
      ellipsis: true,
    },
    {
      title: '售达方',
      width: 100,
      dataIndex: 'soldToCode',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.String.CodeOrName(record.soldToCode, record.soldToName)
      ),
    },
    {
      title: '费用项',
      width: 100,
      dataIndex: 'ecId',
      ellipsis: true,
      render: (_, record) => (
        record.ecName
      ),
    },
    {
      title: '产品线',
      width: 100,
      dataIndex: 'productIds',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.String.CodeOrName(record.productLineCode, record.productLineName)
      ),
    },
    {
      title: '销售月份',
      width: 60,
      dataIndex: 'fperiod',
      ellipsis: true,
    },
    {
      title: '结案金额',
      width: 80,
      dataIndex: 'amount',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.Number.AmountToString(record.amount, 2)
      ),
    }
  ];

  useEffect(() => {
    fetchData(Object.assign({}, tableParams));
  }, []);

  const getFilterOptions = (params: TableParams) => {
    const filterOptions: any = {};

    if (params.filterOptions) {
      const {fperiod, productId, soldToCode} = params.filterOptions;
      if (!wdiCore.Object.IsNullOrUndefined(fperiod)) {
        if (!wdiCore.Object.IsNullOrUndefined(fperiod[0])) {
          filterOptions.startDate = moment(fperiod[0]).format('YYYYMM');
        }
        if (!wdiCore.Object.IsNullOrUndefined(fperiod[1])) {
          filterOptions.endDate = moment(fperiod[1]).format('YYYYMM');
        }
      }
      if (!wdiCore.Object.IsNullOrUndefined(soldToCode)) {
        filterOptions.soldToCode = soldToCode;
      }
      if (!wdiCore.Object.IsNullOrUndefined(productId)) {
        filterOptions.productId = productId;
      }
    }

   
    return filterOptions;
  }

  const fetchData = (params: TableParams) => {
    setLoading(true);
    const filterOptions = getFilterOptions(params);
    const req: API.QueryForPageReq = {
      filterOptions: JSON.stringify(filterOptions),
      pagenum: params.pagination.current ? (params.pagination.current - 1) : 0,
      pagesize: params.pagination.pageSize ? params.pagination.pageSize : 10,
    };
    ExpenseNonTPM.queryReimbursementForSelect(req).then(responseResult => {
      const data = responseResult.data;
      if (data) {
        setDataSource(data.records);
        setTableParams({
          pagination: {
            ...params.pagination,
            total: data.totalCount,
          },
          sorter: params.sorter,
          filterOptions: params.filterOptions
        });
        setLoading(false);
        setSelectedRowKeys([]);
      }
    });
  };
  const doSearch = (filterOptions: any) => {
    fetchData(Object.assign({}, tableParams, {filterOptions}));
  }

  const onSelectChange = (newSelectedRowKeys: Key[], newSelectedRows: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };
  return (
    <WdiModal
      title={props.title}
      visible={props.visible}
      width={1200}
      onCancel={() => {
        props.onClose ? props.onClose() : null
      }}
      onOk={() => {
        if (selectedRows.length == 0) {
          message.error('请至少选择一条数据');
        } else {
          const bohqId = selectedRows[0].bohqId;
          let isBohqDiff: boolean = false;

          for (let i = 0, len = selectedRows.length; i < len; i++) {
            if (bohqId != selectedRows[i].bohqId) {
              isBohqDiff = true;
              break;
            }
          }

          if (isBohqDiff) {
            message.error('费用归属不一致');
          } else {
            props.onSave?.({
              code: ResponseStatus.SUCCESS,
              data: selectedRows
            })
          }
        }
      }}
    >
      <GridFilterForm doSeach={doSearch} columns={filterColumns} buttonColumns={buttonColumns}/>
      <WdiTable
        showPageRows={10}
        rowSelection={{selectedRowKeys, onChange: onSelectChange}}
        columns={columns}
        size='small'
        dataSource={dataSource}
        pagination={{
          ...tableParams.pagination,
          onChange: (page, pageSize) => {
            fetchData(Object.assign({}, tableParams, {
              pagination: {
                ...tableParams.pagination,
                current: page,
                pageSize: pageSize
              }
            }))
          }
        }}
        loading={loading}
      />
    </WdiModal>
  );
};
export default RebateInfoSelectApp;
