import React, {useEffect, useState} from "react";
import {WdiTable} from "@/components/WdiControls/WdiTable";
import {ColumnsType} from "antd/lib/table/interface";
import {wdiCore} from "@/components/WdiControls";
import {WdiModal} from "@/components/WdiControls/WdiModalForm";
import {Pool} from "@/services/sales/financial/pool";

export const PrePayDetail: React.FC<any> = (props: any) => {
  const [dataSource, setDataSource] = useState<any>([]);
  useEffect(() => {
    if (props.data) {
      let data = props.data;
      Pool.queryPoolBalance(data).then(res => {
        if (res?.data) {
          setDataSource(res.data)
        }
      })
    }
  }, [props.data]);
  const balanceInquiryHistoryTotalColumn: ColumnsType<any> = [
    {title: '售达方主户编号', dataIndex: 'soldToMasterCode', ellipsis: true, width: 100},
    {title: '售达方主户名称', dataIndex: 'soldToMasterName', ellipsis: true, width: 100},
    {
      title: '年份', dataIndex: 'year', ellipsis: true, width: 80, render: (text: any) => {
        return wdiCore.Number.ToInt(text);
      }
    },
    {
      title: '预付类型', dataIndex: 'prepayType', ellipsis: true, width: 100,
      render: (text: any) => {
        if (text == 1) {
          return '合同预付';
        } else if (text == 2) {
          return '活动预付'
        } else {
          return '';
        }
      }
    },
    {
      title: '待冲销预付总额', dataIndex: 'amountPrepay', ellipsis: true, width: 100, render: (text: any) => {
        return wdiCore.Number.ToString(text, 2);
      }
    },
    {
      title: '可冲销核销总额', dataIndex: 'amountPrepayPending', ellipsis: true, width: 100, render: (text: any) => {
        return wdiCore.Number.ToString(text, 2);
      }
    },
    {
      title: '剩余待冲销预付总额', dataIndex: 'amountPrepayRemain', ellipsis: true, width: 150, render: (text: any) => {
        return wdiCore.Number.ToString(text, 2);
      }
    },
  ]
  return (
    <>
      <WdiModal
        title={props.title}
        visible={props.visible}
        width={1000}
        onCancel={() => {
          props.onClose ? props.onClose() : null
        }}
        footer={null}
      >
        <WdiTable
          columns={balanceInquiryHistoryTotalColumn}
          dataSource={dataSource}
        />
      </WdiModal>
    </>
  )
}
export default PrePayDetail;
