import { Key, useEffect, useState } from 'react';
import { wdiCore, WdiModalAppProps } from '@/components/WdiControls';
import { WdiModal } from '@/components/WdiControls/WdiModalForm';
import { ButtonColumn, FilterColumn, GridFilterForm } from '@/components/GridWidget/GridFilterForm';
import { Button, DatePicker, message } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { WdiSelect } from '@/components/WdiControls/WdiSelect';
import { SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import { WdiTable } from '@/components/WdiControls/WdiTable';
import { ResponseStatus, formatter } from '@/utils/request';
import { ExpenseNonTPM } from '@/services/contract/expensenontpm';
import { Product } from '@/services/mdm/product';
import { SoldTo } from '@/services/mdm/soldto';
import download from '@/utils/download';
import { Enum } from '@/enums/system';
import { Staff } from '@/services/basic/staff';
import { DataOptions } from '@/services/basic/metadata';
import { VatType } from '@/services/mdm/vattype';
import { SimpleStaff } from '@/services/basic/simplestaff';


const { RangePicker } = DatePicker;

type TableParams = {
  pagination: TablePaginationConfig;
  sorter?: SorterResult<any> | null | undefined;
  filterOptions: any,
}

const baseColumns = [
  { name: "expenseNonTpmId", displayName: "返利单编号*" },
  { name: "soldToCode", displayName: "售达方编号" },
  { name: "soldToName", displayName: "售达方名称" },
  { name: "ecName", displayName: "费用项" },
  { name: "productLineCode", displayName: "产品线编号" },
  { name: "productLineName", displayName: "产品线名称" },
  { name: "fperiod", displayName: "销售月份", type: Enum.FieldType.NUMBER, dataType: Enum.FieldDataType.INT },
  { name: "paymentMethodName", displayName: "支付方式*" },
  { name: "invoiceTypeName", displayName: "发票类型（若是电汇/账扣/挂账余则必填）" },
  { name: "vatTypeName", displayName: "税率类型（若是电汇/账扣则必填）" },
  { name: "amount", displayName: "结案金额" },
  { name: "amountRs", displayName: "核销金额" },
  { name: "amountWaitingPayment", displayName: "待付款金额" },
];


const RebateInfoSelectApp: React.FC<WdiModalAppProps> = (props) => {
  // const { data } = props;


  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      defaultPageSize: 10,
      total: 0,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
    },
    sorter: null,
    filterOptions: null,
  });
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [currentFilterOptions, setCurrentFilterOptions] = useState<any>(null);

  const buttonColumns: ButtonColumn[] = [
    {
      title: '导出',
      key: 'Export',
      doClick: (filterOptions: any) => {
        let columns: any[] = [];
        columns = baseColumns.concat([
          { name: "amountExVat", displayName: "本次付款金额(不含税)*" },
          { name: "remarkSp", displayName: "付款备注" },
        ]);

        let filter = getFilterOptions(Object.assign({}, tableParams, { filterOptions }));

        const req: API.QueryForPageReq = {
          filterOptions: JSON.stringify(filter),
          pagenum: 0,
          pagesize: 0
        };

        ExpenseNonTPM.queryPaymentForSelect(req).then(response => {
          download(ExpenseNonTPM.Path.DownLoadExpenseNonTpmPicker, formatter({
            method: 'POST',
            body: {
              expenseNonTpmSelectQueryPos: response.data.records,
            },
          }))
        });

      }
    },
  ]

  const filterColumns: FilterColumn[] = [
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            allowClear: true,
            placeholder: "请选择售达方",
            showSearch: true,
            remote: true,
            dataLoading: async (params) => {
              const response = await SoldTo.ajaxSearch(Object.assign({
                withBoHqPermissions: true
              }, params));
              return response.data.map((item) => {
                return { value: item.soldToCode, label: item.codeOrName };
              });
            }
          }} />
        )
      },
    },
    {
      title: '销售月份',
      dataIndex: 'fperiod',
      span: 8,
      render: () => {
        return <RangePicker picker="month" style={{ width: '100%' }} allowEmpty={[true, true]} />
      }
    },
    {
      title: '产品线',
      dataIndex: 'productId',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            allowClear: true,
            placeholder: "请选择产品线",
            showSearch: true,
            remote: true,
            dataLoading: async (params) => {
              const response = await Product.ajaxSearchLevel2(Object.assign({
                isActive: true
              }, params));
              return response.data.map((item) => {
                return { value: item.productId, label: item.codeOrName };
              });
            }
          }} />
        )
      },
    },
    {
      title: 'on-invoice核销单已审批人',
      dataIndex: 'approverUserIds',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            mode: "multiple",
            allowClear: true,
            placeholder: "请选择已审批人",
            showSearch: true,
            remote: true,
            dataLoading: async (params) => {
              const response = await SimpleStaff.ajaxSearch(Object.assign({
              }, params));
              return response.data.map((item) => {
                return { value: item.staffId, label: item.staffName };
              });
            }
          }} />
        )
      },
    },
    {
      title: '支付方式',
      dataIndex: 'paymentMethodId',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            allowClear: true,
            placeholder: "请选择支付方式",
            showSearch: true,
            dataLoading: async (params) => {
              const response = await DataOptions.search({ typeGroup: 5 });
              return response.data.map((item) => {
                return { value: item.value, label: item.name };
              });
            }
          }} />
        )
      }
    },
    {
      title: '税率类型',
      dataIndex: 'vatTypeId',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            allowClear: true,
            placeholder: "请选择税率类型",
            showSearch: true,
            dataLoading: async (params) => {
              const response = await VatType.search({});
              return response.data.map((item) => {
                return { value: item.vatTypeId, label: item.vatTypeName };
              });
            }
          }} />
        )
      }
    },
    {
      title: '发票类型',
      dataIndex: 'invoiceType',
      span: 4,
      render: () => {
        return (
          <WdiSelect {...{
            allowClear: true,
            placeholder: "请选择发票类型",
            showSearch: true,
            dataLoading: async (params) => {
              const response = await DataOptions.search({ typeGroup: 106 });
              return response.data.map((item) => {
                return { value: item.value, label: item.name };
              });
            }
          }} />
        )
      },
    }
  ];
  const columns: ColumnsType<any> = [
    {
      title: '返利单编号',
      width: 80,
      dataIndex: 'expenseNonTpmId',
      ellipsis: true,
    },
    {
      title: '售达方',
      width: 100,
      dataIndex: 'soldToCode',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.String.CodeOrName(record.soldToCode, record.soldToName)
      ),
    },
    {
      title: '费用项',
      width: 100,
      dataIndex: 'ecId',
      ellipsis: true,
      render: (_, record) => (
        record.ecName
      ),
    },
    {
      title: '产品线',
      width: 100,
      dataIndex: 'productIds',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.String.CodeOrName(record.productLineCode, record.productLineName)
      ),
    },
    {
      title: '销售月份',
      width: 60,
      dataIndex: 'fperiod',
      ellipsis: true,
    },
    {
      title: '结案金额',
      width: 80,
      dataIndex: 'amount',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.Number.AmountToString(record.amount, 2)
      ),
    },
    {
      title: '核销金额',
      width: 80,
      dataIndex: 'amountRs',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.Number.AmountToString(record.amountRs, 2)
      ),
    },
    {
      title: '待付款金额',
      width: 80,
      dataIndex: 'amountWaitingPayment',
      ellipsis: true,
      render: (_, record) => (
        wdiCore.Number.AmountToString(record.amountWaitingPayment, 2)
      ),
    },
    {
      title: '支付方式',
      width: 80,
      dataIndex: 'paymentMethodName',
      ellipsis: true,
    },
    {
      title: '发票类型',
      width: 80,
      dataIndex: 'invoiceTypeName',
      ellipsis: true,
    },
    {
      title: '税率类型',
      width: 80,
      dataIndex: 'vatTypeName',
      ellipsis: true,
    }
  ];

  useEffect(() => {
    fetchData(Object.assign({}, tableParams));
  }, []);

  const getFilterOptions = (params: TableParams) => {
    const filterOptions: any = {};

    if (params.filterOptions) {
      const { fperiod, productId, soldToCode, approverUserIds, paymentMethodId, vatTypeId, invoiceType } = params.filterOptions;
      if (!wdiCore.Object.IsNullOrUndefined(fperiod)) {
        if (!wdiCore.Object.IsNullOrUndefined(fperiod[0])) {
          filterOptions.startDate = moment(fperiod[0]).format('YYYYMM');
        }
        if (!wdiCore.Object.IsNullOrUndefined(fperiod[1])) {
          filterOptions.endDate = moment(fperiod[1]).format('YYYYMM');
        }
      }
      if (!wdiCore.Object.IsNullOrUndefined(soldToCode)) {
        filterOptions.soldToCode = soldToCode;
      }
      if (!wdiCore.Object.IsNullOrUndefined(productId)) {
        filterOptions.productId = productId;
      }
      if (!wdiCore.Object.IsNullOrUndefined(approverUserIds)) {
        filterOptions.approverUserIds = approverUserIds.join();
      }
      if (!wdiCore.Object.IsNullOrUndefined(paymentMethodId)) {
        filterOptions.paymentMethodId = paymentMethodId;
      }
      if (!wdiCore.Object.IsNullOrUndefined(vatTypeId)) {
        filterOptions.vatTypeId = vatTypeId;
      }
      if (!wdiCore.Object.IsNullOrUndefined(invoiceType)) {
        filterOptions.invoiceType = invoiceType;
      }
    }
    return filterOptions;
  }

  const fetchData = (params: TableParams) => {
    setLoading(true);
    const filterOptions = getFilterOptions(params);
    const req: API.QueryForPageReq = {
      filterOptions: JSON.stringify(filterOptions),
      pagenum: params.pagination.current ? (params.pagination.current - 1) : 0,
      pagesize: params.pagination.pageSize ? params.pagination.pageSize : 10,
    };

    setCurrentFilterOptions(filterOptions);

    ExpenseNonTPM.queryPaymentForSelect(req).then(responseResult => {
      const data = responseResult.data;
      if (data) {
        setDataSource(data.records);
        setTableParams({
          pagination: {
            ...params.pagination,
            total: data.totalCount,
          },
          sorter: params.sorter,
          filterOptions: params.filterOptions
        });
        setLoading(false);
        setSelectedRowKeys([]);
      }
    });
  };
  const doSearch = (filterOptions: any) => {
    fetchData(Object.assign({}, tableParams, { filterOptions }));
  }

  const onSelectChange = (newSelectedRowKeys: Key[], newSelectedRows: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };
  return (
    <WdiModal
      title={props.title}
      visible={props.visible}
      width={1200}
      footer={
        <>
          <Button onClick={() => {
            props.onClose ? props.onClose() : null
          }}>
            取消
          </Button>
          <Button key='test' onClick={() => {
            if (selectedRows.length == 0) {
              message.error('请至少选择一条数据');
            } else {
              props.onSave?.({
                code: ResponseStatus.SUCCESS,
                data: selectedRows
              })
            }
          }}>
            添加
          </Button>
          <Button onClick={(filterOptions: any) => {
            let filter = getFilterOptions(Object.assign({}, tableParams, { currentFilterOptions }));

            const req: API.QueryForPageReq = {
              filterOptions: JSON.stringify(filter),
              pagenum: 0,
              pagesize: 0
            };

            ExpenseNonTPM.queryPaymentForSelect(req).then(response => {
              props.onSave?.({
                code: ResponseStatus.SUCCESS,
                data: response.data.records
              })
            });
          }}>
            全部添加
          </Button>
        </>
      }
      onCancel={() => {
        props.onClose ? props.onClose() : null
      }}
      onOk={() => {

        if (selectedRows.length == 0) {
          message.error('请至少选择一条数据');
        } else {
          props.onSave?.({
            code: ResponseStatus.SUCCESS,
            data: selectedRows
          })
        }
      }}
    >
      <GridFilterForm doSeach={doSearch} columns={filterColumns} buttonColumns={buttonColumns} />
      <WdiTable
        showPageRows={10}
        rowSelection={{ selectedRowKeys, onChange: onSelectChange }}
        columns={columns}
        size='small'
        dataSource={dataSource}
        pagination={{
          ...tableParams.pagination,
          onChange: (page, pageSize) => {
            fetchData(Object.assign({}, tableParams, {
              pagination: {
                ...tableParams.pagination,
                current: page,
                pageSize: pageSize
              }
            }))
          }
        }}
        loading={loading}
      />
    </WdiModal>
  );
};
export default RebateInfoSelectApp;
