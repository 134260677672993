import { useEffect, useRef, useState } from 'react';
import { WdiSelect, WdiSelectFormat } from '@/components/WdiControls/WdiSelect';
import { DatePicker, Form, Input, message } from 'antd';
import { ResponseStatus } from '@/utils/request';
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { Product } from '@/services/mdm/product';
import { ProFormInstance } from '@ant-design/pro-form';
import { Enum } from '@/enums/system';
import { WdiModalAppProps, wdiCore } from '@/components/WdiControls';
import { HorzLayoutOpts, WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { FormSa } from '@/services/sales/sa';
import { getDetailPrevData } from '../../components/data';
import moment from 'moment';
import { District } from '@/services/mdm/district';
import { Platform } from '@/services/mdm/platform';
import { DataOptions } from '@/services/basic/metadata';




const PriceDetailApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const [showPlatform, setShowPlatform] = useState<boolean>(false);
  const [showDistrict, setShowDistrict] = useState<boolean>(false);

  const { formSaDetail, formSa, rowIndex, rows } = props.data;


  /** 根据费用项的核算类型决定如何计算
    申请金额 = 促单听补贴*一促预估销量+二促单听补贴*二促预估销量
   */
  const handleCalcAmount = () => {
    const fields = formRef.current?.getFieldsValue();
    const amount = fields.unitExpense1 * fields.quantityForecast1 + fields.unitExpense2 * fields.quantityForecast2
    formRef.current?.setFieldsValue({
      amount: wdiCore.Number.ToNumber(amount, 2)
    });
  };

  useEffect(() => {  
    DataOptions.search({ typeGroup: 104 }).then(response => {
      setShowPlatform(response?.data.filter(item => item.name == formSaDetail.soldToCode).length > 0)
    })

    if (Enum.BOHQ.DC == formSa.bohqId) {
      setShowDistrict(true);
    }    

    if (formSaDetail) {
      formRef.current?.setFieldsValue({
        activityStartDate: formSaDetail.activityStartDate ? moment(formSaDetail.activityStartDate) : undefined,
        activityEndDate: formSaDetail.activityEndDate ? moment(formSaDetail.activityEndDate) : undefined,
        supplyStartDate: formSaDetail.supplyStartDate ? moment(formSaDetail.supplyStartDate) : undefined,
        supplyEndDate: formSaDetail.supplyEndDate ? moment(formSaDetail.supplyEndDate) : undefined,
        promotionDesc: formSaDetail.promotionDesc,
        unitExpense1: formSaDetail.unitExpense1,
        quantityForecast1: formSaDetail.quantityForecast1,
        pricePosAfter1: formSaDetail.pricePosAfter1,
        unitExpense2: formSaDetail.unitExpense2,
        quantityForecast2: formSaDetail.quantityForecast2,
        pricePosAfter2: formSaDetail.pricePosAfter2,
        productBuyIds: formSaDetail.productBuyIds,
        amount: formSaDetail.amount,
        // boName: formSaDetail.boName,
        ecName: formSaDetail.ecName,
        revenueTypeName: formSaDetail.revenueTypeName,
        soldToName: wdiCore.String.Join(formSaDetail.soldToCode, formSaDetail.soldToName),
        custSysName: wdiCore.String.Join(formSaDetail.custSysCode, formSaDetail.custSysName),
        remark: formSaDetail.remark,
      });

      if (formSaDetail.platformId) {
        formRef.current?.setFieldsValue({ platformId: formSaDetail.platformId });
      }
      if (formSaDetail.districtId) {
        formRef.current?.setFieldsValue({ districtId: formSaDetail.districtId });
      }

      if (formSaDetail.productBuyIds) {
        formRef.current?.setFieldsValue({
          productBuyIds: {
            value: formSaDetail.productBuyIds,
            label: formSaDetail.productBuyNames,
          }
        });
      }

    }
  }, [formSaDetail]);

  return (
    <WdiModalForm
      formRef={formRef}
      initialValues={{ undertakeRate: 1 }}
      title={props.title}
      visible={props.visible}
      modalProps={{ onCancel: () => { props.onClose?.() }, }}
      onFinish={async (values) => {
        const entity: API.FormSaDetail = {
          amount: values.amount,
          remark: values.remark,
          promotionDesc: values.promotionDesc,
          platformId: values.platformId,
          districtId: values.districtId,
          unitExpense1: values.unitExpense1,
          quantityForecast1: values.quantityForecast1,
          pricePosAfter1: values.pricePosAfter1,
          unitExpense2: values.unitExpense2,
          quantityForecast2: values.quantityForecast2,
          pricePosAfter2: values.pricePosAfter2
        };

        entity.productBuyIds = WdiSelectFormat.getvalue(values.productBuyIds);

        if (entity.pricePosAfter1 && entity.pricePosAfter1 <= 0) {
          message.error("一促零售价必须大于0!");
          return;
        }

        if (entity.pricePosAfter2 && entity.pricePosAfter2 <= 0) {
          message.error("二促零售价必须大于0!");
          return;
        }

        // if (entity.amount && entity.amount <= 0) {
        //   message.error("申请金额必须大于0!");
        //   return;
        // }

        if (values.activityStartDate) {
          entity.activityStartDate = moment(values.activityStartDate).format("YYYY-MM-DD");
        }
        if (values.activityEndDate) {
          entity.activityEndDate = moment(values.activityEndDate).format("YYYY-MM-DD");
        }
        if (values.supplyStartDate) {
          entity.supplyStartDate = moment(values.supplyStartDate).format("YYYY-MM-DD");
        }
        if (values.supplyEndDate) {
          entity.supplyEndDate = moment(values.supplyEndDate).format("YYYY-MM-DD");
        }

        const response = await FormSa.Buttons.validateDetail({
          rowIndex: rowIndex,
          formSa: { ...formSa },
          formSaDetail: Object.assign({}, getDetailPrevData(formSaDetail), entity),
          formSaDetails: rows
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (props.onSave) {
          const data = Object.assign({}, { rowIndex: rowIndex }, response.data);
          response.data = data;
          props.onSave(response);
        }
      }}
      {...HorzLayoutOpts}
    >
      <Form.Item name="soldToName" label="售达方">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="custSysName" label="客户集团">
        <Input readOnly />
      </Form.Item>
      {
        showPlatform == true &&
        <Form.Item name="platformId" label="分子平台"
          rules={[{ required: true, message: '分子平台不能为空!' }]}>
          <WdiSelect {...{
            placeholder: "请选择分子平台",
            showSearch: true,
            remote: true,
            dataLoading: async (params) => {
              const response = await Platform.ajaxSearch(Object.assign({
                soldToCode: formSaDetail.soldToCode,
                custSysCode: formSaDetail.custSysCode,
                isActive: true,
              }, params));
              return response.data.map((item) => ({ value: item.platformId, label: item.codeOrName }));
            }
          }} />
        </Form.Item>
      }
      {
        showDistrict == true &&
        <Form.Item name="districtId" label="省份"
          rules={[{ required: true, message: '省份不能为空!' }]}>
          <WdiSelect {...{
            placeholder: "请选择省份",
            showSearch: true,
            remote: true,
            dataLoading: async (params) => {
              const response = await District.search(Object.assign({
                // districtLevels: "0,1",
                soldToCode: formSaDetail.soldToCode,
                custSysCode: formSaDetail.custSysCode,
                containsCountry: true,
                isActive: true,
              }, params));
              return response.data.map((item) => ({ value: item.districtId, label: item.districtName }));
            }
          }} />
        </Form.Item>
      }
      <Form.Item name="productBuyIds" label="产品组"
        rules={[{ required: true, message: '产品组不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择产品组",
          showSearch: true,
          remote: true,
          dataLoading: async (params) => {
            const response = await Product.ajaxSearchLevel4(Object.assign({
              productLevels: "4",
              bohqId: formSa.bohqId,
              isActive: true,
            }, params));
            return response.data.map((item) => ({ value: item.productId, label: item.codeOrName }));
          }
        }} />
      </Form.Item>
      <Form.Item name="promotionDesc" label="活动机制" rules={[{ required: true, message: '活动机制不能为空!' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="unitExpense1" label="一促单听补贴" rules={[{ required: true, message: '一促单听补贴不能为空!' }]}>
        <WdiInputNumber min={0} onChange={() => { handleCalcAmount(); }} />
      </Form.Item>
      <Form.Item name="quantityForecast1" label="一促预估销量" rules={[{ required: true, message: '一促预估销量不能为空!' }]}>
        <WdiInputNumber min={0} onChange={() => { handleCalcAmount(); }} digits={0} />
      </Form.Item>
      <Form.Item name="pricePosAfter1" label="一促零售价" rules={[{ required: true, message: '一促零售价不能为空!' }]}>
        <WdiInputNumber min={0} />
      </Form.Item>
      <Form.Item name="unitExpense2" label="二促单听补贴" rules={[{ required: true, message: '二促单听补贴不能为空!' }]}>
        <WdiInputNumber min={0} onChange={() => { handleCalcAmount(); }} />
      </Form.Item>
      <Form.Item name="quantityForecast2" label="二促预估销量" rules={[{ required: true, message: '二促预估销量不能为空!' }]}>
        <WdiInputNumber min={0} onChange={() => { handleCalcAmount(); }} digits={0} />
      </Form.Item>
      <Form.Item name="pricePosAfter2" label="二促零售价" rules={[{ required: true, message: '二促零售价不能为空!' }]}>
        <WdiInputNumber min={0} />
      </Form.Item>
      <Form.Item name="amount" label="申请金额" rules={[{ required: true, message: '申请金额不能为空!' }]}>
        <WdiInputNumber readOnly={true} />
      </Form.Item>
      <Form.Item name="activityStartDate" label="明细活动开始日期" >
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>
      <Form.Item name="activityEndDate" label="明细活动截止日期" >
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>
      <Form.Item name="supplyStartDate" label="明细供货开始日期" >
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>
      <Form.Item name="supplyEndDate" label="明细供货截止日期" >
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <Input maxLength={2000} />
      </Form.Item>
      <Form.Item name="ecName" label="费用项">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="revenueTypeName" label="核算类型" >
        <Input readOnly />
      </Form.Item>
    </WdiModalForm>
  );
}
export default PriceDetailApp;
