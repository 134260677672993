import React, { Component } from 'react';
import { FieldUtility } from './GridWidgetUtility';
import { NsGrid } from './GridDefProps';
import { GroupDetailTable, GroupDetailTableProps } from './GroupDetailTable';
import { ViewColumnSettingApp } from './ViewSettings';
import { Button } from 'antd';
import { wdiCore } from '@/components/WdiControls';
import { EllipsisOutlined } from '@ant-design/icons';
import { ViewState } from './dataConvert';

const initViewState = (props: GroupDetailGridProps, viewObject: API.ViewObject, viewDefs: API.ViewDef[]) => {
  const viewState = {
    viewMode: NsGrid.ViewMode.None, //0, 1, 2
    viewObject: null,
    viewDefs: viewDefs,
    viewKey: 0,
  };

  if (viewObject) {
    if (props.additionalColumns) {
      viewObject.viewColumns.push(...props.additionalColumns);
    }
    viewState.viewObject = viewObject;
  }
  return viewState;
};

const convertToTableColumn = (fieldObject: API.FieldObject) => {
  const column: Record<string, any> = {
    title: fieldObject.displayName,
    dataIndex: fieldObject.name,
    key: fieldObject.name,
    ellipsis: true,
    sorter: false,
    onCell: (record: any) => ({
      rowSpan: record[fieldObject.name + '-rowSpan'],
    }),
  };

  if (fieldObject.isSortable) {
    column.sorter = true;
    column.title = <span title={fieldObject.displayName}>{fieldObject.displayName}</span>;
  }

  if (!wdiCore.String.IsNullOrEmpty(fieldObject.type)) {
    const fieldCellFunc = FieldUtility[fieldObject.type];
    if (!wdiCore.Object.IsNullOrUndefined(fieldCellFunc)) {
      column.render = fieldCellFunc(fieldObject);
    }
  }

  if (!wdiCore.Object.IsNullOrUndefined(fieldObject.getDisplayFormater)) {
    column.render = fieldObject.getDisplayFormater;
  }

  if (!wdiCore.Object.IsNullOrUndefined(fieldObject.width) && fieldObject.width > 0) {
    column.width = fieldObject.width;
  }

  if (fieldObject.antdProps) {
    for (var key in fieldObject.antdProps) {
      column[key] = fieldObject.antdProps[key];
    }
  }
  return column;
};

const convertToClolumns = (allFields: any, columns: string[]) => {
  let viewColumns: any[] = [];
  if (columns) {
    columns.forEach(function (item, itemIndex) {
      const fieldObject = allFields[item];
      if (!wdiCore.Object.IsNullOrUndefined(fieldObject)) {
        const column = convertToTableColumn(fieldObject);
        viewColumns.push(column);
      }
    });
  }
  return viewColumns;
};

const getTableColumns = (props: GroupDetailGridProps, viewObject: API.ViewObject) => {
  const { formSchema } = props;
  const columns = [];
  const allFields = formSchema.allFields;
  if (viewObject && viewObject.viewColumns && viewObject.viewColumns.length) {
    viewObject.viewColumns.forEach((item: any) => {
      const column = allFields[item];
      if (!wdiCore.Object.IsNullOrUndefined(column) && !column.isDisabled && !column.isHidden) {
        columns.push(column.name);
      }
    });
  } else {
    for (let key in allFields) {
      const column = allFields[key];
      if (!column.isDisabled && !column.isHidden) {
        columns.push(column.name);
      }
    }
  }
  if (props.additionalColumns) {
    props.additionalColumns.forEach((item) => {
      const column = allFields[item];
      if (!wdiCore.Object.IsNullOrUndefined(column)) {
        columns.push(column.name);
      }
    });
  }
  return columns;
};

const initColumnStates = (props: GroupDetailGridProps, viewObject: API.ViewObject) => {
  const columns = getTableColumns(props, viewObject);
  const columnStates = convertToClolumns(props.formSchema.allFields, columns);
  return columnStates;
};

/// formSchema
/// allowModifyColumns: 允许调整数据展示列
/// width
/// height
/// rowKey: uid 固定设置
/// formSchema: props.formSchema,
/// heightHeader: props.heightHeader, /// Antd Table 表头高度默认39
/// heightFooter: props.heightFooter, /// Antd Table 底部分页高度默认 56
/// showAggr: props.showAggr, /// 展示汇总信息
/// showExpandedRow: props.showExpandedRow, ///扩展列
/// params: any 扩展参数和方法
/// editColumn: any

type GroupDetailGridProps = {
  // 汇总列
  additionalColumns?: any[];
  // 控件的宽度
  width?: number;
  //查询参数
  queryParamaters?: any;
  // Antd Table Props
  antdProps?: any;
  // 表格查询需要的数据源
  dataLoading: (args: API.JQXGridArgs, exParams?: any) => Promise<API.QueryResult<any>>;
  // 数据是否延迟加载，报表使用
  isLazyLoad?: boolean;
} & API.GridConfig;

type GroupDetailGridStates = {
  // 弹出数据列配置窗口
  showModifyModal: boolean;
  // 视图定义
  viewState: ViewState;
  // 数据表展示列
  columnStates: any[];
  // 列表宽度
  width?: number;
  //查询参数
  queryParamaters?: any;
  // 汇总列
  additionalColumns: any[];
}

class GroupDetailGrid extends Component<GroupDetailGridProps> {
  state: GroupDetailGridStates;
  tableParentRef: any;
  tableRef: any;
  constructor(props: GroupDetailGridProps) {
    super(props);

    // 初始化视图对象
    const viewState = initViewState(props, props.viewObject, props.viewDefs);
    const columnStates = initColumnStates(props, props.viewObject);
    this.state = {
      showModifyModal: false, //弹出数据配置列
      viewState: viewState,
      columnStates: columnStates, //数据表展示列
      additionalColumns: []
    };

    this.tableParentRef = React.createRef();
    if (props.width) {
      this.state.width = props.width;
    }
    if (props.queryParamaters) {
      this.state.queryParamaters = props.queryParamaters;
    }
    if (props.additionalColumns) {
      this.state.additionalColumns = props.additionalColumns;
    }

    this.tableRef = React.createRef();
    this.handleViewColumnClick = this.handleViewColumnClick.bind(this);
    this.closeViewColumnModal = this.closeViewColumnModal.bind(this);
    this.viewColumnToggle = this.viewColumnToggle.bind(this);
  }

  /// 更新视图切换数据列, 只更新数据展示列
  viewColumnToggle = (viewObject: API.ViewObject, viewDefs: API.ViewDef[]) => {
    this.setState((prevState: GroupDetailGridStates, prevProps: GroupDetailGridProps) => {
      const viewState = initViewState(prevProps, viewObject, viewDefs);
      //更新视图强制刷新
      viewState.viewKey = prevState.viewState.viewKey + 1;
      const columnStates = initColumnStates(prevProps, viewObject);
      return {
        showModifyModal: false,
        viewState,
        columnStates,
      };
    });
  };

  closeViewColumnModal = () => {
    this.setState({ showModifyModal: false });
  };

  handleViewColumnClick = () => {
    this.setState({ showModifyModal: true });
  };

  formatGroupDetailTableProps = (props: GroupDetailGridProps, state: GroupDetailGridStates): GroupDetailTableProps => {
    const settings: GroupDetailTableProps = {
      rowKey: 'uid', /// 表格数据唯一标识符
      columnStates: state.columnStates, /// 展示列设置
      additionalColumns: state.additionalColumns, /// 汇总列
      queryParamaters: state.queryParamaters, /// 查询参数
      antdProps: Object.assign({}, props.antdProps),
      width: props.width ? props.width : this.tableParentRef?.current?.offsetWidth,
      dataLoading: props.dataLoading, /// 数据源dataLoading
      isLazyLoad: props.isLazyLoad, /// 数据是否延迟加载，报表使用
    };
    return settings;
  };

  render() {
    const tableSettings = this.formatGroupDetailTableProps(this.props, this.state);
    return (
      <div>
        <div className="grid-body" ref={this.tableParentRef}>
          <GroupDetailTable
            {...tableSettings}
            //grid={this}
            ref={this.tableRef}
            key={'body' + this.state.viewState.viewKey}
          />
          {this.props.allowModifyColumns && (
            <div className="grid-body-modifycloumns">
              <Button
                type="default"
                icon={<EllipsisOutlined />}
                size={'small'}
                onClick={this.handleViewColumnClick}
              />
              {this.state.showModifyModal && (
                <ViewColumnSettingApp
                  {...{
                    visible: this.state.showModifyModal,
                    viewObject: this.state.viewState.viewObject,
                    formSchema: this.props.formSchema,
                  }}
                  grid={this}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { GroupDetailGrid };
