import {formatter, request} from '@/utils/request';

export namespace BU {
  export const Path = {
    Search: '/basicapi/bu/search',
    Get: '/basicapi/bu/get',
    GetBUs: '/basicapi/bu/getBUs',
  };

  export const BU_VALUE = {
    Global: 10,
  };

  export async function search() {
    const options = {
      method: 'POST',
      body: {},
    };
    return request<API.ResponseResult<API.BU[]>>(Path.Search, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.BU>>(Path.Get, formatter(options));
  }

  export async function getBUs() {
    const options = {
      method: 'POST',
      body: {},
    };
    return request<API.ResponseResult<API.BU[]>>(Path.GetBUs, formatter(options));
  }

}
