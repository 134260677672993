import { Component } from 'react';
import { Utility } from './GridWidgetUtility';
import { Popover, Table } from 'antd';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { FooterSummary, ResizableTitle } from './GridPlugin';
import { initExpandedDisplayFormater } from '@/components/WdiBus/TableColumnRanderFormats';
import { PaginationValue, ShowAggrValue, SortValue } from './PageTable';

type DetailTableStates = {
  pagination: false | PaginationValue;
  sorter: SortValue | null;
  loading: boolean;
  columns: any[];
  expandedRowKeys: string[];
  showAllExpanded: boolean;
  scroll?: any;
}

export type DetailTableProps = {
  // Antd Table Props
  antdProps?: any;
  scroll?: any;
  // 展示列
  columnStates: any[];
  // 容器宽度
  width?: number;
  // 容器高度
  height?: number;
  //可编辑的列
  editColumn?: any[];
  //行号自增列
  autoColumn?: any[];
  //是否有扩展列
  showExpandedRow?: boolean;
  //是否展示汇总信息
  showAggr?: false | ShowAggrValue;
  // Schema定义
  formSchema: API.FormSchema;
}

const defaultDetailPagination: PaginationValue = {
  onlyTotal: false,
  current: 1,
  pageSize: 20,
  showSizeChanger: true,
  pageSizeOptions: [10, 20, 50, 100],
  showTotal: (total: number) => {
    return `共 ${total} 条`;
  },
};

class DetailTable extends Component<DetailTableProps> {
  state: DetailTableStates;

  constructor(props: DetailTableProps) {
    super(props);
    this.state = {
      // 当前表格的分页设置
      pagination: Object.assign({}, defaultDetailPagination, { onChange: this.handleChange }),
      // 当前表格设置的排序策略
      sorter: null,
      loading: false,
      columns: [],
      // 当前表格扩展列
      expandedRowKeys: [],
      // 扩展列是否全部展示
      showAllExpanded: false,
      scroll: null,
    };

    const antdProps = props.antdProps;
    if (antdProps.pagination == false) {
      this.state.pagination = false;
    } else if (antdProps.pagination) {
      this.state.pagination = Object.assign({}, defaultDetailPagination, { onChange: this.handleChange }, antdProps.pagination);
    }
    if (props.scroll) {
      this.state.scroll = props.scroll;
    }
    const newState = this.derivedStateFromProps(props.columnStates, props.width);
    if (newState.columns) {
      this.state.columns = newState.columns;
    }
    if (newState.scroll) {
      this.state.scroll = newState.scroll;
    }
  }

  handleResize = (index: number) => {
    return (_: any, { size }: any) => {
      const newColumns = [...this.state.columns];
      const current: any = newColumns[index];
      newColumns[index] = {
        ...current,
        width: size.width,
      };
      this.setState({ columns: newColumns });
    };
  };

  handleChange = (page: Number, pageSize: Number) => {
    this.setState({ pagination: Object.assign({}, this.state.pagination, { current: page, pageSize: pageSize }) });
  };

  derivedStateFromProps = (columnStates: any[], width?: number) => {
    const fitWidgetTable: any = Utility.fitColumnWidth(columnStates, width);
    const mergeColumns = fitWidgetTable.columns.map((col: any, index: number) => ({
      ...col,
      onHeaderCell: (column: any) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    const newState: any = {
      columns: mergeColumns,
    };
    if (fitWidgetTable.scrollX) {
      newState.scroll = Object.assign({}, this.state.scroll, { x: fitWidgetTable.scrollX });
    }
    return newState;
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.width !== prevProps.width || this.props.columnStates !== prevProps.columnStates) {
      const newState = this.derivedStateFromProps(this.props.columnStates, this.props.width);
      this.setState(newState);
    }
  }

  handleExpandedAllRow = (showAllExpanded: boolean) => {
    if (showAllExpanded) {
      this.setState({
        expandedRowKeys: (this.props.antdProps?.dataSource || []).map((record: any) => record.uid),
        showAllExpanded: true,
      });
    } else {
      this.setState({ expandedRowKeys: [], showAllExpanded: false });
    }
  };

  toggleExpandedRowKeys = (key: string) => {
    const expandedRowKeys = [...this.state.expandedRowKeys];
    if (expandedRowKeys.includes(key)) {
      this.setState({ expandedRowKeys: expandedRowKeys.filter((e) => e !== key) });
    } else {
      expandedRowKeys.push(key);
      this.setState({ expandedRowKeys: expandedRowKeys });
    }
  };

  expendedRowColumn = () => {
    return {
      dataIndex: '__ExpandedRow',
      key: '__ExpandedRow',
      title: () =>
        this.state.showAllExpanded ? (
          <CaretDownOutlined onClick={(e) => this.handleExpandedAllRow(false)} />
        ) : (
          <CaretRightOutlined onClick={(e) => this.handleExpandedAllRow(true)} />
        ),
      render: (_: any, record: any) =>
        this.state.expandedRowKeys.includes(record.uid) ? (
          <CaretDownOutlined onClick={() => this.toggleExpandedRowKeys(record.uid)} />
        ) : (
          <CaretRightOutlined onClick={() => this.toggleExpandedRowKeys(record.uid)} />
        ),
      width: 30,
    };
  };

  render() {
    const { pagination, loading, scroll, columns } = this.state;
    const { formSchema, showAggr, showExpandedRow, editColumn, autoColumn } = this.props;

    const newColumns = columns?.map((col) => {
      if (editColumn && editColumn[col.dataIndex]) {
        col.render = editColumn[col.dataIndex];
      }
      //用于自增行号
      if (autoColumn && autoColumn.indexOf(col.dataIndex) > -1) {
        if (pagination) {
          col.render = (text: any, record: any, index: number) => `${(pagination.current - 1) * pagination.pageSize + (index + 1)}`;
        } else {
          col.render = (text: any, record: any, index: number) => `${(index + 1)}`;
        }
      }
      if (col.ellipsis && (col.ellipsis == true || (col.ellipsis.showTitle && col.ellipsis.showTitle == true))) {
        if (!col.render) {
          col.ellipsis = { showTitle: false };
          col.render = (text: any, record: any, index: number) => {
            if (text && text.length > 10) {
              return <Popover placement='topLeft' content={<div style={{ maxWidth: 800 }}>{text}</div>}>
                {text}
              </Popover>;
            } else {
              return <span title={text}>{text}</span>;
            }
          };
        } else {
          const newCol = { ...col };
          col.ellipsis = { showTitle: false };
          col.render = (text: any, record: any, index: number) => {
            const title = newCol.render(text, record, index);
            if (typeof title == 'string') {
              if (title && title.length > 10) {
                return <Popover placement='topLeft' content={<div style={{ maxWidth: 800 }}>{title}</div>}>
                  {title}
                </Popover>;
              } else {
                return <span title={title}>{title}</span>;
              }
            } else {
              return title;
            }
          };
        }
      }
      return col;
    });

    const antdProps = Object.assign(
      {
        // 默认禁掉排序的Tooltip
        showSorterTooltip: false,
        // 默认设置表格尺寸大小为small
        size: 'small',
        // antd需要当前属性，确定行是否变化，因为扩展列的原因，固定死uid
        rowKey: 'uid',
        locale: { emptyText: '暂无数据' },
      },
      // 使用配置的antd扩展属性覆盖现有属性
      this.props.antdProps,
      {
        // 使用当前分页
        pagination,
        // 是否为loading状态
        loading,
        //onChange: this.handleTableChange,
        // 展示行可拖动
        columns: newColumns,
        components: {
          header: {
            cell: ResizableTitle,
          },
        },
      },
    );
    if (scroll) {
      antdProps.scroll = scroll;
    } else {
      antdProps.scroll = { x: '100%' };
    }
    if (showExpandedRow) {
      antdProps.columns = [this.expendedRowColumn(), ...antdProps.columns];
      antdProps.expandable = Object.assign({
        expandedRowKeys: this.state.expandedRowKeys,
        expandIconColumnIndex: -1,
        expandedRowRender: (record: any) => (
          <p style={{ marginLeft: 30, marginBottom: 0, lineHeight: '20px' }}>
            {initExpandedDisplayFormater(
              this.props,
              record,
              antdProps.initRowFieldConfigs,
            )}
          </p>
        ),
      });
    }
    if (showAggr) {
      antdProps.summary = FooterSummary(
        formSchema,
        columns,
        antdProps.dataSource,
        null,
        showExpandedRow,
      );
    }

    // if (this.props.height) {
    //   var height = getScrollHeight(this.props, this.props.height);
    //   if (height > 0) {
    //    antdProps.scroll = Object.assign({ y: height }, antdProps.scroll);
    //   }
    // }
    return <Table {...antdProps} />;
  }
}

export { DetailTable };
