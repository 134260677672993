import React, { useEffect, useRef } from 'react';
import { DatePicker, Form, message } from 'antd';
import { ProFormInstance } from '@ant-design/pro-form';
import { WdiModalAppProps, wdiCore } from '@/components/WdiControls';
import { ResponseStatus } from '@/utils/request';

import { WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import moment from 'moment';


const SalesQueryApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  useEffect(() => {
    if (props.data) {
      formRef.current?.setFieldsValue({
        startDate: moment(props.data?.startDate, "YYYY-MM"),
        endDate: moment(props.data?.endDate, "YYYY-MM")
      });
    }


  }, [props.data]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      labelAlign='left'
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      modalProps={{
        onCancel: () => { props.onClose ? props.onClose() : null },
        destroyOnClose: true,
        maskClosable: false
      }}
      onFinish={async (values) => {
        const { startDate, endDate, ...entity } = values;

        const start = new Date(startDate);
        const end = new Date(endDate);

        if ((wdiCore.Date.Comp(start, end, "M") ?? 0) > 0) {
          message.error('起始年月不能大于截止年月!');
          return;
        }

        if (startDate) {
          entity.startDate = moment(startDate).format("YYYYMM");
        }

        if (endDate) {
          entity.endDate = moment(endDate).format("YYYYMM");
        }

        if (props.onSave) {
          const response: API.ResponseResult<any> = {
            code: ResponseStatus.SUCCESS,
            message: "",
            data: entity
          };
          props.onSave(response);
        }
        return true;
      }}
    >
      <Form.Item name="startDate" label="起始年月" rules={[{ required: true, message: '起始年月不能为空!' }]}>
        <DatePicker picker={"month"} format={"YYYY-MM"} placeholder={"请选择年月"} />
      </Form.Item>
      <Form.Item name="endDate" label="截止年月" rules={[{ required: true, message: '截止年月不能为空!' }]}>
        <DatePicker picker={"month"} format={"YYYY-MM"} placeholder={"请选择年月"} />
      </Form.Item>

    </WdiModalForm>
  );
};
export default SalesQueryApp;
