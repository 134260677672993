import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Input, message, Row,} from 'antd';
import {ProForm, ProFormInstance} from '@ant-design/pro-form';
import {WdiCardTitle, WdiInput, WdiModalAppResult} from '@/components/WdiControls';
import {FormPageProps} from '@/components/WdiBus/FormPage';
import {ApplyFooter, ApplyFooterType} from '@/components/WdiBus/ApplyFooter';
import {FileUploadUtility, WdiFileUpload} from '@/components/WdiControls/WdiFileUpload';
import {Enum} from '@/enums/system';
import {ResponseStatus} from '@/utils/request';
import {PageType} from '@/services/basic/pagetype';
import {FormSs} from '@/services/sales/ss';
import DataStore from '@/components/GridWidget/DataStore'
import {initApplyPageContext, ViewEntityProps} from '../../components/data';
import GeneralSettleDetailsForApplyApp from './details';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {WarningInfoAppProps} from "@/components/WdiBus/PanelForWarning";
import SsWarningInformation from "@/pages/sales/ss/components/SsWarningInformation";
import {BOHQ} from "@/services/mdm/bohq";

const {TextArea} = Input;
const getPageResult = (values: any, viewEntity: ViewEntityProps, pageType: API.PageType, statusId: number, dataSource: any[], bohqId: number) => {

  const result: WdiModalAppResult = {code: ResponseStatus.UNKNOWN};

  const {fileInfo, settleMaterial, ...entity} = values;

  if (FileUploadUtility.isUploading(fileInfo)) {
    result.message = '附件正在上传中!';
    return result;
  }

  if (FileUploadUtility.isError(fileInfo)) {
    result.message = '附件存在错误文件!';
    return result;
  }

  const req: { batchFormSsParam: any, formSs: any, formSsDetailParamList: any[] } = {
    batchFormSsParam: {
      statusId: statusId,
      pageTypeId: pageType.pageTypeId,
      pageGroupId: pageType.pageGroupId,
      formTypeId: viewEntity.formTypeId,
      formId: viewEntity.formId
    },
    formSs: {},
    formSsDetailParamList: []
  };

  if (viewEntity.formSs) {
    if (viewEntity.relationType === Enum.FormRelationType.REJECTED) {
      req.batchFormSsParam.relatedFormId = viewEntity.relatedFormId;
      req.batchFormSsParam.relationType = viewEntity.relationType;
    } else {
      req.batchFormSsParam.formId = viewEntity.formId;
    }
  }

  const info = FileUploadUtility.serialize(fileInfo);
  entity.fileName = info?.names;
  entity.filePath = info?.urls;
  entity.formSaId = viewEntity.formSaId;
  entity.formSsId = viewEntity.formSs?.formSsId;

  if (settleMaterial) {
    entity.settleMaterial = settleMaterial.join();
  }
  req.batchFormSsParam.fileName = entity.fileName;
  req.batchFormSsParam.filePath = entity.filePath;
  req.batchFormSsParam.bohqId = bohqId;
  req.batchFormSsParam.remark = values.remark;

  req.formSsDetailParamList = dataSource;

  let isOk = true;
  if (req.formSsDetailParamList && req.formSsDetailParamList.length > 0) {
    for (let i = 0; i < req.formSsDetailParamList.length; i++) {
      const detailRow = req.formSsDetailParamList[i];
      detailRow.rowNumber = i + 1;
      detailRow.formSsDetailId = detailRow.formSsDetailId || 0;
      detailRow.formSsId = detailRow.formSsId || 0;
    }
  } else {
    isOk = false;
    result.message = `费用明细不能为空`;
    return result;
  }
  if (!isOk) {
    return result;
  }

  result.data = req;
  result.code = ResponseStatus.SUCCESS;
  return result;
}

const GeneralSettleApplyApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [entity, setEntity] = useState<ViewEntityProps>();
  const [pageType, setPageType] = useState<API.PageType>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [bohqId, setBohqId] = useState<number>();
  const [pageTypeId, setPageTypeId] = useState<number>();
  const [warningSettings, setWarningSettings] = useState<WarningInfoAppProps>({visible: false});
  const [hiddenButton, setHiddenButton] = useState<boolean>(false);
  const onDetailsChange = (data: any) => {
    setDataSource(data);
  };
  
  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        if (params.pageTypeId) {
          await PageType.get(Number(params.pageTypeId)).then(res => {
            formRef.current?.setFieldsValue({
              pageTypeId: res?.data.pageTypeName
            })
          });
          setPageTypeId(params.pageTypeId);
        }
        if (params.bohqId) {
          await BOHQ.get(Number(params.bohqId)).then(res => {
            formRef.current?.setFieldsValue({
              bohqId: res?.data.bohqName
            })
          });
          setBohqId(params.bohqId);
          setPageTypeId(params.pageTypeId);
        }
        const result = await initApplyPageContext(params);
        const viewEntity: ViewEntityProps = result.data;
        if (viewEntity.relatedFormId || viewEntity.formId) {
          setHiddenButton(true);
        }
        if (viewEntity.formSs) {
          formRef.current?.setFieldsValue({
            remark: viewEntity.formSs.remark,
            fileInfo: FileUploadUtility.parse(viewEntity.formSs.fileName, viewEntity.formSs.filePath),
          });
        }
        if (viewEntity.pageTypeId && viewEntity.bohqId) {
          await PageType.get(Number(viewEntity.pageTypeId)).then(res => {
            formRef.current?.setFieldsValue({
              pageTypeId: res?.data.pageTypeName
            })
          });
          await BOHQ.get(Number(viewEntity.bohqId)).then(res => {
            formRef.current?.setFieldsValue({
              bohqId: res?.data.bohqName
            })
          });
          setBohqId(viewEntity.bohqId);
          setPageTypeId(viewEntity.pageTypeId);
        }
        await PageType.get(Enum.PageType.ACTIVITY_SETTLEMENT).then((response) => {
          setPageType(response.data);
        });
        if (viewEntity.formSsDetails) {
          setDataSource(DataStore.init(viewEntity.formSsDetails));
        }
        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle) {
      let title = "活动核销申请";
      if (entity && entity.formSs) {
        title += '【编辑】'
      }
      props.setPageTitle(title);
    }
  }, [props.setPageTitle, pageType, entity]);


  return (
    <>
      <SsWarningInformation {...warningSettings} />
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (
              <ApplyFooter
                setLoading={props.setLoading}
                hiddenButton={[ApplyFooterType.Draft]}
                viewEntity={entity}
                submitBackUrl="/sales/ss/MySettle"
                doSubmit={async (type: number, forceSubmit: boolean) => {
                  if (!entity || !pageType || !bohqId) {
                    return;
                  }
                  const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                  if (values && values.errorFields && values.errorFields.length) {
                    return;
                  }
                  const statusId = type === ApplyFooterType.Draft ? Enum.FormStatus.DRAFT : Enum.FormStatus.AWAITING;
                  const req = getPageResult(values, entity, pageType, statusId, dataSource, bohqId);
                  if (req.code !== ResponseStatus.SUCCESS) {
                    message.error(req.message);
                    return;
                  }
                  props?.setLoading?.(true);
                  if (type == ApplyFooterType.Validate) {
                    return await FormSs.Buttons.batchValidate({
                      forceSubmit: forceSubmit,
                      ...req.data
                    });
                  } else {
                    return await FormSs.Buttons.batchApply({
                      forceSubmit: forceSubmit,
                      ...req.data
                    });
                  }
                }}
                del={async () => {
                  if (!entity || !entity.formId) {
                    return;
                  }
                  return await FormSs.Buttons.del(entity.formId);
                }}
                showWarning={(result: WdiModalAppResult, submitCallback: any) => {
                  setWarningSettings({
                    visible: true,
                    title: "警示信息",
                    wdiPanelList: result.data,
                    onClose: () => {
                      setWarningSettings({visible: false});
                    },
                    onSave: async () => {
                      await submitCallback();
                    },
                  });
                }}
              />
            )
          }
        }}
      >
        <WdiPanel collapsed={false}
                  title={<WdiCardTitle iconType={Enum.IconType.FORM_SS} title='活动核销信息'/>}>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={4}>
              <Form.Item name="bohqId" label="费用归属">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="pageTypeId" label="单据类型">
                <WdiInput readOnly/>
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16}>
              <Form.Item name="remark" label="备注">
                <TextArea rows={4} maxLength={2000}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="fileInfo"
                label="附件"
                valuePropName='fileList'
                getValueFromEvent={(e: any) => {
                  return e.fileList || [];
                }}
              >
                <WdiFileUpload leftTopInfo="请选择文件并上传" multiple={true}/>
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <GeneralSettleDetailsForApplyApp
          data={{bohqId: bohqId, pageTypeId: pageTypeId, hidden: hiddenButton}}
          dataSource={dataSource}
          onChange={onDetailsChange}/>
      </ProForm>
    </>
  );
};
export default GeneralSettleApplyApp;

