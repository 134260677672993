import {formatter, request} from "@/utils/request";

export namespace FormSa {
  const Path = {
    get: '/activityapi/formSa/getFormSa',
    myActivityPrev: '/activityapi/formSa/myActivityPrev',
    getFormSaDetailGroup: '/activityapi/formSa/getFormSaDetailGroup',
    getSellInAndTargetInfos: '/activityapi/formSa/getSellInAndTargetInfos',
    getSellInAndTargetDetailInfos: '/activityapi/formSa/getSellInAndTargetDetailInfos',
    getSellOutInfosByShop: '/activityapi/formSa/getSellOutInfosByShop',
    getSellOutDetailInfosByShop: '/activityapi/formSa/getSellOutDetailInfosByShop',
    getSellOutDetailInfosByProduct: '/activityapi/formSa/getSellOutDetailInfosByProduct',
    getSellOutDetailInfosByEc: '/activityapi/formSa/getSellOutDetailInfosByEc',
    BatchClose: '/activityapi/saButtons/batchClose',
    ImportValidate: '/activityapi/saButtons/importValidate',
    ImportClose: '/activityapi/saButtons/importClose',
  };

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.FormSa>>(Path.get, formatter(options));
  };

  export async function myActivityPrev(body: API.ActivityPrev) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.QueryResult<API.ActivityPrev>>(Path.myActivityPrev, formatter(options));
  };

  export async function getFormSaDetailGroup(body: { formId: number, groupBy: string }) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.getFormSaDetailGroup, formatter(options));
  };

  export async function getSellInAndTargetInfos(body: { formSaId: number, startDate: string, endDate: string }) {
    const options = {
      method: 'POST',
      body: body
    };
    return request<API.ResponseResult<any[]>>(Path.getSellInAndTargetInfos, formatter(options));
  };

  export async function getSellInAndTargetDetailInfos(body: { soldToCode: string, fpStart: number, fpEnd: number }) {
    const options = {
      method: 'POST',
      body: body
    };
    return request<API.ResponseResult<any[]>>(Path.getSellInAndTargetDetailInfos, formatter(options));
  };

  export async function getSellOutInfosByShop(body: { formSaId: number, startDate: string, endDate: string }) {
    const options = {
      method: 'POST',
      body: body
    };
    return request<API.ResponseResult<any[]>>(Path.getSellOutInfosByShop, formatter(options));
  };

  export async function getSellOutDetailInfosByShop(body: { shopId: number, fpStart: number, fpEnd: number }) {
    const options = {
      method: 'POST',
      body: body
    };
    return request<API.ResponseResult<any[]>>(Path.getSellOutDetailInfosByShop, formatter(options));
  };

  export async function getSellOutDetailInfosByProduct(body: { shopId: number, fpStart: number, fpEnd: number }) {
    const options = {
      method: 'POST',
      body: body
    };
    return request<API.ResponseResult<any[]>>(Path.getSellOutDetailInfosByProduct, formatter(options));
  };

  export async function getSellOutDetailInfosByEc(body: { shopId: number, fpStart: number, fpEnd: number }) {
    const options = {
      method: 'POST',
      body: body
    };
    return request<API.ResponseResult<any[]>>(Path.getSellOutDetailInfosByEc, formatter(options));
  };

  export async function batchClose(ids: string) {
    const options = {
      method: 'GET',
      body: {
        ids: ids,
      },
    };
    return request<API.ResponseResult<any>>(Path.BatchClose, formatter(options));
  }

  export namespace Buttons {
    export const Path = {
      get: '/bpapi/formBudgetProject/get',
      getHistory: '/bpapi/formBudgetProject/getHistory',
      validateEcPicker: '/activityapi/saButtons/validateEcPicker',
      validateDetail: '/activityapi/saButtons/validateDetail',
      apply: '/activityapi/saButtons/apply',
      validate: '/activityapi/saButtons/validate',
      delete: '/activityapi/saButtons/delete',
      BUDGET_INFOS: '/activityapi/saButtons/budgetInfos',
      WARNING_INFOS: '/activityapi/saButtons/warningInfos',
      COUNT_OF_WARNING: '/activityapi/saButtons/countOfWarning',
      DYNAMIC_INFOS: '/activityapi/saButtons/dynamicInfos',
      completeDelivery: '/activityapi/saButtons/completeDelivery',
      getFormSaDetailCompareCollections: '/activityapi/saButtons/getFormSaDetailCompareCollections',
      downloadTempByEc: '/activityapi/saButtons/downloadTempByEc',
    };

    export async function getButtons(body: API.ButtonArgs) {
      const options = {
        method: 'POST',
        body: body
      };

      return request<API.ResponseResult<API.Button>>('/Api/SAButtons/Get', formatter(options));
    };

    export async function validatePicker(body: API.DoFormSADetailDTO) {
      const options = {
        method: 'POST',
        body: body
      };

      return request<API.ResponseResult<API.FormSaDetailVo>>(Path.validateEcPicker, formatter(options));
    };

    export async function validateDetail(body: {
      rowIndex: number,
      formSa: any,
      ecId?: any,
      formSaDetail: any,
      formSaDetails: any[],
    }) {
      const options = {
        method: 'POST',
        body: body
      };

      return request<API.ResponseResult<API.FormSaDetailVo>>(Path.validateDetail, formatter(options));
    };

    export async function validate(body: { form: any, formSa: any, formSaDetails: any[] }) {
      const options = {
        method: 'POST',
        body: body
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.validate, formatter(options));
    };

    export async function apply(body: { form: any, formSa: any, formSaDetails: any[] }) {
      const options = {
        method: 'POST',
        body: body
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.apply, formatter(options));
    };

    export async function del(formId: number) {
      const options = {
        method: 'POST',
        body: {formId: formId}
      };
      return request<API.ResponseResult<any>>(Path.delete, formatter(options));
    };

    export async function budgetInfos(formId: number) {
      const options = {
        method: 'GET',
        body: {formId}
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.BUDGET_INFOS, formatter(options));
    };

    export async function warningInfos(formId: number) {
      const options = {
        method: 'GET',
        body: {
          formId: formId,
        },
      };
      return request<API.ResponseResult<API.WdiPanelList>>(Path.WARNING_INFOS, formatter(options));
    };

    export async function countOfWarning(formId: number) {
      const options = {
        method: 'GET',
        body: {
          formId: formId,
        },
      };
      return request<API.ResponseResult<number>>(Path.COUNT_OF_WARNING, formatter(options));
    };

    export async function dynamicInfos(formId: number) {
      const options = {
        method: 'Get',
        body: {formId}
      };
      return request<API.DynamicInformation[]>(Path.DYNAMIC_INFOS, formatter(options));
    };

    export async function completeDelivery(body: API.ButtonArgs) {
      const options = {
        method: 'POST',
        body: body
      };

      return request<API.ResponseResult<any>>(Path.completeDelivery, formatter(options));
    };

    export async function getFormSaDetailCompareCollections(body: { formId: number, relatedFormId: number }) {
      const options = {
        method: 'GET',
        body: {
          ...body
        },
      };
      return request<API.ResponseResult<API.FormSaDetailCompareCollections>>(Path.getFormSaDetailCompareCollections, formatter(options));
    };
  }
  ;

  export async function importValidate(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ImportResult>>(Path.ImportValidate, formatter(options));
  }

  export async function importClose(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.ImportResult>>(Path.ImportClose, formatter(options));
  }

}

export namespace FormSaDetail {
  export const Path = {
    get: '/activityapi/formSa/getFormSaDetail',
    getEx: '/activityapi/formSa/getFormSaDetailEx',
    gets: '/activityapi/formSa/getFormSaDetails',
    getsEx: '/activityapi/formSa/getFormSaDetailsEx',
    getFgEx: '/activityapi/formSa/getFgFormSaDetailsEx'
  };

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.FormSaDetail>>(Path.get, formatter(options));
  }

  export async function getEx(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.FormSaDetailVo>>(Path.getEx, formatter(options));
  }

  export async function gets(formSaId: number) {
    const options = {
      method: 'GET',
      body: {
        formSaId: formSaId,
      },
    };
    return request<API.ResponseResult<API.FormSaDetail[]>>(Path.gets, formatter(options));
  }

  export async function getsEx(formSaId: number) {
    const options = {
      method: 'GET',
      body: {
        formSaId: formSaId,
      },
    };
    return request<API.ResponseResult<API.FormSaDetailVo[]>>(Path.getsEx, formatter(options));
  }

  export async function getFgEx(formSaId: number) {
    const options = {
      method: 'GET',
      body: {
        formSaId: formSaId,
      },
    };
    return request<API.ResponseResult<API.FormSaDetailVo[]>>(Path.getFgEx, formatter(options));
  }
}
