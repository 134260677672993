import {wdiCore, WdiInput, WdiModalAppProps} from "@/components/WdiControls";
import {HorzLayoutOpts, WdiModalForm} from "@/components/WdiControls/WdiModalForm";
import {WdiSelect, WdiSelectFormat} from "@/components/WdiControls/WdiSelect";
import {Form, Input, message} from "antd";
import {ResponseStatus} from "@/utils/request";
import {Platform} from "@/services/mdm/platform";
import {District} from "@/services/mdm/district";
import {WdiInputNumber} from "@/components/WdiControls/WdiInputNumber";
import React, {useEffect, useRef, useState} from "react";
import {ProFormInstance} from "@ant-design/pro-form";
import {FormSp} from "@/services/sales/sp";
import {DataOptions} from "@/services/basic/metadata";
import {VatType} from "@/services/mdm/vattype";
import {Enum} from "@/enums/system";

const FormDetailEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const {row, formSp, rowIndex, rows} = props.data;
  const [showPlatform, setShowPlatform] = useState<boolean>(false);
  const [showDistrict, setShowDistrict] = useState<boolean>(false);
  const [showCustSys, setShowCustSys] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  useEffect(() => {
    if (formSp) {
      formSp.pageTypeId = Enum.PageType.PAYMENT_CUST_SYS;
    }
    if (row) {
      formRef.current?.setFieldsValue({
        promotionDesc: row.promotionDesc,
        productBuyNames: row.productBuyNames,
        amountSa: row.amountSa,
        amountPaid: row.amountPaid,
        amountSs: row.amountSs,
        ecName: row.ecName,
        soldToName: wdiCore.String.Join(row.soldToCode, row.soldToName),
        remark: row.remark,
        amountExVat: row.amountExVat,
        paymentMethodId: row.paymentMethodId,
        vatTypeId: row.vatTypeId,
        invoiceType: row.invoiceType,
      });
      if (row.paymentMethodId == Enum.PaymentMethod.BILL_DEDUCT) {
        setReadOnly(true);
      }

      if (row.platformId) {
        setShowPlatform(true);
        formRef.current?.setFieldsValue({platformId: row.platformId});
      }
      if (row.districtId) {
        setShowDistrict(true);
        formRef.current?.setFieldsValue({districtId: row.districtId});
      }
      if (row.custSysCode) {
        setShowCustSys(true);
        formRef.current?.setFieldsValue({custSysName: wdiCore.String.Join(row.custSysCode, row.custSysName),});
      }
      formSp.formTypeId = Enum.FormType.ACTIVITY_PAYMENT;
    }
  }, [props.data]);

  return (
    <>
      <WdiModalForm
        formRef={formRef}
        initialValues={{undertakeRate: 1}}
        title={props.title}
        visible={props.visible}
        modalProps={{
          onCancel: () => {
            props.onClose?.()
          },
        }}
        onFinish={async (values) => {
          const entity: any = {
            remark: values.remark,
            amountExVat: values.amountExVat,
            paymentMethodId: values.paymentMethodId,
            invoiceType: values.invoiceType,
            vatTypeId: values.vatTypeId
          };

          const response = await FormSp.Buttons.validateDetail({
            rowIndex: rowIndex,
            formSp: {...formSp},
            formSpDetail: Object.assign({}, row, entity),
            formSpDetails: rows
          });
          if (response.code !== ResponseStatus.SUCCESS) {
            message.error(response.message);
            return;
          }
          if (props.onSave) {
            response.data = Object.assign({}, {rowIndex: rowIndex}, response.data);
            props.onSave(response);
          }
        }}
        {...HorzLayoutOpts}
      >
        <Form.Item name="soldToName" label="售达方">
          <Input readOnly/>
        </Form.Item>
        {showCustSys &&
          <Form.Item name="custSysName" label="客户集团">
            <Input readOnly/>
          </Form.Item>
        }
        {
          showPlatform &&
          <Form.Item name="platformId" label="分子平台">
            <WdiSelect {...{
              placeholder: "请选择分子平台",
              showSearch: true,
              remote: true,
              disabled: true,
              dataLoading: async (params) => {
                const response = await Platform.ajaxSearch(Object.assign({}, params));
                return response.data.map((item) => ({value: item.platformId, label: item.codeOrName}));
              }
            }} />
          </Form.Item>
        }
        {
          showDistrict &&
          <Form.Item name="districtId" label="省份">
            <WdiSelect {...{
              placeholder: "请选择省份",
              showSearch: true,
              remote: true,
              disabled: true,
              dataLoading: async (params) => {
                const response = await District.search(Object.assign({}, params));
                return response.data.map((item) => ({value: item.districtId, label: item.districtName}));
              }
            }} />
          </Form.Item>
        }
        <Form.Item name="productBuyNames" label="产品">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="ecName" label="费用项">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="promotionDesc" label="活动机制">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="paymentMethodId" label="支付方式" rules={[{required: true}]}>
          <WdiSelect {...{
            placeholder: "请选择支付方式",
            showSearch: true,
            dataLoading: async (params) => {
              const response = await DataOptions.search(Object.assign({typeGroup: '5', inValues: '2,3,5,6'}, params));
              return response.data.map((item) => ({value: item.value, label: item.name}));
            },
            onChange: (params: any) => {
              if (WdiSelectFormat.getvalue(params) == Enum.PaymentMethod.BILL_DEDUCT) {
                setReadOnly(true);
                formRef.current?.setFieldsValue({
                  vatTypeId: null,
                  invoiceType: null,
                })
              } else {
                setReadOnly(false);
              }
            }
          }} />
        </Form.Item>
        <Form.Item name="invoiceType" label="发票类型" rules={[{required: !readOnly}]}>
          <WdiSelect {...{
            placeholder: "请选择发票类型",
            showSearch: true,
            disabled: readOnly,
            dataLoading: async (params) => {
              const response = await DataOptions.search(Object.assign({typeGroup: '106'}, params));
              return response.data.map((item) => ({value: item.value, label: item.name}));
            }
          }} />
        </Form.Item>
        <Form.Item name="vatTypeId" label="税率类型" rules={[{required: !readOnly}]}>
          <WdiSelect {...{
            placeholder: "请选择税率类型",
            showSearch: true,
            disabled: readOnly,
            dataLoading: async (params) => {
              const response = await VatType.search(Object.assign({}, params));
              return response.data.map((item) => ({value: item.vatTypeId, label: item.vatTypeName}));
            },
          }} />
        </Form.Item>

        <Form.Item name="amountSa" label="申请金额">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="amountSs" label="核销金额">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="amountPaid" label="已付款金额">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="amountExVat" label="付款金额" rules={[{required: true}]}>
          <WdiInputNumber format='d' digits={2} min={0}/>
        </Form.Item>
        <Form.Item name="remark" label="核销备注">
          <Input.TextArea rows={3}/>
        </Form.Item>
      </WdiModalForm>

    </>
  )
}
export default FormDetailEditApp;
