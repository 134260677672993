import React from 'react';
import {Dropdown, Menu, Space} from 'antd';
import {UnorderedListOutlined} from '@ant-design/icons';
import {NsGrid} from './GridDefProps';
import {wdiCore} from '../WdiControls';
import {getFilterStates, NsGridFilter} from './GridWidgetFilter';
import {FieldUtility} from './GridWidgetUtility';

type InitViewStateProps = {
  viewMode?: number;
  viewObject: API.ViewObject;
  viewDefs: API.ViewDef[];
}

export type ViewState = {
  viewMode: number;
  viewObject: API.ViewObject;
  viewDefs: API.ViewDef[];
  viewKey: number;
}

export const initViewState = (props: InitViewStateProps) => {
  const viewState: ViewState = {
    viewMode: NsGrid.ViewMode.None, //0, 1, 2
    viewObject: props.viewObject,
    viewDefs: props.viewDefs,
    viewKey: 0,
  };

  if (props.viewMode === NsGrid.ViewMode.Advance) {
    viewState.viewMode = NsGrid.ViewMode.Advance;
  } else if (props.viewMode === NsGrid.ViewMode.Simple) {
    viewState.viewMode = NsGrid.ViewMode.Simple;
  }
  return viewState;
};

type InitFilterStateProps = {
  filterFormat: number;
  formSchema: API.FormSchema;
  viewObject: API.ViewObject;
};

const isFilterColumn = (column: API.FieldObject) => {
  return !wdiCore.Object.IsNullOrUndefined(column) && !column.isDisabled && column.isFilterable;
};

export const initFilterStates = (props: InitFilterStateProps, grid: any) => {
  let filterStates: NsGridFilter.IBasicFilter[] = [];
  if (props.filterFormat == NsGrid.FilterFormat.Default || props.filterFormat == NsGrid.FilterFormat.Expand) {
    const allFields = props.formSchema.allFields;
    const viewObject = props.viewObject;
    const columns = [];
    if (viewObject && viewObject.filterColumns && viewObject.filterColumns.length) {
      viewObject.filterColumns.forEach((item: string) => {
        let column: API.FieldObject = allFields[item];
        if (isFilterColumn(column)) {
          columns.push(column.name);
        }
      });
    } else {
      for (let key in allFields) {
        const column: API.FieldObject = allFields[key];
        if (isFilterColumn(column)) {
          columns.push(column.name);
        }
      }
    }
    filterStates = getFilterStates(grid, allFields, columns, {});
  }
  return filterStates;
};

const findItem = (filterStates: any, itemKey: string) => {
  let result: any;
  (filterStates ? filterStates : []).forEach((item: any) => {
    if (item.code == itemKey) {
      result = item;
    }
  });
  return result;
};

export const initDefaultFilterValues = (filterStates: any, defaultFilterValues: any) => {
  if (!wdiCore.Object.IsNullOrUndefined(defaultFilterValues)) {
    let filterOptions = defaultFilterValues;
    if (typeof defaultFilterValues === 'string' && defaultFilterValues != '') {
      filterOptions = JSON.parse(defaultFilterValues);
    }
    if (filterOptions && filterOptions.length) {
      filterOptions.forEach((item: any) => {
        const filterState = findItem(filterStates, item.field);
        if (filterState) {
          filterState.setDefaultValue(item);
        }
      });
    }
  }
};

const isDispColumn = (column: API.FieldObject) => {
  return !wdiCore.Object.IsNullOrUndefined(column) && !column.isDisabled && !column.isHidden;
};

const getTableColumns = (formSchema: API.FormSchema, viewObject: API.ViewObject) => {
  const columns = [];
  const allFields = formSchema.allFields;
  if (viewObject && viewObject.viewColumns && viewObject.viewColumns.length) {
    viewObject.viewColumns.forEach((item: string) => {
      const column = allFields[item];
      if (isDispColumn(column)) {
        columns.push(column.name);
      }
    });
  } else if (formSchema.viewFieldNames && formSchema.viewFieldNames.length) {
    formSchema.viewFieldNames.forEach((item: string) => {
      const column = allFields[item];
      if (isDispColumn(column)) {
        columns.push(column.name);
      }
    });
  } else {
    for (let key in allFields) {
      const column = allFields[key];
      if (isDispColumn(column)) {
        columns.push(column.name);
      }
    }
  }
  return columns;
};

type InitColumnStatesProps = {
  formSchema: API.FormSchema;
  actionsAlign?: number;
  showItemCount?: number;
  // 函数不确定类型
  // 待完善
  // TODO by LFQ
  actionsCellsRenderer?: any;
  // 系统中的actions 根据 record 过滤
  filterMenuActions?: (actions: API.ActionObject[], record: any) => API.ActionObject[];
  // Grid 列表宽度, 根据总宽度设置单列的宽度, 和整体滚动条
  width?: number;
  // 扩展列
  // 待完善
  // TODO by LFQ
  additionallNames?: any;
}

export const initColumnStates = (props: InitColumnStatesProps, viewObject: API.ViewObject, grid: any) => {
  const columns = getTableColumns(props.formSchema, viewObject);
  if (props.additionallNames) {
    props.additionallNames.forEach((item: string | number) => {
      if (columns.indexOf(String(item)) < 0) {
        columns.push(String(item));
      }
    });
  }
  return convertToClolumns(props, columns, grid);
};

const convertToTableColumn = (fieldObject: API.FieldObject) => {
  const column: Record<string, any> = {
    title: fieldObject.displayName,
    dataIndex: fieldObject.name,
    key: fieldObject.name,
    ellipsis: true,
    sorter: false,
  };

  if (fieldObject.isSortable) {
    column.sorter = true;
    column.title = <span title={fieldObject.displayName}>{fieldObject.displayName}</span>;
  }

  if (!wdiCore.String.IsNullOrEmpty(fieldObject.type)) {
    const fieldCellFunc = FieldUtility[fieldObject.type];
    if (!wdiCore.Object.IsNullOrUndefined(fieldCellFunc)) {
      column.render = fieldCellFunc(fieldObject);
    }
  }

  if (!wdiCore.Object.IsNullOrUndefined(fieldObject.getDisplayFormater)) {
    column.render = fieldObject.getDisplayFormater;
  }

  if (!wdiCore.Object.IsNullOrUndefined(fieldObject.width) && fieldObject.width > 0) {
    column.width = fieldObject.width;
  }

  if (fieldObject.antdProps) {
    for (var key in fieldObject.antdProps) {
      column[key] = fieldObject.antdProps[key];
    }
  }
  return column;
};

type ActionProps = {
  actions: API.ActionObject[];
  showItemCount: number;
  record: any;
  itemIndex: number;
  filterMenuActions?: (actions: API.ActionObject[], record: any) => API.ActionObject[];
  grid: any;
};

const Action: React.FC<ActionProps> = (props: ActionProps) => {

  const handleClick = (action: API.ActionObject) => {
    if (action.actionClick) {
      const { record, itemIndex, grid } = props;
      return action.actionClick(grid, action, record, itemIndex);
    }
  };

  const { showItemCount, record, filterMenuActions } = props;
  let actions = props.actions;
  if (filterMenuActions) {
    actions = filterMenuActions(props.actions, record);
  }

  return (
    <Space size='middle'>
      {actions.slice(0, Math.min(showItemCount, actions.length)).map((action) => (
        <a onClick={() => handleClick(action)} key={action.name}>
          {action.displayName}
        </a>
      ))}
      {actions.length > showItemCount && (
        <Dropdown
          overlay={
            <Menu
              items={actions.slice(Math.min(showItemCount, actions.length)).map((action) => ({
                key: action.name,
                label: action.displayName,
                onClick: () => handleClick(action),
              }))}
            />
          }
        >
          <a>
            <UnorderedListOutlined/>
          </a>
        </Dropdown>
      )}
    </Space>
  );
};

const createActionColumn = (props: InitColumnStatesProps, grid: any) => {
  let column: any = null;
  if (props.formSchema.actions && props.formSchema.actions.length) {
    column = {
      title: '操作',
      dataIndex: 'GridWidgetAction',
      key: 'GridWidgetAction',
      sorter: false,
      width: 75,
    };
    let showItemCount = 1;
    if (props.showItemCount) {
      showItemCount = props.showItemCount;
    }
    column.render = (text: any, record: any, index: number) => (
      <Action
        {...{
          actions: props.formSchema.actions,
          showItemCount,
          record,
          itemIndex: index,
          grid: grid,
          filterMenuActions: props.filterMenuActions,
        }}
      />
    );
    if (props.actionsCellsRenderer) {
      column.render = props.actionsCellsRenderer;
    }
  }
  return column;
};

const convertToClolumns = (props: InitColumnStatesProps, names: string[], grid: any) => {
  let viewColumns: any[] = [];
  if (names) {
    names.forEach((item: string) => {
      const fieldObject = props.formSchema.allFields[item];
      if (!wdiCore.Object.IsNullOrUndefined(fieldObject)) {
        viewColumns.push(convertToTableColumn(fieldObject));
      }
    });
  }

  if (props.formSchema.actions && props.formSchema.actions.length) {
    //1在左边， 2在右边
    if (props.actionsAlign == NsGrid.ActionsAlign.Left) {
      viewColumns = [createActionColumn(props, grid)].concat(viewColumns);
    } else if (props.actionsAlign == NsGrid.ActionsAlign.Right) {
      viewColumns.push(createActionColumn(props, grid));
    }
  }
  // return Utility.fitColumnWidth(viewColumns, settings);
  return viewColumns;
};

type InitMetaProps = {
  formSchema: API.FormSchema;
  viewObject: API.ViewObject;
  // Grid 列表宽度, 根据总宽度设置单列的宽度, 和整体滚动条
  width?: number;
};

export type MetaValue = {
  headers?: string[];
  rows?: string[];
  columns?: string[];
  values?: string[];
};

export const initMetaStates = (props: InitMetaProps, grid: any) => {
  return props.viewObject.meta;
};
