import { useEffect, useState } from 'react';
import { DetailGrid } from '@/components/GridWidget';
import { NsGrid } from '@/components/GridWidget/GridDefProps';
import { WdiCardTitle } from '@/components/WdiControls';
import { initFieldDisplayFormater } from '@/components/WdiBus/TableColumnRanderFormats'
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { Enum } from '@/enums/system';
import { GridApi } from '@/services/gridapi';


export type DetailForApplyProps = {
  pid?: string | number
  data?: any,
  dataSource: any[],
  onChange?: (dataSource: any) => void;
};

const SalesContractTermsForApproval: React.FC<DetailForApplyProps> = (props) => {
  // Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const { data, dataSource } = props;

  useEffect(() => {
    var formDefID = data.contractType == Enum.SalesContractType.SELLOUT ? "SalesContractTerms2ForApproval" : "SalesContractTermsForApproval";
    GridApi.searchConfig({ id: formDefID, allowModifyColumns: true }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        rowKey: 'uid',
        actionsAlign: NsGrid.ActionsAlign.None,
        showExpandedRow: true,
      });
      settings.formSchema.allFields.rowNumber.getDisplayFormater = (_t: any, record: any, index: any) => {
        return `${record.rowNumber || index + 1}`;
      };
      initFieldDisplayFormater(settings.formSchema.allFields);
      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.ecName.width = 150;
      settings.formSchema.allFields.processModeName.width = 100;
      settings.formSchema.allFields.revenueTypeName.width = 160;
      settings.formSchema.allFields.productNamesRebate.width = 300;
      settings.formSchema.allFields.settlementFrequencyName.width = 80;
      settings.formSchema.allFields.settlementRateDesc.width = 180;
      settings.formSchema.allFields.termsAmount.width = 100;

      settings.formSchema.allFields.rateOriginal.width = 120;
      settings.formSchema.allFields.productNamesReach.width = 100;
      settings.formSchema.allFields.createTypeName.width = 100;
      settings.formSchema.allFields.remark.width = 100;
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);
  let maxAllowedFields = ['rateOriginal', 'productNamesRebate', 'productNamesReach', 'remark'];
  if (data.contractType == Enum.SalesContractType.SELLOUT) {
    maxAllowedFields = [
      'rateOriginal',
      'productNamesRebate',
      'productNamesReach',
      'payToCode',
      'remark',
    ];
  }
  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => { return maxAllowedFields; },
  };

  return (
    <WdiPanel collapsed={true} title={<WdiCardTitle iconType={Enum.IconType.CONTRACT_TERM} title='合同条款' />}>
      {
        isLoaded &&
        <DetailGrid {...pageGridSettings} autoColumn={['rowNumber']} />
      }
    </WdiPanel >
  );
};
export default SalesContractTermsForApproval;
