import React, {useEffect, useRef, useState} from "react";
import {wdiCore, WdiInput, WdiModalAppProps} from "@/components/WdiControls";
import {ProFormInstance} from "@ant-design/pro-form";
import {HorzLayoutOpts, WdiModalForm} from "@/components/WdiControls/WdiModalForm";
import {ResponseStatus} from "@/utils/request";
import {Form, Input, message} from "antd";
import {WdiSelect} from "@/components/WdiControls/WdiSelect";
import {Platform} from "@/services/mdm/platform";
import {District} from "@/services/mdm/district";
import {WdiInputNumber} from "@/components/WdiControls/WdiInputNumber";
import {FormSs} from "@/services/sales/ss";

const SoldToFormDetailNoPriceEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const {row, pageTypeId, rowIndex} = props.data;
  const [showPlatform, setShowPlatform] = useState<boolean>(false);
  const [showDistrict, setShowDistrict] = useState<boolean>(false);


  useEffect(() => {

    if (row) {
      formRef.current?.setFieldsValue({
        promotionDesc: row.promotionDesc,
        productBuyNames: row.productBuyNames,
        amountSa: row.amountSa,
        ecName: row.ecName,
        revenueTypeName: row.revenueTypeName,
        soldToName: wdiCore.String.Join(row.soldToCode, row.soldToName),
        custSysName: wdiCore.String.Join(row.custSysCode, row.custSysName),
        remark: row.remark,
        quantityActual: row.quantityActual,
        amountExVat: row.amountExVat,
      });

      if (row.platformId) {
        setShowPlatform(true);
        formRef.current?.setFieldsValue({platformId: row.platformId});
      }
      if (row.districtId) {
        setShowDistrict(true);
        formRef.current?.setFieldsValue({districtId: row.districtId});
      }
    }
  }, [props.data]);

  return (
    <>
      <WdiModalForm
        formRef={formRef}
        initialValues={{undertakeRate: 1}}
        title={props.title}
        visible={props.visible}
        modalProps={{
          onCancel: () => {
            props.onClose?.()
          },
        }}
        onFinish={async (values) => {
          row.amountExVat = values.amountExVat;
          row.amount = values.amountExVat;
          row.remark = values.remark;
          const response = await FormSs.Buttons.validateDetail({
            pageTypeId: pageTypeId,
            formSsDetail: row
          });
          if (response.code !== ResponseStatus.SUCCESS) {
            message.error(response.message);
            return;
          }
          if (props.onSave) {
            response.data = Object.assign({}, {rowIndex: rowIndex}, response.data);
            props.onSave(response);
          }
        }}
        {...HorzLayoutOpts}
      >
        <Form.Item name="soldToName" label="售达方">
          <Input readOnly/>
        </Form.Item>
        {
          showPlatform &&
          <Form.Item name="platformId" label="分子平台">
            <WdiSelect {...{
              placeholder: "请选择分子平台",
              showSearch: true,
              remote: true,
              disabled: true,
              dataLoading: async (params) => {
                const response = await Platform.ajaxSearch(Object.assign({}, params));
                return response.data.map((item) => ({value: item.platformId, label: item.codeOrName}));
              }
            }} />
          </Form.Item>
        }
        {
          showDistrict &&
          <Form.Item name="districtId" label="省份">
            <WdiSelect {...{
              placeholder: "请选择省份",
              showSearch: true,
              remote: true,
              disabled: true,
              dataLoading: async (params) => {
                const response = await District.search(Object.assign({}, params));
                return response.data.map((item) => ({value: item.districtId, label: item.districtName}));
              }
            }} />
          </Form.Item>
        }
        <Form.Item name="productBuyNames" label="产品别">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="ecName" label="费用项">
          <Input readOnly/>
        </Form.Item>
        <Form.Item name="amountSa" label="申请金额">
          <WdiInput readOnly={true}/>
        </Form.Item>
        <Form.Item name="amountExVat" label="核销金额" rules={[{required: true}]}>
          <WdiInputNumber format='d' digits={2}/>
        </Form.Item>
        <Form.Item name="remark" label="核销备注">
          <Input.TextArea rows={3}/>
        </Form.Item>
        <Form.Item name="promotionDesc" label="活动机制">
          <WdiInput readOnly={true}/>
        </Form.Item>
      </WdiModalForm>
    </>
  )
}

export default SoldToFormDetailNoPriceEditApp;
