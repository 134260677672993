import { formatter, request } from "@/utils/request";

export namespace RebateInfo {
  export const Path = {
    QueryPaymentForSelect: '/reportapi/rebateInfo/queryPaymentForSelect',
    ExportPaymentForSelect: '/reportapi/rebateInfo/exportPaymentForSelect',
    DownLoadRebateInfoPicker: '/reportapi/rebateInfo/downLoadRebateInfoPicker',
    QueryReimbursementForSelect: '/reportapi/rebateInfo/queryReimbursementForSelect'      
  }
  export async function queryPaymentForSelect(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.QueryResult<any>>(Path.QueryPaymentForSelect, formatter(options));
  }
 

  export async function queryReimbursementForSelect(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.QueryResult<any>>(Path.QueryReimbursementForSelect, formatter(options));
  }
  
}

export namespace RebatePolicy {
  export const Path = {
    QueryForSelect: '/reportapi/rebatePolicy/queryForSelect',
  }
  export async function queryForSelect(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.QueryResult<any>>(Path.QueryForSelect, formatter(options));
  }
}

export namespace RebateFreezer {
  export const Path = {
    QueryForSelect: '/reportapi/rebateFreezer/queryForSelect',
  }
  export async function queryForSelect(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.QueryResult<any>>(Path.QueryForSelect, formatter(options));
  }
}