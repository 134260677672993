import { formatter, request } from '@/utils/request';

export namespace FormDiscussion {
  export const Path = {
    Search: '/basicapi/formDiscussion/search',
    Post: '/basicapi/formDiscussion/post',
    Delete: '/basicapi/formDiscussion/delete'
  }
  //请求API获得菜单
  /**获取讨论 */
  export async function search(buId: number, formId: number) {
    const options = {
      method: 'Get',
      body: {
        buId,
        formId
      },
    };

    return request<API.ResponseResult<API.FormDiscussion[]>>(Path.Search, formatter(options));
  }

  /**提交讨论*/
  export async function post(body: API.FormDiscussionReq) {
    const options = {
      method: 'Post',
      body: body,
    };

    return request<API.ResponseResult<API.FormDiscussion>>(Path.Post, formatter(options));
  }

  /**删除讨论 */
  export async function del(id: number) {
    const options = {
      method: 'Get',
      body: {
        id
      },
    };
    return request<API.ResponseResult<boolean>>(Path.Delete, formatter(options));
  }
};
