import { useEffect, useRef, useState } from 'react';
import { WdiSelect, WdiSelectFormat } from '@/components/WdiControls/WdiSelect';
import { Form, Input, message } from 'antd';
import { ResponseStatus } from '@/utils/request';
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { Product } from '@/services/mdm/product';
import { ProFormInstance } from '@ant-design/pro-form';
import { HorzLayoutOpts, WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { FormSa } from '@/services/sales/sa';
import { WdiModalAppProps, wdiCore } from '@/components/WdiControls';
import { ProductPrice } from '@/services/mdm/productprice';
import { ShipTo } from '@/services/mdm/shipto';
import { getDetailPrevData } from '../../components/data';
import { Enum } from '@/enums/system';
import { Persistence } from '@/services/sales/financial/periodconfig';

const PdDiscountDetailApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const { formSaDetail, formSa, rowIndex, rows } = props.data;
  const [buConfig, setConfig] = useState<API.BuConfig>();

  const handleProductChange = (value: any) => {
    formRef.current?.setFieldsValue({ priceSellinBefore: null });
    if (value && formSaDetail.soldToCode) {
      ProductPrice.getProductPrice({ productId: value, soldToCode: formSaDetail.soldToCode }).then(response => {
        if (response?.code === ResponseStatus.SUCCESS && response.data) {
          formRef.current?.setFieldsValue({ priceSellinBefore: response.data.priceSellin });
          handleCalcdiscountAmountSellin();
          handleCalcAmount();
        } else {
          message.error('找不到产品供货价！');
          return;
        }
      });
    }
  };
  const handleCalcdiscountAmountSellin = () => {
    const fields = formRef.current?.getFieldsValue();
    formRef.current?.setFieldsValue({ discountAmountSellin: null });
    if (fields.priceSellinBefore && fields.priceSellinAfter) {
      formRef.current?.setFieldsValue({ discountAmountSellin: wdiCore.Number.ToNumber(fields.priceSellinBefore - fields.priceSellinAfter, 2) });
    }
  };
  const handleCalcrevenueSellinForecast = () => {
    const fields = formRef.current?.getFieldsValue();
    formRef.current?.setFieldsValue({ revenueSellinForecast: null });
    if (fields.priceSellinAfter && fields.quantitySellinForecast) {
      formRef.current?.setFieldsValue({ revenueSellinForecast: wdiCore.Number.ToNumber(fields.priceSellinAfter * fields.quantitySellinForecast, 2) });
    }
  };
  const handleCalcAmount = () => {
    const fields = formRef.current?.getFieldsValue();
    formRef.current?.setFieldsValue({ amount: null });
    if (fields.priceSellinBefore && fields.priceSellinAfter && fields.quantitySellinForecast) {
      formRef.current?.setFieldsValue({ amount: wdiCore.Number.ToNumber((fields.priceSellinBefore - fields.priceSellinAfter) * fields.quantitySellinForecast) });
    }
  };

  useEffect(() => {
    Persistence.getBuConfig().then(response => { setConfig(response.data); });
    if (formSaDetail) {
      formRef.current?.setFieldsValue({
        ecName: formSaDetail.ecName,
        soldToName: wdiCore.String.Join(formSaDetail.soldToCode, formSaDetail.soldToName),
        revenueTypeName: formSaDetail.revenueTypeName,
        boName: formSaDetail.boName,
        priceSellinBefore: formSaDetail.priceSellinBefore,
        priceSellinAfter: formSaDetail.priceSellinAfter,
        discountAmountSellin: formSaDetail.discountAmountSellin,
        pricePosBefore: formSaDetail.pricePosBefore,
        pricePosAfter: formSaDetail.pricePosAfter,
        quantitySellinForecast: formSaDetail.quantitySellinForecast,
        revenueSellinForecast: formSaDetail.revenueSellinForecast,
        amount: formSaDetail.amount,
        remark: formSaDetail.remark,
      });
      if (formSaDetail.productBuyIds) {
        Product.get(Number(formSaDetail.productBuyIds)).then(response => {
          if (response.data) {
            formRef.current?.setFieldsValue({ productBuyIds: { value: response.data.productId, label: response.data.codeOrName } });
          }
        });
        handleProductChange(formSaDetail.productBuyIds);
      }
      if (formSaDetail.shipToCodes) {
        ShipTo.search({ shipToCodes: formSaDetail.shipToCodes }).then(response => {
          if (response.data) {
            formRef.current?.setFieldsValue({ shipToCodes: response.data.map(item => ({ value: item.shipToCode, label: item.codeOrName })) });
          }
        });
      }
    }
  }, [formSaDetail]);

  return (
    <WdiModalForm
      {...HorzLayoutOpts}
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      modalProps={{ onCancel: () => { props.onClose?.() }, }}
      onFinish={async (values) => {
        const { shipToCodes, productBuyIds, ...entity } = values;
        if (!entity.amount || entity.amount <= 0) {
          message.error("申请金额填写不正确!");
          return;
        }
        if (entity.priceSellinAfter >= entity.priceSellinBefore) {
          message.error("促销供货价必须小于原供货价!");
          return;
        }
        entity.shipToCodes = WdiSelectFormat.getvalue(shipToCodes);
        entity.productBuyIds = WdiSelectFormat.getvalue(productBuyIds);
        const response = await FormSa.Buttons.validateDetail({
          rowIndex: rowIndex,
          formSa: { ...formSa },
          formSaDetail: Object.assign({}, getDetailPrevData(formSaDetail), entity),
          formSaDetails: rows
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (props.onSave) {
          const data = Object.assign({}, { rowIndex: rowIndex }, response.data);
          response.data = data;
          props.onSave(response);
        }
        return;
      }}
    >
      <Form.Item name="soldToName" label="售达方">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="shipToCodes" label="送达方">
        <WdiSelect {...{
          placeholder: "请选择送达方",
          remote: true,
          showSearch: true,
          mode: 'multiple',
          dataLoading: async (params) => {
            const responseResult = await ShipTo.search(Object.assign({ soldToCode: formSaDetail.soldToCode, isActive: true }, params));
            return responseResult.data.map((item) => { return { value: item.shipToCode, label: item.codeOrName }; });
          }
        }} />
      </Form.Item>
      <Form.Item name="productBuyIds" label="购买产品" rules={[{ required: true, message: '产品不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择产品",
          showSearch: true,
          defaultParamKey:"productIds",
          remote: true,
          defaultParamKey:"productIds",
          onChange: handleProductChange,
          dataLoading: async (params) => {
            const response = await Product.ajaxSearchSku(Object.assign({ productType: Enum.ProductType.SKU, isActive: true }, params));
            return response.data.map((item) => { return { value: item.productId, label: item.codeOrName }; });
          }
        }} />
      </Form.Item>
      <Form.Item name="priceSellinBefore" label="原到岸价(EA)" rules={[{ required: true, message: '原到岸价不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="priceSellinAfter" label="促销到岸价(EA)" rules={[{ required: true, message: '促销到岸价不能为空!' }]}>
        <WdiInputNumber onChange={() => {
          handleCalcdiscountAmountSellin();
          handleCalcrevenueSellinForecast();
          handleCalcAmount();
        }} />
      </Form.Item>
      <Form.Item name="discountAmountSellin" label="单件费用(EA)" rules={[{ required: true, message: '单件费用不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="pricePosBefore" label="终端原售价" rules={[{ required: buConfig?.isPricePosRequired, message: "终端原售价不能为空" }]}>
        <WdiInputNumber min={0} />
      </Form.Item>
      <Form.Item name="pricePosAfter" label="终端促销售价" rules={[{ required: buConfig?.isPricePosRequired, message: "终端促销售价不能为空" }]}>
        <WdiInputNumber min={0} />
      </Form.Item>
      <Form.Item name="quantitySellinForecast" label="预计销量(EA)" rules={[{ required: true, message: '预计销量不能为空!' }]}>
        <WdiInputNumber min={0} digits={0} onChange={() => {
          handleCalcrevenueSellinForecast();
          handleCalcAmount();
        }} />
      </Form.Item>
      <Form.Item name="revenueSellinForecast" label="预计销售额" rules={[{ required: true, message: '预计销售额不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="amount" label="申请金额" rules={[{ required: true, message: '申请金额不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <Input maxLength={2000}/>
      </Form.Item>
      <Form.Item name="ecName" label="费用项">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="revenueTypeName" label="核算类型">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="boName" label="四级预算中心">
        <Input readOnly />
      </Form.Item>
    </WdiModalForm>
  );
};

export default PdDiscountDetailApp;
