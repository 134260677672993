import {formatter, request} from '@/utils/request';

export namespace CustSys {
  export const Path = {
    Search: '/mdmapi/custSys/search',
    AjaxSearch: '/mdmapi/custSys/ajaxSearch',
    SearchForSp: '/mdmapi/custSys/searchForSp',
    Query: '/mdmapi/custSys/query',
    Export: '/mdmapi/custSys/export',
    Get: '/mdmapi/custSys/get',
    Post: '/mdmapi/custSys/post',
    Delete: '/mdmapi/custSys/delete',
    queryForSheet: '/mdmapi/custSys/queryForSheet',
  };

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.CustSysVo>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.CustSysVo>>(Path.Export, formatter(options));
  }

  export async function search(body: API.CustSysSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.CustSys[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.CustSysSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.CustSys[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function searchForSp(body: API.CustSysSearchForSpParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.CustSys[]>>(Path.SearchForSp, formatter(options));
  }

  export async function get(code: string) {
    const options = {
      method: 'GET',
      body: {
        code,
      },
    };
    return request<API.ResponseResult<API.CustSys>>(Path.Get, formatter(options));
  }

  export async function post(body: API.SoldToGroupEntity) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function del(id: string) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }


  export async function queryForSheet(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.CustSysVo>>(Path.queryForSheet, formatter(options));
  }

}
