import React from 'react';
import { TransferItem } from "@/components/GridWidget/GridViewColumnTranser";
import { BiTransfer } from './BiTransfer';

type BiMetaTransferValue = {
  headers?: string[];
  rows?: string[];
  columns?: string[];
};

export type BiMetaTransferProps = {
  dataSource: TransferItem[];
  value?: BiMetaTransferValue;
  onChange?: (value?: BiMetaTransferValue | null) => void;
};

export const BiMetaTransfer: React.FC<BiMetaTransferProps> = (props) => {
  return (
    <BiTransfer {...props} />
  );
}