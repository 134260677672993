import React, {useEffect, useState} from 'react';
import {WdiGridApp} from '@/components/WdiBus/PanelForWarning';
import {wdiCore} from '@/components/GridWidget';
import {Enum} from '@/enums/system';
import {Empty} from "antd";
import {FormSs} from "@/services/sales/ss";

const FormBudgetInfo: Record<string, any> = {
  bgId: (_: any, record: any) => {
    return record['bgName'];
  },
  productLineId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  },
  ym: (value: any, record: any) => {
    return value;
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amount']);
  },
  revenueMtd: (value: any, record: any) => {
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      return <span style={{color: '#ff0000'}}>{record['revenueMtd']}</span>;
    }

    return wdiCore.Number.AmountToString(value);
  },
  expenseMtd: (value: any, record: any) => {
    return wdiCore.Number.AmountToString(value);
  },
  rateActualMtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueMtd'] == null || record['revenueMtd'] == 0
      || record['rateBaselineMtd'] == null || record['rateActualMtd'] > record['rateBaselineMtd']) {
      isError = true;
    }
    if (record['rateBaselineMtd'] == null) {
      hasStandard = false;
    }
    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      return <>
                <span
                  style={isError ? {color: "#ff0000"} : {}}>{wdiCore.Number.RateToString(record['rateActualMtd'])}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineMtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span style={{color: '#ff0000'}}>{wdiCore.Number.RateToString(record['rateActualMtd'])}</span>;
    }
    return "";
  },
  rateActualMtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxMtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  revenueYtd: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{
            color: isError ? "#ff0000" : "#000000"
          }}>{wdiCore.Number.AmountToString(record['revenueYtd'])}</span>
    </>;
  },
  expenseYtd: (value: any, record: any) => {
    return wdiCore.Number.AmountToString(value);
  },
  rateActualYtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;
    if (record['revenueYtd'] == null || record['revenueYtd'] == 0
      || record['rateBaselineYtd'] == null || record['rateActualYtd'] > record['rateBaselineYtd']) {
      isError = true;
    }
    if (record['rateBaselineYtd'] == null) {
      hasStandard = false;
    }
    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      return <>
        <span
          style={{
            color: isError ? "#ff0000" : "#000000"
          }}>{wdiCore.Number.RateToString(record['rateActualYtd'])}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineYtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span style={{color: '#ff0000'}}>{wdiCore.Number.RateToString(record['rateActualYtd'])}</span>;
    }
    return "";
  },
  rateActualYtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxYtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdLyTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
};

const FormBudgetInfoEc: Record<string, any> = {
  bgId: (_: any, record: any) => {
    return record['bgName'];
  },
  productLineId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.AmountToString(record['amount']);
  },
  revenueMtd: (value: any, record: any) => {
    let isError: boolean = false;
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      isError = true;
    }
    return <>
        <span
          style={{color: isError ? "#ff0000" : "#000000"}}>{wdiCore.Number.AmountToString(value)}</span>
    </>;
  },
  expenseMtd: (value: any, record: any) => {
    return wdiCore.Number.AmountToString(value);
  },
  rateActualMtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0
      || record['rateBaselineMtd'] == null || record['rateActualMtd'] > record['rateBaselineMtd']) {
      isError = true;
    }
    if (record['rateBaselineMtd'] == null) {
      hasStandard = false;
    }
    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      return <>
                <span
                  style={isError ? {color: "#ff0000"} : {}}>{wdiCore.Number.RateToString(record['rateActualMtd'])}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineMtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span style={{color: '#ff0000'}}>{wdiCore.Number.RateToString(record['rateBaselineMtd'])}</span>;
    }
    return "";
  },
  rateActualMtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
};

const BudgetInformation: React.FC<{ formId?: number }> = (props) => {
  const [budgetInfos, setBudgetInfos] = useState<API.WdiPanelList>();
  const [budgetCount, setBudgetCount] = useState<number>(-1);

  useEffect(() => {
    if (props.formId) {
      FormSs.Buttons.budgetInfos(props.formId).then((response) => {
        setBudgetInfos(response.data);
        if (response.data && response.data.items) {
          setBudgetCount(response.data.items.length);
        } else {
          setBudgetCount(0);
        }
      });
    }
  }, [props.formId]);

  const renderWdiGridApp = (grid: API.WdiGrid) => {
    const columns: any[] = [];
    grid.wdiColumns.map((wdiColumn) => {
      if (!wdiColumn.isHidden) {
        const column = {
          title: wdiColumn.displayName,
          dataIndex: wdiColumn.name,
          width: wdiColumn.width,
          ellipsis: true,
          render: (grid.key == "SsBudget" || grid.key == "SsBudgetSubmit") ? FormBudgetInfo[wdiColumn.name] : FormBudgetInfoEc[wdiColumn.name]
        };
        columns.push(column);
      }
    });

    return (
      <WdiGridApp
        key={grid.key}
        wdiGrid={grid}
        columns={columns}
        dataSource={grid.items}
        isExport={false}
        exportColumns={[]}
      />
    );
  };

  return (
    <>
      {budgetInfos?.items?.map((item) => {
        return (
          renderWdiGridApp(item)
        );
      })}
      {budgetCount === 0 &&
        <div>
          <div style={{marginTop: "200px", textAlign: "center"}}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="没有预算信息"/>
          </div>
        </div>
      }
    </>
  );
};

export default BudgetInformation;
