import {wdiCore} from "@/components/WdiControls";
import {Enum} from "@/enums/system";

const idsSplit = (productIds?: string | null) => {
  const ids: string[] = [];
  if (productIds) {
    productIds.split(',').forEach((item) => {
      if (!wdiCore.String.IsNullOrEmpty(item.trim())) {
        ids.push(item.trim());
      }
    });
  }
  return ids;
};

const isDupProduct = (first: string | undefined, second: string | undefined) => {
  if (wdiCore.String.IsNullOrEmpty(first) || wdiCore.String.IsNullOrEmpty(first)) {
    return true;
  }
  const firstIdSet: string[] = idsSplit(first);
  const secondIdSet: string[] = idsSplit(second);
  let isDup = false;
  firstIdSet.forEach((item) => {
    if (secondIdSet.includes(item)) {
      isDup = true;
    }
  });
  return isDup;
};

const isDupTerms = (first: API.SalesContractTermsVo, second: API.SalesContractTermsVo) => {
  // 处理方式不一致 或者 费用项不一致 明细不重复
  if (first.processMode != second.processMode || first.ecId != second.ecId) {
    return false;
  }
  // 如果是自动生成，需要判断核算类型和产品别是否包含
  if (Enum.ProcessMode.AUTO_ACCRUAL_AND_SETTLE == first.processMode) {
    // 核算类型不一致，明细不重复
    if (first.revenueType != second.revenueType) {
      return false;
    }
    return isDupProduct(first.productIdsRebate, second.productIdsRebate);
  }
  // 如果是手动生成，需要判断产品别是否包含
  if (Enum.ProcessMode.NONE_AUTO_ACCRUAL_AND_SETTLE == first.processMode) {
    return isDupProduct(first.productIdsRebate, second.productIdsRebate);
  }
  return true;
};

export const isDupSalesContractTerms = (
  dataSource: API.SalesContractTermsVo[],
  current: API.SalesContractTermsVo,
  rowIndex: number,
) => {
  let isDup = false;
  dataSource?.forEach((item, itemIndex) => {
    if (itemIndex != rowIndex && isDupTerms(item, current)) {
      isDup = true;
    }
    return !isDup;
  });
  return isDup;
};
