import React, {Component} from 'react';
import {DetailTable, DetailTableProps} from './DetailTable';
import {ViewColumnSettingApp} from './ViewSettings';
import {Button} from 'antd';
import {EllipsisOutlined} from '@ant-design/icons';
import {initColumnStates, initViewState, ViewState} from './dataConvert';
import {ShowAggrValue} from './PageTable';

type DetailGridProps = {
  // 操作栏平铺操作展示数量, 默认平铺一个
  showItemCount?: number;
  // 操作栏 actions 函数不确定类型
  actionsCellsRenderer?: any;
  // 操作栏 actions 根据 record 过滤
  filterMenuActions?: (actions: API.ActionObject[], record: any) => API.ActionObject[];
  // 控件的宽度
  width?: number;
  // 控件高度
  height?: number;
  // 附件聚合字段, 不支持
  additionallNames?: number;
  // 可编辑的数据列
  editColumn?: any;
  //行号自增列
  autoColumn?: any[];
  // 展示汇总信息
  showAggr?: false | ShowAggrValue;
  //是否有扩展列
  showExpandedRow?: boolean;
  // Antd Table Props
  antdProps?: any;
  // 扩展参数和方法
  params?: any;
  hiddenColumns?: string[];
} & API.GridConfig;

type DetailGridStates = {
  // 弹出数据列配置窗口
  showModifyModal: boolean;
  // 视图定义
  viewState: ViewState;
  // 数据表展示列
  columnStates: any[];
  // 列表宽度
  width?: number;
}

class DetailGrid extends Component<DetailGridProps> {
  state: DetailGridStates;
  tableParentRef: any;
  tableRef: any;

  constructor(props: DetailGridProps) {
    super(props);

    // 初始化视图对象
    const viewState = initViewState({
      viewMode: props.viewMode,
      viewObject: props.viewObject,
      viewDefs: props.viewDefs,
    });
    // 初始化默认数据展示列, 和操作按钮
    const columnStates = initColumnStates({
      formSchema: props.formSchema,
      actionsAlign: props.actionsAlign,
      showItemCount: props.showItemCount,
      actionsCellsRenderer: props.actionsCellsRenderer,
      filterMenuActions: props.filterMenuActions,
      width: props.width,
      additionallNames: props.additionallNames,
    }, props.viewObject, this);

    this.state = {
      showModifyModal: false, //弹出数据配置列
      viewState: viewState,
      columnStates: columnStates, //数据表展示列
    };

    this.tableParentRef = React.createRef();
    if (props.width) {
      this.state.width = props.width;
    }

    this.tableRef = React.createRef();
    this.handleViewColumnClick = this.handleViewColumnClick.bind(this);
    this.closeViewColumnModal = this.closeViewColumnModal.bind(this);
    this.viewColumnToggle = this.viewColumnToggle.bind(this);
  }

  /// 更新视图切换数据列, 只更新数据展示列
  viewColumnToggle = (newViewObject: API.ViewObject, viewDefs: API.ViewDef[]) => {
    this.setState((prevState: DetailGridStates, prevProps: DetailGridProps) => {
      const viewColumns: string[] = [];
      newViewObject.viewColumns?.forEach((item: string) => {
        if (!(prevProps.hiddenColumns && prevProps.hiddenColumns.findIndex(hiddenItem => hiddenItem == item) > -1)) {
          viewColumns.push(item);
        }
      })
      const viewObject = Object.assign({}, {...newViewObject}, {viewColumns: viewColumns});
      const viewState = initViewState({
        viewMode: prevProps.viewMode,
        viewObject,
        viewDefs,
      });
      //更新视图强制刷新
      viewState.viewKey = prevState.viewState.viewKey + 1;

      const columnStates = initColumnStates({
        formSchema: prevProps.formSchema,
        actionsAlign: prevProps.actionsAlign,
        showItemCount: prevProps.showItemCount,
        actionsCellsRenderer: prevProps.actionsCellsRenderer,
        filterMenuActions: prevProps.filterMenuActions,
        width: prevState.width,
        additionallNames: prevProps.additionallNames,
      }, viewObject, this);

      return {
        showModifyModal: false,
        viewState,
        columnStates,
      };
    });
  };

  closeViewColumnModal = () => {
    this.setState({showModifyModal: false});
  };

  handleViewColumnClick = () => {
    this.setState({showModifyModal: true});
  };

  getContext = () => {
    return {
      dataSource: this.props.antdProps?.dataSource,
      params: this.props.params,
    };
  };

  formatDetailTableProps = (props: DetailGridProps, state: DetailGridStates): DetailTableProps => {
    const settings: DetailTableProps = {
      formSchema: props.formSchema,
      columnStates: state.columnStates, /// 展示列设置
      editColumn: props.editColumn, /// 可编辑的数据列
      autoColumn: props.autoColumn, /// 行号自增列
      width: props.width ? props.width : this.tableParentRef?.current?.offsetWidth,
      height: props.height,
      // 固定写死
      //       heightHeader: props.heightHeader, // Antd Table 表头高度默认39
      //       heightFooter: props.heightFooter, // Antd Table 底部分页高度默认 56
      showAggr: props.showAggr,
      showExpandedRow: props.showExpandedRow ? props.showExpandedRow : false,
      antdProps: Object.assign({}, props.antdProps),
    };
    settings.scroll = {x: settings.width, y: settings.height};
    return settings;
  };

  render() {
    const tableSettings = this.formatDetailTableProps(this.props, this.state);
    return (
      <div>
        <div className='grid-body' ref={this.tableParentRef}>
          <DetailTable
            {...tableSettings}
            //grid={this}
            ref={this.tableRef}
            key={'body' + this.state.viewState.viewKey}
          />
          {this.props.allowModifyColumns && (
            <div className='grid-body-modifycloumns'>
              <Button
                type='default'
                icon={<EllipsisOutlined/>}
                size={'small'}
                onClick={this.handleViewColumnClick}
              />
              {this.state.showModifyModal && (
                <ViewColumnSettingApp
                  {...{
                    visible: this.state.showModifyModal,
                    viewObject: this.state.viewState.viewObject,
                    formSchema: this.props.formSchema,
                  }}
                  grid={this}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export {DetailGrid};
