import { Tooltip } from 'antd';
import { wdiCore } from './wdiCore';
import { request, formatter } from '@/utils/request';

type TimeRefProps = {
  timerID?: number | null;
  value: any;
};

const buildDictMap = (data: any, dict: any) => {
  if (data && data.length) {
    data.forEach((item: any) => {
      dict[item.value] = item;
      buildDictMap(item.children, dict);
    });
  }
};

const resolveTreeExpandedKeys = (data: any, expandedKeys: any[]) => {
  if (data && data.length) {
    const item = data[0];
    expandedKeys.push(item.value);
    if (item.children && item.children.length) {
      resolveTreeExpandedKeys(item.children, expandedKeys);
    }
  }
};

const resolveTreeKeys = (data: any, keys: any[]) => {
  if (data && data.length) {
    data.forEach((item: any) => {
      keys.push(item.value);
      if (item.children && item.children.length) {
        resolveTreeKeys(item.children, keys);
      }
    });
  }
};

export const FieldProps = {
  Select: {
    createTooltipMaxTagPlaceholder: (props?: any) => {
      return (omittedValues: any) => {
        return (
          <Tooltip
            title={ omittedValues.map((v: any) => (
              <div key={ v.value }>{ v.label }</div>
            )) }
          >
            <div>+ { omittedValues.length } ...</div>
          </Tooltip>
        );
      };
    },
    /// url
    /// queryParameters
    /// callback(data)
    createComboboxRemoteSearch: (props: any, ref: TimeRefProps) => {
      return (value: string) => {
        if (ref.timerID) {
          clearTimeout(ref.timerID);
          ref.timerID = null;
        }
        ref.value = value;
        const fetchComboboxRemote = () => {
          const { url, queryParameters } = props;
          let params = Object.assign({}, queryParameters, { Top: 100 });
          if (!wdiCore.String.IsNullOrEmpty(value)) {
            params.filterValue = value;
          }
          request(
            url,
            formatter({
              method: 'POST',
              body: params,
            }),
          ).then((data: any) => {
            if (wdiCore.String.EqIngoreCase(value, ref.value) && props.callback) {
              props.callback(data.data);
            }
          });
        };
        ref.timerID = window.setTimeout(fetchComboboxRemote, 300);
      };
    },
    /// textField
    /// valueField
    dataConvert: (props: any, dataSource: any) => {
      let items = [];
      let { textField, valueField } = props;
      if (wdiCore.String.IsNullOrEmpty(textField)) {
        textField = 'label';
      }
      if (wdiCore.String.IsNullOrEmpty(valueField)) {
        valueField = 'value';
      }
      if (!wdiCore.Object.IsNullOrUndefined(dataSource)) {
        items = dataSource.map((item: any) => ({
          label: item[textField],
          value: item[valueField],
        }));
      }
      return items;
    },
    /// maxTagCount
    /// options
    /// onSearch
    /// onChange
    /// isMulti
    comboboxSettings: (props: any) => {
      const settings: any = {
        showSearch: true,
        maxTagCount: props.maxTagCount,
        options: props.options,
        onSearch: props.onSearch,
        onChange: props.onChange,
        showArrow: false,
        filterOption: false,
        placeholder: props.placeholder
      };
      if (props.isMulti) {
        settings.mode = 'multiple';
        settings.maxTagPlaceholder = FieldProps.Select.createTooltipMaxTagPlaceholder();
      } else {
        settings.allowClear = true;
      }
      return settings;
    },
    /// maxTagCount
    /// options
    /// onChange
    /// isMulti
    selectSettings: (props: any) => {
      const settings: any = {
        showSearch: false,
        maxTagCount: props.maxTagCount,
        options: props.options,
        onChange: props.onChange,
        showArrow: true,
        placeholder: props.placeholder
      };
      if (props.isMulti) {
        settings.mode = 'multiple';
        settings.maxTagPlaceholder = FieldProps.Select.createTooltipMaxTagPlaceholder();
      } else {
        settings.allowClear = true;
      }
      return settings;
    },
    /// type: select/combobox
    settings: (props: any) => {
      if (wdiCore.String.EqIngoreCase(props.type, 'select')) {
        return FieldProps.Select.selectSettings(props);
      } else {
        return FieldProps.Select.comboboxSettings(props);
      }
    },
  },
  TreeSelect: {
    /// textField
    /// valueField
    /// id
    /// pId
    formatTreeData: (props: any, dataSource: any, nodeExtends?: (node: any, item: any) => any) => {
      const treeData: any[] = [], dict: Record<string, any> = {};

      let { textField, valueField, id, pId, level, disabledLevel } = props;
      if (wdiCore.String.IsNullOrEmpty(textField)) {
        textField = 'title';
      }
      if (wdiCore.String.IsNullOrEmpty(valueField)) {
        valueField = 'value';
      }
      if (!wdiCore.Object.IsNullOrUndefined(dataSource)) {
        const tempItems: any[] = [];
        dataSource.forEach((item: any) => {
          let treeNode = {
            title: item[textField],
            value: item[valueField],
            id: item[id],
            pId: item[pId],
            filterKey: item[textField],
            disableCheckbox: false,
            selectable: true,
            level: item[level]
          };
          if (nodeExtends) {
            treeNode = nodeExtends(treeNode, item);
          }
          if (level && disabledLevel && disabledLevel.length > 0) {
            treeNode.disableCheckbox = disabledLevel.indexOf(item[level]) > -1;
            treeNode.selectable = !(disabledLevel.indexOf(item[level]) > -1);
          }
          dict[treeNode.id] = treeNode;
          tempItems.push(treeNode);
        });
        tempItems.forEach((item) => {
          if (wdiCore.Object.IsNullOrUndefined(item.pId)) {
            treeData.push(item);
          } else {
            const parent = dict[item.pId];
            if (!wdiCore.Object.IsNullOrUndefined(parent)) {
              if (wdiCore.Object.IsNullOrUndefined(parent.children)) {
                parent.children = [];
              }
              parent.children.push(item);
            } else {
              treeData.push(item);
            }
          }
        });
      }
      return { treeData, dict };
    },
    getTreeExpandedKeys: (data: any) => {
      const expandedKeys: string[] = [];
      resolveTreeExpandedKeys(data, expandedKeys);
      return expandedKeys;
    },
    getTreeFirstKeys: (data: any) => {
      const expandedKeys: string[] = [];
      if (data && data.length) {
        const item = data[0];
        expandedKeys.push(item.value);
      }
      return expandedKeys;
    },
    getAllKeys: (data: any) => {
      const keys: any[] = [];
      resolveTreeKeys(data, keys);
      return keys;
    },
    buildDictMap: (data: any) => {
      const dict = {};
      buildDictMap(data, dict);
      return dict;
    },
    getParentSet: (dict: any, value: any) => {
      const parentSet = new Set();
      let item = dict[value];
      while (item) {
        item = dict[item.pId];
        if (item) {
          parentSet.add(item.id);
        }
      }
      return parentSet;
    },
    /// 需要层级互斥
    valueFormater: (dict: any, values: any, value: any) => {
      let arrays = values;
      if (wdiCore.Object.IsNullOrUndefined(arrays)) {
        arrays = [];
      } else if (!wdiCore.Object.IsArray(arrays)) {
        arrays = [arrays];
      }
      const parentSet = FieldProps.TreeSelect.getParentSet(dict, value);
      const result: any[] = [];
      arrays.forEach((item: any) => {
        /// 已有的值不在当前选择节点的
        if (!parentSet.has(item) && !FieldProps.TreeSelect.getParentSet(dict, item).has(value) && value != item) {
          result.push(item);
        }
      });
      result.push(value);
      return result;
    },
    labeledValuesMeger: (dict: any, labeledValues: any, labeledValue: any) => {
      const result: any[] = [];
      const value = labeledValue.value;
      if (labeledValues && labeledValues.length) {
        const parentSet = FieldProps.TreeSelect.getParentSet(dict, value);
        labeledValues.forEach((item: any) => {
          /// 已有的值不在当前选择节点的
          const itemValue = item.value;
          if (!parentSet.has(itemValue) && !FieldProps.TreeSelect.getParentSet(dict, itemValue).has(value) && value != itemValue) {
            result.push(item);
          }
        });
      }
      result.push(labeledValue);
      return result;
    },
    valueMeger: (dict: any, values: any, value: any) => {
      return FieldProps.TreeSelect.valueFormater(dict, values, value);
    },
    labeledValue2Value: (values: any) => {
      if (wdiCore.Object.IsNullOrUndefined(values)) {
        return values;
      }
      let arrays = values;
      let isArray = true;
      if (!wdiCore.Object.IsArray(arrays)) {
        arrays = [arrays];
        isArray = false;
      }
      const resultValue: any[] = [];
      arrays.forEach((item: any, itemIndex: number) => {
        if (!wdiCore.Object.IsNullOrUndefined(item)) {
          if (typeof (item) === 'object' && !wdiCore.Object.IsNullOrUndefined(item.value)) {
            resultValue.push(item.value);
          } else {
            resultValue.push(item);
          }
        }
      });
      if (isArray) {
        return resultValue;
      } else if (resultValue.length) {
        return resultValue[0];
      }
      return values;
    }
  },
};
