import React from 'react';
import { WdiPageContainer } from '@/components/WdiControls/WdiPageContainer';
import DynamicTarget from '@/components/WdiBus/DynamicTarget';
import FormDiscussions from '@/components/WdiBus/FormDiscussions';

type DynamicInformationProps = {
  formId?: number;
  pageTypeId?: number;
  formTypeId?: number;
  useCaseId?: number;
}
/**动态信息表 */
const DynamicInformation: React.FC<DynamicInformationProps> = (props) => {
  const dynamicProps = {
    objectId: props.formId,
    pageTypeId: props.pageTypeId,
    formTypeId: props.formTypeId,
    useCaseId: props.useCaseId,
  };
  const discussionsProps = {
    objectId: props.formId,
    useCaseId: props.useCaseId,
  };
  return (
    <WdiPageContainer>
      <DynamicTarget dataItem={dynamicProps} />
      <FormDiscussions dataItem={discussionsProps} />
    </WdiPageContainer>
  );
};

export default DynamicInformation;