import {formatter, request} from '@/utils/request';

export namespace SP {
  export const Path = {
    Query: '/reportapi/sp/query',
    QueryForMy: '/reportapi/sp/queryForMy',
    QueryForPre: '/reportapi/sp/queryForPre',
    QueryForPoolAdjust: '/reportapi/sp/queryForPoolAdjust',
    QueryForMyPre: '/reportapi/sp/queryForMyPre',
    QueryRebateForMy: '/reportapi/sp/queryForMyRebatePayment',
    QueryPolicyForMy: '/reportapi/sp/queryForMyPolicyPayment',
    QueryFreezerForMy: '/reportapi/sp/queryForMyFreezerPayment',
    Export: '/reportapi/sp/export',
    ExportForPre: '/reportapi/sp/exportForPre',
    ExportForPoolAdjust: '/reportapi/sp/exportForPoolAdjust',
    QueryForMyPoolAdjust: '/reportapi/sp/queryForMyPoolAdjust',
    QueryFormSpDetailsForPrepay: "/reportapi/sp/queryFormSpDetailsForPrepay",
    GetFormSpDetailsForPrepay: "/reportapi/sp/getFormSpDetailsForPrepay",
    ExPortFormSpDetailsForPrepay: "/reportapi/sp/exportFormSpDetailsForPrepay",
    GetFormSpDetailsForApproval: '/reportapi/sp/getFormSpDetailsForApproval',
    QueryPaymentForTemplate: '/reportapi/sp/queryPaymentForTemplate',
  };


  export async function querySp(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(SP.Path.Query, formatter(options));
  }

  export async function queryFormSpDetailsForPrepay(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.QueryFormSpDetailsForPrepay, formatter(options));
  }

  export async function getFormSpDetailsForPrepay(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.GetFormSpDetailsForPrepay, formatter(options));
  }

  export async function exportFormSpDetailsForPrepay(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.ExPortFormSpDetailsForPrepay, formatter(options));
  }

  export async function get(id: Number) {
    const options = {
      method: 'GET',
      body: {
        poolPrepayReverseId: id
      },
    };
    return request<API.ResponseResult<any>>(Path.GetFormSpDetailsForApproval, formatter(options));
  }

  export async function queryForMy(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(Path.QueryForMy, formatter(options));
  }

  export async function queryForPre(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(SP.Path.QueryForPre, formatter(options));
  }

  export async function queryForPoolAdjust(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(SP.Path.QueryForPoolAdjust, formatter(options));
  }

  export async function queryForMyPre(body: API.QueryForPageReq) {
    const params = {
      sortdatafield: 'createdDate',
      sortorder: 'DESC',
      ...body,
    };
    const options = {
      method: 'POST',
      body: params,
    };
    return request<API.QueryResult<any>>(Path.QueryForMyPre, formatter(options));
  }

  export async function queryRebateForMy(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(Path.QueryRebateForMy, formatter(options));
  }

  export async function queryPolicyForMy(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(Path.QueryPolicyForMy, formatter(options));
  }

  export async function queryFreezerForMy(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(Path.QueryFreezerForMy, formatter(options));
  }

  export async function queryPoolAdjustForMy(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.QueryForMyPoolAdjust, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.Export, formatter(options));
  }

  export async function exportForPre(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.ExportForPre, formatter(options));
  }

  export async function exportForPoolAdjust(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.ExportForPoolAdjust, formatter(options));
  }

  export async function queryForPayment(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(
      '/reportapi/report/queryForPayment',
      formatter(options),
    );
  }

  export async function exportForPayment(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.ResponseResult<Array<any>>>(
      '/reportapi/report/exportForPayment',
      formatter(options),
    );
  }

  export async function queryForPaymentMkt(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(
      '/reportapi/report/queryForPaymentMkt',
      formatter(options),
    );
  }

  export async function exportForPaymentMkt(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.ResponseResult<Array<any>>>(
      '/reportapi/report/exportForPaymentMkt',
      formatter(options),
    );
  }

  export async function queryPaymentForTemplate(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.QueryResult<any>>(Path.QueryPaymentForTemplate, formatter(options));
  }

}
