import {formatter, request} from '@/utils/request';

export namespace Product {
  export const Path = {
    Get: '/mdmapi/product/get',
    SearchLevel1: '/mdmapi/product/searchLevel1',
    AjaxSearchLevel1: '/mdmapi/product/ajaxSearchLevel1',
    SearchLevel2: '/mdmapi/product/searchLevel2',
    AjaxSearchLevel2: '/mdmapi/product/ajaxSearchLevel2',
    SearchLevel3: '/mdmapi/product/searchLevel3',
    AjaxSearchLevel3: '/mdmapi/product/ajaxSearchLevel3',
    SearchLevel4: '/mdmapi/product/searchLevel4',
    AjaxSearchLevel4: '/mdmapi/product/ajaxSearchLevel4',
    SearchSku: '/mdmapi/product/searchSku',
    AjaxSearchSku: '/mdmapi/product/ajaxSearchSku',
    SearchAll: '/mdmapi/product/searchAll',
    AjaxSearchAll: '/mdmapi/product/ajaxSearchAll',
    SearchNoRoot: '/mdmapi/product/searchNoRoot',
    AjaxSearchNoRoot: '/mdmapi/product/ajaxSearchNoRoot',
    GetRoot: '/mdmapi/product/getRoot',
    Query: '/mdmapi/product/query',
    Export: '/mdmapi/product/export',
    Post: '/mdmapi/product/post',
    Delete: '/mdmapi/product/delete',
    QueryForSheet: '/mdmapi/product/queryForSheet',
    ajaxSearchTargetProduct: '/mdmapi/product/ajaxSearchTargetProduct',
    GetProductStructures: '/mdmapi/product/getProductStructures',
  };

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.Product>>(Path.Get, formatter(options));
  }

  export async function searchLevel1(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.SearchLevel1, formatter(options));
  }

  export async function ajaxSearchLevel1(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.AjaxSearchLevel1, formatter(options));
  }

  export async function searchLevel2(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.SearchLevel2, formatter(options));
  }

  export async function ajaxSearchLevel2(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.AjaxSearchLevel2, formatter(options));
  }

  export async function searchLevel3(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.SearchLevel3, formatter(options));
  }

  export async function ajaxSearchLevel3(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.AjaxSearchLevel3, formatter(options));
  }

  export async function searchLevel4(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.SearchLevel4, formatter(options));
  }

  export async function ajaxSearchLevel4(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.AjaxSearchLevel4, formatter(options));
  }



  export async function searchAll(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.SearchAll, formatter(options));
  }

  export async function ajaxSearchAll(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.AjaxSearchAll, formatter(options));
  }

  export async function searchSku(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.SearchSku, formatter(options));
  }

  export async function ajaxSearchSku(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.AjaxSearchSku, formatter(options));
  }

  export async function searchNoRoot(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.SearchNoRoot, formatter(options));
  }

  export async function ajaxSearchNoRoot(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product[]>>(Path.AjaxSearchNoRoot, formatter(options));
  }

  export async function getRoot() {
    const options = {
      method: 'GET',
    };
    return request<API.ResponseResult<API.Product>>(Path.GetRoot, formatter(options));
  }

  export async function query(body: API.QueryForPageReq) {
    const params = {
      id: 'product',
      ...body,
    };
    const options = {
      method: 'POST',
      body: params,
    };
    return request<API.QueryResult<API.ProductVo>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any[]>>(Path.Export, formatter(options));
  }

  export async function post(body: API.Product) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Product>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<boolean>>(Path.Delete, formatter(options));
  }


  // todo 待删除的方法，服务端api已经删除，确认之后前端也删除
  export async function getProductStructures(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.ResponseResult<API.ProductStructureVo[]>>(
      Path.GetProductStructures,
      formatter(options),
    );
  }

  export async function ajaxSearchTargetProduct(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    }
    return request<API.ResponseResult<API.Product[]>>(Path.ajaxSearchTargetProduct, formatter(options));
  }

  export async function queryForSheet(body: API.ProductSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.QueryForSheet, formatter(options));
  }
}
