import React, { useState } from 'react';
import { Button, Col, Dropdown, Menu, message, Row } from 'antd';
import { DownOutlined, FilterOutlined } from '@ant-design/icons';
import './index.less';
import { ViewApi } from '@/services/gridapi';
import { WdiModalAppResult, WdiModalProps } from '@/components/WdiControls';
import { ViewSettingApp } from './ViewSettings';
import { NsGrid } from './GridDefProps';
import { ResponseCode, responseErrorInfo, responseIsOk } from '@/utils/response';
import { NsGridFilter } from './GridWidgetFilter';
import { ItemType } from 'rc-menu/lib/interface';
import { ViewState } from './dataConvert';

type ToolbarActionProps = {
  grid: any;
  actions: API.ActionObject[];
};

const ToolbarAction: React.FC<ToolbarActionProps> = (props: ToolbarActionProps) => {
  const [loadingObject, setLoadingObject] = useState<any>({});
  const handleClick = (action: API.ActionObject, itemIndex: number) => {
    return async () => {
      if (action.actionClick) {
        setLoadingObject((prevState: any) => {
          const targetObject = { ...prevState };
          targetObject[action.name] = true;
          return targetObject;
        });
        await action.actionClick(props.grid, action, null, itemIndex);
        window.setTimeout(() => {
          setLoadingObject((prevState: any) => {
            const targetObject = { ...prevState };
            targetObject[action.name] = false;
            return targetObject;
          });
        }, 500);
      }
    };
  };
  return (
    <div className='grid-filter-toolbarOptsWrapper'>
      {(props.actions ? props.actions : []).map((item, itemIndex: number) => (
        <Button
          loading={(!!loadingObject[item.name])}
          type='link'
          onClick={handleClick(item, itemIndex)}
          key={item.name}
          className='grid-filter-toolbarOpt'
        >
          {item.displayName}
        </Button>
      ))}
    </div>
  );
};

const getViewMenuItem = (viewDefs: API.ViewDef[], viewMode: number): any => {
  const items: ItemType[] = (viewDefs ? viewDefs : []).map((viewDef) => ({
    label: (
      <div className="grid-filter-toolbarViewWrapperMenu-Item">
        <span>{viewDef.name}</span>
        {viewMode == NsGrid.ViewMode.Advance && (
          <a style={{ marginLeft: '15px', float: 'right' }} data-key="del">
            删除
          </a>
        )}
        <a style={{ marginLeft: '15px', float: 'right' }} data-key="edit">
          编辑
        </a>
      </div>
    ),
    key: viewDef.id,
  }));

  if (viewMode == NsGrid.ViewMode.Advance) {
    items.push({
      type: 'divider',
    });
    items.push({
      label: (
        <div className="grid-filter-toolbarViewWrapperMenu-Item">
          <a data-key="add"> + 新建视图</a>
        </div>
      ),
      key: 'add',
    });
  }
  return items;
};

type GridViewMenuProps = {
  grid: any;
  formSchema: API.FormSchema;
  viewState: ViewState;
};

const GridViewMenu: React.FC<GridViewMenuProps> = (props: GridViewMenuProps) => {
  const [modalState, setModalState] = useState<WdiModalProps>({ visible: false });

  const del = (id: number) => {
    ViewApi.del(id).then((responseResult) => {
      if (responseIsOk(responseResult)) {
        reset(responseResult.data);
      } else {
        responseErrorInfo(responseResult);
      }
    });
  };

  const change = (id: number) => {
    const formDefId = props.formSchema.id;
    ViewApi.getViewAggr({ id, formDefId }).then((responseResult) => {
      if (responseIsOk(responseResult)) {
        reset(responseResult.data);
      } else {
        responseErrorInfo(responseResult);
      }
    });
  };

  const onSave = (responseResult?: WdiModalAppResult | undefined) => {
    if (responseResult && responseResult.code === ResponseCode.SUCCESS) {
      reset(responseResult.data);
    } else if (responseResult) {
      message.error(responseResult.message);
    }
  };

  const reset = (viewAggr: API.ViewAggr) => {
    if (props && props.grid && props.grid.viewToggle) {
      props.grid.viewToggle(viewAggr.viewObject, viewAggr.viewDefs);
    }
  };

  const handleClick = (item: any) => {
    if (item.key == 'add') {
      setModalState({ visible: true, title: '新建视图', id: 0 });
    } else {
      var dataKey = null;
      if (item.domEvent && item.domEvent.target) {
        dataKey = item.domEvent.target.getAttribute('data-key');
      }
      if ('edit' == dataKey) {
        setModalState({ visible: true, title: '编辑视图', id: item.key });
      } else if ('del' == dataKey) {
        if (window.confirm('确定要删除当前视图?')) {
          del(item.key);
        }
      } else {
        change(item.key);
      }
    }
  };

  const onClose = (result?: WdiModalAppResult | undefined) => {
    setModalState({ visible: false });
  };

  return (
    <>
      <div className="grid-filter-toolbarViewDPWrapper">
        <Dropdown
          overlay={
            <Menu
              onClick={handleClick}
              items={getViewMenuItem(props.viewState.viewDefs, props.viewState.viewMode)}
            />
          }
        >
          <a onClick={(e) => e.preventDefault()}>
            {props.viewState.viewObject.viewDefName}
            <DownOutlined />
          </a>
        </Dropdown>
      </div>
      {modalState.visible && (
        <ViewSettingApp
          {...modalState}
          formSchema={props.formSchema}
          grid={props.grid}
          viewObject={props.viewState.viewObject}
          onSave={onSave}
          onClose={onClose}
        />
      )}
    </>
  );
};

const calcFilterPanelHeight = (filterStates: NsGridFilter.IBasicFilter[]) => {
  let height = 0;
  let rowCount = 0;
  rowCount++;
  let totalSpan = 0;
  const const_Total_Span = 24;
  (filterStates ? filterStates : []).forEach(function (filterState, itemIndex) {
    if (totalSpan + filterState.span > const_Total_Span) {
      totalSpan = 0;
      rowCount++;
    }
    totalSpan += filterState.span;
  });

  if (totalSpan + 4 > const_Total_Span) {
    totalSpan = 0;
    rowCount++;
  }
  if (rowCount > 0) {
    height = rowCount * 64 + (rowCount - 1) * 5 + 10;
  }
  return height;
};

const calcFilterPanelComponent = (
  filterStates: NsGridFilter.IBasicFilter[],
  filterFormat: number,
  grid: any,
) => {
  const items = [];

  if (filterFormat == NsGrid.FilterFormat.Default || filterFormat == NsGrid.FilterFormat.Expand) {
    let totalSpan = 0;
    const const_Total_Span = 24;
    (filterStates ? filterStates : []).forEach(function (filterState, itemIndex) {
      if (totalSpan + filterState.span > const_Total_Span) {
        totalSpan = 0;
      }
      totalSpan += filterState.span;
      items.push({ data: filterState, offset: 0, span: filterState.span });
    });

    if (totalSpan + 4 > const_Total_Span) {
      totalSpan = 0;
    }
    const buttonState = Object.assign(
      new NsGridFilter.FilterFactory.Button(grid, {
        displayName: '搜索',
        name: '__grid_button',
        dataType: 'Button',
      } as API.FieldObject),
      {
        searchButtonText: '搜索',
      },
    );
    items.push({
      data: buttonState,
      offset: const_Total_Span - 4 - totalSpan,
      span: buttonState.span,
    });
  }

  return items;
};

export type GridFilterPanelProps = {
  filterStates: NsGridFilter.IBasicFilter[];
  // 设置面板展示/隐藏
  showFilterPanel: boolean;
  // 设置过滤面板样式
  filterFormat: number;
  warningText?: string;
} & GridViewMenuProps;

const GridFilterPanel: React.FC<GridFilterPanelProps> = (props: GridFilterPanelProps) => {
  const expandFilterPanelToggle = () => {
    const grid = props.grid;
    if (grid && grid.expandFilterPanelToggle) {
      grid.expandFilterPanelToggle();
    }
  };

  const rows = calcFilterPanelComponent(props.filterStates, props.filterFormat, props.grid);
  const panelStyle: React.CSSProperties =
    props.showFilterPanel && rows.length > 0 ? { display: 'block' } : { display: 'none' };
  return (
    <div className="grid-filter" style={{ padding: '0 8px' }}>
      <div className="grid-filter-toolbar">
        {rows.length > 0 && (
          <div className="grid-filter-toolbarTitleWrapper">
            <a onClick={expandFilterPanelToggle}>
              <FilterOutlined />
              设置过滤条件
            </a>
          </div>
        )}
        {props.viewState.viewMode != 0 && (
          <GridViewMenu
            viewState={props.viewState}
            formSchema={props.formSchema}
            grid={props.grid}
          />
        )}
        {props.warningText &&
        <div style={{ color: 'red', display: 'inline-block', paddingLeft: 20 }}>
          {props.warningText}
        </div>
        }
        <ToolbarAction {...{ actions: props.formSchema.toolbarActions }} grid={props.grid} />
      </div>
      {
        <div className="grid-filter-panel" style={panelStyle}>
          {rows.length > 0 && (
            <div className="grid-filter-body">
              <Row gutter={[16, 5]}>
                {rows.map((row) => (
                  <Col span={row.span} offset={row.offset} key={row.data.code}>
                    {row.data.render()}
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export { GridFilterPanel, ToolbarAction, calcFilterPanelHeight, calcFilterPanelComponent };
