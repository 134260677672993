import { Component } from 'react';
import { Utility } from './GridWidgetUtility';
import { Table, message } from 'antd';
import { wdiCore } from '@/components/WdiControls';
import { ResizableTitle } from './GridPlugin';
import DataStore from './DataStore';
import { PaginationValue } from './PageTable';


type GroupDetailTableStates = {
  dataSource: any[];
  aggrProps: any;
  pagination: false | PaginationValue;
  loading: boolean;
  columns: string[];
  scroll?: any;
}

export type GroupDetailTableProps = {
  // Antd Table Props
  antdProps?: any;
  // 表格内容区域的宽度和高度
  scroll?: any;
  // 展示列
  columnStates: any;
  // 容器宽度
  width?: number;
  // 表格查询需要的数据源
  dataLoading: (args: API.JQXGridArgs, exParams?: any) => Promise<API.ResponseResult<any>>;
  // Antd Table 需要当前属性标识这一列需要更新
  rowKey?: string;
  //汇总的字段
  additionalColumns: any[];
  //查询参数
  queryParamaters?: any;
  // 数据是否延迟加载，报表使用
  isLazyLoad?: boolean;
}

class GroupDetailTable extends Component<GroupDetailTableProps> {
  state: GroupDetailTableStates;
  constructor(props: GroupDetailTableProps) {
    super(props);
    this.state = {
      dataSource: [],
      aggrProps: null,
      pagination: false,
      loading: false,
      columns: []
    };

    if (props.scroll) {
      this.state.scroll = props.scroll;
    }
    const newState: any = this.derivedStateFromProps(props.columnStates, props.width);
    if (newState.columns) {
      this.state.columns = newState.columns;
    }
    if (newState.scroll) {
      this.state.scroll = newState.scroll;
    }
  }

  componentDidMount() {
    const { isLazyLoad } = this.props;
    if (isLazyLoad) {
      /// 延迟加载数据需要点击之后触发
    } else {
      this.reload();
    }
  }

  handleResize = (index: number) => {
    return (_: any, { size }: any) => {
      const newColumns = [...this.state.columns];
      const current: any = newColumns[index];
      newColumns[index] = {
        ...current,
        width: size.width,
      };
      this.setState({ columns: newColumns });
    };
  };

  derivedStateFromProps = (columnStates: any[], width?: number) => {
    const fitWidgetTable: any = Utility.fitColumnWidth(columnStates, width);
    const mergeColumns = fitWidgetTable.columns.map((col: any, index: number) => ({
      ...col,
      onHeaderCell: (column: any) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    const newState: any = {
      columns: mergeColumns,
    };
    if (fitWidgetTable.scrollX) {
      newState.scroll = { x: fitWidgetTable.scrollX };
    }
    return newState;
  };

  componentDidUpdate(prevProps: GroupDetailTableProps, prevState: GroupDetailTableStates) {
    if (
      this.props.width !== prevProps.width ||
      this.props.columnStates !== prevProps.columnStates
    ) {
      const newState = this.derivedStateFromProps(this.props.columnStates, this.props.width);
      this.setState(newState);
    }
  }

  getRowKey() {
    return wdiCore.String.IsNullOrEmpty(this.props.rowKey) ? 'uid' : this.props.rowKey;
  }

  handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    this.reload();
  };

  reload = () => {
    this.fetch();
  };

  // 表头只支持列合并，使用 column 里的 colSpan 进行设置。
  // 表格支持行/列合并，使用 render 里的单元格属性 colSpan 或者 rowSpan 设值为 0 时，设置的表格不会渲染。
  // 合并数组单元格
  formatGroupData = (data: any[]) => {
    const { columnStates, additionalColumns } = this.props;
    const columnNames = columnStates
      .filter((v: any) => !additionalColumns.includes(v.dataIndex))
      .map((v: any) => v.dataIndex);

    const groupKeyDict: any[] = [];
    for (let index = 0; index < columnNames.length; index++) {
      const group_vals = [];
      for (let i = 0; i <= index; i++) {
        group_vals.push(columnNames[i]);
      }
      const group_key = group_vals.join('+');

      if (!groupKeyDict.find((item) => item['group_key'] === group_key)) {
        groupKeyDict.push({ group_key, group_vals });
      }
    }

    const groupByObj: Record<string, any> = {};
    data.forEach((item) => {
      for (let index = 0; index < groupKeyDict.length; index++) {
        const group_keys = [];
        const groupKey = groupKeyDict[index];
        for (let i = 0; i < groupKey.group_vals.length; i++) {
          group_keys.push(item[groupKey.group_vals[i]]);
        }

        const group_key = group_keys.join('+');
        const key = 'key' + index;
        item[key] = group_key;

        if (!groupByObj[key]) {
          groupByObj[key] = [];
        }
        if (groupByObj[key].indexOf(group_key) < 0) {
          groupByObj[key].push(group_key);
        }
      }
    });

    let [...newData] = data;
    Object.keys(groupByObj).map((groupkey) => {
      groupByObj[groupkey].map((key: any) => {
        const children = data.filter((item) => item[groupkey] === key);
        children.map(
          (item, index) =>
          (newData = DataStore.edit(
            newData,
            (item[groupkey + '-rowSpan'] = index === 0 ? children.length : 0),
          )),
        );
      });
    });

    const lastData: any[] = [];
    newData.map((item) => {
      const { ...row } = item;
      for (let index = 0; index < columnNames.length; index++) {
        const columnName = columnNames[index];
        row[columnName + '-rowSpan'] = row['key' + index + '-rowSpan'];
      }
      lastData.push(row);
    });

    return lastData;
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    const { columnStates, additionalColumns, dataLoading } = this.props;
    const body = {
      groupBy: JSON.stringify(
        columnStates
          .filter((v: any) => !additionalColumns.includes(v.dataIndex))
          .map((v: any) => v.dataIndex),
      ),
      ...this.props.queryParamaters,
    };
    dataLoading(body).then((response) => {
      if (response.data) {
        const data = response.data;
        const records = DataStore.init(data);
        this.setState({
          isFirstLoad: false,
          loading: false,
          dataSource: this.formatGroupData(records),
          aggrProps: data.aggrProps,
        });
      } else {
        message.error(response.message);
      }
    });
  };

  render() {
    const { dataSource, pagination, loading, scroll, columns } = this.state;
    const rowKey = this.getRowKey();

    const antdProps = Object.assign(
      {
        // 默认禁掉排序的Tooltip
        showSorterTooltip: false,
        // 默认设置表格尺寸大小为small
        size: 'small',
        // antd需要当前属性，确定行是否变化
        rowKey,
        locale: { emptyText: "暂无数据" }
      },
      // 使用配置的antd扩展属性覆盖现有属性
      this.props.antdProps,
      {
        pagination,
        // 当前页面展示的数据源
        dataSource,
        loading,
        onChange: this.handleTableChange,
        columns: columns,
        components: {
          header: {
            cell: ResizableTitle,
          },
        },
      },
    );

    if (scroll) {
      antdProps.scroll = scroll;
    } else {
      antdProps.scroll = { x: '100%' };
    }

    return <Table {...antdProps} />;
  }
}

export { GroupDetailTable };
