import { wdiCore } from '@/components/WdiControls';

const cellsDisplayFormat = (fieldObject: API.FieldObject) => {
  const format = String(fieldObject.format);
  if ('Number' === fieldObject.type) {
    if (wdiCore.String.EqIngoreCase(format, 'Percentage')) {
      return 'p2';
    } else if (!wdiCore.String.IsNullOrEmpty(format)) {
      if (wdiCore.String.EqIngoreCase(format, 'Integer')) {
        return 'd0';
      }
      if (/^d\d$/g.test(format) || /^p\d$/g.test(format)) {
        return format;
      }
    }
    return 'd2';
  } else if ('DateTime' === fieldObject.type) {
    if (format) {
      return format;
    }
    return 'yyyy-MM-dd';
  }
  return '';
};

const FieldUtility: Record<string, any> = {
  Boolean: function (fieldObject: API.FieldObject) {
    return function (text: any, record: any, index: any) {
      if (!wdiCore.String.IsNullOrEmpty(text)) {
        if (text === true || text === 'true' || text === 1 || text === '1') {
          return 'Y';
        } else {
          return 'N';
        }
      }
      return text;
    };
  },
  Number: function (fieldObject: API.FieldObject) {
    const cellsformat = cellsDisplayFormat(fieldObject);
    return function (text: any, record: any, index: any) {
      let value = text;
      if (!wdiCore.String.IsNullOrEmpty(value) && !isNaN(value)) {
        if (/^p\d$/g.test(cellsformat)) {
          value = wdiCore.Number.AmountToString(value * 100, parseInt(cellsformat.substring(1)));
          value = value + '%';
        } else if (/^d\d$/g.test(cellsformat)) {
          value = wdiCore.Number.AmountToString(value, parseInt(cellsformat.substring(1)));
        }
      }
      return value;
    };
  },
  DateTime: function (fieldObject: API.FieldObject) {
    const cellsformat = cellsDisplayFormat(fieldObject);
    return function (text: any, record: any, index: any) {
      let value = text;
      if (!wdiCore.String.IsNullOrEmpty(value)) {
        value = wdiCore.Date.ToString(value, cellsformat);
      }
      return value;
    };
  },
};

const Utility = {
  /// width
  fitColumnWidth: (columns: any[], width?: number) => {
    if (!width || width <= 600) {
      return {
        columns,
        width: width,
      };
    }
    const result: any = {
      columns: [],
      // scrollX: null
    };

    const innerWidth = width - 50;
    let totalWidth = 0;
    const withoutWidthColumns: any[] = [];
    if (columns) {
      columns.forEach((child, itemIndex) => {
        const item = { ...child };
        if (item.width) {
          totalWidth += item.width;
        } else {
          withoutWidthColumns.push(item);
        }
        result.columns.push(item);
      });
    }

    let leftTotalWidth = innerWidth - totalWidth;
    let cellWidth: number;
    //剩余的字段列如果都是100，并且超过给定的表格宽度，
    //设置每一行的宽度为120避免仅仅只超过指定宽度很少的时候展示不好看
    //例如 总宽度为1000字段有11个则总宽度为1100刚超过100的滚动条
    //如果设置为120则宽度变成1320超过320的滚动条比较好看。
    if (100 * withoutWidthColumns.length > leftTotalWidth) {
      cellWidth = 120;
      withoutWidthColumns.forEach((child, itemIndex) => {
        child.width = cellWidth;
      });
      result.scrollX = totalWidth + withoutWidthColumns.length * cellWidth;
    } else {
      if (withoutWidthColumns.length > 0) {
        const viewExpandWidth = 0;
        leftTotalWidth = leftTotalWidth - viewExpandWidth;
        cellWidth = Math.floor(leftTotalWidth / withoutWidthColumns.length);
        withoutWidthColumns.forEach((child, itemIndex) => {
          child.width = cellWidth;
        });
      }
    }
    /// antd table 最后一列不能设置固定宽度；最后一列要设置宽度，不设置的话列表拖动后最后一列会看不到，因此，上面的innerWidth = width - 50
    // if (result && result.columns) {
    //   delete result.columns[result.columns.length - 1].width;
    // }
    return result;
  },
  guid: () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  /** 1. 防止按键被连续点击造成多次调用，比如导出Execl按键，
   *  2. 连续点击情况使用最后一次点击，比如行编辑按键连续点击
   * 参考 @/pages/contract/SalesContract/index */
  callbackWrapped: (name: any, callback: (grid: any, action: API.ActionObject, record: any, itemIndex: number) => any, timeoutMs: any) => {
    let calling = false;
    let timeoutId: any = null;
    return function (grid: any, action: API.ActionObject, record: any, itemIndex: number) {
      if (calling) {
        //执行中无效
        window.event?.preventDefault();
        window.event?.stopPropagation();
        return false;
      }
      /*连续点击情况使用最后一次点击，比如行编辑按键连续点击，参考 @/pages/contract/SalesContract/index */
      clearTimeout(timeoutId);
      timeoutId = setTimeout(
        () => {
          innerCallBack(grid, action, record, itemIndex);
        },
        timeoutMs && timeoutMs > 200 ? timeoutMs : 200,
      );
      return true;
    };

    function innerCallBack(grid: any, action: API.ActionObject, record: any, itemIndex: number) {
      calling = true;
      const response = callback?.(grid, action, record, itemIndex);
      if (response instanceof Promise) {
        response?.finally(() => {
          calling = false;
        });
      } else {
        calling = false;
      }
    }
  },
  //actions, actionName, callback
  setActionCallback: (actions: API.ActionObject[]
    , name: string
    , callback?: (grid: any, action: API.ActionObject, record: any, itemIndex: number) => any
    , timeoutMs?: any) => {
    if (actions && actions.length) {
      actions.forEach((action: API.ActionObject) => {
        if (wdiCore.String.EqIngoreCase(name, action.name) && callback) {
          action.actionClick = Utility.callbackWrapped(name, callback, timeoutMs);
        }
      });
    }
  },
  enumDisplayFunc: (options: { value: any, name: string }[]) => {
    return (text: any, record: any, index: any) => {
      if (options && options.length > 0) {
        const option = options.find(m => m.value == text);
        if (option) {
          return option.name;
        }
      }
      return text;
    };
  },
  /**显示 行号 */
  displayRowNumber: (text: any, record: any, index: any) => {
    return <span>{index + 1}</span>;
  },
};

export { Utility, FieldUtility, cellsDisplayFormat };
