//判断数据类型
const isType = function (type: string) {
  return function (obj: any) {
    return Object.prototype.toString.call(obj) == '[object ' + type + ']';
  };
};
const Utility = {
  Object: {
    IsObject: isType('Object'),
    IsNumber: isType('Number'),
    IsBoolean: isType('Boolean'),
    IsString: isType('String'),
    IsArray: isType('Array'),
    IsFunction: isType('Function'),
    IsDate: isType('Date'),
    //判断给定的对象为null或者undefined
    IsNullOrUndefined: function (value: any) {
      return value === undefined || value === null;
    },
    RemoveNullOrUndefined: function (item: any) {
      if (Utility.Object.IsNullOrUndefined(item)) {
        return item;
      }
      const result: Record<string, any> = {};
      for (const i in item) {
        if (!Utility.Object.IsNullOrUndefined(item[i])) {
          result[i] = item[i];
        }
      }
      return result;
    },
  },
  String: {
    IsNullOrEmpty: function (value: any) {
      if (Utility.Object.IsNullOrUndefined(value)) {
        return true;
      }
      if (typeof value === 'string' && (value === '' || value.trim() === '')) {
        return true;
      }
      return false;
    },
    //两个数据比较大小
    //返回 0 相等，-1后者大，1前者大, undefined 无效的数据
    Comp: function (first: any, second: any) {
      if (first > second) {
        return 1;
      } else if (first < second) {
        return -1;
      } else if (first === second) {
        return 0;
      }
      return;
    },
    //将给定的数据填充到指定的位数，默认使用0填充
    PadLeft: function (value: any, totalWidth: number, padChar?: any) {
      let str = value;
      if (Utility.Object.IsNullOrUndefined(value)) {
        str = '';
      } else if (value.toString) {
        str = value.toString();
      } else {
        str = value + '';
      }
      if (str.length >= totalWidth) {
        return str;
      }
      let padCharacter = padChar;
      //默认用0填充
      if (Utility.String.IsNullOrEmpty(padCharacter)) {
        padCharacter = '0';
      }
      let padArray = [];
      for (let i = 0; i < totalWidth - str.length; i++) {
        padArray.push(padCharacter);
      }
      return padArray.join('') + str;
    },
    //截取字符串的前length字符
    //replaceString: 多出的字符代替字符串
    SubString: function (value: any, length: number, replaceString?: any) {
      if (Utility.String.IsNullOrEmpty(value)) {
        return value;
      }
      if (value.length <= length) {
        return value;
      }
      const subString = value.substring(0, length);
      if (!Utility.String.IsNullOrEmpty(replaceString)) {
        return subString + replaceString;
      }
      return subString;
    },
    //连接多个字符串
    //默认使用 ' - ' 链接
    Join: function (first: any, second: any, separator?: any) {
      const strArray = [];
      if (!Utility.String.IsNullOrEmpty(first)) {
        strArray.push(first);
      }
      if (!Utility.String.IsNullOrEmpty(second)) {
        strArray.push(second);
      }
      let sep = separator;
      if (Utility.String.IsNullOrEmpty(sep)) {
        sep = ' - ';
      }
      return strArray.join(sep);
    },
    //业务相关的方法，默认第二个字符传名称在前，编号在后
    CodeOrName: function (code: any, name: any) {
      return Utility.String.Join(code, name);
    },
    EqIngoreCase: function (first: any, second: any) {
      if (Utility.Object.IsNullOrUndefined(first) || Utility.Object.IsNullOrUndefined(second)) {
        return false;
      }
      return first.toLocaleLowerCase() == second.toLocaleLowerCase();
    },
    Match: function (first: any, second: any) {
      if (Utility.Object.IsNullOrUndefined(first)) {
        return false;
      }
      if (Utility.Object.IsNullOrUndefined(second)) {
        return true;
      }
      return first.toLocaleLowerCase().indexOf(second.toLocaleLowerCase()) >= 0;
    },
    ObjectToString: function (value: any) {
      if (value === undefined || value === null) {
        return null;
      }
      if (typeof value === 'string') {
        return value;
      }
      if (typeof value === 'number' || typeof value === 'boolean' || typeof value === 'object') {
        return JSON.stringify(value);
      }
      return null;
    },
  },
  Number: {
    //两个数据比较大小
    //返回 0 相等，-1后者大，1前者大, undefined 无效的数据
    Comp: function (first: any, second: any) {
      if (first > second) {
        return 1;
      } else if (first < second) {
        return -1;
      } else if (first === second) {
        return 0;
      }
      return;
    },
    // value 字符串，或者数字类型
    // digits 保留的小数位数，如果后续都是0怎么自动去掉
    // 无效的空值，统一按照null处理
    ToNumber: function (value: any, digits?: number, defaultValue?: number | null) {
      //如果为undefined或者null，直接返回
      if (Utility.Object.IsNullOrUndefined(defaultValue)) {
        defaultValue = null;
      }
      if (Utility.Object.IsNullOrUndefined(value)) {
        return defaultValue;
      }
      let valueItem = value;
      if (Utility.Object.IsString(valueItem)) {
        //空字符串Number会转化成0
        if (Utility.String.IsNullOrEmpty(valueItem)) {
          return defaultValue;
        }
        //1. 去掉千分符
        valueItem = valueItem.replace(/,/gm, '');
        //2. 如果为百分号结尾，去掉%号 / 100
        if (valueItem.charAt(valueItem.length - 1) === '%') {
          valueItem = valueItem.substring(0, valueItem.length - 1);
          if (!isNaN(valueItem)) {
            valueItem = Number(valueItem) / 100;
          }
        }
      }
      if (!isNaN(valueItem)) {
        valueItem = Number(valueItem);
        if (!Utility.Object.IsNullOrUndefined(digits)) {
          let digitsPow = Math.pow(10, Number(digits));
          valueItem = Math.round(valueItem * digitsPow) / digitsPow;
        }
        return valueItem;
      }
      return defaultValue;
    },
    // value 字符串，或者数字类型
    ToInt: function (value: any) {
      return Utility.Number.ToNumber(value, 0);
    },
    // value 字符串，或者数字类型
    // digits 保留小数位数，默认为2
    // null / undefined / 无效的数字类型都按照空白字符串处理
    ToString: function (value: any, digits: number) {
      const valueItem = Utility.Number.ToNumber(value);
      if (Utility.Object.IsNullOrUndefined(valueItem)) {
        return '';
      }
      let digitsValue = digits;
      if (Utility.Object.IsNullOrUndefined(digitsValue)) {
        digitsValue = 2;
      }
      return valueItem.toFixed(digitsValue);
    },
    // value 字符串，或者数字类型
    // digits 保留小数位数，默认为2
    // 返回千分符格式化之后的数据
    // null / undefined / 无效的数字类型都按照空白字符串处理
    AmountToString: function (value: any, digits?: number) {
      let valueItem = Utility.Number.ToNumber(value, digits);
      if (Utility.Object.IsNullOrUndefined(valueItem)) {
        return '';
      }
      let digitsValue = digits;
      if (Utility.Object.IsNullOrUndefined(digits)) {
        digitsValue = 2;
      }
      // 处理小数点位数
      valueItem = valueItem.toFixed(digitsValue);
      // 分离数字的小数部分和整数部分
      let parts = valueItem.split('.');
      // 整数部分加,分隔
      parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      valueItem = parts.join('.');
      return valueItem;
    },
    // value 字符串，或者数字类型
    // digits 保留小数位数，默认为2
    // 数字会*100返回千分符格式化，并且在最后添加%符
    RateToString: function (value: any, digits?: number) {
      let valueItem = Utility.Number.ToNumber(value);
      if (Utility.Object.IsNullOrUndefined(valueItem)) {
        return '';
      }
      let digitsValue = digits;
      if (Utility.Object.IsNullOrUndefined(digits)) {
        digitsValue = 2;
      }
      valueItem = valueItem * 100;
      // 处理小数点位数
      valueItem = valueItem.toFixed(digitsValue);
      // 分离数字的小数部分和整数部分
      let parts = valueItem.split('.');
      // 整数部分加,分隔
      parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      valueItem = parts.join('.');
      return valueItem + '%';
    },
  },
  Date: {
    Parse: function (value: any) {
      let dateValue;
      try {
        let valueItem = value.replace(/-/g, '/');
        dateValue = new Date(valueItem);
      } catch { }
      return dateValue;
    },
    ///时间类型格式化，默认格式为yyyy-MM-dd
    ToString: function (value: any, format?: string) {
      if (Utility.Object.IsNullOrUndefined(value)) {
        return value;
      }
      let formatValue = 'yyyy-MM-dd';
      //默认格式
      if (!Utility.String.IsNullOrEmpty(format)) {
        formatValue = String(format);
      }
      let dateValue;
      //1. C# 时间格式Date(123456789)
      if (Utility.Object.IsString(value) && value.match(/^\/Date\([\d]+\)\/$/g)) {
        let timeSpan = value.substring(6, value.length - 2);
        dateValue = new Date(timeSpan * 1);
      } else if (Utility.Object.IsDate(value)) {
        dateValue = value;
      } else if (Utility.Object.IsString(value)) {
        dateValue = Utility.Date.Parse(value);
      }
      if (!Utility.Object.IsNullOrUndefined(dateValue)) {
        formatValue = formatValue.replace(
          /yyyy/g,
          Utility.String.PadLeft(dateValue.getFullYear(), 4),
        );
        formatValue = formatValue.replace(
          /MM/g,
          Utility.String.PadLeft(dateValue.getMonth() + 1, 2, 0),
        );
        formatValue = formatValue.replace(/dd/g, Utility.String.PadLeft(dateValue.getDate(), 2, 0));
        formatValue = formatValue.replace(/hh/g, Utility.String.PadLeft(dateValue.getHours(), 2, 0));
        formatValue = formatValue.replace(/HH/g, Utility.String.PadLeft(dateValue.getHours(), 2, 0));
        formatValue = formatValue.replace(
          /mm/g,
          Utility.String.PadLeft(dateValue.getMinutes(), 2, 0),
        );
        formatValue = formatValue.replace(
          /ss/g,
          Utility.String.PadLeft(dateValue.getSeconds(), 2, 0),
        );
        dateValue = formatValue;
      }
      return dateValue;
    },
    ///时间类型格式化，格式为yyyy-MM-dd
    DateToString: function (value: any) {
      return Utility.Date.ToString(value, 'yyyy-MM-dd');
    },
    ///时间类型格式化，格式为yyyy-MM-dd hh:mm
    MinuteToString: function (value: any) {
      return Utility.Date.ToString(value, 'yyyy-MM-dd hh:mm');
    },
    ///时间类型格式化，格式为yyyy-MM-dd hh:mm:ss
    SecondToString: function (value: any) {
      return Utility.Date.ToString(value, 'yyyy-MM-dd hh:mm:ss');
    },
    ///时间类型格式化，格式为yyyyMMddhhmmss
    SecondToString2: function (value: any) {
      return Utility.Date.ToString(value, 'yyyyMMddhhmmss');
    },
    //两个日期类型比较大小，默认到秒
    //返回 0 相等，-1后者大，1前者大, undefined 无效的数据
    //level: y 年份，M 月份，d 日，h/H 时，m 分， s秒
    Comp: function (first: any, second: any, level: any) {
      let result;
      if (!Utility.Object.IsDate(first)) {
        return result;
      }
      if (!Utility.Object.IsDate(second)) {
        return result;
      }
      //年份比较
      result = Utility.Number.Comp(first.getFullYear(), second.getFullYear());
      if (result !== 0) {
        return result;
      }
      if (level === 'y') {
        return 0;
      }
      //月份比较
      result = Utility.Number.Comp(first.getMonth(), second.getMonth());

      if (result !== 0) {
        return result;
      }
      if (level === 'M') {
        return 0;
      }

      result = Utility.Number.Comp(first.getDate(), second.getDate());
      if (result !== 0) {
        return result;
      }
      if (level === 'd') {
        return 0;
      }
      //小时比较
      result = Utility.Number.Comp(first.getHours(), second.getHours());
      if (result !== 0) {
        return result;
      }
      if (level === 'h' || level === 'H') {
        return 0;
      }
      //分钟比较
      result = Utility.Number.Comp(first.getMinutes(), second.getMinutes());
      if (result !== 0) {
        return result;
      }
      if (level === 'm') {
        return 0;
      }
      return Utility.Number.Comp(first.getSeconds(), second.getSeconds());
    },
    //起止日期拼接，默认格式为yyyy-MM-dd
    Join: function (start: any, end: any, format?: string) {
      const startString = Utility.Date.ToString(start, format || 'yyyy-MM-dd');
      const endString = Utility.Date.ToString(end, format || 'yyyy-MM-dd');
      return Utility.String.Join(startString, endString, ' ~ ');
    },
  },
  Boolean: {
    Display: function (value: any) {
      let val = '';
      if (!Utility.Object.IsNullOrUndefined(value)) {
        if (true === value || '1' === value) {
          val = 'Y';
        } else {
          val = 'N';
        }
      }
      return val;
    },
  }
};

const wdiCore = Utility;

export { wdiCore };
