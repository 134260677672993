import React, { useState } from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { isNumber } from 'lodash';
import { wdiCore } from './wdiCore';

/// width:  文本框宽度 默认100%
/// digits: 小数点后几位 默认2
/// format: 'd' | 'p' 默认d
/// antdProps: render中antd属性会覆盖掉默认属性
export type WdiInputNumberProps = {
  width?: string;
  format?: 'd' | 'p';
  digits?: number;
} & InputNumberProps;

const getDefaultProps = (props: WdiInputNumberProps) => {
  const defaultProps: WdiInputNumberProps = {
    controls: false,
    keyboard: false,
    style: { width: props.width || '100%' },
  };

  if (!defaultProps.precision) {
    defaultProps.precision = (props.digits || props.digits === 0) ? props.digits : 2;
  }

  if (props.format === 'p') {
    // defaultProps.formatter = value => `${value}%`;
    // defaultProps.parser = value => value!.replace('%', '');
  } else {
    // defaultProps.formatter = value => "" == value || null == value ? (value as string) : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    defaultProps.parser = value => value!.replace(/\$\s?|(,*)/g, '');
  }

  return defaultProps;
};

// const floatSub = (a: number, b: number) => {
//   let c, d, e;
//   try {
//     c = a.toString().split(".")[1].length;
//   } catch (f) {
//     c = 0;
//   }
//   try {
//     d = b.toString().split(".")[1].length;
//   } catch (f) {
//     d = 0;
//   }
//   e = Math.pow(10, Math.max(c, d));
//   return (floatMul(a, e) - floatMul(b, e)) / e;
// }
const floatMul = (a: number, b: number) => {
  let c = 0, d = a.toString(), e = b.toString();
  try {
    c += d.split(".")[1].length;
  } catch (f) { }
  try {
    c += e.split(".")[1].length;
  } catch (f) { }
  return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
}
const floatDiv = (a: number, b: number) => {
  let c, d, n, e = 0, f = 0;
  try {
    e = a.toString().split(".")[1].length;
  } catch (g) { }
  try {
    f = b.toString().split(".")[1].length;
  } catch (g) { }
  c = Number(a.toString().replace(".", ""));
  d = Number(b.toString().replace(".", ""));
  n = (e >= f) ? e : f;
  return floatMul(c / d, Number(Math.pow(10, f - e).toFixed(n)));
}

/**
 * width:  文本框宽度 默认 100%
 * digits: 小数点后几位 默认 2
 * format: 'd' | 'p' 默认 d
 * 注意! format 'p' 是百分比,输入是 100 实际 value 除 100 是 1
 * @param props
 * @returns
 */
export const WdiInputNumber: React.FC<WdiInputNumberProps> = (props) => {
  const [focus, setFocus] = useState(false);
  const { value, onChange, onBlur, ...antdProps } = Object.assign({}, getDefaultProps(props), props);
  if (props.format === 'p') {
    return <InputNumber
      value={(
        //"" == 0 ==>> true
        "" === value || null == value
          ? value
          : (focus ? floatMul(Number(value), 100) : `${floatMul(Number(value), 100).toFixed(antdProps.precision)}%`)
      )
      }
      onChange={
        (v) => {
          // console.log(v);
          const v1 = ("" === v || null == v ? v : (isNumber(v) ? floatDiv(v, 100) : floatDiv(Number((v as string).replace('%', '')), 100)));
          onChange?.(v1);
        }
      }
      onFocus={() => setFocus(true)}
      onBlur={(e) => { setFocus(false); onBlur?.(e) }}
      {...antdProps}>
    </InputNumber >
  }
  return (
    <InputNumber
      {...antdProps}
      value={(
        focus ? value : wdiCore.Number.AmountToString(value, antdProps.precision)
      )}
      onChange={onChange}
      onFocus={() => setFocus(true)}
      onBlur={(e) => { setFocus(false); onBlur?.(e) }}
    />
  )
};
