import { useEffect, useState } from 'react';
import { WdiModalAppProps, WdiModalProps, wdiCore, WdiModalAppResult } from '@/components/WdiControls';
import { CloseOutlined } from "@ant-design/icons";
import { Input, Tooltip, Button, TableColumnsType, DatePicker } from 'antd';
import { WdiModal } from '@/components/WdiControls/WdiModalForm';
import { ResponseStatus } from '@/utils/request';
import { FilterColumn, GridFilterForm } from '@/components/GridWidget/GridFilterForm';
import { WdiTable } from '@/components/WdiControls/WdiTable';
import { ProductPolicy } from '@/services/sales/productpolicy';
import { WdiSelectFormat } from '@/components/WdiControls/WdiSelect';
import moment from 'moment';
import { Enum } from '@/enums/system';

const { RangePicker } = DatePicker;

type TableParams = { filterOptions: any };

export type PolicyItem = {
  productPolicyId: number;
  codeOrName: string;
};

const PolicySelectModalApp: React.FC<WdiModalAppProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<API.ProductPolicy[]>([]);
  const [selectedRow, setSelectedRow] = useState<API.ProductPolicy>();
  const [selectedRowKey, setSelectedRowKey] = useState<any>();

  const [tableParams, setTableParams] = useState<TableParams>({
    filterOptions: null,
  });

  const setRowClass = (record: API.ProductPolicy) => {
    return record.productPolicyId === selectedRowKey ? 'ant-table-row-selected' : ''
  };

  const filterColumns: FilterColumn[] = [];
  filterColumns.push(...[
    {
      title: '政策编号',
      dataIndex: 'policyCode',
      span: 4,
      render: () => <Input/>,
    },
    {
      title: '政策名称',
      dataIndex: 'policyName',
      span: 4,
      render: () => <Input/>
    },
  ]);
  if (props.data.pageTypeId == Enum.PageType.ACTIVITY_APPLICATION) {
    filterColumns.push({
      title: '政策期间',
      dataIndex: 'policyDate',
      span: 8,
      render: () => <RangePicker style={ { width: '100%' } }/>
    },);
  }
  const columns: TableColumnsType<API.ProductPolicyVo> = [
    {
      title: '政策名称',
      width: 180,
      dataIndex: 'policyName',
      ellipsis: true,
      render: (value, record) => (
        <a onClick={ () => {
          window.open(`/policy/ProductPolicy/view/?policyType=${ record.policyType }&productPolicyId=${ record.productPolicyId }`);
        } } data-key={ record.productPolicyId }>
          { value }
        </a>
      )
    },
    {
      title: '政策编号',
      width: 110,
      dataIndex: 'policyCode',
      ellipsis: true,
    },
    {
      title: '政策期间',
      width: 180,
      dataIndex: 'policyDate',
      ellipsis: true,
      render: (_, record) => (wdiCore.Date.Join(record.startDate, record.endDate)),
    },
    {
      title: '返利产品',
      width: 200,
      dataIndex: 'productNamesRebate',
      ellipsis: true,
    },
    {
      title: '适用费用项',
      width: 140,
      dataIndex: 'ecNames',
      ellipsis: true,
    },
    {
      title: '政策类型',
      width: 140,
      dataIndex: 'policyTypeName',
      ellipsis: true,
    },
  ];

  const fetchData = (params: TableParams) => {
    setLoading(true);
    const filterOptions: any = {};
    if (params.filterOptions) {
      const { policyCode, policyName, policyDate, bohqId, productIds } = params.filterOptions;
      if (!wdiCore.String.IsNullOrEmpty(policyCode)) {
        filterOptions.policyCode = policyCode;
      }
      if (!wdiCore.String.IsNullOrEmpty(policyName)) {
        filterOptions.policyName = policyName;
      }
      if (!wdiCore.Object.IsNullOrUndefined(policyDate)) {
        if (policyDate.length == 2) {
          filterOptions.policyStartDate = moment(policyDate[0]).format('YYYY-MM-DD');
          filterOptions.policyEndDate = moment(policyDate[1]).format('YYYY-MM-DD');
        }
      }
      if (!wdiCore.Object.IsNullOrUndefined(bohqId)) {
        filterOptions.bohqId = bohqId;
      }
      if (!wdiCore.Object.IsNullOrUndefined(productIds) && productIds.length > 0) {
        filterOptions.productIds = WdiSelectFormat.getvalue(productIds);
      }
    }
    const req: any = {
      filterOptions: JSON.stringify(Object.assign({}, props.data, filterOptions)),
    };
    ProductPolicy.searchForPicker(req).then(responseResult => {
      const data = responseResult.data;
      if (data) {
        setDataSource(data);
        setTableParams({
          filterOptions: params.filterOptions
        });
        setLoading(false);
        setSelectedRowKey(null);
      }
    });
  };

  const doSearch = (filterOptions: any) => {
    fetchData(Object.assign({}, tableParams, { filterOptions }));
  };

  const doSave = () => {
    if (selectedRow && selectedRowKey) {
      const data: PolicyItem = {
        productPolicyId: selectedRowKey,
        codeOrName: selectedRow?.codeOrName ?? ''
      };

      if (props.onSave) props.onSave({ code: ResponseStatus.SUCCESS, data: data });
    }
    return true;
  };

  useEffect(() => {
    fetchData(Object.assign({}, tableParams));
  }, []);

  return (
    <WdiModal
      title={ props.title }
      visible={ props.visible }
      width={ 1000 }
      bodyStyle={ { height: 600 } }
      onCancel={ () => {
        props.onClose?.()
      } }
      onOk={ doSave }
    >
      <GridFilterForm doSeach={ doSearch } columns={ filterColumns }/>
      <WdiTable
        columns={ columns }
        showPageRows={ 10 }
        rowKey="productPolicyId"
        dataSource={ dataSource }
        pagination={ { defaultPageSize: 10, } }
        loading={ loading }
        rowClassName={ setRowClass }
        onRow={ (record: API.ProductPolicy) => {
          return {
            onClick: () => {
              setSelectedRow(record);
              setSelectedRowKey(record.productPolicyId);
            },
            onDoubleClick: () => {
              setSelectedRow(record);
              setSelectedRowKey(record.productPolicyId);
              doSave();
            },
          }
        } }
      />
    </WdiModal>
  );
};

export type PolicySelectProps = {
  value?: PolicyItem;
  onChange?: (value?: PolicyItem) => void;
  params?: any;
};

//活动申请Prev页专项Select
const PolicySelectApp: React.FC<PolicySelectProps> = (props) => {
  const [modalProps, setModalProps] = useState<WdiModalProps>({ visible: false });
  const onClose = () => {
    setModalProps({ visible: false, title: "", id: null, data: null });
  };

  const onSave = (result?: WdiModalAppResult) => {
    setModalProps({ visible: false, title: "", id: null, data: null });
    if (result && result.code === ResponseStatus.SUCCESS && props.onChange) {
      props.onChange(result.data);
    }
  };

  const newProps: any = {
    // readOnly: true,
    onClick: () => {
      setModalProps({ visible: true, title: "产品政策查询（双击即可选中）", id: null, data: props.value });
    },
    allowClear: {
      clearIcon: (
        <Button
          shape='circle'
          style={ { border: "none", width: 20, height: 20 } }
          icon={ <CloseOutlined/> }
          size="small"
          onClick={ () => {
            if (props.onChange) {
              props.onChange(undefined);
            }
          } }
        />
      )
    }
  };

  return (
    <>
      <Tooltip title={ props.value?.codeOrName }>
        <Input
          { ...newProps }
          placeholder="请选择产品政策"
          value={ props.value?.codeOrName }
        />
      </Tooltip>
      {
        modalProps.visible &&
        <PolicySelectModalApp
          { ...modalProps }
          data={ { ...props.params } }
          onClose={ onClose }
          onSave={ onSave }
        />
      }
    </>
  );
}
export default PolicySelectApp;
