import {formatter, request} from "@/utils/request";

export namespace PoolPrepayReverse {
  export const Path = {
    QueryPoolPrepayReverse: '/activityapi/poolPrepayReverse/queryPoolPrepayReverse',
    ExPort: '/activityapi/poolPrepayReverse/export',
    Apply: '/activityapi/poolPrepayReverse/apply',
    Get: '/activityapi/poolPrepayReverse/get',
    AllowCancel: '/activityapi/poolPrepayReverse/allowCancel',
    Cancel: '/activityapi/poolPrepayReverse/cancel',
    Confirm: '/activityapi/poolPrepayReverse/confirm',
    BatchConfirm: '/activityapi/poolPrepayReverse/batchConfirm',
    UpdateInvoiceNo: '/activityapi/poolPrepayReverse/updateInvoiceNo',
  }

  export async function queryPoolPrepayReverse(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.PoolPrepayReverseQuery[]>>(Path.QueryPoolPrepayReverse, formatter(options));
  }


  export async function exportPoolPrepayReverse(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.ExPort, formatter(options));
  }

  export async function apply(body: { poolPrepayReverse: any, poolPrepays: any[], formSpDetails: any[] }) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.ResponseResult<any[]>>(Path.Apply, formatter(options));
  };

  export async function get(id: Number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.PoolPrepayReverseQuery>>(Path.Get, formatter(options));
  }

  export async function allowCancel(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.AllowCancel, formatter(options));
  }

  export async function cancel(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Cancel, formatter(options));
  }

  export async function updateInvoiceNo(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.UpdateInvoiceNo, formatter(options));
  }

  export async function confirm(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Confirm, formatter(options));
  }

  export async function batchConfirm(poolPrepayReverseIds: string) {
    const options = {
      method: 'Get',
      body: {
        poolPrepayReverseIds: poolPrepayReverseIds
      },
    };
    return request<API.ResponseResult<any>>(Path.BatchConfirm, formatter(options));
  }
}
