import { Enum } from "@/enums/system";
import { wdiCore } from "./wdiCore";
import * as XLSX from 'xlsx'
import {GridApi, ViewApi} from '@/services/gridapi';

const allowExportFields = (column: any, exceptNames?: string[]) => {
  if (!wdiCore.Object.IsNullOrUndefined(column) && !column.isDisabled && !column.isHidden) {
    if (exceptNames && exceptNames.indexOf(column.Name) > -1) {
      return false;
    }
    return true;
  }
  return false
}

const getFields = (formSchema: any, names: string[], exceptNames?: string[]) => {
  const fields = [];
  const allFields = formSchema.allFields;
  if (names && names.length) {
    names.forEach((item) => {
      const column = allFields[item];
      if (allowExportFields(column, exceptNames)) {
        fields.push(column);
      }
    });
  } else {
    for (let key in allFields) {
      const column = allFields[key];
      if (allowExportFields(column, exceptNames)) {
        fields.push(column);
      }
    }
  }
  return fields;
};

const defaultCellFormat = (fieldObject: any, row: any, value: any) => {
  return value;
}

const numberCellFormat = (fieldObject: any, row: any, value: any) => {
  let format = fieldObject.format;
  let cellsformat = 'd2';
  if (wdiCore.String.EqIngoreCase(fieldObject.dataType, Enum.FieldDataType.INT)) {
    cellsformat = 'd0';
  } else {
    if (!wdiCore.String.IsNullOrEmpty(format)) {
      if (/^d\d$/g.test(format) || /^p\d$/g.test(format)) {
        cellsformat = format;
      }
    }
  }
  let digits = Number(cellsformat.substring(1));
  if (wdiCore.String.EqIngoreCase(cellsformat[0], 'p')) {
    return wdiCore.Number.RateToString(value, digits);
  } else {
    return wdiCore.Number.ToString(value, digits);
  }
}

const dateCellFormat = (fieldObject: any, row: any, value: any) => {
  let format = fieldObject.format;
  let cellsformat = 'yyyy-MM-dd';
  if (!wdiCore.String.IsNullOrEmpty(format)) {
    cellsformat = format;
  }
  return wdiCore.Date.ToString(value, cellsformat);
}

const booleanCellFormat = (fieldObject: any, row: any, value: any) => {
  return wdiCore.Boolean.Display(value);
}

type ToSheetProps = {
  dataLoading?: any;
  data: any;
  names?: any[];
  exceptNames?: string[];
  cells?: {
    [key: string]: (fieldObject: any, row: any, value: any) => any;
  };
  sheetName?: string;
}




const gridToSheet = (settings: ToSheetProps, formSchema?: any) => {
  let viewNames: any[] = [];
  if (settings.names) {
    viewNames = settings.names;
  }
  let fields = viewNames;
  if (formSchema) {
    fields = getFields(formSchema, viewNames, settings.exceptNames);
  }

  const header: string[] = [];
  const firstRow: { [key: string]: string } = {};
  fields.forEach((field) => {
    const name: string = String(field.name);
    header.push(name);
    firstRow[name] = field.displayName;
  });

  const sheetBody = [];
  sheetBody.push(firstRow);

  if (settings.data) {
    settings.data.forEach((item: any) => {
      const itemRow: { [key: string]: any } = {};
      fields.forEach((field) => {
        const name: string = String(field.name);
        const cellObject = item[name];
        let cellFormat = defaultCellFormat;
        if (wdiCore.String.EqIngoreCase(field.type, Enum.FieldType.NUMBER)) {
          cellFormat = numberCellFormat;
        } else if (wdiCore.String.EqIngoreCase(field.type, Enum.FieldType.DATE_TIME)) {
          cellFormat = dateCellFormat;
        } else if (wdiCore.String.EqIngoreCase(field.type, Enum.FieldType.BOOLEAN)) {
          cellFormat = booleanCellFormat;
        }
        if (settings.cells && settings.cells[name]) {
          cellFormat = settings.cells[name];
        }
        let resultCellObject = cellFormat(field, item, cellObject);
        if (!wdiCore.Object.IsNullOrUndefined(resultCellObject)) {
          // @ts-ignore
          if (wdiCore.String.ObjectToString(resultCellObject)?.length > 10000) {
            console.log("出现过长字符串:", resultCellObject);
            // @ts-ignore
            resultCellObject = wdiCore.String.ObjectToString(resultCellObject).substring(0, 10000);
          }
          itemRow[name] = resultCellObject;
        }
      });
      sheetBody.push(itemRow);
    })
  }
  const sheet = XLSX.utils.json_to_sheet(sheetBody, { header: header, skipHeader: true });
  return sheet;
}

export type ToExcelProps = {
  sheetProps: ToSheetProps[];
  name: string;
}

export const gridToExcel = (formSchema: any, settings: ToExcelProps) => {
  const wb = XLSX.utils.book_new();
  settings.sheetProps.forEach((sheetProp) => {
    const sheet = gridToSheet(sheetProp, formSchema);
    XLSX.utils.book_append_sheet(wb, sheet, sheetProp.sheetName || "data");
  })
  XLSX.writeFile(wb, settings.name);
}

export const toExcel = (settings: ToExcelProps) => {
  const wb = XLSX.utils.book_new();
  settings.sheetProps.forEach((sheetProp) => {
    const sheet = gridToSheet(sheetProp);
    XLSX.utils.book_append_sheet(wb, sheet, sheetProp.sheetName || "data");
  })
  XLSX.writeFile(wb, settings.name);
}

export const toExcelAdjustOrder = (settings: ToExcelProps) => {
  const wb = XLSX.utils.book_new();
  settings.sheetProps.forEach((sheetProp) => {
    const sheet = gridToSheet(sheetProp);
    XLSX.utils.book_append_sheet(wb, sheet, sheetProp.sheetName || "data");
  })
  XLSX.writeFile(wb, settings.name);
}


export enum ExportType {
  View = 1,
  All = 2
};

export const getSchemaColumns = async (schemaId: string, replaceColumns?: any, exportType?: ExportType, viewId?: number) => {
  const responseResult = await GridApi.searchConfig({id: schemaId});
  const settings = responseResult.data;
  if (viewId) {
    const viewResponseResult = await ViewApi.getViewAggr({id: viewId, formDefId: schemaId});
    if (viewResponseResult.data) {
      settings.viewObject = viewResponseResult.data.viewObject;
    }
  }
  const viewColumns: string[] = [];
  if (settings) {
    if (settings.viewObject && settings.viewObject.viewColumns) {
      viewColumns.push(...settings.viewObject.viewColumns);
    }
    if (exportType != ExportType.View) {
      if (settings.formSchema && settings.formSchema.viewFieldNames) {
        viewColumns.push(...settings.formSchema.viewFieldNames.filter(key => !viewColumns.includes(key)));
      }
    }
  }
  const columns: any[] = [];
  viewColumns.forEach(key => {
    if (replaceColumns?.[key]) {
      columns.push(...replaceColumns?.[key]);
    } else if (settings.formSchema.allFields[key] && !settings.formSchema.allFields[key].isHidden) {
      columns.push({
        name: settings.formSchema.allFields[key].name,
        displayName: settings.formSchema.allFields[key].displayName,
      });
    }
  });
  return columns;
};
