import { MenuDataItem } from "@ant-design/pro-layout";

const lookForMenuKeys = (data: MenuDataItem[] = [], keys: string[]) => {
  for (const item of data) {
    if (!keys.includes(`${item.menuID}`)) {
      keys.push(`${item.menuID}`);
    }
    if (item.routes && item.routes.length) {
      lookForMenuKeys(item.routes, keys);
    }
  }
  return keys;
}
/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: any) {
  const { currentUser, menuData } = initialState || {};
  const menuKeys = lookForMenuKeys(menuData, []);
  console.log('get access');
  return {
    canAdmin: currentUser && currentUser.access === 'admin',
    dept: menuKeys.includes('901'),
    onLeaveConfirm: null as any
  };
}
