import {request, formatter} from '@/utils/request';

export namespace GridApi {
  export const Path = {
    searchConfig: '/gridapi/grid/searchConfig',
  }

  export async function searchConfig(body: API.GridParam) {
    const options = {
      method: "POST",
      data: body
    };
    return request<API.ResponseResult<API.GridConfig>>(Path.searchConfig, options);
  }
}

export namespace ViewApi {
  const Path = {
    GetViewAggr: "/gridapi/view/search",
    Delete: '/gridapi/view/delete',
    SearchViewSettings: '/gridapi/view/searchSettings',
    Post: '/gridapi/view/post',
    PostForColumn: '/gridapi/view/postForColumn',
    GetForAdmin: '/gridapi/view/getForAdmin',
    PostForAdmin: '/gridapi/view/postForAdmin'
  }

  export async function getViewAggr(body: API.ViewDefSearchParam) {
    const options = {
      method: "POST",
      data: {
        ...body
      }
    };
    return request<API.ResponseResult<API.ViewAggr>>(Path.GetViewAggr, options);
  }

  export async function searchViewSettings(body: API.ViewDefSearchParam) {
    const options = {
      method: "POST",
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.ViewSettings>>(Path.SearchViewSettings, formatter(options));
  }

  export async function post(body: API.ViewDefSearchParam) {
    const options = {
      method: "POST",
      body: {
        id: body.id,
        formDefId: body.formDefId,
        viewObject: JSON.stringify(body.viewObject)
      }
    };
    return request<API.ResponseResult<API.ViewAggr>>(Path.Post, formatter(options));
  }

  export async function postForColumn(body: API.ViewDefSearchParam) {
    const options = {
      method: "POST",
      body: {
        id: body.id,
        formDefId: body.formDefId,
        viewObject: JSON.stringify(body.viewObject)
      }
    };
    return request<API.ResponseResult<API.ViewAggr>>(Path.PostForColumn, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      }
    };
    return request<API.ResponseResult<API.ViewAggr>>(Path.Delete, formatter(options));
  }

  export async function getForAdmin(id: string) {
    const options = {
      method: 'GET',
      body: {
        id: id
      }
    };
    return request<API.ResponseResult<API.ViewSettings>>(Path.GetForAdmin, formatter(options));
  }

  export async function postForAdmin(body: API.ViewDefSearchParam) {
    const options = {
      method: "POST",
      body: {
        id: body.id,
        formDefId: body.formDefId,
        viewObject: JSON.stringify(body.viewObject)
      }
    };
    return request<API.ResponseResult<API.ViewAggr>>(Path.PostForAdmin, formatter(options));
  }
}
