import React, { useState, useEffect } from 'react';
import { Dropdown, Menu, message, Space, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ErrorInfo } from '@/services/flow';
import { ResponseStatus, request, formatter } from '@/utils/request';
import { MenuItemProps, convertTo, OnChangeType } from "./data"
import { WdiModalProps, WdiModalAppResult } from '@/components/WdiControls';
import * as ButtonDict from './Button'
import { OpenMode } from '../FormPage';
import ApprovalModalApp from './ApprovalModal';

const ButtonStore: Record<string, any> = {
  UndoButton: {
    // init: (button: API.Button, form: API.Form, useCaseId: number) => {
    //   const menuItemProps = convertTo(button, form, useCaseId);
    //   if (menuItemProps.props && menuItemProps.props.BackupUrl) {
    //     menuItemProps.props.BackupUrl = "/home/index/?Tabs=MyDraft";
    //   }
    //   return menuItemProps;
    // },
    // click: (props: MenuItemProps, callback?: (item: MenuItemProps) => void) => {
    //   if (props.getParameter) {
    //     const args: ButtonArgs = props.getParameter(props);
    //     request(props.url, formatter({ method: "POST", body: args })).then(res => {
    //       if (res.code === ResponseStatus.SUCCESS) {
    //         message.success("操作成功");
    //         // if (callback) {
    //         //   callback(newProps);
    //         // }
    //       } else {
    //         message.error(res.message);
    //       }
    //     })
    //   }
    // }
  },
  InterveneFlowButton: {
  },
  RepairButton: {
  }
}

const buttonDict: Record<string, any> = ButtonDict;

type ButtonReqParams = {
  url: string;
  keys: string[];
}

type MoreWapperProps = {
  openMode?: OpenMode;
  form?: API.Form;
  useCaseId: number;
  successParams: ButtonReqParams[],
  errorParams: ButtonReqParams[],
  onClose?: (result?: WdiModalAppResult) => void;
  callback?: (result?: WdiModalAppResult) => void;
};

const convertToMenuItem = (item: API.Button, form: API.Form, useCaseId: number) => {
  const buttonStore = ButtonStore[item.key];
  let menu: MenuItemProps;
  if (buttonStore && buttonStore.init) {
    menu = buttonStore.init(item, form, useCaseId)
  } else {
    menu = convertTo(item, form, useCaseId)
  }
  return menu;
}

const convertToMenuItems = (buttonArray: API.Button[], form: API.Form, useCaseId: number) => {
  const menuItems: MenuItemProps[] = [];
  buttonArray?.forEach(item => {
    menuItems.push(convertToMenuItem(item, form, useCaseId));
  });
  return menuItems;
}

const promiseAll = (promiseArray: (Promise<API.ResponseResult<API.Button[]>>)[]) => {
  return Promise.all(promiseArray).then(resArray => {
    let result: API.Button[] = [];
    let message = "";
    let status = ResponseStatus.SUCCESS;
    resArray.forEach((item) => {
      if (item.code === ResponseStatus.SUCCESS) {
        if (item.data && item.data.length) {
          item.data.forEach((button) => {
            result.push(button);
          });
        }
      } else {
        status = ResponseStatus.UNKNOWN;
        message = item.message;
      }
    });
    return {
      code: status,
      message: message,
      data: result
    }
  });
}

const fetchAll = (form: API.Form, useCaseId: number, params: ButtonReqParams[]) => {
  const promiseArray: (Promise<API.ResponseResult<API.Button[]>>)[] = [];
  params.forEach(item => {
    promiseArray.push(request(item.url, formatter({
      method: 'POST',
      body: {
        formId: form.formId,
        keys: item.keys.join(),
        items: [{
          name: "useCaseId",
          value: String(useCaseId)
        }]
      }
    })))
  })
  return promiseAll(promiseArray);
}

export const MoreWapper: React.FC<MoreWapperProps> = (props: MoreWapperProps) => {

  const [menuItems, setMenuItems] = useState<MenuItemProps[]>([]);

  useEffect(() => {
    if (props.form) {
      const form: API.Form = props.form;
      let processId = 0;
      if (form.processId) {
        processId = Number(form.processId);
      }
      ErrorInfo.Search({ processId: processId }).then((res) => {
        if (res.code === ResponseStatus.SUCCESS) {
          if (res.data && res.data.length) {
            /// 加载错误信息数据源
            fetchAll(form, props.useCaseId, props.errorParams).then((res2) => {
              if (res2.code === ResponseStatus.SUCCESS) {
                setMenuItems(convertToMenuItems(res2.data, form, props.useCaseId))
              } else {
                message.error(res2.message)
              }
            })
          } else {
            /// 加载正常数据源
            fetchAll(form, props.useCaseId, props.successParams).then((res2) => {
              if (res2.code === ResponseStatus.SUCCESS) {
                setMenuItems(convertToMenuItems(res2.data, form, props.useCaseId))
              } else {
                message.error(res2.message)
              }
            })
          }
        } else {
          message.error(res.message);
        }
      });
    }
  }, [props.form, props.useCaseId, props.successParams, props.errorParams]);

  const onChange = (type: number, result?: WdiModalAppResult) => {
    if (OnChangeType.Source === type) {
      const newMenuItems: MenuItemProps[] = [];
      const button = result?.data;
      menuItems?.forEach(item => {
        if (item.key === button?.key) {
          newMenuItems.push(convertToMenuItem(button, item.form, item.useCaseId));
        } else {
          newMenuItems.push(item);
        }
      })
      setMenuItems(newMenuItems)
    } else if (OnChangeType.Callback === type) {
      window.location.href = result?.data;
    }
  };

  return (
    <>
      <Dropdown overlay={
        <Menu
          // onClick={handleClick}
          items={menuItems.map((item: MenuItemProps) => {
            let Component = buttonDict[item.key];
            let comp: any = item.label
            if (Component) {
              comp = (<Component item={item} onChange={onChange} onClose={props.onClose} callback={props.callback} />)
            }
            return {
              label: comp,
              key: item.key
            }
          })}
        />
      }>
        <a onClick={e => e.preventDefault()}>
          <Space>
            More <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </>
  )
}

type ApprovalButtonProps = {
  openMode?: OpenMode;
  form?: API.Form;
  useCaseId: number;
  params: ButtonReqParams,
  onClose?: (result?: WdiModalAppResult) => void;
  callback?: (result?: WdiModalAppResult) => void;
};

export const ApprovalButton: React.FC<ApprovalButtonProps> = (props: ApprovalButtonProps) => {

  const [data, setData] = useState<any>({});
  const [showButton, setShowButton] = useState<boolean>(false);
  const [approvalModalProps, setApprovalModalProps] = useState<WdiModalProps>({ visible: false });

  useEffect(() => {
    if (props.form) {
      const form: API.Form = props.form;
      request(props.params.url, formatter({
        method: 'POST',
        body: {
          formId: form.formId,
          keys: props.params.keys.join(),
          items: [{
            name: "useCaseId",
            value: String(props.useCaseId)
          }]
        }
      })).then(res => {
        if (res.code === ResponseStatus.SUCCESS) {
          if (res.data && res.data.length) {
            setData(convertToMenuItem(res.data[0], form, props.useCaseId))
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        } else {
          message.error(res.message)
          setShowButton(false);
        }
      })
    }
  }, [props.form, props.useCaseId, props.params]);

  const onApprovalClose = () => {
    setApprovalModalProps({ visible: false, data: null });
  }

  const onApprovalSave = (result: any) => {
    setApprovalModalProps({ visible: false, data: null });
    if (props.onClose) {
      props.onClose();
    }
    if (props.callback) {
      props.callback();
    }
  }

  const handleClick = (e: any) => {
    setApprovalModalProps({ visible: true, data: data });
  };

  return (
    <>
      {
        showButton &&
        <Button type="primary" onClick={handleClick} size="small" style={{ fontSize: '16px', height: '26px', lineHeight: 1.5 }}>
          {data.label}
        </Button>
      }
      {showButton && approvalModalProps.visible &&
        <ApprovalModalApp
          {...approvalModalProps}
          onClose={onApprovalClose}
          onSave={onApprovalSave}
        />
      }
    </>
  )
}

export const WdiButtonContainer = (props: any) => {
  return (
    <>
      <div style={{ ...{ display: 'inline-block', marginLeft: 20 } }}>
        {props.children}
      </div>
    </>
  )
}
