import {ParamsProps} from '@/components/WdiBus/FormPage';
import {Enum} from '@/enums/system';
import {ResponseStatus} from '@/utils/request';
import {WdiModalAppResult} from '@/components/WdiControls';
import {FormApi} from '@/services/sales';
import {FormRs, FormRsDetail} from '@/services/sales/rs';

export type ViewEntityProps = {
  form?: API.Form,
  formRs?: API.FormRs,
  formRsDetails?: API.FormRsDetail[],
  relationType?: number,
  relatedFormId?: number,
  formId?: number;
  statusId?: number;
  formTypeId?: number;
  pageTypeId?: number;
  userId?: number;
  positionId?: number;
  deptId?: number;
}

export const initApplyPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId || params.relatedFormId) {
    // 退回待修改
    const formResponse = await FormApi.get(params.formId || params.relatedFormId || 0);
    viewEntity.form = formResponse.data;
    if (!viewEntity.form) {
      result.message = "无效的退回待修改单";
      return result;
    }
    if (params.formId) {
      viewEntity.formId = params.formId;
    } else {
      viewEntity.relationType = Enum.FormRelationType.REJECTED;
      viewEntity.relatedFormId = params.relatedFormId;
    }
    const formRsResponse = await FormRs.get(viewEntity.form.formId);
    viewEntity.formRs = formRsResponse.data;
    viewEntity.userId = viewEntity.form.userId;
    viewEntity.positionId = viewEntity.form.positionId;
    viewEntity.deptId = viewEntity.form.deptId;
    viewEntity.pageTypeId = viewEntity.form.pageTypeId;
    viewEntity.formTypeId = viewEntity.form.formTypeId;
  } else {
    // 直接申请
    viewEntity.pageTypeId = params.pageTypeId;
    viewEntity.formTypeId = params.formTypeId;
    viewEntity.userId = params.userId;
    viewEntity.positionId = params.positionId;
    viewEntity.deptId = params.deptId;
  }

  if (viewEntity.form) {
    const formRsDetailsResponse = await FormRsDetail.getDetailsForRebate(viewEntity.form.formId);
    viewEntity.formRsDetails = formRsDetailsResponse.data;
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
}

export const initApprovalPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId) {
    const formRsResponse = await FormRs.get(params.formId);
    viewEntity.formRs = formRsResponse.data;
    if (!viewEntity.formRs) {
      result.message = "无效的单据";
      return result;
    }
    const formResponse = await FormApi.get(params.formId);
    viewEntity.form = formResponse.data;

    const formRsDetailsResponse = await FormRsDetail.getDetailsForRebate(params.formId);
    viewEntity.formRsDetails = formRsDetailsResponse.data;
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
};

