import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, } from 'antd';
import type { ProFormInstance } from '@ant-design/pro-form';
import type { WdiModalAppProps } from '@/components/WdiControls';
import { WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { WdiSelect } from '@/components/WdiControls/WdiSelect';
import { Enum } from '@/enums/system';
import { PageType } from '@/services/basic/pagetype';
import { FormSa } from '@/services/sales/sa';
import { FormApi } from '@/services/sales';
import { BOHQ } from '@/services/mdm/bohq';

const MyActivityPrevApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [enable, setEnable] = useState<boolean>(true);

  const handlePageTypeIdChange = (value: any) => {
    if (value) {
      PageType.get(value).then((responseResult) => {
        formRef.current?.setFieldsValue({
          remark: responseResult.data.remark
        });
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (props.data) {
        FormApi.get(props.data.relatedFormId).then(response => {
          formRef.current?.setFieldsValue({
            pageTypeId: response.data.pageTypeId,
            // bohqId: response.data.bohqId,
          });
          setEnable(false);
          handlePageTypeIdChange(response.data.pageTypeId);
        })

        formRef.current?.setFieldsValue({
          relatedFormId: props.data.relatedFormId,
          relationType: props.data.relationType
        });
      } else {
        const filter: API.PageTypeFilter = {};
        filter.formTypeId = Enum.FormType.ACTIVITY_APPLICATION;
        var pageTypes = await PageType.search(filter);
        if (pageTypes) {
          formRef.current?.setFieldsValue({
            pageTypeId: pageTypes.data[0].pageTypeId,
            remark: pageTypes.data[0].remark
          });
          handlePageTypeIdChange(pageTypes.data[0].pageTypeId);
        }
      }
    })();
  }, []);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      labelAlign='left'
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      modalProps={{
        onCancel: () => { props.onClose ? props.onClose() : null },
        destroyOnClose: true,
        maskClosable: false
      }}
      onFinish={async (values) => {
        const { pageTypeId, bohqId, ...entity } = values;

        entity.pageTypeId = pageTypeId;
        entity.bohqId = bohqId;
        entity.formTypeId = Enum.FormType.ACTIVITY_APPLICATION;
        // entity.budgetProjectId = budgetProject.budgetProjectId;

        const response = await FormSa.myActivityPrev(entity);
        if (props.onSave) props.onSave(response);
      }}
    >

      <Form.Item name="relatedFormId" label="relatedFormId" hidden={true} >
        <input hidden></input>
      </Form.Item>
      <Form.Item name="relationType" label="relationType" hidden={true} >
        <input hidden></input>
      </Form.Item>
      {/* <Form.Item name="budgetProject" label="专项项目" rules={[{ required: true, message: '专项项目不能为空!' }]}>
        <ProjectSelectApp />
      </Form.Item> */}
      <Form.Item
        name="pageTypeId"
        label="表单类型"
        rules={[{ required: true, message: '表单类型不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择表单类型",
          showSearch: true,
          disabled: !enable,
          dataLoading: async (params) => {
            params.formTypeId = Enum.FormType.ACTIVITY_APPLICATION;
            const response = await PageType.search(Object.assign({}, params));
            return response.data.filter((item) => item.pageTypeId != Enum.PageType.MKT_APPLICATION).map((item) => { return { value: item.pageTypeId, label: item.pageTypeName }; });
          },
          onChange: handlePageTypeIdChange
        }} />
      </Form.Item>
      <Form.Item
        name="bohqId"
        label="费用归属"
        rules={[{ required: true, message: '费用归属不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择费用归属",
          showSearch: true,
          dataLoading: async (params) => {
            const response = await BOHQ.search(Object.assign({}, params, {isActive: true, withPermissions: true}));

            if (response.data && response.data.length == 1) {
              formRef.current?.setFieldsValue({
                bohqId: response.data[0].bohqId,
              });
            }

            return response.data.map((item) => { return { value: item.bohqId, label: item.bohqName }; });
          }
        }} />
      </Form.Item>
      <Form.Item name="remark" label="描述">
        <Input.TextArea rows={3} readOnly />
      </Form.Item>
    </WdiModalForm>
  );
};
export default MyActivityPrevApp;
