export const ButtonModalType = {
  None: 0,
  Approval: 1,
  InterveneFlow: 2,
  Repair: 3,
};

export const OnChangeType = {
  None: 0,
  // 更新数据源
  Source: 1,
  // Callback
  Callback: 2,
};

export type ButtonPair = {
  name: string;
  value: string;
};

export type ButtonArgs = {
  formId: number;
  key: string;
  items: ButtonPair[];
}

export type MenuItemProps = {
  form: API.Form;
  useCaseId: number;
  url: string;
  key: string;
  label: string;
  tooltip?: string;
  props?: any;
}

export const getParameter = (props: MenuItemProps) => {
  const args: ButtonArgs = {
    formId: props.form.formId,
    key: props.key,
    items: []
  }
  args.items.push({ name: "useCaseId", value: String(props.useCaseId) });
  return args;
};

export const convertTo = (button: API.Button, form: API.Form, useCaseId: number) => {
  const menuItem: MenuItemProps = {
    form: form,
    useCaseId: useCaseId,
    url: button.url,
    key: button.key,
    label: button.label,
    tooltip: button.tooltip,
    props: {}
  }
  if (button.extendProperties) {
    Object.keys(button.extendProperties).forEach((item) => {
      menuItem.props[item] = button.extendProperties[item];
    });
  }
  return menuItem;
}
