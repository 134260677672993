import { useEffect, useMemo, useRef, useState } from 'react';
import type { WdiModalAppProps } from '@/components/WdiControls';
import { FieldProps } from '@/components/WdiControls';
import type { ProFormInstance } from '@ant-design/pro-form';
import { EC } from '@/services/mdm/ec';
import { WdiSelect, WdiTreeSelect } from '@/components/WdiControls/WdiSelect';
import { Form, Input, message } from 'antd';
import { WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { ResponseStatus } from '@/utils/request';
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { Enum } from '@/enums/system';
import { SalesContractTerms } from '@/services/contract/salescontractterms';
import { DataOptions } from '@/services/basic/metadata';
import { Product } from '@/services/mdm/product';
import { RevenueType } from '@/services/mdm/revenuetype';
import {
  convertToResult,
  initSalesContractRateGrid,
  SCTRateGrid,
} from './rate-grid';
import { ModalAppTypeEnums } from './details';
import { isDupSalesContractTerms } from "@/pages/contract/SalesContractApply/components/data";
import { SoldTo } from '@/services/mdm/soldto';

const { TextArea } = Input;

const ecDataLoadingFactory = (contractType: number) => {
  return (params: any) => {
    return EC.searchEc3(
      Object.assign(
        {
          withPermissions: true,
          isActive: true,
          pageTypeIds: [contractType == Enum.SalesContractType.SELLIN
            ? Enum.PageType.CONTRACT_REBATE
            : Enum.PageType.CONTRACT_REBATE_LEVEL_2].join()
        },
        params,
      ),
    ).then((response) => {
      return response.data.map((item) => ({ value: item.ecId, label: item.ecName }));
    });
  };
};

const processModeDataLoading = (params: any) => {
  return DataOptions.search(Object.assign({ typeGroup: 13 }, params)).then((response) => {
    return response.data.map((item) => ({ value: item.value, label: item.name }));
  });
};

const productTreeDataLoading = (params: any) => {

  return Product.searchAll(Object.assign({
    productLevels: [Enum.ProductLevel.ROOT, Enum.ProductLevel.PRODUCT1, Enum.ProductLevel.PRODUCT2, Enum.ProductLevel.PRODUCT3, Enum.ProductLevel.PRODUCT4].join(),
    isActive: true,
    // bohqId: 1
  }, params)).then(
    (response) => {
      const { treeData, dict } = FieldProps.TreeSelect.formatTreeData(
        {
          textField: 'productName',
          valueField: 'productId',
          id: 'productId',
          pId: 'parentId',
          level: 'productLevel',
        },
        response.data,
      );
      return { treeData, dict };
    },
  );
};

const revenueTypeDataLoadingFactory = (contractType: number) => {
  return (params: any) => {
    return RevenueType.search(
      Object.assign(
        {
          revenueTypeIds: contractType == Enum.SalesContractType.SELLIN ? '3,4,5,6' : '5,6',
        },
        params,
      ),
    ).then((response) => {
      return response.data.map((item) => ({
        value: item.revenueTypeId,
        label: item.revenueTypeName,
      }));
    });
  };
};

const settlementFrequencyDataLoading = (params: any) => {
  return DataOptions.search(Object.assign({ typeGroup: 15 }, params)).then((response) => {
    return response.data.map((item) => ({ value: item.value, label: item.name }));
  });
};

const SalesContractTermsAutoEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [editMode, setEditMode] = useState<boolean>();
  const [isQuantity, setIsQuantity] = useState<boolean>();

  const ecDataLoading = useMemo(() => {
    return ecDataLoadingFactory(props.data?.contractType);
  }, [props.data?.contractType]);

  const revenueTypeDataLoading = useMemo(() => {
    return revenueTypeDataLoadingFactory(props.data?.contractType);
  }, [props.data?.contractType]);

  const onRevenueTypeChange = (revenueTypeId: number) => {
    if (revenueTypeId == 3 || revenueTypeId == 5) {
      setIsQuantity(true);
    } else {
      setIsQuantity(false);
    }
  };

  useEffect(() => {
    formRef.current?.setFieldsValue({
      processMode: props?.data?.row?.processMode,
    });
    if (props.data.row) {
      const type = Number(props?.type);
      setEditMode(
        type == ModalAppTypeEnums.EditApply ||
        type == ModalAppTypeEnums.EditAuto ||
        type == ModalAppTypeEnums.EditImport,
      );

      const detail = props.data.row;
      formRef.current?.setFieldsValue({
        salesContractTermsId: detail.salesContractTermsId,
        salesContractId: detail.salesContractId,
        ecId: detail.ecId,
        revenueType: detail.revenueType,
        settlementFrequency: detail.settlementFrequency,
        rateOriginal: detail.rateOriginal,
        remark: detail.remark,
      });

      if (detail.revenueType) {
        onRevenueTypeChange(detail.revenueType);
      }

      if (detail.productIdsReach) {
        Product.searchAll({ productIds: detail.productIdsReach }).then((response) => {
          if (response.data && response.data.length > 0) {
            formRef.current?.setFieldsValue({
              productIdsReach: response.data.map((product) => ({
                value: product.productId,
                label: product.productName,
              })),
            });
          }
        });
      }

      if (detail.productIdsRebate) {
        Product.searchAll({ productIds: detail.productIdsRebate }).then((response) => {
          if (response.data && response.data.length > 0) {
            formRef.current?.setFieldsValue({
              productIdsRebate: response.data.map((product) => ({
                value: product.productId,
                label: product.productName,
              })),
            });
          }
        });
      }
    }
    formRef.current?.setFieldsValue({
      salesContractRateList: initSalesContractRateGrid(props?.data?.row?.salesContractRateList),
    });
  }, [props.data.row]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 17 }}
      labelAlign="left"
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      preserve={true}
      modalProps={{
        onCancel: () => {
          props.onClose ? props.onClose() : null;
        },
        destroyOnClose: true,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        const { salesContractRateList, productIdsReach, productIdsRebate, ...entity } = values;

        const gridResponse: any = convertToResult(salesContractRateList);
        if (gridResponse.code != ResponseStatus.SUCCESS) {
          message.error(String(gridResponse?.message));
          return;
        }
        entity.salesContractRateList = gridResponse.data.data;

        if (productIdsReach) {
          entity.productIdsReach = productIdsReach.map((item: any) => item.value).join();
        }

        if (productIdsRebate) {
          entity.productIdsRebate = productIdsRebate.map((item: any) => item.value).join();
        }

        const response = await SalesContractTerms.validateDetail({
          salesContractTerms: entity,
          contractType: props.data?.contractType,
          soldToCode: props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
        } else {
          if (isDupSalesContractTerms(props.data.rows, response.data, props.data.rowIndex)) {
            message.error("合同条款已存在");
          } else {
            if (props.onSave) {
              props.onSave({
                code: ResponseStatus.SUCCESS,
                message: '',
                data: response.data,
              });
            }
          }
        }
      }}
    >
      <Form.Item name="salesContractTermsId" label="salesContractTermsId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="salesContractId" label="salesContractId" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        required
        name="ecId"
        label="费用项"
        rules={[{ required: true, message: '费用项不能为空!' }]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择费用项',
            dataLoading: ecDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        name="processMode"
        label="处理方式"
        rules={[{ required: true, message: '处理方式不能为空!' }]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择处理方式',
            disabled: true,
            dataLoading: processModeDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        name="productIdsReach"
        label="达量产品"
        rules={[{ required: true, message: '达量产品不能为空!' }]}
      >
        <WdiTreeSelect
          {...{
            multiple: true,
            placeholder: '请选择达量产品',
            dataLoading: async (params) => {
              const soldto = await SoldTo.get(props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode);

              return Product.searchAll(Object.assign({
                productLevels: [Enum.ProductLevel.ROOT, Enum.ProductLevel.PRODUCT1, Enum.ProductLevel.PRODUCT2, Enum.ProductLevel.PRODUCT3, Enum.ProductLevel.PRODUCT4].join(),
                isActive: true,
                bohqId: soldto.data.defaultBohqId
              }, params)).then(
                (response) => {
                  const { treeData, dict } = FieldProps.TreeSelect.formatTreeData(
                    {
                      textField: 'productName',
                      valueField: 'productId',
                      id: 'productId',
                      pId: 'parentId',
                      level: 'productLevel',
                    },
                    response.data,
                  );
                  return { treeData, dict };
                },
              );
            },
            // dataLoading: productTreeDataLoading,
            onExSelect: (value: any, node: any, dict: any) => {
              const values = formRef.current?.getFieldValue('productIdsReach');
              const rtv = FieldProps.TreeSelect.labeledValuesMeger(dict, values, { value });
              formRef.current?.setFieldsValue({
                productIdsReach: rtv,
              });
            },
          }}
        />
      </Form.Item>
      <Form.Item
        name="productIdsRebate"
        label="返利产品"
        rules={[{ required: true, message: '返利产品不能为空!' }]}
      >
        <WdiTreeSelect
          {...{
            multiple: true,
            placeholder: '请选择返利产品',
            dataLoading: async (params) => {
              const soldto = await SoldTo.get(props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode);

              return Product.searchAll(Object.assign({
                productLevels: [Enum.ProductLevel.ROOT, Enum.ProductLevel.PRODUCT1, Enum.ProductLevel.PRODUCT2, Enum.ProductLevel.PRODUCT3, Enum.ProductLevel.PRODUCT4].join(),
                isActive: true,
                bohqId: soldto.data.defaultBohqId
              }, params)).then(
                (response) => {
                  const { treeData, dict } = FieldProps.TreeSelect.formatTreeData(
                    {
                      textField: 'productName',
                      valueField: 'productId',
                      id: 'productId',
                      pId: 'parentId',
                      level: 'productLevel',
                    },
                    response.data,
                  );
                  return { treeData, dict };
                },
              );
            },
            // dataLoading: productTreeDataLoading,
            onExSelect: (value: any, node: any, dict: any) => {
              const values = formRef.current?.getFieldValue('productIdsRebate');
              const rtv = FieldProps.TreeSelect.labeledValuesMeger(dict, values, { value });
              formRef.current?.setFieldsValue({
                productIdsRebate: rtv,
              });
            },
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="revenueType"
        label="核算类型"
        rules={[{ required: true, message: '核算类型不能为空!' }]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择核算类型',
            dataLoading: revenueTypeDataLoading,
            onChange: onRevenueTypeChange
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="settlementFrequency"
        label="结案频率"
        rules={[{ required: true, message: '结案频率不能为空!' }]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择结案频率',
            disabled: editMode,
            dataLoading: settlementFrequencyDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        name="rateOriginal"
        label="初始比例/单位费用"
        rules={[{ required: false, message: '初始比例/单位费用不能为空!' }]}>
        <WdiInputNumber digits={6} min={0} />
      </Form.Item>
      <Form.Item
        required
        name="salesContractRateList"
        label="费用标准"
        rules={[{ required: true, message: '费用标准不能为空!' }]}
      >
        <SCTRateGrid isQuantity={isQuantity} />
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <TextArea rows={3} style={{ width: '100%' }} />
      </Form.Item>
    </WdiModalForm>
  );
};
export default SalesContractTermsAutoEditApp;
