import React, {useEffect, useRef, useState} from 'react';
import {Col, DatePicker, Form, Input, message, Row,} from 'antd';
import {ProForm, ProFormInstance} from '@ant-design/pro-form';
import {FieldProps, WdiCardTitle, WdiModalAppResult} from '@/components/WdiControls';
import {FormPageProps} from '@/components/WdiBus/FormPage';
import {ApplyFooter, ApplyFooterType} from '@/components/WdiBus/ApplyFooter';
import moment from 'moment';
import {BOHQ} from '@/services/mdm/bohq';
import {FileUploadUtility, WdiFileUpload} from '@/components/WdiControls/WdiFileUpload';
import {FormBudgetProject, FormBudgetProjectDetail} from '@/services/sales/bp/formBudgetProject';
import {BudgetProjectDetail} from '@/services/sales/bp/budgetProject';
import {PageType} from '@/services/basic/pagetype';
import {WdiSelect, WdiSelectFormat, WdiTreeSelect} from '@/components/WdiControls/WdiSelect';
import {EC} from '@/services/mdm/ec';
import {Enum} from '@/enums/system';
import {ResponseStatus} from '@/utils/request';
import BPWarningInfoApp from '../../components/warning';
import {initApplyPageContext, ViewEntityProps} from '../../components/data';
import DataStore from '@/components/GridWidget/DataStore'
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import FormBPDetailsForApplyPanel from './details';
import ApproverUserApp, {ApproverUserIds} from '../../components/ApproverUser';
import {Product} from '@/services/mdm/product';
import {WarningInfoAppProps} from '@/components/WdiBus/PanelForWarning';
import {Channel} from '@/services/mdm/channel';
import {ShopLevel} from '@/services/mdm/shoplevel';
import {SoldTo} from '@/services/mdm/soldto';

const {RangePicker} = DatePicker;
const {TextArea} = Input;

const getPageResult = (values: any, viewEntity: ViewEntityProps, pageType: API.PageType, statusId: number, dataSource: any[]) => {
  const result: WdiModalAppResult = {code: ResponseStatus.UNKNOWN};

  const {
    projectDate, applyDate, projectApproverIds, fileInfo, limitedChannelIds,
    limitedEcIds, limitedProductIds, limitedShopLevelIds, limitedSoldToCodes,
    ...entity
  } = values;
  if (FileUploadUtility.isUploading(fileInfo)) {
    result.message = '附件正在上传中!';
    return result;
  }

  if (FileUploadUtility.isError(fileInfo)) {
    result.message = '附件存在错误文件!';
    return result;
  }

  const req: { form: any, formBudgetProject: any, formBudgetProjectDetails: any[] } = {
    form: {
      statusId: statusId,
      pageTypeId: pageType.pageTypeId,
      pageGroupId: pageType.pageGroupId,
      formTypeId: viewEntity.formTypeId
    },
    formBudgetProject: {},
    formBudgetProjectDetails: []
  };

  if (viewEntity.budgetProject) {
    req.form.relatedFormId = viewEntity.relatedFormId;
    req.form.relationType = viewEntity.relationType;
    req.form.budgetProjectId = viewEntity.budgetProject.budgetProjectId;
  } else {
    if (viewEntity.formBudgetProject) {
      if (viewEntity.relationType === Enum.FormRelationType.REJECTED) {
        // 退回待修改
        req.form.relatedFormId = viewEntity.relatedFormId;
        req.form.relationType = viewEntity.relationType;
      } else {
        // 从草稿打开
        req.form.formId = viewEntity.formId;
      }
    }
    entity.modifiedReason = '新建';
  }
  if (projectDate && projectDate.length == 2) {
    entity.projectStartDate = moment(projectDate[0]).format("YYYY-MM-DD");
    entity.projectEndDate = moment(projectDate[1]).format("YYYY-MM-DD");
  } else {
    result.message = "无效的项目日期";
    return result;
  }

  if (applyDate && applyDate.length == 2) {
    entity.applyStartDate = moment(applyDate[0]).format("YYYY-MM-DD");
    entity.applyEndDate = moment(applyDate[1]).format("YYYY-MM-DD");
  }

  entity.projectApproverIds = ApproverUserIds.approverUserItemToValue(projectApproverIds);

  if (limitedChannelIds) {
    entity.limitedChannelIds = WdiSelectFormat.getvalue(limitedChannelIds);
  }

  if (limitedEcIds) {
    entity.limitedEcIds = WdiSelectFormat.getvalue(limitedEcIds);
  }
  if (limitedProductIds) {
    entity.limitedProductIds = WdiSelectFormat.getvalue(limitedProductIds);
  }
  if (limitedShopLevelIds) {
    entity.limitedShopLevelIds = WdiSelectFormat.getvalue(limitedShopLevelIds);
  }
  if (limitedSoldToCodes) {
    entity.limitedSoldToCodes = WdiSelectFormat.getvalue(limitedSoldToCodes);
  }
  const info = FileUploadUtility.serialize(fileInfo);
  entity.fileName = info?.names;
  entity.filePath = info?.urls;
  req.formBudgetProject = entity;
  if (dataSource && dataSource.length) {
    dataSource.forEach((item, itemIndex) => {
      item.rowNumber = itemIndex + 1;
    });
  } else {
    result.message = "请填写分配明细!";
    return result;
  }

  req.formBudgetProjectDetails = dataSource;
  result.data = req;
  result.code = ResponseStatus.SUCCESS;
  return result;
};

const FormBudgetProjectApplyApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const [entity, setEntity] = useState<ViewEntityProps>();
  const [pageType, setPageType] = useState<API.PageType>();
  const [boHQ, setBOHQ] = useState<API.BOHQ>();
  const [hiddenButton, setHiddenButtons] = useState<number[]>([]);
  const [warningSettings, setWarningSettings] = useState<WarningInfoAppProps>({visible: false});
  const [dataSource, setDataSource] = useState<any[]>([]);

  const [expandKeysEC, setExpandKeysEC] = useState<string[]>();
  const [expandKeysProduct, setExpandKeysProduct] = useState<string[]>();

  const onDetailsChange = (data: any) => {
    setDataSource(data);
  };

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApplyPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }

        const viewEntity = result.data;
        let limitedChannelIds, limitedEcIds, limitedProductIds, limitedShopLevelIds, projectApproverIds,
          limitedSoldToCodes;

        if (viewEntity.budgetProject) {
          formRef.current?.setFieldsValue({
            projectName: viewEntity.budgetProject.projectName,
            projectDate: [moment(viewEntity.budgetProject.projectStartDate), moment(viewEntity.budgetProject.projectEndDate)],
            projectRemark: viewEntity.budgetProject.projectRemark,
            fileInfo: FileUploadUtility.parse(viewEntity.budgetProject.fileName, viewEntity.budgetProject.filePath),
            applyDate: [moment(viewEntity.budgetProject.applyStartDate), moment(viewEntity.budgetProject.applyEndDate)],
            modifiedReason: viewEntity.budgetProject.modifiedReason,
          });
          limitedChannelIds = viewEntity.budgetProject.limitedChannelIds;
          limitedEcIds = viewEntity.budgetProject.limitedEcIds;
          limitedProductIds = viewEntity.budgetProject.limitedProductIds;
          limitedShopLevelIds = viewEntity.budgetProject.limitedShopLevelIds;
          limitedSoldToCodes = viewEntity.budgetProject.limitedSoldToCodes;
          projectApproverIds = viewEntity.budgetProject.projectApproverIds;
        } else if (viewEntity.formBudgetProject) {
          formRef.current?.setFieldsValue({
            projectName: viewEntity.formBudgetProject.projectName,
            projectDate: [moment(viewEntity.formBudgetProject.projectStartDate), moment(viewEntity.formBudgetProject.projectEndDate)],
            projectRemark: viewEntity.formBudgetProject.projectRemark,
            fileInfo: FileUploadUtility.parse(viewEntity.formBudgetProject.fileName, viewEntity.formBudgetProject.filePath),
            applyDate: [moment(viewEntity.formBudgetProject.applyStartDate), moment(viewEntity.formBudgetProject.applyEndDate)],
            modifiedReason: viewEntity.formBudgetProject.modifiedReason,
          });
          limitedChannelIds = viewEntity.formBudgetProject.limitedChannelIds;
          limitedEcIds = viewEntity.formBudgetProject.limitedEcIds;
          limitedProductIds = viewEntity.formBudgetProject.limitedProductIds;
          limitedShopLevelIds = viewEntity.formBudgetProject.limitedShopLevelIds;
          limitedSoldToCodes = viewEntity.formBudgetProject.limitedSoldToCodes;
          projectApproverIds = viewEntity.formBudgetProject.projectApproverIds;
        }

        if (limitedChannelIds) {
          Channel.search({channelIds: limitedChannelIds, isActive: true}).then(response => {
            if (response.data && response.data.length > 0) {
              formRef.current?.setFieldsValue({
                limitedChannelIds: response.data.map(channel => ({value: channel.channelId, label: channel.codeOrName}))
              });
            }
          });
        }

        if (limitedEcIds) {
          EC.search({ecIds: limitedEcIds, isActive: true}).then(response => {
            if (response.data && response.data.length > 0) {
              setExpandKeysEC(response.data.map(ec => ec.ecId.toString()));
              formRef.current?.setFieldsValue({
                limitedEcIds: response.data.map(ec => ({value: ec.ecId, label: ec.ecName}))
              });
            }
          });
        }

        if (limitedProductIds) {
          Product.searchAll({productIds: limitedProductIds, isActive: true}).then(response => {
            if (response.data && response.data.length > 0) {
              setExpandKeysProduct(response.data.map(product => product.productId.toString()));
              formRef.current?.setFieldsValue({
                limitedProductIds: response.data.map(product => ({value: product.productId, label: product.codeOrName}))
              });
            }
          });
        }

        if (limitedShopLevelIds) {
          ShopLevel.search({shopLevelIds: limitedShopLevelIds, isActive: true}).then(response => {
            if (response.data && response.data.length > 0) {
              formRef.current?.setFieldsValue({
                limitedShopLevelIds: response.data.map(shopLevel => ({
                  value: shopLevel.shopLevelId,
                  label: shopLevel.codeOrName
                }))
              });
            }
          });
        }

        if (limitedSoldToCodes) {
          SoldTo.search({soldToCodes: limitedSoldToCodes, isActive: true, withPermissions: true}).then(response => {
            if (response.data && response.data.length > 0) {
              formRef.current?.setFieldsValue({
                limitedSoldToCodes: response.data.map(soldTo => ({value: soldTo.soldToCode, label: soldTo.codeOrName}))
              });
            }
          });
        }

        if (projectApproverIds) {
          ApproverUserIds.fetchUserForDataSource(projectApproverIds, (data) => {
            formRef.current?.setFieldsValue({
              projectApproverIds: data
            });
          });
        }

        setEntity(viewEntity);

        if (viewEntity.bohqId) {
          BOHQ.get(viewEntity.bohqId).then((response) => {
            setBOHQ(response.data);
            formRef.current?.setFieldsValue({
              bohqId: response.data.bohqId,
              bohqName: response.data.bohqName
            });
          });
        }

        if (viewEntity.pageTypeId) {
          PageType.get(viewEntity.pageTypeId).then((response) => {
            setPageType(response.data);
          });
        }

        if (viewEntity.budgetProject) {
          BudgetProjectDetail.query(viewEntity.budgetProject.budgetProjectId, viewEntity.budgetProject.buId).then((response) => {
            setDataSource(DataStore.init(response.data));
          });
        } else if (viewEntity.formBudgetProject) {
          FormBudgetProjectDetail.query(viewEntity.formBudgetProject.formBudgetProjectId, viewEntity.formBudgetProject.buId).then((response) => {
            setDataSource(DataStore.init(response.data));
          });
        } else {
          setDataSource(DataStore.init([]));
        }

        if (viewEntity.budgetProject
          || (viewEntity.formBudgetProject && viewEntity.relationType === Enum.FormRelationType.REJECTED)) {
          setHiddenButtons(hiddenButton.concat(ApplyFooterType.Draft));
        }
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && pageType && entity) {
      let title = "专项项目申请";
      if (entity.budgetProject) {
        title += '【编辑】'
      }
      props.setPageTitle(title);
    }
  }, [props.setPageTitle, pageType, entity]);

  return (
    <>
      <BPWarningInfoApp {...warningSettings} />
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (
              <ApplyFooter
                setLoading={props.setLoading}
                hiddenButton={hiddenButton}
                viewEntity={entity}
                submitBackUrl="/project/budgetproject"
                doSubmit={async (type: number, forceSubmit: boolean) => {
                  if (!entity || !pageType || !dataSource) {
                    return;
                  }
                  const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                  if (values && values.errorFields && values.errorFields.length) {
                    return;
                  }
                  const statusId = type === ApplyFooterType.Draft ? Enum.FormStatus.DRAFT : Enum.FormStatus.AWAITING;
                  const req = getPageResult(values, entity, pageType, statusId, dataSource);

                  if (req.code !== ResponseStatus.SUCCESS) {
                    message.error(req.message);
                    return;
                  }
                  props?.setLoading?.(true);
                  if (type === ApplyFooterType.Validate) {
                    return await FormBudgetProject.Buttons.validate(req.data);
                  } else {
                    return await FormBudgetProject.Buttons.apply({
                      forceSubmit: forceSubmit,
                      ...req.data
                    });
                  }
                }}
                del={async () => {
                  if (!entity || !entity.formId) {
                    return;
                  }
                  return await FormBudgetProject.Buttons.del(entity.formId);
                }}
                showWarning={(result: WdiModalAppResult, submitCallback: any) => {
                  setWarningSettings({
                    visible: true,
                    title: "警示信息",
                    wdiPanelList: result.data,
                    onClose: () => {
                      setWarningSettings({visible: false});
                    },
                    onSave: async () => {
                      await submitCallback();
                    },
                  });
                }}
              />
            )
          }
        }}
      >
        <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.BASE_INFO} title='项目基本信息'/>}>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="projectName" label="项目名称" rules={[{required: true, message: '项目名称不能为空!'}]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item required name="projectDate" label="项目起止日期"
                         rules={[{required: true, message: '项目起止日期不能为空!'}]}>
                <RangePicker/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item required name="bohqName" label="费用归属"
                         rules={[{required: true, message: '费用归属不能为空!'}]}>
                <Input readOnly/>
              </Form.Item>
              <Form.Item name="bohqId" label="bohqId" hidden>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4} style={{display: 'none'}}>
              <Form.Item name="projectApproverIds" label="附加审批人">
                <ApproverUserApp/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16}>
              <Form.Item name="projectRemark" label="方案简述" rules={[{message: '方案简述不能为空!'}]}>
                <TextArea rows={4} maxLength={2000}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="fileInfo"
                label="附件"
                valuePropName='fileList'
                getValueFromEvent={(e: any) => {
                  return e.fileList || [];
                }}
              >
                <WdiFileUpload leftTopInfo="请选择文件并上传"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16} hidden={(entity?.budgetProject) ? false : true}>
              <Form.Item name="modifiedReason" label="修改原因"
                         rules={[{required: (entity?.budgetProject) ? true : false, message: '修改原因不能为空!'}]}>
                <TextArea rows={4}/>
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.LIMITED_INFO} title='维度信息'/>}>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={4}>
              <Form.Item required name="applyDate" label="活动申请时间限制"
                         rules={[{required: true, message: '活动申请时间限制不能为空!'}]}>
                <RangePicker/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="limitedChannelIds" label="渠道类型维度">
                <WdiSelect
                  {...{
                    mode: "multiple",
                    placeholder: "请选择渠道类型",
                    remote: true,
                    labelInValue: true,
                    style: {width: '100%'},
                    dataLoading: async (params) => {
                      const responseResult = await Channel.ajaxSearch(Object.assign({isActive: true}, params));
                      return responseResult.data?.map((item) => {
                        return {value: item.channelId, label: item.codeOrName};
                      });
                    }
                  }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="limitedEcIds" label="费用项维度">
                <WdiTreeSelect {...{
                  placeholder: "请选择费用项",
                  multiple: true,
                  defaultExpandKeys: expandKeysEC,
                  dataLoading: async (params) => {
                    const response = await EC.searchEc3(Object.assign({
                      boHqId: entity?.bohqId,
                      toParentLevel: String(Enum.ECLevel.EC1),
                      isActive: true
                    }, params));
                    const {treeData, dict} = FieldProps.TreeSelect.formatTreeData({
                      textField: "ecName",
                      valueField: "ecId",
                      id: "ecId",
                      pId: "parentId",
                      level: "ecLevel",
                      disabledLevel: [Enum.ECLevel.ROOT, Enum.ECLevel.EC1, Enum.ECLevel.EC2],
                    }, response.data);
                    return {treeData, dict};
                  },
                  reloadKey: entity?.bohqId
                }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="limitedProductIds" label="产品别维度">
                <WdiTreeSelect {...{
                  placeholder: "请选择产品别",
                  multiple: true,
                  defaultExpandKeys: expandKeysProduct,
                  dataLoading: async (params) => {
                    const response = await Product.searchNoRoot(Object.assign({
                      productType:1,
                      isActive: true
                    }, params));
                    const {treeData, dict} = FieldProps.TreeSelect.formatTreeData({
                      textField: "productName",
                      valueField: "productId",
                      id: "productId",
                      pId: "parentId"
                    }, response.data);
                    return {treeData, dict};
                  },
                  onExSelect: (value: any, node: any, dict: any) => {
                    const values = formRef.current?.getFieldValue("limitedProductIds");
                    const rtv = FieldProps.TreeSelect.labeledValuesMeger(dict, values, {value});
                    formRef.current?.setFieldsValue({limitedProductIds: rtv});
                  }
                }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="limitedShopLevelIds" label="门店等级维度">
                <WdiSelect
                  {...{
                    mode: "multiple",
                    placeholder: "请选择门店等级",
                    showSearch: true,
                    remote: true,
                    labelInValue: true,
                    style: {width: '100%'},
                    dataLoading: async (params) => {
                      const responseResult = await ShopLevel.ajaxSearch(Object.assign({isActive: true}, params));
                      return responseResult.data?.map((item) => {
                        return {value: item.shopLevelId, label: item.codeOrName};
                      });
                    }
                  }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="limitedSoldToCodes" label="售达方维度">
                <WdiSelect
                  {...{
                    mode: "multiple",
                    placeholder: "请选择售达方",
                    showSearch: true,
                    remote: true,
                    labelInValue: true,
                    dataLoading: async (params) => {
                      const responseResult = await SoldTo.ajaxSearch(Object.assign({
                        isActive: true,
                        withPermissions: true
                      }, params));
                      return responseResult.data?.map((item) => {
                        return {value: item.soldToCode, label: item.codeOrName};
                      });
                    }
                  }} />
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <FormBPDetailsForApplyPanel
          data={{pageTypeId: pageType?.pageTypeId, bohqId: boHQ?.bohqId, budgetProject: entity?.budgetProject}}
          dataSource={dataSource}
          onChange={onDetailsChange}
        />
      </ProForm>
    </>
  );
};
export default FormBudgetProjectApplyApp;
