export type FieldConfigItem = {
  req: boolean;
  contain: boolean;
}

const parse = (value: string) => {
  if (value) {
    return value.split("-");
  }
  return '0-0-0-0'.split("-");
}

const getFieldConfigItem = (keys: string[], values: string[]) => {
  const config = { req: false, contain: false };
  values.forEach((item, itemIndex) => {
    const k = window.parseInt(keys[itemIndex]);
    let v = window.parseInt(item);
    config.contain = config.contain || (k & v) != 0;
    config.req = config.req || ((k * 2) & v) != 0;
  })
  return config;
}

export namespace FieldConfigUtility {
  export const get = (key: string, value?: string) => {
    const keys = parse(key);
    const values = parse(value || '0-0-0-0');
    return getFieldConfigItem(keys, values);
  };

  export const and = (key: string, value?: string) => {
    return get(key, value).req;
  };

  export const add = (key: string, req: boolean, value?: string) => {
    const keys = parse(key);
    const values = parse(value || '0-0-0-0');
    const result: number[] = [];
    values.forEach((item, itemIndex) => {
      const k = window.parseInt(keys[itemIndex]);
      let v = window.parseInt(item);
      let r = k | v;
      if (req) {
        r = r | ((k * 2) | v);
      }
      result.push(r);
    })
    return result.join("-");
  };

  export const reduce = (key: string, value?: string) => {
    const keys = parse(key);
    const values = parse(value || '0-0-0-0');
    const result: number[] = [];
    values.forEach((item, itemIndex) => {
      const k = window.parseInt(keys[itemIndex]);
      let v = window.parseInt(item);
      v = (~k & v);
      v = (~(k * 2)) & v;
      result.push(v);
    })
    return result.join("-");
  };

  export const merge = (first?: string, second?: string) => {
    const firsts = parse(first || '0-0-0-0');
    const seconds = parse(second || '0-0-0-0');
    const result: number[] = [];
    [1, 2, 3, 4].forEach((item, itemIndex) => {
      const value1 = window.parseInt(firsts[itemIndex]);
      const value2 = window.parseInt(seconds[itemIndex]);
      result.push(value1 | value2);
    })
    return result.join("-");
  };
}

export const getEcFieldsValue = (ecFieldConfigs: Record<string, { contain: boolean, req: boolean }> | undefined, ecFields: Record<string, any>, values: any) => {
  const ecFieldValues: any = {};
  if (ecFieldConfigs) {
    Object.keys(ecFieldConfigs).map(key => {
      if (ecFieldConfigs[key].contain) {
        if (ecFields[key] && ecFields[key].get) {
          ecFieldValues[key] = ecFields[key].get(values[key]);
        } else {
          ecFieldValues[key] = values[key];
        }
      }
    });
  }
  return ecFieldValues;
};

export const getEcDefFields = (ecFieldConfigs: API.EcFieldConfig[], extendFieldsSa: string | undefined) => {
  const defFields: string[] = [];
  ecFieldConfigs.forEach((fieldDef: API.EcFieldConfig) => {
    const item = FieldConfigUtility.get(fieldDef.bitValue, extendFieldsSa);
    if (item.contain) {
      defFields.push(fieldDef.fieldKey);
    }
  });
  return defFields;
};