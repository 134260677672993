import { useEffect, useRef } from 'react';
import { FieldProps, WdiModalAppProps } from '@/components/WdiControls';
import { ProFormInstance } from '@ant-design/pro-form';
import { EC } from '@/services/mdm/ec';
import { WdiSelect, WdiSelectFormat, WdiTreeSelect } from '@/components/WdiControls/WdiSelect';
import { Form, Input, message } from 'antd';
import { WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { BO } from '@/services/mdm/bo';
import { ResponseStatus } from '@/utils/request';
import { FormBudgetProject } from '@/services/sales/bp/formBudgetProject';
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { Enum } from '@/enums/system';

const { TextArea } = Input;

const ModalAppTypeEnums = {
  None: 0,
  Insert: 1,
  Edit: 2,
  Import: 3,
};

const FormBPDetailEditApp: React.FC<WdiModalAppProps> = (props) => {

  const formRef = useRef<ProFormInstance>();
  useEffect(() => {
    if (props.data.row) {
      const detail = props.data.row;
      formRef.current?.setFieldsValue({
        budgetProjectDetailId: detail.budgetProjectDetailId,
        formBudgetProjectDetailId: detail.formBudgetProjectDetailId,
        rowNumber: detail.rowNumber,
        boId: { label: detail.boName, value: detail.boId },
        ecId: { label: detail.ecName, value: detail.ecId },
        amount: detail.amount,
        remark: detail.remark,
      });
    }
  }, [props.data.row]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      labelAlign='left'
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      modalProps={{
        onCancel: () => { props.onClose ? props.onClose() : null },
        destroyOnClose: true,
        maskClosable: false
      }}
      onFinish={async (values) => {
        const { boId, ecId, ...entity } = values;
        const response = await FormBudgetProject.Buttons.validateDetail({
          rowIndex: props.data.rowIndex,
          formBudgetProject: {
            pageTypeId: props.data.pageTypeId
          },
          formBudgetProjectDetail: { ...entity, boId: WdiSelectFormat.getvalue(boId), ecId: WdiSelectFormat.getvalue(ecId) },
          formBudgetProjectDetails: props.data.rows
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
        } else {
          if (props.onSave) {
            props.onSave(response);
          }
        }
      }}
    >
      <Form.Item name="budgetProjectDetailId" label="budgetProjectDetailId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="formBudgetProjectDetailId" label="formBudgetProjectDetailId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="rowNumber" label="rowNumber" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="boId"
        label="预算中心"
        rules={[{ required: !(props.type === ModalAppTypeEnums.Edit), message: '预算中心不能为空!' }]}>
        <WdiTreeSelect {...{
          placeholder: "请选择预算中心",
          labelInValue: true,
          dataLoading: async (params) => {
            const response = await BO.search({ isActive: true, withPermissions: true, boLevels: [Enum.BOLevel.BO1, Enum.BOLevel.BO2, Enum.BOLevel.BO3, Enum.BOLevel.BO4].join() });
            const { treeData, dict } = FieldProps.TreeSelect.formatTreeData({
              textField: "boName",
              valueField: "boId",
              id: "boId",
              pId: "parentId"
            }, response.data);
            return { treeData, dict };
          },
          disabled: props.type === ModalAppTypeEnums.Edit
        }} />
      </Form.Item>
      <Form.Item
        name="ecId"
        label="费用小类"
        rules={[{ required: !(props.type === ModalAppTypeEnums.Edit), message: '费用小类不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择费用小类",
          showSearch: true,
          labelInValue: true,
          dataLoading: async (params) => {
            const response = await EC.searchEc3(Object.assign({ toParentLevel: Enum.ECLevel.EC2, isActive: true, pageTypeIds: String(Enum.PageType.PROJECT_GENERAL), bohqId: props.data.bohqId }, params));
            return response.data.filter((data) => data?.ecLevel == Enum.ECLevel.EC2).map((item) => { return { value: item.ecId, label: item.ecName }; });
          },
          disabled: props.type === ModalAppTypeEnums.Edit
        }} />
      </Form.Item>
      <Form.Item name="amount" label="分配金额" rules={[{ required: true, message: '分配金额不能为空!' }]}>
        <WdiInputNumber digits={2} />
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <TextArea rows={3} style={{ width: '100%' }} maxLength={2000} />
      </Form.Item>
    </WdiModalForm>
  );
}
export default FormBPDetailEditApp;
