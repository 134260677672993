import { ParamsProps } from '@/components/WdiBus/FormPage';
import { Enum } from '@/enums/system';
import { ResponseStatus } from '@/utils/request';
import { WdiModalAppResult } from '@/components/WdiControls';
import { FormApi } from '@/services/sales';
import { FormRs, FormRsDetail } from '@/services/sales/rs';


export type ViewEntityProps = {
  form?: API.Form,
  formRs?: API.FormRs,
  // paymentMethod?: API.DataOptions,
  // supplier?: API.Supplier,
  formRsDetails?: API.FormRsDetail[],
  // vatType?: API.VatType;  
  relationType?: number,
  relatedFormId?: number,
  formId?: number;
  statusId?: number;
  formTypeId?: number;
  pageTypeId?: number;
  userId?: number;
  positionId?: number;
  deptId?: number;
}

export const initApplyPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};

  if (params.formId || params.relatedFormId) {
    // 退回待修改
    const formResponse = await FormApi.get(params.formId || params.relatedFormId || 0);
    viewEntity.form = formResponse.data;
    if (!viewEntity.form) {
      result.message = "无效的退回待修改单";
      return result;
    }
    if (params.formId) {
      viewEntity.formId = params.formId;
    } else {
      viewEntity.relationType = Enum.FormRelationType.REJECTED;
      viewEntity.relatedFormId = params.relatedFormId;
    }
    const formrSRespone = await FormRs.get(viewEntity.form.formId);
    viewEntity.formRs = formrSRespone.data;
   
    // viewEntity.paymentMethodId = viewEntity.formSp.paymentMethodId;
    // viewEntity.supplierId = viewEntity.formSp.supplierId;
    viewEntity.userId = viewEntity.form.userId;
    viewEntity.positionId = viewEntity.form.positionId;
    viewEntity.deptId = viewEntity.form.deptId;
    viewEntity.pageTypeId = viewEntity.form.pageTypeId;
    viewEntity.formTypeId = viewEntity.form.formTypeId;
  } else {
    // 直接申请
    // viewEntity.paymentMethodId = params.paymentMethodId;
    // viewEntity.supplierId = params.supplierId;
   
    viewEntity.pageTypeId = params.pageTypeId;
    viewEntity.formTypeId = params.formTypeId;
    // viewEntity.reimburseType = params.reimburseType;
    viewEntity.userId = params.userId;
    viewEntity.positionId = params.positionId;
    viewEntity.deptId = params.deptId;
  }

  if (viewEntity && viewEntity.formId) {
    const formRsDetailsResponse = await FormRsDetail.getDetailForOninvoice(viewEntity.formId);
    viewEntity.formRsDetails = formRsDetailsResponse.data;
  }
  
  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
}

export const initApprovalPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId) {
    const formRsResponse = await FormRs.get(params.formId);
    viewEntity.formRs = formRsResponse.data;
    if (!viewEntity.formRs) {
      result.message = "无效的单据";
      return result;
    }
    const formResponse = await FormApi.get(params.formId);
    viewEntity.form = formResponse.data;

    const formRsDetailsResponse = await FormRsDetail.getDetailForOninvoice(params.formId);
    viewEntity.formRsDetails = formRsDetailsResponse.data;

  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
};

