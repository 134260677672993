import React, {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Col, Form, Input, message, Row,} from 'antd';
import ProForm, {ProFormInstance} from '@ant-design/pro-form';
import {WdiInput, WdiModalAppResult, WdiModalProps} from '@/components/WdiControls';
import {FileUploadUtility, WdiFileUpload} from '@/components/WdiControls/WdiFileUpload';
import {PageType} from '@/services/basic/pagetype';
import {Enum} from '@/enums/system';
import {ResponseStatus} from '@/utils/request';
import {FormPageProps} from '@/components/WdiBus/FormPage';
import DataStore from '@/components/GridWidget/DataStore';
import {ApplyFooter, ApplyFooterType} from '@/components/WdiBus/ApplyFooter';
import {initApplyPageContext, ViewEntityProps} from '../../components/data';
import {FormSp} from '@/services/sales/sp';
import GeneralPaymentDetailsForApplyPanel from '../components/detail';
import {WarningInfoAppProps} from "@/components/WdiBus/PanelForWarning";
import SPWarningInfoApp from "@/pages/sales/sp/components/WarningInformation";
import {SoldTo} from "@/services/mdm/soldto";
import PrePayDetail from "@/pages/sales/sp/components/PrePayDetail";

const {TextArea} = Input;

const getPageResult = (values: any, viewEntity: ViewEntityProps, pageType: API.PageType, statusId: number, dataSource: any[], bohqId: number) => {
  const result: WdiModalAppResult = {code: ResponseStatus.UNKNOWN};

  const {reimburseMaterials, attachment, ...entity} = values;
  if (FileUploadUtility.isUploading(attachment)) {
    result.message = '附件正在上传中!';
    return result;
  }
  if (FileUploadUtility.isError(attachment)) {
    result.message = '附件存在错误文件!';
    return result;
  }
  const req: {
    batchFormSp: any, formSpDetails: any[],
  } = {
    batchFormSp: {
      statusId: statusId,
      bohqId: bohqId,
      pageTypeId: Enum.PageType.PAYMENT_CUST_SYS,
      pageGroupId: pageType?.pageGroupId,
      formTypeId: Enum.FormType.ACTIVITY_PAYMENT,
      userId: viewEntity.userId,
      positionId: viewEntity.positionId,
      deptId: viewEntity.deptId
    },
    formSpDetails: [],
  };

  if (viewEntity.formSp) {
    if (viewEntity.relationType === Enum.FormRelationType.REJECTED) {
      // 退回待修改
      req.batchFormSp.relatedFormId = viewEntity.relatedFormId;
      req.batchFormSp.relationType = viewEntity.relationType;
    } else {
      // 从草稿打开
      req.batchFormSp.formId = viewEntity.formId;
    }
  }
  const info = FileUploadUtility.serialize(attachment);
  entity.fileName = info?.names;
  entity.filePath = info?.urls;
  if (reimburseMaterials) {
    entity.reimburseMaterial = reimburseMaterials.join();
  }
  req.batchFormSp.fileName = entity.fileName;
  req.batchFormSp.filePath = entity.filePath;
  req.batchFormSp.bohqId = bohqId;
  req.batchFormSp.remark = values.remark;

  req.formSpDetails = dataSource;
  for (let i = 0; i < req.formSpDetails.length; i++) {
    const detailRow = req.formSpDetails[i];
    detailRow.rowNumber = i + 1;
    detailRow.formSpDetailId = detailRow.formSpDetailId || 0;
    detailRow.formSpId = detailRow.formSpId || 0;
    detailRow.amountExVat = Number(detailRow.amountExVat);
    detailRow.tax = Number(detailRow.tax) || 0;
  }

  result.data = req;
  result.code = ResponseStatus.SUCCESS;
  return result;
}

const GeneralPaymentApplyApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [entity, setEntity] = useState<any>();
  const [pageType, setPageType] = useState<any>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [warningSettings, setWarningSettings] = useState<WarningInfoAppProps>({visible: false});
  const [hiddenPrePay, setHiddenPrePay] = useState<any>(true);
  const [modalProp, setModalProp] = useState<WdiModalProps>({visible: false});
  const [prePayParam, setPrePayParam] = useState<any>({});
  const [bohqId, setBohqId] = useState<number>();
  const [hiddenButton, setHiddenButton] = useState<boolean>(false);
  const onDetailsChange = (data: any) => {
    setDataSource(data);
  };
  const onPrepayClick = (evt: any) => {
    if (evt && evt.target) {
      setModalProp({visible: true, title: "预付信息", id: null, data: prePayParam});
    }
  };
  const onClose = () => {
    setModalProp({visible: false, title: "", id: null});
  }
  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApplyPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;

        if (viewEntity.relatedFormId || viewEntity.formId) {
          setHiddenButton(true);
        }

        if (viewEntity.formSp) {
          formRef.current?.setFieldsValue({
            remark: viewEntity.formSp.remark,
            attachment: FileUploadUtility.parse(viewEntity.formSp.fileName, viewEntity.formSp.filePath),
            isForPrePay: viewEntity.formSp.isForPrePay,
          });
        }

        if (viewEntity.bohq) {
          formRef.current?.setFieldsValue({
            bohqName: viewEntity.bohq.bohqName,
          });
          setBohqId(viewEntity.bohq.bohqId);
        }
        if (viewEntity.soldToCode) {
          SoldTo.get(viewEntity.soldToCode).then(response => {
            if (response?.data.soldToMasterCode) {
              setHiddenPrePay(false);
            }
            setPrePayParam({
              soldToMasterCode: response?.data.soldToMasterCode,
              prepayType: 2,
              startYear: viewEntity.startYear,
              endYear: viewEntity.endYear,
            });
          });
        }

        if (viewEntity?.pageTypeId) {
          PageType.get(viewEntity.pageTypeId).then(response => {
            setPageType(response.data);
          });
        }

        if (viewEntity.formSpDetails) {
          setDataSource(DataStore.init(viewEntity.formSpDetails));
        }
        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && bohqId) {
      let title = "活动付款申请";
      if (entity?.formSp) {
        title += '【编辑】'
      }
      props.setPageTitle(title);
    }
  }, [props.setPageTitle, bohqId, entity]);

  return (
    <>
      <SPWarningInfoApp {...warningSettings} />
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (
              <ApplyFooter
                setLoading={props.setLoading}
                hiddenButton={[ApplyFooterType.Validate, ApplyFooterType.Delete, ApplyFooterType.Draft]}
                viewEntity={entity}
                submitBackUrl="/sales/sp/mypayment"
                doSubmit={async (type: number, forceSubmit: boolean) => {
                  if (!entity || !bohqId) {
                    return;
                  }
                  const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                  if (values && values.errorFields && values.errorFields.length) {
                    return;
                  }
                  const statusId = type === ApplyFooterType.Draft ? Enum.FormStatus.DRAFT : Enum.FormStatus.AWAITING;
                  const req = getPageResult(values, entity, pageType, statusId, dataSource, bohqId);
                  if (req.code !== ResponseStatus.SUCCESS) {
                    message.error(req.message);
                    return;
                  }
                  props?.setLoading?.(true);
                  return await FormSp.Buttons.batchApply({
                    forceSubmit: forceSubmit,
                    ...req.data
                  });
                }}              
                showWarning={(result: WdiModalAppResult, submitCallback: any) => {
                  setWarningSettings({
                    visible: true,
                    title: '警示信息',
                    wdiPanelList: result.data,
                    onClose: () => {
                      setWarningSettings({visible: false});
                    },
                    onSave: async () => {
                      return await submitCallback();
                    },
                  });
                }}
              />
            )
          }
        }}
      >
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" span={4}>
            <Form.Item name="bohqName" label="费用归属">
              <WdiInput readOnly/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={3} hidden={hiddenPrePay}>
            <Form.Item name="isForPrePay" label="是否冲销预付" valuePropName="checked">
              <Checkbox/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={4} hidden={hiddenPrePay}>
            <Button type="link" style={{marginTop: -7}} onClick={onPrepayClick}>预付信息</Button>
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" span={16}>
            <Form.Item name="remark" label="备注">
              <TextArea rows={4} maxLength={2000}/>
            </Form.Item>
          </Col>
          <Col className="gutter-ferow" span={8}>
            <Form.Item
              name="attachment"
              label="附件"
              valuePropName='fileList'
              getValueFromEvent={(e: any) => {
                return e.fileList || [];
              }}
            >
              <WdiFileUpload leftTopInfo="请选择文件并上传"/>
            </Form.Item>
          </Col>
        </Row>

        <GeneralPaymentDetailsForApplyPanel
          dataSource={dataSource}
          data={{bohqId: bohqId, hidden: hiddenButton}}
          onChange={onDetailsChange}/>
      </ProForm>
      {modalProp.visible &&
        <PrePayDetail
          {...modalProp}
          onClose={onClose}
        />}
    </>
  );
};
export default GeneralPaymentApplyApp;
