import {wdiCore, WdiModalAppResult} from "@/components/WdiControls";
import {ResponseStatus} from "@/utils/request";
import {PoolPrepayReverse} from "@/services/sales/financial/poolprepayreverse";
import {Pool} from "@/services/sales/financial/pool";
import {SP} from "@/services/report/sp";
import {Enum} from "@/enums/system";

export type DataSummary = {
  amountPrepay: 0,
  amountReimbursement: 0,
  amountPayment: 0,

}
export const calcPositiveVoucherSummary = (formSpDetailDataSource: any[], poolPrepayDetails: any[]) => {
  const dataSummary: DataSummary = {
    amountPrepay: 0,
    amountReimbursement: 0,
    amountPayment: 0,
  };
  let amountPrepay = 0, amountReimbursement = 0, amountPayment = 0;
  formSpDetailDataSource.forEach(item => {
    amountReimbursement += item.spAmount;
  })
  poolPrepayDetails.forEach(item => {
    amountPrepay += item.amountPrepay;
  });
  amountPayment = (amountReimbursement ? amountReimbursement : 0) - (amountPrepay ? amountPrepay : 0)
  dataSummary.amountPrepay = wdiCore.Number.ToNumber(amountPrepay, 2);
  dataSummary.amountReimbursement = wdiCore.Number.ToNumber(amountReimbursement, 2);
  dataSummary.amountPayment = wdiCore.Number.ToNumber(amountPayment, 2);
  return dataSummary;
};

export type ViewEntityProps = {
  poolPrepayReverse?: API.PoolPrepayReverse,
  poolPrepayDetails?: API.PoolPrepayQuery[],
  formSpDetails?: any[],
  buttonHidden?: boolean,
  confirmButtonHidden?: boolean,
  updateButtonHidden?: boolean,
  inputHidden?: boolean,
}
export const initApplyPageContext = async (params: any) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  const poolPrepayReverse = await PoolPrepayReverse.get(params.data.poolPrepayReverseId);
  viewEntity.poolPrepayReverse = poolPrepayReverse.data;
  if (viewEntity.poolPrepayReverse?.paymentMethodId == Enum.PaymentMethod.BILL_DEDUCT) {
    viewEntity.inputHidden = true;
  }

  const poolPrepayDetails = await Pool.get(poolPrepayReverse.data.poolPrepayReverseId);
  const formSpDetails = await SP.get(poolPrepayReverse.data.poolPrepayReverseId);
  viewEntity.poolPrepayDetails = poolPrepayDetails.data;
  viewEntity.formSpDetails = formSpDetails.data;

  const promise = await PoolPrepayReverse.allowCancel({formId: params.data.poolPrepayReverseId});
  if (viewEntity.poolPrepayReverse?.statusId == 2 || viewEntity.poolPrepayReverse?.statusId == 1) {
    if (promise.data == true) {
      viewEntity.buttonHidden = true
      if (poolPrepayReverse.data.paymentMethodId != Enum.PaymentMethod.BILL_DEDUCT) {
        viewEntity.updateButtonHidden = true;
      }
    }
  }
  if (viewEntity.buttonHidden == true && viewEntity.poolPrepayReverse?.statusId == 1) {
    if (promise.data == true) {
      viewEntity.confirmButtonHidden = true;
    }

  }
  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
}
