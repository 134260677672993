import { useEffect, useRef, useState } from 'react';
import { WdiModalAppProps, WdiCardTitle, wdiCore, WdiInput, WdiTextArea, FieldProps } from '@/components/WdiControls';
import { ProFormInstance } from '@ant-design/pro-form';
import { WdiSelect, WdiTreeSelect } from '@/components/WdiControls/WdiSelect';
import { Col, DatePicker, Form, Input, message, Row, Table, Typography } from 'antd';
import { WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { ResponseStatus } from '@/utils/request';
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { Product } from '@/services/mdm/product';
import { ShipTo } from '@/services/mdm/shipto';
import moment from 'moment';
import { FormSa } from '@/services/sales/sa';
import DataStore from '@/components/GridWidget/DataStore';
import { WdiTable } from '@/components/WdiControls/WdiTable';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { FormGoodsShipping } from '@/services/sales/sp';
import { Enum } from '@/enums/system';

const { TextArea } = Input;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: any;
  index: number;
  children: React.ReactNode;
}

const FormSADetailEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [formGoodsDetail, setFormGoodsDetail] = useState<API.FormGoodsDetailVo>();
  const editingFormGoodsDetail = useRef<API.FormGoodsDetailVo>();
  const [editingKey, setEditingKey] = useState('');//当前编辑的行

  const isEditing = (record: any) => { return record.uid === editingKey };

  const edit = (record: Partial<any> & { key: React.Key }) => {
    //重新获取ProductCode、ProductName
    Product.get(record.productId).then(productResponse => {
      Product.get(record.budgetProductId).then(budgetProductResponse => {
        const formGoodsDetail: API.FormGoodsDetailVo = {
          productId: record.productId,
          productCode: productResponse.data.productCode,
          productName: productResponse.data.productName,
          budgetProductId: record.budgetProductId,
          budgetProductCode: budgetProductResponse.data.productCode,
          budgetProductName: budgetProductResponse.data.productName,
          quantity: record.quantity,
          unitPrice: record.unitPrice,
          amount: record.amount,
          uom: record.uom,
          uid: record.uid
        };

        editingFormGoodsDetail.current = formGoodsDetail;
        formRef.current?.setFieldsValue({
          productId: {
            value: formGoodsDetail?.productId,
            label: wdiCore.String.CodeOrName(formGoodsDetail?.productCode, formGoodsDetail?.productName)
          },
          budgetProductId: {
            value: formGoodsDetail?.budgetProductId,
            label: wdiCore.String.CodeOrName(formGoodsDetail?.budgetProductCode, formGoodsDetail?.budgetProductName)
          },
          quantity: formGoodsDetail.quantity,
          unitPrice: formGoodsDetail.unitPrice,
          amount: formGoodsDetail.amount,
          uom: formGoodsDetail.uom
        });
      })
    });
    setEditingKey(record.uid);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key: React.Key) => {
    if (!validateEditItem()) {
      return false;
    }

    const row = editingFormGoodsDetail.current;
    const newData = [...dataSource];
    const index = newData.findIndex(item => key === item.uid);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
    } else {
      newData.push(row);
    }
    setDataSource(newData);
    setEditingKey('');
    editingFormGoodsDetail.current = undefined;
    return true;
  }
  const del = (key: any) => {
    if (window.confirm("确认要删除么")) {
      setDataSource(DataStore.del(dataSource, key));
    }
  };

  const disabledDate = (current: any) => {
    if (current && current < Date.now() + 8.64e7) {
      return true;
    }
    return false;
  };

  const validateEditItem = () => {
    if (!editingFormGoodsDetail.current?.productId) {
      message.error("申领产品不能为空");
      return false;
    }
    if (!editingFormGoodsDetail.current?.budgetProductId) {
      message.error("费用产品别不能为空");
      return false;
    }
    if (!editingFormGoodsDetail.current.quantity) {
      message.error("申领数量不能为空");
      return false;
    }
    if (editingFormGoodsDetail.current.quantity <= 0) {
      message.error("申领数量不是正整数");
      return false;
    }
    if (!editingFormGoodsDetail.current.unitPrice) {
      message.error(`申领产品【${editingFormGoodsDetail.current.productCode}】找不到价格`);
      return false;
    }
    if (!editingFormGoodsDetail.current.amount || editingFormGoodsDetail.current.amount <= 0) {
      message.error("申领金额不能等于0");
      return false;
    }
    if (dataSource.findIndex(item => { return item.productId == editingFormGoodsDetail.current?.productId && item.uid != editingFormGoodsDetail.current?.uid }) >= 0) {
      message.error(`申领产品【${editingFormGoodsDetail.current.productCode} 】已申领，请检查`);
      return false;
    }
    return true;
  }
  const validateItem = () => {
    if (!formGoodsDetail?.productId) {
      message.error("申领产品不能为空");
      return false;
    }
    if (!formGoodsDetail?.budgetProductId) {
      message.error("费用产品别不能为空");
      return false;
    }
    if (!formGoodsDetail.quantity) {
      message.error("申领数量不能为空");
      return false;
    }
    if (formGoodsDetail.quantity <= 0) {
      message.error("申领数量不是正整数");
      return false;
    }
    if (!formGoodsDetail.unitPrice) {
      message.error(`申领产品【${formGoodsDetail.productCode}】找不到价格`);
      return false;
    }
    if (!formGoodsDetail.amount || formGoodsDetail.amount <= 0) {
      message.error("申领金额不能等于0");
      return false;
    }

    if (dataSource.findIndex(item => { return item.productId == formGoodsDetail.productId }) >= 0) {
      message.error(`申领产品【${formGoodsDetail.productCode} 】已申领，请检查`);
      return false;
    }
    return true;
  }
  const addItem = () => {
    if (!validateItem()) {
      return false;
    }
    setDataSource(DataStore.add(dataSource, formGoodsDetail));
    setFormGoodsDetail(undefined);
    return true;
  }

  const columns = [
    {
      title: '产品',
      dataIndex: 'productId',
      width: 250,
      editable: true,
      ellipsis: true,
      render: (value: any, record: any) => {
        return wdiCore.String.CodeOrName(record.productCode, record.productName);
      }
    },
    {
      title: '费用产品别',
      dataIndex: 'budgetProductId',
      width: 250,
      editable: true,
      ellipsis: true,
      render: (value: any, record: any) => {
        return wdiCore.String.CodeOrName(record.budgetProductCode, record.budgetProductName);
      }
    },
    {
      title: '领用单位',
      dataIndex: 'uom',
      editable: true,
      ellipsis: true,
      render: (value: any, record: any) => {
        return value;
      }
    },
    {
      title: '申领单价',
      dataIndex: 'unitPrice',
      ellipsis: true,
      editable: true,
      hidden: true,
      render: (value: any, record: any) => {
        return wdiCore.Number.AmountToString(value);
      }
    },
    {
      title: '申领数量',
      dataIndex: 'quantity',
      ellipsis: true,
      editable: true,
      render: (value: any, record: any) => {
        return value;
      }
    },
    {
      title: '申领金额',
      dataIndex: 'amount',
      ellipsis: true,
      editable: true,
      hidden: true,
      render: (value: any, record: any) => {
        return wdiCore.Number.AmountToString(value);
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 80,
      render: (_: any, record: any) => {
        const editable = isEditing(record);

        return editable ? (
          <>
            <Typography.Link onClick={() => save(record.uid)} style={{ marginRight: 8 }}>
              更新
            </Typography.Link>
            <Typography.Link onClick={() => cancel()}>
              取消
            </Typography.Link>
          </>
        ) : (
          <>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)} style={{ marginRight: 8 }}>
              编辑
            </Typography.Link>
            <Typography.Link onClick={() => del(record.uid)}>
              删除
            </Typography.Link>
          </>)
      }
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode = children;
    if (dataIndex == 'productId') {
      inputNode = <WdiSelect
        placeholder="请录入或选择"
        showSearch={true}
        remote={true}
        style={{ width: '100%' }}
        onChange={async (value, option) => {
          let newFormGoodsDetail: API.FormGoodsDetailVo = { quantity: editingFormGoodsDetail.current?.quantity };
          if (value) {
            const productEditResponse = await Product.get(value);
            if (productEditResponse.code == ResponseStatus.SUCCESS) {
              const product = productEditResponse.data;
              if (product) {
                if (product.productType == Enum.ProductType.SKU) {
                  newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { productId: product.productId, uom: product.uom, productCode: product.productCode, productName: product.productName, budgetProductId: product.productId, budgetProductCode: product.productCode, budgetProductName: product.productName });
                } else if (product.parentId) {
                  const budgetProductResponse = await Product.get(product.parentId);
                  if (productEditResponse.code == ResponseStatus.SUCCESS) {
                    const budgetProduct = budgetProductResponse.data;
                    newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { productId: product.productId, uom: product.uom, productCode: product.productCode, productName: product.productName, budgetProductId: budgetProduct.productId, budgetProductCode: budgetProduct.productCode, budgetProductName: budgetProduct.productName });
                  }
                } else {
                  newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { productId: product.productId, uom: product.uom, productCode: product.productCode, productName: product.productName });
                }
                //如ProductType=2则取物料价格（用售达方+productid调用峰迁提供的方法，如找不到或是0则取该产品的成本价），否则取ProductPrice的到岸价
                if (product.productType == Enum.ProductType.SKU) {
                  newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { unitPrice: product.priceCostInVat });
                } else {
                  newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { unitPrice: 100 });
                }
                if (product.priceCostInVat) {
                  newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { unitPriceCost: product.priceCostInVat });
                  if (newFormGoodsDetail.quantity) {
                    const amountCost = wdiCore.Number.ToNumber(newFormGoodsDetail.quantity * product.priceCostInVat, 2);
                    newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { amountCost: amountCost });
                  }
                } else {
                  message.error(`产品【${productEditResponse.data.codeOrName} 】找不到成本价`);
                }
              }
              if (newFormGoodsDetail.unitPrice) {
                if (newFormGoodsDetail.quantity) {
                  const amount = wdiCore.Number.ToNumber(newFormGoodsDetail.quantity * newFormGoodsDetail.unitPrice, 2);
                  newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { amount: amount });
                }
              } else {
                message.error(`申领产品【${productEditResponse.data.codeOrName} 】找不到价格`);
              }
            }
          } else {
            Object.assign({}, newFormGoodsDetail, { productId: null, uom: null, productCode: null, productName: null, budgetProductId: null, budgetProductCode: null, budgetProductName: null });
          }
          editingFormGoodsDetail.current = newFormGoodsDetail;
          formRef.current?.setFieldsValue({ budgetProductId: newFormGoodsDetail.budgetProductId, uom: newFormGoodsDetail.uom });
        }}
        dataLoading={async (params) => {
          const responseResult = await Product.ajaxSearchSku(Object.assign({ isActive: true }, params));
          return responseResult.data?.map((item) => { return { value: item.productId, label: item.codeOrName }; });
        }}
      />;
    } else if (dataIndex == 'budgetProductId') {
      inputNode =
        <WdiTreeSelect
          placeholder="请选择费用产品别"
          style={{ width: '100%' }}
          dataLoading={async (params) => {
            const response = await Product.searchNoRoot(Object.assign({ isActive: true, productType: Enum.ProductType.SKU }, params));
            const { treeData, dict } = FieldProps.TreeSelect.formatTreeData({
              textField: "codeOrName",
              valueField: "productId",
              id: "productId",
              pId: "parentId"
            }, response.data);
            return { treeData, dict };
          }}
          onChange={async (value) => {
            let newFormGoodsDetail = { ...editingFormGoodsDetail.current };
            if (value) {
              const budgetProductResponse = await Product.get(value);
              if (budgetProductResponse.code == ResponseStatus.SUCCESS) {
                const budgetProduct = budgetProductResponse.data;
                if (budgetProduct) {
                  newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { budgetProductId: budgetProduct.productId, budgetProductCode: budgetProduct.productCode, budgetProductName: budgetProduct.productName });
                }
              }
            } else {
              newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { budgetProductId: null, budgetProductCode: null, budgetProductName: null });
            }
            editingFormGoodsDetail.current = newFormGoodsDetail;
          }}
        />
    } else if (dataIndex == 'quantity') {
      inputNode = <WdiInputNumber digits={0}
        onBlur={(e: any) => {
          const quantity = wdiCore.Number.ToNumber(e.target.value, 0);
          let newFormGoodsDetail = Object.assign({}, editingFormGoodsDetail.current, { quantity: quantity });
          if (newFormGoodsDetail?.unitPrice) {
            const amount = wdiCore.Number.ToNumber(quantity * newFormGoodsDetail.unitPrice, 2);
            newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { amount: amount });
          }
          editingFormGoodsDetail.current = newFormGoodsDetail;
          // setFormGoodsDetail(newFormGoodsDetail);
        }}
      />;
    } else if (dataIndex == 'uom' || dataIndex == 'unitPrice' || dataIndex == 'amount') {
      inputNode = <Input style={{ background: 'none', border: 'none' }} readOnly />;
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  useEffect(() => {
    if (props.data.formSaDetail) {
      const detail = props.data.formSaDetail;
      formRef.current?.setFieldsValue({
        formSaDetailId: detail.formSaDetailId,
        rowNumber: detail.rowNumber,
        boId: detail.boId,
        ecId: detail.ecId,
        boName: detail.boName,
        soldToCode: detail.soldToCode,
        soldToName: wdiCore.String.CodeOrName(detail.soldToCode, detail.soldToName),
        shipToCode: detail.shipToCode,
        deliveryDate: detail?.deliveryDate ? moment(String(detail?.deliveryDate), "YYYY-MM-DD") : moment().add(2, 'days'),
        remark: detail.remark,
      });

      if (props.data.formSaDetail.formGoodsDetails) {
        setDataSource(DataStore.init(props.data.formSaDetail.formGoodsDetails));
      }

    }
  }, [props.data.formSaDetail]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelAlign='left'
      layout="vertical"
      title={props.title}
      visible={props.visible}
      modalProps={{
        width: 1000,
        onCancel: () => { props.onClose ? props.onClose() : null },
        destroyOnClose: true,
        maskClosable: false
      }}
      onFinish={async (values) => {
        const { shipToCode, ...entity } = values;

        let formGoodsDetails = [...dataSource];
        if (formGoodsDetail && formGoodsDetail.productId) {
          if (!validateItem()) {
            return;
          }
          formGoodsDetails = DataStore.add(dataSource, formGoodsDetail);
        }

        if (editingFormGoodsDetail.current && editingFormGoodsDetail.current.productId) {
          if (!validateEditItem()) {
            return;
          }
          formGoodsDetails = DataStore.edit(dataSource, editingFormGoodsDetail.current);
        }

        entity.deliveryDate = wdiCore.Date.DateToString(entity.deliveryDate);
        entity.shipToCode = shipToCode;

        if (!formGoodsDetails || formGoodsDetails.length <= 0) {
          message.error("请填写申领明细！");
          return;
        }

        //售达方+送达方是否重复
        let isDup = false;
        props.data.formSaDetails.forEach((item: any) => {
          if (item.soldToCode == props.data.soldToCode && item.shipToCode == entity.shipToCode && item.uid != entity.uid) {
            message.error(`与第${item.rowNumber}行信息重复，请检查`);
            isDup = true;
          }
        });

        if (isDup) {
          return;
        }

        entity.formGoodsDetails = formGoodsDetails;

        const response = await FormSa.Buttons.validateDetail({
          rowIndex: props.data.rowIndex,
          formSa: {
            pageTypeId: props.data.formSa.pageTypeId,
            budgetProjectId: props.data.formSa.budgetProjectId
          },
          ecId: props.data.ecId,
          formSaDetail: entity,
          formSaDetails: props.data.formSaDetails
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (props.onSave) {
          props.onSave(response);
        }
      }}
    >
      <Form.Item name="formSaDetailId" label="formSaDetailId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="rowNumber" label="rowNumber" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="ecId" label="ecId" hidden>
        <Input />
      </Form.Item>
      <Row gutter={[10, 0]}>
        <Col className="gutter-row" span={6} >
          <Form.Item
            label="soldToCode"
            name="soldToCode"
            hidden
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="售达方"
            name="soldToName"
          >
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6} >
          <Form.Item
            label="送达方"
            name="shipToCode"
            rules={[{ required: true, message: '送达方不能为空!' }]}
          >
            <WdiSelect {...{
              placeholder: "请选择送达方",
              remote: true,
              showSearch: true,
              dataLoading: async (params) => {
                const responseResult = await ShipTo.search(Object.assign({
                  soldToCode: props.data.formSaDetail.soldToCode,
                  withPermissions: true,
                  isActive: true
                }, params));
                if (responseResult?.data.length == 1) {
                  formRef.current?.setFieldsValue({
                    shipToCode: responseResult.data[0].shipToCode
                  })
                }

                return responseResult.data.map((item) => { return { value: item.shipToCode, label: wdiCore.String.Join(item.codeOrName, item.address) }; });
              }
            }} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6} >
          <Form.Item
            label="建议发货日期"
            name="deliveryDate"
            rules={[{ required: true, message: '建议发货日期不能为空!' }]}
          >
            <DatePicker picker={"date"} format={"YYYY-MM-DD"} disabledDate={disabledDate} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6} >
          <Form.Item
            label="boId"
            name="boId"
            hidden
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="四级预算中心"
            name="boName"
          >
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[10, 0]}>
        <Col className="gutter-row" span={12} >
          <Form.Item
            label="备注"
            name="remark"
          >
            <TextArea rows={2} style={{ width: '100%' }} maxLength={2000} />
          </Form.Item>
        </Col>
      </Row>
      <WdiPanel title={<WdiCardTitle title='申领明细' />} >
        <WdiTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns.filter(item => !item.hidden)}
          rowKey="uid"
          dataSource={dataSource}
          pagination={false}
          // aggrColumns={['amount']}
          extraFooterSummary={() => {
            return (<Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <WdiSelect
                  placeholder="请录入或选择"
                  showSearch={true}
                  remote={true}
                  value={formGoodsDetail?.productId}
                  style={{ width: '100%' }}
                  onChange={async (value, option) => {
                    let newFormGoodsDetail: API.FormGoodsDetailVo = { quantity: formGoodsDetail?.quantity };
                    if (value) {
                      const productResponse = await Product.get(value);
                      if (productResponse.code == ResponseStatus.SUCCESS) {
                        const product = productResponse.data;
                        if (product) {
                          if (product.productType == Enum.ProductType.SKU) {
                            newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { productId: product.productId, uom: product.uom, productCode: product.productCode, productName: product.productName, budgetProductId: product.productId, budgetProductCode: product.productCode, budgetProductName: product.productName });
                          } else if (product.parentId) {
                            const budgetProductResponse = await Product.get(product.parentId);
                            if (productResponse.code == ResponseStatus.SUCCESS) {
                              const budgetProduct = budgetProductResponse.data;
                              newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { productId: product.productId, uom: product.uom, productCode: product.productCode, productName: product.productName, budgetProductId: budgetProduct.productId, budgetProductCode: budgetProduct.productCode, budgetProductName: budgetProduct.productName });
                            }
                          } else {
                            newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { productId: product.productId, uom: product.uom, productCode: product.productCode, productName: product.productName });
                          }
                          //如ProductType=2则取物料价格（用售达方+productid调用峰迁提供的方法，如找不到或是0则取该产品的成本价），否则取ProductPrice的到岸价
                          if (product.productType == Enum.ProductType.SKU) {
                            newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { unitPrice: product.priceCostInVat });
                          } else {
                            const materialPriceResponse = await SellingMaterial.getPrice({ soldToCode: props.data.formSaDetail.soldToCode, materialId: product.productId });
                            if (materialPriceResponse.data && materialPriceResponse.data != 0) {
                              newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { unitPrice: materialPriceResponse.data });
                            } else {
                              newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { unitPrice: product.priceCostInVat });
                            }
                          }
                          if (product.priceCostInVat) {
                            newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { unitPriceCost: product.priceCostInVat });
                          } else {
                            message.error(`产品【${productResponse.data.codeOrName} 】找不到成本价`);
                          }
                        }
                        if (newFormGoodsDetail.unitPrice) {
                          if (newFormGoodsDetail.quantity) {
                            const amount = wdiCore.Number.ToNumber(newFormGoodsDetail.quantity * newFormGoodsDetail.unitPrice, 2);
                            newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { amount: amount });
                          }
                        } else {
                          message.error(`申领产品【${productResponse.data.codeOrName}】找不到价格`);
                        }
                      }
                    } else {
                      Object.assign({}, newFormGoodsDetail, { productId: null, uom: null, productCode: null, productName: null, budgetProductId: null, budgetProductCode: null, budgetProductName: null });
                    }
                    setFormGoodsDetail(newFormGoodsDetail);
                  }}
                  dataLoading={async (params) => {
                    const responseResult = await Product.ajaxSearchSku(Object.assign({ isActive: true }, params));
                    return responseResult.data?.map((item) => { return { value: item.productId, label: item.codeOrName }; });
                  }}
                />
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <WdiTreeSelect
                  placeholder="请选择费用产品别"
                  style={{ width: '100%' }}
                  value={formGoodsDetail?.budgetProductId}
                  dataLoading={async (params) => {
                    const response = await Product.searchNoRoot(Object.assign({ isActive: true, productType: Enum.ProductType.SKU }, params));
                    const { treeData, dict } = FieldProps.TreeSelect.formatTreeData({
                      textField: "codeOrName",
                      valueField: "productId",
                      id: "productId",
                      pId: "parentId"
                    }, response.data);
                    return { treeData, dict };
                  }}
                  onChange={async (value, option) => {
                    let newFormGoodsDetail = { ...formGoodsDetail };
                    if (value) {
                      const budgetProductResponse = await Product.get(value);
                      if (budgetProductResponse.code == ResponseStatus.SUCCESS) {
                        const budgetProduct = budgetProductResponse.data;
                        if (budgetProduct) {
                          newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { budgetProductId: budgetProduct.productId, budgetProductCode: budgetProduct.productCode, budgetProductName: budgetProduct.productName });
                        }
                      }
                    } else {
                      newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { budgetProductId: null, budgetProductCode: null, budgetProductName: null });
                    }
                    setFormGoodsDetail(newFormGoodsDetail);
                  }}
                />
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                {formGoodsDetail?.uom}
              </Table.Summary.Cell>
              {/* <Table.Summary.Cell index={3}>
                {formGoodsDetail?.unitPrice}
              </Table.Summary.Cell> */}
              <Table.Summary.Cell index={3}>
                <WdiInputNumber digits={0} value={formGoodsDetail?.quantity}
                  onBlur={(e: any) => {
                    const quantity = wdiCore.Number.ToNumber(e.target.value, 0);
                    let newFormGoodsDetail = Object.assign({}, formGoodsDetail, { quantity: quantity });
                    if (newFormGoodsDetail?.unitPrice) {
                      const amount = wdiCore.Number.ToNumber(quantity * newFormGoodsDetail.unitPrice, 2);
                      newFormGoodsDetail = Object.assign({}, newFormGoodsDetail, { amount: amount });
                    }
                    setFormGoodsDetail(newFormGoodsDetail);
                  }}
                />
              </Table.Summary.Cell>
              {/* <Table.Summary.Cell index={5}>
                {formGoodsDetail?.amount}
              </Table.Summary.Cell> */}
              <Table.Summary.Cell index={4}>
                <Typography.Link onClick={() => addItem()}>
                  新增
                </Typography.Link>
              </Table.Summary.Cell>
            </Table.Summary.Row>)
          }}
        />
      </WdiPanel>
    </WdiModalForm >
  );
}
export default FormSADetailEditApp;

export const FormSADetailViewApp: React.FC<WdiModalAppProps> = (props) => {

  const formRef = useRef<ProFormInstance>();

  const [dataSource, setDataSource] = useState<API.FormGoodsDetailVo[]>([]);
  const [shippingDataSource, setShippingDataSource] = useState<API.FormGoodsDetailVo[]>([]);

  const columns = [
    {
      title: '产品',
      dataIndex: 'productId',
      width: 250,
      ellipsis: true,
      render: (value: any, record: any) => {
        return wdiCore.String.CodeOrName(record.productCode, record.productName);
      }
    },
    {
      title: '费用产品别',
      dataIndex: 'budgetProductId',
      width: 250,
      ellipsis: true,
      render: (value: any, record: any) => {
        return wdiCore.String.CodeOrName(record.budgetProductCode, record.budgetProductName);
      }
    },
    {
      title: '领用单位',
      dataIndex: 'uom',
      ellipsis: true,
      render: (value: any, record: any) => {
        return value;
      }
    },
    {
      title: '申领单价',
      dataIndex: 'unitPrice',
      ellipsis: true,
      hidden: true,
      render: (value: any, record: any) => {
        return wdiCore.Number.AmountToString(value);
      }
    },
    {
      title: '申领数量',
      dataIndex: 'quantity',
      ellipsis: true,
      render: (value: any, record: any) => {
        return value;
      }
    },
    {
      title: '申领金额',
      dataIndex: 'amount',
      ellipsis: true,
      hidden: true,
      render: (value: any, record: any) => {
        return wdiCore.Number.AmountToString(value);
      }
    },
  ];

  const shippingColumns = [
    {
      title: '订单编号',
      dataIndex: 'orderNo',
      width: 100,
      ellipsis: true
    },
    {
      title: '产品',
      dataIndex: 'productId',
      width: 200,
      ellipsis: true,
      render: (value: any, record: any) => {
        return wdiCore.String.CodeOrName(record.productCode, record.productName);
      }
    },
    {
      title: '发货价',
      dataIndex: 'unitPrice',
      width: 100,
      ellipsis: true,
      hidden: true,
      render: (value: any, record: any) => {
        return wdiCore.Number.AmountToString(value);
      }
    },
    {
      title: '发货数量',
      dataIndex: 'quantity',
      width: 100,
      ellipsis: true
    },
    {
      title: '发货金额',
      dataIndex: 'amount',
      width: 100,
      ellipsis: true,
      hidden: true,
      render: (value: any, record: any) => {
        return wdiCore.Number.AmountToString(value);
      }
    },
    {
      title: '订单日期',
      dataIndex: 'orderDate',
      width: 120,
      ellipsis: true
    },
    {
      title: '送达方',
      dataIndex: 'shipToCode',
      width: 200,
      ellipsis: true,
      render: (value: any, record: any) => {
        return wdiCore.String.CodeOrName(record.shipToCode, record.shipToName);
      }
    },
  ];

  useEffect(() => {
    if (props.data) {
      const formSaDetail: API.FormSaDetailVo = props.data;

      formRef.current?.setFieldsValue({
        soldToName: wdiCore.String.CodeOrName(formSaDetail.soldToCode, formSaDetail.soldToName),
        deliveryDate: wdiCore.Date.DateToString(formSaDetail?.deliveryDate),
        boName: formSaDetail.boName,
        remark: formSaDetail.remark,
        deliveredOperatorName: formSaDetail.deliveredOperatorName,
        deliveredSetupDate: wdiCore.Date.DateToString(formSaDetail?.deliveredSetupDate),
      });
      if (formSaDetail.shipToCode) {
        ShipTo.get(formSaDetail.shipToCode).then(response => {
          if (response.data) {
            formRef.current?.setFieldsValue({
              shipToName: wdiCore.String.Join(response.data.codeOrName, response.data.address)
            });
          }
        })
      }
      if (formSaDetail.formGoodsDetails) {
        setDataSource(formSaDetail.formGoodsDetails);
      }
      FormGoodsShipping.getFormGoodsShippings(formSaDetail.formSaDetailId ?? 0).then(response => {
        setShippingDataSource(DataStore.init(response.data));
      })
    }
  }, [props.data]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelAlign='left'
      layout="vertical"
      title={props.title}
      visible={props.visible}
      submitter={false}
      modalProps={{
        width: 1000,
        onCancel: () => { props.onClose ? props.onClose() : null },
        destroyOnClose: true,
        maskClosable: false
      }}
    >
      <Row gutter={[10, 0]}>
        <Col className="gutter-row" span={6}>
          <Form.Item label="售达方" name="soldToName" >
            <WdiInput readOnly />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6} >
          <Form.Item label="送达方" name="shipToName" >
            <WdiInput readOnly />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6} >
          <Form.Item label="建议发货日期" name="deliveryDate" >
            <WdiInput readOnly />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6} >
          <Form.Item label="四级预算中心" name="boName" >
            <WdiInput readOnly />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[10, 0]}>
        <Col className="gutter-row" span={6}>
          <Form.Item label="备注" name="remark" >
            <WdiTextArea readOnly rows={1} />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6} >
          <Form.Item label="发货完成设置人" name="deliveredOperatorName" >
            <WdiInput readOnly />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6} >
          <Form.Item label="发货完成设置日期" name="deliveredSetupDate" >
            <WdiInput readOnly />
          </Form.Item>
        </Col>
      </Row>
      <WdiPanel title={<WdiCardTitle title='申领明细' />} >
        <WdiTable
          columns={columns.filter(item => !item.hidden)}
          rowKey="uid"
          dataSource={dataSource}
          pagination={false}
        // aggrColumns={['amount']}
        />
      </WdiPanel>
      <WdiPanel title={<WdiCardTitle title='发货明细' />} >
        <WdiTable
          columns={shippingColumns.filter(item => !item.hidden)}
          rowKey="uid"
          dataSource={shippingDataSource}
          pagination={false}
        // aggrColumns={['amount']}
        />
      </WdiPanel>
    </WdiModalForm >
  );
}
