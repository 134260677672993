import React, { useEffect, useState } from 'react';
import { wdiCore } from '@/components/WdiControls';
import { ColumnsType } from 'antd/lib/table';
import PanelForWarningApp, {
  WarningInfoAppProps,
  WarningInfoApprovalProps,
  WdiGridApp,
  WdiObjApp
} from '@/components/WdiBus/PanelForWarning';
import { FormSa } from '@/services/sales/sa';
import { Empty, message } from 'antd';
import { getFormUrlMapping } from '@/components/WdiBus/FormPage';
import { Enum } from '@/enums/system';
import { FormApi } from '@/services/sales';
import { ResponseStatus } from '@/utils/request';

const showDispModal = (evt: any) => {
  if (evt && evt.target) {
    const dataKey = evt.target.getAttribute("data-key");
    const record = JSON.parse(evt.target.getAttribute("data-record"));
    FormApi.doesAccess(dataKey).then(response => {
      if (response.code == ResponseStatus.SUCCESS && response.data) {
        window.open(getFormUrlMapping(record.pageTypeId, record.formTypeId) + `?formId=${dataKey}&pageTypeId=${record.pageTypeId}&formTypeId=${record.formTypeId}`);
      } else {
        message.error("没有权限查看单据");
      }
    })
  }
};

const activityStatus = [
  { text: '待审批', value: String(Enum.FormStatus.AWAITING) }
  , { text: '已审批', value: String(Enum.FormStatus.APPROVE_COMPLETED) }
  , { text: '退回待修改', value: String(Enum.FormStatus.REJECTED) }
  , { text: '作废', value: String(Enum.FormStatus.SCRAP) }
  , { text: '已审批未结案', value: '5' }
  , { text: '已审批结案中', value: '6' }
  , { text: '已审批已结案', value: '7' }
  , { text: '已审批未关闭', value: '8' }
  , { text: '已审批已关闭', value: '9' }
];


const OverPromotionRate: Record<string, any> = {
  custSysCode: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['custSysCode'], record['custSysName']);
  },
  productId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productCode'], record['productName']);
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  },
  ym: (value: any, record: any) => {
    return value;
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.ToNumber(record['amount'], 2);
  },
  quantityForecastMonth: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  salesRate2: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  salesRate: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  quantityForecast1: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  quantityForecast2: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  quantityForecastRate2: (value: any, record: any) => {
    if (value > record['salesRate2']) {
      return <span style={{ color: '#ff0000' }}>{wdiCore.Number.RateToString(value)}</span>;
    }
    return wdiCore.Number.RateToString(value);
  },
  quantityForecastRate: (value: any, record: any) => {
    if (value > record['salesRate']) {
      return <span style={{ color: '#ff0000' }}>{wdiCore.Number.RateToString(value)}</span>;
    }
    return wdiCore.Number.RateToString(value);
  },
};

const OverPromotionLimit: Record<string, any> = {
  custSysCode: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['custSysCode'], record['custSysName']);
  },
  productId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productCode'], record['productName']);
  },
  quantityForecastMonth: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  salesLimit1: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  salesLimit2: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  quantityForecastTotal1: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  quantityForecastTotal2: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 0);
  },
  quantityForecast1: (value: any, record: any) => {
    return <span style={{ color: "#ff0000" }}>{wdiCore.Number.ToNumber(value, 0)}</span>;
  },
  quantityForecast2: (value: any, record: any) => {
    return <span style={{ color: "#ff0000" }}>{wdiCore.Number.ToNumber(value, 0)}</span>;
  }
};

const SaBudget: Record<string, any> = {
  bgId: (_: any, record: any) => {
    return record['bgName'];
  },
  productLineId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ecId: (_: any, record: any) => {
    if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span style={{ color: "#ff0000" }}>{record['ecName']}</span>;
    } else {
      return <span>{record['ecName']}</span>;
    }
  },
  ym: (_: any, record: any) => {
    return record['ym'];
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.ToNumber(record['amount'], 2);
  },
  revenueMtd: (value: any, record: any) => {
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      return <span style={{ color: '#ff0000' }}>{record['revenueMtd']}</span>;
    }

    return wdiCore.Number.ToNumber(value, 2);
  },
  expenseMtd: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 2);
  },
  rateActualMtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      isError = true;
    }

    if (record['rateBaselineMtd'] == null) {
      hasStandard = false;
    }

    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      let isRed: boolean = false;
      if (record['revenueMtd'] == null || record['revenueMtd'] == 0
        || record['rateBaselineMtd'] == null || record['rateActualMtd'] > record['rateBaselineMtd']) {
        isRed = true;
      }

      return <>
        <span
          style={isRed ? { color: "#ff0000" } : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualMtd']) : "error"}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineMtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span
        style={isError ? { color: "#ff0000" } : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualMtd']) : "error"}</span>;
    }

    return "";
  },
  rateActualMtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxMtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  revenueYtd: (value: any, record: any) => {
    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      return <span style={{ color: '#ff0000' }}>{record['revenueYtd']}</span>;
    }

    return wdiCore.Number.ToNumber(value, 2);
  },
  expenseYtd: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 2);
  },
  rateActualYtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      isError = true;
    }

    if (record['rateBaselineYtd'] == null) {
      hasStandard = false;
    }

    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      let isRed: boolean = false;
      if (record['revenueYtd'] == null || record['revenueYtd'] == 0
        || record['rateBaselineYtd'] == null || record['rateActualYtd'] > record['rateBaselineYtd']) {
        isRed = true;
      }

      return <>
        <span
          style={isRed ? { color: "#ff0000" } : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualYtd']) : "error"}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineYtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span
        style={isError ? { color: "#ff0000" } : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualYtd']) : "error"}</span>;
    }

    return "";
  },
  rateActualYtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxYtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdLyTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rowNumbers: (_: any, record: any) => {
    return record['rowNumbers'];
  },
};

const SaBudgetImportant: Record<string, any> = {
  bgId: (_: any, record: any) => {
    return record['bgName'];
  },
  productLineId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  },
  ym: (_: any, record: any) => {
    return record['ym'];
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.ToNumber(record['amount'], 2);
  },
  revenueMtd: (value: any, record: any) => {
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      return <span style={{ color: '#ff0000' }}>{record['revenueMtd']}</span>;
    }

    return wdiCore.Number.ToNumber(value, 2);
  },
  expenseMtd: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 2);
  },
  rateActualMtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      isError = true;
    }

    if (record['rateBaselineMtd'] == null) {
      hasStandard = false;
    }

    let isRed: boolean = false;
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0
      || record['rateBaselineMtd'] == null || record['rateActualMtd'] > record['rateBaselineMtd']) {
      isRed = true;
    }

    return <>
      <span
        style={isRed ? { color: "#ff0000" } : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualMtd']) : "error"}</span>
      <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineMtd']) : "未设置"})</span>
    </>;
  },
  rateActualMtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rowNumbers: (_: any, record: any) => {
    return record['rowNumbers'];
  },
};

const OverRateStandard: Record<string, any> = {
  bgId: (_: any, record: any) => {
    return record['bgName'];
  },
  productLineId: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['productLineCode'], record['productLineName']);
  },
  ecId: (_: any, record: any) => {
    if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span style={{ color: "#ff0000" }}>{record['ecName']}</span>;
    } else {
      return <span>{record['ecName']}</span>;
    }
  },
  ym: (_: any, record: any) => {
    return record['ym'];
  },
  amount: (_: any, record: any) => {
    return wdiCore.Number.ToNumber(record['amount'], 2);
  },
  revenueMtd: (value: any, record: any) => {
    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      return <span style={{ color: '#ff0000' }}>{record['revenueMtd']}</span>;
    }

    return wdiCore.Number.ToNumber(value, 2);
  },
  expenseMtd: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 2);
  },
  rateActualMtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueMtd'] == null || record['revenueMtd'] == 0) {
      isError = true;
    }

    if (record['rateBaselineMtd'] == null) {
      hasStandard = false;
    }

    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      let isRed: boolean = false;
      if (record['revenueMtd'] == null || record['revenueMtd'] == 0
        || record['rateBaselineMtd'] == null || record['rateActualMtd'] > record['rateBaselineMtd']) {
        isRed = true;
      }

      return <>
        <span
          style={isRed ? { color: "#ff0000" } : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualMtd']) : "error"}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineMtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span
        style={isError ? { color: "#ff0000" } : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualMtd']) : "error"}</span>;
    }

    return "";
  },
  rateActualMtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxMtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  revenueYtd: (value: any, record: any) => {
    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      return <span style={{ color: '#ff0000' }}>{record['revenueYtd']}</span>;
    }

    return wdiCore.Number.ToNumber(value, 2);
  },
  expenseYtd: (value: any, record: any) => {
    return wdiCore.Number.ToNumber(value, 2);
  },
  rateActualYtd: (_: any, record: any) => {
    let isError: boolean = false;
    let hasStandard: boolean = true;

    if (record['revenueYtd'] == null || record['revenueYtd'] == 0) {
      isError = true;
    }

    if (record['rateBaselineYtd'] == null) {
      hasStandard = false;
    }

    if (record['budgetControlType'] == Enum.BudGetControlType.CONTROL) {
      let isRed: boolean = false;
      if (record['revenueYtd'] == null || record['revenueYtd'] == 0
        || record['rateBaselineYtd'] == null || record['rateActualYtd'] > record['rateBaselineYtd']) {
        isRed = true;
      }

      return <>
        <span
          style={isRed ? { color: "#ff0000" } : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualYtd']) : "error"}</span>
        <span>({hasStandard ? wdiCore.Number.RateToString(record['rateBaselineYtd']) : "未设置"})</span>
      </>;
    } else if (record['budgetControlType'] == Enum.BudGetControlType.SPECIAL) {
      return <span
        style={isError ? { color: "#ff0000" } : {}}>{!isError ? wdiCore.Number.RateToString(record['rateActualYtd']) : "error"}</span>;
    }

    return "";
  },
  rateActualYtdLy: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateMaxYtd: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rateActualYtdLyTotal: (value: any, record: any) => {
    return wdiCore.Number.RateToString(value);
  },
  rowNumbers: (_: any, record: any) => {
    return record['rowNumbers'];
  },
};


const DuplicatedGeneralActivity: Record<string, any> = {
  formNo: (_: any, record: any) => {
    return <a onClick={showDispModal} data-key={record.formId} data-record={JSON.stringify(record)}>
      {record.formNo}
    </a>;
  },
  formStatus: (_: any, record: any) => {
    var html = "";
    if (record.statusId === 2) {
      if (record.isClosed) {
        html = "已审批已关闭";// formContext.Res.Closed;
      } else {
        html = "已审批未关闭";//formContext.Res.NotClosed;
        if (record.settlementMode === 1 || record.settlementMode === 2) {
          if (record.settledStatus === 2) {
            html = "已审批已结案";// formContext.Res.Settled;
          } else if (record.settledStatus === 1) {
            html = "已审批结案中";// formContext.Res.Settling;
          } else {
            html = "已审批未结案"; //formContext.Res.NotSettleted;
          }
        }
      }
    } else {
      activityStatus.forEach((item: any) => {
        if (wdiCore.String.EqIngoreCase(String(record.statusId), item.value)) {
          html = item.text;
          return false;
        }
        return true;
      })
    }
    return html;
  },
  soldTo: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['soldToCode'], record['soldToName']);
  },
  shop: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['shopCode'], record['shopName']);
  },
  ec: (_: any, record: any) => {
    return record['ecName'];
  },
  product: (_: any, record: any) => {
    return record['productBuyNames'];
  },
  originalActivityDate: (_: any, record: any) => {
    return wdiCore.Date.Join(record['originalActivityStartDate'], record['originalActivityEndDate']);
  },
  currentActivityDate: (_: any, record: any) => {
    return wdiCore.Date.Join(record['currentActivityStartDate'], record['currentActivityEndDate']);
  },
  originalRowNumber: (_: any, record: any) => {
    return <span style={{ color: '#ff0000' }}>{record['originalRowNumber']}</span>;
  },
  currentRowNumber: (_: any, record: any) => {
    return <span style={{ color: '#ff0000' }}>{record['currentRowNumber']}</span>;
  },
};
const DuplicatedPdActivity: Record<string, any> = {
  formNo: (_: any, record: any) => {
    return <a onClick={showDispModal} data-key={record.formId} data-record={JSON.stringify(record)}>
      {record.formNo}
    </a>;
  },
  formStatus: (_: any, record: any) => {
    var html = "";
    if (record.statusId === 2) {
      if (record.isClosed) {
        html = "已审批已关闭";// formContext.Res.Closed;
      } else {
        html = "已审批未关闭";//formContext.Res.NotClosed;
        if (record.settlementMode === 1 || record.settlementMode === 2) {
          if (record.settledStatus === 2) {
            html = "已审批已结案";// formContext.Res.Settled;
          } else if (record.settledStatus === 1) {
            html = "已审批结案中";// formContext.Res.Settling;
          } else {
            html = "已审批未结案"; //formContext.Res.NotSettleted;
          }
        }
      }
    } else {
      activityStatus.forEach((item: any) => {
        if (wdiCore.String.EqIngoreCase(String(record.statusId), item.value)) {
          html = item.text;
          return false;
        }
        return true;
      })
    }
    return html;
  },
  soldTo: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['soldToCode'], record['soldToName']);
  },
  product: (_: any, record: any) => {
    return record['productBuyNames'];
  },
  revenueType: (_: any, record: any) => {
    return record['revenueTypeName'];
  },
  originalSupplyDate: (_: any, record: any) => {
    return wdiCore.Date.Join(record['originalSupplyStartDate'], record['originalSupplyEndDate']);
  },
  currentSupplyDate: (_: any, record: any) => {
    return wdiCore.Date.Join(record['currentSupplyStartDate'], record['currentSupplyEndDate']);
  },
  originalRowNumber: (_: any, record: any) => {
    return <span style={{ color: '#ff0000' }}>{record['originalRowNumber']}</span>;
  },
  currentRowNumber: (_: any, record: any) => {
    return <span style={{ color: '#ff0000' }}>{record['currentRowNumber']}</span>;
  },
};
const BuyGiveAndDiscountSimultaneously: Record<string, any> = {
  formNo: (_: any, record: any) => {
    return <a onClick={showDispModal} data-key={record.formId} data-record={JSON.stringify(record)}>
      {record.formNo}
    </a>;
  },
  formStatus: (_: any, record: any) => {
    var html = "";
    if (record.statusId === 2) {
      if (record.isClosed) {
        html = "已审批已关闭";// formContext.Res.Closed;
      } else {
        html = "已审批未关闭";//formContext.Res.NotClosed;
        if (record.settlementMode === 1 || record.settlementMode === 2) {
          if (record.settledStatus === 2) {
            html = "已审批已结案";// formContext.Res.Settled;
          } else if (record.settledStatus === 1) {
            html = "已审批结案中";// formContext.Res.Settling;
          } else {
            html = "已审批未结案"; //formContext.Res.NotSettleted;
          }
        }
      }
    } else {
      activityStatus.forEach((item: any) => {
        if (wdiCore.String.EqIngoreCase(String(record.statusId), item.value)) {
          html = item.text;
          return false;
        }
        return true;
      })
    }
    return html;
  },
  soldTo: (_: any, record: any) => {
    return wdiCore.String.CodeOrName(record['soldToCode'], record['soldToName']);
  },
  productBuy: (_: any, record: any) => {
    return record['productBuyNames'];
  },
  originalRevenueType: (_: any, record: any) => {
    return record['originalRevenueTypeName'];
  },
  currentRevenueType: (_: any, record: any) => {
    return record['currentRevenueTypeName'];
  },
  originalSupplyDate: (_: any, record: any) => {
    return wdiCore.Date.Join(record['originalSupplyStartDate'], record['originalSupplyEndDate']);
  },
  currentSupplyDate: (_: any, record: any) => {
    return wdiCore.Date.Join(record['currentSupplyStartDate'], record['currentSupplyEndDate']);
  },
  originalRowNumber: (_: any, record: any) => {
    return <span style={{ color: '#ff0000' }}>{record['originalRowNumber']}</span>;
  },
  currentRowNumber: (_: any, record: any) => {
    return <span style={{ color: '#ff0000' }}>{record['currentRowNumber']}</span>;
  },
};

const columnsRenders: Record<string, Record<string, any>> = {
  OverPromotionRate: OverPromotionRate,
  OverPromotionLimit: OverPromotionLimit,
  SaBudget: SaBudget,
  SaBudgetImportant: SaBudgetImportant,
  OverRateStandard: OverRateStandard,
  DuplicatedGeneralActivity: DuplicatedGeneralActivity,
  DuplicatedPdActivity: DuplicatedPdActivity,
  BuyGiveAndDiscountSimultaneously: BuyGiveAndDiscountSimultaneously,
};

const renderWdiGridApp = (props: API.WdiGrid, index: number) => {
  const columns: ColumnsType<any> = [];
  const columnsRender = columnsRenders[props.key];
  props.wdiColumns.map((wdiColumn) => {
    if (!wdiColumn.isHidden) {
      const column: any = {
        title: wdiColumn.displayName,
        dataIndex: wdiColumn.name,
        width: wdiColumn.width,
        ellipsis: true,
      };
      if (columnsRender && columnsRender[wdiColumn.name]) {
        column.render = columnsRender[wdiColumn.name];
      }
      columns.push(column);
    }
  });
  return (
    (props.status != 0 || props.showAlways == true)
    && <WdiGridApp key={props.key} wdiGrid={props} columns={columns} dataSource={props.items} isExport={false}
      exportColumns={[]} />
  );
};

const renderWdiObjApp = (props: API.WdiObj, index: number) => {
  return (
    (props.status != 0 || props.showAlways == true)
    && <WdiObjApp key={index} wdiObj={props} message={props.target} />
  );
};

const SAWarningInfoApp: React.FC<WarningInfoAppProps> = (props) => {
  let status: 0 | 1 | 2 = 0;


  let hasWarning: boolean = false;

  props.wdiPanelList?.items.map((item) => {
    if (item.status != 0 || item.showAlways == true) {
      hasWarning = true;
    }

    if (item.status === 2) {
      status = 2;
    }
    if (status !== 2 && item.status === 1) {
      status = 1;
    }
  });


  return (
    <PanelForWarningApp
      title={props.title}
      visible={props.visible}
      status={status}
      onClose={props.onClose}
      onSave={props.onSave}
    >
      {hasWarning &&
        props.wdiPanelList?.items.map((item, index) => {
          if (item.type === 'WdiGrid') {
            return renderWdiGridApp(item, index);
          } else if (item.type === 'WdiObj') {
            return renderWdiObjApp(item, index);
          } else {
            return <></>;
          }
        })}
      {
        !hasWarning &&
        <>此单据无费率超标等异常信息</>
      }
    </PanelForWarningApp>
  );
}
export default SAWarningInfoApp;

export const SAWarningApprovalApp: React.FC<WarningInfoApprovalProps> = (props) => {
  const [isWarning, setIsWarning] = useState(false);
  const [paneList, setPaneList] = useState<API.WdiPanelList>();
  useEffect(() => {
    if (props?.formId) {
      FormSa.Buttons.warningInfos(props?.formId).then(response => {
        if (response?.data && response?.data.items && response?.data.items.length > 0) {
          setIsWarning(true);
        }
        setPaneList(response?.data);
      });
    }
  }, [props.formId]);

  return (
    <>
      {isWarning && paneList != undefined && paneList.items.map((item, index) => {
        if (item.type === 'WdiGrid') {
          return renderWdiGridApp(item, index);
        } else if (item.type === 'WdiObj') {
          return renderWdiObjApp(item, index);
        } else {
          return <></>;
        }
      })}
      {!isWarning &&
        <div>
          <div style={{ marginTop: "200px", textAlign: "center" }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="没有任何警示信息" />
          </div>
        </div>
      }
    </>
  );
};
