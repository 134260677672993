import { request, formatter } from '@/utils/request';




export namespace BudgetProject {
  export const Path = {
    Get: "/projectapi/budgetProject/get",
    GetForTooltip: "/projectapi/budgetProject/getForTooltip",
    QueryForSelect: "/reportapi/BP/QueryForSelect",
    QueryForPicker: "/reportapi/bp/queryForPicker",
    AjaxSearch: "/projectapi/budgetProject/ajaxSearch",
    Query: "/reportapi/bp/queryForBudgetProject"
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };

    return request<API.ResponseResult<API.QueryResult<any>>>(Path.Query, formatter(options));
  };

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      }
    };
    return request<API.ResponseResult<API.BudgetProject>>(Path.Get, formatter(options));
  }

  export async function ajaxSearch(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.BudgetProject[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function getForTooltip(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      }
    };
    return request<API.ResponseResult<API.BudgetProjectTooltip>>(Path.GetForTooltip, formatter(options));
  }

  export async function queryForSelect(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<any>>(Path.QueryForSelect, formatter(options));
  }

  export async function queryForPicker(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.BudgetProjectPicker[]>>(Path.QueryForPicker, formatter(options));
  }

  export namespace Buttons {
    export const Path = {
      Get: '/projectapi/buttons/getForBudgetProject',
      Close: '/projectapi/buttons/close',
      Opened: '/projectapi/buttons/opened',
    };

    export async function get(budgetProjectId: number) {
      const options = {
        method: 'GET',
        body: {
          budgetProjectId: budgetProjectId
        },
      };

      return request<API.ResponseResult<any>>(Path.Get, formatter(options));
    };

    export async function close(budgetProjectId: number) {
      const options = {
        method: 'GET',
        body: {
          budgetProjectId: budgetProjectId
        },
      };
      return request<API.ResponseResult<any>>(Path.Close, formatter(options));
    };

    export async function opened(budgetProjectId: number) {
      const options = {
        method: 'GET',
        body: {
          budgetProjectId: budgetProjectId
        }
      };
      return request<API.ResponseResult<any>>(Path.Opened, formatter(options));
    };

  }
}

export namespace BudgetProjectDetail {
  export const Path = {
    query: "/projectapi/budgetProjectDetail/query",
    GetForActivity: "/bpapi/BudgetProjectDetail/GetForActivity",
  }

  export async function query(budgetProjectId: number, buId: number) {
    const options = {
      method: 'GET',
      body: {
        budgetProjectId: budgetProjectId,
        buId: buId
      }
    };
    return request<API.ResponseResult<API.BudgetProjectDetailView[]>>(Path.query, formatter(options));
  }

  export async function getForActivity(body: { BudgetProjectID: number, BOID: number, ECID: number }) {
    const options = {
      method: 'POST',
      body: body
    };
    return request<API.ResponseResult<API.BudgetProjectDetail[]>>(Path.GetForActivity, formatter(options));
  }
}
