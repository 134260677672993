import { useEffect, useState, useRef } from 'react';
import { FilterUtility, PageGrid } from '@/components/GridWidget';
import { NsGrid } from '@/components/GridWidget/GridDefProps';
import { FieldProps, wdiCore } from '@/components/WdiControls';
import { getFormUrlMapping } from '@/components/WdiBus/FormPage';
import { DataOptions } from '@/services/basic/metadata';
import { EC } from '@/services/mdm/ec';
import { Shop } from '@/services/mdm/shop';
import { SoldTo } from '@/services/mdm/soldto';
import { Enum } from '@/enums/system';
import { BO } from '@/services/mdm/bo';
import { Product } from '@/services/mdm/product';
import { Staff } from '@/services/basic/staff';
import { BudgetProject } from '@/services/sales/bp/budgetProject';
import { SA } from '@/services/report/sa';
import { WdiIcon } from '@/components/WdiControls/WdiIcon';
import moment from 'moment';
import { GridApi } from '@/services/gridapi';
import { PageGroup } from '@/services/basic/pagegroup';
import useWindowSize from '@/utils/useWindowSize';
import { FormApi } from '@/services/sales';
import { ResponseStatus } from '@/utils/request';
import { message } from 'antd';
import { SimpleStaff } from '@/services/basic/simplestaff';

type ReleatedActivitiesProps = {
  soldTos?: any[];
  activityStartDate: string;
  activityEndDate: string;
  pageGroupId: number;
  formNo: string;
}
const ReleatedActivitiesApp: React.FC<ReleatedActivitiesProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  //获取GridWdiget实例
  const gridRef: any = useRef();
  const getGridRef = (el: any) => {
    gridRef.current = el
  };

  const showDispModal = (evt: any) => {
    if (evt && evt.target) {
      const dataKey = evt.target.getAttribute("data-key");
      const record = JSON.parse(evt.target.getAttribute("data-record"));
      FormApi.doesAccess(dataKey).then(response => {
        if (response.code == ResponseStatus.SUCCESS && response.data) {
          window.open(getFormUrlMapping(record.pageTypeId, record.formTypeId) + `?formId=${dataKey}&pageTypeId=${record.pageTypeId}&formTypeId=${record.formTypeId}`);
        } else {
          message.error("没有权限查看单据");
        }
      })
    }
  };

  const activityStatus = [
    { label: '待审批', value: String(Enum.FormStatus.AWAITING) }
    , { label: '已审批', value: String(Enum.FormStatus.APPROVE_COMPLETED) }
    , { label: '退回待修改', value: String(Enum.FormStatus.REJECTED) }
    , { label: '作废', value: String(Enum.FormStatus.SCRAP) }
    , { label: '已审批未核销', value: '5' }
    , { label: '已审批核销中', value: '6' }
    , { label: '已审批已核销', value: '7' }
    , { label: '已审批未关闭', value: '8' }
    , { label: '已审批已关闭', value: '9' }
  ];
  const exportStatus = [
    { label: '无需导出', value: '0' }
    , { label: '需要导出', value: '1' }
    , { label: '导出成功', value: '2' }
    , { label: '导出失败', value: '3' }
    , { label: '待再次导出', value: '4' }
  ];

  const warningInfoType = [
    { label: '重复活动申请', value: '2' }
    , { label: '活动补入', value: '4' }
  ];
  const deliveredStatus = [
    { label: "未发货完成", value: 0 },
    { label: "已发货完成", value: 1 }
  ]
  const getApprovalStatus = (record: any) => {
    switch (record.statusId) {
      case 1:
        if (record.approvalStatusId) {
          return <WdiIcon type={Enum.IconType.RED_POINT} />
        } else {
          return <WdiIcon type={Enum.IconType.GREEN_POINT} />
        }
        break;
      case 2:
      case 4:
        return <WdiIcon type={Enum.IconType.GREEN_POINT} />
      case 3:
        return <WdiIcon type={Enum.IconType.RED_POINT} />
    }
    return <></>;
  }
  const windowSize = useWindowSize();
  const containerSize = { height: windowSize.height - 132 };
  useEffect(() => {
    Promise.all([GridApi.searchConfig({
      id: 'ScheduleActivityRelated',
      allowModifyColumns: true
    }), DataOptions.search({ typeGroup: 3, notInValues: '0' })]).then((dataArray: any[]) => {
      const responseResult = dataArray[0];
      const responseDataOptions = dataArray[1];
      const data = responseResult.data;
      const settings: any = Object.assign({}, data, {
        dataLoading: async (params: any) => {
          return await SA.querySa(Object.assign({}, params));
        },
        rowKey: 'formSaId',
        actionsAlign: NsGrid.ActionsAlign.Left,
        filterFormat: NsGrid.FilterFormat.Default,
        allowSaveViewSetting: true,
        viewMode: NsGrid.ViewMode.Advance,
        showToolbarActions: true,
        extendSortFields: [{ "Field": "formSaId", "Order": "desc" }],
      });
      settings.formSchema.allFields.formNo.getDisplayFormater = (text: any, record: any, index: any) => {
        return (
          <a onClick={showDispModal} data-key={record.formSaId} data-record={JSON.stringify(record)}>
            {text}
          </a>
        )
      };

      settings.formSchema.allFields.activityStartDate.getDisplayFormater = (text: any, record: any, index: any) => {
        var html = "";
        var start = record.activityStartDate;
        var end = record.activityEndDate;
        if (start && end) {
          html = wdiCore.Date.Join(start, end);
        }
        return html;
      };
      settings.formSchema.allFields.supplyStartDate.getDisplayFormater = (text: any, record: any, index: any) => {
        var html = "";
        var start = record.SupplyStartDate;
        var end = record.SupplyEndDate;
        if (start && end) {
          html = wdiCore.Date.Join(start, end);
        }
        return html;
      };
      settings.formSchema.allFields.statusId.getDisplayFormater = (text: any, record: any, index: any) => {
        var html = "";
        if (record.statusId == 2) {
          if (record.isClosed) {
            html = "已审批已关闭";// formContext.Res.Closed;
          } else {
            html = "已审批未关闭";//formContext.Res.NotClosed;
            if (record.needSettled) {
              if (record.settledStatus === 2) {
                html = "已审批已核销";// formContext.Res.Settled;
              } else if (record.settledStatus === 1) {
                html = "已审批核销中";// formContext.Res.Settling;
              } else {
                html = "已审批未核销"; //formContext.Res.NotSettleted;
              }
            }
          }
        } else {
          responseDataOptions?.data.forEach((item: any) => {
            if (text == item.value) {
              html = item.name;
              return false;
            }
            return true;
          })
        }
        return html;
      }
      settings.formSchema.allFields.budgetProjectId.getDisplayFormater = (text: any, record: any, index: any) => {
        return wdiCore.String.CodeOrName(record.projectCode, record.projectName);
      };
      settings.formSchema.allFields.approvalStatusId.getDisplayFormater = (text: any, record: any, index: any) => {
        return getApprovalStatus(record);
      };
      settings.formSchema.allFields.pageGroupId.getDisplayFormater = (text: any, record: any, index: any) => {
        return record.pageGroupName;
      };
      settings.formSchema.allFields.exportStatus.getDisplayFormater = (text: any, record: any, index: any) => {
        var html = "";
        exportStatus.forEach((item: any) => {
          if (text == Number(item.value)) {
            html = item.label;
          }
        });
        return html;
      };

      settings.formSchema.allFields.warningIds.getDisplayFormater = (text: any, record: any, index: any) => {
        var html = "";
        warningInfoType.forEach((item: any) => {
          if ((text & Number(item.value)) > 0) {
            if (html.length > 0) {
              html += "," + item.label;
            } else {
              html += item.label;
            }
          }
        });
        return html;
      };
      settings.formSchema.allFields.isDelivered.getDisplayFormater = (text: any, record: any, index: any) => {
        var html = "";
        deliveredStatus.forEach((item: any) => {
          if (text == Number(item.value)) {
            html = item.label;
          }
        });
        return html;
      };

      settings.formSchema.allFields.activityStartDate.getFilterStyle = FilterUtility.createDateTime({
        startFilterValue: moment(props.activityStartDate),
        endFilterValue: moment(props.activityEndDate),
      });

      settings.formSchema.allFields.soldTo.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return SoldTo.search({}).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.codeOrName,
                value: item.soldToCode
              }
            })
          })
        },
        isMulti: true,
        defaultFilterValue: props.soldTos
      });
      settings.formSchema.allFields.boId.getFilterStyle = FilterUtility.createTreeSelect({
        dataLoading: () => {
          return BO.search({ boLevels: Enum.BOLevel.BO1 + ',' + Enum.BOLevel.BO2 + ',' + Enum.BOLevel.BO3 }).then((responseResult) => {
            const { treeData, dict } = FieldProps.TreeSelect.formatTreeData({
              textField: "boName",
              valueField: "boId",
              id: "boId",
              pId: "parentId"
            }, responseResult.data);
            return { treeData, dict };
          })
        },
        isMulti: true,
      });
      settings.formSchema.allFields.budgetProjectId.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return BudgetProject.ajaxSearch({}).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.codeOrName,
                value: item.budgetProjectId
              }
            })
          })
        },
      });
      settings.formSchema.allFields.warningIds.getFilterStyle = FilterUtility.createCombobox({
        antdProps: {
          options: warningInfoType,
        },
        isMulti: true,
      });

      settings.formSchema.allFields.ecId.getFilterStyle = FilterUtility.createTreeSelect({
        dataLoading: () => {
          return EC.search({ ecLevels: [Enum.ECLevel.EC1, Enum.ECLevel.EC2, Enum.ECLevel.EC3].join(",") }).then((responseResult) => {
            const { treeData, dict } = FieldProps.TreeSelect.formatTreeData({
              textField: "ecName",
              valueField: "ecId",
              id: "ecId",
              pId: "parentId"
            }, responseResult.data);
            return { treeData, dict };
          })
        },
        isMulti: true,
      });
      settings.formSchema.allFields.shop.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return Shop.search({}).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.codeOrName,
                value: item.shopId
              }
            })
          })
        },
        isMulti: true,
      });
      settings.formSchema.allFields.pageGroupId.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return PageGroup.search({ formTypeId: Enum.FormType.ACTIVITY_APPLICATION }).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.pageGroupName,
                value: item.pageGroupId
              }
            })
          })
        },
        defaultFilterValue: props.pageGroupId
      });
      settings.formSchema.allFields.statusId.getFilterStyle = FilterUtility.createCombobox({
        antdProps: {
          options: activityStatus,
        },
        isMulti: true,
      });
      settings.formSchema.allFields.detailProduct.getFilterStyle = FilterUtility.createTreeSelect({
        dataLoading: () => {
          return Product.searchNoRoot({}).then((responseResult) => {
            const { treeData, dict } = FieldProps.TreeSelect.formatTreeData({
              textField: "codeOrName",
              valueField: "productId",
              id: "productId",
              pId: "parentId"
            }, responseResult.data);
            return { treeData, dict };
          })
        },
        isMulti: true,
      });
      settings.formSchema.allFields.paymentMethodId.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return DataOptions.search({ typeGroup: 5 }).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.name,
                value: item.value
              }
            })
          })
        },
      });

      settings.formSchema.allFields.staffName.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return SimpleStaff.search({}).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.staffName,
                value: item.staffId
              }
            })
          })
        },
      });

      settings.formSchema.allFields.approverUserIds.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return SimpleStaff.search({}).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.staffName,
                value: item.staffId
              }
            })
          })
        },
      });

      settings.formSchema.allFields.approverUsers.getFilterStyle = FilterUtility.createCombobox({
        dataLoading: () => {
          return SimpleStaff.search({}).then((responseResult) => {
            return responseResult.data?.map((item) => {
              return {
                label: item.staffName,
                value: item.staffId
              }
            })
          })
        },
      });
      settings.formSchema.allFields.exportStatus.getFilterStyle = FilterUtility.createCombobox({
        antdProps: {
          options: exportStatus,
        },
        isMulti: true,
      });

      settings.formSchema.allFields.isDelivered.getFilterStyle = FilterUtility.createCombobox({
        antdProps: {
          options: deliveredStatus,
        },
      });

      settings.formSchema.allFields.formNo.width = 100;
      settings.formSchema.allFields.activityName.width = 200;
      settings.formSchema.allFields.pageGroupId.width = 150;
      settings.formSchema.allFields.activityStartDate.width = 180;
      settings.formSchema.allFields.soldTos.width = 200;
      settings.formSchema.allFields.amount.width = 100;
      settings.formSchema.allFields.supplyStartDate.width = 180;
      settings.formSchema.allFields.budgetProjectId.width = 200;
      settings.formSchema.allFields.statusId.width = 100;
      settings.formSchema.allFields.staffName.width = 100;
      settings.formSchema.allFields.submitDate.width = 150;
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);
  return (
    <>
      {isLoaded && <PageGrid {...gridSettings} getInstance={getGridRef} {...containerSize} />}
    </>
  );
};

export default ReleatedActivitiesApp;
