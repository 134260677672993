import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Tabs, message, Badge, } from 'antd';
import ProForm, { ProFormInstance } from '@ant-design/pro-form';
import { FileUploadUtility, WdiFileList } from '@/components/WdiControls/WdiFileUpload';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import DynamicInformation from '../../components/DynamicInformation';
import BudgetInformation from '../../components/BudgetInformation';
import { FormPageProps, WdiFormPageTitle } from '@/components/WdiBus/FormPage';
import { getFormStatusName, initApprovalPageContext, ViewEntityProps } from '../../components/data';
import { ResponseStatus } from '@/utils/request';
import DataStore from '@/components/GridWidget/DataStore';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { WdiCardTitle, wdiCore, WdiInput, WdiStaffCard, WdiTextArea } from '@/components/WdiControls';
import { Enum } from '@/enums/system';
import { FormSa, FormSaDetail } from '@/services/sales/sa';
import { SAWarningApprovalApp } from '../../components/WarningInformation';
import FormSaDetailGroupPanel from '../../components/FormSADetailGroup';
import { FormSaDetailsForApprovalPanel } from '../../GeneralActivityApply/components/details';
import { BOHQ } from '@/services/mdm/bohq';


const moreConfig: any = {
  successParams: [
    {
      url: "/basicapi/favorite/getButtons",
      keys: []
    }, {
      url: "/activityapi/saButtons/get",
      keys: [
        "UndoButton",
        "ReeditButton",
        "CancelButton",
        "CopyButton",
        "InterveneFlowButton",
        "ClosedButton",
        "OpenedButton",
        "UpdateApplyButton",
        // "PaymentButton",
        // "ModifyPaymentMethodButton",
        // "ModifyAmountButton",
      ]
    }
  ],
  errorParams: [
    {
      url: "/activityapi/saButtons/get",
      keys: [
        "RepairButton",
        "InterveneFlowButton"
      ]
    }
  ]
};

const approvalConfig: any = {
  params: {
    url: "/activityapi/saButtons/get",
    keys: [
      "ApprovalButton"
    ]
  }
};

const GeneralActivityApprovalApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [entity, setEntity] = useState<ViewEntityProps>();

  const [warningCount, setWarningCount] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApprovalPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;
        if (viewEntity.form) {
          formRef.current?.setFieldsValue({
            isSpecialApprove: viewEntity.form.isSpecialApprove
          })
        }
        if (viewEntity.formSa) {
          formRef.current?.setFieldsValue({
            activityName: viewEntity.formSa?.activityName,
            activityDate: wdiCore.Date.Join(viewEntity.formSa?.activityStartDate, viewEntity.formSa?.activityEndDate),
            supplyDate: wdiCore.Date.Join(viewEntity.formSa?.supplyStartDate, viewEntity.formSa?.supplyEndDate),
            fileInfo: FileUploadUtility.parse(viewEntity.formSa?.fileName, viewEntity.formSa?.filePath),
            remark: viewEntity.formSa?.remark,
            project: viewEntity.budgetProject
          });

          if (viewEntity.formSa.bohqId) {
            BOHQ.get(viewEntity.formSa.bohqId).then((response) => {
              formRef.current?.setFieldsValue({
                bohqName: response.data.bohqName
              });
            });
          }
          const formSaId = viewEntity.formSa?.formSaId ?? 0;

          FormSa.Buttons.countOfWarning(formSaId).then(response => {
            setWarningCount(response.data);
          });
          // var soldToCodes: any[] = [];
          FormSaDetail.getsEx(formSaId).then((response) => {
            setDataSource(DataStore.init(response.data));

            // if (response && response.data.length > 0) {
            //   response.data.map(item => {
            //     if (soldToCodes.indexOf(item.soldToCode) < 0) {
            //       soldToCodes.push(item.soldToCode);
            //     }

            //   });
            //   setReleatedActivitiesProps({
            //     soldTos: soldToCodes,
            //     activityStartDate: String(viewEntity.formSa?.activityStartDate),
            //     activityEndDate: String(viewEntity.formSa?.activityEndDate),
            //     pageGroupId: viewEntity.formSa?.pageGroupId,
            //     formNo: viewEntity.form?.formNo,
            //   });
            // }
          });
        }
        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = `客户投资类活动申请单【${entity.form?.formNo}】`;
      getFormStatusName(entity).then(response => {
        title += `,${response}`;
        if (entity?.form?.relationType === 2) {
          title += '（变更申请）';
        }
        if (props.setPageTitle) props.setPageTitle(
          <WdiFormPageTitle
            title={title}
            onClose={props.onClose}
            callback={props.callback}
            form={entity?.form}
            useCaseId={Enum.UseCase.SA}
            moreConfig={moreConfig}
            approvalConfig={approvalConfig} />
        );
      });
    }
  }, [props.setPageTitle, entity]);

  return (
    <>
      <WdiStaffCard formId={entity?.form?.formId} />
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab={<span>活动信息</span>} key="1">
          <ProForm
            formRef={formRef}
            submitter={{
              render: () => { return (<></>) },
            }}
          >
            <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.BASE_INFO} title='活动基本信息' />} collapsed={true} >
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={8}>
                  <Form.Item name="activityName" label="活动名称">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="activityDate" label="活动期间">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="supplyDate" label="供货期间">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="bohqName" label="费用归属">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={16}>
                  <Form.Item name="remark" label="方案简述" >
                    <WdiTextArea rows={4} readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="fileInfo"
                    label="附件"
                    valuePropName='fileList'
                    getValueFromEvent={(e: any) => { return e.fileList || []; }}
                  >
                    <WdiFileList fileList={FileUploadUtility.parse(entity?.formSa?.fileName, entity?.formSa?.filePath)} />
                  </Form.Item>
                </Col>
              </Row>
            </WdiPanel>
          </ProForm>
          {entity?.form?.formId &&
            <FormSaDetailGroupPanel formId={entity?.form?.formId} />
          }
          <FormSaDetailsForApprovalPanel data={{ ecFieldConfigs: entity?.ecFieldConfigs, form: entity?.form }} dataSource={dataSource} />
        </TabPane>
        <TabPane tab={<span>预算信息</span>} key="2">
          <BudgetInformation formId={entity?.form?.formId} />
        </TabPane>
        {/* <TabPane tab={<span>销量与费用(售达方)</span>} key="3">
          <SalesAndCost formSaId={entity?.form?.formId} pageTypeId={entity?.form?.pageTypeId} />
        </TabPane> */}
        {/* {includeShop && <TabPane tab={<span>销量与费用(门店)</span>} key="3" >
          <SalesAndCostByShop formSaId={entity?.form?.formId} pageTypeId={entity?.form?.pageTypeId} />
        </TabPane>} */}
        {/* <TabPane tab={<span>相关活动</span>} key="4">
          <ReleatedActivitiesApp {...releatedActivitiesProps} />
        </TabPane> */}
        <TabPane
          tab={
            <>
              <span>警示信息</span>
              <Badge count={warningCount}
                style={{ color: "black", boxShadow: "none", background: "none", top: "-20px", right: "-15px", position: 'absolute' }}>
              </Badge>
            </>
          }
          key="3"
        >
          <SAWarningApprovalApp formId={entity?.form?.formId} />
        </TabPane>
        <TabPane tab={<span>动态信息</span>} key="4">
          <DynamicInformation formId={entity?.form?.formId} formTypeId={entity?.form?.formTypeId} pageTypeId={entity?.form?.pageTypeId} useCaseId={Enum.UseCase.SA} />
        </TabPane>
      </Tabs>
    </>
  );
};
export default GeneralActivityApprovalApp;
