import {Component} from 'react';
import {responseErrorInfo, responseIsOk} from '@/utils/response';
import {wdiCore} from '../WdiControls';
import {getFilterOptions, NsGridFilter} from './GridWidgetFilter';
import {message, Table} from 'antd';
import {Utility} from './GridWidgetUtility';
import {FooterSummary, ResizableTitle} from './GridPlugin';
import DataStore from './DataStore';
import {initExpandedDisplayFormater} from "@/components/WdiBus/TableColumnRanderFormats";
import {CaretDownOutlined, CaretRightOutlined} from "@ant-design/icons";

export type PaginationValue = {
  onlyTotal: boolean;
  current: number;
  pageSize: number;
  showSizeChanger: boolean;
  pageSizeOptions?: number[];
  total?: number;
  showTotal?: (total: number) => string;
};

export type SortValue = {
  field: string;
  order: string;
};

export type ShowAggrValue = {
  // 定义需要展示汇总的数据列
  columns: [];
};

const getJQXGridArgs = (
  formSchema: API.FormSchema,
  settings: {
    filterOptions?: NsGridFilter.FilterItemValue[];
    // 当前分页
    pagination?: PaginationValue | false;
    // 当前排序列
    sorter?: SortValue | null;
    // 默认设置排序条件
    extendSortFields?: any[];
  },
): API.JQXGridArgs => {
  const params: API.JQXGridArgs = {
    id: formSchema.id,
  };

  if (settings.filterOptions && settings.filterOptions.length) {
    params.filterOptions = JSON.stringify(settings.filterOptions);
  }

  if (settings.pagination) {
    params.pagenum = settings.pagination.current - 1;
    params.pagesize = settings.pagination.pageSize;
  }

  if (settings.sorter) {
    if (settings.sorter.field) {
      params.sortdatafield = settings.sorter.field;
      if (settings.sorter.order && wdiCore.String.EqIngoreCase('descend', settings.sorter.order)) {
        params.sortorder = 'DESC';
      } else {
        params.sortorder = 'ASC';
      }
    }
  }

  // //设置数据延迟加载，如果
  // if (this.state.isFirstLoad && this.state.isLazyLoad) {
  //   params.isLazyLoad = this.state.isLazyLoad;
  // }

  //如果没有设置排序的列，使用默认的多个列排序扩展
  //适用于初始化的时候设置排序的情况
  if (!params.sortdatafield) {
    if (settings.extendSortFields && settings.extendSortFields.length) {
      params.extendsortfields = JSON.stringify(settings.extendSortFields);
    }
  }

  return params;
};

/// 计算滚动区域的高度
const getScrollHeight = (props: {
  // 容器高度
  height: number;
  // 展示grid工具栏, 展示需要减去工具栏高度35
  hiddenToolbar?: boolean;
  // 表头高度 默认39
  headerHeight?: number;
  // 是否分页 如果分页需要 - 分页高度footerHeight
  pagination?: any;
  // 底部分页控件高度 默认56
  footerHeight?: number;
  // 展示聚合列
  showFooterAggr?: boolean;
  // 展示聚合列的高度 56
  footerAggrHeight?: number;
  // 是否展开过滤栏，如果需要展开需要 - filterPanelHeight高度
  showFilterPanel?: boolean;
  // 过滤栏高度：根据过滤列动态计算
  filterPanelHeight?: number;
}) => {
  let height: number = props.height;
  if (props.height) {
    if (!props.hiddenToolbar) {
      height = height - 35;
    }

    //表头高度
    if (props.headerHeight) {
      height = height - props.headerHeight;
    } else {
      height = height - 39;
    }

    if (props.footerHeight) {
      height = height - props.footerHeight;
    } else if (props.pagination === false) {
      /// 不设置分页
    } else {
      /// 减去分页控件占据高度
      height = height - 56;
    }

    if (props.showFooterAggr) {
      if (props.footerAggrHeight) {
        height = height - props.footerAggrHeight;
      } else {
        height = height - 56;
      }
    }

    //滚动条减去过滤列的内容
    if (props.showFilterPanel && props.filterPanelHeight) {
      height = height - props.filterPanelHeight;
    }
    if (height > 0) {
      return height;
    }
  }
  return props.height;
};

export type PageTableProps = {
  // Schema定义
  formSchema: API.FormSchema;
  // 过滤列
  filterStates: NsGridFilter.IBasicFilter[];
  // 展示列
  columnStates: any;
  // Antd Table 需要当前属性标识这一列需要更新
  rowKey?: string;
  // 表格查询需要的数据源
  dataLoading: (args: API.JQXGridArgs, exParams?: any) => Promise<API.QueryResult<any>>;
  // 容器宽度
  width?: number;
  // 容器高度
  height?: number;
  // 固定查询条件
  fixedQueryValues?: NsGridFilter.FilterItemValue[] | NsGridFilter.FilterItemValue;
  // 默认的数据排序规则
  extendSortFields?: any;
  // 面板展开，收缩调整表格高度
  showFilterPanel: boolean;
  // 过滤面板高度, 通过过滤计算获取
  filterPanelHeight?: number;
  // 隐藏工具栏
  hiddenToolbar: boolean;
  // 数据是否延迟加载，报表使用
  isLazyLoad?: boolean;
  //是否有扩展列，配置这个不能配置rowKey，或者配置成uid,否则全部展开不起作用
  showExpandedRow?: boolean;
  //每行是否展示展开图标
  showExpendIcon: (record: any) => boolean;
  defaultShowAllExpanded?: boolean;
  expandable?: any;
  showAggr?: false | ShowAggrValue;
  // Antd Table Props
  antdProps?: any;
};

type PageTableStates = {
  dataSource: any[];
  // 字典类型数据结构
  aggrProps: any;
  columns: string[];
  pagination: false | PaginationValue;
  sorter: SortValue | null;
  filterOptions?: NsGridFilter.FilterItemValue[] | null;
  // 表格内容区域的宽度和高度
  scroll?: any;
  loading: boolean;
  showAllExpanded: boolean;
  expandedRowKeys: string[];
};
const defaultPagination: PaginationValue = {
  onlyTotal: false,
  current: 1,
  pageSize: 20,
  showSizeChanger: true,
  pageSizeOptions: [10, 20, 50, 100],
  showTotal: (total: number) => {
    return `共 ${total} 条`;
  },
};

const defaultReportPagination: PaginationValue = {
  onlyTotal: false,
  current: 1,
  pageSize: 500,
  showSizeChanger: false,
};

class PageTable extends Component<PageTableProps> {
  state: PageTableStates;

  constructor(props: PageTableProps) {
    super(props);

    this.state = {
      dataSource: [],
      columns: [],
      // 当前表格的分页设置
      pagination: defaultPagination,
      // 当前表格设置的排序策略
      sorter: null,
      //保存当前表格的过滤条件
      filterOptions: null,
      loading: false,
      aggrProps: null,
      // 当前表格扩展列
      expandedRowKeys: [],
      // 扩展列是否全部展示
      showAllExpanded: props.defaultShowAllExpanded ? props.defaultShowAllExpanded : false,
      // scroll
    };

    const antdProps = props.antdProps;
    if (antdProps.pagination == false) {
      this.state.pagination = false;
    } else if (antdProps.pagination) {
      this.state.pagination = Object.assign({}, defaultPagination, antdProps.pagination);
    }

    const newState = this.derivedStateFromProps(props.columnStates, props.width);
    if (newState.columns) {
      this.state.columns = newState.columns;
    }
    if (newState.scroll) {
      this.state.scroll = newState.scroll;
    }
  }

  handleResize = (index: number) => {
    return (_: any, {size}: any) => {
      const newColumns = [...this.state.columns];
      const current: any = newColumns[index];
      newColumns[index] = {
        ...current,
        width: size.width,
      };
      this.setState({columns: newColumns});
    };
  };

  derivedStateFromProps = (columnStates: any[], width?: number) => {
    const fitWidgetTable: any = Utility.fitColumnWidth(columnStates, width);
    const mergeColumns = fitWidgetTable.columns.map((col: any, index: number) => ({
      ...col,
      onHeaderCell: (column: any) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    const newState: any = {
      columns: mergeColumns,
    };
    if (fitWidgetTable.scrollX) {
      newState.scroll = {x: fitWidgetTable.scrollX};
    }
    return newState;
  };

  componentDidMount() {
    const {isLazyLoad, filterStates, fixedQueryValues} = this.props;
    if (isLazyLoad) {
      /// 延迟加载数据需要点击之后触发
    } else {
      const responseResult = getFilterOptions(filterStates, true, {
        fixedQueryValues: fixedQueryValues,
      });
      if (responseIsOk(responseResult)) {
        this.reload({filterOptions: responseResult.data});
      } else {
        responseErrorInfo(responseResult);
      }
    }
  }

  componentDidUpdate(prevProps: PageTableProps, prevState: PageTableStates) {
    if (
      this.props.width !== prevProps.width ||
      this.props.columnStates !== prevProps.columnStates
    ) {
      const newState = this.derivedStateFromProps(this.props.columnStates, this.props.width);
      this.setState(newState);
    }
  }

  getRowKey() {
    return wdiCore.String.IsNullOrEmpty(this.props.rowKey) ? 'uid' : this.props.rowKey;
  }

  filterOptions = () => {
    return getFilterOptions(this.props.filterStates, false, {});
  };

  getRowSelection() {
    return this.props.antdProps.rowSelection;
  }

  applyFilter = () => {
    const {filterStates, fixedQueryValues} = this.props;
    const responseResult = getFilterOptions(filterStates, false, {
      fixedQueryValues: fixedQueryValues,
    });
    if (responseIsOk(responseResult)) {
      const {pagination} = this.state;
      // 点击搜索按钮需要回退到第一页，否则查询页面可能没有数据
      if (pagination) {
        const newPagination = Object.assign({...pagination}, {current: 1});
        this.reload({filterOptions: responseResult.data, pagination: newPagination});
      } else {
        this.reload({filterOptions: responseResult.data});
      }
    } else {
      responseErrorInfo(responseResult);
    }
  };

  resetFilter = () => {
    const {filterStates} = this.props;
    (filterStates ? filterStates : []).forEach((item) => {
      item.resetValue();
    });
  };

  getGridArgs = (p?: {
    filterOptions?: NsGridFilter.FilterItemValue[];
    pagination?: PaginationValue | false;
    sorter?: SortValue;
  }) => {
    const {filterStates, fixedQueryValues} = this.props;
    const filterOptions = getFilterOptions(filterStates, false, {
      fixedQueryValues: fixedQueryValues,
    }).data;
    const {formSchema, extendSortFields} = this.props;
    const {pagination, sorter} = Object.assign({}, this.state, p);
    const jqxGridArgs = getJQXGridArgs(formSchema, {
      filterOptions,
      pagination,
      sorter,
      extendSortFields,
    });
    return jqxGridArgs;
  };

  // 页面变化, 排序规则变化需要回调
  handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    this.reload({pagination, sorter});
  };

  reload = (p?: {
    filterOptions?: NsGridFilter.FilterItemValue[];
    pagination?: PaginationValue | false;
    sorter?: SortValue;
  }) => {
    const {formSchema, extendSortFields} = this.props;
    const {filterOptions, pagination, sorter} = Object.assign({}, this.state, p);
    const jqxGridArgs = getJQXGridArgs(formSchema, {
      filterOptions,
      pagination,
      sorter,
      extendSortFields,
    });
    this.fetch({body: jqxGridArgs, filterOptions, pagination, sorter});
  };

  fetch = (p: {
    body: API.JQXGridArgs;
    filterOptions?: NsGridFilter.FilterItemValue[];
    pagination?: PaginationValue | false;
    sorter?: SortValue;
  }) => {
    this.setState({loading: true});
    const {dataLoading, antdProps} = this.props;
    dataLoading(p.body).then((responseResult) => {
      if (responseIsOk(responseResult)) {
        const data = responseResult.data;

        const newState: any = {
          isFirstLoad: false,
          loading: false,
          dataSource: DataStore.init(data.records),
          aggrProps: data.aggrProps,
          sorter: p.sorter,
          filterOptions: p.filterOptions,
        };

        if (this.state.showAllExpanded) {
          newState.expandedRowKeys = (newState.dataSource || []).map((record: any) => record.uid);
        }

        if (p.pagination) {
          newState.pagination = {
            ...defaultPagination,
            ...p.pagination,
            total: data.totalCount,
          };
        }
        antdProps?.rowSelection?.onChange?.([], []);
        this.setState(newState);
      } else {
        message.error(responseResult.message);
        this.setState({loading: false});
      }
    });
  };

  handleExpandedAllRow = (showAllExpanded: boolean) => {
    if (showAllExpanded) {
      this.setState({
        expandedRowKeys: (this.state?.dataSource || []).map((record: any) => record.uid),
        showAllExpanded: true,
      });
    } else {
      this.setState({expandedRowKeys: [], showAllExpanded: false});
    }
  };

  toggleExpandedRowKeys = (key: string) => {
    const expandedRowKeys = [...this.state.expandedRowKeys];
    if (expandedRowKeys.includes(key)) {
      this.setState({expandedRowKeys: expandedRowKeys.filter((e) => e !== key)});
    } else {
      expandedRowKeys.push(key);
      this.setState({expandedRowKeys: expandedRowKeys});
    }
  };

  expendedRowColumn = () => {
    return {
      dataIndex: '__ExpandedRow',
      key: '__ExpandedRow',
      title: () =>
        this.state.showAllExpanded ? (
          <CaretDownOutlined onClick={(e) => this.handleExpandedAllRow(false)}/>
        ) : (
          <CaretRightOutlined onClick={(e) => this.handleExpandedAllRow(true)}/>
        ),
      render: (_: any, record: any) => {
        if (this.props.showExpendIcon(record)) {
          if (this.state.expandedRowKeys.includes(record.uid)) {
            return <CaretDownOutlined onClick={() => this.toggleExpandedRowKeys(record.uid)}/>
          } else {
            return <CaretRightOutlined onClick={() => this.toggleExpandedRowKeys(record.uid)}/>
          }
        } else {
          return <></>
        }
      },
      width: 30,
    };
  };

  render() {
    const {dataSource, pagination, loading, scroll, columns, aggrProps} = this.state;
    const {formSchema, showAggr, showExpandedRow, expandable} = this.props;
    const rowKey = this.getRowKey();
    const antdProps = Object.assign(
      {
        // 默认禁掉排序的Tooltip
        showSorterTooltip: false,
        // 默认设置表格尺寸大小为small
        size: 'small',
        // antd需要当前属性，确定行是否变化
        rowKey,
        locale: {emptyText: "暂无数据"}
      },
      // 使用配置的antd扩展属性覆盖现有属性
      this.props.antdProps,
      {
        // 使用当前分页
        pagination,
        // 当前页面展示的数据源
        dataSource,
        // 是否为loading状态
        loading,
        onChange: this.handleTableChange,
        // 展示行可拖动
        columns,
        components: {
          header: {
            cell: ResizableTitle,
          },
        },
      },
    );

    if (scroll) {
      antdProps.scroll = scroll;
    }

    if (showExpandedRow) {
      antdProps.columns = [this.expendedRowColumn(), ...antdProps.columns];
      if (expandable) {
        antdProps.expandable = Object.assign({
          expandedRowKeys: this.state.expandedRowKeys,
          expandIconColumnIndex: -1,
        }, {...expandable});
      } else {
        antdProps.expandable = Object.assign({
          expandedRowKeys: this.state.expandedRowKeys,
          expandIconColumnIndex: -1,
          expandedRowRender: (record: any) => (
            <p style={{marginLeft: 30, marginBottom: 0, lineHeight: '20px'}}>
              {initExpandedDisplayFormater(
                this.props,
                record,
                antdProps.initRowFieldConfigs,
              )}
            </p>
          ),
        });
      }
    }

    let showFooterAggr = false;
    if (showAggr) {
      showFooterAggr = true;
      antdProps.summary = FooterSummary(formSchema, columns, antdProps.dataSource, aggrProps, showExpandedRow);
    }

    // 报表模式, 使用自定义的分页控件
    if (pagination && pagination.onlyTotal && !wdiCore.Object.IsNullOrUndefined(pagination.total)) {
      antdProps.footer = () => (
        <span>
          {dataSource.length}/共{pagination.total}条
        </span>
      );
      antdProps.pagination = false;
    }

    if (this.props.height) {
      var height = getScrollHeight({
        height: this.props.height,
        hiddenToolbar: this.props.hiddenToolbar,
        // headerHeight
        pagination: pagination,
        // footerHeight
        showFooterAggr,
        // footerAggrHeight
        showFilterPanel: this.props.showFilterPanel,
        filterPanelHeight: this.props.filterPanelHeight,
      });
      if (height > 0) {
        antdProps.scroll = Object.assign({y: height}, antdProps.scroll);
      }
    }

    return <Table {...antdProps} />;
  }
}

export {PageTable, defaultReportPagination};
