import { Space } from 'antd';
import { HomeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { Link, useModel } from 'umi';
import Avatar from './AvatarDropdown';
import styles from './index.less';
import QuickLaunch from '../QuickLaunch';
import Notice from '../Notice';

export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight: React.FC = () => {
  const { initialState } = useModel('@@initialState');

  if (!initialState || !initialState.settings) {
    return null;
  }

  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }
  return (
    <Space className={className}>
      <QuickLaunch />
      <Notice />
      <Link title="帮助中心" className={styles.action} style={{ verticalAlign: 'middle' }} to='/basic/helpcenter'>
        <QuestionCircleOutlined />
      </Link>
      <Avatar menu={true} />
      <Link title="主页" className={styles.action} style={{ verticalAlign: 'middle' }} to='/home/index'>
        <HomeOutlined />
      </Link>
    </Space>
  );
};
export default GlobalHeaderRight;

const fetchChildrenRoutes = (route: any, pathname: string) => {
  let title = '';
  if (String(route.path) === pathname) {
    if (route.name) {
      title = String(route.name);
    }
  } else {
    if (route.children) {
      route.children.map((children: any) => {
        if (!title && children) {
          title = fetchChildrenRoutes(children, pathname);
        }
      })
    }
  }
  return title;
};

export const HeaderTitleContent: React.FC = (props: any) => {
  //防止地址后加 / 
  let pathname = props.location?.pathname;
  if (pathname.substring(pathname.length - 1) === '/') {
    pathname = pathname.substring(0, pathname.length - 1);
  }
  let title = '';
  if (pathname && props.breadcrumb && props.breadcrumb[pathname]) {
    const breadItem = props.breadcrumb[pathname];
    title = breadItem.name;
  }
  // if (!title && props.route) {
  //   title = fetchChildrenRoutes(props.route, pathname);
  // }
  if (props.route) {
    var routeTitle = fetchChildrenRoutes(props.route, pathname);
    if (routeTitle) {
      title = routeTitle;
    }
  }
  return (
    <h3 style={{ fontWeight: "bolder" }} className="wdi-page-title-h3">
      {title}
    </h3>
  );
}
