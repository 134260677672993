import React, { useEffect, useRef } from "react";
import { wdiCore, WdiModalAppProps } from "@/components/WdiControls";
import { ProFormInstance } from "@ant-design/pro-form";
import { FormSp, FormSpDetail } from "@/services/sales/sp";
import { Enum } from "@/enums/system";
import { toExcel } from "@/components/WdiControls/WdiExcel";
import { ImportModalApp } from "@/components/WdiControls/WdiImport";

const PrepayTypeEdit: React.FC<WdiModalAppProps> = (props) => {
    const formRef = useRef<ProFormInstance>();
   
    let columns: any[] = [];

    useEffect(() => {
        if (props.id) {
            const formId = Number(props.id)
            formRef.current?.setFieldsValue({
                formId: formId,
            })
            FormSp.get(formId).then(res => {
                formRef.current?.setFieldsValue({
                    isForPrePay: res?.data.isForPrePay
                });
            })
        }

    }, [props.id])

    if (props.data.form.pageTypeId == Enum.PageType.PAYMENT_CUST_SYS) {
        columns = [
            { name: "formSpDetailId", displayName: "付款明细编号*" },
            { name: "formSaDetailId", displayName: "活动明细编号" },
            { name: "formSaNo", displayName: "活动单号" },
            { name: "activityName", displayName: "活动名称" },
            { name: "activityStartDate", displayName: "活动开始日期" },
            { name: "activityEndDate", displayName: "活动截止日期" },
            { name: "soldToCode", displayName: "售达方编号" },
            { name: "soldToName", displayName: "售达方名称" },
            { name: "custSysCode", displayName: "客户集团编号" },
            { name: "custSysName", displayName: "客户集团名称" },
            { name: "platformName", displayName: "分子平台" },
            { name: "districtName", displayName: "省份" },
            { name: "productBuyNames", displayName: "产品别" },
            { name: "ecName", displayName: "费用项" },
            { name: "promotionDesc", displayName: "活动机制" },
            { name: "paymentMethodName", displayName: "支付方式" },
            { name: "invoiceTypeName", displayName: "发票类型" },
            { name: "vatTypeName", displayName: "税率类型" },
            { name: "amountSa", displayName: "申请金额" },
            { name: "amountSs", displayName: "核销金额" },
            { name: "amountPaid", displayName: "已付款金额" },
            { name: "amountNotPaid", displayName: "可付款金额" },
            { name: "amountExVat", displayName: "本次付款金额" },
            { name: "remark", displayName: "付款备注" },
            { name: "invoiceNo", displayName: "发票号码*" },
        ];
    } else if (props.data.form.pageTypeId == Enum.PageType.PAYMENT_CONTRACT_REBATE) {
        columns = [
            { name: "formSpDetailId", displayName: "付款明细编号*" },
            { name: "rebateInfoId", displayName: "返利单编号" },
            { name: "soldToCode", displayName: "售达方编号" },
            { name: "soldToName", displayName: "售达方名称" },
            { name: "custSysCode", displayName: "客户集团编号" },
            { name: "custSysName", displayName: "客户集团名称" },
            { name: "ecName", displayName: "费用项" },
            { name: "productLineCode", displayName: "产品线编号" },
            { name: "productLineName", displayName: "产品线名称" },
            { name: "speriod", displayName: "销售月份" },
            { name: "paymentMethodName", displayName: "支付方式" },
            { name: "invoiceTypeName", displayName: "发票类型" },
            { name: "vatTypeName", displayName: "税率类型" },
            { name: "amountSs", displayName: "核销金额" },
            { name: "amountNotPaid", displayName: "可付款金额" },
            { name: "amountExVat", displayName: "本次付款金额" },
            { name: "remark", displayName: "付款备注" },
            { name: "invoiceNo", displayName: "发票号码*" },
        ];
    } else if (props.data.form.pageTypeId == Enum.PageType.PAYMENT_CONTRACT_ONINVOICE) {
        columns = [
            { name: "formSpDetailId", displayName: "付款明细编号*" },
            { name: "expenseNonTpmId", displayName: "返利单编号" },
            { name: "soldToCode", displayName: "售达方编号" },
            { name: "soldToName", displayName: "售达方名称" },
            { name: "custSysCode", displayName: "客户集团编号" },
            { name: "custSysName", displayName: "客户集团名称" },
            { name: "ecName", displayName: "费用项" },
            { name: "productLineCode", displayName: "产品线编号" },
            { name: "productLineName", displayName: "产品线名称" },
            { name: "fperiod", displayName: "销售月份" },
            { name: "paymentMethodName", displayName: "支付方式" },
            { name: "invoiceTypeName", displayName: "发票类型" },
            { name: "vatTypeName", displayName: "税率类型" },
            { name: "amountExVat", displayName: "本次付款金额" },
            { name: "remark", displayName: "付款备注" },
            { name: "invoiceNo", displayName: "发票号码*" },
        ];
    }

    return (
        <>
            <ImportModalApp
                title={props.title}
                visible={props.visible}
                settingsUrl="/activityapi/spButtons/getImportSettings"
                uploadingUrl="/activityapi/spButtons/importUploading"
                onClose={props.onClose}
                onSave={props.onSave}
                clientParameters={{
                    formTypeId: props.data.form.formTypeId,
                    pageTypeId: props.data.form.pageTypeId,
                    formId: props.id,
                    isInvoice: 1
                }}
                events={{
                    downloadTempByEc: () => {
                        if (props.data.form.pageTypeId == Enum.PageType.PAYMENT_CUST_SYS) {
                            FormSpDetail.exportFormSpDetailForPayment(Number(props.id)).then(res => {
                                const sheet = {
                                    data: res?.data,
                                    names: columns,
                                    sheetName: "formSpDetails",
                                };
                                toExcel({
                                    name: "付款明细信息:发票信息导入模板_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
                                    sheetProps: [sheet]
                                })
                            })
                        } else if (props.data.form.pageTypeId == Enum.PageType.PAYMENT_CONTRACT_REBATE) {
                            FormSpDetail.getsForRebate(Number(props.id)).then(res => {
                                const sheet = {
                                    data: res?.data,
                                    names: columns,
                                    sheetName: "formSpDetails",
                                };
                                toExcel({
                                    name: "付款明细信息:发票信息导入模板_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
                                    sheetProps: [sheet]
                                })
                            })
                        } else if (props.data.form.pageTypeId == Enum.PageType.PAYMENT_CONTRACT_ONINVOICE) {
                            FormSpDetail.getsForExpenseNonTpm(Number(props.id)).then(res => {
                                const sheet = {
                                    data: res?.data,
                                    names: columns,
                                    sheetName: "formSpDetails",
                                };
                                toExcel({
                                    name: "付款明细信息:发票信息导入模板_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
                                    sheetProps: [sheet]
                                })
                            })
                        }
                    },
                }}
            />        </>
    )
}
export default PrepayTypeEdit;
