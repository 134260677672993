import {formatter, request} from '@/utils/request';

export namespace ProductPrice {
  export const Path = {
    GetProductPrice: '/mdmapi/productPrice/getProductPrice'
  };

  export async function getProductPrice(body: API.ProductPriceSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.GetProductPrice, formatter(options));
  }
}
