import { Button, Result } from 'antd';
import React from 'react';
import { history } from 'umi';

const AccessDenyPage: React.FC<{ subTitle?: string }> = (props) => (
  <Result
    status="403"
    title="403"
    subTitle={props?.subTitle || "您没有权限访问该页面"}
    extra={
      <Button type="primary" onClick={() => history.push('/')}>
        返回首页
      </Button>
    }
  />
);

export default AccessDenyPage;
