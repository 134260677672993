import {formatter, request} from "@/utils/request";

export namespace District {
  export const Path = {
    Get: '/mdmapi/district/get',
    Search: '/mdmapi/district/search',
    AjaxSearch: '/mdmapi/district/ajaxSearch',
    GetAllDistrict: '/mdmapi/district/getAllDistrict',
    QueryForSheet: '/mdmapi/district/queryForSheet',
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.District>>(Path.Get, formatter(options));
  }

  export async function search(body: API.DistrictFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.District[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.DistrictFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.District[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function getAllDistrict(body: API.DistrictFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<Array<any>>>(Path.GetAllDistrict, formatter(options));
  }

  export async function queryForSheet(body: API.DistrictFilter) {
    const options = {
      method: 'GET',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.DistrictStructure[]>>(Path.QueryForSheet, formatter(options));
  }

}
