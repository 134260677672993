export default {
  'pages.layouts.userLayout.title':
    'Ant Design é a especificação de web design mais influente no distrito de Xihu',
  'pages.login.accountLogin.tab': 'Login da conta',
  'pages.login.accountLogin.errorMessage': 'usuário/senha incorreto(admin/ant.design)',
  'pages.login.username.placeholder': 'Usuário: admin or user',
  'pages.login.username.required': 'Por favor insira seu usuário!',
  'pages.login.password.placeholder': 'Senha: ant.design',
  'pages.login.password.required': 'Por favor insira sua senha!',
  'pages.login.phoneLogin.tab': 'Login com Telefone',
  'pages.login.phoneLogin.errorMessage': 'Erro de Código de Verificação',
  'pages.login.phoneNumber.placeholder': 'Telefone',
  'pages.login.phoneNumber.required': 'Por favor entre com seu telefone!',
  'pages.login.phoneNumber.invalid': 'Telefone é inválido!',
  'pages.login.captcha.placeholder': 'Código de Verificação',
  'pages.login.captcha.required': 'Por favor entre com o código de verificação!',
  'pages.login.phoneLogin.getVerificationCode': 'Obter Código',
  'pages.getCaptchaSecondText': 'seg(s)',
  'pages.login.rememberMe': 'Lembre-me',
  'pages.login.forgotPassword': 'Perdeu a Senha ?',
  'pages.login.submit': 'Enviar',
  'pages.login.loginWith': 'Login com :',
  'pages.login.registerAccount': 'Registra Conta',
  'pages.welcome.advancedComponent': 'Componente Avançado',
  'pages.welcome.link': 'Bem-vindo',
  'pages.welcome.advancedLayout': 'Layout Avançado',
  'pages.welcome.alertMessage': 'Componentes pesados mais rápidos e mais fortes foram lançados.',
  'pages.admin.subPage.title': 'Esta página só pode ser vista pelo Admin',
  'pages.admin.subPage.alertMessage':
    'O Umi ui foi lançado, bem-vindo ao usar o npm run ui para iniciar a experiência.',
  'pages.searchTable.createForm.newRule': 'Neva Regra',
  'pages.searchTable.updateForm.ruleConfig': 'Configuração de Regra',
  'pages.searchTable.updateForm.basicConfig': 'Informação básica',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Nome da Regra',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Por favor entre com o nome da regra!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Descrição da Regra',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder':
    'Por favor insira ao menos cinco caracteres',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Insira uma descrição de regra de pelo menos cinco caracteres!',
  'pages.searchTable.updateForm.ruleProps.title': 'Configurar Propriedades',
  'pages.searchTable.updateForm.object': 'Objeto de Monitoramento',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Modelo de Regra',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Tipo de Regra',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Definir Período de Agendamento',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Hora de Início',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules':
    'Por favor selecione um horáriod e início!',
  'pages.searchTable.titleDesc': 'Descrição',
  'pages.searchTable.ruleName': 'O nome da regra é obrigatório',
  'pages.searchTable.titleCallNo': 'Número de chamadas de serviço',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'padrão',
  'pages.searchTable.nameStatus.running': 'executando',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'anormal',
  'pages.searchTable.titleUpdatedAt': 'Última programação em',
  'pages.searchTable.exception': 'Por favor, indique o motivo da exceção!',
  'pages.searchTable.titleOption': 'Opção',
  'pages.searchTable.config': 'Configuração',
  'pages.searchTable.subscribeAlert': 'Inscreva-se para receber alertas',
  'pages.searchTable.title': 'Formulário de Consulta',
  'pages.searchTable.new': 'Novo',
  'pages.searchTable.chosen': 'selecionado',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Número total de chamadas de serviço',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'deleção em lote',
  'pages.searchTable.batchApproval': 'aprovação em lote',
};
