import DataStore from "@/components/GridWidget/DataStore";
import { wdiCore, WdiModalAppProps } from "@/components/WdiControls";
import { WdiModal } from "@/components/WdiControls/WdiModalForm";
import { WdiTable } from "@/components/WdiControls/WdiTable";
import { Enum } from "@/enums/system";
import { FormSa, FormSaDetail } from "@/services/sales/sa";
import { TableColumnsType } from "antd";
import { useEffect, useState } from "react";

const PD_HID_COL_NAME = 'pdHidden';
const GENERAL_HID_COL_NAME = 'generalHidden';

const ViewProcessModalApp: React.FC<WdiModalAppProps> = (props) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [columns, setColumns] = useState<TableColumnsType<API.FormSaDetailVo>>([]);
  const allColumns: TableColumnsType<API.FormSaDetailVo> = [
    {
      title: '行号',
      width: 50,
      dataIndex: 'rowNumber',
    },
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      ellipsis: true,
      width: 220,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.soldToCode, record.soldToName)}</span>
      ),
    },
    {
      title: '客户集团',
      dataIndex: 'custSysCode',
      ellipsis: true,
      width: 200,
      className: PD_HID_COL_NAME,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.custSysCode, record.custSysName)}</span>
      ),
    },
    {
      title: '门店',
      dataIndex: 'shopId',
      ellipsis: true,
      width: 200,
      className: PD_HID_COL_NAME,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.shopCode, record.shopName)}</span>
      ),
    },
    {
      title: '产品别',
      dataIndex: 'productBuyNames',
      ellipsis: true,
      width: 260,
      className: PD_HID_COL_NAME,
    },
    {
      title: '购买产品',
      dataIndex: 'productBuyNames',
      ellipsis: true,
      width: 260,
      className: GENERAL_HID_COL_NAME,
    },
    {
      title: '产品政策',
      dataIndex: 'productPolicyId',
      ellipsis: true,
      width: 200,
      className: GENERAL_HID_COL_NAME,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.policyCode, record.policyName)}</span>
      ),
    },
    {
      title: '费用项',
      dataIndex: 'ecName',
      width: 150,
      ellipsis: true,
    },
    {
      title: '预估销量',
      dataIndex: 'quantityForecast',
      width: 90,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
    {
      title: '预计销售额',
      dataIndex: 'revenueForecast',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
    {
      title: '申请金额',
      dataIndex: 'amount',
      width: 90,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
    {
      title: '实际销量',
      dataIndex: 'quantityActual',
      width: 90,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
    {
      title: '实际销售额',
      dataIndex: 'revenueActual',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
    {
      title: '当前实际花费',
      dataIndex: 'amountActual',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
  ];

  useEffect(() => {
    if (props.id) {
      const formSaId = Number(props.id);
      FormSa.get(formSaId).then((response) => {
        if (response && response.data) {
          if (response.data.pageTypeId == Enum.PageType.PRICE_DISCOUNT_APPLICATION) {
            setColumns(allColumns.filter(m => m.className != PD_HID_COL_NAME));
          } else {
            setColumns(allColumns.filter(m => m.className != GENERAL_HID_COL_NAME));
          }
        }
      });

      setLoading(true);
      FormSaDetail.getsEx(formSaId).then((response) => {
        const newDs: any[] = [...response.data];
        newDs.map((item) => {
          if (item.revenueType == Enum.RevenueType.POS_QUANTITY) {
            item.quantityForecast = item.quantityPosForecast ?? 0;
            item.quantityActual = item.quantityPosActual ?? 0;
          } else if (item.revenueType == Enum.RevenueType.POS_QUANTITY) {
            item.revenueForecast = item.revenuePosForecast ?? 0;
            item.revenueActual = item.revenuePosActual ?? 0;
          } else if (item.revenueType == Enum.RevenueType.SELLOUT_QUANTITY_1) {
            item.quantityForecast = item.quantitySelloutForecast ?? 0;
            item.quantityActual = item.quantitySelloutActual ?? 0;
          } else if (item.revenueType == Enum.RevenueType.SELLOUT_REVENUE) {
            item.revenueForecast = item.revenueSelloutForecast ?? 0;
            item.revenueActual = item.revenueSelloutActual ?? 0;
          } else if (item.revenueType == Enum.RevenueType.SELLOUT_QUANTITY_2) {
            item.quantityForecast = item.quantitySelloutForecast2 ?? 0;
            item.quantityActual = item.quantitySelloutActual2 ?? 0;
          } else if (item.revenueType == Enum.RevenueType.SELLOUT_QUANTITY_3) {
            item.quantityForecast = item.quantitySelloutForecast3 ?? 0;
            item.quantityActual = item.quantitySelloutActual3 ?? 0;
          } else if (item.revenueType == Enum.RevenueType.SELLOUT_QUANTITY_4) {
            item.quantityForecast = item.quantitySelloutForecast4 ?? 0;
            item.quantityActual = item.quantitySelloutActual4 ?? 0;
          } else if (item.revenueType == Enum.RevenueType.SELLOUT_QUANTITY_5) {
            item.quantityForecast = item.quantitySelloutForecast5 ?? 0;
            item.quantityActual = item.quantitySelloutActual5 ?? 0;
          } else if (item.revenueType == Enum.RevenueType.SELLIN_QUANTITY_1
            || item.revenueType == Enum.RevenueType.SELLIN_QUANTITY_2
            || item.revenueType == Enum.RevenueType.SELLIN_QUANTITY_3) {
            item.quantityForecast = item.quantitySellinForecast ?? 0;
            item.quantityActual = item.quantitySellinActual ?? 0;
          } else if (item.revenueType == Enum.RevenueType.SELLIN_REVENUE_1
            || item.revenueType == Enum.RevenueType.SELLIN_REVENUE_2
            || item.revenueType == Enum.RevenueType.SELLIN_REVENUE_3
            || item.revenueType == Enum.RevenueType.SELLIN_REVENUE_4) {
            item.quantityForecast = item.revenueSellinForecast ?? 0;
            item.quantityActual = item.revenueSellinActual ?? 0;
          } else if (item.revenueType == Enum.RevenueType.PRICE
            || item.revenueType == Enum.RevenueType.PD_DISCOUNT) {
            item.quantityForecast = item.quantitySellinForecast ?? 0;
            item.revenueForecast = item.revenueSellinForecast ?? 0;
            item.quantityActual = item.quantitySellinActual ?? 0;
            item.revenueActual = item.revenueSellinActual ?? 0;
          } else if (item.revenueType == Enum.RevenueType.PD_BUY_GIVE) {
            item.revenueForecast = item.revenueSellinForecast ?? 0;
            item.revenueActual = item.revenueSellinActual ?? 0;
          }
        });
        setDataSource(DataStore.init(newDs));
        setLoading(false);
      });
    }
  }, [props.id]);

  return (
    <WdiModal
      title={props.title}
      visible={props.visible}
      width={1350}
      onCancel={() => { props.onClose ? props.onClose() : null }}
      footer={false}
    >
      <WdiTable
        showPageRows={10}
        columns={columns}
        dataSource={dataSource}
        pagination={{ showSizeChanger: false }}
        loading={loading}
        aggrColumns={['quantityForecast', 'revenueForecast', 'amount', 'quantityActual', 'revenueActual', 'amountActual']}
      />
    </WdiModal>
  );
};

export default ViewProcessModalApp;
