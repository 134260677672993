import { useEffect, useState } from 'react';
import { DetailGrid, Utility } from '@/components/GridWidget';
import { NsGrid } from '@/components/GridWidget/GridDefProps';
import { WdiModalProps, WdiModalAppResult, WdiCardTitle } from '@/components/WdiControls';
import FormBPDetailEditApp from './detail-edit';
import { Dropdown, Menu, message } from 'antd';
import { TableColumnRanderFormats, initFieldDisplayFormater } from '@/components/WdiBus/TableColumnRanderFormats'
import DataStore from '@/components/GridWidget/DataStore'
import { ResponseStatus } from '@/utils/request';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { ImportModalApp } from '@/components/WdiControls/WdiImport';
import { Enum } from '@/enums/system';
import { GridApi } from "@/services/gridapi";
import { BO } from "@/services/mdm/bo";
import { ToExcelProps } from "@/components/WdiControls/WdiExcel";
import { EC } from '@/services/mdm/ec';

const ModalAppTypeEnums = {
  None: 0,
  Insert: 1,
  Edit: 2,
  Import: 3,
};

export type DetailForApplyProps = {
  data?: any,
  dataSource: any[],
  onChange?: (dataSource: any) => void;
};

const FormBPDetailsForApplyPanel: React.FC<DetailForApplyProps> = (props) => {
  // Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});

  const { data, dataSource, onChange } = props;
  const [importExcels, setImportExcels] = useState<ToExcelProps[]>([]);

  const [editModalProps, setEditModalProps] = useState<WdiModalProps>({ visible: false });
  const onEditClose = () => {
    setEditModalProps({ visible: false, title: "", type: ModalAppTypeEnums.None, id: null });
  };
  const onEditSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      const row = result?.data;
      if (editModalProps.type === ModalAppTypeEnums.Edit) {
        const record = editModalProps.data.row;
        const newItem = { ...row };
        newItem.uid = record.uid;
        const newDataSouce = DataStore.edit(dataSource, newItem);
        onChange?.(newDataSouce);
      } else if (editModalProps.type === ModalAppTypeEnums.Insert) {
        const newItem = { ...row };
        onChange?.(DataStore.add(dataSource, newItem));
      }
    }
    setEditModalProps({ visible: false, title: "", id: null });
  };
  const [importModalProps, setImportModalProps] = useState<WdiModalProps>({ visible: false });
  const onImportClose = () => {
    setImportModalProps({ visible: false, title: "", id: null });
  }
  const onImportSave = (uploadResult: any) => {
    if (uploadResult.code == ResponseStatus.SUCCESS) {
      const newDataSource = [...dataSource];
      const result = uploadResult?.data?.data;
      if (uploadResult?.data?.exProperty) {
        if (result && result.length > 0) {
          onChange?.(DataStore.addMulti([], result));
        }
      } else {
        if (result && result.length > 0) {
          onChange?.(DataStore.addMulti(newDataSource, result));
        }
      }
    }
    setImportModalProps({ visible: false, title: "", id: null });
  }

  useEffect(() => {
    GridApi.searchConfig({ id: 'FormBudgetProjectDetail', allowModifyColumns: false }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,
        filterMenuActions: (actions: any, record: any) => {
          const newActions: any[] = [];
          if (actions) {
            actions.forEach((item: any) => {
              if (item.name == 'SystemDelete') {
                if (!record.budgetProjectDetailId) {
                  newActions.push(item);
                }
              } else {
                newActions.push(item);
              }
            });
          }
          return newActions;
        }
      });

      initFieldDisplayFormater(settings.formSchema.allFields)

      settings.formSchema.allFields.amount.aggr = TableColumnRanderFormats.sumAggr('amount', 2);

      Utility.setActionCallback(
        settings.formSchema.actions,
        'Update',
        async (grid: any, action: any, record: any) => {
          const context = grid.getContext();
          setEditModalProps({
            visible: true,
            title: '编辑分配明细',
            type: ModalAppTypeEnums.Edit,
            id: record.formBudgetProjectDetailId,
            data: {
              row: record,
              rows: context.dataSource,
              rowIndex: DataStore.getRowIndex(context.dataSource, record.uid),
              pageTypeId: context.params.pageTypeId,
              bohqId: context.params.bohqId,
            }
          });
        },
      );

      Utility.setActionCallback(
        settings.formSchema.actions,
        'SystemDelete',
        async (grid: any, action: any, record: any) => {
          if (record.budgetProjectDetailId) {
            message.error("已经创建专项的明细不能被删除");
            return
          }
          if (confirm("确定要删除吗？")) {
            const context = grid.getContext();
            onChange?.(DataStore.del(context.dataSource, record.uid));
            message.success("删除成功");
          }
        },
      );

      settings.formSchema.allFields.rowNumber.width = 80;
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    const boColumns = [
      { name: "boName1", displayName: "一级预算中心名称" },
      { name: "boName2", displayName: "二级预算中心名称" },
      { name: "boName3", displayName: "三级预算中心名称" },
      { name: "boName4", displayName: "四级预算中心名称" },
    ];
    const boSheet = {
      dataLoading: async (params: any) => {
        return await BO.exportEx(Object.assign({ isActive: true, withPermissions: true, boLevels: String(Enum.BOLevel.BO4) }, params));
      },
      data: null,
      names: boColumns,
      sheetName: "预算中心",
    };

    const ecColumns = [
      { name: "ecName1", displayName: "费用大类名称" },
      { name: "ecName2", displayName: "费用小类名称" },
    ];
    const ecSheet = {
      dataLoading: async (params: any) => {
        return await EC.queryForSheet({ ecLevel: Enum.ECLevel.EC2, pageTypeId: data.pageTypeId, bohqId: data.bohqId });
      },
      data: null,
      names: ecColumns,
      sheetName: "费用类别",
    };
    const importExcels: ToExcelProps[] = [];
    importExcels.push({
      name: "全部下载",
      sheetProps: [boSheet, ecSheet]
    });

    importExcels.push({
      name: "下载预算中心列表",
      sheetProps: [boSheet]
    }, {
      name: "下载费用类别列表",
      sheetProps: [ecSheet]
    });

    setImportExcels(importExcels)
  }, [props]);

  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
  };

  return (
    <WdiPanel
      title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用分配信息' />}
      extra={
        <Dropdown.Button
          type='primary'
          onClick={() => {
            setEditModalProps({
              visible: true,
              title: '新建分配明细',
              type: ModalAppTypeEnums.Insert,
              id: null,
              data: {
                row: null,
                rows: dataSource,
                rowIndex: -1,
                pageTypeId: data.pageTypeId,
                bohqId: data.bohqId,
              }
            });
          }}
          overlay={
            <Menu items={[{
              key: 'import',
              label: '导入',
              onClick: () => {
                setImportModalProps({
                  title: '费用分配信息导入', visible: true
                });
                return true;
              }
            }]} />
          }
          style={{ float: 'right' }}
        >新建</Dropdown.Button>
      }
    >
      {isLoaded &&
        importModalProps.visible &&
        <ImportModalApp
          title={importModalProps.title}
          visible={importModalProps.visible}
          settingsUrl="/projectapi/buttons/importSettings"
          uploadingUrl="/projectapi/buttons/importUploading"
          onClose={onImportClose}
          onSave={onImportSave}
          importExcels={importExcels}
          clientParameters={{
            isCreate: (data.budgetProject ? false : true),
            formBudgetProject: data,
            formBudgetProjectDetails: JSON.stringify(dataSource)
          }}
        />
      }
      {
        isLoaded &&
        editModalProps.visible &&
        (editModalProps.type === ModalAppTypeEnums.Insert || editModalProps.type === ModalAppTypeEnums.Edit) &&
        <FormBPDetailEditApp
          {...editModalProps}
          onClose={onEditClose}
          onSave={onEditSave}
        />
      }
      {
        isLoaded &&
        <DetailGrid {...pageGridSettings} params={data} autoColumn={['rowNumber']} />
      }
    </WdiPanel>
  );
};
export default FormBPDetailsForApplyPanel;

export const FormBPDetailsForApprovalPanel: React.FC<DetailForApplyProps> = (props) => {

  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});

  useEffect(() => {
    GridApi.searchConfig({ id: 'FormBudgetProjectDetail', allowModifyColumns: false }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.None,
        showAggr: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.amount.aggr = TableColumnRanderFormats.sumAggr('amount', 2);

      settings.formSchema.allFields.rowNumber.width = 50;
      setGridSettings(settings);

      setIsLoaded(true);
    });
  }, []);

  const { dataSource } = props;
  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource
  };

  return (
    <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用分配信息' />} collapsed={true} >
      {isLoaded && <DetailGrid {...pageGridSettings} />}
    </WdiPanel>
  );
};
