import React, { useState, useRef } from 'react';
import { ModalForm, ModalFormProps } from '@ant-design/pro-form';
import { Modal, ModalProps } from 'antd';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import useWindowSize from '@/utils/useWindowSize';

export type WdiModalFormProps = {
  disableDragable?: boolean
} & ModalFormProps<any>;

export const WdiModalForm: React.FC<WdiModalFormProps> = (props) => {
  const { height } = useWindowSize();
  const { disableDragable, ...newProps } = Object.assign({}, props);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const draggleRef = useRef<HTMLDivElement>(null);

  const antdProps = Object.assign({}, newProps);
  antdProps.modalProps = Object.assign({
    destroyOnClose: true,
    maskClosable: false
  }, antdProps.modalProps, {
    modalRender: (modal: any) => {
      return (
        <Draggable
          disabled={disableDragable}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
          handle='.ant-modal-header'
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )
    }
  });

  return (
    <ModalForm
      {...antdProps}
    >
      <div style={{ maxHeight: height - (antdProps.submitter === false ? 280 : 330), overflowY: 'auto', overflowX: 'hidden' }}>
        {props.children}
      </div>
    </ModalForm>
  );
}

export type WdiModalProps = {
  disableDragable?: boolean;
  // 弹框内容高度，如果配置了就按照配置的
  bodyHeight?: number | string;
} & ModalProps;

export const WdiModal: React.FC<WdiModalProps> = (props) => {
  const { height } = useWindowSize();
  const { disableDragable, ...newProps } = Object.assign({}, props);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const draggleRef = useRef<HTMLDivElement>(null);

  const antdProps = Object.assign({
    destroyOnClose: true,
    maskClosable: false
  }, newProps, {
    modalRender: (modal: any) => {
      return (
        <Draggable
          disabled={disableDragable}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
          handle='.ant-modal-header'
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )
    }
  });

  const bodyStyle: React.CSSProperties = {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: props.bodyHeight ? props.bodyHeight : height - (antdProps.footer === false ? 280 : 330),
  }
  return (
    <Modal
      {...antdProps}
    >
      <div style={props.bodyHeight ? { height: props.bodyHeight, ...bodyStyle } : { ...bodyStyle }}>
        {props.children}
      </div>
    </Modal>
  );
}

export const HorzLayoutOpts: any = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
  labelAlign: 'left',
  layout: "horizontal"
};