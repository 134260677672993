import { request, formatter } from '@/utils/request';

export namespace BO {
  export const Path = {
    Search: '/mdmapi/bo/search',
    AjaxSearch: '/mdmapi/bo/ajaxSearch',
    Get: '/mdmapi/bo/get',
    Post: '/mdmapi/bo/post',
    Delete: '/mdmapi/bo/delete',
    Export: '/mdmapi/bo/export'
  };

  export async function search(body: API.BOFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.BO[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.BOFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.BO[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.BO>>(Path.Get, formatter(options));
  }



  export async function post(body: API.BO) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.BO>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<boolean>>(Path.Delete, formatter(options));
  }

  export async function exportEx(body: API.BOFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.BOExport>>(Path.Export, formatter(options));
  }
}
