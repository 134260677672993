import {formatter, request} from "@/utils/request";

export namespace SalesContractHistory {
  export const Path = {
    Get: '/contractapi/salescontracthistory/get',
    Query: '/contractapi/salescontracthistory/query',
  }
 
  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.SalesContractHistory>>(Path.Get, formatter(options));
  }

  export async function query(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.SalesContractHistory[]>>(Path.Query, formatter(options));
  }
}




