import {ParamsProps} from '@/components/WdiBus/FormPage';
import {Enum} from '@/enums/system';
import {ResponseStatus} from '@/utils/request';
import {WdiModalAppResult} from '@/components/WdiControls';
import {FormApi} from '@/services/sales';
import {FormSs} from '@/services/sales/ss';
import {DataOptions} from '@/services/basic/metadata';
import {EC} from '@/services/mdm/ec';
import DataStore from '@/components/GridWidget/DataStore';
import {FormSa} from "@/services/sales/sa";

export type ViewEntityProps = {
  pageTypeId?: number;
  formTypeId?: number;
  formId?: number;
  relationType?: Enum.FormRelationType;
  relatedFormId?: number;
  formSaId?: number,
  saForm?: API.Form;
  form?: API.Form;
  formSs?: API.FormSs;
  formSsDetails?: API.FormSsDetail[],
  settlementMaterials?: API.DataOptions[];
  ecFieldConfigs?: API.EcFieldConfig[];
  bohqId?: number;
}

export const initApplyPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  viewEntity.formTypeId = Enum.FormType.ACTIVITY_SETTLEMENT;
  if (params.formId || params.relatedFormId) {
    // 退回待修改
    const formResponse = await FormApi.get(params.formId || params.relatedFormId || 0);
    viewEntity.form = formResponse.data;
    if (!viewEntity.form) {
      result.message = "无效的退回待修改单";
      return result;
    }

    if (params.formId) {
      viewEntity.formId = params.formId;
    } else {
      viewEntity.relationType = Enum.FormRelationType.REJECTED;
      viewEntity.relatedFormId = params.relatedFormId;
    }

    const formSSResponse = await FormSs.get(viewEntity.form.formId);
    if (formSSResponse) {
      viewEntity.formSs = formSSResponse.data;
      viewEntity.formSaId = viewEntity.formSs.formSaId;
    }
  } else {
    viewEntity.formSaId = params.formSaId;
  }

  if (viewEntity.formSs?.formSsId) {
    const formSsDetailsResponse = await FormSs.getFormSsDetailsForApply({formSsId: viewEntity.formSs?.formSsId});
    viewEntity.formSsDetails = DataStore.init(formSsDetailsResponse.data);
  }
  if (viewEntity.formSs) {
    const formSaRes = await FormSa.get(Number(viewEntity?.formSs?.formSaId));
    viewEntity.pageTypeId = formSaRes.data.pageTypeId;
    viewEntity.bohqId = formSaRes.data.bohqId;
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
}

export const initApprovalPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId) {
    const formSSResponse = await FormSs.get(params.formId);
    viewEntity.formSs = formSSResponse.data;
    if (!viewEntity.formSs) {
      result.message = "无效的草稿单";
      return result;
    }
    const formResponse = await FormApi.get(params.formId);
    viewEntity.form = formResponse.data;
    viewEntity.formId = viewEntity.form.formId

    const settlementMaterials = await DataOptions.GetSettlementMaterials(Number(viewEntity.formSs.formSaId));
    viewEntity.settlementMaterials = settlementMaterials?.data;

    const formSsDetailsResponse = await FormSs.getFormSsDetailsForApproval(params.formId);
    viewEntity.formSsDetails = DataStore.init(formSsDetailsResponse.data);

  }

  const ecFieldConfigsResponse = await EC.getEcFieldConfigs();
  viewEntity.ecFieldConfigs = ecFieldConfigsResponse.data;

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
}
