import { formatter, request } from "@/utils/request";

export namespace Channel {
  export const Path = {
    Search: '/mdmapi/channel/search',
    AjaxSearch: '/mdmapi/channel/ajaxSearch',
    Get: '/mdmapi/channel/get', 
    Post: '/mdmapi/channel/post',
    Delete: '/mdmapi/channel/delete',
  };


  export async function search(body: API.ChannelFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Channel[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.ChannelFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Channel[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.Channel>>(Path.Get, formatter(options));
  } 
  export async function post(body: API.Channel) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }
}