import React, {useEffect, useState} from "react";
import {WdiPanel} from "@/components/WdiControls/WdiPanel";
import {WdiCardTitle, WdiModalAppResult, WdiModalProps} from "@/components/WdiControls";
import {Enum} from "@/enums/system";
import {Button, message} from "antd";
import {GridApi} from "@/services/gridapi";
import {NsGrid} from "@/components/GridWidget/GridDefProps";
import {DetailGrid, Utility} from "@/components/GridWidget";
import DataStore from "@/components/GridWidget/DataStore";
import PoolPrepaySelect from "@/pages/financial/PoolPrepayReverseApply/components/PoolPrepaySelect";
import {TableColumnRanderFormats} from "@/components/WdiBus/TableColumnRanderFormats";
import FormSpDetailSelect from "@/pages/financial/PoolPrepayReverseApply/components/FormSpDetailSelect";
import useWindowSize from "@/utils/useWindowSize";
import {ImportModalApp} from "@/components/WdiControls/WdiImport";
import {ResponseStatus} from "@/utils/request";

export type PositiveDetailForApplyProps = {
  dataSource: any[],
  data: {
    soldToMasterCode: string | undefined
    year: number | undefined,
    prepayType: number | undefined
  },
  onChange?: (dataSource: any) => void,
}
export type PositiveDetailForApprovalProps = {
  dataSource: any[] | undefined,
}
const ModalTypeEnums = {
  None: 0,
  Select: -1,
  Import: -2,
};
const PoolPrepayApplyPanel: React.FC<PositiveDetailForApplyProps> = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const {dataSource, data, onChange} = props;
  const [modalProps, setModalProps] = useState<WdiModalProps>({visible: false});
  const [importModalProps, setImportModalProps] = useState<WdiModalProps>({visible: false});
  const onImportClose = () => {
    setImportModalProps({visible: false, title: "", id: null});
  }
  const onImportSave = (uploadResult: any) => {
    if (uploadResult.code == ResponseStatus.SUCCESS) {
      const result = uploadResult?.data?.data;
      if (result && result.length > 0) {
        if (uploadResult?.data?.exProperty) {
          //是否清空
          onChange?.(DataStore.init(result));
        } else {
          if (result && result.length > 0) {

            onChange?.(DataStore.init(uploadResult?.data?.data));
          }
        }
      }
    }
    setImportModalProps({visible: false, title: "", id: null});
  }

  const onDetailSave = (result?: WdiModalAppResult) => {
    const rows = result?.data;
    let resultData = [...dataSource];
    if (rows && rows.length > 0) {
      if ((rows.length + dataSource.length) > 1000) {
        message.error('明细数量过多请分开申请');
        return;
      }
      rows.forEach((item: any) => {
        if (resultData.findIndex(row => row.poolPrepayId == item.poolPrepayId) < 0) {
          resultData.push(item);
        }
      });
      onChange?.(resultData);
    }
    setModalProps({visible: false, type: ModalTypeEnums.None});
  };
  const onDetailClose = () => {
    setModalProps({visible: false, title: '', type: ModalTypeEnums.None, id: null, data: null});
  };
  useEffect(() => {
    GridApi.searchConfig({id: 'PoolPrepayForApply', allowModifyColumns: false}).then((res) => {
      const settings: any = Object.assign({}, res.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,
        showExpandedRow: false,
      });
      settings.formSchema.allFields.prepayType.getDisplayFormater = (text: any, record: any, index: any) => {
        if (record.prepayType == 1) {
          return '合同预付';
        } else if (record.prepayType == 2) {
          return '活动预付';
        } else {
          return '';
        }
      }
      settings.formSchema.allFields.amountPrepay.aggr = TableColumnRanderFormats.sumAggr('amountPrepay', 2);
      settings.formSchema.allFields.poolPrepayId.width = 100;
      settings.formSchema.allFields.soldToCode.width = 150;
      settings.formSchema.allFields.soldToName.width = 150;
      settings.formSchema.allFields.ym.width = 150;
      settings.formSchema.allFields.prepayType.width = 150;
      settings.formSchema.allFields.amountPrepay.width = 150;
      setGridSettings(settings);
      setIsLoaded(true);
    })
  }, []);
  const pageGridSettings = Object.assign({
    antdProps: {
      dataSource,
      pagination: {
        defaultPageSize: 5,
        showSizeChanger: true
      }
    }
  }, gridSettings);

  if (pageGridSettings && pageGridSettings.formSchema && pageGridSettings.formSchema.actions) {
    Utility.setActionCallback(
      pageGridSettings.formSchema.actions,
      'SystemDelete',
      async (grid: any, action: any, record: any) => {
        if (confirm('确定要删除该行吗？')) {
          onChange?.(DataStore.del(dataSource, record.uid));
          message.success('删除成功');
        }
      },
    );
  }
  return (
    <>
      <WdiPanel
        title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='预付信息'/>}
        extra={
          <>
            <Button type='primary' onClick={() => {
              setImportModalProps({
                title: '预付信息导入',
                visible: true,
                data: {details: dataSource}
              });
            }}
                    style={{float: 'right', marginRight: 10}}>
              预付信息导入
            </Button>
            <Button type='primary' onClick={() => {
              setModalProps({
                type: ModalTypeEnums.Select,
                visible: true,
                data: {data},
                title: `选择待冲销的预付信息`,
              });
            }}
                    style={{float: 'right', marginRight: 10}}>
              选择预付信息
            </Button>
          </>
        }
      >
        {modalProps.visible && modalProps.type == ModalTypeEnums.Select &&
          <PoolPrepaySelect
            {...modalProps}
            onClose={onDetailClose}
            onSave={onDetailSave}
          />}
        {
          isLoaded &&
          importModalProps.visible &&
          <ImportModalApp
            title={importModalProps.title}
            visible={importModalProps.visible}
            settingsUrl="/activityapi/poolPrepayReverse/getPoolPrepayImportSettings"
            uploadingUrl="/activityapi/poolPrepayReverse/poolPrepayImportUploading"
            onClose={onImportClose}
            onSave={onImportSave}
            clientParameters={{
              details: JSON.stringify(dataSource)
            }}/>
        }
        {isLoaded &&
          <DetailGrid {...pageGridSettings} />}
      </WdiPanel>
    </>
  )
}
export default PoolPrepayApplyPanel;

export const FormSpDetailApplyPanel: React.FC<PositiveDetailForApplyProps> = (props) => {
  const {width} = useWindowSize();
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const {dataSource, data, onChange} = props;
  const [modalProps, setModalProps] = useState<WdiModalProps>({visible: false});
  const [importModalProps, setImportModalProps] = useState<WdiModalProps>({visible: false});
  const onImportClose = () => {
    setImportModalProps({visible: false, title: "", id: null});
  }
  const onImportSave = (uploadResult: any) => {
    if (uploadResult.code == ResponseStatus.SUCCESS) {
      const result = uploadResult?.data?.data;
      if (result && result.length > 0) {
        if (uploadResult?.data?.exProperty) {
          //是否清空
          onChange?.(DataStore.init(result));
        } else {
          if (result && result.length > 0) {
            onChange?.(DataStore.init(uploadResult?.data?.data));
          }
        }
      }
    }
    setImportModalProps({visible: false, title: "", id: null});
  }
  const onDetailSave = (result?: WdiModalAppResult) => {
    const rows = result?.data;
    let resultData = [...dataSource];
    if (rows && rows.length > 0) {
      if ((rows.length + dataSource.length) > 2000) {
        message.error('明细数量过多请分开申请');
        return;
      }
      rows.forEach((item: any) => {
        if (resultData.findIndex(row => row.formSpDetailId == item.formSpDetailId) < 0) {
          resultData.push(item);
        }
      });
      onChange?.(resultData);
    }
    setModalProps({visible: false, type: ModalTypeEnums.None});
  };
  const onDetailClose = () => {
    setModalProps({visible: false, title: '', type: ModalTypeEnums.None, id: null, data: null});
  };

  useEffect(() => {
    GridApi.searchConfig({id: 'FormSpDetilForPoolPrepayReverseApply', allowModifyColumns: false}).then((res) => {
      const settings: any = Object.assign({}, res.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,

      });
      settings.formSchema.allFields.spAmount.aggr = TableColumnRanderFormats.sumAggr('spAmount', 2);
      settings.formSchema.allFields.spAmount.width = 150;
      settings.formSchema.allFields.formSpDetailId.width = 150;
      settings.formSchema.allFields.formSpNo.width = 150;
      settings.formSchema.allFields.soldToCode.width = 150;
      settings.formSchema.allFields.soldToName.width = 150;
      settings.formSchema.allFields.ecName3.width = 150;
      settings.formSchema.allFields.activityStartDate.width = 150;
      settings.formSchema.allFields.activityEndDate.width = 150;
      settings.formSchema.allFields.custSysCode.width = 150;
      settings.formSchema.allFields.custSysName.width = 150;
      settings.formSchema.allFields.activityName.width = 150;
      settings.formSchema.allFields.productLineCode.width = 150;
      settings.formSchema.allFields.productLineName.width = 150;
      setGridSettings(settings);
      setIsLoaded(true);
    })
  }, []);

  const pageGridSettings = Object.assign({
    width: width - 300,
    antdProps: {
      dataSource,
      pagination: {
        defaultPageSize: 10,
        showSizeChanger: true
      }
    }
  }, gridSettings);

  if (pageGridSettings && pageGridSettings.formSchema && pageGridSettings.formSchema.actions) {
    Utility.setActionCallback(
      pageGridSettings.formSchema.actions,
      'SystemDelete',
      async (grid: any, action: any, record: any) => {
        if (confirm('确定要删除该行吗？')) {
          onChange?.(DataStore.del(dataSource, record.uid));
          message.success('删除成功');
        }
      },
    );
  }
  return (
    <>
      <WdiPanel
        title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='付款信息'/>}
        extra={
          <>
            <Button type='primary' onClick={() => {
              setImportModalProps({
                title: '付款信息导入',
                visible: true,
                data: {details: dataSource}
              });
            }}
                    style={{float: 'right', marginRight: 10}}>
              付款信息导入
            </Button>
            <Button type='primary' onClick={() => {
              setModalProps({
                type: ModalTypeEnums.Select,
                visible: true,
                data: {data},
                title: `选择付款单`,
              });
            }}
                    style={{float: 'right', marginRight: 10}}>
              选择付款信息
            </Button>
          </>
        }
      >
        {modalProps.visible && modalProps.type == ModalTypeEnums.Select &&
          <FormSpDetailSelect
            {...modalProps}
            onClose={onDetailClose}
            onSave={onDetailSave}
          />}
        {
          isLoaded &&
          importModalProps.visible &&
          <ImportModalApp
            title={importModalProps.title}
            visible={importModalProps.visible}
            settingsUrl="/activityapi/poolPrepayReverse/getFormSpDetailImportSettings"
            uploadingUrl="/activityapi/poolPrepayReverse/formSpDetailImportUploading"
            onClose={onImportClose}
            onSave={onImportSave}
            clientParameters={{
              details: JSON.stringify(dataSource)
            }}/>
        }
        {isLoaded &&
          <DetailGrid {...pageGridSettings} />}
      </WdiPanel>

    </>
  )
}
export const PoolPrepayApprovalPanel: React.FC<PositiveDetailForApprovalProps> = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const {dataSource} = props;

  useEffect(() => {
    GridApi.searchConfig({id: 'PoolPrepayForApply', allowModifyColumns: false}).then((res) => {
      const settings: any = Object.assign({}, res.data, {
        actionsAlign: NsGrid.ActionsAlign.None,
        showAggr: true,
        showExpandedRow: false,
      });
      settings.formSchema.allFields.prepayType.getDisplayFormater = (text: any, record: any, index: any) => {
        if (record.prepayType == 1) {
          return '合同预付';
        } else if (record.prepayType == 2) {
          return '活动预付';
        } else {
          return '';
        }
      }
      settings.formSchema.allFields.amountPrepay.aggr = TableColumnRanderFormats.sumAggr('amountPrepay', 2);
      settings.formSchema.allFields.poolPrepayId.width = 100;
      settings.formSchema.allFields.soldToCode.width = 150;
      settings.formSchema.allFields.soldToName.width = 150;
      settings.formSchema.allFields.ym.width = 150;
      settings.formSchema.allFields.prepayType.width = 150;
      settings.formSchema.allFields.amountPrepay.width = 150;
      setGridSettings(settings);
      setIsLoaded(true);
    })
  }, []);

  const pageGridSettings = Object.assign({
    antdProps: {
      dataSource,
      pagination: {
        defaultPageSize: 5,
        showSizeChanger: true
      }
    }
  }, gridSettings);
  return (
    <>
      <WdiPanel collapsed={true}
                title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='预付信息'/>}>

        {isLoaded &&
          <DetailGrid {...pageGridSettings} />}
      </WdiPanel>
    </>
  )
}
export const FormSpDetailApprovalPanel: React.FC<PositiveDetailForApprovalProps> = (props) => {
  const {width} = useWindowSize();
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const {dataSource} = props;
  useEffect(() => {
    GridApi.searchConfig({id: 'FormSpDetilForPoolPrepayReverseApply', allowModifyColumns: false}).then((res) => {
      const settings: any = Object.assign({}, res.data, {
        actionsAlign: NsGrid.ActionsAlign.None,
        showAggr: true,

      });
      settings.formSchema.allFields.spAmount.aggr = TableColumnRanderFormats.sumAggr('spAmount', 2);
      settings.formSchema.allFields.spAmount.width = 150;
      settings.formSchema.allFields.formSpDetailId.width = 150;
      settings.formSchema.allFields.formSpNo.width = 150;
      settings.formSchema.allFields.soldToCode.width = 150;
      settings.formSchema.allFields.soldToName.width = 150;
      settings.formSchema.allFields.ecName3.width = 150;
      settings.formSchema.allFields.activityStartDate.width = 150;
      settings.formSchema.allFields.activityEndDate.width = 150;
      settings.formSchema.allFields.custSysCode.width = 150;
      settings.formSchema.allFields.custSysName.width = 150;
      settings.formSchema.allFields.activityName.width = 150;
      settings.formSchema.allFields.productLineCode.width = 150;
      settings.formSchema.allFields.productLineName.width = 150;
      setGridSettings(settings);
      setIsLoaded(true);
    })
  }, []);
  const pageGridSettings = Object.assign({
    width: width - 300,
    antdProps: {
      dataSource,
      pagination: {
        defaultPageSize: 10,
        showSizeChanger: true
      }
    }
  }, gridSettings);
  return (
    <>
      <WdiPanel collapsed={true}
                title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='付款信息'/>}>
        {isLoaded &&
          <DetailGrid {...pageGridSettings} />}
      </WdiPanel>

    </>
  )
}
