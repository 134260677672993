import { request, formatter } from '@/utils/request';

export namespace OptType {
  export const Path = {
    Search: '/basicapi/optType/search',
    Get: '/basicapi/optType/get',
  }

  export async function search(id?: number) {
    const options = {
      method: 'POST',
      body: {
      },
    };
    return request<API.ResponseResult<API.OptType[]>>(Path.Search, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.OptType>>(Path.Get, formatter(options));
  }
}

export namespace OptLog {
  export const Path = {
    Get: '/basicapi/optLog/get',
    Query: '/basicapi/optLog/query',
    Export: '/basicapi/optLog/export',
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.Export, formatter(options));
  }



  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.OptLog>>(Path.Get, formatter(options));
  }
}

export namespace LoginActionLog {
  export const Path = {
    Query: '/basicapi/loginActionLog/query',
    Export: '/basicapi/loginActionLog/export'
  }
  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.Export, formatter(options));
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }
}

export namespace FormLog {
  export const Path = {
    QueryByFormNo: '/basicapi/formLog/queryByFormNo',
  }

  export async function queryByFormNo(formNo: string) {
    const options = {
      method: 'GET',
      body: {
        formNo: formNo
      },
    };
    return request<API.ResponseResult<any[]>>(Path.QueryByFormNo, formatter(options));
  }
}
