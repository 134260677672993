import React, { useEffect, useState } from 'react';
import { WdiTable } from '@/components/WdiControls/WdiTable';
import ProCard from '@ant-design/pro-card';
import { WdiModalProps, WdiModalAppResult, WdiCardTitle, wdiCore } from '@/components/WdiControls';
import { Space, Typography } from 'antd';
import { FormSa } from '@/services/sales/sa';
import { ResponseStatus } from '@/utils/request';
import SalesQueryApp from './SalesQuery';
import { Enum } from '@/enums/system';

type SalesAndCostProps = {
  formSaId?: number;
  pageTypeId?: number;
}
/**销量与费用 */
const SalesAndCost: React.FC<SalesAndCostProps> = (props) => {

  const [title, setTitle] = useState("销量与费用统计信息");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sellinDataSource, setSellinDataSource] = useState<any[]>([]);
  const [detailSellinDataSource, setDetailSellinDataSource] = useState<any[]>([]);
  const [queryModalProps, setQueryModalProps] = useState<WdiModalProps>({ visible: false });

  const onQueryClose = () => {
    setQueryModalProps({ visible: false, title: "", id: null });
  };
  const onQuerySave = (result?: WdiModalAppResult) => {
    setStartDate(result?.data.startDate);
    setEndDate(result?.data.endDate);
    setTitle("销量与费用统计信息(" + result?.data.startDate + " ~ " + result?.data.endDate + ")");

    loadData(result?.data.startDate, result?.data.endDate);
    setDetailSellinDataSource([]);

    setQueryModalProps({ visible: false, title: "", id: null });
  };

  const salesColumns = [
    {
      title: '目标销售额',
      dataIndex: 'revenueForecast',
      ellipsis: true,
    },
    {
      title: '实际销售额',
      dataIndex: 'revenueActual',
      ellipsis: true,
    },
    {
      title: '达成率',
      dataIndex: 'completionRate',
      ellipsis: true,
      render: (value: any, record: any) => {
        if (record.revenueForecast && record.revenueActual) {
          return wdiCore.Number.RateToString(record.revenueActual / record.revenueForecast);
        }
        return "";
      }
    },
    {
      title: '已用费用',
      dataIndex: 'amountTotal',
      ellipsis: true,
      render: (value: any, record: any) => {
        return value;
      }
    },
    {
      title: '实际费比',
      dataIndex: 'actualRoi',
      ellipsis: true,
      render: (value: any, record: any) => {
        return value;
      }
    },
    {
      title: '去年同期目标销售额',
      dataIndex: 'lastYearRevenueForecast',
      ellipsis: true,
      render: (value: any, record: any) => {
        return value;
      }
    },
    {
      title: '去年同期实际销售额',
      dataIndex: 'lastYearRevenueActual',
      ellipsis: true,
      render: (value: any, record: any) => {
        return value;
      }
    },
    {
      title: '去年同期费用',
      dataIndex: 'lastYearAmountTotal',
      ellipsis: true,
      render: (value: any, record: any) => {
        return value;
      }
    },
    {
      title: '去年同期费比',
      dataIndex: 'lastYearActualRoi',
      ellipsis: true,
      render: (value: any, record: any) => {
        return value;
      }
    }
  ];
  const columns = [
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      width: 200,
      ellipsis: true,
      render: (value: any, record: any) => {
        return (
          <a onClick={() => { loadDetailData(record.soldToCode) }} data-key={record.soldToCode}>
            {wdiCore.String.CodeOrName(record.soldToCode, record.soldToName)}
          </a>
        );
      }
    },
    ...salesColumns
  ];

  const detailColumns = [
    {
      title: '年月',
      dataIndex: 'ym',
      width: 200,
      ellipsis: true,
      render: (value: any, record: any) => {
        return value;
      }
    },
    ...salesColumns
  ];

  const loadData = (begin: string, end: string) => {
    FormSa.getSellInAndTargetInfos({ formSaId: props.formSaId ?? 0, startDate: begin, endDate: end }).then((responseResult) => {
      if (ResponseStatus.SUCCESS === responseResult.code) {
        setSellinDataSource(responseResult.data);
      }
    })
  }

  const loadDetailData = (soldToCode: string) => {
    FormSa.getSellInAndTargetDetailInfos({ soldToCode: soldToCode, fpStart: Number(startDate), fpEnd: Number(endDate) }).then((responseResult) => {
      if (ResponseStatus.SUCCESS === responseResult.code) {
        setDetailSellinDataSource(responseResult.data);
      }
    })
  }

  const onSearch = (e: any) => {
    setQueryModalProps({ visible: true, title: '查询条件', id: null, data: { startDate: startDate, endDate: endDate } });
  };

  useEffect(() => {
    if (props.formSaId) {
      const date = new Date();
      const year = date.getFullYear() * 100;
      const begin = (year + 1).toString();
      const end = (year + date.getMonth() + 1).toString();

      setStartDate(begin);
      setEndDate(end);
      setTitle("销量与费用统计信息(" + begin + " ~ " + end + ")");

      loadData(begin, end);
    }
  }, [props.formSaId]);


  return (
    <>
      {
        queryModalProps.visible &&
        <SalesQueryApp
          {...queryModalProps}
          onClose={onQueryClose}
          onSave={onQuerySave}
        />
      }
      <ProCard
        type="inner"
        headStyle={{ height: 40, backgroundColor: 'white', padding: '0' }}
        bodyStyle={{ padding: '0px' }}
        title={<WdiCardTitle iconType={Enum.IconType.DETAIL_SALES_INFO} title={title} />}
        extra={
          <Space>
            <Typography.Link onClick={onSearch} >更改查询条件</Typography.Link>
          </Space>}
      >
        <WdiTable columns={columns} dataSource={sellinDataSource} pagination={false} />
      </ProCard>
      <ProCard
        type="inner"
        headStyle={{ height: 40, backgroundColor: 'white', padding: '0' }}
        bodyStyle={{ padding: '0px' }}
        title={<WdiCardTitle iconType={Enum.IconType.DETAIL_SALES_INFO} title={"销量与费用明细信息"} />}
      >
        <WdiTable columns={detailColumns} dataSource={detailSellinDataSource} pagination={false} />
      </ProCard>
    </>
  );
};

export default SalesAndCost;
