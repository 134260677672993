import type {WdiModalAppProps} from '@/components/WdiControls';
import {WdiModalForm} from '@/components/WdiControls/WdiModalForm';
import {WdiSelect} from '@/components/WdiControls/WdiSelect';
import {Enum} from '@/enums/system';
import {DataOptions} from '@/services/basic/metadata';
import {ResponseStatus} from '@/utils/request';
import type {ProFormInstance} from '@ant-design/pro-form';
import {Form} from 'antd';
import {useEffect, useRef, useState} from 'react';

/**
 * 合同条款处理方式弹窗
 */
const SalesContractTermsPrevApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [processModes, setProcessModes] = useState<any[]>([]);

  useEffect(() => {
    let inValues = [Enum.ProcessMode.AUTO_ACCRUAL_AND_SETTLE, Enum.ProcessMode.NONE_AUTO_ACCRUAL_AND_SETTLE, Enum.ProcessMode.NON_TPM_IMPORT].join();
    if (props.data) {
      if (props.data.contractType == Enum.SalesContractType.SELLOUT) {
        inValues = [Enum.ProcessMode.AUTO_ACCRUAL_AND_SETTLE, Enum.ProcessMode.NONE_AUTO_ACCRUAL_AND_SETTLE].join();
      }
    }
    DataOptions.search(Object.assign({typeGroup: 13, inValues: inValues})).then((response) => {
      const data = response.data.map((item) => ({value: item.value, label: item.name}));
      if (data.length == 1) {
        formRef.current?.setFieldsValue({
          processMode: data[0].value,
        });
      }
      setProcessModes(data);
    });
  }, [props.data]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{span: 6}}
      wrapperCol={{span: 12}}
      labelAlign="left"
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      preserve={true}
      modalProps={{
        onCancel: () => {
          props.onClose ? props.onClose() : null;
        },
        destroyOnClose: true,
        maskClosable: false,
        okText: '下一步',
      }}
      onFinish={async (values) => {
        const {processMode} = values;
        const row = Object.assign({}, props.data?.row, {processMode});
        const data = Object.assign({}, props.data, {row});
        const response: API.ResponseResult<any> = {
          code: ResponseStatus.SUCCESS,
          message: '',
          data: data,
        };
        if (props.onSave) {
          props.onSave(response);
        }
        return true;
      }}
    >
      <Form.Item
        name="processMode"
        label="处理方式"
        rules={[{required: true, message: '处理方式不能为空!'}]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择处理方式',
            showSearch: true,
            options: processModes,
          }}
        />
      </Form.Item>
    </WdiModalForm>
  );
};

export default SalesContractTermsPrevApp;
