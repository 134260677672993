import { useEffect, useRef } from 'react';
import { WdiSelect, WdiSelectFormat } from '@/components/WdiControls/WdiSelect';
import { Form, Input, message } from 'antd';
import { ResponseStatus } from '@/utils/request';
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { ProFormInstance } from '@ant-design/pro-form';
import { WdiModalAppProps, wdiCore } from '@/components/WdiControls';
import { HorzLayoutOpts, WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { FormSa } from '@/services/sales/sa';
import { ShipTo } from '@/services/mdm/shipto';
import { getDetailPrevData } from '../../components/data';

const { TextArea } = Input;

const PolicyPdGiveawayDetailApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const { formSaDetail, formSa, rowIndex, rows } = props.data;

  const handleCalcRevenueSellin = () => {
    const fields = formRef.current?.getFieldsValue();
    formRef.current?.setFieldsValue({ revenueSellinForecast: null });
    if (fields.quantityBomForecast) {
      formRef.current?.setFieldsValue({
        revenueSellinForecast: wdiCore.Number.ToNumber(formSaDetail.policyPdBuyPrice * fields.quantityBomForecast, 2)
      });
    }
  };
  const handleCalcAmount = () => {
    const fields = formRef.current?.getFieldsValue();
    formRef.current?.setFieldsValue({ amount: null });
    if (fields.quantityBomForecast) {
      formRef.current?.setFieldsValue({
        amount: wdiCore.Number.ToNumber(formSaDetail.policyPdFreePrice * fields.quantityBomForecast, 2)
      });
    }
  };

  useEffect(() => {
    if (formSaDetail) {
      formRef.current?.setFieldsValue({
        policyName: wdiCore.String.Join(formSaDetail.policyCode, formSaDetail.policyName),
        revenueTypeName: formSaDetail.revenueTypeName,
        policyDesc: formSaDetail.policyDesc,
        boName: formSaDetail.boName,
        ecName: formSaDetail.ecName,
        soldToName: wdiCore.String.Join(formSaDetail.soldToCode, formSaDetail.soldToName),
        quantityBomForecast: formSaDetail.quantityBomForecast,
        revenueSellinForecast: formSaDetail.revenueSellinForecast,
        amount: formSaDetail.amount,
        remark: formSaDetail.remark,
      });
      if (formSaDetail.shipToCodes) {
        ShipTo.search({ shipToCodes: formSaDetail.shipToCodes }).then(response => {
          if (response.data) {
            formRef.current?.setFieldsValue({ shipToCodes: response.data.map(item => ({ value: item.shipToCode, label: item.codeOrName })) });
          }
        });
      }
    }
  }, [formSaDetail]);

  return (
    <WdiModalForm
      {...HorzLayoutOpts}
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      modalProps={{ onCancel: () => { props.onClose?.() }, }}
      onFinish={async (values) => {
        const { shipToCodes, ...entity } = values;
        if (!values.amount || values.amount <= 0) {
          message.error("申请金额必须大于等于0!");
          return;
        }
        entity.shipToCodes = WdiSelectFormat.getvalue(shipToCodes);
        const response = await FormSa.Buttons.validateDetail({
          rowIndex: rowIndex,
          formSa: { ...formSa },
          formSaDetail: Object.assign({}, getDetailPrevData(formSaDetail), entity),
          formSaDetails: rows
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (props.onSave) {
          const data = Object.assign({}, { rowIndex: rowIndex }, response.data);
          response.data = data;
          props.onSave(response);
        }
        return;
      }}
    >
      <Form.Item name="policyName" label="产品政策" rules={[{ required: true, message: '产品政策不能为空!' }]}>
        <Input readOnly />
      </Form.Item>
      <Form.Item name="soldToName" label="售达方">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="shipToCodes" label="送达方">
        <WdiSelect {...{
          placeholder: "请选择送达方",
          remote: true,
          showSearch: true,
          mode: 'multiple',
          dataLoading: async (params) => {
            const responseResult = await ShipTo.search(Object.assign({ soldToCode: formSaDetail.soldToCode, isActive: true }, params));
            return responseResult.data.map((item) => { return { value: item.shipToCode, label: item.codeOrName }; });
          }
        }} />
      </Form.Item>
      <Form.Item name="policyDesc" label="政策规则">
        <TextArea rows={2} readOnly />
      </Form.Item>
      <Form.Item name="quantityBomForecast" label="预计购买套数" rules={[{ required: true, message: '预计购买套数不能为空!' }]}>
        <WdiInputNumber min={0} digits={0} onChange={() => {
          handleCalcRevenueSellin();
          handleCalcAmount();
        }} />
      </Form.Item>
      <Form.Item name="revenueSellinForecast" label="预计销售额" rules={[{ required: true, message: '预计销售额不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="amount" label="申请金额" rules={[{ required: true, message: '申请金额不能为空!' }]}>
        <WdiInputNumber readOnly />
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <Input maxLength={2000}/>
      </Form.Item>
      <Form.Item name="ecName" label="费用项">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="revenueTypeName" label="核算类型">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="boName" label="四级预算中心">
        <Input readOnly />
      </Form.Item>
    </WdiModalForm>
  );
};

export default PolicyPdGiveawayDetailApp;
