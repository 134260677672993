import DataStore from "@/components/GridWidget/DataStore";
import { getParameter } from "@/components/WdiBus/Buttons/data";
import { WdiModalAppProps, wdiCore } from "@/components/WdiControls";
import { WdiInputNumber } from "@/components/WdiControls/WdiInputNumber";
import { WdiModalForm } from "@/components/WdiControls/WdiModalForm";
import { WdiTable } from "@/components/WdiControls/WdiTable";
import { FormSaDetail } from "@/services/sales/sa";
import { formatter, request, ResponseStatus } from "@/utils/request";
import { ProFormInstance } from "@ant-design/pro-form";
import { message, TableColumnsType, } from "antd";
import { useEffect, useRef, useState } from "react";


const ModifyAmountModalApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const columns: TableColumnsType<API.FormSaDetailVo> = [
    {
      title: '行号',
      width: 50,
      dataIndex: 'rowNumber',
    },
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (<span>{wdiCore.String.Join(record.soldToCode, record.soldToName)}</span>),
    },
    {
      title: '客户集团',
      dataIndex: 'custSysCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (<span>{wdiCore.String.Join(record.custSysCode, record.custSysName)}</span>),
    },
    {
      title: '门店',
      dataIndex: 'shopId',
      ellipsis: true,
      width: 240,
      render: (_, record) => (<span>{wdiCore.String.Join(record.shopCode, record.shopName)}</span>),
    },
    {
      title: '产品别',
      dataIndex: 'productBuyNames',
      ellipsis: true,
      width: 240,
    },
    {
      title: '费用项',
      dataIndex: 'ecName',
      width: 150,
      ellipsis: true,
    },
    {
      title: '核算类型',
      dataIndex: 'revenueTypeName',
      ellipsis: true,
      width: 150,
    },
    {
      title: '产品政策',
      dataIndex: 'productPolicyId',
      ellipsis: true,
      width: 240,
      render: (_, record) => (<span>{wdiCore.String.Join(record.policyCode, record.policyName)}</span>),
    },
    {
      title: '原申请金额',
      dataIndex: 'amountOriginal',
      width: 100,
      ellipsis: true,
      render: (value) => (<span>{wdiCore.Number.AmountToString(value)}</span>),
    },
    {
      title: '新申请金额',
      dataIndex: 'amount',
      width: 100,
      ellipsis: true,
      render: (value: any, record) => (
        <WdiInputNumber value={value} min={0} style={{ width: '100%' }} digits={2}
          onChange={(value) => {
            const { ...newRecord } = record;
            newRecord.amount = Number(value);
            setDataSource(DataStore.edit(dataSource, newRecord));
          }} />
      ),
    },
  ]

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      FormSaDetail.getsEx(Number(props.id)).then((response) => {
        if (response && response.data) {
          const formSaDetails = response.data;
          for (let i = 0; i < formSaDetails.length; i++) {
            const formSaDetail = formSaDetails[i];
            formSaDetail.amountOriginal = formSaDetail.amount;
          }
          setDataSource(DataStore.init(formSaDetails));
          setLoading(false);
        }
      });
    }
  }, [props.id]);

  return (
    <WdiModalForm
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      width={1350}
      modalProps={{
        onCancel: () => { props.onClose ? props.onClose() : null },
      }}
      onFinish={async () => {
        const { data } = props;
        const args = getParameter(data);
        args.items.push({ name: "formSaDetails", value: JSON.stringify(dataSource) });
        request(data.url, formatter({ method: 'POST', body: args })).then((response) => {
          if (response.code == ResponseStatus.SUCCESS) {
            message.success('操作成功');
            if (props.onSave) {
              props.onSave();
            }
          } else {
            message.error(response.message);
          }
        });
      }}
    >
      <WdiTable
        height={507}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        aggrColumns={["amountOriginal", "amount"]}
        footerSummaryFixed={true}
      />
    </WdiModalForm>
  );
};

export default ModifyAmountModalApp;