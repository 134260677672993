import { useEffect, useState } from 'react';
import { Badge, Tabs, List, Avatar, Skeleton, Divider, Button, message } from 'antd';
import { Message } from '@/services/basic/message';
import { wdiCore } from '../GridWidget';
import HeaderDropdown from '../HeaderDropdown';
import { BellOutlined, UserOutlined } from '@ant-design/icons';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import { FormApi } from '@/services/sales';
import { getFormUrlMapping } from '../WdiBus/FormPage';
import useWindowSize from '@/utils/useWindowSize';
import moment from 'moment';
import { ResponseStatus } from '@/utils/request';

const NoticeList = (props: any) => {
  const { height } = useWindowSize();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [pagenum, setPagenum] = useState<number>(0);
  const [hasMore, setHasMore] = useState(true);
  const before = moment().format('YYYY-MM-DD HH:mm:ss');

  const fetchData = (initPagenum: number) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const filterOptions = { messageType: props.messageType, isRead: props.isRead, before: before };
    const req: API.QueryForPageReq = {
      filterOptions: JSON.stringify(filterOptions),
      pagenum: initPagenum,
      pagesize: props.isRead ? 10 : 99999,
    };
    Message.query(req).then(response => {
      const newDataSource = (response?.data?.records || []).map(item => (
        {
          title: (item.senderUserName ? item.senderUserName + "：" : "") + item.content,
          description: wdiCore.Date.MinuteToString(item.createDate),
          record: item
        }));
      if (initPagenum == 0) {
        setDataSource(newDataSource);
      } else {
        setDataSource([...dataSource, ...newDataSource]);
      }
      setLoading(false);
      setPagenum(initPagenum);
      setHasMore(response?.data?.hasMore == true);
    })
  }
  const loadMore =
    !loading && hasMore ? (
      <div
        style={{
          textAlign: 'center',
          margin: '12px 0',
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={() => { fetchData(pagenum + 1); }}>加载更多</Button>
      </div>
    ) : <Divider plain>没有更多了</Divider>;
  useEffect(() => {
    fetchData(0);
  }, [props.messageType, props.isRead, props.reloadKey])

  return (
    <>
      <div id="scrollableDiv" style={{ maxHeight: height - 200, overflowY: 'auto', padding: '0 20px' }}>
        <List
          itemLayout="horizontal"
          dataSource={dataSource}
          loadMore={loadMore}
          renderItem={(item: any) => (
            <List.Item
              actions={[<a onClick={() => {
                FormApi.get(item?.record.objectId).then(formResponse => {
                  const form = formResponse?.data;
                  if (form) {
                    FormApi.doesAccess(form.formId).then(response => {
                      if (response.code == ResponseStatus.SUCCESS && response.data) {
                        window.open(getFormUrlMapping(form.pageTypeId, form.formTypeId) + `?formId=${form.formId}&pageTypeId=${form.pageTypeId}&formTypeId=${form.formTypeId}`);
                      } else {
                        message.error("没有权限查看单据");
                      }
                    })
                  }
                })
                if (item?.record && !item?.record.isRead) {
                  Message.postToRead(item?.record.messageId, undefined).then(response => {
                    props?.updateCount?.();
                    fetchData(0);
                  })
                }
              }}>去操作</a>]}
            >
              <Skeleton avatar title={false} loading={loading} active>
                <List.Item.Meta
                  avatar={<Avatar shape="square" size="large" icon={<UserOutlined />} />}
                  title={<span>{item.title}</span>}
                  description={item.description}
                />
              </Skeleton>
            </List.Item>
          )} />
      </div>
    </>
  );
}
const NoticeTabs = (props: any) => {
  const [activeKey, setActiveKey] = useState<string>('1');
  const [activeKey1, setActiveKey1] = useState<string>('1-1');
  const [activeKey2, setActiveKey2] = useState<string>('2-1');
  const [messageType, setMessageType] = useState<number>(1);
  const [isRead, setIsRead] = useState<boolean>(false);
  const [reloadKey, setReloadKey] = useState<number>(0);

  const onTabChange = (activeKey: string) => {
    setActiveKey(activeKey);
    let messageType = 1, isRead = false;
    if (activeKey == "2") {
      messageType = 0;
      if (activeKey2 == '2-2') {
        isRead = true;
      }
    } else {
      if (activeKey1 == '1-2') {
        isRead = true;
      }
    }
    setMessageType(messageType);
    setIsRead(isRead);
  }
  const onTabChange1 = (activeKey: string) => {
    setActiveKey1(activeKey);
    setIsRead(activeKey == '1-2');
  }
  const onTabChange2 = (activeKey: string) => {
    setActiveKey2(activeKey);
    setIsRead(activeKey == '2-2');
  }
  return (
    <Tabs onChange={onTabChange} activeKey={activeKey} tabBarStyle={{ padding: '0 10px' }} style={{ marginTop: 12 }}>
      <TabPane tab={<Badge
        count={(props?.count?.alertMeCount || 0)}
        overflowCount={99}
        size={'small'}
        style={{ boxShadow: 'none' }}
      >系统消息</Badge>} key="1" >
        <Tabs
          onChange={onTabChange1}
          activeKey={activeKey1}
          type='card'
          size='small'
          tabBarStyle={{ padding: '0 10px' }}
          tabBarExtraContent={{
            right: <a onClick={() => {
              Message.postToRead(undefined, 1).then(response => {
                props?.updateCount?.();
                setReloadKey(reloadKey + 1);
              })
            }}>全部设为已读</a>
          }}
        >
          <TabPane tab="当前消息" key="1-1" >
            <NoticeList updateCount={props.updateCount} messageType={messageType} isRead={isRead} reloadKey={reloadKey} />
          </TabPane>
          <TabPane tab="历史消息" key="1-2" >
            <NoticeList messageType={messageType} isRead={isRead} />
          </TabPane>
        </Tabs>
      </TabPane>
      <TabPane tab={<Badge
        count={(props?.count?.callMeCount || 0)}
        overflowCount={99}
        size={'small'}
        style={{ boxShadow: 'none' }}
      >@我的</Badge>} key="2" >
        <Tabs
          onChange={onTabChange2}
          activeKey={activeKey2}
          type='card' size='small'
          tabBarStyle={{ padding: '0 10px' }}
          tabBarExtraContent={{
            right: <a onClick={() => {
              Message.postToRead(undefined, 0).then(response => {
                props?.updateCount?.();
                setReloadKey(reloadKey + 1);
              })
            }}>全部设为已读</a>
          }}
        >
          <TabPane tab="当前消息" key="2-1" >
            <NoticeList updateCount={props.updateCount} messageType={messageType} isRead={isRead} reloadKey={reloadKey} />
          </TabPane>
          <TabPane tab="历史消息" key="2-2" >
            <NoticeList messageType={messageType} isRead={isRead} />
          </TabPane>
        </Tabs>
      </TabPane>
    </Tabs>
  );
};

export type NoticeProps = {
};
export const Notice: React.FC<NoticeProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [messageCount, setMessageCount] = useState<API.MessageCount>();
  const updateCount = () => {
    Message.countForMy().then(response => {
      setMessageCount(response.data);
    })
  }
  useEffect(() => {
    updateCount();
  }, [])

  return (
    <HeaderDropdown
      placement="bottomRight"
      overlay={<NoticeTabs count={messageCount} updateCount={updateCount} />}
      overlayStyle={{ width: 450 }}
      trigger={['click']}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <span style={{ cursor: 'pointer' }}>
        <Badge
          count={(messageCount?.alertMeCount || 0) + (messageCount?.callMeCount || 0)}
          overflowCount={99}
          size={'small'}
          style={{ boxShadow: 'none' }}
        >
          <BellOutlined />
        </Badge>
      </span>
    </HeaderDropdown>
  );
};

export default Notice;
