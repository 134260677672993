import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Form, Input, message, Radio, Row} from 'antd';
import {ResponseStatus} from '@/utils/request';
import {wdiCore, WdiModalAppProps, WdiModalProps} from '../WdiControls';
import type {FilterOption, TransferItem} from './GridViewColumnTranser';
import SortableTransfter from './GridViewColumnTranser';
import {getFilterState} from './GridWidgetFilter';
import {WdiModal} from '../WdiControls/WdiModalForm';
import {ViewApi} from '@/services/gridapi';
import {NsGrid} from './GridDefProps';
import {BiMetaTransfer} from "@/components/BiWidget/BiViewSettings";

const formItemExLayout = {
  labelCol: {span: 7},
  wrapperCol: {span: 16},
};

const toItem = (item: any) => {
  return {
    key: item.name,
    title: item.displayName,
    isRequired: item.isRequired,
  };
};

/// SortableTransfterProps
const getTransterItemValue = (dataSource: TransferItem[], columns: string[] | null | undefined) => {
  const value: string[] = [];
  /// 数据展示列转化, 选择列
  if (columns && columns.length) {
    columns.forEach(itemKey => {
      const result = dataSource.find((item) => item.key == itemKey);
      if (result) {
        value.push(result.key);
      }
    });
    dataSource.forEach(item => {
      if (item.isRequired && columns.findIndex(itemKey => item.key == itemKey) < 0) {
        value.push(item.key);
      }
    })
  } else {
    //从Schema定义获取字段进行展示
    dataSource.forEach((item) => {
      value.push(item.key);
    });
  }
  return value;
};

/// 数据展示列定义
const getViewColumnTransferItem = (formObject: any) => {
  const dataSource: TransferItem[] = [];
  /// 处理数据展示列
  if (formObject.allFields) {
    formObject.allFieldNames.forEach((fieldName: any) => {
      const field = formObject.allFields[fieldName];
      if (!field.isHidden && !field.isDisabled) {
        dataSource.push(toItem(field));
      }
    });
  }
  return dataSource;
};

/// 数据过滤列定义
const getFilterColumnTransferItem = (formObject: any) => {
  const dataSource: TransferItem[] = [];
  /// 处理数据过滤列
  if (formObject.allFields) {
    formObject.allFieldNames.forEach((fieldName: any) => {
      const field = formObject.allFields[fieldName];
      if (field.isFilterable && !field.isDisabled) {
        dataSource.push(toItem(field));
      }
    });
  }
  return dataSource;
};

const getDimensionTransferItem = (formObject: any) => {
  const dataSource: TransferItem[] = [];
  /// 处理数据过滤列
  if (formObject.allFields) {
    formObject.allFieldNames.forEach((fieldName: any) => {
      const field = formObject.allFields[fieldName];
      if (!field.isDisabled
        && !field.isHidden
        && field.columnType == NsGrid.BiColumnType.Dimension) {
        dataSource.push(toItem(field));
      }
    });
  }
  return dataSource;
};

const getIndicatorTransferItem = (formObject: any) => {
  const dataSource: TransferItem[] = [];
  /// 处理数据过滤列
  if (formObject.allFields) {
    formObject.allFieldNames.forEach((fieldName: any) => {
      const field = formObject.allFields[fieldName];
      if (!field.isDisabled
        && !field.isHidden
        && field.columnType == NsGrid.BiColumnType.Indicator) {
        dataSource.push(toItem(field));
      }
    });
  }
  return dataSource;
};

export type MetaValueProps = {
  headers: string[],
  rows: string[],
  columns: string[],
  values?: string[]
};

const getMetaValue = (dataSource: TransferItem[], meta: API.BiMeta) => {
  const value: MetaValueProps = {
    headers: [],
    rows: [],
    columns: []
  };
  /// 数据展示列转化, 选择列
  if (meta && meta.headers && meta.headers.length) {
    meta.headers.forEach(itemKey => {
      const result = dataSource.find((item) => item.key == itemKey);
      if (result) {
        value.headers.push(result.key);
      }
    });
  }

  if (meta && meta.rows && meta.rows.length) {
    meta.rows.forEach(itemKey => {
      const result = dataSource.find((item) => item.key == itemKey);
      if (result) {
        value.rows.push(result.key);
      }
    });
  }

  if (meta && meta.columns && meta.columns.length) {
    meta.columns.forEach(itemKey => {
      const result = dataSource.find((item) => item.key == itemKey);
      if (result) {
        value.columns.push(result.key);
      }
    });
  }
  return value;
};

// 根据当前选择过滤字段重新生成和计算过滤值
const findFilterOptions = (filterOptions: any, targetKeys: string[] | null | undefined) => {
  const items: FilterOption[] = [];
  if (filterOptions && filterOptions.length) {
    filterOptions.forEach((item: FilterOption) => {
      if (targetKeys && targetKeys.length && targetKeys.indexOf(item.field) >= 0) {
        items.push(item)
      }
    });
  }
  return items;
};

/// 过滤条件设置
const initFilterOptionState = (viewObject: any, targetKeys: string[] | null | undefined) => {
  var filterOptions = [];
  if (viewObject && viewObject.filterOptions) {
    if (typeof (viewObject.filterOptions) === 'string') {
      filterOptions = JSON.parse(viewObject.filterOptions);
    } else {
      filterOptions = viewObject.filterOptions;
    }
  }
  return findFilterOptions(filterOptions, targetKeys);
};

const ValivateType = {
  FLITER: "过滤条件",
  COLUMN: "查询结果"
};

// 查询结果/过滤条件是否存在必填的字段
const validateRequired = (valivateType: string, dataSource: TransferItem[], columns: string[]) => {
  if (dataSource && dataSource.length > 0) {
    const inValidColumn = dataSource.find(column => column.isRequired && columns.findIndex((item: any) => item == column.key) < 0);
    if (inValidColumn) {
      message.error(valivateType + "中【" + inValidColumn.title + "】不能被隐藏");
      return false;
    }
  }
  return true;
};

type FieldSettingAppProps = {
  fieldState: any;
  onSave: (key: string, value: any) => void;
  onCancel: () => void;
} & WdiModalProps;

const FieldSettingApp: React.FC<FieldSettingAppProps> = (props) => {
  const handleSave = () => {
    var value = null;
    var key = '';
    if (props.fieldState) {
      key = props.fieldState.code;
      value = props.fieldState.getValue();
    }
    props.onSave(key, value);
  };

  return (
    <WdiModal
      title={props.title}
      visible={props.visible}
      onOk={handleSave}
      onCancel={() => {
        props.onCancel()
      }}
    >
      {props.fieldState && props.fieldState.render()}
    </WdiModal>
  );
}

type ViewSettingAppProps = {
  formSchema: any;
  grid: any;
  viewObject: any;
} & WdiModalAppProps;

type FilterOptionState = {
  items: FilterOption[];
  fieldState: any;
  visible: boolean;
};

export const ViewSettingApp: React.FC<ViewSettingAppProps> = (props) => {
  const [form] = Form.useForm();

  const [displayType, setDisplayType] = useState<any>();
  const [dimensionColumnDataSource, setDimensionColumnDataSource] = useState<TransferItem[]>([]);
  const [indicatorColumnDataSource, setIndicatorColumnDataSource] = useState<TransferItem[]>([]);
  const [viewColumnDataSource, setViewColumnDataSource] = useState<TransferItem[]>([]);
  const [filterColumnDataSource, setFilterColumnDataSource] = useState<TransferItem[]>([]);
  const [filterOptionState, setFilterOptionState] = useState<FilterOptionState>({
    items: [],
    fieldState: null,
    visible: false
  });

  useEffect(() => {
    ViewApi.searchViewSettings({id: Number(props.id), formDefId: props.formSchema.id}).then((responseResult) => {
      if (ResponseStatus.SUCCESS === responseResult.code) {
        const data = responseResult.data;
        let viewObject = data.viewObject;
        const formObject = data.formObject;

        // 新建将当前页面的信息作为默认配置条件进行创建
        if (!viewObject || !viewObject.id) {
          viewObject = Object.assign({
            id: 0,
            isDefault: false,
            isFilterExpand: props.viewObject.isFilterExpand,
            viewColumns: props.viewObject.viewColumns,
            filterColumns: props.viewObject.filterColumns,
            meta: props.viewObject.meta,
            filterOptions: props.viewObject.filterOptions,
            storageType: NsGrid.StorageType.All
          });
        }

        form.setFieldsValue({
          id: viewObject.id,
          viewDefId: viewObject.viewDefId,
          viewDefName: viewObject.viewDefName,
          storageType: (viewObject.storageType != NsGrid.StorageType.All) ? NsGrid.StorageType.OnlyConfig : NsGrid.StorageType.All,
          isDefault: viewObject.isDefault,
          isFilterExpand: viewObject.isFilterExpand
        });

        setDisplayType(formObject.displayType);
        if (formObject.displayType == NsGrid.DisplayType.Pivot) {
          const newDimensionColumnDataSource = getDimensionTransferItem(formObject);
          setDimensionColumnDataSource(newDimensionColumnDataSource);
          const meta = getMetaValue(newDimensionColumnDataSource, viewObject?.meta);

          const newIndicatorColumnDataSource = getIndicatorTransferItem(formObject);
          setIndicatorColumnDataSource(newIndicatorColumnDataSource);
          const valueColumns = getTransterItemValue(newIndicatorColumnDataSource, viewObject?.meta?.values);
          form.setFieldsValue({
            meta: meta,
            valueColumns: valueColumns
          });
        } else {
          const newViewColumnDataSource = getViewColumnTransferItem(formObject);
          setViewColumnDataSource(newViewColumnDataSource);
          const viewColumns = getTransterItemValue(newViewColumnDataSource, viewObject?.viewColumns);
          form.setFieldsValue({viewColumns: viewColumns});
        }

        const newFilterColumnDataSouce = getFilterColumnTransferItem(formObject);
        setFilterColumnDataSource(newFilterColumnDataSouce);
        const filterColumns = getTransterItemValue(newFilterColumnDataSouce, viewObject?.filterColumns);
        form.setFieldsValue({filterColumns: filterColumns});
        setFilterOptionState({
          items: initFilterOptionState(viewObject, filterColumns),
          fieldState: null,
          visible: false
        });
      } else {
        message.error(responseResult.message);
      }
    });
  }, [props.id]);

  const handleFilterColumnChange = (targetKeys: string[]) => {
    form.setFieldsValue({
      filterColumns: targetKeys
    })
    setFilterOptionState({
      visible: false,
      fieldState: null,
      items: findFilterOptions(filterOptionState.items, targetKeys)
    })
  };

  const handleFilterColumnSetting = (key: string) => {
    const items = filterOptionState.items;
    const fieldState = getFilterState(null, props.formSchema.allFields, key, null);
    if (fieldState) {
      (items ? items : []).forEach(item => {
        if (fieldState.code == item.field) {
          fieldState.setDefaultValue(item);
        }
      });
    }
    setFilterOptionState({items, fieldState, visible: true});
  };

  const saveFilterColumnSetting = (key: string, value: any) => {
    const {items} = filterOptionState;
    let newItems: FilterOption[] = [];
    (items ? items : []).forEach(item => {
      if (item.field != key) {
        newItems.push(item);
      }
    });
    if (value) {
      if (wdiCore.Object.IsArray(value)) {
        newItems = newItems.concat(value);
      } else {
        newItems.push(value);
      }
    }
    setFilterOptionState({
      visible: false,
      fieldState: null,
      items: newItems
    })
  };

  const cancelFilterColumnSetting = () => {
    setFilterOptionState({
      visible: false,
      fieldState: null,
      items: filterOptionState.items
    })
  };

  const handleSubmit = () => {
    form.validateFields().then(values => {
      const {meta, valueColumns, viewColumns, ...newViewObject} = values;
      newViewObject.viewDefId = values.viewDefName;
      if (NsGrid.StorageType.All == values.storageType) {
        if (filterOptionState && filterOptionState.items && filterOptionState.items.length) {
          newViewObject.filterOptions = findFilterOptions(filterOptionState.items, values.filterColumns);
        }
      }

      newViewObject.displayType = displayType;
      newViewObject.createType = NsGrid.CreateType.Custom;
      newViewObject.scopeType = NsGrid.ScopeType.Custom;

      if (displayType == NsGrid.DisplayType.Pivot) {
        newViewObject.meta = Object.assign({values: valueColumns}, meta);
      } else {
        newViewObject.viewColumns = viewColumns;
      }

      if (validateRequired(ValivateType.FLITER, filterColumnDataSource, newViewObject.filterColumns)
        && validateRequired(ValivateType.COLUMN, viewColumnDataSource, newViewObject.viewColumns)) {
        ViewApi.post({viewObject: newViewObject, formDefId: props.formSchema.id}).then((responseResult: any) => {
          if (ResponseStatus.SUCCESS === responseResult.code) {
            if (props.onSave) {
              props.onSave(responseResult)
            }
          } else {
            message.error(responseResult.message);
          }
        });
      }
    }).catch(errorInfo => {
      console.log("Submit error", errorInfo);
    });
  }

  const handleDefaultSubmit = () => {
    if (window.confirm("确定恢复到默认设置吗？")) {
      ViewApi.getForAdmin(String(props.formSchema.id)).then((responseResult) => {
        if (ResponseStatus.SUCCESS === responseResult.code) {
          const data = responseResult.data;
          const viewObject = data.viewObject;
          form.validateFields().then(values => {
            const newViewObject: any = {
              id: values.id,
              viewDefId: values.viewDefName,
              viewDefName: values.viewDefName,
              storageType: viewObject.storageType,
              displayType: viewObject.displayType,
              createType: NsGrid.CreateType.Custom,
              scopeType: NsGrid.ScopeType.Custom,
              filterColumns: viewObject.filterColumns,
              viewColumns: viewObject.viewColumns,
              meta: viewObject.meta,
              filterOptions: viewObject.filterOptions
            };

            ViewApi.post({viewObject: newViewObject, formDefId: props.formSchema.id}).then((responseResult: any) => {
              if (ResponseStatus.SUCCESS === responseResult.code) {
                if (props.onSave) {
                  props.onSave(responseResult)
                }
              } else {
                message.error(responseResult.message);
              }
            });
          }).catch(errorInfo => {
            console.log("Submit error", errorInfo);
          });
        } else {
          message.error(responseResult.message);
        }
      });
    }
  }

  return (
    <>
      <WdiModal
        title={props.title}
        visible={props.visible}
        //onOk={handleSubmit}
        onCancel={() => {
          if (props.onClose) {
            props.onClose()
          }
        }}
        width={1000}
        footer={[
          <Button key="default" onClick={handleDefaultSubmit} hidden={props.id ? false : true}>
            恢复默认配置
          </Button>,
          <Button key="back" onClick={() => {
            if (props.onClose) {
              props.onClose()
            }
          }}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            确定
          </Button>,
        ]}
      >
        <Form form={form} labelAlign='left'>
          <Form.Item name="id" hidden>
            <Input/>
          </Form.Item>
          <Form.Item name="viewDefId" hidden>
            <Input/>
          </Form.Item>
          <Row gutter={[0, 0]}>
            <Col span={12} key='viewDefName'>
              <Form.Item
                {...formItemExLayout}
                label="视图名称"
                name="viewDefName"
                rules={[{required: true, message: '请输入视图名称'}]}
              >
                <Input placeholder="请输入视图名称" autoComplete="off"/>
              </Form.Item>
            </Col>
            <Col span={12} key='storageType'>
              <Form.Item
                {...formItemExLayout}
                label="存储类型"
                name="storageType"
                style={{marginLeft: 10}}
              >
                <Radio.Group>
                  <Radio value={2}>仅配置</Radio>
                  <Radio value={1}>配置+过滤条件设置值</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[0, 0]}>
            <Col span={12} key='isFilterExpand'>
              <Form.Item
                {...formItemExLayout}
                label="过滤条件默认展开"
                name="isFilterExpand"
                valuePropName="checked"
              >
                <Checkbox/>
              </Form.Item>
            </Col>
            <Col span={12} key='isDefault'>
              <Form.Item
                {...formItemExLayout}
                label="默认视图"
                name="isDefault"
                valuePropName="checked"
                style={{marginLeft: 10}}
              >
                <Checkbox/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item noStyle>
            <span>过滤条件配置:</span>
            <Form.Item name="filterColumns">
              <SortableTransfter
                dataSource={filterColumnDataSource}
                items={filterOptionState.items}
                onChange={handleFilterColumnChange}
                onColumnSetting={handleFilterColumnSetting}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item noStyle hidden={displayType != NsGrid.DisplayType.Pivot}>
            <span>维度选择:</span>
            <Form.Item name="meta">
              <BiMetaTransfer dataSource={dimensionColumnDataSource}/>
            </Form.Item>
          </Form.Item>
          <Form.Item noStyle hidden={displayType != NsGrid.DisplayType.Pivot}>
            <span>值选择:</span>
            <Form.Item name="valueColumns">
              <SortableTransfter dataSource={indicatorColumnDataSource}/>
            </Form.Item>
          </Form.Item>
          <Form.Item noStyle hidden={displayType == NsGrid.DisplayType.Pivot}>
            <span>查询结果配置:</span>
            <Form.Item name="viewColumns">
              <SortableTransfter dataSource={viewColumnDataSource}/>
            </Form.Item>
          </Form.Item>
        </Form>
      </WdiModal>
      {
        filterOptionState.visible &&
        <FieldSettingApp
          {...{
            fieldState: filterOptionState.fieldState,
            visible: filterOptionState.visible,
            title: '设置',
          }}
          onSave={saveFilterColumnSetting}
          onCancel={cancelFilterColumnSetting}
        />
      }
    </>
  );
}

type ViewColumnSettingAppProps = {
  visible: boolean;
  viewObject: any;
  formSchema: any;
  grid: any;
};

export const ViewColumnSettingApp: React.FC<ViewColumnSettingAppProps> = (props) => {
  const [viewColumnState, setViewColumnState] = useState(() => {
    const dataSource = getViewColumnTransferItem({
      allFields: props.formSchema.allFields,
      allFieldNames: props.formSchema.allFieldNames
    });
    const value = getTransterItemValue(dataSource, props?.viewObject?.viewColumns);
    return {dataSource, value};
  });

  const handleChange = (value: string[]) => {
    setViewColumnState({value, dataSource: viewColumnState.dataSource});
  };

  const handleSubmit = () => {
    const viewObject = Object.assign({}, props.viewObject, {
      viewColumns: viewColumnState.value,
      scopeType: NsGrid.ScopeType.Custom,
      createType: NsGrid.CreateType.Custom,
    });
    if (wdiCore.Object.IsNullOrUndefined(viewObject.displayType)) {
      viewObject.displayType = NsGrid.DisplayType.Table;
    }
    if (wdiCore.Object.IsNullOrUndefined(viewObject.storageType)) {
      viewObject.storageType = NsGrid.StorageType.OnlyConfig;
    }
    if (validateRequired(ValivateType.COLUMN, viewColumnState.dataSource, viewObject.viewColumns)) {
      ViewApi.postForColumn({viewObject: viewObject, formDefId: props.formSchema.id}).then((responseResult) => {
        if (ResponseStatus.SUCCESS === responseResult.code) {
          const data = responseResult.data;
          if (props.grid && props.grid.viewColumnToggle) {
            props.grid.viewColumnToggle(data.viewObject, data.viewDefs);
          }
        } else {
          message.error(responseResult.message);
        }
      });
    }
  };

  const handleCancel = () => {
    if (props.grid && props.grid.closeViewColumnModal) {
      props.grid.closeViewColumnModal();
    }
  };

  const handleDefaultSubmit = () => {
    if (window.confirm("确定恢复到默认设置吗？")) {
      ViewApi.getForAdmin(String(props.formSchema.id)).then((responseResult) => {
        if (ResponseStatus.SUCCESS === responseResult.code) {
          const defaultViewObject = responseResult.data.viewObject;
          const viewObject = Object.assign({}, props.viewObject, {
            viewColumns: defaultViewObject?.viewColumns,
            scopeType: NsGrid.ScopeType.Custom,
            createType: NsGrid.CreateType.Custom,
          });
          if (wdiCore.Object.IsNullOrUndefined(viewObject.displayType)) {
            viewObject.displayType = NsGrid.DisplayType.Table;
          }
          if (wdiCore.Object.IsNullOrUndefined(viewObject.storageType)) {
            viewObject.storageType = NsGrid.StorageType.OnlyConfig;
          }
          ViewApi.postForColumn({viewObject: viewObject, formDefId: props.formSchema.id}).then((responseResult) => {
            if (ResponseStatus.SUCCESS === responseResult.code) {
              const data = responseResult.data;
              if (props.grid && props.grid.viewColumnToggle) {
                props.grid.viewColumnToggle(data.viewObject, data.viewDefs);
              }
            } else {
              message.error(responseResult.message);
            }
          });
        } else {
          message.error(responseResult.message);
        }
      });
    }
  }

  return (
    <WdiModal
      title="调整数据列"
      visible={props.visible}
      //onOk={handleSubmit}
      onCancel={handleCancel}
      width={700}
      footer={[
        <Button key="default" onClick={handleDefaultSubmit}>
          恢复默认配置
        </Button>,
        <Button key="back" onClick={handleCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          确定
        </Button>,
      ]}
    >
      <SortableTransfter
        height={400}
        {...viewColumnState}
        onChange={handleChange}
      />
    </WdiModal>
  );
}

export const AdminViewSettingApp: React.FC<WdiModalAppProps> = (props) => {
  const [form] = Form.useForm();

  const [displayType, setDisplayType] = useState<any>();
  const [dimensionColumnDataSource, setDimensionColumnDataSource] = useState<TransferItem[]>([]);
  const [indicatorColumnDataSource, setIndicatorColumnDataSource] = useState<TransferItem[]>([]);
  const [viewColumnDataSource, setViewColumnDataSource] = useState<TransferItem[]>([]);
  const [filterColumnDataSource, setFilterColumnDataSource] = useState<TransferItem[]>([]);

  useEffect(() => {
    ViewApi.getForAdmin(String(props.id)).then((responseResult) => {
      if (ResponseStatus.SUCCESS === responseResult.code) {
        const data = responseResult.data;
        const viewObject = data.viewObject;
        const formObject = data.formObject;
        if (viewObject && viewObject.id) {
          form.setFieldsValue({
            id: viewObject.id,
            filterOptions: viewObject.filterOptions ? (typeof (viewObject.filterOptions) == 'string' ? viewObject.filterOptions : JSON.stringify(viewObject.filterOptions)) : ''
          });
        } else {
          form.setFieldsValue({
            id: 0,
          });
        }
        setDisplayType(formObject.displayType);
        if (formObject.displayType == NsGrid.DisplayType.Pivot) {
          const newDimensionColumnDataSource = getDimensionTransferItem(formObject);
          setDimensionColumnDataSource(newDimensionColumnDataSource);
          const meta = getMetaValue(newDimensionColumnDataSource, viewObject?.meta);

          const newIndicatorColumnDataSource = getIndicatorTransferItem(formObject);
          setIndicatorColumnDataSource(newIndicatorColumnDataSource);
          const valueColumns = getTransterItemValue(newIndicatorColumnDataSource, viewObject?.meta?.values);
          form.setFieldsValue({
            meta: meta,
            valueColumns: valueColumns
          });
        } else {
          const newViewColumnDataSource = getViewColumnTransferItem(formObject);
          setViewColumnDataSource(newViewColumnDataSource);
          const viewColumns = getTransterItemValue(newViewColumnDataSource, viewObject?.viewColumns);
          form.setFieldsValue({viewColumns: viewColumns});
        }
        const newFilterColumnDataSouce = getFilterColumnTransferItem(formObject);
        setFilterColumnDataSource(newFilterColumnDataSouce);
        const filterColumns = getTransterItemValue(newFilterColumnDataSouce, viewObject?.filterColumns);
        form.setFieldsValue({filterColumns: filterColumns});
      } else {
        message.error(responseResult.message);
      }
    });
  }, [props.id]);

  const handleSubmit = () => {
    form.validateFields().then(values => {
      const {meta, valueColumns, viewColumns, filterOptions, ...newValues} = values;

      const newViewObject = Object.assign({
        viewDefId: "默认视图",
        viewDefName: "默认视图",
        isDefault: true,
        isFilterExpand: false,
        storageType: NsGrid.StorageType.All,
        displayType: displayType,
        scopeType: NsGrid.ScopeType.Default,
        createType: NsGrid.CreateType.Custom
      }, newValues);

      // 如果过滤条件不为空则存储类型更新为保存所有
      if (!wdiCore.String.IsNullOrEmpty(filterOptions)) {
        newViewObject.storageType = NsGrid.StorageType.All;
        newViewObject.filterOptions = filterOptions;
      }

      if (displayType == NsGrid.DisplayType.Pivot) {
        newViewObject.meta = Object.assign({values: valueColumns}, meta);
      } else {
        newViewObject.viewColumns = viewColumns;
      }

      if (validateRequired(ValivateType.FLITER, filterColumnDataSource, newViewObject.filterColumns)
        && validateRequired(ValivateType.COLUMN, viewColumnDataSource, newViewObject.viewColumns)) {
        ViewApi.postForAdmin({viewObject: newViewObject, formDefId: String(props.id)}).then((responseResult) => {
          if (ResponseStatus.SUCCESS === responseResult.code) {
            if (props.onSave) {
              props.onSave(responseResult)
            }
          } else {
            message.error(responseResult.message);
          }
        });
      }
    }).catch(errorInfo => {
      console.log("Submit error", errorInfo);
    });
  }

  return (
    <>
      <WdiModal
        title={props.title}
        visible={props.visible}
        onOk={handleSubmit}
        onCancel={() => {
          if (props.onClose) {
            props.onClose()
          }
        }}
        width={1000}
      >
        <Form form={form}>
          <Form.Item name="id" hidden>
            <Input/>
          </Form.Item>
          <Form.Item noStyle>
            <span>过滤条件配置:</span>
            <Form.Item name="filterColumns">
              <SortableTransfter dataSource={filterColumnDataSource}/>
            </Form.Item>
          </Form.Item>
          <Form.Item noStyle hidden={displayType != NsGrid.DisplayType.Pivot}>
            <span>维度选择:</span>
            <Form.Item name="meta">
              <BiMetaTransfer dataSource={dimensionColumnDataSource}></BiMetaTransfer>
            </Form.Item>
          </Form.Item>
          <Form.Item noStyle hidden={displayType != NsGrid.DisplayType.Pivot}>
            <span>值选择:</span>
            <Form.Item name="valueColumns">
              <SortableTransfter dataSource={indicatorColumnDataSource}/>
            </Form.Item>
          </Form.Item>
          <Form.Item noStyle hidden={displayType == NsGrid.DisplayType.Pivot}>
            <span>查询结果配置:</span>
            <Form.Item name="viewColumns">
              <SortableTransfter dataSource={viewColumnDataSource}/>
            </Form.Item>
          </Form.Item>
          <Form.Item name="filterOptions" label="过滤字段默认值">
            <Input.TextArea style={{resize: 'both'}}/>
          </Form.Item>
        </Form>
      </WdiModal>
    </>
  );
}
