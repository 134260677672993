import React, {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Col, Form, message, Row, Tabs} from 'antd';
import ProForm, {ProFormInstance} from '@ant-design/pro-form';
import {FileUploadUtility, WdiFileList} from '@/components/WdiControls/WdiFileUpload';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import {FormPageProps, WdiFormPageTitle} from '@/components/WdiBus/FormPage';
import {initApprovalPageContext, ViewEntityProps} from '../../components/data';
import {ResponseStatus} from '@/utils/request';
import DataStore from '@/components/GridWidget/DataStore';
import {DataOptions} from '@/services/basic/metadata';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {WdiCardTitle, wdiCore, WdiModalProps, WdiStaffCard, WdiTextArea} from '@/components/WdiControls';
import {Enum} from '@/enums/system';
import FormSaInfosApp from '@/pages/sales/sa/components/FormSaInfos';
import {GeneralPaymentDetailsForApprovalPanel} from '../../GeneralPaymentApply/components/detail';
import DynamicInformation from '../../components/DynamicInformation';
import {WdiInputNumber} from "@/components/WdiControls/WdiInputNumber";
import {SoldTo} from "@/services/mdm/soldto";
import PrePayDetail from "@/pages/sales/sp/components/PrePayDetail";

const moreConfig: any = {
  successParams: [
    {
      url: "/basicapi/favorite/getButtons",
      keys: []
    }, {
      url: "/activityapi/spButtons/get",
      keys: [
        // "UndoButton",
        "ReeditButton",
        "CancelButton",
        "InterveneFlowButton",
        "ExportLockRemoveButton",
        "ViewSfaCollectionDetailsButton",
        "ModifyInvoiceNoButton",
        "TbdButton",
        "CancelTbdButton"
      ]
    }
  ],
  errorParams: [
    {
      url: "/activityapi/spButtons/get",
      keys: [
        "RepairButton",
        "InterveneFlowButton"
      ]
    }
  ]
};

const approvalConfig: any = {
  params: {
    url: "/activityapi/spButtons/get",
    keys: [
      "ApprovalButton"
    ]
  }
};

const GeneralPaymentApprovalApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [entity, setEntity] = useState<ViewEntityProps>();
  const [hiddenPrePay, setHiddenPrePay] = useState<any>(true);
  const [modalProp, setModalProp] = useState<WdiModalProps>({visible: false});
  const [prePayParam, setPrePayParam] = useState<any>({});
  const [hiddenButton, setHiddenButton] = useState<boolean>(false);
  const onDetailsChange = (data: any[]) => {
    setDataSource(data);
  };
  const onPrepayClick = (evt: any) => {
    if (evt && evt.target) {
      setModalProp({visible: true, title: "预付信息", id: null, data: prePayParam});
    }
  };
  const onClose = () => {
    setModalProp({visible: false, title: "", id: null});
  }

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApprovalPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;

        setEntity(viewEntity);

        if (viewEntity.formSp) {
          formRef.current?.setFieldsValue({
            amount: wdiCore.Number.AmountToString(viewEntity.formSp.amount),
            remark: viewEntity.formSp.remark,
            attachment: FileUploadUtility.parse(viewEntity.formSp.fileName, viewEntity.formSp.filePath),
            isForPrePay: viewEntity.formSp?.isForPrePay
          });
        }
        if (viewEntity.form?.statusId == Enum.FormStatus.APPROVE_COMPLETED || viewEntity.form?.statusId == Enum.FormStatus.AWAITING) {
          if (!viewEntity.formSp?.isForPrePay) {
            viewEntity.formSpDetails?.map(item => {
              if (item.paymentMethodId == Enum.PaymentMethod.ACCOUNT_DEDUCT || item.paymentMethodId == Enum.PaymentMethod.THIRD_PART
                || item.paymentMethodId == Enum.PaymentMethod.TRANSFER_FUND_POOL) {
                setHiddenButton(false);
              } else {
                setHiddenButton(true);
              }
            });
          }
        }
        if (viewEntity.formSp?.isForPrePay) {
          setHiddenPrePay(false);
        }
        if (viewEntity.soldToCode) {
          SoldTo.get(viewEntity.soldToCode).then(response => {
            setPrePayParam({
              soldToMasterCode: response?.data.soldToMasterCode,
              prepayType: 2,
              startYear: viewEntity.startYear,
              endYear: viewEntity.endYear,
            });
          });
        }

        if (viewEntity.formSpDetails) {
          setDataSource(DataStore.init(viewEntity.formSpDetails));
        }
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = `活动付款单【${entity.form?.formNo}】`;
      DataOptions.search({typeGroup: 3, value: entity.form?.statusId}).then(response => {
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (response.data && response.data.length > 0) {
          if (entity.form?.statusId != Enum.FormStatus.SCRAP && entity.form?.exportStatus == Enum.ExportStatus.EXPORT_SUCCESS) {
            title += `，已导出`;
          } else {
            title += `，${response.data[0].name}`;
          }
          if (props.setPageTitle) props.setPageTitle(
            <WdiFormPageTitle
              title={title}
              onClose={props.onClose}
              callback={props.callback}
              form={entity?.form}
              useCaseId={Enum.UseCase.SP}
              moreConfig={moreConfig}
              approvalConfig={approvalConfig}/>
          );
        }
      });
    }
  }, [props.setPageTitle, entity]);

  return (
    <>
      <WdiStaffCard formId={entity?.form?.formId}/>
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab={<span>付款信息</span>} key="1">
          <ProForm
            formRef={formRef}
            submitter={{
              render: () => {
                return (<></>)
              },
            }}
          >
            <FormSaInfosApp formRef={formRef} defaultCollapsed={true} formSaId={entity?.formSp?.formSaId}/>
            <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.FORM_SP} title='活动付款单信息'/>}
                      collapsed={true}>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="amount" label="本次付款总金额">
                    <WdiInputNumber format='d' digits={2} readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={3}>
                  <Form.Item name="isForPrePay" label="是否冲销预付" valuePropName="checked">
                    <Checkbox disabled={true}/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4} hidden={hiddenPrePay}>
                  <Button type="link" style={{marginTop: -7}}
                          onClick={onPrepayClick}>预付信息</Button>
                </Col>

              </Row>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={16}>
                  <Form.Item name="remark" label="付款备注">
                    <WdiTextArea rows={4} readOnly/>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="attachment"
                    label="付款附件"
                    valuePropName='fileList'
                    getValueFromEvent={(e: any) => {
                      return e.fileList || [];
                    }}
                  >
                    <WdiFileList/>
                  </Form.Item>
                </Col>
              </Row>
            </WdiPanel>
          </ProForm>
          <GeneralPaymentDetailsForApprovalPanel
            dataSource={dataSource}
            data={{
              formSp: entity?.formSp,
              formId: entity?.formId,
              pageTypeId: entity?.formSp?.pageTypeId,
              hidden: hiddenButton
            }}
            onChange={onDetailsChange}/>
        </TabPane>
        <TabPane tab={<span>动态信息</span>} key="4">
          <DynamicInformation formId={entity?.form?.formId} formTypeId={entity?.form?.formTypeId}
                              pageTypeId={entity?.form?.pageTypeId} useCaseId={Enum.UseCase.SP}/>
        </TabPane>
      </Tabs>
      {modalProp.visible &&
        <PrePayDetail
          {...modalProp}
          onClose={onClose}
        />}
      {}
    </>
  );
};
export default GeneralPaymentApprovalApp;
