import React from 'react';

/// children
const WdiPageContainer: React.FC = (props) => {
    return (
        <div className='ant-pro-page-container'>
            <div className='ant-pro-grid-content'>
                <div className='ant-pro-grid-content-children'>
                    <div className='ant-pro-page-container-children-content'>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { WdiPageContainer }
