import { message } from "antd";

const ResponseCode = {
  SUCCESS: 200,
  UNKNOWN: 201,
}

/** 检查返回数据 responseResult.Status == ResponseCode.SUCCESS 返回 True */
function responseIsOk<T>(responseResult: API.ResponseResult<T> | undefined | null) {
  if (responseResult) {
    // 改成Java之后返回数据统一使用小写
    if (responseResult.code === ResponseCode.SUCCESS) {
      return true;
    }
  }
  return false;
}

function responseErrorInfo<T>(responseResult: API.ResponseResult<T> | undefined | null) {
  if (responseResult) {
    if (responseResult.message) {
      message.error(responseResult.message);
    } else {
      message.error("请求失败!");
    }
  } else {
    message.error("请求失败!");
  }
}

function responseResultOk(data: any){
  return {
    code: ResponseCode.SUCCESS,
    data: data
  };
}

function responseResultError(msg: string){
  return {
    code: ResponseCode.UNKNOWN,
    message: msg
  };
}

export { responseIsOk, responseErrorInfo, responseResultError, responseResultOk, ResponseCode };
