import {wdiCore} from '@/components/WdiControls';
import {toExcel} from '@/components/WdiControls/WdiExcel';
import {FileUploadUtility, WdiFileUpload} from '@/components/WdiControls/WdiFileUpload';
import {ImportModalProps} from '@/components/WdiControls/WdiImport';
import {WdiModal} from '@/components/WdiControls/WdiModalForm';
import {ResponseStatus} from '@/utils/request';
import {Button, Checkbox, Col, Form, message, Progress, Row, Space, Steps, Typography} from 'antd';
import {useEffect, useState} from 'react';
import {WdiTable} from '@/components/WdiControls/WdiTable';
import {FormSs} from "@/services/sales/ss";
import {WdiSelectFormat} from "@/components/WdiControls/WdiSelect";

const CalculateProgress: React.FC = () => {
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setPercent((prevPercent) => {
        if (prevPercent > 97) {
          return prevPercent;
        }
        return prevPercent + 1;
      });
    }, 50);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Progress key="calculate" percent={percent} strokeWidth={30} strokeColor='#52c41a'/>
  )
}

export const FormSsDetailImportModalApp: React.FC<ImportModalProps> = (props) => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState<number>(0);
  const [isRunning, setIsRunning] = useState(false);
  const [importResult, setImportResult] = useState<any>();
  const {bohqId, pageTypeId, details} = props.data;
  const errorColumns = [
    {
      title: '行号',
      dataIndex: 'rowIndex',
      width: 50
    },
    {
      title: '错误信息',
      dataIndex: 'errorInfo',
    },
  ];

  const onCancel = (e: any) => {
    if (props.onClose) {
      props.onClose();
    }
  };
  const handleClick = (e: any) => {
    if (current === 0) {
      form.validateFields().then(() => {
        const values = form.getFieldsValue();
        const {fileList, isClean} = values;
        if (!fileList) {
          message.error('请先上传文件');
          return;
        }
        const fileInfo: Record<string, any> = {
          sheetName: 'formSsDetails',
          startRowNum: 1,
        };
        const files = FileUploadUtility.serialize(fileList);
        if (files && files.names && files.urls) {
          fileInfo.fileName = files.names;
          fileInfo.filePath = files.urls;
        }
        const parameters: any = {
          limitedFileSize: -1,
          limitedItemsCount: -1,
          items: [{
            name: 'fileInfo',
            value: JSON.stringify(fileInfo),
          }, {
            name: 'bohqId',
            value: bohqId
          },
            {
              name: 'pageTypeId',
              value: pageTypeId
            },
            {
              name: 'isClean',
              value: WdiSelectFormat.getvalue(isClean)
            }, {
              name: 'formSsDetails',
              value: JSON.stringify(details),
            }
          ],
        };
        setIsRunning(true);
        setCurrent(1);
        FormSs.Buttons.formSsDetailsImportValidate(parameters).then(response => {
          if (response.code == ResponseStatus.SUCCESS) {
            setImportResult(response.data);
            if (response.data.success) {
              setIsRunning(false);
              setCurrent(2);
            } else {
              message.error(response.data.message);
              setIsRunning(false);
              setCurrent(0);
            }
          } else {
            message.error(response.message);
            setIsRunning(false);
            setCurrent(0);
          }
        })
      })
    } else if (current === 2) {
      const values = form.getFieldsValue();
      const {isClean} = values;
      setIsRunning(true);
      setCurrent(3);
      const parameters: any = {
        limitedFileSize: -1,
        limitedItemsCount: -1,
        items: [{
          name: 'uuid',
          value: JSON.stringify(importResult?.exProperty),
        }, {
          name: 'pageTypeId',
          value: pageTypeId
        },
          {
            name: 'isClean',
            value: WdiSelectFormat.getvalue(isClean)
          }
        ],
      };
      FormSs.Buttons.formSsDetailsImport(parameters).then(response => {
        if (response.code == ResponseStatus.SUCCESS) {
          setImportResult(response);
          setIsRunning(false);
          setCurrent(4);
        } else {
          message.error(response.message);
          setIsRunning(false);
          setCurrent(2);
        }
      })
    } else if (current === 4) {
      if (props.onSave) {
        props.onSave(importResult);
      }
    }
  };

  const columns = [
    {name: 'formSaDetailId', displayName: '活动明细编号*'},
    {name: 'formNo', displayName: '活动单号*'},
    {name: 'activityName', displayName: '活动名称'},
    {name: 'saStartDate', displayName: '活动开始日期'},
    {name: 'saEndDate', displayName: '活动截止日期'},
    {name: 'staffName', displayName: '申请人'},
    {name: 'soldToCode', displayName: '售达方编号'},
    {name: 'soldToName', displayName: '售达方名称'},
    {name: 'custSysCode', displayName: '客户集团编号'},
    {name: 'custSysName', displayName: '客户集团名称'},
    {name: 'platformName', displayName: '分子平台'},
    {name: 'districtName', displayName: '省份'},
    {name: 'productBuyNames', displayName: '产品别'},
    {name: 'ecName', displayName: '费用项'},
    {name: 'promotionDesc', displayName: '活动机制'},
    {name: 'unitExpense1', displayName: '一促单听补贴'},
    {name: 'quantityForecast1', displayName: '一促预估销量'},
    {name: 'unitExpense2', displayName: '二促单听补贴'},
    {name: 'quantityForecast2', displayName: '二促预估销量'},
    {name: 'unitExpenseSellin', displayName: 'sellin单听补贴'},
    {name: 'quantityForecastSellin', displayName: 'sellin预估销量'},
    {name: 'amount', displayName: '申请金额'},
    {name: 'quantityActual', displayName: '系统计算实际销量'},
    {name: 'salesTypeName', displayName: '销量类型'},
    {name: 'amountSs', displayName: '核销金额(非price类则必填)'},
    {name: 'remarkSs', displayName: '核销备注(用户填写)'},
    {name: 'quantityActual1', displayName: '一促实际销量(用户填写)'},
    {name: 'quantityActualAmount1', displayName: '一促核销金额(用户填写)'},
    {name: 'quantityActual2', displayName: '二促实际销量(用户填写)'},
    {name: 'quantityActualAmount2', displayName: '二促核销金额(用户填写)'},
  ];
  const resultDownload = () => {
    const sheet = {
      data: importResult?.data?.data,
      names: columns,
      sheetName: "活动明细",
    };
    toExcel({
      name: "检查通过的活动明细_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
      sheetProps: [sheet]
    })
  }

  const failDownload = (e: any) => {
    const errorInfoColumns = columns.concat([{name: "errorInfo", displayName: "错误信息"}]);
    const sheet = {
      data: importResult?.data?.errorData,
      names: errorInfoColumns,
      sheetName: "错误信息"
    };
    toExcel({
      name: "错误信息_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
      sheetProps: [sheet]
    })
  };


  return (
    <WdiModal
      destroyOnClose
      maskClosable={false}
      title={props.title}
      width={800}
      onCancel={onCancel}
      visible={props.visible}
      footer={
        <>
          {
            (current === 0 || current === 2) &&
            <Button onClick={onCancel}>
              取消
            </Button>
          }
          {
            (current === 0 || current === 2 || current === 4) &&
            <Button type="primary"
                    onClick={handleClick}
                    loading={isRunning}
            >
              {current === 0
                ? '开始检查'
                : current === 2
                  ? '开始导入'
                  : '关闭'
              }
            </Button>
          }
        </>
      }
    >
      <Steps size="small" current={current} style={{marginBottom: "20px"}}>
        <Steps.Step key="start" title='开始'/>
        <Steps.Step key="calculate" title='检查'/>
        <Steps.Step key="confirm" title='确认'/>
        <Steps.Step key="generate" title='执行'/>
        <Steps.Step key="complete" title='完成'/>
      </Steps>
      <div className="steps-action">
        {
          (current === 0) &&
          <Form form={form} labelCol={{span: 10}}>
            <Form.Item>
              <Typography.Text>一、请根据模板准备数据文件：</Typography.Text>
            </Form.Item>
            <Form.Item
              name="fileList"
              valuePropName="fileList"
              getValueFromEvent={(e: any) => {
                return e.fileList || [];
              }}
            >
              <WdiFileUpload maxCount={1} leftTopInfo="请选择文件并上传" addToAttachment={false}/>
            </Form.Item>
            <Row>
              <Col><Form.Item>
                <Typography.Text>导入前清空费用明细信息：</Typography.Text>
              </Form.Item></Col>
              <Col>
                <Form.Item valuePropName="checked" name="isClean" style={{marginBottom: '5px'}}>
                  <Checkbox/>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space direction="vertical">
                <Typography.Text>提示：</Typography.Text>
                <Typography.Text
                  type="secondary">1、请先使用“导出活动明细”功能将要核销的活动信息导出</Typography.Text>
                <Typography.Text
                  type="secondary">2、模板中的表头行不能更改；活动明细编号不能更改，系统根据此列进行匹配；</Typography.Text>
                <Typography.Text type="secondary">3、最多可批量导入100个活动单</Typography.Text>
              </Space>
            </Form.Item>
          </Form>
        }
        {
          (current === 1) &&
          <>
            <div style={{marginBottom: "5px"}} key='-1'>
              <CalculateProgress/>
            </div>
            <div style={{marginBottom: "5px"}} key='0'>
              <Typography.Title level={5}>
                正在检查，请耐心等待
              </Typography.Title>
            </div>
            <div style={{marginBottom: "5px"}} key="1">
              <Space direction="vertical">
                <Typography.Text>
                  提示：
                </Typography.Text>
                <Typography.Text type="secondary">
                  1、检查期间请不要关闭此窗口，关闭会导致检查结果无法显示
                </Typography.Text>
              </Space>
            </div>
          </>
        }
        {
          (current === 2) &&
          <>
            <div style={{marginBottom: "5px"}} key='0'>
              <Typography.Title level={5}>
                检查完成：共{importResult?.totalCount}条活动，检查成功{importResult?.successCount}条，检查失败{importResult?.failCount}条
              </Typography.Title>
              <Typography.Link
                onClick={resultDownload}
                key={1}
              >
                检查通过的数据下载
              </Typography.Link>
            </div>
            {importResult?.data?.errorData && importResult?.data?.errorData.length > 0 &&
              <>
                <div style={{marginBottom: "5px"}}>
                  <Typography.Text>
                    错误信息
                  </Typography.Text>
                  <Typography.Link style={{float: "right"}} onClick={failDownload}>
                    下载
                  </Typography.Link>
                </div>
                <div style={{marginBottom: "5px"}}>
                  <WdiTable
                    showPageRows={5}
                    columns={errorColumns}
                    dataSource={importResult.data.errorData.map((item: any) => {
                      const row = {
                        rowIndex: item.rowIndex,
                        errorInfo: item.errorInfo,
                      };
                      return row;
                    })}
                  />
                </div>
              </>
            }
            <div style={{marginBottom: "5px"}} key="1">
              <Space direction="vertical">
                <Typography.Text>
                  提示：
                </Typography.Text>
                <Typography.Text type="secondary">1、若有错误，请下载错误信息进行检查</Typography.Text>
                <Typography.Text
                  type="secondary">2、请确认无误后点击开始创建，系统仅对检查通过的订单信息进行创建</Typography.Text>
              </Space>
            </div>
          </>
        }
        {
          (current === 3) &&
          <>
            <div style={{marginBottom: "5px"}} key='-1'>
              <CalculateProgress/>
            </div>
            <div style={{marginBottom: "5px"}} key='0'>
              <Typography.Title level={5}>
                正在导入数据，请耐心等待
              </Typography.Title>
            </div>
            <div style={{marginBottom: "5px"}} key="1">
              <Space direction="vertical">
                <Typography.Text>
                  提示：
                </Typography.Text>
                <Typography.Text type="secondary">
                  1、导入期间请不要关闭此窗口，关闭会导致导入出错
                </Typography.Text>
              </Space>
            </div>
          </>
        }
        {
          (current === 4) && (
            <>
              <Progress key="complete" percent={100} strokeWidth={30} style={{marginBottom: "5px"}}/>
              <div>
                <div style={{marginBottom: "5px"}}>
                  <span>
                    导入完成
                  </span>
                </div>
              </div>
            </>
          )}
      </div>
    </WdiModal>
  );
}
