import type { ParamsProps } from '@/components/WdiBus/FormPage';
import { ResponseStatus } from '@/utils/request';
import type { WdiModalAppResult } from '@/components/WdiControls';
import { wdiCore } from '@/components/WdiControls';
import { FormApi } from '@/services/sales';
import { FormSa } from '@/services/sales/sa';
import { EC } from '@/services/mdm/ec';
import { Enum } from '@/enums/system';
import { SoldTo } from '@/services/mdm/soldto';

export type ViewEntityProps = {
  budgetProject?: API.BudgetProject,
  form?: API.Form,
  formSa?: API.FormSa,
  formBudgetProject?: API.FormBudgetProject,
  relationType?: number,
  relatedFormId?: number,
  formId?: number;
  statusId?: number;
  formTypeId?: number;
  pageTypeId?: number;
  bohqId?: number;
  budgetProjectId?: number;
  userId?: number;
  positionId?: number;
  ecId?: number;
  ecFieldConfigs?: API.EcFieldConfig[];
}

export const initApplyPageContext = async (params: ParamsProps) => {

  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };

  const viewEntity: ViewEntityProps = {};

  viewEntity.formTypeId = params.formTypeId;
  viewEntity.pageTypeId = params.pageTypeId;
  //viewEntity.budgetProjectId = params.budgetProjectId;
  viewEntity.bohqId = params.bohqId;
  viewEntity.userId = params.userId;
  viewEntity.positionId = params.positionId;
  if (params.formId) {
    //草稿
    const form = await FormApi.get(params.formId);
    viewEntity.form = form.data;
    const formSa = await FormSa.get(params.formId);
    viewEntity.formSa = formSa.data;
    viewEntity.statusId = viewEntity.form.statusId;
    viewEntity.formId = viewEntity.form.formId;
    viewEntity.bohqId = formSa.data.bohqId;
  } else if (params.relatedFormId) {
    //变更
    const form = await FormApi.get(params.relatedFormId);
    viewEntity.form = form.data;
    viewEntity.relatedFormId = viewEntity.form.formId;
    viewEntity.relationType = params.relationType;

    const formSa = await FormSa.get(params.relatedFormId);
    viewEntity.formSa = formSa.data;
    //不是复制单据，用原来的费用归属
    if(viewEntity.relationType != 3)
    {
      viewEntity.bohqId = formSa.data.bohqId;
    }


    // const budgetProjectResponse = await BudgetProject.get(formSa.data.budgetProjectId ?? 0);
    // viewEntity.budgetProject = budgetProjectResponse.data;
    // if (!viewEntity.budgetProject) {
    //   result.message = "无效的专项项目";
    //   return result;
    // }

    if (viewEntity.relationType == Enum.FormRelationType.REJECTED && viewEntity.form.statusId != Enum.FormStatus.REJECTED) {
      result.message = viewEntity.form.formNo + "单据不是退回待修改状态，无效的操作！";
      return result;
    }
    if (viewEntity.relationType == Enum.FormRelationType.MODIFIED && viewEntity.form.statusId != Enum.FormStatus.APPROVE_COMPLETED) {
      result.message = viewEntity.form.formNo + "单据不是审批完成状态，无效的操作！";
      return result;
    }
  }

  if (viewEntity.form) {
    // if (!params.budgetProjectId && viewEntity.formSa && viewEntity.formSa.budgetProjectId) {
    //   viewEntity.budgetProjectId = viewEntity.formSa.budgetProjectId;
    // }
    if (!params.formTypeId) {
      viewEntity.formTypeId = viewEntity.form.formTypeId;
    }
    if (!params.pageTypeId) {
      viewEntity.pageTypeId = viewEntity.form.pageTypeId;
    }
    if (!params.userId) {
      viewEntity.userId = viewEntity.form.userId;
    }
    if (!params.positionId) {
      viewEntity.positionId = viewEntity.form.positionId;
    }
  }

  // //后补类活动需要费用项扩展列
  // if (viewEntity.pageTypeId === Enum.PageType.ACTIVITY_APPLICATION) {
  //   const ecFieldConfigsResponse = await EC.getEcFieldConfigs();
  //   viewEntity.ecFieldConfigs = ecFieldConfigsResponse.data;
  // }

  // if (params.budgetProjectId) {
  //   const budgetProjectResponse = await BudgetProject.get(params.budgetProjectId);
  //   viewEntity.budgetProject = budgetProjectResponse.data;
  //   if (!viewEntity.budgetProject) {
  //     result.message = "无效的专项项目";
  //     return result;
  //   }
  // }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
}

export const initApprovalPageContext = async (params: ParamsProps) => {
  const result: WdiModalAppResult = {
    code: ResponseStatus.UNKNOWN
  };
  const viewEntity: ViewEntityProps = {};
  if (params.formId) {
    const formSAResponse = await FormSa.get(params.formId);
    viewEntity.formSa = formSAResponse.data;
    if (!viewEntity.formSa) {
      result.message = "无效的草稿单";
      return result;
    }

    const formResponse = await FormApi.get(params.formId);
    viewEntity.form = formResponse.data;

    // if (viewEntity.formSa.budgetProjectId) {
    //   const budgetProjectResponse = await BudgetProject.get(viewEntity.formSa.budgetProjectId);
    //   viewEntity.budgetProject = budgetProjectResponse.data;
    // }
    //后补类活动需要费用项扩展列
    if (viewEntity.formSa.pageTypeId === Enum.PageType.ACTIVITY_APPLICATION) {
      const ecFieldConfigsResponse = await EC.getEcFieldConfigs();
      viewEntity.ecFieldConfigs = ecFieldConfigsResponse.data;
    }
  }

  result.code = ResponseStatus.SUCCESS;
  result.data = viewEntity;
  return result;
}

export const getFormStatusName = async (entity: ViewEntityProps) => {
  if (entity.form?.statusId === Enum.FormStatus.APPROVE_COMPLETED) {
    if (entity.formSa?.isClosed) {
      return '已审批已关闭';
    } else {
      if (entity.formSa?.settlementMode === 1 || entity.formSa?.settlementMode === 2) {
        if (entity.formSa.settledStatus === 2) {
          return '已审批已核销';
        } else if (entity.formSa.settledStatus === 1) {
          return '已审批核销中';
        } else {
          return '已审批未核销';
        }
      } else {
        return '已审批未关闭';
      }
    }
  } else {
    let formStatusName = "";
    switch (entity.formSa?.statusId) {
      case 0:
        formStatusName = '草稿';
        break;
      case 1:
        formStatusName = '待审批';
        break;
      case 2:
        formStatusName = '已审批';
        break;
      case 3:
        formStatusName = '退回待修改';
        break;
      case 4:
        formStatusName = '作废';
        break;
    }
    return formStatusName;
  }
}

export const getDetailPrevData = (formSaDetail: API.FormSaDetail) => {
  return {
    ecId: formSaDetail.ecId,
    soldToCode: formSaDetail.soldToCode,
    custSysCode: formSaDetail.custSysCode,
    // boId: formSaDetail.boId,
    revenueType: formSaDetail.revenueType,
  };
};

const pmSoldToTypeLimits: Record<number, number[]> = {
  [0]: [
    Enum.PaymentMethod.DISCOUNT,
    Enum.PaymentMethod.SPECIFIED_FREE_GOODS,
    Enum.PaymentMethod.NON_SPECIFIED_FREE_GOODS,
    Enum.PaymentMethod.TRANSFER_FUND_POOL
  ],
  [Enum.SoldToType.DEALER]: [
    Enum.PaymentMethod.BILL_DEDUCT,
    Enum.PaymentMethod.FREE_GOODS,
    Enum.PaymentMethod.ACCOUNT_DEDUCT
  ],
  [Enum.SoldToType.KA]: [
    Enum.PaymentMethod.FREE_GOODS,
  ],
};

export const paymentMethodDataloading = async (soldToCode: string, ecId: number) => {
  const results = await Promise.all([SoldTo.get(soldToCode), EC.getPaymentMethods(ecId)]);
  if (results[0] && results[1]) {
    const soldToRes = results[0];
    const paymentMethodRes = results[1];
    const soldToType = soldToRes.data.soldToType ?? 0;
    const pmLimits: number[] = [];
    if ([Enum.SoldToType.DEALER, Enum.SoldToType.KA].includes(soldToType)) {
      pmLimits.push(...pmSoldToTypeLimits[soldToType]);
    } else {
      pmLimits.push(...pmSoldToTypeLimits[0]);
    }
    return paymentMethodRes.data.filter(m => !pmLimits.includes(m.value));
  }
  return [];
};

/**
 * 达成率(SAP量)/达成率(SAP额)/达成率(入库量)/达成率(入库额)/达成率(售出量)/达成率(派发量)/达成率(新客量)/达成率(扫码量)/达成率(POS量)/达成率(POS额)；
 */
const calcColumns = [
  ["quantitySellinForecast", "quantitySellinActual", "quantitySellinRate"],
  ["revenueSellinForecast", "revenueSellinActual", "revenueSellinRate"],
  ["quantitySelloutForecast", "quantitySelloutActual", "quantitySelloutRate"],
  ["revenueSelloutForecast", "revenueSelloutActual", "revenueSelloutRate"],
  ["quantitySelloutForecast2", "quantitySelloutActual2", "quantitySelloutRate2"],
  ["quantitySelloutForecast3", "quantitySelloutActual3", "quantitySelloutRate3"],
  ["quantitySelloutForecast4", "quantitySelloutActual4", "quantitySelloutRate4"],
  ["quantitySelloutForecast5", "quantitySelloutActual5", "quantitySelloutRate5"],
  ["quantityPosForecast", "quantityPosActual", "quantityPosRate"],
  ["revenuePosForecast", "revenuePosActual", "revenuePosRate"],
];

/**
 * 加上计算列（对应的实际/目标，有空值或分母0则为空，有值才显示）：
 *
 * @param record
 * @param columns
 * @returns
 */
export const getRateColumns = (record: any, columns: string[]) => {
  const rateColumns: string[] = [];
  for (const calcColumn of calcColumns) {
    const forecastCol = calcColumn[0];
    const actualCol = calcColumn[1];
    const rateCol = calcColumn[2];
    if (columns.includes(forecastCol) && columns.includes(actualCol)
      && wdiCore.Number.ToNumber(record[forecastCol], 2, 0) != 0 && !wdiCore.Object.IsNullOrUndefined(record[actualCol])) {
      rateColumns.push(rateCol);
    }
  }
  return rateColumns;
};

/**
 * 各种实际销量/销额字段：
 * SAP发货销量(EA)/SAP发货销额/门店入库销量(EA)/门店入库历史月均销量(EA)/门店入库销额/门店售出销量(EA)/小听粉派发数量/新客数量/消费者扫码销量(EA)/POS销量(EA)/POS销额
 */
const extensColumns = ["quantitySellinActual", "revenueSellinActual", "quantitySelloutActual6"
  , "quantitySelloutActual", "revenueSelloutActual", "quantitySelloutActual2", "quantitySelloutActual3"
  , "quantitySelloutActual4", "quantitySelloutActual5", "quantityPosActual", "revenuePosActual"];

/**
 * 扩展列：有值才显示
 *
 * @param record
 * @returns
 */
export const getHasValueColumns = (record: any) => {
  const hasValueColumns: string[] = [];
  for (const column of extensColumns) {
    if (!wdiCore.Object.IsNullOrUndefined(record[column])) {
      hasValueColumns.push(column);
    }
  }
  return hasValueColumns;
};

/**
 * 单位费用（EA）（有值显示），折扣比例（有值显示），公司承担比例（有值显示），SAP发货销量目标（EA）（有值显示），SAP发货销额目标（有值显示），销量目标2（EA）（有值显示），销额目标2（有值显示），产品政策，货补产品别，公式计算参数，公式计算金额
 */
const allExtensColumns = ["platformId", "districtId", "unitExpense1", "quantityForecast1"
  , "pricePosAfter1", "unitExpense2", "quantityForecast2", "pricePosAfter2"
  , "proQuantityRate", "proQuantityRate1", "proQuantityRate2"
  , "unitExpenseSellin", "quantityForecastSellin", "quantityActual","invoiceType","vatTypeId"];

export const getAllHasValueColumns = (record: any) => {
  const hasValueColumns: string[] = [];
  for (const column of allExtensColumns) {
    if (!wdiCore.Object.IsNullOrUndefined(record[column])) {
      hasValueColumns.push(column);
    }
  }
  return hasValueColumns;
};
