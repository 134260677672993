export namespace PermissionsEnum {
  export enum UseCase {
    //#region 预算管理[UseCaseGroupID=11]

    // 总部预算
    BUDGET_HQ = 1102,
    // 专项项目
    BUDGET_PROJECT = 1105,
    // 总部预算(广告)
    BUDGET_HQ_MKT = 1122,

    //#endregion

    //#region 政策管理[UseCaseGroupID=14]

    // 产品政策
    PRODUCT_POLICY = 1402,
    // 政策返利单
    REBATE_POLICY = 1405,
    // 政策返利核销
    TSP = 1408,
    // 君币管理
    JB = 1411,
    // 冰柜管理
    FREEZER = 1422,
    //冰柜返利单
    REBATE_FREEZER = 1425,
    // 冰柜返利核销
    TRP = 1428,

    //#endregion

    //#region 活动管理[UseCaseGroupID=16]

    //客户集团销量预测
    SALES_TARGET_CUST = 1601,
    // 活动申请
    SA = 1602,
    // 活动预付
    PP = 1603,
    // 活动结案
    SS = 1608,
    //活动付款
    SP = 1609,
    // 活动申请(广告)
    SA_MKT = 1622,
    // 活动预付(广告)
    PP_MKT = 1623,
    // 活动核销(广告)
    SP_MKT = 1628,

    //#endregion

    //#region A&P
    //ioGroup预算
    IO_GROUP = 1301,
    //io预算
    IO = 1302,
    //#endregion

    //#region 客户管理[UseCaseGroupID=18]

    // 一级客户合同
    SALES_CONTRACT = 1802,
    // 二级客户合同
    SALES_CONTRACT_INDIRECT = 1803,
    // 合同返利单
    REBATE_INFO = 1805,
    // 合同返利报销
    RS = 1808,
    // 合同返利付款
    RSP = 1809,
    // 一级客户合同销售目标
    SALES_TARGET_CONTRACT = 1811,
    // 二级客户合同销售目标
    SALES_TARGET_CONTRACT_INDIRECT = 1812,
    // 合同签约方
    SOLD_TO_GROUP = 1813,
    // 售达方
    SOLD_TO = 1815,
    //售达方主户
    SOLD_TO_MASTER = 1816,
    // 售达方销售目标
    SALES_TARGET = 1818,
    // 售达方调拨
    SELL_IN = 1819,
    // 送达方
    SHIP_TO = 1821,
    // 门店
    SHOP = 1824,
    // 门店POS
    SELL_POS = 1827,
    // 客户集团
    CUST_SYS = 1830,

    //#endregion

    //#region 费用池管理[UseCaseGroupID=20]

    //费用池
    POOL_EXPENSE = 2002,
    //费用池追加
    POOL_ADD = 2002,
    //费用池扣减
    POOL_REDUCE = 2002,
    //费用池调整
    POOL_ADJUST = 2006,
    //费用池冻结
    POOL_FREEZE = 2008,
    //货补系数
    FG_CONVERT = 2011,
    //货补上账折扣
    FG_ACCOUNT_CONVERT = 2014,
    //费用池可用比例
    POOL_USABLE_RATE = 2017,

    //#endregion、

    //#region 规则设置[UseCaseGroupID=12]
    //预算组
    BG = 1101,
    //预算组设置
    RELATION_BG = 1102,
    //年度费率标准
    BUDGET_BASELINE_YTD = 1103,
    //月度费率标准
    BUDGET_BASELINE_MTD = 1104,
    //重点费用项费率标准
    BUDGET_BASELINE_EC = 1105,
    //促销补贴标准
    UNIT_EXPENSE_SETTING = 1106,
    //建议促销价
    PRICE_POS_SETTING = 1107,
    //促销销量占比设置
    SALES_RATE_SETTING = 1108,
    //客户集团销量预测维护人
    STAFF_AND_CUST = 1109,
     //最大促销数量设置
    SALES_LIMIT_SETTING = 1110,
    //department
    DEPARTMENT = 2520,
    //CommunicationPurpose
    COMMUNICATION_PURPOSE = 2521,
    //SpendingCategory
    SPENDING_CATEGORY = 2522,
    //touchPoint
    TOUCH_POINT = 2523,
    //subChannel
    SUB_CHANNEL = 2524,
    //IO预算维护人修改
    ANP_DEPT_AND_STAFF = 2525,
    //#endregion


    //#region 财务操作[UseCaseGroupID=22]

    // 费用类别
    EC = 2202,
    // 费用类别(广告)
    EC_MKT = 2205,
    // 预算中心
    BO = 2208,
    // 费用归属
    BOHQ = 2211,
    // 财务设置
    PERIOD_CONFIG = 2214,
    // 业务预提
    ACCRUAL = 2217,
    // 预提手工调整
    EXPNESE_NON_TPM = 2222,
    // 预提手工调整(广告)
    POOL_PREPAY = 2223,
    // 售达方适用费用项
    SOLD_TO_AND_EC = 2224,
    // 税率类型
    VAT_TYPE = 2225,

    //#endregion

    //#region 基础数据[UseCaseGroupID=25]

    // 系统公告
    ANNOUNCEMENT = 2502,
    // 产品
    PRODUCT = 2505,
    // 产品层级
    PRODUCT_LEVEL = 2508,
    //产品分月成本
    PRODUCT_COST_MONTHLY = 2511,
    //供应商
    SUPPLIER = 2514,
    //审批拒绝原因
    REJECT_REASON = 2517,
    /**
     * 活动类型
     */
    ACTIVITY_TYPE = 2519,
    /**
     * 费用池调整类型
     */
    EXPENSE_POOL_ADJUSTMENT = 2521,

    //#endregion

    //#region 系统管理[UseCaseGroupID=81]

    // 组织结构
    DEPT = 8102,
    // 用户管理
    STAFF = 8105,
    // 系统角色
    SYSTEM_ROLE = 8108,
    // 审批角色
    WORKFLOW_ROLE = 8111,
    // 流程设置
    INDEX = 8114,
    // 任务日志
    SERVICE_LOG = 8117,
    // 操作日志
    OPTION_LOG = 8120,
    // 登陆日志
    LOGIN_ACTION_LOG = 8123,
    // 经销商登陆日志
    SOLD_TO_LOGIN_ACTION_LOG = 8126,
    // 业务代理
    AGENT_BUSINESS = 8129,
    // 代理审批
    SUPER_AGENT_APPROVER = 8132,
    // 帮助中心
    HELP_CENTER = 8135,
    // 错误处理
    ERROR_HANDlING = 8138,

    //#endregion

    //#region 系统配置[UseCaseGroupID=83]

    // 表单类型
    PAGE_TYPE = 8302,
    // 计划任务
    SERVICE = 8305,
    // 提醒规则设置
    REMIND_TYPE = 8308,
    // 审批时效设置
    FORM_TYPE = 8311,
    // 系统参数设置
    PARAMETER = 8314,
    // 错误信息设置
    ERROR_CONFIGURATION = 8317,

    //#endregion

    //#region 个人配置[UseCaseGroupID=85]

    // 代理审批
    AGENT_APPROVE = 8502,
    // 批量审批
    BATCH_APPROVE = 8505,

    //#endregion

    //#region 数据分析[UseCaseGroupID=91]

    // 总部预算分析
    REPORT_BUDGET = 9102,
    // 专项项目分析
    REPORT_MULTI_PROJECT = 9105,
    // 预提入账明细
    REPORT_ACCRUAL = 9108,
    // 活动明细报告
    REPORT_ACTIVITY = 9111,
    // 核销明细报告
    REPORT_PAYMENT = 9114,
    // 年度合同费用明细
    REPORT_CONTRACT = 9117,
    // 总部预算调整明细
    REPORT_BUDGET_ADJUST = 9120,
    // SAP发货销量明细
    REPORT_SAP_DELIVERY = 9123,
    // 门店销量明细
    REPORT_SHOP_SALES_QUANTITY = 9126,
    // 审批时效明细报告
    REPORT_FORM_TYPE = 9129,
    // IO预提明细报告
    REPORT_FOR_BUDGET_IO_ACCRUAL = 9155,

    //#endregion

    // ======================== DMS 相关useCase start ========================

    //#region 经销商基础数据[UseCaseGroupID=51]

    /**
     * 经销商可购产品清单
     */
    SELLING_SKU = 5101,
    /**
     * 经销商物料指导清单
     */
    SELLING_MATERIAL = 5102,
    /**
     * 可随时下单经销商
     */
    SO_TIME_UNLIMITED = 5103,
    /**
     * 城市下单时间
     */
    SO_TIME_CITY = 5104,
    /**
     * 临时下单管理
     */
    SO_TIME_SPECIAL = 5105,
    /**
     * 订单起运量
     */
    SO_DEMAND_WEIGHT = 5106,
    /**
     * 产品起订量
     */
    SO_DEMAND_QUANTITY = 5107,
    /**
     * 产品必订清单
     */
    SO_DEMAND_SKU = 5108,
    /**
     * 包材组合
     */
    MATERIAL_GROUP = 5109,
    /**
     * 产品库存设置
     */
    PRODUCT_INVENTORY = 5110,
    /**
     * 日报表模板设置
     */
    SO_SKU_CATEGORY = 5111,
    /**
     * 发货仓查看
     */
    WARE_HOUSE = 5112,
    /**
     * 起订金额
     */
    SO_DEMAND_AMOUNT = 5113,
    /**
     * 产品价格
     */
    PRODUCT_PRICE = 5114,
    /**
     * 产品单位转换
     */
    PRODUCT_UOM = 5115,
    /**
     * 登录页面滚动图片
     */
    LOGIN_PICTURE = 5116,
    /**
     * 物料供应商映射
     */
    MATERIAL_SUPPLIER = 5117,
    /**
     * 发货仓映射
     */
    WAREHOUSE_MAPPING = 5118,
    /**
     * 货补特价额度
     */
    SO_FG_DISCOUNT_LIMIT = 5119,
    //#endregion

    //#region 经销商订单管理[UseCaseGroupID=52]

    /**
     * 经销商产品订单处理
     */
    PRODUCT_SO = 5201,
    /**
     * 经销商物料订单处理
     */
    MATERIAL_SO = 5202,
    /**
     * 经销商订单查询
     */
    SALES_ORDER = 5203,

    //#endregion

    //#region 信息中心[UseCaseGroupID=53]

    /**
     * 经销商通知公告
     */
    DEALER_ANNOUNCEMENT = 5301,
    /**
     * 客户之声
     */
    CUSTOMER_VOICE = 5302,
    /**
     * 产品报告及公司证件
     */
    COMPANY_CREDENTIAL = 5303,
    /**
     * 行销元素
     */
    MARKETING_ELEMENTS = 5304,

    /**
     * 首页强制公告
     */
    HOME_FORCE_ANNOUNCEMENT = 5306,

    //#endregion

    //#region 财务中心[UseCaseGroupID=55]

    /**
     * 对账管理
     */
    RECONCILIATION = 5501,
    /**
     * 资金对账
     */
    FUND_RECONCILIATION = 5502,
    /**
     * 资金对账人管理
     */
    FUND_RECONCILIATIONER = 5503,
    /**
     * 费用对账
     */
    COST_RECONCILIATION = 5504,
    /**
     * 费用对账人管理
     */
    COST_RECONCILIATIONER = 5505,
    /**
     * 电子发票
     */
    CUSTOMER_INVOICE = 5506,
    /**
     * 内部转账明细
     */
    CUSTOMER_TRANSFER = 5507,
    /**
     * 内部转账人管理
     */
    CUSTOMER_TRANSFER_PREPARER = 5508,
    /**
     * 保证金对账
     */
    GUARANTEE_STATEMENT = 5509,
    /**
     * 资金对账联系人
     */
    FUND_CONTACTS = 5510,
    /**
     * 费用池对账联系人
     */
    POOL_CONTACTS = 5511,

    //#endregion

    //#region 电商对账[UseCaseGroupID=61]

    /**
     * 客户管理
     */
    EC_CUSTOMER = 6101,
    /**
     * 产品映射
     */
    EC_PRODUCT_MAPPING = 6102,
    /**
     * 电商产品红线价格
     */
    EC_REDLINE_PRICE = 6103,
    /**
     * 电商产品预算价格
     */
    EC_BUDGET_PRICE = 6104,
    /**
     * 电商产品基准价格
     */
    EC_GUIDE_PRICE = 6105,
    /**
     * 出库管理
     */
    EC_OUT_BOUND_ORDER = 6106,
    /**
     * 退货管理
     */
    EC_RETURN_ORDER = 6107,
    /**
     * 收款管理
     */
    EC_RECEIPT = 6108,
    /**
     * 退款管理
     */
    EC_REFUND = 6109,
    /**
     * 订单汇总
     */
    EC_ORDER_SUMMARY = 6110,
    /**
     * 对账管理
     */
    EC_RECONCILIATION = 6111,
    /**
     * 电商导入
     */
    EC_SO_IMPORT = 6112,

    //#endregion

    //#region 直营基础数据[UseCaseGroupID=71]

    /**
     * 直营产品统一单位
     */
    KA_SKU_UNIT = 7101,
    /**
     * 直营产品映射
     */
    KA_SKU_MAPPING = 7102,
    /**
     * 直营门店映射
     */
    KA_SHOP_MAPPING = 7103,
    /**
     * 商超系统
     */
    KASYS = 7104,

    //#endregion

    //#region 直营订单管理[UseCaseGroupID=72]

    /**
     * 直营订单处理（内勤）
     */
    OFFICE_KA_SO = 7201,
    /**
     * 直营订单处理（业务）
     */
    BUSINESS_KA_SO = 7202,
    /**
     * 直营订单查询
     */
    KA_ORDER = 7203,

    //#endregion

    //#region 直营验单管理[UseCaseGroupID=73]

    /**
     * 直营验单管理
     */
    KA_CHECKLIST = 7301,

    //#endregion

    //#region 经销商用户管理[UseCaseGroupID=81]

    /**
     * 经销商用户管理
     */
    SOLD_TO_USER = 8139,

    //#endregion

    // ======================== DMS 相关useCase end ========================

    //#region 本项目不再使用

    //#endregion
  }

  export enum OperateType {
    /**
     * 填单，申请（指各种表单的申请，包括活动、专项等等任何表单）
     */
    APPLY = 1,
    /**
     * 查询（和权限范围相关）
     */
    QUERY = 2,
    /**
     * 查看（和权限范围无关的，指基础数据）
     */
    VIEW = 3,
    /**
     * 维护（指增删改）
     */
    MAINTAIN = 4,
    /**
     * 作废（指单据的作废）
     */
    SCRAP = 5,
    /**
     * 关闭（活动或者返点单的关闭）
     */
    CLOSE = 6,
    /**
     * 重新打开（活动或者专项的重新打开）
     */
    RE_OPEN = 7,
    /**
     * 补入（专指合同返利单的补入）
     */
    ADD = 8,
    /**
     * 导入（目前导入都和维护或者申请合在一起的，未来可扩展）
     */
    IMPORT = 9,
    /**
     * 导出（一般不单独列出，有些涉及性能的导出则需要特别列出）
     */
    EXPORT = 10,
    /**
     * 解除导出锁定（一般用于核销单）
     */
    REMOVE_LOCK = 11,
    /**
     * 发货完成
     */
    COMPLETE_DELIVERY = 12,
    /**
     * 解除发货完成
     */
    RE_DELIVERY = 13,
    /**
     * 修改支付方式
     */
    MODIFY_PAYMENT_METHOD = 14,
    /**
     * 修改金额（一般是修改核销单的金额，本项目修改结案金额也使用）
     */
    MODIFY_AMOUNT = 15,
    /**
     * 手动结案（专指合同返利单的手动结案）
     */
    MANUAL_SETTLE = 16,
    /**
     * 取消价格折扣
     */
    CANCEL_PD = 17,
    /**
     * 一级合同返利生成
     */
    GENERATE_REBATE = 18,
    /**
     * 二级合同返利生成
     */
    GENERATE_REBATE_INDIRECT = 19,
    /**
     * 政策返利生成
     */
    GENERATE_REBATE_POLICY = 51,
    /**
     * 确认
     */
    CONFIRM = 52,
    /**
     * 冰柜返利生成
     */
    GENERATE_REBATE_FREEZER = 54,
    /**
     * 匹配政策返利单
     */
    MATCH_REBATE_POLICY = 55,

    // ======================== DMS 相关OperateType start ========================
    MODIFY_INVOICE_NO = 57,
    /**
     * 初审
     */
    INITIAL_APPROVAL = 501,
    /**
     * 调整确认
     */
    ADJUST_CONFIRM = 502,
    /**
     * 终审
     */
    FINAL_APPROVAL = 503,
    /**
     * 调整导入
     */
    ADJUST_IMPORT = 504,
    /**
     * 新建导入
     */
    INSERT_IMPORT = 505,
    /**
     * 订单撤回
     */
    CANCEL_SO = 506,
    /**
     * 费用池调整
     */
    POOL_ADJUST = 63,
    /**
     * 日报表导出
     */
    EXPORT_DAILY = 507,
    /**
     * 订单调整（内勤）
     */
    OFFICE_ADJUST = 511,
    /**
     * 订单确认（内勤）
     */
    OFFICE_CONFIRM = 512,
    /**
     * 订单终审（内勤）
     */
    OFFICE_FINAL_APPROVAL = 513,
    /**
     * 订单创建（内勤）
     */
    OFFICE_INSERT = 514,
    /**
     * 订单导入（石家庄内勤）
     */
    OFFICE_IMPORT_SJZ = 515,
    /**
     * 订单导入（外阜内勤）
     */
    OFFICE_IMPORT = 516,
    /**
     * 订单删除（内勤）
     */
    OFFICE_DELETE = 517,
    /**
     * 订单调整（业务）
     */
    BUSINESS_ADJUST = 521,
    /**
     * 订单创建（业务）
     */
    BUSINESS_INSERT = 522,
    /**
     * 石家庄订单调整（主任）
     */
    BUSINESS_ADJUST_SJZ = 523,
    /**
     * 验单调整
     */
    VALIDATE_ADJUST = 531,
    /**
     * 验单确认
     */
    VALIDATE_CONFIRM = 532,
    /**
     * 验单撤回
     */
    VALIDATE_CANCEL = 533,
    /**
     * 验单删除
     */
    VALIDATE_DELETE = 534

    // ======================== DMS 相关OperateType end ========================
  }
}
