import {formatter, request} from '@/utils/request';

export namespace RejectReason {

  export const Path = {
    Get: '/flowapi/rejectReason/get',
    Post: '/flowapi/rejectReason/post',
    Delete: '/flowapi/rejectReason/delete',
    Query: '/flowapi/rejectReason/query',
    Search: '/flowapi/rejectReason/search',
    Export: '/flowapi/rejectReason/export'
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.Export, formatter(options));
  }

  export async function search(body?: { formTypeId: number, isNull: boolean }) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.RejectReason[]>>(Path.Search, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.RejectReason>>(Path.Get, formatter(options));
  }

  export async function post(body: API.RejectReason) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.QueryForRejectReasonData>>(Path.Query, formatter(options),);
  }
}
