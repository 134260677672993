import type {Settings as LayoutSettings} from '@ant-design/pro-layout';
import {PageLoading} from '@ant-design/pro-layout';
import {history, IRouteProps, Prompt, RunTimeLayoutConfig} from 'umi';
import RightContent, {HeaderTitleContent} from '@/components/RightContent';
import {toLayoutMenu} from '@/utils/Icon';
import {NSToken} from '@/services/identity';
import {Menus} from '@/services/basic/menu';
import {notification} from 'antd';
import {useContext, useEffect, useState} from 'react';
import WdiPromptContext, {WdiPromptContextProvider} from './components/WdiControls/WdiPromptContext';
import AccessDenyPage from './pages/403';
import {responseIsOk} from '@/utils/response';
import {BU} from "@/services/basic/bu";

const loginPath = '/user/login';
const acsPath = '/user/acs';
const dbPath = '/user/dblogin';
const outsidePath = '/user/outside/login';
const isAuthLogin = (pathname: string) => {
  if (pathname !== undefined && pathname !== null) {
    if (pathname.indexOf(loginPath) > -1 || pathname.indexOf(acsPath) > -1 || pathname.indexOf(dbPath) > -1 || pathname.indexOf(outsidePath) > -1) {
      return true;
    }
  }
  return false;
};

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading/>,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  user?: API.SysUser;
  pageMenuModel?: API.PageMenu | undefined;
  loading?: boolean;
  fetchUserInfo?: () => Promise<API.SysUser | undefined>;
  fetchPageMenuModel?: () => Promise<API.PageMenu | undefined>;
}> {
  // 获取用户信息
  const fetchUserInfo = async () => {
    try {
      const userInfo = await NSToken.getUserinfo();
      // 获取用户之后设置当前页面的BU信息，后续所有请求需要在header中绑定bu
      if (userInfo && userInfo.data) {
        NSToken.setBu(userInfo.data.buId);
        const bu = await BU.get(userInfo.data.buId)
        NSToken.setBuName(bu.data.buName)
      } else {
        NSToken.clearBu();
      }
      return userInfo.data;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };

  // 获取菜单信息
  const fetchPageMenuModel = async () => {
    try {
      const pageMenuModel = await Menus.getPageMenuModel();
      return pageMenuModel.data;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };

  // 如果是登录和鉴权页面，不执行
  if (!isAuthLogin(history.location.pathname)) {
    // 没有Token 或者Token 超时跳转到首页
    const accessToken = NSToken.accessToken();
    if (!accessToken) {
      history.push(loginPath);
      return {
        fetchUserInfo,
        fetchPageMenuModel,
        settings: {},
      };
    }

    const user = await fetchUserInfo();
    const pageMenuModel = await fetchPageMenuModel();
    return {
      fetchUserInfo,
      fetchPageMenuModel,
      user,
      pageMenuModel,
      settings: {},
    };
  }

  return {
    fetchUserInfo,
    fetchPageMenuModel,
    settings: {},
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({initialState, setInitialState}) => {
  if (initialState && initialState.settings && NSToken.getBuName()) {
    let title = NSToken.getBuName();
    if (REACT_APP_ENV === 'uat' || REACT_APP_ENV === 'uat2') {
      title += '(测试)'
    }
    initialState.settings.title = title;
  }
  return {
    // headerTitleRender(logo, title, props) {
    // },
    collapsedButtonRender: false,
    rightContentRender: () => (
      <RightContent/>
    ),
    disableContentMargin: false,
    // 禁掉水印功能
    // waterMarkProps: {
    //   content: initialState?.currentUser?.name,
    // },
    headerContentRender: (props) => <HeaderTitleContent {...props} />,
    // footerRender: () => <Footer />,
    onPageChange: () => {
      const {location} = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.user && !isAuthLogin(location.pathname)) {
        history.push(loginPath);
      }
    },
    menuDataRender: () => {
      return toLayoutMenu(initialState?.pageMenuModel?.menus);
    },
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    childrenRender: (children, props) => {
      // if (initialState?.loading) return <PageLoading />;
      return (
        <WdiPromptContextProvider>
          {children}
        </WdiPromptContextProvider>
      );
    },
    ...initialState?.settings,
  };
};

export function patchRoutes(params: { routes: IRouteProps[] }) {
  const {routes} = params;
  RoutesWraper(routes);
}

/**路由处理 */
function RoutesWraper(routes: IRouteProps[]) {
  if (routes) {
    routes.forEach(
      route => {
        if (route.path && route.exact && route.component) {
          route.component = AuthWrapper(
            route,
            route.passAuthVerify//跳过路权限由检测
          );
        } else if (route.routes) {
          RoutesWraper(route.routes);
        }
      }
    );
  }
}

/**页面权限验证组件 */
function AuthWrapper(routes: IRouteProps, passAuthVerify: boolean) {

  const {
    component: Component,
    pathAuthOwner//权限验证路径，对于一些直接打开页面的权限控制可以通过这个设置
  } = routes;
  const RouteComponent = Component as any;
  return (props: any) => {
    const promptCtx = useContext(WdiPromptContext);
    const pathname = props.location?.pathname;
    const [canAccess, setCanAccess] = useState(false);
    const [verifing, setVerifing] = useState(true);

    useEffect(
      () => {
        (
          async function () {
            if (passAuthVerify) {
              //跳过路权限由检测
              setCanAccess(true);
            } else {
              const ret = await Menus.canAccessUrl(pathAuthOwner ?? pathname);
              if (responseIsOk(ret)) {
                setCanAccess(ret.data);
              } else {
                notification.error({message: ret.message});
                setCanAccess(false);
              }
            }

            setVerifing(false);
          }
        )();
        return () => {
          promptCtx.setEnable(false);
          promptCtx.pageChange();
        }

      },
      [pathname]
    );
    if (verifing) {
      return <PageLoading/>;
    }
    if (canAccess) {
      return (
        <>
          <Prompt when={promptCtx?.enable} message={promptCtx.promptMessage()}/>
          <RouteComponent {...props}></RouteComponent>
        </>
      );
    }

    notification.error({message: "非法访问"});
    return <AccessDenyPage subTitle={`您无权查看页面“${pathname}”`}></AccessDenyPage>;
  }
}

