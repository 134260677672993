import React, { useEffect, useRef } from 'react';
import { Form, message, } from 'antd';
import { ProFormInstance } from '@ant-design/pro-form';
import { WdiInput, WdiModalAppProps } from '@/components/WdiControls';
import { HorzLayoutOpts, WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { ResponseStatus } from '@/utils/request';
import { WdiSelect } from '@/components/WdiControls/WdiSelect';
import { Enum } from '@/enums/system';
import { Supplier } from '@/services/mdm/supplier';
import { FormSp } from '@/services/sales/sp';

const PrePaymentPrevApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  useEffect(() => {

  }, [props.id])
  return (
    <WdiModalForm
      formRef={formRef}
      initialValues={{ paymentMethodName: "第三方付款" }}
      title={props.title}
      visible={props.visible}
      modalProps={{
        onCancel: () => { props.onClose ? props.onClose() : null },
        okText: '下一步'
      }}
      {...HorzLayoutOpts}
      onFinish={async (values) => {
        const { supplierId } = values;
        const entity = {
          formSaId: Number(props.id),
          paymentMethodId: Enum.PaymentMethod.THIRD_PART,
          supplierId: supplierId,
          formTypeId: Enum.FormType.ACTIVITY_PRE_PAYMENT,
          pageTypeId: Enum.PageType.PRE_PAYMENT_THIRD_PART,
          reimburseType: 2
        };
        const response: any = await FormSp.paymentPrev(entity);
        if (response.code != ResponseStatus.SUCCESS) {
          message.error(response.message);
        } else {
          if (props.onSave) {
            props.onSave(response);
          }
        }
      }}
    >
      <Form.Item
        name="supplierId"
        label="供应商"
        rules={[{ required: true, message: '供应商不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择供应商",
          remote: true,
          defaultParamKey:"supplierIds",
          showSearch: true,
          dataLoading: async (params) => {
            const response = await Supplier.ajaxSearch(Object.assign({ isActive: true }, params));
            return response.data.map((item) => { return { value: item.supplierId, label: item.codeOrName }; });
          }
        }} />
      </Form.Item>
      <Form.Item
        name="paymentMethodName"
        label="支付方式"
        rules={[{ required: true, message: '支付方式不能为空!' }]}>
        <WdiInput readOnly />
      </Form.Item>
    </WdiModalForm>
  );
};
export default PrePaymentPrevApp;
