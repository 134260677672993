import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Input, DatePicker, message, Row, Checkbox, } from 'antd';
import { ProForm, ProFormInstance } from '@ant-design/pro-form';
import { WdiModalAppResult, FieldProps, WdiCardTitle, wdiCore } from '@/components/WdiControls';
import { FormPageProps } from '@/components/WdiBus/FormPage';
import { ApplyFooter, ApplyFooterType } from '@/components/WdiBus/ApplyFooter';
import moment from 'moment';
import { FileUploadUtility, WdiFileUpload } from '@/components/WdiControls/WdiFileUpload';
import { PageType } from '@/services/basic/pagetype';
import { WdiSelect, WdiTreeSelect } from '@/components/WdiControls/WdiSelect';
import { EC } from '@/services/mdm/ec';
import { Enum } from '@/enums/system';
import { ResponseStatus } from '@/utils/request';
import { initApplyPageContext, ViewEntityProps } from '../../components/data';
import DataStore from '@/components/GridWidget/DataStore'
import FormSADetailsForApplyPanel from './details';
import SAWarningInfoApp from '../../components/WarningInformation';
import { FormSa, FormSaDetail } from '@/services/sales/sa';
import { ProjectToolTipApp } from '../../components/ProjectCmpt';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { WarningInfoAppProps } from '@/components/WdiBus/PanelForWarning';
import { ActivityType } from "@/services/mdm/ActivityType";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const getPageResult = (values: any, viewEntity: ViewEntityProps, pageType: API.PageType, statusId: number, dataSource: any[]) => {
  const result: WdiModalAppResult = { code: ResponseStatus.UNKNOWN };

  const { activityDate, fileInfo, ecId, isSpecialApprove, ...entity } = values;

  if (FileUploadUtility.isUploading(fileInfo)) {
    result.message = '附件正在上传中!';
    return result;
  }

  if (FileUploadUtility.isError(fileInfo)) {
    result.message = '附件存在错误文件!';
    return result;
  }

  const req: { form: any, formSa: any, formSaDetails: any[], ecId: number } = {
    form: {
      statusId: statusId,
      pageTypeId: pageType.pageTypeId,
      pageGroupId: pageType.pageGroupId,
      formTypeId: viewEntity.formTypeId,
      budgetProjectId: viewEntity?.budgetProject?.budgetProjectId,
      userId: viewEntity?.userId,
      positionId: viewEntity?.positionId,
      isSpecialApprove: isSpecialApprove,
    },
    formSa: {},
    formSaDetails: [],
    ecId: ecId
  };

  if (viewEntity.formId) {
    //草稿打开
    req.form.formId = viewEntity.formId;
  }
  else if (viewEntity.relatedFormId) {
    if (viewEntity.relationType === Enum.FormRelationType.REJECTED ||
      viewEntity.relationType === Enum.FormRelationType.MODIFIED) {
      // 退回待修改,修改单据
      req.form.formId = 0;
      req.form.relatedFormId = viewEntity.relatedFormId;
      req.form.relationType = viewEntity.relationType;
    }
    else if (viewEntity.relationType === Enum.FormRelationType.COPY) {
      //复制去掉相关单据
      req.form.formId = 0;
    }
    else {
      req.form.formId = 0;
    }

    entity.modifiedReason = '新建';
  }

  if (activityDate && activityDate.length == 2) {
    entity.activityStartDate = wdiCore.Date.DateToString(activityDate[0]);
    entity.activityEndDate = wdiCore.Date.DateToString(activityDate[1]);
  } else {
    result.message = "无效的活动日期";
    return result;
  }

  const info = FileUploadUtility.serialize(fileInfo);
  entity.fileName = info?.names;
  entity.filePath = info?.urls;

  req.formSa = entity;
  if (dataSource && dataSource.length) {
    dataSource.forEach((item, itemIndex) => {
      item.rowNumber = itemIndex + 1;
    });
  } else {
    result.message = "请填写申领信息!";
    return result;
  }

  req.formSaDetails = dataSource;
  result.data = req;
  result.code = ResponseStatus.SUCCESS;
  return result;
};

const FreeGoodsApplyApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const [entity, setEntity] = useState<ViewEntityProps>();
  const [pageType, setPageType] = useState<API.PageType>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [warningSettings, setWarningSettings] = useState<WarningInfoAppProps>({ visible: false });
  const [ecId, setEcId] = useState<Number>();

  const [budgetProjectId, setBudgetProjectId] = useState<number>();

  const onDetailsChange = (data: any) => {
    setDataSource(data);
  };

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApplyPageContext(params);

        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;

        if (viewEntity.form) {
          formRef.current?.setFieldsValue({
            isSpecialApprove: viewEntity.form.isSpecialApprove
          })
        }

        if (viewEntity.formSa) {
          formRef.current?.setFieldsValue({
            activityName: viewEntity.formSa.activityName,
            activityDate: [moment(viewEntity.formSa.activityStartDate), moment(viewEntity.formSa.activityEndDate)],
            remark: viewEntity.formSa.remark,
            activityTypeId: viewEntity.formSa.activityTypeId,
            fileInfo: FileUploadUtility.parse(viewEntity.formSa.fileName, viewEntity.formSa.filePath)
          });
        }

        if (viewEntity.budgetProject) {
          formRef.current?.setFieldsValue({
            project: viewEntity.budgetProject
          });
        }
        setEntity(viewEntity);

        if (viewEntity.pageTypeId) {
          PageType.get(viewEntity.pageTypeId).then((response) => {
            setPageType(response.data);
          });
        }

        if (viewEntity.budgetProjectId) {
          setBudgetProjectId(viewEntity.budgetProjectId);
        }

        //获取明细,并赋值明细第一个费用项
        if (viewEntity.formSa) {
          FormSaDetail.getFgEx(viewEntity.formSa.formSaId || 0).then((response) => {
            setDataSource(DataStore.init(response.data));

            if (response.data && response.data.length > 0) {
              setEcId(response.data[0].ecId);
              formRef.current?.setFieldsValue({
                ecId: response.data[0].ecId
              });
            }
          });
        } else {
          setDataSource(DataStore.init([]));
        }
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && pageType && entity) {
      let title = "活动申请";

      if (entity.formSa) {
        title += '【编辑】'
      }
      props.setPageTitle(title);
    }
  }, [props.setPageTitle, pageType, entity]);

  return (
    <>
      <SAWarningInfoApp {...warningSettings} />
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (
              <ApplyFooter
                setLoading={props.setLoading}
                viewEntity={entity}
                submitBackUrl="/sales/sa/myactivity"
                doSubmit={async (type: number, forceSubmit: boolean) => {
                  if (!entity || !pageType || !dataSource) {
                    return;
                  }
                  const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                  if (values && values.errorFields && values.errorFields.length) {
                    return;
                  }
                  const statusId = type === ApplyFooterType.Draft ? Enum.FormStatus.DRAFT : Enum.FormStatus.AWAITING;
                  const req = getPageResult(values, entity, pageType, statusId, dataSource);
                  if (req.code !== ResponseStatus.SUCCESS) {
                    message.error(req.message);
                    return;
                  }
                  props?.setLoading?.(true);
                  if (type === ApplyFooterType.Validate) {
                    return await FormSa.Buttons.validate(req.data);
                  } else {
                    return await FormSa.Buttons.apply({
                      forceSubmit: forceSubmit,
                      ...req.data
                    });
                  }
                }}
                del={async () => {
                  if (!entity || !entity.formId) {
                    return;
                  }
                  return await FormSa.Buttons.del(entity.formId);
                }}
                showWarning={(result: WdiModalAppResult, submitCallback: any) => {
                  setWarningSettings({
                    visible: true,
                    title: "警示信息",
                    wdiPanelList: result.data,
                    onClose: () => {
                      setWarningSettings({ visible: false });
                    },
                    onSave: async () => {
                      return await submitCallback();
                    },
                  });
                }}
              />
            )
          }
        }}
      >
        <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.BASE_INFO} title='活动基本信息' />} >
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={4}>
              <Form.Item name="activityName" label="活动名称" rules={[{ required: true, message: '活动名称不能为空!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name='activityTypeId' label='活动类型' rules={[{ required: true, message: '活动类型不能为空!' }]}>
                <WdiSelect
                  dataLoading={async (params) => {
                    const activityTypeResult = await ActivityType.search(Object.assign({
                      isActive: true, budgetProjectId: entity?.budgetProjectId,
                    }, params));

                    if (activityTypeResult.data.length > 0) {
                      formRef.current?.setFieldsValue({
                        activityTypeId: activityTypeResult.data[0]?.activityTypeId
                      })
                    };

                    return activityTypeResult.data.map((item) => ({
                      value: item.activityTypeId,
                      label: item.activityTypeName
                    }));
                  }}
                  reloadKey={entity?.budgetProjectId}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item required name="activityDate" label="活动期间" rules={[{ required: true, message: '活动期间不能为空!' }]}>
                <RangePicker />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="ecId" label="费用项" rules={[{ required: true, message: '费用项不能为空!' }]}>
                <WdiTreeSelect {...{
                  placeholder: "请选择费用项",
                  dataLoading: async (params) => {
                    const response = await EC.searchEc3(Object.assign({
                      budgetProjectId: entity?.budgetProjectId,
                      toParentLevel: Enum.ECLevel.EC2,
                      isActive: true,
                      pageTypeIds: String(Enum.PageType.FREE_GOODS_APPLICATION)
                    }, params));

                    const { treeData, dict } = FieldProps.TreeSelect.formatTreeData({
                      textField: "ecName",
                      valueField: "ecId",
                      id: "ecId",
                      pId: "parentId",
                      level: "ecLevel",
                      disabledLevel: [Enum.ECLevel.EC2],
                    }, response.data);

                    let ecList = response.data.filter(item => item?.ecLevel == Enum.ECLevel.EC3);
                    if (ecList.length == 1) {
                      setEcId(ecList[0].ecId);
                      formRef.current?.setFieldsValue({
                        ecId: ecList[0].ecId
                      });
                    }

                    return { treeData, dict };
                  },
                  onChange: (value: any, node: any, dict: any) => {
                    setEcId(value);
                  },
                  reloadKey: entity?.budgetProjectId
                }} />

              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="isSpecialApprove" valuePropName="checked" label="是否需特批" >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="remark" label="方案简述"  >
                <TextArea rows={4} maxLength={2000}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="fileInfo"
                label="附件"
                valuePropName='fileList'
                getValueFromEvent={(e: any) => { return e.fileList || []; }}
              >
                <WdiFileUpload leftTopInfo="请选择文件并上传" multiple={true} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item name="budgetProjectId" label="budgetProjectId" hidden >
                <Input />
              </Form.Item>
              <Form.Item name="project" label="专项项目">
                <ProjectToolTipApp />
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <FormSADetailsForApplyPanel
          pid={props.id}
          data={{
            formSa: {
              pageTypeId: pageType?.pageTypeId,
              budgetProjectId: budgetProjectId
            },
            ecId: ecId,
          }}
          dataSource={dataSource}
          onChange={onDetailsChange}
        />
      </ProForm>
    </>
  );
};
export default FreeGoodsApplyApp;
