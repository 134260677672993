import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Tabs, message, Badge, Checkbox, Button } from 'antd';
import ProForm, { ProFormInstance } from '@ant-design/pro-form';
import { FileUploadUtility, WdiFileList } from '@/components/WdiControls/WdiFileUpload';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import { FormPageProps, WdiFormPageTitle } from '@/components/WdiBus/FormPage';
import { ResponseStatus } from '@/utils/request';
import DataStore from '@/components/GridWidget/DataStore';
import { DataOptions } from '@/services/basic/metadata';
import { Enum } from '@/enums/system';
import { initApprovalPageContext, ViewEntityProps } from '../../RebatePaymentApply/components/data';
import DynamicInformation from './DynamicInformation';
import { RebatePaymentDetailsForApprovalPanel } from '../../RebatePaymentApply/components/detail';
import { WdiInput, WdiModalProps, WdiStaffCard, WdiTextArea } from '@/components/WdiControls';
import { SoldTo } from '@/services/mdm/soldto';
import PrePayDetail from '@/pages/sales/sp/components/PrePayDetail';

const moreConfig: any = {
  successParams: [
    {
      url: "/basicapi/favorite/getButtons",
      keys: []
    }, {
      url: "/activityapi/spButtons/get",
      keys: [
        // "UndoButton",
        "ReeditButton",
        "CancelButton",
        "InterveneFlowButton",
        "ExportLockRemoveButton",      
        "ModifyPrepayButton",  
        "ModifyInvoiceNoButton",
        "TbdButton",
        "CancelTbdButton"
      ]
    }
  ],
  errorParams: [
    {
      url: "/activityapi/spButtons/get",
      keys: [
        "RepairButton",
        "InterveneFlowButton"
      ]
    }
  ]
};

const approvalConfig: any = {
  params: {
    url: "/activityapi/spButtons/get",
    keys: [
      "ApprovalButton"
    ]
  }
};

const RebatePaymentApprovalApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [entity, setEntity] = useState<ViewEntityProps>();
  const [hiddenPrePay, setHiddenPrePay] = useState<any>(true);
  const [modalProp, setModalProp] = useState<WdiModalProps>({ visible: false });
  const [prePayParam, setPrePayParam] = useState<any>({});

  // const [warningCount, setWarningCount] = useState<number>(0);

  const onPrepayClick = (evt: any) => {
    if (evt && evt.target) {
      setModalProp({ visible: true, title: "预付信息", id: null, data: prePayParam });
    }
  };
  const onClose = () => {
    setModalProp({ visible: false, title: "", id: null });
  }

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApprovalPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;

        if (viewEntity.formSp) {
          formRef.current?.setFieldsValue({
            amountExVat: viewEntity.formSp.amountExVat,
            remark: viewEntity.formSp.remark,
            isForPrePay: viewEntity.formSp.isForPrePay,
            attachment: FileUploadUtility.parse(viewEntity.formSp.fileName, viewEntity.formSp.filePath)
          });
        }

        if (viewEntity.soldTo) {
          SoldTo.get(viewEntity.soldTo.soldToCode).then(response => {
            if (response?.data.soldToMasterCode) {
              setHiddenPrePay(false);
            }

            const currentYear = new Date().getFullYear();
            const lastYear = currentYear - 1;

            setPrePayParam({
              soldToMasterCode: response?.data.soldToMasterCode,
              prepayType: 1,
              startYear: lastYear,
              endYear: currentYear,
            });
          });
        }

        if (viewEntity.formSpDetails) {
          setDataSource(DataStore.init(viewEntity.formSpDetails));
        }

        // const formSpId = viewEntity.formSp?.formSpId ?? 0;

        // FormSp.Buttons.countOfWarning(formSpId).then(response => {
        //   setWarningCount(response.data);
        // });

        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = `合同返利付款单【${entity.form?.formNo}】`;
      DataOptions.search({ typeGroup: 3, value: entity.form?.statusId }).then(response => {
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (response.data && response.data.length > 0) {
          if (entity.form?.statusId != Enum.FormStatus.SCRAP && entity.form?.exportStatus == Enum.ExportStatus.EXPORT_SUCCESS) {
            title += `，已导出`;
          } else {
            title += `，${response.data[0].name}`;
          }
          if (props.setPageTitle) props.setPageTitle(
            <WdiFormPageTitle
              title={title}
              onClose={props.onClose}
              callback={props.callback}
              form={entity?.form}
              useCaseId={Enum.UseCase.RSP}
              moreConfig={moreConfig}
              approvalConfig={approvalConfig} />
          );
        }
      });
    }
  }, [props.setPageTitle, entity]);

  return (
    <>

      <WdiStaffCard formId={entity?.form?.formId} />
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab={<span>付款信息</span>} key="1">
          <ProForm
            formRef={formRef}
            submitter={{
              render: () => { return (<></>) },
            }}
          >
            <Row gutter={[16, 24]}>
              <Col className="gutter-row" span={4}>
                <Form.Item name="amountExVat" label="本次付款总金额" >
                  <WdiInput readOnly />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={3} hidden={hiddenPrePay}>
                <Form.Item name="isForPrePay" label="是否冲销预付" valuePropName="checked">
                  <Checkbox disabled={true} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4} hidden={hiddenPrePay}>
                <Button type="link" style={{ marginTop: -7 }}
                  onClick={onPrepayClick}>预付信息</Button>
              </Col>
            </Row>
            <Row gutter={[16, 24]}>
              <Col className="gutter-row" span={16}>
                <Form.Item name="remark" label="付款备注" >
                  <WdiTextArea rows={4} readOnly />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  name="attachment"
                  label="核销附件"
                  valuePropName='fileList'
                  getValueFromEvent={(e: any) => { return e.fileList || []; }}
                >
                  <WdiFileList />
                </Form.Item>
              </Col>
            </Row>
          </ProForm>
          <RebatePaymentDetailsForApprovalPanel dataSource={dataSource} />
        </TabPane>
        {/* <TabPane
          tab={
            <>
              <span>警示信息</span>
              <Badge count={warningCount}
                style={{ color: "black", boxShadow: "none", background: "none", top: "-20px", right: "-15px", position: 'absolute' }}>
              </Badge>
            </>
          }
          key="2"
        >
          <SPWarningApprovalApp formId={entity?.form?.formId} />
        </TabPane> */}
        <TabPane tab={<span>动态信息</span>} key="3">
          <DynamicInformation formId={entity?.form?.formId} formTypeId={entity?.form?.formTypeId} pageTypeId={entity?.form?.pageTypeId} useCaseId={Enum.UseCase.RSP} />
        </TabPane>
      </Tabs>
      {modalProp.visible &&
        <PrePayDetail
          {...modalProp}
          onClose={onClose}
        />}

    </>
  );
};
export default RebatePaymentApprovalApp;
