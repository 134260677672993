import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Tabs, message } from 'antd';
import ProForm, { ProFormInstance } from '@ant-design/pro-form';
import { BOHQ } from '@/services/mdm/bohq';
import { FileUploadUtility, WdiFileList } from '@/components/WdiControls/WdiFileUpload';
import { FormBudgetProjectDetail } from '@/services/sales/bp/formBudgetProject';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import DynamicInformation from '../../components/DynamicInformation';
import BudgetInformation from '../../components/BudgetInformation';
import { FormPageProps, WdiFormPageTitle } from '@/components/WdiBus/FormPage';
import { initApprovalPageContext, ViewEntityProps } from '../../components/data';
import { ResponseStatus } from '@/utils/request';
import DataStore from '@/components/GridWidget/DataStore';
import { DataOptions } from '@/services/basic/metadata';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { WdiCardTitle, wdiCore, WdiInput, WdiStaffCard, WdiTextArea } from '@/components/WdiControls';
import { FormBPDetailsForApprovalPanel } from '../../GeneralProjectApply/components/details';
import { EC } from '@/services/mdm/ec';
import { Product } from '@/services/mdm/product';
import ApproverUserApp, { ApproverUserIds } from '../../components/ApproverUser';
import { Enum } from '@/enums/system';
import { Channel } from '@/services/mdm/channel';
import { ShopLevel } from '@/services/mdm/shoplevel';
import { SoldTo } from '@/services/mdm/soldto';

const moreConfig: any = {
  successParams: [
    {
      url: "/basicapi/favorite/getButtons",
      keys: []
    }, {
      url: "/projectapi/buttons/get",
      keys: [
        "UndoButton",
        "ReeditButton",
        "InterveneFlowButton"
      ]
    }
  ],
  errorParams: [
    {
      url: "/projectapi/buttons/get",
      keys: [
        "RepairButton",
        "InterveneFlowButton"
      ]
    }
  ]
};

const approvalConfig: any = {
  params: {
    url: "/projectapi/buttons/get",
    keys: [
      "ApprovalButton"
    ]
  }
};

const FormBudgetProjectApprovalApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [entity, setEntity] = useState<ViewEntityProps>();
  const useCaseId = Enum.UseCase.BUDGET_PROJECT;

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApprovalPageContext(params);

        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;
        const { formBudgetProject } = viewEntity;
        if (viewEntity.formBudgetProject) {
          formRef.current?.setFieldsValue({
            projectName: formBudgetProject?.projectName,
            projectCode: formBudgetProject?.projectCode,
            projectDate: wdiCore.Date.Join(formBudgetProject?.projectStartDate, formBudgetProject?.projectEndDate),
            totalBudget: wdiCore.Number.AmountToString(formBudgetProject?.totalBudget),
            projectRemark: formBudgetProject?.projectRemark,
            fileInfo: FileUploadUtility.parse(formBudgetProject?.fileName, formBudgetProject?.filePath),
            applyDate: wdiCore.Date.Join(formBudgetProject?.applyStartDate, formBudgetProject?.applyEndDate),
            modifiedReason: viewEntity.formBudgetProject.modifiedReason,
          });
        }
        setEntity(viewEntity);

        if (formBudgetProject?.bohqId) {
          BOHQ.get(formBudgetProject.bohqId).then((response) => {
            formRef.current?.setFieldsValue({
              bohqName: response.data.bohqName
            });
          });
        }
        if (formBudgetProject?.projectApproverIds) {
          ApproverUserIds.fetchUserForDataSource(formBudgetProject?.projectApproverIds, (data) => {
            formRef.current?.setFieldsValue({
              projectApproverIds: data
            });
          });
        }

        if (formBudgetProject?.limitedChannelIds) {
          Channel.search({ channelIds: formBudgetProject.limitedChannelIds }).then(response => {
            if (response.data && response.data.length > 0) {
              formRef.current?.setFieldsValue({
                limitedChannelIds: response.data.map(channel => channel.codeOrName).join()
              });
            }
          });
        }

        if (formBudgetProject?.limitedEcIds) {
          EC.search({ ecIds: formBudgetProject.limitedEcIds }).then((response) => {
            formRef.current?.setFieldsValue({
              limitedEcIds: response.data.map(item => item.ecName).join()
            });
          });
        }
        if (formBudgetProject?.limitedProductIds) {
          Product.searchAll({ productIds: formBudgetProject.limitedProductIds }).then((response) => {
            formRef.current?.setFieldsValue({
              limitedProductIds: response.data.map(item => item.productName).join()
            });
          });
        }
        if (formBudgetProject?.limitedShopLevelIds) {
          ShopLevel.search({ shopLevelIds: formBudgetProject.limitedShopLevelIds }).then(response => {
            if (response.data && response.data.length > 0) {
              formRef.current?.setFieldsValue({
                limitedShopLevelIds: response.data.map(shopLevel => shopLevel.codeOrName).join()
              });
            }
          });
        }

        if (formBudgetProject?.limitedSoldToCodes) {
          SoldTo.search({ soldToCodes: formBudgetProject?.limitedSoldToCodes }).then(response => {
            if (response.data && response.data.length > 0) {
              formRef.current?.setFieldsValue({
                limitedSoldToCodes: response.data.map(soldTo => soldTo.codeOrName).join()
              });
            }
          });
        }

        if (formBudgetProject) {
          FormBudgetProjectDetail.query(formBudgetProject?.formBudgetProjectId, formBudgetProject?.buId).then((response) => {
            setDataSource(DataStore.init(response.data));
          });
        }
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = `专项项目${entity.form?.relationType == Enum.FormRelationType.MODIFIED ? '修改' : '创建'}申请单【${entity.form?.formNo}】`;
      DataOptions.search({ typeGroup: 3, value: entity.form?.statusId }).then(response => {
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (response.data && response.data.length > 0) {
          title += `，${response.data[0].name}`;
          if (props.setPageTitle) props.setPageTitle(
            <WdiFormPageTitle
              title={title}
              onClose={props.onClose}
              callback={props.callback}
              form={entity?.form}
              useCaseId={Enum.UseCase.BUDGET_PROJECT}
              moreConfig={moreConfig}
              approvalConfig={approvalConfig} />
          );
        }
      });
    }
  }, [props.setPageTitle, entity]);

  return (
    <>
      <WdiStaffCard formId={entity?.form?.formId} />
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab={<span>资料</span>} key="1">
          <ProForm
            formRef={formRef}
            submitter={{
              render: () => { return (<></>) },
            }}
          >
            <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.BASE_INFO} title='项目基本信息' />} collapsed={true} >
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="projectName" label="项目名称">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="projectCode" label="项目编号" >
                    <WdiInput readOnly style={entity?.formBudgetProject?.budgetProjectId ? { color: "#2299ee", cursor: "pointer" } : {}} onClick={() => {
                      if (entity?.formBudgetProject?.budgetProjectId) {
                        window.open(`/project/budgetprojectdisplay?budgetProjectId=${entity?.formBudgetProject?.budgetProjectId}`);
                      }
                    }} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="projectDate" label="项目日期" >
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="totalBudget" label="项目总额" >
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="bohqName" label="费用归属" >
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={4} hidden>
                  <Form.Item name="projectApproverIds" label="附加审批人">
                    <ApproverUserApp readOnly={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={16}>
                  <Form.Item name="projectRemark" label="方案简述">
                    <WdiTextArea rows={4} readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    name="fileInfo"
                    label="附件"
                    valuePropName='fileList'
                    getValueFromEvent={(e: any) => { return e.fileList || []; }}
                  >
                    <WdiFileList fileList={FileUploadUtility.parse(entity?.formBudgetProject?.fileName, entity?.formBudgetProject?.filePath)} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={16} hidden={entity?.form?.relationType == Enum.FormRelationType.MODIFIED ? false : true}>
                  <Form.Item name="modifiedReason" label="修改原因" >
                    <WdiTextArea rows={4} readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </WdiPanel>
            <WdiPanel
              collapsed={true}
              title={<WdiCardTitle iconType={Enum.IconType.LIMITED_INFO} title='维度信息' />}
            >
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="applyDate" label="活动申请时间限制">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="limitedChannelIds" label="渠道类型维度">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="limitedEcIds" label="费用项维度">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="limitedProductIds" label="产品别维度">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="limitedShopLevelIds" label="门店等级维度">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item name="limitedSoldToCodes" label="售达方维度">
                    <WdiInput readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </WdiPanel>
          </ProForm>
          <FormBPDetailsForApprovalPanel dataSource={dataSource} />
        </TabPane>
        <TabPane tab={<span>预算信息</span>} key="2">
          <BudgetInformation formId={entity?.form?.formId} />
        </TabPane>
        <TabPane tab={<span>动态信息</span>} key="3">
          <DynamicInformation
            formId={entity?.form?.formId}
            formTypeId={entity?.form?.formTypeId}
            pageTypeId={entity?.form?.pageTypeId}
            useCaseId={useCaseId}
          />
        </TabPane>
      </Tabs>
    </>
  );
};
export default FormBudgetProjectApprovalApp;
