import {WdiModalAppResult} from "@/components/WdiControls";
import {DownOutlined} from "@ant-design/icons";
import {Dropdown, Menu, message, Space} from "antd";
import {useEffect, useState} from "react";
import PageControl, {ModuleType, PageMode} from '@/components/WdiBus/FormPage';
import {Position} from "@/services/basic/position";
import {Enum} from "@/enums/system";
import {ResponseStatus} from "@/utils/request";
import {SalesContract} from "@/services/contract/salescontract";

type MoreWapperProps = {
  salesContractId?: number;
  contractType: number;
  isActive?: boolean;
  statusId?: number;
  onClose?: (result?: WdiModalAppResult) => void;
  callback?: (result?: WdiModalAppResult) => void;
};
const UpdateApplyButton: React.FC<MoreWapperProps> = (props: MoreWapperProps) => {
  const {salesContractId, onClose} = props;

  const handleClick = (e: any) => {
    if (onClose) {
      onClose();
      PageControl.apply({
        mode: PageMode.Apply,
        moduleType: ModuleType.Contract,
        data: {salesContractId: salesContractId}
      });
    }
    // else {
    //   window.open(`/contract/SalesContract/SalesContractApply/?SalesContractID=${salesContractID}`);
    // }
  }
  return (
    <>
      <div onClick={handleClick}>
        编辑
      </div>
    </>
  )
}
const DeactiveButton: React.FC<MoreWapperProps> = (props: MoreWapperProps) => {
  const {salesContractId} = props;
  const handleClick = (e: any) => {
    if (window.confirm('合同失效后不会再生成此合同的返利，且无效后不可撤销，确认吗？')) {
      SalesContract.deactive(salesContractId || 0).then(response => {
        if (response.code == ResponseStatus.SUCCESS) {
          message.success("操作成功！");
          window.location.reload();
          // if (onClose) {
          //   PageControl.show({
          //     mode: PageMode.Approval,
          //     moduleType: ModuleType.Contract,
          //     data: {salesContractId: salesContractId}
          //   });
          //   onClose();
          // } else {
          //   window.location.reload();
          // }
        } else {
          message.error(response.message);
        }
      });
    }
  }
  return (
    <div onClick={handleClick}>
      合同无效
    </div>
  )
}
const ConfirmButton: React.FC<MoreWapperProps> = (props: MoreWapperProps) => {
  const {salesContractId} = props;
  const handleClick = (e: any) => {
    if (window.confirm('确认后合同会变为生效，确定吗？')) {
      SalesContract.confirm(salesContractId || 0).then(response => {
        if (response.code == ResponseStatus.SUCCESS) {
          message.success("操作成功！");
          window.location.reload();
        } else {
          message.error(response.message);
        }
      });
    }
  }
  return (
    <div onClick={handleClick}>
      合同确认
    </div>
  )
}
export const SalesContractMoreWapper: React.FC<MoreWapperProps> = (props: MoreWapperProps) => {
  const [menuItems, setMenuItems] = useState<any[]>([]);

  useEffect(() => {
    const newMenuItems: any[] = [];
    var useCaseId = props.contractType == Enum.SalesContractType.SELLIN ? Enum.UseCase.SALES_CONTRACT : Enum.UseCase.SALES_CONTRACT_INDIRECT;
    Position.access({
      useCaseId: useCaseId,
      operateTypeIds: [Enum.OperateType.MAINTAIN]
    }).then(response => {
      if (response.code == ResponseStatus.SUCCESS && response.data == true) {
        if (response.data && props.isActive) {
          newMenuItems.push({
            label: (
              <UpdateApplyButton {...props} />
            ),
            key: "UpdateApply"
          })
          newMenuItems.push({
            label: (
              <DeactiveButton {...props} />
            ),
            key: "Deactive"
          });
          if (props.statusId == Enum.SalesContractStatus.UNCONFIRMED) {
            newMenuItems.push({
              label: (
                <ConfirmButton {...props} />
              ),
              key: "Confirm"
            });
          }
        }
        setMenuItems(newMenuItems);
      }
    })
  }, [props.contractType]);
  // useEffect(() => {
  //   if (props.salesContractID && allowMaintain) {
  //   //   BudgetProject.Buttons.get(props.salesContractID).then(response => {
  //   //     const buttonAccess = response.Data;

  //       const newMenuItems: any[] = [];
  //       if (allowMaintain) {
  //         newMenuItems.push({
  //           label: (
  //             <UpdateApplyButton {...props} />
  //           ),
  //           key: "UpdateApply"
  //         })
  //       }
  //       // if (buttonAccess.AllowClosed) {
  //         // newMenuItems.push({
  //         //   label: (
  //         //     <DeleteButton {...props} />
  //         //   ),
  //         //   key: "Delete"
  //         // })
  //       // }
  //       setMenuItems(newMenuItems);
  //   //   })
  //   }
  // }, [props.salesContractID]);

  return (
    <>
      {menuItems && menuItems.length > 0 &&
        <Dropdown overlay={
          <Menu
            items={menuItems}
          />
        }>
          <a onClick={e => e.preventDefault()}>
            <Space>
              More <DownOutlined/>
            </Space>
          </a>
        </Dropdown>
      }
    </>
  )
}
