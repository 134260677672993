import { request, formatter } from '@/utils/request';

export namespace Dept {
  export const Path = {
    Get: '/basicapi/dept/get',
    Search: '/basicapi/dept/search',
    Move: '/basicapi/dept/move',
    Post: '/basicapi/dept/post',
    Delete: '/basicapi/dept/delete',
    GetDeptNames:'/basicapi/dept/getDeptNames'
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.Dept>>(Path.Get, formatter(options));
  }

  export async function search(body: API.DeptSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.Dept[]>>(Path.Search, formatter(options));
  }

  export async function move(body: API.DeptMoveReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Move, formatter(options));
  }

  export async function post(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function getDeptNames(deptId: number) {
    const options = {
      method: 'GET',
      body: {
        deptId: deptId,
      },
    };
    return request<API.ResponseResult<string>>(Path.GetDeptNames, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        deptId: id,
      },
    };
    return request<API.ResponseResult<API.Dept>>(Path.Delete, formatter(options));
  }





  //BasicApi/Staff/QueryForDept
  export async function queryStaffForDept(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        sortdatafield: 'staffName',
        sortorder: 'ASC',
        ...body,
      },
    };
    return request<API.QueryResult<API.QueryForDeptData>>(
      '/basicapi/staff/queryForDept',
      formatter(options),
    );
  }
}
