import {formatter, request} from "@/utils/request";

export namespace Pool {
  export const Path = {
    QueryPoolRemain: '/activityapi/pool/queryPoolRemain',
    QueryPoolBalance: '/activityapi/pool/queryPoolBalance',
    QueryPoolPrepay: '/activityapi/pool/queryPoolPrepay',
    GetPoolPrepays: '/activityapi/pool/getPoolPrepays',
    GetByPoolPrepayReverse: '/activityapi/pool/getByPoolPrepayReverse',
  }

  export async function queryPoolRemain(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.PoolRemainQuery[]>>(Path.QueryPoolRemain, formatter(options));
  }

  export async function queryPoolPrepay(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.PoolPrepayQuery[]>>(Path.QueryPoolPrepay, formatter(options));
  }

  export async function getPoolPrepays(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.PoolPrepayQuery[]>>(Path.GetPoolPrepays, formatter(options));
  }


  export async function queryPoolBalance(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.PoolRemainQuery[]>>(Path.QueryPoolBalance, formatter(options));
  }

  export async function get(id: Number) {
    const options = {
      method: 'GET',
      body: {
        poolPrepayReverseId: id
      },
    };
    return request<API.ResponseResult<any>>(Path.GetByPoolPrepayReverse, formatter(options));
  }
}
