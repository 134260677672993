import { useEffect, useState } from 'react';
import { DetailGrid, } from '@/components/GridWidget';
import { NsGrid } from '@/components/GridWidget/GridDefProps';
import { WdiCardTitle } from '@/components/WdiControls';
import { DetailForApplyProps } from './details';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { Enum } from '@/enums/system';
import { GridApi } from '@/services/gridapi';
import { Staff } from '@/services/basic/staff';
import { SimpleStaff } from '@/services/basic/simplestaff';

const SalesContractHistoryForPanel: React.FC<DetailForApplyProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});

  const { dataSource } = props;
  const showDispModal = (evt: any) => {
    if (evt && evt.target) {
      const dataKey = evt.target.getAttribute("data-key");
      // const record = JSON.parse(evt.target.getAttribute("data-record"));
      window.open('/contract/SalesContract/salescontracthistory/' + `?salesContractHistoryId=${dataKey}`);
    }
  };

  useEffect(() => {
    Promise.all([
      GridApi.searchConfig({ id: 'SalesContractHistory', allowModifyColumns: false })
      ,SimpleStaff.search({})
    ]).then((dataArray: any[]) => {
      const settings: any = Object.assign({}, dataArray[0].data, {
        rowKey: 'uid',
        actionsAlign: NsGrid.ActionsAlign.None,
      });
      const staffAll= dataArray[1].data;

      settings.formSchema.allFields.modifiedDate.getDisplayFormater = (text: any, record: any, index: any) => {
        return (
          <a onClick={showDispModal} data-key={record.salesContractHistoryId} data-record={JSON.stringify(record)}>
            {text}
          </a>
        )
      };
      settings.formSchema.allFields.modifiedUserId.getDisplayFormater = (text: any, record: any, index: any) => {
        var modifiedUserName="";
        staffAll.forEach((item:any, index: any) => {
           if(item.staffId==record.modifiedUserId){
            modifiedUserName=item.staffName;
           }
        })
        return modifiedUserName;
      };
      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    pagination: false
  };


  return (
    <WdiPanel collapsed={true} title={<WdiCardTitle iconType={Enum.IconType.HISTORY} title='合同变更历史' />}>
      {
        isLoaded &&
        <DetailGrid {...pageGridSettings} />
      }
    </WdiPanel >
  );
};

export default SalesContractHistoryForPanel;
