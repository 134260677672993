import { formatter, request } from '@/utils/request';

export namespace PageType {

  export const Path = {
    Get: '/basicapi/pageType/get',
    Search: '/basicapi/pageType/search',
    Post: '/basicapi/pageType/post',
    Query: '/basicapi/pageType/query'
  }

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }

  export async function search(body: API.PageTypeFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.PageType[]>>(Path.Search, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<API.PageType>>(Path.Get, formatter(options));
  }

  export async function post(body: API.PageType) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }
}
