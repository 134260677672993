import React, { useEffect, useRef, useState } from 'react';
import { FieldProps } from './FieldProps';
import { wdiCore } from './wdiCore';
import { Button, Col, Descriptions, Input, InputProps, message, Popover } from 'antd';
import { FormApi } from '@/services/sales';
import { ResponseStatus } from '@/utils/request';
import { getFormUrlMapping } from '../WdiBus/FormPage';
import { Staff } from '@/services/basic/staff';
import { WdiIcon } from './WdiIcon';
import { Position } from '@/services/basic/position';
import { Enum } from '@/enums/system';
import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import { Dept } from '@/services/basic/dept';
import { FormattedMessage } from 'umi';
import { WdiModal } from './WdiModalForm';

export { FieldProps, wdiCore };

export const WdiModalAppTypeEnums = {
  None: 0,
  Insert: 1,
  Edit: 2,
  Delete: 3,
};

export type WdiModalAppResult = {
  code: number;
  message?: string | null;
  data?: any;
};

export type WdiModalProps = {
  title?: string | null;
  visible: boolean;
  id?: number | string | null;
  type?: number;
  data?: any;
};

export type WdiModalAppProps = {
  onClose?: (result?: WdiModalAppResult) => void;
  onSave?: (result?: WdiModalAppResult) => void;
} & WdiModalProps;

const WdiCardTitle: React.FC<{ title: string; iconType?: string; }> = (props: any) => {
  return (
    <span>
      <span style={{ paddingRight: 5 }}>{props?.iconType ? <WdiIcon type={props?.iconType} /> : <WdiIcon type={Enum.IconType.BASE_INFO} />}</span>
      {props.title}
    </span>
  )
};

type WdiFormCardTitleProps = {
  title: string;
  iconType?: string;
  form?: API.Form;
  isJump?: boolean;
};

const WdiFormCardTitle: React.FC<WdiFormCardTitleProps> = (props: any) => {
  const showDispModal = (evt: any) => {
    if (evt && evt.target) {
      const dataKey = evt.target.getAttribute("data-key");
      FormApi.get(dataKey).then(response => {
        if (response.code === ResponseStatus.SUCCESS) {
          const form = response.data;
          FormApi.doesAccess(form.formId).then(response => {
            if (response.code == ResponseStatus.SUCCESS && response.data) {
              window.open(getFormUrlMapping(form.pageTypeId, form.formTypeId) + `?formId=${form.formId}&pageTypeId=${form.pageTypeId}&formTypeId=${form.formTypeId}`);
            } else {
              message.error("没有权限查看单据");
            }
          })
        }
      });
    }
  };
  return (
    <span>
      <span style={{ paddingRight: 5 }}>{props?.iconType ? <WdiIcon type={props?.iconType} /> : <WdiIcon type={Enum.IconType.BASE_INFO} />}</span>
      {props.title}
      {props.form ? props.isJump ? (<a onClick={showDispModal} data-key={props.form?.formId}>{'【' + props.form?.formNo + '】'} </a>) : '【' + props.form?.formNo + '】' : ""}
    </span>
  )
};

const WdiCardHeader: React.FC<{ title: string; }> = (props: any) => {
  return (
    <Col className="gutter-row" span={24} style={{ height: '35px', borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: '#d9d9d9' }}>
      <WdiCardTitle title={props.title} />
      {props.children}
    </Col>
  )
};

const WdiStaffCard: React.FC<{ formId?: number; }> = (props: any) => {
  const [form, setForm] = useState<API.Form>();
  const [staffCard, setStaffCard] = useState<API.StaffCardResult>();
  const [position, setPosition] = useState<API.Position>();
  const [deptNames, setDeptNames] = useState<string>("");
  useEffect(() => {
    if (props.formId) {
      FormApi.get(props.formId).then(response => {
        if (response.code == ResponseStatus.SUCCESS) {
          setForm(response.data)
          Staff.staffCardInfo({ userId: response.data.userId, proxyUserId: response.data.proxyUserId }).then(staffResponse => {
            if (response.code == ResponseStatus.SUCCESS) {
              setStaffCard(staffResponse.data);
            }
          })
          Position.get(response.data.positionId).then(positionResponse => {
            setPosition(positionResponse.data);
          })
          Dept.getDeptNames(response.data.deptId).then(positionResponse => {
            setDeptNames(positionResponse.data);
          })
        }
      })
    }
  }, [props.formId])

  const userFormatter = (name?: string, alias?: string) => {
    var resultString = name;
    if (alias) {
      resultString += " (" + alias + ")";
    }
    return resultString;
  }
  return (
    <Popover
      trigger="hover"
      placement="bottomRight"
      overlayInnerStyle={{ width: 400 }}
      content={
        <Descriptions column={1} labelStyle={{ width: 100 }}>
          <Descriptions.Item label="申请人">{userFormatter(staffCard?.user.staffName, staffCard?.user.staffAlias)}</Descriptions.Item>
          {staffCard?.proxyUser && <Descriptions.Item label="制单人">{userFormatter(staffCard?.proxyUser.staffName, staffCard?.proxyUser.staffAlias)}</Descriptions.Item>}
          <Descriptions.Item label="职务">{position?.positionName || "（空）"}</Descriptions.Item>
          <Descriptions.Item label="部门">{deptNames}</Descriptions.Item>
          <Descriptions.Item label="员工工号">{staffCard?.user.staffNo || "（空）"}</Descriptions.Item>
          <Descriptions.Item label="入职日期">{wdiCore.Date.DateToString(staffCard?.user.attendDate)}</Descriptions.Item>
          <Descriptions.Item label="邮箱">{staffCard?.user.email || "（空）"}</Descriptions.Item>
          <Descriptions.Item label="提交日期">{wdiCore.Date.SecondToString(form?.submitDate)}</Descriptions.Item>
        </Descriptions>
      }>
      <div style={{ position: "absolute", right: 35, top: 10, zIndex: 100000, border: 0, cursor: 'pointer' }}>
        <Button style={{
          position: 'relative', top: -8, pointerEvents: 'none', border: 0, boxShadow: 'none'
        }}>
          申请人：{staffCard?.user.staffName}
        </Button>
        <WdiIcon type={Enum.IconType.STAFF_CARD} />
      </div>
    </Popover >
  )
};

const WdiInput: React.FC<InputProps> = (props: any) => {
  const ref: any = useRef(null);
  return (
    <div ref={ref}>
      {props.value && props.value.length > 10
        ? <Popover placement="topLeft" content={<div style={{ maxWidth: 250 }}>{props.value}</div>}>
          <Input {...props} />
        </Popover>
        : <Input {...props} title={props.value} />}
    </div>
  )
};

const WdiTextArea: React.FC<TextAreaProps> = (props: any) => {
  const ref: any = useRef(null);
  const [visible, setVisible] = useState(false);
  return (
    <div ref={ref}>
      <WdiModal
        visible={visible}
        width={1000}
        bodyStyle={{ whiteSpace: "pre-line", maxWidth: 990 }}
        onCancel={() => { setVisible(false) }}
        footer={false}
        maskClosable={true}
        closeIcon={""}
      >
        {props.value}
      </WdiModal>
      <TextArea {...props} onClick={() => { if (props.value && props.value.length > 100) { setVisible(true); } }} />
    </div>
  )
};

type LangLabelProps = {
  resKey: string;
  defaultMessage?: string,
  values?: Record<string, any>;
}
const LangLabel: React.FC<LangLabelProps> = (props: LangLabelProps) => {
  return (
    <FormattedMessage id={props.resKey} defaultMessage={props.defaultMessage} values={props.values} />
  )
}

export { WdiCardTitle, WdiFormCardTitle, WdiCardHeader, WdiStaffCard, WdiInput, WdiTextArea, LangLabel };
