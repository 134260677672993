import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Input, message, Row } from 'antd';
import ProForm, { ProFormInstance } from '@ant-design/pro-form';
import { FileUploadUtility, WdiFileUpload } from '@/components/WdiControls/WdiFileUpload';
import { PageType } from '@/services/basic/pagetype';
import { Enum } from '@/enums/system';
import { ResponseStatus } from '@/utils/request';
import { FormPageProps } from '@/components/WdiBus/FormPage';
import DataStore from '@/components/GridWidget/DataStore';
import { ApplyFooter, ApplyFooterType } from '@/components/WdiBus/ApplyFooter';
import { WdiModalAppResult, WdiModalProps } from '@/components/WdiControls';
import RebatePaymentDetailsForApplyPanel from './detail';
import { initApplyPageContext, ViewEntityProps } from './data';
import { FormSp } from '@/services/sales/sp';
import SPWarningInfoApp from '@/pages/sales/sp/components/WarningInformation';
import { WarningInfoAppProps } from '@/components/WdiBus/PanelForWarning';
import PrePayDetail from '@/pages/sales/sp/components/PrePayDetail';

const { TextArea } = Input;

const getPageResult = (values: any, viewEntity: ViewEntityProps, pageType: API.PageType, statusId: number, dataSource: any[]) => {
  const result: WdiModalAppResult = { code: ResponseStatus.UNKNOWN };

  const { fileInfo, ...entity } = values;
  if (FileUploadUtility.isUploading(fileInfo)) {
    result.message = '附件正在上传中!';
    return result;
  }
  if (FileUploadUtility.isError(fileInfo)) {
    result.message = '附件存在错误文件!';
    return result;
  }
  const req: { batchFormSp: any, formSpDetails: any[] } = {
    batchFormSp: {
      statusId: statusId,
      pageTypeId: pageType.pageTypeId,
      pageGroupId: pageType.pageGroupId,
      formTypeId: viewEntity.formTypeId,
      userId: viewEntity.userId,
      positionId: viewEntity.positionId,
      deptId: viewEntity.deptId
    },   
    formSpDetails: []   
  };

  if (viewEntity.formSp) {
    if (viewEntity.relationType === Enum.FormRelationType.REJECTED) {
      // 退回待修改
      req.batchFormSp.relatedFormId = viewEntity.relatedFormId;
      req.batchFormSp.relationType = viewEntity.relationType;
    } else {
      // 从草稿打开
      req.batchFormSp.formId = viewEntity.formId;
    }
  }

  // entity.modifiedReason = '新建';
  // entity.reimburseType = viewEntity.reimburseType;

  const info = FileUploadUtility.serialize(fileInfo);
  req.batchFormSp.fileName = info?.names;
  req.batchFormSp.filePath = info?.urls;
  // req.formSp = entity;

  if (!dataSource || dataSource.length == 0) {
    result.message = '请填写付款明细信息!';
    return result;
  }
  dataSource.forEach((item, itemIndex) => {
    item.rowNumber = itemIndex + 1;
  });
  req.formSpDetails = dataSource;

  for (let i = 0; i < req.formSpDetails.length; i++) {
    const detailRow = req.formSpDetails[i];
    detailRow.formSpDetailId = detailRow.formSpDetailId || 0;
    detailRow.formSpId = detailRow.formSpId || 0;
    detailRow.amountInVat = detailRow.amountInVat ? Number(detailRow.amountInVat) : 0;
    detailRow.amountExVat = detailRow.amountExVat ? Number(detailRow.amountExVat) : 0;
    detailRow.tax = detailRow.tax ? Number(detailRow.tax) : 0;

    // 账扣/电汇/挂账余则必填发票类型、税率类型
    if ((detailRow.paymentMethodId == Enum.PaymentMethod.ACCOUNT_DEDUCT ||
      detailRow.paymentMethodId == Enum.PaymentMethod.THIRD_PART ||
      detailRow.paymentMethodId == Enum.PaymentMethod.TRANSFER_FUND_POOL) &&
      (detailRow.invoiceType == null || detailRow.vatTypeId == null)) {
      result.message = '支付方式账扣/电汇/挂账余则发票类型、税率类型必填!';
      return result;
    }
  }

  result.data = req;
  result.code = ResponseStatus.SUCCESS;
  return result;
}

const RebatePaymentApplyApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const [entity, setEntity] = useState<ViewEntityProps>();
  const [pageType, setPageType] = useState<API.PageType>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [warningSettings, setWarningSettings] = useState<WarningInfoAppProps>({ visible: false });
  const [modalProp, setModalProp] = useState<WdiModalProps>({ visible: false });



  const onDetailsChange = (data: any) => {
    setDataSource(data);
  };


  const onClose = () => {
    setModalProp({ visible: false, title: "", id: null });
  }


  useEffect(() => {
    (async () => {
      const params = props.data;

      if (params) {
        const result = await initApplyPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;

        if (viewEntity.formSp) {
          formRef.current?.setFieldsValue({
            remark: viewEntity.formSp.remark,
            fileInfo: FileUploadUtility.parse(viewEntity.formSp.fileName, viewEntity.formSp.filePath)
          });
        }

        if (viewEntity.pageTypeId) {
          PageType.get(viewEntity.pageTypeId).then((response) => {
            setPageType(response.data);
          });
        }

        if (viewEntity.formSpDetails) {
          setDataSource(DataStore.init(viewEntity.formSpDetails));
        }

        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && pageType && entity) {
      let title = "合同返利费用付款申请";
      if (entity.formSp) {
        title += '【编辑】'
      }
      props.setPageTitle(title);
    }
  }, [props.setPageTitle, pageType, entity]);

  return (
    <>
      <SPWarningInfoApp {...warningSettings} />
      <ProForm
        formRef={formRef}
        initialValues={{ invoiceStatus: Enum.InvoiceStatus.NON_INVOICE }}
        submitter={{
          render: () => {
            return (
              <ApplyFooter
                setLoading={props.setLoading}
                hiddenButton={[ApplyFooterType.Validate, ApplyFooterType.Draft, ApplyFooterType.Delete]}
                viewEntity={entity}
                submitBackUrl="/contract/myrebateinfopayment"
                doSubmit={async (type: number, forceSubmit: boolean) => {
                  if (!entity || !pageType) {
                    return;
                  }
                  const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                  if (values && values.errorFields && values.errorFields.length) {
                    return;
                  }
                  const statusId = type === ApplyFooterType.Draft ? Enum.FormStatus.DRAFT : Enum.FormStatus.AWAITING;
                  const req = getPageResult(values, entity, pageType, statusId, dataSource);
                  if (req.code !== ResponseStatus.SUCCESS) {
                    message.error(req.message);
                    return;
                  }
                  props?.setLoading?.(true);
                  return await FormSp.Buttons.batchApply({
                    forceSubmit: forceSubmit,
                    ...req.data
                  });
                }}
                showWarning={(result: WdiModalAppResult, submitCallback: any) => {
                  setWarningSettings({
                    visible: true,
                    title: '警示信息',
                    wdiPanelList: result.data,
                    onClose: () => {
                      setWarningSettings({ visible: false });
                    },
                    onSave: async () => {
                      return await submitCallback();
                    },
                  });
                }}
                del={async () => {
                  if (!entity || !entity.formId) {
                    return;
                  }
                  return await FormSp.Buttons.del(entity.formId);
                }}
              />
            )
          }
        }}
      >
        <>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16}>
              <Form.Item name="remark" label="付款备注">
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="fileInfo"
                label="附件"
                valuePropName='fileList'
                getValueFromEvent={(e: any) => { return e.fileList || []; }}
              >
                <WdiFileUpload leftTopInfo="请选择文件并上传" />
              </Form.Item>
            </Col>
          </Row>
        </>
        <RebatePaymentDetailsForApplyPanel
          data={{ soldToCode: entity?.soldToCode, pageTypeId: entity?.pageTypeId }}
          dataSource={dataSource}
          onChange={onDetailsChange} />
        {modalProp.visible &&
          <PrePayDetail
            {...modalProp}
            onClose={onClose}
          />}
      </ProForm >
    </>
  );
};
export default RebatePaymentApplyApp;
