import { useEffect, useRef, useState } from 'react';
import { FieldProps, WdiModalAppProps, wdiCore } from '@/components/WdiControls';
import { ProFormInstance } from '@ant-design/pro-form';
import { EC } from '@/services/mdm/ec';
import { WdiSelect, WdiSelectFormat, WdiTreeSelect } from '@/components/WdiControls/WdiSelect';
import { Form, message } from 'antd';
import { HorzLayoutOpts, WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { responseIsSuccess, ResponseStatus } from '@/utils/request';
import { BudgetProject } from '@/services/sales/bp/budgetProject';
import { SoldTo } from '@/services/mdm/soldto';
import { BO } from '@/services/mdm/bo';
import { Enum } from '@/enums/system';
import { FormSa } from '@/services/sales/sa';
import { RevenueType } from '@/services/mdm/revenuetype';
import { ProductPolicy } from '@/services/sales/productpolicy';
import PolicySelectApp, { PolicyItem } from '../../components/PolicyCmpt';
import moment from 'moment';

const FormSaDetailPrevApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [boOpts, setBoOpts] = useState<any[]>();
  const [revenueTypeOpts, setRevenueTypeOpts] = useState<any[]>();
  const [budgetProject, setBudgetProject] = useState<API.BudgetProject>();

  const { row, rowIndex, formSa } = props.data;

  useEffect(() => {
    if (formSa.budgetProjectId) {
      BudgetProject.get(formSa.budgetProjectId).then(response => {
        setBudgetProject(response.data);
      })
    }
  }, [formSa.budgetProjectId]);

  const handleSoldToChange = (soldToCode: any) => {
    formRef.current?.setFieldsValue({ boId: null });
    BO.search({ soldToCode: soldToCode, boLevels: `${Enum.BOLevel.BO4}`, withPermissions: true, isActive: true }).then(response => {
      if (responseIsSuccess(response)) {
        setBoOpts(response.data.map((item) => ({ value: item.boId, label: item.boName })));
        if (response.data.length === 1) {
          formRef.current?.setFieldsValue({ boId: response.data[0].boId });
        }
      }
    });
  };

  const revDataLoading = async (ecId: any, productPolicyId: any) => {
    let productPolicy: API.ProductPolicy = {};
    if (productPolicyId) {
      const policyRes = await ProductPolicy.get(productPolicyId);
      productPolicy = policyRes.data;
    }
    if (productPolicyId && productPolicy) {
      if (productPolicy.ecIds && productPolicy.ecIds.split(',').length === 1) {
        formRef.current?.setFieldsValue({ ecId: { value: productPolicy.ecIds, label: productPolicy.ecNames } });
      }
      if (productPolicy.policyType == Enum.PolicyType.PD_DISCOUNT_FOR_ACTIVITY) {
        const revenueTypeRes = await RevenueType.get(Enum.RevenueType.PD_DISCOUNT);
        if (revenueTypeRes.data) {
          setRevenueTypeOpts([{ value: revenueTypeRes.data.revenueTypeId, label: revenueTypeRes.data.revenueTypeName }]);
          formRef.current?.setFieldsValue({ revenueType: revenueTypeRes.data.revenueTypeId });
        }
      } else if (productPolicy.policyType == Enum.PolicyType.PD_BUY_GIVE_FOR_ACTIVITY) {
        const revenueTypeRes = await RevenueType.get(Enum.RevenueType.PD_BUY_GIVE);
        if (revenueTypeRes.data) {
          setRevenueTypeOpts([{ value: revenueTypeRes.data.revenueTypeId, label: revenueTypeRes.data.revenueTypeName }]);
          formRef.current?.setFieldsValue({ revenueType: revenueTypeRes.data.revenueTypeId });
        }
      }
    } else {
      const revenueTypeRes = await RevenueType.search({ ecId: ecId, gt: 400, lt: 500 });
      if (responseIsSuccess(revenueTypeRes)) {
        setRevenueTypeOpts(revenueTypeRes.data.map((item => { return { value: item.revenueTypeId, label: item.revenueTypeName } })));
        if (revenueTypeRes.data.length === 1) {
          formRef.current?.setFieldsValue({ revenueType: revenueTypeRes.data[0].revenueTypeId });
        }
      }
    }
  };

  useEffect(() => {
    if (row) {
      if (row.ecId) {
        formRef.current?.setFieldsValue({ ecId: row.ecId });
      }
      if (row.productPolicyId) {
        ProductPolicy.get(row.productPolicyId).then(response => {
          if (response.data) {
            formRef.current?.setFieldsValue({ productPolicy: { productPolicyId: response.data.productPolicyId, codeOrName: response.data.codeOrName } });
          }
        });
      }

      revDataLoading(row.ecId, row.productPolicyId);

      if (!formRef.current?.getFieldValue("revenueType")) {
        formRef.current?.setFieldsValue({ revenueType: row.revenueType });
      }
      if (row.soldToCode) {
        formRef.current?.setFieldsValue({
          soldToCode: { value: row.soldToCode, label: wdiCore.String.Join(row.soldToCode, row.soldToName) }
        });
        handleSoldToChange(row.soldToCode);
        if (!formRef.current?.getFieldValue("boId")) {
          formRef.current?.setFieldsValue({ boId: row.boId });
        }
      }
    }
  }, [row]);

  return (
    <WdiModalForm
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      modalProps={{ onCancel: () => { props.onClose?.() }, }}
      submitter={{ searchConfig: { submitText: '下一步', }, }}
      onFinish={async (values) => {
        const { productPolicy, ecId, revenueType, soldToCode, ...entity } = values;
        if (productPolicy) {
          entity.productPolicyId = productPolicy.productPolicyId;
        }
        entity.ecId = WdiSelectFormat.getvalue(ecId);
        entity.revenueType = WdiSelectFormat.getvalue(revenueType);
        entity.soldToCode = WdiSelectFormat.getvalue(soldToCode);
        const response = await FormSa.Buttons.validatePicker({
          rowIndex: rowIndex,
          formSaDetail: entity,
          formSa: { ...formSa },
        });

        if (!responseIsSuccess(response)) {
          message.error(response.message);
        } else if (props.onSave) {
          const formSaDetail = Object.assign({}, row, {
            ecId: response.data.ecId,
            ecName: response.data.ecName,
            soldToCode: response.data.soldToCode,
            soldToName: response.data.soldToName,
            boId: response.data.boId,
            boCode: response.data.boCode,
            boName: response.data.boName,
            productPolicyId: response.data.productPolicyId,
          });
          if (response.data.productPolicyId) {
            formSaDetail.policyCode = response.data.policyCode;
            formSaDetail.policyName = response.data.policyName;
            formSaDetail.policyType = response.data.policyType;
            formSaDetail.policyDesc = response.data.policyDesc;
            formSaDetail.priceSellinAfter = response.data.priceSellinAfter;
            formSaDetail.priceSellinBefore = response.data.priceSellinBefore;
            formSaDetail.policyPdBuyPrice = response.data.policyPdBuyPrice;
            formSaDetail.policyPdFreePrice = response.data.policyPdFreePrice;
          }
          formSaDetail.revenueType = response.data.revenueType;
          formSaDetail.revenueTypeName = response.data.revenueTypeName;
          formSaDetail.accrualMethodId = response.data.accrualMethodId;
          formSaDetail.accrualMethodName = response.data.accrualMethodName;
          props.onSave({
            code: ResponseStatus.SUCCESS,
            data: Object.assign({}, { formSaDetail: formSaDetail }, props.data),
          });
        }
      }}
      {...HorzLayoutOpts}
    >
      <Form.Item name="productPolicy" label="产品政策" >
        <PolicySelectApp
          params={{
            pageTypeId: formSa.pageTypeId,
            policyEndDate: moment().format('YYYY-MM-DD'),
            policyTypes: ` ${Enum.PolicyType.PD_DISCOUNT_FOR_ACTIVITY}, ${Enum.PolicyType.PD_BUY_GIVE_FOR_ACTIVITY} `
          }}
          onChange={async (value?: PolicyItem) => {
            await revDataLoading(WdiSelectFormat.getvalue(formRef.current?.getFieldValue("ecId")), value?.productPolicyId);
          }}
        />
      </Form.Item>
      <Form.Item name="ecId" label="费用项" rules={[{ required: true, message: '费用项不能为空!' }]}>
        <WdiTreeSelect {...{
          placeholder: "请选择费用项",
          dataLoading: async (params) => {
            const response = await EC.searchEc3(Object.assign({
              toParentLevel: Enum.ECLevel.EC2,
              budgetProjectId: formSa.budgetProjectId,
              ecIds: budgetProject?.limitedEcIds,
              pageTypeIds: String(formSa.pageTypeId),
              isActive: true
            }, params));
            const { treeData, dict } = FieldProps.TreeSelect.formatTreeData({
              textField: "ecName",
              valueField: "ecId",
              id: "ecId",
              pId: "parentId",
              level: "ecLevel",
              disabledLevel: [Enum.ECLevel.EC2],
            }, response.data);
            return { treeData, dict };
          },
          onChange: async (value) => {
            await revDataLoading(WdiSelectFormat.getvalue(value), formRef.current?.getFieldValue("productPolicy")?.productPolicyId);
          },
          reloadKey: budgetProject?.limitedEcIds
        }} />
      </Form.Item>
      <Form.Item name="revenueType" label="核算类型" rules={[{ required: true, message: '核算类型不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择核算类型",
          showSearch: true,
          options: revenueTypeOpts
        }} />
      </Form.Item>
      <Form.Item name="soldToCode" label="售达方" rules={[{ required: true, message: '售达方不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择售达方",
          showSearch: true,
          remote: true,
          onChange: handleSoldToChange,
          dataLoading: async (params) => {
            const response = await SoldTo.ajaxSearch(Object.assign({ withPermissions: true, isActive: true, budgetProjectId: formSa.budgetProjectId, soldToCodes: budgetProject?.limitedSoldToCodes }, params));
            return response.data.map((item) => { return { value: item.soldToCode, label: item.codeOrName }; });
          },
          reloadKey: budgetProject?.limitedSoldToCodes
        }} />
      </Form.Item>
      <Form.Item name="boId" label="四级预算中心" rules={[{ required: true, message: '四级预算中心不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择四级预算中心",
          showSearch: true,
          options: boOpts,
        }} />
      </Form.Item>
    </WdiModalForm>
  );
};
export default FormSaDetailPrevApp;
