import {Avatar, Button, Col, Comment, Form, List, Mentions, message, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {PaperClipOutlined} from '@ant-design/icons';
import {Staff as staffApi} from '@/services/basic/staff';
import {responseIsSuccess} from '@/utils/request';
import styles from './FormDiscussions.less';
import {FileUploadUtility, WdiFileUpload} from '@/components/WdiControls/WdiFileUpload'
import {FormDiscussion} from '@/services/basic/formdiscussion';
import {WdiPanel} from '../WdiControls/WdiPanel';
import {WdiCardTitle, wdiCore} from '../WdiControls';
import {Enum} from '@/enums/system';
import {WdiIcon} from '../WdiControls/WdiIcon';
import {FormApi} from "@/services/sales";
import DataStore, {uuidv4} from '../GridWidget/DataStore';
import { SimpleStaff } from '@/services/basic/simplestaff';

type FormDiscussionsProps = {
  dataItem: any;
}

/**讨论板 */
const FormDiscussions: React.FC<FormDiscussionsProps> = (props) => {
  const [staffs, setStaffs] = useState<API.SimpleStaff[]>([]);
  // const [current, setCurrent] = useState<API.Staff>();
  const [form] = Form.useForm();

  const [buId, setBuId] = useState<number>(-1);
  const [submitting, setSubmitting] = useState(false);
  const [commentData, setCommentData] = useState<any[]>([]);

  const extractMentions = (text: string) => {
    if (!text || !text.match(/[@＠]/)) {
      return [];
    }
    let possibleNames: string[] = [];

    text.replace(/@([^@)]*)\)/g, (a, b) => {
      possibleNames.push(b);
      return ""
    });

    text.replace(/@([^@\s\)]*)\s/g, (a, b) => {//match first space
      possibleNames.push(b);
      return "";
    });

    text.replace(/@([^@\s]*)\s([^@\s]*)\s/g, (a, b, c) => {//match second space
      possibleNames.push((b + " " + c));
      return "";
    });

    var names = possibleNames.map((value, i) => {
      var arr = value.split('(');
      return arr[0];
    });
    return names;
  }

  const onSave = async () => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue();
      const {content, fileInfo} = values;
      const {objectId, useCaseId} = props.dataItem;

      if (FileUploadUtility.isUploading(fileInfo)) {
        message.error('附件正在上传中!');
        return;
      }

      if (FileUploadUtility.isError(fileInfo)) {
        message.error('附件存在错误文件!');
        return;
      }
      const info = FileUploadUtility.serialize(fileInfo);

      if (submitting) return;
      setSubmitting(true);

      FormDiscussion.post({
        formId: objectId,
        buId: buId,
        content: String(content),
        fileName: info?.names,
        filePath: info?.urls,
        receiverIds: extractMentions(content).join(),
        useCaseId: useCaseId
      }).then(response => {
        if (responseIsSuccess(response)) {
          form.setFieldsValue({
            content: '',
            fileInfo: undefined
          });
          const data = response.data;
          setCommentData([
            {
              uid: uuidv4(),
              id: data.formDiscussionId,
              author: data.sender.staffName,
              avatar: (<Avatar style={{backgroundColor: 'unset'}} shape="square" size="large"
                               icon={<WdiIcon type={Enum.IconType.AVATAR}/>}/>),
              content: (
                <>
                  {/* <p
                    dangerouslySetInnerHTML={{__html: data.content}}>
                  </p> */}
                  <div style={{ whiteSpace: 'pre-wrap' }}>{data.content}</div>
                  {BuildDownloadLink(FileUploadUtility.parse(data?.fileName, data?.filePath))}
                </>
              ),
              datetime: wdiCore.Date.MinuteToString(data.createDate),
              sender: data.sender,
            },
            ...(commentData || [])]);
        } else {
          message.error('发表失败');
        }
        setSubmitting(false);
      })
    });
  };

  useEffect(() => {
    SimpleStaff.search({}).then(response => {
      setStaffs(response.data);
    });
    // staffApi.current().then(response => {
    //   setCurrent(response.data);
    // })
    const objectId = props.dataItem.objectId
    FormApi.get(objectId).then(res => {
      setBuId(res.data.buId);
      FormDiscussion.search(res.data.buId, objectId).then(response => {
        setCommentData(DataStore.init(response?.data.map(item => ({
            id: item.formDiscussionId,
            author: item.sender.staffName,
            avatar: (<Avatar style={{backgroundColor: 'unset'}} shape="square" size="large"
                             icon={<WdiIcon type={Enum.IconType.AVATAR}/>}/>),
            content: (
              <>
                {/* <p dangerouslySetInnerHTML={{__html: item.content}}></p> */}
                <div style={{whiteSpace: 'pre-wrap'}}>{item.content}</div>
                {BuildDownloadLink(FileUploadUtility.parse(item?.fileName, item?.filePath))}
              </>),
            datetime: wdiCore.Date.MinuteToString(item.createDate),
            sender: item.sender,
          })))
        )
      })
    })
  }, []);
  return (
    <>
      <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DISCUSSION} title='讨论版'/>} collapsed={true}>
        <Comment
          className={styles.comment}
          content={
            <Form form={form}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="content"
                    label=""
                    rules={[{required: true, message: '讨论内容不能为空!'}]}
                  >
                    <Mentions rows={3}>
                      {(staffs || []).map(item => (
                        <Mentions.Option key={item.loginName} value={item.staffName}>{item.staffName}</Mentions.Option>
                      ))}

                    </Mentions>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Form.Item
                    name="fileInfo"
                    label=""
                    valuePropName='fileList'
                    getValueFromEvent={(e: any) => {
                      return e.fileList || [];
                    }}>
                    <WdiFileUpload leftTopInfo="请选择文件并上传"/>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Form.Item>
                    <Button loading={submitting} onClick={onSave} type="primary" style={{marginRight: '8px'}}>
                      发表
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          }
        />
        {
          commentData && commentData.length > 0 &&
          <List
            className={styles.list}
            dataSource={commentData}
            header={`${commentData.length} 个讨论`}
            itemLayout="horizontal"
            renderItem={props => (
              <Comment
                onClick={() => {
                  form.setFieldsValue({
                    content: '@' + props.author + " ",
                  });
                }}
                className={styles.commentlist}
                actions={[
                  /* <span hidden={current?.staffId != props.sender.staffId} data-id={props.id} onClick={(e) => {
                     if (confirm('确定要删除此条评论吗？')) {
                       FormDiscussion.del(props.id).then(response => {
                         if (response.code == ResponseCode.SUCCESS) {
                           setCommentData(DataStore.del(commentData, props.uid));
                           message.success('删除成功');
                         } else {
                           message.error(response.message);
                         }
                       })
                     }
                     e.stopPropagation()
                   }}>删除</span>,*/
                  <span data-id={props.id} onClick={() => {
                    form.setFieldsValue({
                      content: '@' + props.author + " ",
                    });
                  }}>回复</span>
                ]}
                {...props}
              />
            )
            }
          />
        }
      </WdiPanel>
    </>
  );
};

export default FormDiscussions;


/**创建历史讨论文件下载链接 */
function BuildDownloadLink(fileList: any[]) {
  return (
    <>
      {
        fileList?.length > 0 &&
        <PaperClipOutlined style={{color: '#1296db'}}/>
      }
      {
        fileList?.length > 0 &&
        fileList.map((item, itemIndex) => (
          <a onClick={(event) => {
            FileUploadUtility.download(item);
            event.stopPropagation();
          }} key={item.url}>
            {item.name + "；"}
          </a>
        ))
      }
    </>
  );
}
