import { useEffect, useState } from 'react';
import { DetailGrid, Utility, wdiCore } from '@/components/GridWidget';
import { NsGrid } from '@/components/GridWidget/GridDefProps';
import { WdiCardTitle, WdiModalAppResult, WdiModalProps } from '@/components/WdiControls';
import { Button, Dropdown, Menu, message, Space } from 'antd';
import { initFieldDisplayFormater, TableColumnRanderFormats } from '@/components/WdiBus/TableColumnRanderFormats'
import DataStore from '@/components/GridWidget/DataStore'
import { ResponseStatus } from '@/utils/request';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import FormSaDetailPrevApp from './detail-prev';
import PdDiscountDetailApp from './detail-edit-pd-discount';
import PdGiveawayDetailApp from './detail-edit-pd-giveaway';
import PolicyPdDiscountDetailApp from './detail-edit-policy-pd-discount';
import PolicyPdGiveawayDetailApp from './detail-edit-policy-pd-giveaway';
import { ImportModalApp } from '@/components/WdiControls/WdiImport';
import { last } from 'lodash';
import { Enum } from '@/enums/system';
import { toExcel, ToExcelProps } from '@/components/WdiControls/WdiExcel';
import { SoldTo } from '@/services/mdm/soldto';
import { Product } from '@/services/mdm/product';
import { EC } from '@/services/mdm/ec';
import { GridApi } from '@/services/gridapi';
import { ProductPolicy } from '@/services/sales/productpolicy';
import FormSaDetailCompareModalApp from '../../components/FormSaDetailCompare';
import { SA } from '@/services/report/sa';

const ModalTypeEnums = {
  None: 0,
  Prev: -1,
  Import: -2,
  PD_DISCOUNT: 1,
  PD_BUY_GIVE: 2,
  PLOICY_PD_DISCOUNT: 3,
  POLICY_BUY_GIVE: 4,
};

export type DetailForApplyProps = {
  data?: any,
  dataSource: any[],
  onChange?: (dataSource: any) => void;
};

const initRowFieldConfigs = (record: any) => {
  const columns = ["boId", "soldToCode", "shipToCodes", "productBuyIds", "ecId", "revenueType", "revenueSellinForecast", "amount", "discountRate", "syncStatus", "syncDate", "Remark"];
  if (record.productPolicyId) {
    columns.push("productPolicyId");
  }
  if (record.revenueType === Enum.RevenueType.PD_DISCOUNT) {
    return [...columns, "priceSellinBefore", "priceSellinAfter", "unitExpense", "pricePosBefore", "pricePosAfter", "quantitySellinForecast"];
  } else {
    return [...columns, "productFreeIds", "quantityBuy", "quantityFree", "quantityBomForecast"];
  }
};

export const FormSaDetailsForApplyPanel: React.FC<DetailForApplyProps> = (props) => {
  // Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [importExcels, setImportExcels] = useState<ToExcelProps[]>([]);
  const [modalProps, setModalProps] = useState<WdiModalProps>({ visible: false });

  const { data, dataSource, onChange } = props;

  const onModalClose = () => {
    setModalProps({ visible: false, title: "", type: ModalTypeEnums.None, id: null });
  };

  const onImportSave = (uploadResult: any) => {
    if (uploadResult.code == ResponseStatus.SUCCESS) {
      const newDataSource = [...dataSource];
      const result = uploadResult?.data?.data;

      if (result && result.length > 0) {
        if (uploadResult?.data?.exProperty) {
          //是否清空
          onChange?.(DataStore.init(result));
        } else {
          onChange?.(DataStore.addMulti(newDataSource, result));
        }
      }
    }
    onModalClose();
  }

  const onPrevSave = (result?: WdiModalAppResult) => {
    const modalSetting: WdiModalProps = { visible: true, data: result?.data };
    if (result?.data.formSaDetail.productPolicyId) {
      if (result?.data.formSaDetail.revenueType == Enum.RevenueType.PD_DISCOUNT) {
        modalSetting.type = ModalTypeEnums.PLOICY_PD_DISCOUNT;
      } else {
        modalSetting.type = ModalTypeEnums.POLICY_BUY_GIVE;
      }
    } else {
      if (result?.data.formSaDetail.revenueType == Enum.RevenueType.PD_DISCOUNT) {
        modalSetting.type = ModalTypeEnums.PD_DISCOUNT;
      } else {
        modalSetting.type = ModalTypeEnums.PD_BUY_GIVE;
      }
    }
    if (result?.data.rowIndex > -1) {
      setModalProps({ title: "编辑费用明细", ...modalSetting });
    } else {
      setModalProps({ title: "新建费用明细", ...modalSetting });
    }
  };

  const onEditSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      const row = result?.data;
      if (result?.data.rowIndex > -1) {
        const record = modalProps.data.row;
        const newItem = { ...row };
        newItem.uid = record.uid;
        const newDataSouce = DataStore.edit(dataSource, newItem);
        onChange?.(newDataSouce);
      } else {
        const newItem = { ...row };
        onChange?.(DataStore.add(dataSource, newItem));
      }
    }
    onModalClose();
  };

  useEffect(() => {
    const soldToSheet = {
      dataLoading: async (params: any) => {
        return await SoldTo.queryForSheet({ withPermissions: true });
      },
      data: null,
      names: [
        { name: "soldToCode", displayName: "售达方编号" },
        { name: "soldToName", displayName: "售达方名称" },
        { name: "boName4", displayName: "四级预算中心" },
        { name: "boName3", displayName: "三级预算中心" },
        { name: "boName2", displayName: "二级预算中心" },
        { name: "boName1", displayName: "一级预算中心" },
      ],
      sheetName: "售达方",
    };
    const productSheet = {
      dataLoading: async () => {
        return await Product.getProductStructures({
          isActive: true,
          productLevels: `${Enum.ProductLevel.PRODUCT5}`,
          productType: Enum.ProductType.SKU
        });
      },
      data: null,
      names: [
        { name: "productCode5", displayName: "产品编号" },
        { name: "productName5", displayName: "产品名称" },
        { name: "productCode4", displayName: "L4编号" },
        { name: "productName4", displayName: "L4名称" },
        { name: "productCode3", displayName: "L3编号" },
        { name: "productName3", displayName: "L3名称" },
        { name: "productCode2", displayName: "品类品牌编号" },
        { name: "productName2", displayName: "品类品牌名称" },
        { name: "productCode1", displayName: "品类编号" },
        { name: "productName1", displayName: "品类名称" },
      ],
      sheetName: "产品",
    };
    const ecSheet = {
      dataLoading: async (params: any) => {
        return await EC.queryForSheet({
          ecLevel: Enum.ECLevel.EC3,
          pageTypeId: props.data?.formSa?.pageTypeId,
          budgetProjectId: props.data?.formSa?.budgetProjectId
        });
      },
      data: null,
      names: [
        { name: "ecName1", displayName: "费用大类" },
        { name: "ecName2", displayName: "费用小类" },
        { name: "ecName3", displayName: "费用项" },
        { name: "revenueTypeNames", displayName: "核算类型" },
      ],
      sheetName: "费用类别",
    };
    const productPolicySheet = {
      dataLoading: async (params: any) => {
        const req: any = {
          filterOptions: JSON.stringify(Object.assign({}, { policyTypes: ` ${Enum.PolicyType.PD_DISCOUNT_FOR_ACTIVITY}, ${Enum.PolicyType.PD_BUY_GIVE_FOR_ACTIVITY} ` })),
        };
        return await ProductPolicy.searchForPicker(req);
      },
      data: null,
      names: [
        { name: "policyCode", displayName: "政策编号" },
        { name: "policyName", displayName: "政策名称" },
        { name: "policyTypeName", displayName: "政策类型" },
        { name: "startDate", displayName: "政策期间" },
        { name: "boNames", displayName: "适用预算中心" },
        { name: "ecNames", displayName: "适用费用项" },
        { name: "productNamesReach", displayName: "达量产品" },
        { name: "productNamesRebate", displayName: "返利产品" },
        { name: "policyDesc", displayName: "政策规则" },
      ],
      sheetName: "产品政策列表",
      cells: {
        startDate: (fieldObject: any, row: any, value: any) => {
          return wdiCore.Date.Join(row.startDate, row.endDate)
        },
      }
    };
    const importExcels = [
      {
        name: "全部下载",
        sheetProps: [soldToSheet, productSheet, ecSheet, productPolicySheet]
      }, {
        name: "售达方列表",
        sheetProps: [soldToSheet]
      }, {
        name: "产品列表",
        sheetProps: [productSheet]
      }, {
        name: "费用类别列表",
        sheetProps: [ecSheet]
      }, {
        name: "产品政策列表",
        sheetProps: [productPolicySheet]
      }
    ];
    setImportExcels(importExcels);
  }, [props.data]);

  useEffect(() => {
    GridApi.searchConfig({ id: 'FormSADetailForPD', allowModifyColumns: true }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,
        showExpandedRow: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields)
      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.ecId.width = 200;
      settings.formSchema.allFields.priceSellinBefore.width = 100;
      settings.formSchema.allFields.priceSellinAfter.width = 100;
      settings.formSchema.allFields.pricePosBefore.width = 100;
      settings.formSchema.allFields.pricePosAfter.width = 100;
      settings.formSchema.allFields.quantitySellinForecast.width = 100;
      settings.formSchema.allFields.revenueSellinForecast.width = 100;
      settings.formSchema.allFields.revenueSellinForecast.aggr = TableColumnRanderFormats.sumAggr('revenueSellinForecast', 2);
      settings.formSchema.allFields.amount.width = 100;
      settings.formSchema.allFields.amount.aggr = TableColumnRanderFormats.sumAggr('amount', 2);

      Utility.setActionCallback(settings.formSchema.actions, 'Update',
        async (grid: any, action: any, record: any) => {
          const context = grid.getContext();
          setModalProps({
            visible: true,
            title: '选择费用类型',
            type: ModalTypeEnums.Prev,
            id: record.formSaDetailId,
            data: {
              row: record,
              rows: context.dataSource,
              rowIndex: DataStore.getRowIndex(context.dataSource, record.uid),
              formSa: context.params.formSa,
            }
          });
        },
      );

      Utility.setActionCallback(settings.formSchema.actions, 'Copy',
        async (grid: any, action: any, record: any) => {
          const context = grid.getContext();
          setModalProps({
            visible: true,
            title: '选择费用类型',
            type: ModalTypeEnums.Prev,
            id: record.formSaDetailId,
            data: {
              row: record,
              rows: context.dataSource,
              rowIndex: -1,
              formSa: context.params.formSa,
            }
          });
        },
      );

      Utility.setActionCallback(settings.formSchema.actions, 'SystemDelete',
        async (grid: any, action: any, record: any) => {
          if (window.confirm("确定要删除吗？")) {
            const context = grid.getContext();
            onChange?.(DataStore.del(context.dataSource, record.uid));
            message.success("删除成功");
          }
        },
      );

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true
    }
  };

  return (
    <WdiPanel
      title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用明细信息' />}
      extra={
        <Dropdown.Button
          type='primary'
          onClick={() => {
            const row: any = {};
            if (dataSource && dataSource.length > 0) {
              const lastrow = last(dataSource);
              row.ecId = lastrow.ecId;
              row.soldToCode = lastrow.soldToCode;
              row.soldToName = lastrow.soldToName;
              row.boId = lastrow.boId;
              row.revenueType = lastrow.revenueType;
              row.productPolicyId = lastrow.productPolicyId;
            }
            setModalProps({
              visible: true,
              title: '选择费用类型',
              type: ModalTypeEnums.Prev,
              id: null,
              data: {
                row: row,
                rows: dataSource,
                rowIndex: -1,
                formSa: data?.formSa,
              }
            });
          }}
          overlay={
            <Menu items={[{
              key: 'import',
              label: '导入',
              onClick: () => {
                setModalProps({ title: '费用明细导入', visible: true, type: ModalTypeEnums.Import });
              }
            }]} />
          }
          style={{ float: 'right' }}
        >
          新建
        </Dropdown.Button>
      }
    >
      {
        isLoaded &&
        modalProps.visible &&
        modalProps.type === ModalTypeEnums.Import &&
        <ImportModalApp
          title={modalProps.title}
          visible={modalProps.visible}
          settingsUrl="/activityapi/saButtons/getImportSettings"
          uploadingUrl="/activityapi/saButtons/importUploading"
          onClose={onModalClose}
          onSave={onImportSave}
          clientParameters={{
            formSa: data?.formSa,
            formSaDetails: JSON.stringify(dataSource)
          }}
          importExcels={importExcels}
        />
      }
      {
        isLoaded &&
        modalProps.visible &&
        modalProps.type === ModalTypeEnums.Prev &&
        <FormSaDetailPrevApp {...modalProps} onClose={onModalClose} onSave={onPrevSave} />
      }
      {
        isLoaded &&
        modalProps.visible &&
        (modalProps.type === ModalTypeEnums.PD_DISCOUNT) &&
        <PdDiscountDetailApp {...modalProps} onClose={onModalClose} onSave={onEditSave} />
      }
      {
        isLoaded &&
        modalProps.visible &&
        (modalProps.type === ModalTypeEnums.PD_BUY_GIVE) &&
        <PdGiveawayDetailApp {...modalProps} onClose={onModalClose} onSave={onEditSave} />
      }
      {
        isLoaded &&
        modalProps.visible &&
        (modalProps.type === ModalTypeEnums.PLOICY_PD_DISCOUNT) &&
        <PolicyPdDiscountDetailApp {...modalProps} onClose={onModalClose} onSave={onEditSave} />
      }
      {
        isLoaded &&
        modalProps.visible &&
        (modalProps.type === ModalTypeEnums.POLICY_BUY_GIVE) &&
        <PolicyPdGiveawayDetailApp {...modalProps} onClose={onModalClose} onSave={onEditSave} />
      }
      {
        isLoaded &&
        <DetailGrid {...pageGridSettings} params={data} autoColumn={['rowNumber']} />
      }
    </WdiPanel>
  );
};

export const FormSaDetailsForApprovalPanel: React.FC<DetailForApplyProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [modalProps, setModalProps] = useState<WdiModalProps>({ visible: false });

  useEffect(() => {
    GridApi.searchConfig({ id: 'FormSADetailForPDApproval', allowModifyColumns: true }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.None,
        showAggr: true,
        showExpandedRow: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);
      settings.formSchema.allFields.productPolicyId.getDisplayFormater = (text: any, record: any, index: any) => {
        return (
          <a onClick={() => {
            window.open(`/policy/ProductPolicy/view/?policyType=${record.policyType}&productPolicyId=${record.productPolicyId}`);
          }}>
            {wdiCore.String.CodeOrName(record.policyCode, record.policyName)}
          </a>
        )
      };
      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.ecId.width = 200;
      settings.formSchema.allFields.priceSellinBefore.width = 100;
      settings.formSchema.allFields.priceSellinAfter.width = 100;
      settings.formSchema.allFields.pricePosBefore.width = 100;
      settings.formSchema.allFields.pricePosAfter.width = 100;
      settings.formSchema.allFields.quantitySellinForecast.width = 100;
      settings.formSchema.allFields.revenueSellinForecast.width = 100;
      settings.formSchema.allFields.revenueSellinForecast.aggr = TableColumnRanderFormats.sumAggr('revenueSellinForecast', 2);
      settings.formSchema.allFields.amount.width = 100;
      settings.formSchema.allFields.amount.aggr = TableColumnRanderFormats.sumAggr('amount', 2);

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  const { dataSource, data } = props;

  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true
    }
  };

  useEffect(() => {
    if (data?.form) {
      setModalProps(Object.assign({}, modalProps, { title: "费用明细变更对比", data: { form: data.form } }));
    }
  }, [data]);
  const exportDetails = () => {
    const columns = [
      { name: "policyCode", displayName: "产品政策编号" },
      { name: "ecName", displayName: "费用项" },
      { name: "revenueTypeName", displayName: "核算类型" },
      { name: "soldToCode", displayName: "售达方编号" },
      { name: "shipToCodes", displayName: "送达方编号" },
      { name: "productBuyCodes", displayName: "购买产品编号" },
      { name: "productFreeCodes", displayName: "赠送产品编号" },
      { name: "priceSellinAfter", displayName: "促销到岸价(EA)" },
      { name: "pricePosBefore", displayName: "终端原售价" },
      { name: "pricePosAfter", displayName: "终端促销售价" },
      { name: "quantitySellinForecast", displayName: "预计销量(EA)" },
      { name: "quantityBuy", displayName: "每套购买数量(EA)" },
      { name: "quantityFree", displayName: "每套赠送数量(EA)" },
      { name: "quantityBomForecast", displayName: "预计购买套数" },
      { name: "remark", displayName: "备注" },
      { name: "policyName", displayName: "产品政策名称" },
      { name: "boName", displayName: "四级预算中心" },
      { name: "soldToName", displayName: "售达方名称" },
      { name: "shipToNames", displayName: "送达方名称" },
      { name: "productBuyNames", displayName: "购买产品名称" },
      { name: "productFreeNames", displayName: "赠送产品名称" },
      { name: "discountAmountSellin", displayName: "单件费用(EA)" },
      { name: "revenueSellinForecast", displayName: "预计销售额" },
      { name: "priceSellinBefore", displayName: "原到岸价(EA)" },
      { name: "amount", displayName: "申请金额" },
      { name: "discountRate", displayName: "折扣比例", type: Enum.FieldType.NUMBER },
      { name: "syncStatusName", displayName: "同步状态" },
      { name: "syncDate", displayName: "同步日期" },
      { name: "rebateParameters", displayName: "公式计算参数" },
      { name: "amountCalc", displayName: "公式计算金额" },
    ];
    SA.exportPdSaDetails(Number(data?.form?.formId)).then(response => {
      const formSASheet = {
        data: response?.data,
        names: columns,
        sheetName: "活动明细",
        cells: {
          discountRate: (fieldObject: any, row: any, value: any) => {
            if (value) {
              return wdiCore.Number.RateToString(value);
            }
            return "";
          },
        }
      };
      toExcel({
        name: "随货类活动费用明细信息_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
        sheetProps: [formSASheet]
      });
    });
  };

  return (
    <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用明细信息' />} collapsed={true} extra={
      <Space>
        <Button type='primary' onClick={(e) => {
          e.stopPropagation();
          exportDetails();
        }}>
          导出
        </Button>
        <Button
          hidden={wdiCore.Object.IsNullOrUndefined(data?.form?.relatedFormId)}
          onClick={(e) => {
            e.stopPropagation();
            setModalProps({ ...modalProps, ...{ visible: true } });
          }}
          type='primary'>
          变更对比
        </Button>
      </Space>
    }>
      {isLoaded
        && modalProps.visible
        && <FormSaDetailCompareModalApp {...modalProps} onClose={() => {
          setModalProps({ ...modalProps, ...{ visible: false } });
        }} />}
      {isLoaded
        && <DetailGrid {...pageGridSettings} />}
    </WdiPanel>
  );
};
