import React, {Component, useEffect, useState} from 'react';
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Spin} from 'antd';
import type {CheckboxChangeEvent} from 'antd/es/checkbox';
import {FieldProps, wdiCore, WdiModalAppProps} from '../WdiControls';
import {NsGrid} from './GridDefProps';
import {WdiSelect, WdiTreeSelect} from '../WdiControls/WdiSelect';
import moment from 'moment';
import {BlockOutlined, CloseOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {ResponseCode} from '@/utils/response';
import {WdiModal} from '../WdiControls/WdiModalForm';
import {WdiTable} from '../WdiControls/WdiTable';
import DataStore from './DataStore';
import {ColumnsType} from 'antd/lib/table';

export namespace NsGridFilter {
  type FilterCellProps = {
    // 过滤列展示的标识符
    code: string;
    // 过滤列展示的名称
    name: string;
    // 过滤列是否需要标识必填标记
    isRequired?: boolean;
  };

  type SigleFilterCellProps = {
    // 子组件
    comp: React.ReactNode;
  } & FilterCellProps;

  type DoubleFilterCellProps = {
    // 左侧子组件
    leftComp: React.ReactNode;
    // 右侧子组件
    rightComp: React.ReactNode;
  } & FilterCellProps;

  const textAlginCenterStyle: React.CSSProperties = {textAlign: 'center'};

  const NonSupportCompnent = () => {
    return <>不支持的过滤内容</>;
  };

  // 单个组件单元格列的定义
  export const SingleCell: React.FC<SigleFilterCellProps> = (props: SigleFilterCellProps) => {
    const {name, isRequired} = props;
    const reqStyle: React.CSSProperties = {};
    if (!isRequired) {
      reqStyle.display = 'none';
    }
    return (
      <div className="grid-filter-item">
        <div className="grid-filter-header">
          {name}
          <span className="grid-filter-require" style={reqStyle}>
            *
          </span>
        </div>
        <div className="grid-filter-controlholder">{props.comp}</div>
      </div>
    );
  };

  // 两个组件单元格列的定义
  export const DoubleCell: React.FC<DoubleFilterCellProps> = (props: DoubleFilterCellProps) => {
    const {name, isRequired} = props;
    const reqStyle: React.CSSProperties = {};
    if (!isRequired) {
      reqStyle.display = 'none';
    }
    return (
      <div className="grid-filter-item">
        <div className="grid-filter-header">
          {name}
          <span className="grid-filter-require" style={reqStyle}>
            *
          </span>
        </div>
        <div className="grid-filter-controlholder">
          <Row>
            <Col flex="auto">{props.leftComp}</Col>
            <Col flex="20px" style={textAlginCenterStyle}>
              ~
            </Col>
            <Col flex="auto">{props.rightComp}</Col>
          </Row>
        </div>
      </div>
    );
  };

  export type FilterItemValue = {
    field?: string;
    title?: string;
    type?: string;
    // 值的比较类型
    opt: number | string;
    // 值的数据
    value: any;
  };

  export interface IBasicFilter {
    container: any;
    code: string;
    name: string;
    dataType?: string;
    opt?: number;
    isRequired?: boolean;
    antdProps?: any;
    span: number;
    defaultFilterValue?: any;
    startFilterValue?: any;
    endFilterValue?: any;
    ref?: any;

    [key: string]: any;

    cellSettings: () => FilterCellProps;
    byFilter: () => void;
    byReset: () => void;
    getObject: (opt: number, value: any) => FilterItemValue;
    render: () => React.ReactNode;
    getValue: () => FilterItemValue | FilterItemValue[] | undefined | null;
    setDefaultValue: (item: FilterItemValue[] | FilterItemValue) => void;
    clearDefaultValue: () => void;
    getDefaultValue: () => FilterItemValue | FilterItemValue[] | undefined | null;
    resetValue: () => void;
  }

  class BasicFilter implements IBasicFilter {
    container: any;
    code: string;
    name: string;
    dataType?: string;
    isRequired?: boolean;
    opt?: number;
    antdProps?: any;
    span: number;
    defaultFilterValue?: any;
    startFilterValue?: any;
    endFilterValue?: any;
    ref?: any;

    [key: string]: any;

    constructor(container: any, fieldObject: API.FieldObject) {
      if (container) {
        this.container = container;
      }
      this.code = fieldObject.name;
      this.name = fieldObject.displayName;
      if (!wdiCore.Object.IsNullOrUndefined(fieldObject.dataType)) {
        this.dataType = String(fieldObject.dataType);
      }
      if (!wdiCore.Object.IsNullOrUndefined(fieldObject.isRequired)) {
        this.isRequired = fieldObject.isRequired;
      }
      if (!wdiCore.Object.IsNullOrUndefined(fieldObject.antdProps)) {
        this.antdProps = fieldObject.antdProps;
      }
      this.span = 4;
    }

    public cellSettings() {
      return {
        name: this.name,
        code: this.code,
        isRequired: this.isRequired,
      };
    }

    public byFilter() {
      if (this.container && this.container.byFilter) {
        this.container.byFilter();
      }
    }

    public byReset() {
      if (this.container && this.container.byReset) {
        this.container.byReset();
      }
    }

    public getObject(opt: number, value: any) {
      return {
        title: this.name,
        field: this.code,
        type: this.dataType,
        opt: opt,
        value: value,
      };
    }

    public render() {
      return <NonSupportCompnent/>;
    }

    public getValue(): FilterItemValue | FilterItemValue[] | undefined | null {
      return null;
    }

    public setDefaultValue(item: FilterItemValue | FilterItemValue[]) {
      let items: FilterItemValue[] = [];
      if (!wdiCore.Object.IsNullOrUndefined(item)) {
        if (item instanceof Array) {
          items = item;
        } else {
          items.push(item);
        }
      }
      items.forEach((filterValue) => {
        let value = null;
        if (!wdiCore.Object.IsNullOrUndefined(filterValue.value)) {
          value = filterValue.value;
        }
        if (
          filterValue.opt == NsGrid.FilterOptType.EGT ||
          filterValue.opt == NsGrid.FilterOptType.GT
        ) {
          this.startFilterValue = value;
        } else if (
          filterValue.opt == NsGrid.FilterOptType.ELT ||
          filterValue.opt == NsGrid.FilterOptType.LT
        ) {
          this.endFilterValue = value;
        } else {
          this.defaultFilterValue = value;
        }
      });
    }

    public clearDefaultValue() {
      this.startFilterValue = null;
      this.endFilterValue = null;
      this.defaultFilterValue = null;
    }

    public getDefaultValue(): FilterItemValue | FilterItemValue[] | undefined | null {
      return null;
    }

    public resetValue() {
    };
  }

  class EmptyFilter extends BasicFilter {
    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.opt = NsGrid.FilterOptType.EQ;
    }

    public render() {
      const cellSettings = this.cellSettings();
      return <SingleCell {...cellSettings} key={this.code} comp={<NonSupportCompnent/>}/>;
    }
  }

  type CheckBoxComponentProps = {
    value: any;
    antdProps?: any;
  };

  type CheckBoxComponentStates = {
    value: any;
  };

  class CheckBoxComponent extends Component<CheckBoxComponentProps> {
    state: CheckBoxComponentStates;

    constructor(props: CheckBoxComponentProps) {
      super(props);
      this.state = {
        value: false,
      };
      if (!wdiCore.Object.IsNullOrUndefined(this.props.value)) {
        this.state.value = this.props.value;
      }
    }

    handelValueChange = (e: CheckboxChangeEvent) => {
      if (e && e.target) {
        this.setState({value: e.target.checked});
      }
    };

    render() {
      const {value} = this.state;
      /// 不能复制对象属性
      const antdProps = Object.assign({}, this.props.antdProps);
      if (!wdiCore.Object.IsNullOrUndefined(this.props.value)) {
        antdProps.defaultChecked = this.props.value;
      }
      if (!wdiCore.Object.IsNullOrUndefined(value)) {
        antdProps.checked = value;
      }
      antdProps.onChange = this.handelValueChange;
      return <Checkbox {...antdProps} style={{width: '99.5%'}}/>;
    }
  }

  class CheckBoxFilter extends BasicFilter {
    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.opt = NsGrid.FilterOptType.EQ;
      this.ref = React.createRef();
    }

    public render() {
      const settings = {
        value: this.defaultFilterValue,
        antdProps: Object.assign({}, this.antdProps),
      };
      const ref = this.ref;
      const cellSettings = this.cellSettings();
      return (
        <SingleCell
          {...cellSettings}
          key={this.code}
          comp={<CheckBoxComponent {...settings} ref={ref}/>}
        />
      );
    }

    public getValue() {
      const ref = this.ref;
      var value = ref.current.state.value;
      return this.getObject(this.opt as number, value);
    }

    public getDefaultValue() {
      const value = this.defaultFilterValue;
      return this.getObject(this.opt as number, value);
    }

    public resetValue() {
      const ref = this.ref;
      ref.current.setState({value: this.defaultFilterValue || false});
    }
  }

  type TextComponentProps = {
    value: any;
    antdProps?: any;
  };

  type TextComponentStates = {
    value: any;
  };

  class TextComponent extends Component<TextComponentProps> {
    state: TextComponentStates;

    constructor(props: TextComponentProps) {
      super(props);
      this.state = {
        value: '',
      };
      if (!wdiCore.Object.IsNullOrUndefined(this.props.value)) {
        this.state.value = this.props.value;
      }
    }

    handelValueChange = (e: any) => {
      if (e && e.target) {
        this.setState({value: e.target.value});
      }
    };

    render() {
      const {value} = this.state;
      /// 不能复制对象属性
      const antdProps = Object.assign({}, this.props.antdProps);
      if (!wdiCore.Object.IsNullOrUndefined(this.props.value)) {
        antdProps.defaultValue = this.props.value;
      }
      if (!wdiCore.Object.IsNullOrUndefined(value)) {
        antdProps.value = value;
      }
      antdProps.onChange = this.handelValueChange;
      return <Input {...antdProps} style={{width: '99.5%'}}/>;
    }
  }

  class TextFilter extends BasicFilter {
    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.opt = NsGrid.FilterOptType.CONTAINS;
      this.ref = React.createRef();
    }

    public render() {
      const settings = {
        value: this.defaultFilterValue,
        antdProps: Object.assign({}, this.antdProps),
      };
      const ref = this.ref;
      const cellSettings = this.cellSettings();
      return (
        <SingleCell
          {...cellSettings}
          key={this.code}
          comp={<TextComponent {...settings} ref={ref}/>}
        />
      );
    }

    public getValue() {
      const ref = this.ref;
      let value = ref.current.state.value;
      if (wdiCore.String.IsNullOrEmpty(value)) {
        return null;
      }
      return this.getObject(this.opt as number, value.trim());
    }

    public getDefaultValue() {
      const value = this.defaultFilterValue;
      if (wdiCore.String.IsNullOrEmpty(value)) {
        return null;
      }
      return this.getObject(this.opt as number, value.trim());
    }

    public resetValue() {
      const ref = this.ref;
      ref.current.setState({value: this.defaultFilterValue});
    }
  }

  type TextParseComponentStates = {
    modalProps: WdiModalAppProps;
  } & TextComponentStates;

  const TextParseModalApp: React.FC<WdiModalAppProps> = (props: WdiModalAppProps) => {
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const columns: ColumnsType<any> = [
      {
        title: <CloseOutlined onClick={() => {
          if (window.confirm("确定要删除所有行吗？")) {
            setDataSource([]);
          }
        }}/>,
        align: 'center',
        width: 50,
        dataIndex: 'delete',
        ellipsis: true,
        render: (_, record) => (<CloseOutlined onClick={() => {
          setDataSource(DataStore.del(dataSource, record.uid))
        }}/>),
      },
      {
        title: '内容',
        dataIndex: 'content',
        ellipsis: true,
      },
    ];

    const buildDataSource = (value: string) => {
      const result = value.replaceAll(/\r\n/g, ",").replaceAll(/\n/g, ",").replaceAll('，', ",").replaceAll(';', ",").replaceAll('；', ",");
      setDataSource(DataStore.addMulti(dataSource, result.split(',').filter(v => v != "").map(v => ({content: v}))));
      form.setFieldsValue({
        content: ''
      })
    }

    const handelParse = (e: any) => {
      setLoading(true);
      setTimeout(() => {
        let value = e.target.value;
        if (value) {
          buildDataSource(value);
        }
        setLoading(false);
      }, 100);
    };

    const handelEnter = (e: any) => {
      if ('enter' == e?.key.toLocaleLowerCase()) {
        if ('textarea' == e.target?.tagName.toLocaleLowerCase()) {
          setLoading(true);
          setTimeout(() => {
            let value = e.target.value;
            if (value) {
              buildDataSource(value);
            }
            setLoading(false);
          }, 100);
          setLoading(false);
        }
      }
    };

    return (
      <WdiModal
        title={props.title}
        visible={props.visible}
        width={800}
        onCancel={() => {
          props.onClose?.()
        }}
        onOk={() => {
          props.onSave?.({
            code: ResponseCode.SUCCESS,
            data: [...new Set(dataSource.map(value => value.content))].join()
          })
        }}
      >
        <Spin tip="识别中，请稍候" spinning={loading}>
          <Form form={form}>
            <Row>
              <Col span={24}>
                <Form.Item name="content" label="粘贴至此">
                  <Input.TextArea onPaste={handelParse} onKeyUp={handelEnter}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <WdiTable
            showPageRows={10}
            columns={columns}
            size='small'
            dataSource={dataSource}
            pagination={false}
          />
        </Spin>
      </WdiModal>
    )
  }

  class TextParseComponent extends Component<TextComponentProps> {
    state: TextParseComponentStates;

    constructor(props: TextComponentProps) {
      super(props);
      this.state = {
        value: '',
        modalProps: {visible: false},
      };
      if (!wdiCore.Object.IsNullOrUndefined(this.props.value)) {
        this.state.value = this.props.value;
      }
    }

    handelValueChange = (e: any) => {
      if (e && e.target) {
        this.setState({value: e.target.value});
      }
    };

    handelParseChange = () => {
      this.setState({
        modalProps: {visible: true, title: '复制粘贴'}
      });
    }

    render() {
      const {value, modalProps} = this.state;
      const antdProps = Object.assign({}, this.props.antdProps);
      if (!wdiCore.Object.IsNullOrUndefined(value)) {
        antdProps.value = value;
      }
      antdProps.onChange = this.handelValueChange;
      return <>
        {modalProps.visible &&
        <TextParseModalApp
          {...modalProps}
          onClose={() => {
            this.setState({
              modalProps: {visible: false}
            });
          }}
          onSave={(result) => {
            this.setState({
              value: result?.data,
              modalProps: {visible: false}
            });
          }}
        />
        }
        <Input {...antdProps} style={{width: '85%'}}/>
        <BlockOutlined onClick={this.handelParseChange} style={{fontSize: 20}}/>
      </>;
    }
  }

  class TextParseFilter extends BasicFilter {
    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.opt = NsGrid.FilterOptType.IN;
      this.ref = React.createRef();
    }

    public render() {
      const settings = {
        value: this.defaultFilterValue,
        antdProps: Object.assign({}, this.antdProps),
      };
      const ref = this.ref;
      const cellSettings = this.cellSettings();
      return (
        <SingleCell
          {...cellSettings}
          key={this.code}
          comp={<TextParseComponent {...settings} ref={ref}/>}
        />
      );
    }

    public getValue() {
      const ref = this.ref;
      let value = ref.current.state.value;
      if (wdiCore.String.IsNullOrEmpty(value)) {
        return null;
      }
      if (!wdiCore.Object.IsArray(value)) {
        value = value.trim().split(',');
      }
      return this.getObject(this.opt as number, value);
    }

    public getDefaultValue() {
      let value = this.defaultFilterValue;
      if (wdiCore.String.IsNullOrEmpty(value)) {
        return null;
      }
      if (!wdiCore.Object.IsArray(value)) {
        value = value.trim().split(',');
      }
      return this.getObject(this.opt as number, value);
    }

    public resetValue() {
      const ref = this.ref;
      ref.current.setState({value: this.defaultFilterValue});
    }
  }

  type WdiSelectComponentProps = {
    value: any;
    remote?: boolean;
    dataLoading?: (params: any) => Promise<any>;
    // remote 模式下根据当前值和默认过滤条件拼接参数, 作为dataLoading的参数
    defaultParamKey?: string;
    // remote 模式下需要自定义方法设置默认值, 默认设置默认值策略是将当前dataLoading添加defaultParamKey参数
    remoteSetDefaultValue?: (
      props: any,
      value: any,
      callback: (newValue: any, labeledValue: any) => void,
    ) => void;
    antdProps?: any;
  };

  type WdiSelectComponentStates = {
    // remote 模式下value不能赋值需要使用label: value模式
    labeledValue: any;
    value: any;
  };

  class SelectComponent extends Component<WdiSelectComponentProps> {
    state: WdiSelectComponentStates;

    constructor(props: WdiSelectComponentProps) {
      super(props);
      this.state = {
        labeledValue: null,
        value: null,
      };

      if (this.props.antdProps && this.props.antdProps.dataLoading) {
        // noting to do
      } else {
        // 使用props.antdProps.options 进行赋值
        this.state.value = props.value;
      }
    }

    componentDidMount() {
      const {antdProps, remoteSetDefaultValue, defaultParamKey, value} = this.props;
      if (antdProps && antdProps.dataLoading) {
        if (!wdiCore.Object.IsNullOrUndefined(value)) {
          if (antdProps.remote) {
            if (remoteSetDefaultValue) {
              remoteSetDefaultValue(
                {dataLoading: antdProps.dataLoading, defaultParamKey},
                value,
                (newValue, labeledValue) => {
                  this.setState({value: newValue, labeledValue});
                },
              );
            }
          } else {
            this.setState({value, labeledValue: null});
          }
        }
      }
    }

    handelValueChange = (value: any) => {
      this.setState({value: value, labeledValue: null});
    };

    render() {
      const {value, labeledValue} = this.state;
      /// 不能复制对象属性
      const antdProps = Object.assign({onChange: this.handelValueChange}, this.props.antdProps);
      if (!wdiCore.Object.IsNullOrUndefined(value)) {
        antdProps.value = value;
      }
      if (!wdiCore.Object.IsNullOrUndefined(labeledValue)) {
        antdProps.value = labeledValue;
      }
      return <WdiSelect {...antdProps} style={{width: '99.5%'}}/>;
    }
  }

  class SelectFilter extends BasicFilter {
    remote?: boolean;
    placeholder: string;
    // 是否多选
    isMulti: boolean;
    dataLoading?: (params: any) => Promise<any>;
    // remote 模式下根据当前值和默认过滤条件拼接参数, 作为dataLoading的参数
    defaultParamKey?: string;

    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.opt = NsGrid.FilterOptType.IN;
      this.placeholder = '全部';
      this.isMulti = false;
      this.ref = React.createRef();
    }

    public render() {
      const settings: WdiSelectComponentProps = {
        value: this.defaultFilterValue,
        defaultParamKey: this.defaultParamKey,
        remoteSetDefaultValue: this.remoteSetDefaultValue,
      };
      const exAntdProps: any = {};
      if (!wdiCore.Object.IsNullOrUndefined(this.remote)) {
        exAntdProps.remote = this.remote;
      }
      if (!wdiCore.Object.IsNullOrUndefined(this.dataLoading)) {
        exAntdProps.dataLoading = this.dataLoading;
      }
      const antdProps = Object.assign(
        {
          maxTagCount: 'responsive',
          maxTagTextLength: 15.9,
          placeholder: this.placeholder,
          allowClear: true,
        },
        exAntdProps,
        this.antdProps,
      );
      // remote 优先使用 this.antdProps中的
      const {remote} = antdProps;
      if (this.isMulti) {
        antdProps.mode = 'multiple';
        antdProps.autoClearSearchValue = false;
      }
      antdProps.showSearch = true;
      antdProps.showArrow = false;
      if (remote) {
        antdProps.filterOption = false;
      } else {
        antdProps.filterOption = (inputValue: any, option: any) => {
          return !!(option && wdiCore.String.Match(option.label, inputValue));
        };
      }

      settings.antdProps = Object.assign({}, antdProps, this.antdProps);
      const ref = this.ref;
      const cellSettings = this.cellSettings();
      return (
        <SingleCell
          {...cellSettings}
          key={this.code}
          comp={<SelectComponent {...settings} ref={ref}/>}
        />
      );
    }

    /// 远程设置配置默认值
    public remoteSetDefaultValue(
      props: any,
      value: any,
      callback: (newValue: any, labeledValue: any) => void,
    ) {
      let valueArray = [];
      let isArrayValue = false;
      if (value) {
        if (wdiCore.Object.IsArray(value)) {
          valueArray = value;
          isArrayValue = true;
        } else {
          valueArray.push(value);
        }
      }
      if (valueArray.length) {
        // url 支持ids查询
        const params2: Record<string, any> = {};
        params2[props.defaultParamKey] = valueArray.join();
        props.dataLoading(params2).then((data: any) => {
          if (data && data.length) {
            let resultLabeledValue, resultValue;
            if (isArrayValue) {
              resultValue = data.map((item: any) => item.value);
              resultLabeledValue = data;
            } else {
              resultValue = data[0].value;
              resultLabeledValue = data[0];
            }
            if (callback) {
              callback(resultValue, resultLabeledValue);
            }
          }
        });
      }
    }

    public setDefaultValue(item: FilterItemValue[] | FilterItemValue) {
      let items: FilterItemValue[] = [];
      if (!wdiCore.Object.IsNullOrUndefined(item)) {
        if (item instanceof Array) {
          items = item;
        } else {
          items.push(item);
        }
      }
      items.forEach((filterValue) => {
        if (!wdiCore.Object.IsNullOrUndefined(filterValue.value)) {
          const value = filterValue.value;
          if (wdiCore.Object.IsArray(value)) {
            if (value.length) {
              if (this.isMulti) {
                this.defaultFilterValue = value;
              } else {
                this.defaultFilterValue = value[0];
              }
            }
          } else {
            if (this.isMulti) {
              this.defaultFilterValue = [value];
            } else {
              this.defaultFilterValue = value;
            }
          }
        } else {
          this.defaultFilterValue = null;
        }
      });
    }

    public getValue() {
      const ref = this.ref;
      let value = ref.current.state.value;
      if (wdiCore.Object.IsNullOrUndefined(value)) {
        return null;
      }
      if (!wdiCore.Object.IsArray(value)) {
        value = [value];
      }
      if (!value.length) {
        return null;
      }
      /// 多选使用IN, 单选使用EQ
      if (this.isMulti) {
        return this.getObject(this.opt as number, value);
      } else {
        if (value.length >= 1) {
          return this.getObject(NsGrid.FilterOptType.EQ, value[0]);
        } else {
          return null;
        }
      }
    }

    public getDefaultValue() {
      let value = this.defaultFilterValue;
      if (wdiCore.Object.IsNullOrUndefined(value)) {
        return null;
      }
      if (!wdiCore.Object.IsArray(value)) {
        value = [value];
      }
      if (!value.length) {
        return null;
      }
      /// 多选使用IN, 单选使用EQ
      if (this.isMulti) {
        return this.getObject(this.opt as number, value);
      } else {
        if (value.length >= 1) {
          return this.getObject(NsGrid.FilterOptType.EQ, value[0]);
        } else {
          return null;
        }
      }
    }

    public resetValue() {
      const ref = this.ref;
      ref.current.setState({value: this.defaultFilterValue, labeledValue: null});
    }
  }

  type WdiSelectParseComponentStates = {
    modalProps: WdiModalAppProps;
  } & WdiSelectComponentStates;

  type SelectParseModalAppProps = {
    dataLoading?: (params: any) => Promise<any>;
  } & WdiModalAppProps;

  const SelectParseModalApp: React.FC<SelectParseModalAppProps> = (props: SelectParseModalAppProps) => {
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<any[]>([]);

    const columns: ColumnsType<any> = [
      {
        title: <CloseOutlined onClick={() => {
          if (window.confirm("确定要删除所有行吗？")) {
            setDataSource([]);
          }
        }}/>,
        align: 'center',
        width: 50,
        dataIndex: 'delete',
        ellipsis: true,
        render: (_, record) => (<CloseOutlined onClick={() => {
          setDataSource(DataStore.del(dataSource, record.uid))
        }}/>),
      },
      {
        title: '内容',
        dataIndex: 'content',
        ellipsis: true,
        render: (value, record) => (<span style={record.isRed ? {color: 'red'} : {}}>{value}</span>),
      },
    ];

    const buildDataSource = (value: string) => {
      const result = value.replaceAll(/\r\n/g, ",").replaceAll(/\n/g, ",").replaceAll('，', ",").replaceAll(';', ",").replaceAll('；', ",");
      const resultList = result.split(',').filter(v => v != "");
      const newDataSource: any[] = [];
      resultList.forEach(item => {
        const option = options.find(option => option.text == item || option.value == item || option.label == item);
        if (option) {
          newDataSource.push({content: option.label, isRed: false, value: option.value, label: option.label});
        } else {
          newDataSource.push({content: item, isRed: true, value: ''})
        }
      })
      setDataSource(DataStore.addMulti(dataSource, newDataSource));
      form.setFieldsValue({
        content: ''
      })
    }

    const handelParse = (e: any) => {
      setLoading(true);
      setTimeout(() => {
        let value = e.target.value;
        if (value) {
          buildDataSource(value);
        }
        setLoading(false);
      }, 100);
    };

    const handelEnter = (e: any) => {
      if ('enter' == e?.key.toLocaleLowerCase()) {
        if ('textarea' == e.target?.tagName.toLocaleLowerCase()) {
          setLoading(true);
          setTimeout(() => {
            let value = e.target.value;
            if (value) {
              buildDataSource(value);
            }
            setLoading(false);
          }, 100);
          setLoading(false);
        }
      }
    };

    useEffect(() => {
      if (props.dataLoading) {
        props.dataLoading({}).then(items => {
          setOptions(items);
        });
      }
    }, [props.dataLoading])

    return (
      <WdiModal
        title={props.title}
        visible={props.visible}
        width={800}
        onCancel={() => {
          props.onClose?.()
        }}
        onOk={() => {
          props.onSave?.({
            code: ResponseCode.SUCCESS,
            data: [...new Set(dataSource.filter(item => item.value != ''))]
          })
        }}
      >
        <Spin tip="识别中，请稍候" spinning={loading}>
          <Form form={form}>
            <Row>
              <Col span={24}>
                <Form.Item name="content" label="粘贴至此">
                  <Input.TextArea onPaste={handelParse} onKeyUp={handelEnter}/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <WdiTable
            showPageRows={10}
            columns={columns}
            size='small'
            dataSource={dataSource}
            pagination={false}
          />
        </Spin>
      </WdiModal>
    )
  }

  class SelectParseComponent extends Component<WdiSelectComponentProps> {
    state: WdiSelectParseComponentStates;

    constructor(props: WdiSelectComponentProps) {
      super(props);
      this.state = {
        labeledValue: null,
        value: null,
        modalProps: {visible: false},
      };

      if (!wdiCore.Object.IsNullOrUndefined(this.props.value)) {
        this.state.value = this.props.value;
      }
    }

    componentDidMount() {
      const {antdProps, remoteSetDefaultValue, defaultParamKey, value} = this.props;
      if (antdProps && antdProps.dataLoading) {
        if (!wdiCore.Object.IsNullOrUndefined(value)) {
          if (antdProps.remote) {
            if (remoteSetDefaultValue) {
              remoteSetDefaultValue(
                {dataLoading: antdProps.dataLoading, defaultParamKey},
                value,
                (newValue, labeledValue) => {
                  this.setState({value: newValue, labeledValue});
                },
              );
            }
          } else {
            this.setState({value, labeledValue: null});
          }
        }
      }
    }

    handelValueChange = (value: any) => {
      this.setState({value: value, labeledValue: null});
    };

    handelParseChange = () => {
      this.setState({
        modalProps: {visible: true, title: '复制粘贴'}
      });
    }

    render() {
      const {value, labeledValue, modalProps} = this.state;
      const {modalDataLoading, ...aProps} = this.props.antdProps;
      /// 不能复制对象属性
      const antdProps = Object.assign({onChange: this.handelValueChange}, aProps);
      if (!wdiCore.Object.IsNullOrUndefined(value)) {
        antdProps.value = value;
      }
      if (!wdiCore.Object.IsNullOrUndefined(labeledValue)) {
        antdProps.value = labeledValue;
      }
      return <>
        {modalProps.visible &&
        <SelectParseModalApp
          {...modalProps}
          dataLoading={modalDataLoading}
          onClose={() => {
            this.setState({
              modalProps: {visible: false}
            });
          }}
          onSave={(result) => {
            this.setState({
              value: result?.data.map((m: any) => m.value),
              labeledValue: result?.data.map((m: any) => m.label),
              modalProps: {visible: false}
            });
          }}
        />
        }
        <WdiSelect {...antdProps} style={{width: '85%'}}/>
        <BlockOutlined onClick={this.handelParseChange} style={{fontSize: 20}}/>
      </>;
    }
  }

  class SelectParseFilter extends BasicFilter {
    remote?: boolean;
    placeholder: string;
    dataLoading?: (params: any) => Promise<any>;
    modalDataLoading?: (params: any) => Promise<any>;
    defaultParamKey?: string;

    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.opt = NsGrid.FilterOptType.IN;
      this.placeholder = '全部';
      this.isMulti = true;
      this.ref = React.createRef();
    }

    public render() {
      const settings: WdiSelectComponentProps = {
        value: this.defaultFilterValue,
        defaultParamKey: this.defaultParamKey,
        remoteSetDefaultValue: this.remoteSetDefaultValue,
      };
      const exAntdProps: any = {};
      if (!wdiCore.Object.IsNullOrUndefined(this.remote)) {
        exAntdProps.remote = this.remote;
      }
      if (!wdiCore.Object.IsNullOrUndefined(this.dataLoading)) {
        exAntdProps.dataLoading = this.dataLoading;
      }
      if (!wdiCore.Object.IsNullOrUndefined(this.modalDataLoading)) {
        exAntdProps.modalDataLoading = this.modalDataLoading;
      }
      const antdProps = Object.assign(
        {
          maxTagCount: 'responsive',
          maxTagTextLength: 15.9,
          placeholder: this.placeholder,
          allowClear: true,
          dropdownMatchSelectWidth: false,
        },
        exAntdProps,
        this.antdProps,
      );
      // remote 优先使用 this.antdProps中的
      const {remote} = antdProps;
      antdProps.mode = 'multiple';
      antdProps.autoClearSearchValue = false;
      antdProps.showSearch = true;
      antdProps.showArrow = false;
      if (remote) {
        antdProps.filterOption = false;
      } else {
        antdProps.filterOption = (inputValue: any, option: any) => {
          return option && wdiCore.String.Match(option.label, inputValue) ? true : false;
        };
      }

      settings.antdProps = Object.assign({}, antdProps, this.antdProps);
      const ref = this.ref;
      const cellSettings = this.cellSettings();
      return (
        <SingleCell
          {...cellSettings}
          key={this.code}
          comp={<SelectParseComponent {...settings} ref={ref}/>}
        />
      );
    }

    /// 远程设置配置默认值
    public remoteSetDefaultValue(
      props: any,
      value: any,
      callback: (newValue: any, labeledValue: any) => void,
    ) {
      let valueArray = [];
      let isArrayValue = false;
      if (value) {
        if (wdiCore.Object.IsArray(value)) {
          valueArray = value;
          isArrayValue = true;
        } else {
          valueArray.push(value);
        }
      }
      if (valueArray.length) {
        // url 支持ids查询
        const params2: Record<string, any> = {};
        params2[props.defaultParamKey] = valueArray.join();
        props.dataLoading(params2).then((data: any) => {
          if (data && data.length) {
            let resultLabeledValue, resultValue;
            if (isArrayValue) {
              resultValue = data.map((item: any) => item.value);
              resultLabeledValue = data;
            } else {
              resultValue = data[0].value;
              resultLabeledValue = data[0];
            }
            if (callback) {
              callback(resultValue, resultLabeledValue);
            }
          }
        });
      }
    }

    public setDefaultValue(item: FilterItemValue[] | FilterItemValue) {
      let items: FilterItemValue[] = [];
      if (!wdiCore.Object.IsNullOrUndefined(item)) {
        if (item instanceof Array) {
          items = item;
        } else {
          items.push(item);
        }
      }
      items.forEach((filterValue) => {
        if (!wdiCore.Object.IsNullOrUndefined(filterValue.value)) {
          const value = filterValue.value;
          if (wdiCore.Object.IsArray(value)) {
            if (value.length) {
              this.defaultFilterValue = value;
            }
          } else {
            this.defaultFilterValue = [value];
          }
        } else {
          this.defaultFilterValue = null;
        }
      });
    }

    public getValue() {
      const ref = this.ref;
      let value = ref.current.state.value;
      if (wdiCore.Object.IsNullOrUndefined(value)) {
        return null;
      }
      if (!wdiCore.Object.IsArray(value)) {
        value = [value];
      }
      if (!value.length) {
        return null;
      }
      return this.getObject(this.opt as number, value);
    }

    public getDefaultValue() {
      let value = this.defaultFilterValue;
      if (wdiCore.Object.IsNullOrUndefined(value)) {
        return null;
      }
      if (!wdiCore.Object.IsArray(value)) {
        value = [value];
      }
      if (!value.length) {
        return null;
      }
      return this.getObject(this.opt as number, value);
    }

    public resetValue() {
      const ref = this.ref;
      ref.current.setState({value: this.defaultFilterValue, labeledValue: null});
    }
  }

  const containerValues = (valueArray: any[], itemValue: any) => {
    let isOk = false;
    if (valueArray && valueArray.length) {
      valueArray.forEach((item) => {
        if (item == itemValue) {
          isOk = true;
        }
      });
    }
    return isOk;
  };

  function resolveTreeData(data: any[], value: any[], resultValue: any[]) {
    if (data && data.length) {
      data.forEach((item) => {
        const itemValue = item.value;
        if (containerValues(value, itemValue)) {
          resultValue.push(itemValue);
        }
        resolveTreeData(item.children, value, resultValue);
      });
    }
  }

  const getTreeSelectValuesFromDataSource = (treeData: any, value: any) => {
    if (wdiCore.Object.IsNullOrUndefined(value)) {
      return value;
    }
    let resultValue: any[] = [];
    let tmpValue = [];
    let isArray = false;
    if (wdiCore.Object.IsArray(value)) {
      isArray = true;
      tmpValue = value;
    } else {
      tmpValue = [value];
    }
    resolveTreeData(treeData, tmpValue, resultValue);
    if (resultValue.length) {
      if (isArray) {
        return resultValue;
      } else {
        return resultValue[0];
      }
    } else {
      return null;
    }
  };

  type WdiTreeSelectComponentProps = {
    value: any;
    dataLoading?: (params: any) => Promise<any>;
    antdProps?: any;
  };

  type WdiTreeSelectComponentStates = {
    value: any;
  };

  export class TreeSelectComponent extends Component<WdiTreeSelectComponentProps> {
    state: WdiTreeSelectComponentStates;

    constructor(props: WdiTreeSelectComponentProps) {
      super(props);
      this.state = {
        value: null,
      };
      const {dataLoading} = this.props;
      if (dataLoading) {
        // 根据传递dataLoading，再componentDidMount中加载数据
      } else {
        // 默认通过antdProps.treeData传递参数
        this.state.value = getTreeSelectValuesFromDataSource(
          props.antdProps?.treeData,
          props.value,
        );
      }
    }

    handelValueChange = (value: any, node: any, extra: any) => {
      const tmpValue = FieldProps.TreeSelect.labeledValue2Value(value);
      this.setState({value: tmpValue});
    };

    /// 开启多选, 选择需要层级互斥
    handleSelectChange = (value: any, node: any, dict: any) => {
      this.setState((prevState: WdiSelectComponentStates) => {
        const tmpValue = FieldProps.TreeSelect.valueFormater(dict, prevState.value, value);
        return {value: tmpValue};
      });
    };

    componentDidMount() {
      const {antdProps, value} = this.props;
      if (antdProps && antdProps.dataLoading) {
        if (!wdiCore.Object.IsNullOrUndefined(value)) {
          antdProps.dataLoading().then((data: any) => {
            this.setState({
              value: getTreeSelectValuesFromDataSource(
                data?.treeData,
                value
              )
            })
          })
        }
      }
    }

    render() {
      const {value} = this.state;
      /// 不能复制对象属性
      const antdProps = Object.assign(
        {
          onChange: this.handelValueChange,
        },
        this.props.antdProps,
      );
      if (antdProps.multiple) {
        antdProps.onExSelect = this.handleSelectChange;
      }
      if (!wdiCore.Object.IsNullOrUndefined(value)) {
        antdProps.value = value;
      }
      return <WdiTreeSelect {...antdProps} style={{width: '99.5%'}}/>;
    }
  }

  class TreeSelectFilter extends BasicFilter {
    placeholder: string;
    // 是否多选
    isMulti: boolean;
    dataLoading?: (params: any) => Promise<any>;

    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.opt = NsGrid.FilterOptType.IN;
      this.placeholder = '全部';
      this.isMulti = false;
      this.ref = React.createRef();
    }

    public render() {
      const settings: WdiTreeSelectComponentProps = {
        value: this.defaultFilterValue,
      };
      const exAntdProps: any = {};
      if (!wdiCore.Object.IsNullOrUndefined(this.dataLoading)) {
        exAntdProps.dataLoading = this.dataLoading;
      }
      const antdProps = Object.assign(
        {
          showSearch: true,
          showArrow: false,
          treeNodeFilterProp: 'filterKey',
          maxTagCount: 'responsive',
          maxTagTextLength: 15.9,
          placeholder: this.placeholder,
          allowClear: true,
        },
        exAntdProps,
        this.antdProps,
      );
      if (this.isMulti) {
        antdProps.multiple = true;
        antdProps.autoClearSearchValue = false;
        // antdProps.showCheckedStrategy = TreeSelect.SHOW_PARENT;
        antdProps.treeCheckStrictly = true;
        antdProps.treeCheckable = true;
      }
      settings.antdProps = Object.assign({}, antdProps, this.antdProps);

      const ref = this.ref;
      const cellSettings = this.cellSettings();
      return (
        <SingleCell
          {...cellSettings}
          key={this.code}
          comp={<TreeSelectComponent {...settings} ref={ref}/>}
        />
      );
    }

    public setDefaultValue(item: FilterItemValue[] | FilterItemValue) {
      let items: FilterItemValue[] = [];
      if (!wdiCore.Object.IsNullOrUndefined(item)) {
        if (item instanceof Array) {
          items = item;
        } else {
          items.push(item);
        }
      }
      items.forEach((filterValue) => {
        if (!wdiCore.Object.IsNullOrUndefined(filterValue.value)) {
          const value = filterValue.value;
          if (wdiCore.Object.IsArray(value)) {
            if (value.length) {
              if (this.isMulti) {
                this.defaultFilterValue = value;
              } else {
                this.defaultFilterValue = value[0];
              }
            }
          } else {
            if (this.isMulti) {
              this.defaultFilterValue = [value];
            } else {
              this.defaultFilterValue = value;
            }
          }
        } else {
          this.defaultFilterValue = null;
        }
      });
    }

    public getValue() {
      const ref = this.ref;
      let value = ref.current.state.value;
      if (wdiCore.Object.IsNullOrUndefined(value)) {
        return null;
      }
      if (!wdiCore.Object.IsArray(value)) {
        value = [value];
      }
      if (!value.length) {
        return null;
      }
      /// 多选使用IN, 单选使用EQ
      if (this.isMulti) {
        return this.getObject(this.opt as number, value);
      } else {
        if (value.length >= 1) {
          return this.getObject(NsGrid.FilterOptType.EQ, value[0]);
        } else {
          return null;
        }
      }
    }

    public getDefaultValue() {
      let value = this.defaultFilterValue;
      if (wdiCore.Object.IsNullOrUndefined(value)) {
        return null;
      }
      if (!wdiCore.Object.IsArray(value)) {
        value = [value];
      }
      if (!value.length) {
        return null;
      }
      /// 多选使用IN, 单选使用EQ
      if (this.isMulti) {
        return this.getObject(this.opt as number, value);
      } else {
        if (value.length >= 1) {
          return this.getObject(NsGrid.FilterOptType.EQ, value[0]);
        } else {
          return null;
        }
      }
    }

    public resetValue() {
      const ref = this.ref;
      ref.current.setState({value: this.defaultFilterValue});
    }
  }

  type DateTimeComponentProps = {
    value: any;
    /// antdProps: {
    ///   picker: 空, quarter, year, month
    ///   format: 默认YYYY-MM-DD, YYYY\QQ, YYYY, YYYYMM
    /// }
    antdProps?: any;
  };

  type DateTimeComponentStates = {
    value: any;
  };

  class DateTimeComponent extends Component<DateTimeComponentProps> {
    state: DateTimeComponentStates;

    constructor(props: DateTimeComponentProps) {
      super(props);
      this.state = {
        value: null,
      };
      this.state.value = this.props.value;
    }

    handelValueChange = (dates: any, dateStrings: any) => {
      this.setState({value: dateStrings});
    };

    render() {
      const {value} = this.state;
      /// 不能复制对象属性
      const antdProps = Object.assign({}, this.props.antdProps);
      if (!wdiCore.Object.IsNullOrUndefined(this.props.value)) {
        antdProps.defaultValue = moment(this.props.value, antdProps.format);
      }
      if (value) {
        antdProps.value = moment(value, antdProps.format);
      }
      antdProps.onChange = this.handelValueChange;
      return <DatePicker {...antdProps} style={{width: '99.5%'}}/>;
    }
  }

  class DateTimeFilter extends BasicFilter {
    optRight: number;
    optLeft: number;
    refLeft: any;
    refRight: any;

    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.optLeft = NsGrid.FilterOptType.EGT;
      this.optRight = NsGrid.FilterOptType.ELT;
      this.span = 8;
      this.refLeft = React.createRef();
      this.refRight = React.createRef();
    }

    public render() {
      const leftSettings = {
        value: this.startFilterValue,
        antdProps: Object.assign({}, this.antdProps),
      };
      const rightSettings = {
        value: this.endFilterValue,
        antdProps: Object.assign({}, this.antdProps),
      };
      const refLeft = this.refLeft;
      const refRight = this.refRight;
      const cellSettings = this.cellSettings();
      return (
        <DoubleCell
          {...cellSettings}
          key={this.code}
          leftComp={<DateTimeComponent {...leftSettings} ref={refLeft}/>}
          rightComp={<DateTimeComponent {...rightSettings} ref={refRight}/>}
        />
      );
    }

    public getValue() {
      const refLeft = this.refLeft;
      const refRight = this.refRight;
      const startValue = refLeft.current.state.value;
      const endValue = refRight.current.state.value;
      if (wdiCore.String.IsNullOrEmpty(startValue) && wdiCore.String.IsNullOrEmpty(endValue)) {
        return null;
      }
      const values = [];
      if (!wdiCore.String.IsNullOrEmpty(startValue)) {
        values.push(this.getObject(this.optLeft, startValue));
      }
      if (!wdiCore.String.IsNullOrEmpty(endValue)) {
        values.push(this.getObject(this.optRight, endValue));
      }
      return values;
    }

    public getDefaultValue() {
      const startValue = this.startFilterValue;
      const endValue = this.endFilterValue;
      if (wdiCore.String.IsNullOrEmpty(startValue) && wdiCore.String.IsNullOrEmpty(endValue)) {
        return null;
      }
      const values = [];
      if (!wdiCore.String.IsNullOrEmpty(startValue)) {
        values.push(this.getObject(this.optLeft, startValue));
      }
      if (!wdiCore.String.IsNullOrEmpty(endValue)) {
        values.push(this.getObject(this.optRight, endValue));
      }
      return values;
    }

    public resetValue() {
      const refLeft = this.refLeft;
      const refRight = this.refRight;
      refLeft.current.setState({value: this.startFilterValue});
      refRight.current.setState({value: this.endFilterValue});
    }
  }

  class EQDateTimeFilter extends BasicFilter {
    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.opt = NsGrid.FilterOptType.EQ;
      this.ref = React.createRef();
    }

    public render() {
      const settings = {
        value: this.defaultFilterValue,
        antdProps: Object.assign({}, this.antdProps),
      };
      const ref = this.ref;
      const cellSettings = this.cellSettings();
      return (
        <SingleCell
          {...cellSettings}
          key={this.code}
          comp={<DateTimeComponent {...settings} ref={ref}/>}
        />
      );
    }

    public getValue() {
      const ref = this.ref;
      const value = ref.current.state.value;
      if (wdiCore.String.IsNullOrEmpty(value)) {
        return null;
      }
      return this.getObject(this.opt as number, value);
    }

    public getDefaultValue() {
      const value = this.defaultFilterValue;
      if (wdiCore.String.IsNullOrEmpty(value)) {
        return null;
      }
      return this.getObject(this.opt as number, value.trim());
    }

    public resetValue() {
      const ref = this.ref;
      ref.current.setState({value: this.defaultFilterValue});
    }
  }

  type InputNumberComponentProps = {
    value: any;
    antdProps?: any;
  };

  type InputNumberComponentStates = {
    value: any;
  };

  class InputNumberComponent extends React.Component<InputNumberComponentProps> {
    state: InputNumberComponentStates;

    constructor(props: InputNumberComponentProps) {
      super(props);
      this.state = {
        value: null,
      };
      this.state.value = this.props.value;
    }

    handelValueChange = (value: any) => {
      this.setState({value});
    };

    render() {
      /// 不能复制对象属性
      const antdProps = Object.assign({}, this.props.antdProps);
      if (!wdiCore.Object.IsNullOrUndefined(this.props.value)) {
        antdProps.defaultValue = this.props.value;
      }
      antdProps.onChange = this.handelValueChange;
      return <InputNumber {...antdProps} style={{width: '99.5%'}}/>;
    }
  }

  class InputNumberFilter extends BasicFilter {
    optRight: number;
    optLeft: number;
    refLeft: any;
    refRight: any;

    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.optLeft = NsGrid.FilterOptType.EGT;
      this.optRight = NsGrid.FilterOptType.ELT;
      this.span = 8;
      this.refLeft = React.createRef();
      this.refRight = React.createRef();
    }

    public render() {
      const leftSettings = {
        value: this.startFilterValue,
        antdProps: Object.assign({}, this.antdProps),
      };
      const rightSettings = {
        value: this.endFilterValue,
        antdProps: Object.assign({}, this.antdProps),
      };
      const refLeft = this.refLeft;
      const refRight = this.refRight;
      const cellSettings = this.cellSettings();
      return (
        <DoubleCell
          {...cellSettings}
          key={this.code}
          leftComp={<InputNumberComponent {...leftSettings} ref={refLeft}/>}
          rightComp={<InputNumberComponent {...rightSettings} ref={refRight}/>}
        />
      );
    }

    public getValue() {
      const refLeft = this.refLeft;
      const refRight = this.refRight;
      const startValue = refLeft.current.state.value;
      const endValue = refRight.current.state.value;
      if (wdiCore.String.IsNullOrEmpty(startValue) && wdiCore.String.IsNullOrEmpty(endValue)) {
        return null;
      }
      const values = [];
      if (!wdiCore.String.IsNullOrEmpty(startValue)) {
        values.push(this.getObject(this.optLeft, startValue));
      }
      if (!wdiCore.String.IsNullOrEmpty(endValue)) {
        values.push(this.getObject(this.optRight, endValue));
      }
      return values;
    }

    public getDefaultValue() {
      const startValue = this.startFilterValue;
      const endValue = this.endFilterValue;
      if (wdiCore.String.IsNullOrEmpty(startValue) && wdiCore.String.IsNullOrEmpty(endValue)) {
        return null;
      }
      const values = [];
      if (!wdiCore.String.IsNullOrEmpty(startValue)) {
        values.push(this.getObject(this.optLeft, startValue));
      }
      if (!wdiCore.String.IsNullOrEmpty(endValue)) {
        values.push(this.getObject(this.optRight, endValue));
      }
      return values;
    }

    public resetValue() {
      const refLeft = this.refLeft;
      const refRight = this.refRight;
      refLeft.current.setState({value: this.startFilterValue});
      refRight.current.setState({value: this.endFilterValue});
    }
  }

  class EQNumberFilter extends BasicFilter {
    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.opt = NsGrid.FilterOptType.EQ;
      this.ref = React.createRef();
    }

    public render() {
      const settings = {
        value: this.defaultFilterValue,
        antdProps: Object.assign({}, this.antdProps),
      };
      const ref = this.ref;
      const cellSettings = this.cellSettings();
      return (
        <SingleCell
          {...cellSettings}
          key={this.code}
          comp={<InputNumberComponent {...settings} ref={ref}/>}
        />
      );
    }

    public getValue() {
      const ref = this.ref;
      const value = ref.current.state.value;
      if (wdiCore.String.IsNullOrEmpty(value)) {
        return null;
      }
      return this.getObject(this.opt as number, value);
    }

    public getDefaultValue() {
      const value = this.defaultFilterValue;
      if (wdiCore.String.IsNullOrEmpty(value)) {
        return null;
      }
      return this.getObject(this.opt as number, value);
    }

    public resetValue() {
      const ref = this.ref;
      ref.current.setState({value: this.defaultFilterValue});
    }
  }

  type SearchButtonComponentProps = {
    // 搜索按钮的显示文本
    searchButtonText: string;
    // 点击执行过滤
    byFilter: (e: any) => void;
    // 点击重置筛选条件
    byReset: (e: any) => void;
  };

  const SearchButtonComponent: React.FC<SearchButtonComponentProps> = (
    props: SearchButtonComponentProps,
  ) => {
    return (
      <div style={{width: '100%', height: '100%', position: 'relative'}}>
        <div className="grid-filter-item">
          <div className="grid-filter-header">&nbsp;</div>
          <div
            className="grid-filter-controlholder"
            style={{bottom: '0px', position: 'absolute', right: 0}}
          >
            <Button type="primary" style={{padding: '4px 5px'}} icon={<SearchOutlined/>} onClick={props.byFilter}>
              {props.searchButtonText}
            </Button>
            <Button style={{marginLeft: 8, padding: '4px 5px'}} type="primary" icon={<ReloadOutlined/>}
                    onClick={props.byReset}>
              重置
            </Button>
          </div>
        </div>
      </div>
    );
  };

  class SearchButtonFilter extends BasicFilter {
    // 搜索按钮的显示文本
    searchButtonText: string;

    constructor(container: any, fieldObject: API.FieldObject) {
      super(container, fieldObject);
      this.opt = NsGrid.FilterOptType.EQ;
      this.searchButtonText = '搜索';
    }

    public render() {
      const settings = {
        searchButtonText: this.searchButtonText,
        byFilter: () => {
          this.byFilter();
        },
        byReset: () => {
          this.byReset();
        },
      };
      return <SearchButtonComponent {...settings} key={this.code}/>;
    }
  }

  export const FilterFactory: Record<string, any> = {
    Basic: BasicFilter,
    Empty: EmptyFilter,
    Text: TextFilter,
    TextParse: TextParseFilter,
    Select: SelectFilter,
    SelectParse: SelectParseFilter,
    Number: InputNumberFilter,
    TreeSelect: TreeSelectFilter,
    DateTime: DateTimeFilter,
    Button: SearchButtonFilter,
    createCheckBox: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        return Object.assign(new CheckBoxFilter(container, fieldObject), props);
      };
    },
    createCombobox: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new SelectFilter(container, fieldObject), props);
        //ctrl.picker = 'combobox';
        return ctrl;
      };
    },
    createBooleanSelect: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new SelectFilter(container, fieldObject), props);
        if (
          wdiCore.Object.IsNullOrUndefined(ctrl.antdProps) ||
          wdiCore.Object.IsNullOrUndefined(ctrl.antdProps.options)
        ) {
          ctrl.antdProps = {
            options: [
              {label: 'Y', value: '1'},
              {label: 'N', value: '0'},
            ],
          };
        }
        return ctrl;
      };
    },
    createTreeSelect: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new TreeSelectFilter(container, fieldObject), props);
        return ctrl;
      };
    },
    createDateTime: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new DateTimeFilter(container, fieldObject), props);
        if (wdiCore.Object.IsNullOrUndefined(ctrl.antdProps)) {
          ctrl.antdProps = {
            format: 'YYYY-MM-DD',
          };
        }
        return ctrl;
      };
    },
    createQuarter: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new DateTimeFilter(container, fieldObject), props);
        if (wdiCore.Object.IsNullOrUndefined(ctrl.antdProps)) {
          ctrl.antdProps = {
            picker: 'quarter',
            format: 'YYYY\\QQ',
          };
        }
        return ctrl;
      };
    },
    createYear: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new DateTimeFilter(container, fieldObject), props);
        if (wdiCore.Object.IsNullOrUndefined(ctrl.antdProps)) {
          ctrl.antdProps = {
            picker: 'year',
            format: 'YYYY',
          };
        }
        return ctrl;
      };
    },
    createYM: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new DateTimeFilter(container, fieldObject), props);
        if (wdiCore.Object.IsNullOrUndefined(ctrl.antdProps)) {
          ctrl.antdProps = {
            picker: 'month',
            format: 'YYYYMM',
          };
        }
        return ctrl;
      };
    },
    createEQDateTime: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new EQDateTimeFilter(container, fieldObject), props);
        if (wdiCore.Object.IsNullOrUndefined(ctrl.antdProps)) {
          ctrl.antdProps = {
            format: 'YYYY-MM-DD',
          };
        }
        return ctrl;
      };
    },
    createEQQuarter: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new EQDateTimeFilter(container, fieldObject), props);
        if (wdiCore.Object.IsNullOrUndefined(ctrl.antdProps)) {
          ctrl.antdProps = {
            picker: 'quarter',
            format: 'YYYY\\QQ',
          };
        }
        return ctrl;
      };
    },
    createEQYear: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new EQDateTimeFilter(container, fieldObject), props);
        if (wdiCore.Object.IsNullOrUndefined(ctrl.antdProps)) {
          ctrl.antdProps = {
            picker: 'year',
            format: 'YYYY',
          };
        }
        return ctrl;
      };
    },
    createEQYM: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new EQDateTimeFilter(container, fieldObject), props);
        if (wdiCore.Object.IsNullOrUndefined(ctrl.antdProps)) {
          ctrl.antdProps = {
            picker: 'month',
            format: 'YYYYMM',
          };
        }
        return ctrl;
      };
    },
    createNumber: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        return Object.assign(new InputNumberFilter(container, fieldObject), props);
      };
    },
    createEQNumber: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        return Object.assign(new EQNumberFilter(container, fieldObject), props);
      };
    },
    createTextParse: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new TextParseFilter(container, fieldObject), props);
        return ctrl;
      };
    },
    createComboboxParse: function (props: any) {
      return function (container: any, fieldObject: API.FieldObject) {
        const ctrl = Object.assign(new SelectParseFilter(container, fieldObject), props);
        return ctrl;
      };
    },
  };
}

const getFilterStates = (container: any, allFields: {
  [key: string]: API.FieldObject
}, names: string[], settings: any): NsGridFilter.IBasicFilter[] => {
  const filterStates: NsGridFilter.IBasicFilter[] = [];
  if (names) {
    names.forEach(function (item, itemIndex) {
      const filterState = getFilterState(container, allFields, item, settings);
      if (!wdiCore.Object.IsNullOrUndefined(filterState)) {
        filterStates.push(filterState);
      }
    });
  }
  return filterStates;
};

const getFilterState = (container: any, allFields: {
  [key: string]: API.FieldObject
}, key: string, settings: any): NsGridFilter.IBasicFilter => {
  const fieldObject = allFields[key];
  let filterState;
  if (!wdiCore.Object.IsNullOrUndefined(fieldObject)) {
    if (!wdiCore.String.IsNullOrEmpty(fieldObject.type)) {
      const typeClass = NsGridFilter.FilterFactory[fieldObject.type];
      if (!wdiCore.Object.IsNullOrUndefined(typeClass)) {
        filterState = new typeClass(container, fieldObject);
      }
    }

    if (!wdiCore.Object.IsNullOrUndefined(fieldObject.getFilterStyle)) {
      filterState = fieldObject.getFilterStyle(container, fieldObject);
    }

    if (wdiCore.Object.IsNullOrUndefined(filterState)) {
      filterState = new NsGridFilter.FilterFactory.Empty(container, fieldObject);
    }
  }
  return filterState;
};

/// filterStates: 过滤数据列
/// settings.fixedQueryValues: 固定过滤条件
const getFilterOptions = (
  filterStates: NsGridFilter.IBasicFilter[],
  isDefault: boolean,
  settings?: {
    fixedQueryValues?: NsGridFilter.FilterItemValue[] | NsGridFilter.FilterItemValue;
  },
): API.ResponseResult<NsGridFilter.FilterItemValue[]> => {
  let filterOptions: NsGridFilter.FilterItemValue[] = [];
  let isOK = true;
  let errorMessage: string = '';
  (filterStates ? filterStates : []).forEach((item) => {
    const filterOption = isDefault ? item.getDefaultValue() : item.getValue();
    if (!wdiCore.Object.IsNullOrUndefined(filterOption)) {
      if (filterOption instanceof Array) {
        filterOptions = filterOptions.concat(filterOption);
      } else {
        filterOptions.push(filterOption as NsGridFilter.FilterItemValue);
      }
    } else {
      if (item.isRequired) {
        isOK = false;
        errorMessage = item.name + '不能为空';
      }
    }
  });
  if (!isOK) {
    // 出现错误也需要, 返回数据，调整数据列需要根据当前数据进行，还原查询条件
    return {
      code: ResponseCode.UNKNOWN,
      message: errorMessage,
      data: filterOptions,
    };
  }
  if (settings && settings.fixedQueryValues) {
    if (settings.fixedQueryValues instanceof Array) {
      filterOptions = filterOptions.concat(settings.fixedQueryValues);
    } else {
      filterOptions.push(settings.fixedQueryValues);
    }
  }
  return {
    code: ResponseCode.SUCCESS,
    message: errorMessage,
    data: filterOptions,
  };
};

const FilterUtility = NsGridFilter.FilterFactory;
const SingleCell = NsGridFilter.SingleCell;
const GridWidgetTreeSelect = NsGridFilter.TreeSelectComponent;

export {
  getFilterStates,
  getFilterState,
  FilterUtility,
  SingleCell,
  GridWidgetTreeSelect,
  getFilterOptions
};
