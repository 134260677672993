import useWindowSize from '@/utils/useWindowSize';
import { Drawer, DrawerProps } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

export type WdiPromptInstance = {
  enable: boolean,
  /**开票页面跳转提醒 */
  setEnable: (v: boolean) => void,
  promptMessage: () => string,
  /**设置跳转提醒信息 */
  setPromptMessage: (fn: () => string) => void,
  /**移除最近加入的跳转提醒信息 */
  popPromptMessage: () => void,
  addPageChangeListener: (fn: (a?: any) => void) => void,
  popPageChangeListener: () => void,
  pageChange: () => void,
}

const WdiPromptContext = React.createContext<WdiPromptInstance>(null!);
export let GlobalPromptInstance: WdiPromptInstance;

export default WdiPromptContext;
let PromptContextProviderCount = 0;

function DefaultPromptMessage() {
  return "你确定要离开么？";
}

export const WdiPromptContextProvider = ({ children }: { children: React.ReactNode }) => {
  useEffect(
    () => {
      console.log('Create PromptContextProvider', PromptContextProviderCount);
      PromptContextProviderCount++;
    },
    []
  );
  const [enable, setEnable] = useState(false);
  const promptRef = useRef<{ promptMessage: (() => string)[], listeners: ((a?: any) => void)[] }>({
    promptMessage: [DefaultPromptMessage],
    listeners: []
  });
  
  GlobalPromptInstance = {
    enable,
    setEnable,
    promptMessage: () => {
      return promptRef.current.promptMessage[promptRef.current.promptMessage.length - 1]();
    },
    setPromptMessage: (fn) => { promptRef.current.promptMessage = [...promptRef.current.promptMessage, fn]; },
    popPromptMessage: () => {
      console.log('popPromptMessage');
      promptRef.current.promptMessage.pop();
      promptRef.current.promptMessage = [...promptRef.current.promptMessage];
    },
    addPageChangeListener: (fn) => {
      console.log('addPageChangeListener');
      promptRef.current.listeners = [...promptRef.current.listeners, fn];
    },
    popPageChangeListener: () => {
      console.log('popPageChangeListener');
      promptRef.current.listeners.pop();
      promptRef.current.listeners = [...promptRef.current.listeners];
    },
    pageChange: () => {
      console.log('pageChange', promptRef.current.listeners);
      promptRef.current.listeners.forEach((listener: any) => listener?.());
    }
  };
  return (
    <WdiPromptContext.Provider value={GlobalPromptInstance}>
      {children}
    </WdiPromptContext.Provider>
  )
}

export type WdiPageDrawerProps = {
  /**关闭确认函数，用来代替默认的 window.confirm */
  onCloseConfirm?: () => boolean,
  /**关闭确认信息 */
  closeConfirmMessage?: () => string
  /**禁用关闭确认,这个是优先级最高的判断 */
  disableCloseConfirm?: boolean
} & DrawerProps;

export const WdiPromptDrawer: React.FC<WdiPageDrawerProps> = (props: WdiPageDrawerProps) => {
  const promptCtx = useContext(WdiPromptContext);
  const { title, children, onClose, visible, onCloseConfirm, disableCloseConfirm, closeConfirmMessage, ...antdProps } = props;
  const { width } = useWindowSize();
  const hasConfirm = disableCloseConfirm ? false : true;
  const promptMessage = () => closeConfirmMessage?.() || "系统不会保存您所做的更改，确定要关闭此界面吗？";
  useEffect(
    () => {
      if (visible) {
        console.log('prompt enable');
        if (hasConfirm) {
          promptCtx.setEnable(true);
          promptCtx.setPromptMessage(promptMessage);
        }
      }
      return () => {
        if (visible) {
          console.log('prompt disable');
          if (hasConfirm) {
            promptCtx.setEnable(false);
            promptCtx.popPromptMessage();
          }
        }
      };
    },
    []
  );
  const onCloseHandler = (e: any) => {
    if (disableCloseConfirm
      || onCloseConfirm?.()
      || window.confirm(promptMessage())
    ) {
      onClose?.(e);
    }
  }

  return (
    <Drawer
      {...antdProps}
      onClose={onCloseHandler}
      title={title}
      destroyOnClose={true}
      width={width - 208}
      visible={visible}
      bodyStyle={{ padding: '0 32px' }}
      mask={false}
    >
      {children}
    </Drawer>
  );
}
