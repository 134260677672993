import React, {useEffect, useRef, useState} from 'react';
import {wdiCore, WdiModalAppProps} from '@/components/WdiControls';
import {WdiModalForm} from '@/components/WdiControls/WdiModalForm';
import {Button, Col, Form, Input, message, Radio, RadioChangeEvent, Row, TableColumnsType} from 'antd';
import {ProFormInstance} from '@ant-design/pro-form';
import {WdiSelect} from '@/components/WdiControls/WdiSelect';
import {RejectReason} from '@/services/flow/rejectreason';
import {ProcessInstance} from '@/services/flow';
import {formatter, request, ResponseStatus} from '@/utils/request';
import {WdiTable} from '@/components/WdiControls/WdiTable';
import DataStore from '@/components/GridWidget/DataStore';
import {getParameter} from './data';

const ApprovalModalApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isAgree, setIsAgree] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');
  const [dataSource, setDataSource] = useState<API.FlowNode[]>([]);
  useEffect(() => {
    setLoading(true);
    let processId = props?.data?.form?.processId || 0;

    ProcessInstance.getFlowUsers(processId).then(response => {
      var approversWaitingFor = '';
      if (response.code === ResponseStatus.SUCCESS && response.data) {
        response.data.forEach((item, rowIndex) => {
          if (rowIndex > 1) {
            return false;
          }
          approversWaitingFor += item.staffName;
          approversWaitingFor += ',';
          return true;
        })
        approversWaitingFor = approversWaitingFor.substring(0, approversWaitingFor.length - 1);
        if (response.data.length > 2) {
          approversWaitingFor += '…';
        }
      }
      setTitle('等待' + approversWaitingFor + '审批');
    });

    ProcessInstance.getFlowTrace(processId).then(response => {
      setDataSource(DataStore.init(response?.data));
    })

    setLoading(false);
  }, [props.data]);

  const onRadioChange = ({target: {value}}: RadioChangeEvent) => {
    setIsAgree(value == 'true');
    if (value == 'true') {
      formRef.current?.setFieldsValue({
        approvalReason: '',
        rejectReason: undefined
      });
    }
  }

  const columns: TableColumnsType<API.FlowNode> = [
    {
      title: '操作人',
      width: 150,
      dataIndex: 'approver',
      ellipsis: true,
    },
    {
      title: '审批类型',
      width: 150,
      dataIndex: 'approveType',
      ellipsis: true,
    },
    {
      title: '操作时间',
      dataIndex: 'invoiceDate',
      width: 150,
      ellipsis: true,
      render: (_, record) => (
        wdiCore.Date.MinuteToString(record.approvedDate)
      ),
    },
    {
      title: '审批意见',
      width: 120,
      dataIndex: 'remark',
      ellipsis: true,
    },
  ];
  const submit = (entity: any) => {
    const {data} = props;
    const args = getParameter(data);
    args.items.push({name: "isAgree", value: entity.isAgree});
    args.items.push({name: "approvalReason", value: entity.approvalReason});
    setButtonLoading(true);
    request(data.url, formatter({method: 'POST', body: args})).then((response) => {
      if (response.code == ResponseStatus.SUCCESS) {
        message.success('审批成功');
      } else {
        message.error(response.message);
        setButtonLoading(false);
      }
      if (props.onSave) {
        props.onSave({
          code: ResponseStatus.SUCCESS,
          data: data
        });
      }
    });
  }

  return (
    <WdiModalForm
      formRef={formRef}
      initialValues={{
        isAgree: 'true'
      }}
      labelAlign='left'
      layout="horizontal"
      title={title}
      visible={props.visible}
      modalProps={{
        onCancel: () => {
          props.onClose?.()
        },
      }}
      submitter={{
        render: () => {
          return [
            <Button key="back" onClick={() => {
              props.onClose?.()
            }}>
              取消
            </Button>,
            <Button loading={buttonLoading} type="primary"
                    onClick={async () => {
                      const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                      if (values && values.errorFields && values.errorFields.length) {
                        return;
                      }
                      const {...entity} = values;
                      if (entity.isAgree == 'false' && !entity.approvalReason) {
                        message.error('请输入审批拒绝的原因');
                      } else {
                        if (props?.data?.tooltip) {
                          if (window.confirm(props?.data?.tooltip)) {
                            submit(entity);
                          }
                        } else {
                          submit(entity);
                        }
                      }
                    }}
            >
              确认
            </Button>,
          ];
        },
      }}
    >
      <Row gutter={[0, 0]}>
        <Col span={6}>
          <Form.Item name="isAgree">
            <Radio.Group onChange={onRadioChange}>
              <Radio value='true'>同意</Radio>
              <Radio value='false'>拒绝</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name="rejectReason" wrapperCol={{span: 12}} hidden={isAgree}>
            <WdiSelect {...{
              placeholder: "请选择审批拒绝原因",
              dataLoading: async (params) => {
                const response = await RejectReason.search({formTypeId: props.data.form.formTypeId, isNull: true});
                return response.data.map((item) => {
                  return {value: item.rejectReasonId, label: item.title, content: item.content};
                });
              },
              onChange: (value, option: any) => {
                formRef.current?.setFieldsValue({
                  approvalReason: option ? option['content'] : "",
                });
              }
            }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="approvalReason">
        <Input.TextArea style={{resize: 'both'}}/>
      </Form.Item>
      <WdiTable
        columns={columns}
        showPageRows={3}
        rowKey="uid"
        dataSource={dataSource}
        pagination={false}
        loading={loading}
      />
    </WdiModalForm>
  );
};
export default ApprovalModalApp;
