import {useEffect, useState} from 'react';
import {DetailGrid, Utility, wdiCore} from '@/components/GridWidget';
import {WdiCardTitle, WdiModalAppResult, WdiModalProps} from '@/components/WdiControls';
import {Button, Input, message} from 'antd';
import {initFieldDisplayFormater, TableColumnRanderFormats} from '@/components/WdiBus/TableColumnRanderFormats';
import {NsGrid} from '@/components/GridWidget/GridDefProps';
import DataStore from '@/components/GridWidget/DataStore';
import RebateInfoSelectApp from './RebateInfoSelect';
import {ResponseStatus} from '@/utils/request';
import {WdiInputNumber} from '@/components/WdiControls/WdiInputNumber';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {ImportModalApp} from '@/components/WdiControls/WdiImport';
import {Enum} from '@/enums/system';
import {GridApi} from '@/services/gridapi';
import {VatType} from '@/services/mdm/vattype';
import {DataOptions} from '@/services/basic/metadata';
import {WdiSelect, WdiSelectFormat} from '@/components/WdiControls/WdiSelect';

const ModalAppTypeEnums = {
  None: 0,
  Insert: 1,
  Edit: 2,
  Import: 3,
  Select: 4,
};

export type DetailForApplyProps = {
  data?: any,
  dataSource: any[],
  onChange?: (dataSource: any) => void;
}

const RebatePaymentDetailsForApplyPanel: React.FC<DetailForApplyProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [detailModalProps, setDetailModalProps] = useState<WdiModalProps>({visible: false});
  const {data, dataSource, onChange} = props;
  const [vatTypeOptions, setVatTypeOptions] = useState<any[]>([]);
  const [invoiceTypeOptions, setInvoiceTypeOptions] = useState<any[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<any[]>([]);

  const initOptions = async (params?: any) => {
    const paymentResponse = await DataOptions.search({
      typeGroup: 5
    });
    setPaymentMethodOptions(paymentResponse.data.map((item) => {
      return {value: item.value, label: item.name};
    }));

    const invoiceTypeResponse = await DataOptions.search({
      typeGroup: 106
    });
    setInvoiceTypeOptions(invoiceTypeResponse.data.map((item) => {
      return {value: item.value, label: item.name};
    }));

    const vatTypeResponse = await VatType.search({});
    setVatTypeOptions(vatTypeResponse.data.map((item) => {
      return {value: item.vatTypeId, label: item.vatTypeName};
    }));
  }

  const onDetailClose = () => {
    setDetailModalProps({visible: false, title: "", type: ModalAppTypeEnums.None, id: null, data: null});
  };

  const onDetailSave = (result?: WdiModalAppResult) => {
    const rows = result?.data;
    let resultData: any[] = [];
    if (rows && rows.length > 0) {
      if ((rows.length + dataSource.length) > 1000) {
        message.error("明细数量过多请分开申请");
        return;
      }
      rows.forEach((item: any) => {
        if (dataSource.findIndex(row => row.rebateInfoId == item.rebateInfoId) < 0) {
          item.amountSs = item.amountRs;
          item.remark = null;

          resultData.push(item);
        }
      });
      if (resultData.length > 0) {
        onChange?.(DataStore.addMulti(dataSource, resultData));
      }
    }
    setDetailModalProps({visible: false, title: "", type: ModalAppTypeEnums.None, id: null, data: null});
  };

  const onImportSave = (uploadResult: any) => {
    if (uploadResult.code == ResponseStatus.SUCCESS) {
      const rows = uploadResult?.data?.data;
      if (uploadResult?.data?.exProperty) {
        if (rows && rows.length > 0) {
          onChange?.(DataStore.addMulti([], rows));
        }
      } else {
        let resultData = [...dataSource];
        if (rows && rows.length > 0) {
          rows.forEach((item: any) => {
            let record = resultData.find(row => row.rebateInfoId == item.rebateInfoId);
            if (record) {
              item.amountSs = item.amountRs;
            
              resultData = DataStore.edit(resultData, record);
            } else {
              item.amountSs = item.amountRs;

              resultData = DataStore.add(resultData, item);
            }
          });
          onChange?.(resultData);
        }
      }
    }
    setDetailModalProps({visible: false, title: "", type: ModalAppTypeEnums.None, id: null, data: null});
  }

  useEffect(() => {
    initOptions();

    GridApi.searchConfig({id: 'FormRebateSPDetail', allowModifyColumns: false}).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.amountNotPaid.aggr = TableColumnRanderFormats.sumAggr('amountNotPaid', 2);
      settings.formSchema.allFields.amountSs.aggr = TableColumnRanderFormats.sumAggr('amountSs', 2);
      settings.formSchema.allFields.amountExVat.aggr = TableColumnRanderFormats.sumAggr('amountExVat', 2);

      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.rebateInfoId.width = 100;
      settings.formSchema.allFields.speriod.width = 100;
      settings.formSchema.allFields.soldToCode.width = 200;
      settings.formSchema.allFields.ecName.width = 150;
      settings.formSchema.allFields.amountNotPaid.width = 100;
      settings.formSchema.allFields.amountSs.width = 100;
      settings.formSchema.allFields.amountExVat.width = 150;

      settings.formSchema.allFields.vatTypeId.getDisplayFormater = (text: any, record: any, index: any) => {
        return record.vatTypeName
      };
      settings.formSchema.allFields.paymentMethodId.getDisplayFormater = (text: any, record: any, index: any) => {
        return record.paymentMethodName
      };
      settings.formSchema.allFields.invoiceType.getDisplayFormater = (text: any, record: any, index: any) => {
        return record.invoiceTypeName
      };

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true
    },
  };
  if (pageGridSettings && pageGridSettings.formSchema && pageGridSettings.formSchema.actions) {
    Utility.setActionCallback(
      pageGridSettings.formSchema.actions,
      'SystemDelete',
      async (grid: any, action: any, record: any) => {
        if (confirm("确定要删除该行吗？")) {
          onChange?.(DataStore.del(dataSource, record.uid));
          message.success("删除成功");
        }
      },
    );
  }

  const editColumn = {
    vatTypeId: (_t: any, record: any, index: any) => {
      return <WdiSelect {...{
        style: {width: '100%'},
        value: record.vatTypeId ? {
          label: record.vatTypeName,
          value: record.vatTypeId,
        } : null,
        placeholder: '请选择税率',
        remote: false,
        labelInValue: true,
        options: vatTypeOptions,
        onChange: (value) => {
          const {...newRecord} = record;
          newRecord.vatTypeId = null;
          newRecord.vatTypeName = null;
          if (value) {
            newRecord.vatTypeId = WdiSelectFormat.getvalue(value);
            newRecord.vatTypeName = WdiSelectFormat.gettext(value);
          }
          onChange?.(DataStore.edit(dataSource, newRecord));
        },
      }} />;
    },
    paymentMethodId: (_t: any, record: any, index: any) => {
      return <WdiSelect {...{
        style: {width: '100%'},
        value: record.paymentMethodId ? {
          label: record.paymentMethodName,
          value: record.paymentMethodId,
        } : null,
        placeholder: '请选择支付方式',
        remote: false,
        options: paymentMethodOptions,
        labelInValue: true,
        onChange: (value) => {
          const {...newRecord} = record;
          newRecord.paymentMethodId = null;
          newRecord.paymentMethodName = null;
          if (value) {
            newRecord.paymentMethodId = WdiSelectFormat.getvalue(value);
            newRecord.paymentMethodName = WdiSelectFormat.gettext(value);
          }

          //票扣其他赋值为空
          if (newRecord.paymentMethodId == Enum.PaymentMethod.BILL_DEDUCT) {
            newRecord.invoiceType = null;
            newRecord.invoiceTypeName = null;
            newRecord.vatTypeId = null;
            newRecord.vatTypeName = null;
          }

          onChange?.(DataStore.edit(dataSource, newRecord));
        },
      }} />;
    },
    invoiceType: (_t: any, record: any, index: any) => {
      return <WdiSelect {...{
        style: {width: '100%'},
        value: record.invoiceType ? {
          label: record.invoiceTypeName,
          value: record.invoiceType,
        } : null,
        placeholder: '请选择发票类型',
        remote: false,
        labelInValue: true,
        options: invoiceTypeOptions,
        onChange: (value) => {
          const {...newRecord} = record;
          newRecord.invoiceType = null;
          newRecord.invoiceTypeName = null;
          if (value) {
            newRecord.invoiceType = WdiSelectFormat.getvalue(value);
            newRecord.invoiceTypeName = WdiSelectFormat.gettext(value);
          }
          onChange?.(DataStore.edit(dataSource, newRecord));
        },
      }} />;
    },
    amountExVat: (_t: any, record: any, index: any) => {
      return <WdiInputNumber format='d' digits={2} value={record.amountExVat} onBlur={(e: any) => {
        const {...newRecord} = record;
        newRecord.amountExVat = wdiCore.Number.ToString(e.target.value, 2);
        newRecord.amountInVat = wdiCore.Number.ToString(e.target.value, 2);
        newRecord.tax = 0.00;
        onChange?.(DataStore.edit(dataSource, newRecord));
      }}/>
    },
    remark: (_t: any, record: any, index: any) => {
      return <Input value={record.remark} onChange={(e: any) => {
        const {...newRecord} = record;
        newRecord.remark = e.target.value;
        onChange?.(DataStore.edit(dataSource, newRecord));
      }}/>;
    }
  };

  return (
    <>
      <WdiPanel
        title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='付款明细信息'/>}
        extra={
          <>
            <Button type='primary' onClick={() => {
              const newDataSource = [...dataSource];
              newDataSource.forEach(item => {
                item.amountExVat = wdiCore.Number.ToNumber(item.amountNotPaid, 2);
                item.amountExVat = wdiCore.Number.ToNumber(item.amountNotPaid, 2);
                item.tax = 0.00;
              })
              onChange?.(newDataSource);
            }} style={{float: 'right', marginRight: 10}}>
              全部付款
            </Button>
            <Button
              type='primary'
              onClick={() => {
                setDetailModalProps({
                  visible: true, title: '选择合同返利单', type: ModalAppTypeEnums.Select, id: null, data: data
                });
              }}
              style={{float: 'right', marginRight: 10}}
            >选择返利单</Button>
            <Button type='primary' onClick={() => {
              setDetailModalProps({title: '费用明细导入', visible: true, type: ModalAppTypeEnums.Import});
            }}
                    style={{float: 'right', marginRight: 10}}>
              导入
            </Button>
          </>
        }
      >
        {isLoaded && detailModalProps.visible && detailModalProps.type == ModalAppTypeEnums.Select &&
          <RebateInfoSelectApp
            {...detailModalProps}
            onClose={onDetailClose}
            onSave={onDetailSave}
          />
        }
        {
          isLoaded &&
          detailModalProps.visible && detailModalProps.type == ModalAppTypeEnums.Import &&
          <ImportModalApp
            title={detailModalProps.title}
            visible={detailModalProps.visible}
            settingsUrl="/activityapi/spButtons/getImportSettings"
            uploadingUrl="/activityapi/spButtons/importUploading"
            onClose={onDetailClose}
            onSave={onImportSave}
            clientParameters={{
              pageTypeId: data.pageTypeId,
              // soldToCode: data.soldToCode,
              // paymentMethodId: data.paymentMethodId,
              // vatTypeId: data.vatType.vatTypeId,
              details: JSON.stringify(dataSource),
              isCreate: true
            }}
          />
        }
        {isLoaded &&
          <DetailGrid {...pageGridSettings} params={data} editColumn={editColumn} autoColumn={['rowNumber']}/>}
      </WdiPanel>
    </>
  );
}
export default RebatePaymentDetailsForApplyPanel;

export const RebatePaymentDetailsForApprovalPanel: React.FC<DetailForApplyProps> = (props) => {

  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});

  useEffect(() => {
    GridApi.searchConfig({id: 'FormRebateSPDetailForApproval', allowModifyColumns: true}).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.None,
        showAggr: true,
        showExpandedRow: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.amountNotPaid.aggr = TableColumnRanderFormats.sumAggr('amountNotPaid', 2);
      settings.formSchema.allFields.amountSs.aggr = TableColumnRanderFormats.sumAggr('amountSs', 2);
      settings.formSchema.allFields.amountExVat.aggr = TableColumnRanderFormats.sumAggr('amountExVat', 2);

      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.rebateInfoId.width = 100;
      settings.formSchema.allFields.speriod.width = 100;
      settings.formSchema.allFields.soldToCode.width = 200;
      settings.formSchema.allFields.ecName.width = 150;
      settings.formSchema.allFields.amountNotPaid.width = 100;
      settings.formSchema.allFields.amountSs.width = 100;
      settings.formSchema.allFields.amountExVat.width = 150;


      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);
  const maxAllowedFields = ["rowNumber", "rebateInfoId", "soldToCode", "soldToMasterCode", "custSysCode", "ecName", "productLineId", "speriod", "paymentMethodName", "invoiceTypeName", "vatTypeName", "amount", "amountSs", "amountNotPaid", "amountExVat", "remark", "fperiod", "revSoldTo", "revenueTypeName", "rateOriginal", "originalAmountSa", "rateFinal", "amountExVat", "amountPaid", "settlementExplain", "bgName", "bohqName", "isReverse", "poolPrepayReverseId", "formSpDetailId"];
  const {dataSource} = props;
  const pageGridSettings = Object.assign({}, gridSettings);
  pageGridSettings.antdProps = {
    dataSource,
    initRowFieldConfigs: (record: any) => {
      return maxAllowedFields;
    },
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true
    },
  };

  return (
    <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='付款明细信息'/>} collapsed={true}>
      {isLoaded && <DetailGrid {...pageGridSettings} />}
    </WdiPanel>
  );
};
