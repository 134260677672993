import {Button, DatePicker, Input, message, TablePaginationConfig} from "antd";
import {ColumnsType, SorterResult} from "antd/lib/table/interface";
import {wdiCore, WdiModalAppProps} from "@/components/WdiControls";
import React, {Key, useEffect, useState} from "react";
import {WdiModal} from "@/components/WdiControls/WdiModalForm";
import {ResponseStatus} from "@/utils/request";
import {ButtonColumn, FilterColumn, GridFilterForm} from "@/components/GridWidget/GridFilterForm";
import {WdiTable} from "@/components/WdiControls/WdiTable";
import {WdiSelect} from "@/components/WdiControls/WdiSelect";
import {SoldTo} from "@/services/mdm/soldto";
import moment from "moment";
import {SP} from "@/services/report/sp";
import {DataOptions} from "@/services/basic/metadata";
import {VatType} from "@/services/mdm/vattype";
import {toExcel} from "@/components/WdiControls/WdiExcel";

const {RangePicker} = DatePicker;

type TableParams = {
  pagination: TablePaginationConfig;
  sorter?: SorterResult<any> | null | undefined;
  filterOptions: any,
}
const FormSpDetailSelect: React.FC<WdiModalAppProps> = (props) => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [allDataSource, setAllDataSource] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const {data} = props.data;
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      defaultPageSize: 10,
      total: 0,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
    },
    sorter: null,
    filterOptions: null,
  });
  const filterColumns: FilterColumn[] = [
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      span: 4,
      render: () => {
        return <WdiSelect {...{
          placeholder: '请选择售达方',
          showSearch: true,
          remote: true,
          dataLoading: async (params) => {
            const response = await SoldTo.ajaxSearch(Object.assign({soldToMasterCode: data.soldToMasterCode}, params));
            return response.data.map((item: { soldToCode: any; codeOrName: any; }) => {
              return {value: item.soldToCode, label: item.codeOrName};
            });
          },
        }} />;
      },
    },
    {
      title: '活动期间',
      dataIndex: 'ym',
      span: 8,
      render: () => {
        return <RangePicker allowEmpty={[true, true]} picker='month' style={{width: '100%'}}/>;
      },
    },
    {
      title: '付款单编号',
      dataIndex: 'formSpNo',
      span: 4,
      render: () => {
        return <Input/>;
      },
    },
    {
      title: '支付方式',
      dataIndex: 'paymentMethodId',
      span: 4,
      render: () => {
        return <WdiSelect {...{
          placeholder: '请选择支付方式',
          showSearch: true,
          remote: true,
          dataLoading: async (params) => {
            const response = await DataOptions.search(Object.assign({typeGroup: 5, inValues: '2,3,5,6'}, params));
            return response.data.map((item: any) => {
              return {value: item.value, label: item.name};
            });
          },
        }} />;
      },
    },
    {
      title: '税率类型',
      dataIndex: 'vatTypeId',
      span: 4,
      render: () => {
        return <WdiSelect {...{
          placeholder: '请选择税率类型',
          showSearch: true,
          remote: true,
          dataLoading: async (params) => {
            const response = await VatType.search(Object.assign({isActive: true}, params));
            return response.data.map((item: any) => {
              return {value: item.vatTypeId, label: item.vatTypeName};
            });
          },
        }} />;
      },
    },
    {
      title: '发票类型',
      dataIndex: 'invoiceType',
      span: 4,
      render: () => {
        return <WdiSelect {...{
          placeholder: '请选择发票类型',
          showSearch: true,
          remote: true,
          dataLoading: async (params) => {
            const response = await DataOptions.search(Object.assign({typeGroup: 106}, params));
            return response.data.map((item: any) => {
              return {value: item.value, label: item.name};
            });
          },
        }} />;
      },
    },
  ]
  const columns: ColumnsType<any> = [
    {
      title: '付款明细编号',
      width: 100,
      dataIndex: 'formSpDetailId',
      ellipsis: true,
    },
    {
      title: '付款单号',
      width: 150,
      dataIndex: 'formSpNo',
      ellipsis: true,
    },
    {
      title: '售达方编号',
      width: 150,
      dataIndex: 'soldToCode',
      ellipsis: true,
    },
    {
      title: '售达方名称',
      width: 150,
      dataIndex: 'soldToName',
      ellipsis: true,
    },
    {
      title: '费用项',
      width: 100,
      dataIndex: 'ecName3',
      ellipsis: true,
    },
    {
      title: '活动开始日期',
      width: 100,
      dataIndex: 'activityStartDate',
      ellipsis: true,
    },
    {
      title: '活动截止日期',
      width: 100,
      dataIndex: 'activityEndDate',
      ellipsis: true,
    },
    {
      title: '付款金额',
      width: 100,
      dataIndex: 'spAmount',
      ellipsis: true,
      render: (text: any) => {
        return text == null ? '' : text.toFixed(2);
      }
    },
    {
      title: '支付方式',
      width: 100,
      dataIndex: 'paymentMethodName',
      ellipsis: true,
    },
    {
      title: '发票类型',
      width: 100,
      dataIndex: 'invoiceTypeName',
      ellipsis: true,
    },
    {
      title: '税率类型',
      width: 100,
      dataIndex: 'vatTypeName',
      ellipsis: true,
    },
    {
      title: '客户集团编号',
      width: 150,
      dataIndex: 'custSysCode',
      ellipsis: true,
    },
    {
      title: '客户集团名称',
      width: 150,
      dataIndex: 'custSysName',
      ellipsis: true,
    },
    {
      title: '活动名称',
      width: 150,
      dataIndex: 'activityName',
      ellipsis: true,
    },
    {
      title: '产品线编号',
      width: 150,
      dataIndex: 'productLineCode',
      ellipsis: true,
    },
    {
      title: '产品线名称',
      width: 150,
      dataIndex: 'productLineName',
      ellipsis: true,
    },
  ];
  const doSearch = (filterOptions: any) => {
    fetchData(Object.assign({}, tableParams, {filterOptions}));
  };
  const getFilterOptions = (params: TableParams) => {
    const filterOptions: any = {
      soldToMasterCode: data.soldToMasterCode,
      year: data.year,
      prepayType: data.prepayType,
      isReverse: false
    };
    if (params.filterOptions) {
      const {soldToCode, ym, formSpDetailId, paymentMethodId, vatTypeId, formSpNo, invoiceType} = params.filterOptions;
      if (!wdiCore.String.IsNullOrEmpty(soldToCode)) {
        filterOptions.soldToCode = soldToCode;
      }
      if (!wdiCore.String.IsNullOrEmpty(formSpDetailId)) {
        filterOptions.formSpDetailId = formSpDetailId;
      }
      if (!wdiCore.String.IsNullOrEmpty(paymentMethodId)) {
        filterOptions.paymentMethodId = paymentMethodId;
      }
      if (!wdiCore.String.IsNullOrEmpty(vatTypeId)) {
        filterOptions.vatTypeId = vatTypeId;
      }
      if (!wdiCore.String.IsNullOrEmpty(formSpNo)) {
        filterOptions.formSpNo = formSpNo;
      }
      if (!wdiCore.String.IsNullOrEmpty(invoiceType)) {
        filterOptions.invoiceType = invoiceType;
      }
      if (!wdiCore.String.IsNullOrEmpty(ym)) {
        const ymArray: any[] = ym.map((item: any) => {
          return item == null ? null : moment(item).format('YYYYMM');
        });
        filterOptions.startYm = ymArray[0];
        filterOptions.endYm = ymArray[1];
      }
    }
    return filterOptions;
  };
  const fetchData = (params: TableParams) => {
    setLoading(true);
    const filterOptions = getFilterOptions(params);
    // @ts-ignore
    const req: API.QueryForPageReq = {
      filterOptions: JSON.stringify(filterOptions),
      pagenum: params.pagination.current ? (params.pagination.current - 1) : 0,
      pagesize: params.pagination.pageSize ? params.pagination.pageSize : 10,
    };
    SP.getFormSpDetailsForPrepay(req).then(responseResult => {
      const data = responseResult.data;
      if (data) {
        setDataSource(data.records);
        setTableParams({
          pagination: {
            ...params.pagination,
            total: data.totalCount,
          },
          sorter: params.sorter,
          filterOptions: params.filterOptions,
        });
        setLoading(false);
      }
    });
    const reqAll: API.QueryForPageReq = {
      filterOptions: JSON.stringify(filterOptions),
      pagesize: -1,
      pagenum: -1
    };
    SP.getFormSpDetailsForPrepay(reqAll).then(responseResult => {
      const data = responseResult.data;
      if (data) {
        setAllDataSource(data.records);
        setLoading(false);
      }
    });
  };
  const onSelectChange = (newSelectedRowKeys: Key[], newSelectedRows: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };
  useEffect(() => {
    fetchData(Object.assign({}, tableParams));
  }, [data]);

  const buttonColumns: ButtonColumn[] = [
    {
      title: '导出',
      key: 'Export',
      doClick: (filterOptions: any) => {
        let columns: any[] = [];
        columns = [
          {name: "formSpDetailId", displayName: "付款明细编号"},
          {name: "formSpNo", displayName: "付款单号"},
          {name: "soldToCode", displayName: "售达方编号"},
          {name: "soldToName", displayName: "售达方名称"},
          {name: "ecName3", displayName: "费用项"},
          {name: "activityStartDate", displayName: "活动开始日期"},
          {name: "activityEndDate", displayName: "活动截止日期"},
          {name: "spAmount", displayName: "付款金额"},
          {name: "paymentMethodName", displayName: "支付方式"},
          {name: "invoiceTypeName", displayName: "发票类型"},
          {name: "vatTypeName", displayName: "税率类型"},
          {name: "custSysCode", displayName: "客户集团编号"},
          {name: "custSysName", displayName: "客户集团名称"},
          {name: "activityName", displayName: "活动名称"},
          {name: "productLineCode", displayName: "产品线编号"},
          {name: "productLineName", displayName: "产品线名称"},
        ];
        const req: API.QueryForPageReq = {
          filterOptions: JSON.stringify(getFilterOptions(Object.assign({}, tableParams, {filterOptions}))),
          pagenum: 0,
          pagesize: 0
        };
        SP.getFormSpDetailsForPrepay(req).then(response => {
          const sheet = {
            data: response?.data?.records,
            names: columns,
            sheetName: "formSpDetails",
          };
          toExcel({
            name: "付款信息_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
            sheetProps: [sheet]
          });
        });
      }
    },
  ]

  return (
    <>
      <WdiModal
        title={props.title}
        visible={props.visible}
        width={1400}
        footer={[
          <Button key='onCancel' onClick={() => {
            props.onClose?.();
          }}>
            取消
          </Button>,
          <Button key='allSubmit' type='primary' onClick={() => {
            if (dataSource.length == 0) {
              message.error('请至少添加一条数据');
            } else {
              props.onSave?.({
                code: ResponseStatus.SUCCESS,
                data: allDataSource,
              });
            }
          }}>
            全部添加
          </Button>,
          <Button key='submit' type='primary' onClick={() => {
            if (selectedRows.length == 0) {
              message.error('请至少选择一条数据');
            } else {
              props.onSave?.({
                code: ResponseStatus.SUCCESS,
                data: selectedRows,
              });
            }
          }}>
            添加
          </Button>,
        ]}
        onCancel={() => {
          props.onClose ? props.onClose() : null;
        }}
        maskClosable={false}
        destroyOnClose
      >
        <GridFilterForm columns={filterColumns} doSeach={doSearch} buttonColumns={buttonColumns}/>
        <WdiTable
          columns={columns}
          showPageRows={10}
          rowKey='formSpDetailId'
          dataSource={dataSource}
          rowSelection={{selectedRowKeys, onChange: onSelectChange}}
          pagination={{
            ...tableParams.pagination,
            onChange: (page, pageSize) => {
              fetchData(Object.assign({}, tableParams, {
                pagination: {
                  ...tableParams.pagination,
                  current: page,
                  pageSize: pageSize,
                },
              }));
            },
          }}
          loading={loading}
        />
      </WdiModal>
    </>
  )
}
export default FormSpDetailSelect;
