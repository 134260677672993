export namespace LevelEnum {
  export enum BOLevel {
    //根节点
    ROOT = 0,
    //一级预算中心
    BO1 = 1,
    //二级预算中心
    BO2 = 2,
    //三级预算中心
    BO3 = 3,
    //四级预算中心
    BO4 = 4,
  }

  export enum BgLevel {
    //预算组
    BG2 = 2,
  }

  export enum ECLevel {
    //根节点
    ROOT = 0,
    //1:费用大类
    EC1 = 1,
    //2:费用小类
    EC2 = 2,
    //3:费用项
    EC3 = 3
  }


  export enum SoSkuCategoryLevel {
    //根节点
    ROOT = 0,
    //1:
    LEVEL1 = 1,
    //2:
    LEVEL2 = 2,
    //3:
    LEVEL3 = 3,
    //4:
    LEVEL4 = 4

  }

  export enum DistrictLevel {
    ROOT = 0,
    DISTRICTLEVEL1 = 1,
    DISTRICTLEVEL2 = 2,
    DISTRICTLEVEL3 = 3
  }

  export enum ProductLevel {
    //根节点
    ROOT = 0,
    //品牌
    PRODUCT1 = 1,
    //产品线
    PRODUCT2 = 2,
    //产品段
    PRODUCT3 = 3,
    //产品组
    PRODUCT4 = 4,
    //SKU
    PRODUCT5 = 5
  }
}
