import DataStore from "@/components/GridWidget/DataStore";
import { getParameter } from "@/components/WdiBus/Buttons/data";
import { WdiModalAppProps, wdiCore } from "@/components/WdiControls";
import { WdiModalForm } from "@/components/WdiControls/WdiModalForm";
import { WdiSelect } from "@/components/WdiControls/WdiSelect";
import { WdiTable } from "@/components/WdiControls/WdiTable";
import { Enum } from "@/enums/system";
import { FormSaDetail } from "@/services/sales/sa";
import { formatter, request, ResponseStatus } from "@/utils/request";
import { ProFormInstance } from "@ant-design/pro-form";
import { message, TableColumnsType, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { paymentMethodDataloading } from "./data";

const { Paragraph } = Typography;

const ModifyPaymentMethodModalApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const columns: TableColumnsType<API.FormSaDetailVo> = [
    {
      title: '行号',
      width: 50,
      dataIndex: 'rowNumber',
    },
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.soldToCode, record.soldToName)}</span>
      ),
    },
    {
      title: '客户集团',
      dataIndex: 'custSysCode',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.custSysCode, record.custSysName)}</span>
      ),
    },
    {
      title: '门店',
      dataIndex: 'shopId',
      ellipsis: true,
      width: 240,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.shopCode, record.shopName)}</span>
      ),
    },
    {
      title: '产品别',
      dataIndex: 'productBuyNames',
      ellipsis: true,
      width: 240,
    },
    {
      title: '费用项',
      dataIndex: 'ecName',
      width: 150,
      ellipsis: true,
    },
    {
      title: '核算类型',
      dataIndex: 'revenueTypeName',
      ellipsis: true,
      width: 150,
    },
    {
      title: '产品政策',
      dataIndex: 'productPolicyId',
      ellipsis: true,
      width: 240,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.policyCode, record.policyName)}</span>
      ),
    },
    {
      title: '申请金额',
      dataIndex: 'amount',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
    {
      title: '支付方式',
      dataIndex: 'paymentMethodId',
      width: 120,
      ellipsis: true,
      render: (_: any, record) => (
        <WdiSelect  {...{
          value: { value: record.paymentMethodId, label: record.paymentMethodName },
          dataLoading: async (params) => {
            const response = await paymentMethodDataloading(String(record.soldToCode), Number(record.ecId));
            const paymentMethodOpts = response.filter(m => m.value != Enum.PaymentMethod.SPECIFIED_FREE_GOODS && m.value != Enum.PaymentMethod.NON_SPECIFIED_FREE_GOODS);
            return paymentMethodOpts.map((item) => { return { value: item.value, label: item.name }; });
          },
          onChange: (value, option: any) => {
            const { ...newRecord } = record;
            newRecord.paymentMethodId = value;
            newRecord.paymentMethodName = option.label;
            setDataSource(DataStore.edit(dataSource, newRecord));
          }
        }} style={{ width: '100%' }} />),
    },
  ]

  useEffect(() => {
    if (props.id) {
      const formSaId = Number(props.id);
      setLoading(true);
      FormSaDetail.getsEx(formSaId).then((response) => {
        if (response && response.data) {
          setDataSource(DataStore.init(response.data.filter(m =>
            m.paymentMethodId != Enum.PaymentMethod.SPECIFIED_FREE_GOODS
            && m.paymentMethodId != Enum.PaymentMethod.NON_SPECIFIED_FREE_GOODS))
          );
          setLoading(false);
        }
      });
    }
  }, [props.id]);

  return (
    <WdiModalForm
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      width={1350}
      modalProps={{
        onCancel: () => { props.onClose ? props.onClose() : null },
      }}
      onFinish={async () => {
        if (confirm("确定要进行支付方式的变更吗")) {
          const { data } = props;
          const args = getParameter(data);
          args.items.push({ name: "formSaDetails", value: JSON.stringify(dataSource) });
          request(data.url, formatter({ method: 'POST', body: args })).then((response) => {
            if (response.code == ResponseStatus.SUCCESS) {
              message.success('操作成功');
              if (props.onSave) {
                props.onSave();
              }
            } else {
              message.error(response.message);
            }
          });
        }
      }}
    >
      <Paragraph>注：指定货补、非指定货补的活动明细不能修改支付方式</Paragraph>
      <WdiTable
        rowKey="formSaDetailId"
        showPageRows={10}
        columns={columns}
        dataSource={dataSource}
        pagination={{ showSizeChanger: false }}
        loading={loading}
      />
    </WdiModalForm>
  );
};

export default ModifyPaymentMethodModalApp;