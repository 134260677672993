import {formatter, request} from "@/utils/request";

export namespace Platform {
  export const Path = {
    Get: '/mdmapi/platform/get',
    Search: '/mdmapi/platform/search',
    AjaxSearch: '/mdmapi/platform/ajaxSearch'
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.Platform>>(Path.Get, formatter(options));
  }

  export async function search(body: API.PlatformFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.Platform[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.PlatformFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.ResponseResult<API.Platform[]>>(Path.AjaxSearch, formatter(options));
  }


}
