import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { ProFormInstance } from '@ant-design/pro-form';
import { WdiModalAppResult, WdiModalAppProps } from '@/components/WdiControls';
import { ResponseStatus } from '@/utils/request';
import { WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { Position } from '@/services/basic/position';
import { WdiSelect } from '../WdiControls/WdiSelect';

const PositionSwitchApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  useEffect(() => {
    Position.searchForSwitch().then(response => {
      setDataSource(response?.data?.items.map((item: { id: number; name: string; }) => { return { value: item.id, label: response?.data?.roleId == item.id ? item.name + '(当前登陆职务)' : item.name } }));
      formRef.current?.setFieldsValue({
        StaffID: response?.data?.userId,
        RoleID: response?.data?.roleId,
      });

    })
  }, []);
  return (
    <>
      <WdiModalForm
        formRef={formRef}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        labelAlign='left'
        layout="horizontal"
        title={props.title}
        visible={props.visible}
        modalProps={{
          onCancel: () => { props.onClose ? props.onClose() : null },
          destroyOnClose: true,
          maskClosable: false
        }}
        onFinish={async (values) => {
          const { ...entity } = values;
          const response: WdiModalAppResult = {
            code: ResponseStatus.SUCCESS,
            data: entity
          }
          if (props.onSave) {
            props.onSave(response);
          }
          return true;
        }}
      >
        <Form.Item name="UserID" hidden >
          <Input />
        </Form.Item>
        <Form.Item
          name="RoleID"
          label=""
          rules={[{ required: true, message: '职务不能为空!' }]}
        >
          <WdiSelect {...{
            placeholder: "请选择职务",
            options: dataSource
          }} />
        </Form.Item>
        说明：职务切换会影响到审批流程和功能权限
      </WdiModalForm>
    </>
  );
};
export default PositionSwitchApp;
