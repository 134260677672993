import moment from 'moment';
import {FieldUtility} from '../GridWidget/GridWidgetUtility';
import {wdiCore} from '../WdiControls';

/**表格组件，一些通用的数据格式化方式 */
export const TableColumnRanderFormats = {
  /**显示到分 yyyy-MM-dd hh:mm*/
  ToMinutes: function (value: any, record: any, index: number) {
    return moment(value).format('YYYY-MM-DD HH:mm');// DateTime(value, 'yyyy-MM-dd hh:mm')
  },
  /**时间显示 yyyy-MM-dd */
  ToDate: function (value: any, record: any, index: number) {
    return moment(value).format('YYYY-MM-DD');// DateTime(value, 'yyyy-MM-dd')
  },
  sumAggr: function (key: string, digits?: number) {
    return (col: any, source: any, aggrProps: any) => {
      if (source) {
        let sum = 0;
        source.forEach((item: any) => {
          const amount = Number(item[key]);
          if (!Number.isNaN(amount)) {
            sum += amount;
          }
        })
        if (digits === undefined || digits < 0) {
          return String(sum);
        }
        return wdiCore.Number.AmountToString(sum, digits);
      }
      return '';
    }
  },
  mapSumAggr: function (key: string, digits?: number) {
    return (col: any, source: any, aggrProps: any) => {
      if (aggrProps && aggrProps[col.dataIndex]) {
        let sum = Number(aggrProps[col.dataIndex]);
        if (Number.isNaN(sum)) {
          return '';
        }
        if (digits === undefined || digits < 0) {
          return String(sum);
        }
        return wdiCore.Number.AmountToString(sum, digits);
      }
      return '';
    }
  },
};

export const TableFieldFormal: Record<string, (_t: any, record: any, index?: any) => any> = {
  boId: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.boName)) {
      return record.boName;
    }
    return ""
  },
  bgId: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.bgName)) {
      return record.bgName;
    }
    return ""
  },
  bohqId: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.bohqName)) {
      return record.bohqName;
    }
    return ""
  },
  ecId: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.ecName)) {
      return record.ecName;
    }
    return ""
  },
  ecMktId: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.ecMktName)) {
      return record.ecMktName;
    }
    return ""
  },
  shopId: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.shopCode, record.shopName);
    }
    return ""
  },
  productId: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.productCode, record.productName);
    }
    return ""
  },
  soldToCode: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.soldToCode, record.soldToName);
    }
    return ""
  },
  shipToCode: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.shipToCode, record.shipToName);
    }
    return ""
  },
  shipToCodes: (_t: any, record: any, index: any) => {
    if (record) {
      return record.shipToNames;
    }
    return ""
  },
  supplierId: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.supplierCode, record.supplierName);
    }
    return ""
  },
  paymentMethodId: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.paymentMethodName)) {
      return record.paymentMethodName;
    }
    return ""
  },
  displayTypeId: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.displayTypeName)) {
      return record.displayTypeName;
    }
    return ""
  },
  displayLocationId: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.displayLocationName)) {
      return record.displayLocationName;
    }
    return ""
  },
  revenueType: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.revenueTypeName)) {
      return record.revenueTypeName;
    }
    return ""
  },
  salesType: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.salesTypeName)) {
      return record.salesTypeName;
    }
    return ""
  },
  custSysCode: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.custSysCode, record.custSysName);
    }
    return ""
  },
  payToCode: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.payToCode, record.payToName);
    }
    return ""
  },
  productBuyIds: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.productBuyNames)) {
      return record.productBuyNames;
    }
    return ""
  },
  productFreeIds: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.productFreeNames)) {
      return record.productFreeNames;
    }
    return ""
  },
  syncStatus: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.syncStatusName)) {
      return record.syncStatusName;
    }
    return record.syncStatus;
  },
  allocatedBoIds: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.allocatedBoNames)) {
      return record.allocatedBoNames;
    }
    return ""
  },
  productPolicyId: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.policyCode, record.policyName);
    }
    return ""
  },
  assignProductId: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.assignProductCode, record.assignProductName);
    }
    return ""
  },
  startDate: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Date.Join(record.startDate, record.endDate);
    }
    return ""
  },
  rebateStartDate: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Date.Join(record.rebateStartDate, record.rebateEndDate);
    }
    return ""
  },
  generateType: (_t: any, record: any, index: any) => {
    if (record && !wdiCore.String.IsNullOrEmpty(record.generateTypeName)) {
      return record.generateTypeName;
    }
    return ""
  },
  quantitySellinRate: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.quantitySellinActual / record.quantitySellinForecast);
    }
    return ""
  },
  revenueSellinRate: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.revenueSellinActual / record.revenueSellinForecast);
    }
    return ""
  },
  quantitySelloutRate: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.quantitySelloutActual / record.quantitySelloutForecast);
    }
    return ""
  },
  revenueSelloutRate: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.revenueSelloutActual / record.revenueSelloutForecast);
    }
    return ""
  },
  quantitySelloutRate2: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.quantitySelloutActual2 / record.quantitySelloutForecast2);
    }
    return ""
  },
  quantitySelloutRate3: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.quantitySelloutActual3 / record.quantitySelloutForecast3);
    }
    return ""
  },
  quantitySelloutRate4: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.quantitySelloutActual4 / record.quantitySelloutForecast4);
    }
    return ""
  },
  quantitySelloutRate5: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.quantitySelloutActual5 / record.quantitySelloutForecast5);
    }
    return ""
  },
  quantityPosRate: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.quantityPosActual / record.quantityPosForecast);
    }
    return ""
  },
  revenuePosRate: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.revenuePosActual / record.revenuePosForecast);
    }
    return ""
  },
  proQuantityRate: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.proQuantityRate);
    }
    return ""
  },
  proQuantityRate1: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.proQuantityRate1);
    }
    return ""
  },
  proQuantityRate2: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Number.RateToString(record.proQuantityRate2);
    }
    return ""
  },
  districtId: (_t: any, record: any, index: any) => {
    if (record) {
      return record.districtName;
    }
    return ""
  },
  platformId: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.platformCode, record.platformName);
    }
    return ""
  },
  isConfirmed: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.Boolean.Display(record.isConfirmed);
    }
    return ""
  },
  productLineId: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.productLineCode, record.productLineName);
    }
    return ""
  },
  vatTypeId: (_t: any, record: any, index: any) => {
    if (record) {
      return record.vatTypeName;
    }
    return ""
  },
  invoiceType: (_t: any, record: any, index: any) => {
    if (record) {
      return record.invoiceTypeName;
    }
    return ""
  },
  soldToMasterCode: (_t: any, record: any, index: any) => {
    if (record) {
      return wdiCore.String.CodeOrName(record.soldToMasterCode, record.soldToMasterName);
    }
    return ""
  },
}

export const initFieldDisplayFormater = function (allFields: any) {
  for (var key in TableFieldFormal) {
    if (allFields[key]) {
      allFields[key].getDisplayFormater = TableFieldFormal[key];
    }
  }
};

const TableExpandedFormal = (key: string, record: any, fieldObject: any) => {
  let result;
  if (wdiCore.String.IsNullOrEmpty(result)) {
    if (!wdiCore.Object.IsNullOrUndefined(fieldObject.getDisplayFormater)) {
      result = fieldObject.getDisplayFormater(record[key], record);
    } else {
      for (const field in TableFieldFormal) {
        if (key == field) {
          result = TableFieldFormal[key]('', record);
        }
      }
    }
  }
  if (wdiCore.String.IsNullOrEmpty(result)) {
    const fieldCellFunc = FieldUtility[fieldObject.type];
    if (!wdiCore.Object.IsNullOrUndefined(fieldCellFunc)) {
      result = fieldCellFunc(fieldObject)(record[key]);
    } else {
      result = record[key];
    }
  }
  return result || "";
};

const getExpandedDisplayColumns = function (viewFieldNames: any, columnStates: any, record: any, initRowFieldConfigs?: (record: any) => string[]) {
  const leftFields: string[] = [];
  viewFieldNames.forEach((key: string) => {
    if (columnStates.findIndex((item: any) => (item.dataIndex == key)) < 0) {
      leftFields.push(key);
    }
  })

  const resultFields: string[] = [];
  if (initRowFieldConfigs) {
    const rowEcFieldConfigs = initRowFieldConfigs(record);
    leftFields.forEach((key: string) => {
      if (rowEcFieldConfigs.includes(key)) {
        resultFields.push(key);
      }
    })
  }
  const sortFeilds: string[] = [];
  viewFieldNames.forEach((key: string) => {
    if (resultFields.includes(key)) {
      sortFeilds.push(key);
    }
  })
  return sortFeilds;
};

export const initExpandedDisplayFormater = function (pageGridSettings: any, record: any, initRowFieldConfigs?: (record: any) => string[]) {
  const viewFieldNames = pageGridSettings.formSchema.viewFieldNames;
  const columnStates = pageGridSettings.columnStates;
  const allFields = pageGridSettings.formSchema.allFields;
  const generalFields = getExpandedDisplayColumns(viewFieldNames, columnStates, record, initRowFieldConfigs);
  return (
    <>
      {
        generalFields.map((key: string) =>
          <span key={key}>
            {allFields[key].displayName}：
            <span style={{color: '#808080'}}>{TableExpandedFormal(key, record, allFields[key]) || "(无)"}；   </span>
          </span>
        )
      }
    </>
  )
};
