import { request, formatter } from '@/utils/request';

export namespace Menus {

  const Path = {
    GetPageMenuModel: "/basicapi/menu/getPageMenuModel"
  }

  //请求API获得菜单
  export async function getUserMenus() {
    return request<API.ResponseResult<API.PageMenu>>(Path.GetPageMenuModel, formatter({
      method: 'GET',
      body: {},
    }));
  }

  //请求API获得菜单
  export async function getPageMenuModel() {
    return request<API.ResponseResult<API.PageMenu>>(Path.GetPageMenuModel, formatter({
      method: 'GET',
      body: {},
    }));
  }

  export async function getPageFavoriteMenu() {
    const options = {
      method: 'GET',
      body: {},
    };
    return request<API.ResponseResult<API.PageFavoriteMenu>>('/basicapi/menu/getPageFavoriteMenu', formatter(options));
  }

  export async function postBatchMyMenu(menuIds: string) {
    const options = {
      method: 'POST',
      body: {
        menuIds: menuIds
      },
    };
    return request<API.ResponseResult<API.PageFavoriteMenu>>('/basicapi/menu/postBatchMyMenu', formatter(options));
  }

  export async function canAccessUrl(url: string) {
    const options = {
      method: 'POST',
      body: {
        url
      },
    };
    return request<API.ResponseResult<boolean>>('/basicapi/menu/canAccessUrl', formatter(options));
  }
}


