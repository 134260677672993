import {formatter, request} from "@/utils/request";

export namespace SA {
  export const Path = {
    QueryForMy: '/reportapi/sa/queryForMy',
    QuerySa: '/reportapi/sa/query',
    Export: '/reportapi/sa/export',
    queryForActivity: '/reportapi/report/queryForActivity',
    exportForActivity: '/reportapi/report/exportForActivity',
    exportGeneralSaDetails: '/reportapi/sa/exportGeneralSaDetails',
    exportPdSaDetails: '/reportapi/sa/exportPdSaDetails',
    exportDealerSaDetails: '/reportapi/sa/exportDealerSaDetails',
    QueryForPicker: '/reportapi/sa/queryForPicker',
    QueryForSpPicker: '/reportapi/sa/queryForSpPicker',
    ExportForPicker: '/reportapi/sa/exportForPicker',
    DownLoadFormSaDetailPicker: '/reportapi/sa/downLoadFormSaDetailPicker',
    ExportSpForPicker: '/reportapi/sa/exportSpForPicker',
  };

  export async function queryForMy(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        sortdatafield: 'createdDate',
        sortorder: 'DESC',
        ...body,
      },
    };
    return request<API.QueryResult<any>>(SA.Path.QueryForMy, formatter(options));
  }

  export async function queryForPicker(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(SA.Path.QueryForPicker, formatter(options));
  }

  export async function queryForSpPicker(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(SA.Path.QueryForSpPicker, formatter(options));
  }


  export async function querySa(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body
      }
    };
    return request<API.QueryResult<any>>(SA.Path.QuerySa, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.Export, formatter(options));
  }

  export async function exportFormSaDetailsForPicker(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.ExportForPicker, formatter(options));
  }

  export async function exportFormSaDetailsSpForPicker(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.ExportSpForPicker, formatter(options));
  }

  export async function queryForActivity(body: API.QueryForPageReq) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.QueryResult<any>>(Path.queryForActivity, formatter(options),
    );
  }

  export async function exportForActivity(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body,
      },
    };
    return request<API.ResponseResult<Array<any>>>(Path.exportForActivity, formatter(options),);
  }

  export async function exportGeneralSaDetails(formSaId: number) {
    const options = {
      method: 'GET',
      body: {
        formSaId: formSaId,
      },
    };
    return request<API.ResponseResult<API.GeneralSaDetailExport[]>>(Path.exportGeneralSaDetails, formatter(options),);
  }

  export async function exportPdSaDetails(formSaId: number) {
    const options = {
      method: 'GET',
      body: {
        formSaId: formSaId,
      },
    };
    return request<API.ResponseResult<API.PdSaDetailExport[]>>(Path.exportPdSaDetails, formatter(options),);
  }

  export async function exportDealerSaDetails(formSaId: number) {
    const options = {
      method: 'GET',
      body: {
        formSaId: formSaId,
      },
    };
    return request<API.ResponseResult<API.GeneralSaDetailExport[]>>(Path.exportDealerSaDetails, formatter(options),);
  }
}
