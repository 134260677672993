import {FormTypeEnum} from './formType';
import {IconEnum} from './icon';
import {LevelEnum} from './level';
import {PermissionsEnum} from './permissions';
import {StatusEnum} from './status';


export namespace Enum {
  export const IconType = IconEnum.IconType;

  export const FormType = FormTypeEnum.FormType;

  export const PageGroup = FormTypeEnum.PageGroup;

  export const PageType = FormTypeEnum.PageType;

  export const UseCase = PermissionsEnum.UseCase;

  export const OperateType = PermissionsEnum.OperateType;

  export const BOLevel = LevelEnum.BOLevel;
  export const BgLevel = LevelEnum.BgLevel;

  export const ECLevel = LevelEnum.ECLevel;
  export const BudGetControlType = StatusEnum.BudgetControlType;


  export const SoSkuCategoryLevel = LevelEnum.SoSkuCategoryLevel;

  export const DistrictLevel = LevelEnum.DistrictLevel;

  export const ProductLevel = LevelEnum.ProductLevel;

  export const FormStatus = StatusEnum.FormStatus;

  export const ExportStatus = StatusEnum.ExportStatus;

  export const InvoiceStatus = StatusEnum.InvoiceStatus;

  export const SyncStatus = StatusEnum.SyncStatus;

  export const RebateInfoStatus = StatusEnum.RebateInfoStatus;
  export const DistrictLevels = LevelEnum.DistrictLevel;

  export enum BOHQ {
    DC = 1,
    DI = 2,
    HK = 3
  }

  export enum Bu {
    HQ = 10,
    YN = 11,
    NF = 14,
    QZ = 15,
    XN = 12,
    TQ = 13,
    ZY = 16,
  }

  export enum HomeSequence {
    ANNOUNCEMENT = '1',
    WAIT_FOR_MY_APPROVAL = '2',
    MY_APPROVED = '3',
    MY_DRAFT = '4',
    MY_SUBMIT = '5',
    MY_FAVORITE = '6',
    WAIT_FOR_MY_APPROVAL_PAYMENT = '7',
  }

  export enum ExtendsBT {
    //1:超申请结案
    SETTLE_OVER_APPLY = 1,
    //2:超预提结案
    SETTLE_OVER_ACCRUAL = 2,
  }

  export enum PaymentMethod {
    // 转账
    TRANSFER = 1,
    // 账扣
    ACCOUNT_DEDUCT = 2,
    // 票扣
    BILL_DEDUCT = 3,
    // 货补
    FREE_GOODS = 4,
    // 第三方(电汇)
    THIRD_PART = 5,
    //挂账余
    TRANSFER_FUND_POOL = 6,
    //折扣
    DISCOUNT = 7,
    //指定货补
    SPECIFIED_FREE_GOODS = 8,
    //非指定货补
    NON_SPECIFIED_FREE_GOODS = 9,
    //直降
    //改为 记账
    VOUCHER_ACCOUNTING = 10,
  }

  export enum BohqType {
    //非广告
    GENERAL = 1,
    //广告
    MKT = 2
  }

  export enum FormRelationType {
    //退回单据的修改
    REJECTED = 1,
    //变更申请
    MODIFIED = 2,
    //单据复制
    COPY = 3
  }

  export enum AccrualMethodType {
    // 后补固定类
    FIXED = 1,
    // 后补随量类
    REVENUE_SELLIN = 2,
    // // 随货降价类（不预提）
    // PriceDiscount = 21,
    // // 随货搭赠类（不预提）
    // Giveaway = 22,
    // // 免费货申请类（不预提）
    // FreeGoods = 31,
    // // 系统外费用（不预提）
    // NonTPM = 32
  }

  export enum ProcessMode {
    //自动生成
    AUTO_ACCRUAL_AND_SETTLE = 1,
    //手动申请
    NON_AUTO = 2,
    //手动结案
    NON_AUTO_SETTLE = 3,
    //外部导入
    NON_TPM_IMPORT = 4,
    //手动生成
    NONE_AUTO_ACCRUAL_AND_SETTLE = 5
  }

  export enum RevenueType {
    //固定类
    NONPRICE = 1,
    PRICE = 2,
    SELLIN = 3,
    POLICY_AUTO_CALC = 91,
    POLICY_MANUAL_CALC = 92,
    //SAP发货销量(不含赠)
    SELLIN_QUANTITY_1 = 101,
    //SAP发货销量(含赠)
    SELLIN_QUANTITY_2 = 102,
    //SAP发货销量(全部)
    SELLIN_QUANTITY_3 = 103,
    //SAP发货销售额
    SELLIN_REVENUE_1 = 151,
    //SAP发货销售额（不含退单）
    SELLIN_REVENUE_2 = 152,
    //SAP发货销售额（不含变价单）
    SELLIN_REVENUE_3 = 153,
    //SAP发货销售额（不含退单和变价单）
    SELLIN_REVENUE_4 = 154,
    //门店入库销量
    SELLOUT_QUANTITY_1 = 201,
    //门店售出销量
    SELLOUT_QUANTITY_2 = 202,
    //小听粉派发数量
    SELLOUT_QUANTITY_3 = 203,
    //新客数量
    SELLOUT_QUANTITY_4 = 204,
    //消费者扫码数量
    SELLOUT_QUANTITY_5 = 205,
    //门店入库销售额
    SELLOUT_REVENUE = 251,
    //POS实际销量
    POS_QUANTITY = 301,
    //POS实际销售额
    POS_REVENUE = 351,
    //随货降价
    PD_DISCOUNT = 401,
    //随货搭赠
    PD_BUY_GIVE = 402,
    //免费货
    FREE_GOODS = 501,
  }

  export enum FieldType {
    BOOLEAN = "Boolean",
    NUMBER = "Number",
    DATE_TIME = "DateTime",
    TEXT = "Text",
  }

  export enum FieldDataType {
    BOOLEAN = "Boolean",
    INT = "Int",
    DECIMAL = "Decimal",
    DATE_TIME = "DateTime",
    STRING = "String"
  }

  export enum AttachmentGenerateType {
    /**
     * TPM 服务器配置默认文件目录
     * 根据配置决定存储在文件服务器，或者OBS服务器
     */
    DEFAULT = 1,

    /**
     * TPM 系统产生的附件，日志文件，存在硬盘
     */
    SYSTEM = 2,

    /**
     * 系统自定义的文件目录配置如登录页配置的图片，产品图片等
     */
    CUSTOM = 3,
  }

  export enum AttachmentCustomPrefix {
    OBS_CUSTOM_PREFIX = "obs_custom://",
    FILE_CUSTOM_PREFIX = "file_custom://",
  }

  export enum PolicyType {
    // 随货特价类(无需申请)
    PD_DISCOUNT = 1,
    // 随货特价类(需申请)
    PD_DISCOUNT_FOR_ACTIVITY = 2,
    // 随货搭赠类(无需申请)
    PD_BUY_GIVE = 3,
    // 随货搭赠类(需申请)
    PD_BUY_GIVE_FOR_ACTIVITY = 4,
    //  后补自动计算类(无需申请)
    AUTO_CALC = 5,
    //  后补自动计算类(需申请)
    AUTO_CALC_FOR_ACTIVITY = 6,
    // 后补自动计算类(发货预提)
    AUTO_CALC_BY_DELIVERY = 7,
    //  后补自动计算类(君币核销类)
    AUTO_CALC_BY_JUNBI = 8,
    // 后补手工计算类(无需申请)
    MANUAL_CALC = 9,
    //  后补手工计算类(需申请)
    MANUAL_CALC_FOR_ACTIVITY = 10,


  }

  export enum SalesContractType {
    // 一级客户合同
    SELLIN = 1,
    // 二级客户合同
    SELLOUT = 2
  }

  export enum SalesContractStatus {
    // 待确认
    UNCONFIRMED = 1,
    // 已确认
    CONFIRMED = 2
  }

  export enum SettlementMode {
    //必须结案
    REQ_SETTLE = 1,
    //可不结案
    NON_REQ_SETTLE = 2,
    //无需结案
    NO_SETTLE = 3
  }

  export enum PersistenceType {
    FINANCIAL = 1,
    SYSTEM_PARAMETER = 2
  }

  export enum SoldToLevel {
    MAIN = 1,
    SUB = 2,
  }

  export enum SoldToType {
    //经销商
    DEALER = 1,
    //直营
    KA = 2,
    //电商
    DS = 3,
    //司机
    DRIVER = 4,
    //直采
    DIRECT = 5,

  }

  export enum PolicyTargetType {
    FIXED_AMOUNT = 1,
    FIXED_QUANTITY = 2,
    YEAR_ON_YEAR = 3,
    MONTH_ON_MONTH = 4,
    ACTIVITY_TARGET_QUANTITY = 5,
    ACTIVITY_TARGET_REVENUE = 6,
  }

  export enum TermsPolicyType {
    //达量产品
    REACH_PRODUCT = 1,
    //返利产品
    REBATE_PRODUCT = 2,
    //购买产品
    BUY_PRODUCT = 3,
    //赠送产品
    GIVEAWAY_PRODUCT = 4,
    //特价产品
    DISCOUNT_PRODUCT = 5,
  }

  export enum TermsProductTypeEnum {
    //达量
    REACH = 1,
    //返利 (购买产品, 特价产品)
    REBATE = 2,
    //赠送产品
    GIVE = 3
  }

  export enum SoType {
    // 普通订单
    DMS_NORMAL = 101,
    // 免费订单
    DMS_FREE = 102,
    // 物料部分收费订单
    DMS_PART_TOLL_MATERIAL = 103,
    // 免费物料订单
    DMS_FREE_MATERIAL = 104,
    // TPM免费产品订单
    DMS_FREE_TPM = 105,
    // TPM免费物料订单
    DMS_FREE_MATERIAL_TPM = 106,
    // 预订单
    DEALER_PRE = 107,
    // 跨公司订单
    DEALER_CROSS_COMPANY = 108,
    //直营寄售补货
    KA_CONSIGNMENT = 201,
    //直营免费订单
    KA_FREE = 202,
    //直营销售订单(石家庄)
    KA_SJZ = 203,
    //直营销售订单(外阜)
    KA_COMMON = 204,
    //经销商TPM免费订单
    KA_TPM_FREE = 205,
    //电商订单
    EC_ORDER = 302,

    //退货订单
    EC_RETURN_ORDER = 303,

    //免费订单
    EC_FREE_ORDER = 306,

  }

  export enum SoStatus {
    // 待初审
    TOFIRSTTRAIL = 101,
    // 待调整
    TOADJUST = 102,
    // 待终审
    TOFINALTRAIL = 103,
    // 待发货
    TODELIVER = 104,
    // 部分交货
    PARTDELIVER = 105,
    // 部分发货
    HAVEDELIVER = 106,
    // 订单完成
    FINISH = 107,
    // 订单关闭
    CLOSE = 108,
    // 订单驳回
    REJECT = 109,
    // 订单撤回
    WITHDRAW = 110,
    // 部分完成
    PARTFINISH = 111,
    /** 待确认 */
    KA_STAY_CONFIRM = 201,
    /** 待终审 */
    KA_STAY_FINAL_APPROVE = 202,
    /** 已传输ERP */
    KA_TO_ERP = 203,
    /** 关闭 */
    KA_CLOSED = 204,
    /** 取消关闭 */
    KA_CANCEL_CLOSED = 206,
    /** 自动关闭 */
    KA_AUTO_CLOSED = 207,

    /** 未传入 */
    EC_NOT_LOADED = 301,
    /** 已撤回 */
    EC_CANCELED = 302,
    /** 已传入 */
    EC_LOADED = 303,
    /** 上传失败 */
    EC_UPLOAD_FAILED = 304,
    /** 不扣款 */
    EC_NOT_CHARGE = 305,
  }

  export enum SoSource {
    // 经销商电脑端
    DMS_PC = 101,
    // 经销商移动端
    DMS_MOBILE = 102,
    // 内部人员
    DMS_INSIDER = 103,
    // 系统抓取
    KA_SYSTEM_SCRAP = 201,
    // 手工创建
    KA_MANUAL = 202,
    // 进销存订单
    KA_ORDER = 203,
    // 系统自动
    DS_SYSTEM_AUTO = 301,
    // 寄售导入
    DS_CONSIGN_IMPORT = 302,
  }

  export enum SoCategory {
    // 经销商订单
    DMS = 1,
    // 直营订单
    KA = 2,
    // 电商订单
    DS = 3,
  }

  //SAP定义：X:赠品 Z:特价 S:货补 空:本品
  export enum ProductItemType {
    ZP = 'X',
    TJ = 'Z',
    HB = 'S',
    BP = '',
  }

  export enum SoDetailItemType {
    // 本品
    SELF = 0,
    // 赠品
    GIVE = 1,
    // 特价
    DISCOUNT = 2,
    // 货补
    FG = 3,
    // 货补特价
    FG_DISCOUNT = 32,
  }

  export enum ProductType {
    //系列
    SKU = 1,
    //品类
    MATERIAL = 2,
  }

  export enum ShoppingCartRowType {
    None = 0,
    Discount = 1,
    BuyGive = 2,
    Fg = 3,
    Material = 4,
    MaterialGroup = 5,
    // 货补特价行，计算页面需要和货补行区分
    FgDiscount = 32,
  };

  export enum SoOperateType {
    SUBMIT = 0,
    APPROVAL = 1,
    MODIFY = 2,
    //订单还原
    CANCEL = 3,
    //经销商订单撤回
    WITHDRAW = 4,
  };

  export enum ReconciliationStatus {
    STAY_UPLOAD = 0,
    STAY_APPROVE = 1,
    SURE = 2,
    REFUSE = 3,
    NO_DEAL = 4,
  }

  export enum ProductCategory {
    MATERIAL = 0,
    YN = 1,
    XN = 2,
    NF = 3,
    QZ = 4,
  }

  export enum SalesType {
    SELLIN = 1,
    SELLOUT = 2,
    POS = 3
  }

}
