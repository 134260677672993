import { formatter, request } from '@/utils/request';

export namespace VatType {
  export const Path = {
    Query: '/mdmapi/vatType/query',
    Search: '/mdmapi/vatType/search',
    AjaxSearch: '/mdmapi/vatType/ajaxSearch',
    Get: '/mdmapi/vatType/get',
    Post: '/mdmapi/vatType/post',
    Delete: '/mdmapi/vatType/delete',
    GetByFormSaId: '/mdmapi/vatType/getVatTypesByFormSaId',
    GetValueByProductID: '/mdmapi/vatType/getVatTypeValueByProductId',
    GetByValue: '/mdmapi/vatType/getVatTypeByValue',
  };

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<API.QueryForVatTypeData>>(Path.Query, formatter(options),);
  }



  export async function ajaxSearch(body: API.VatTypeFilter) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.VatType[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function get(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.VatType>>(Path.Get, formatter(options));
  }

  //对应原 getVatTypes
  export async function search(param: API.VatTypeFilter) {
    const options = {
      method: 'POST',
      body: {
        ...param
      },
    };
    return request<API.ResponseResult<API.VatType[]>>(Path.Search, formatter(options));
  }

  export async function post(body: API.VatType) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function del(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }

  export async function getByFormSaId(id: number) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<API.VatType[]>>(Path.GetByFormSaId, formatter(options));
  }

  export async function getValueByProductID(productID: number) {
    const options = {
      method: 'GET',
      body: {
        productID: productID,
      },
    };
    return request<API.ResponseResult<number>>(Path.GetValueByProductID, formatter(options));
  }

  export async function getByValue(value: number) {
    const options = {
      method: 'GET',
      body: {
        value: value,
      },
    };
    return request<API.ResponseResult<API.VatType>>(Path.GetByValue, formatter(options));
  }
}
