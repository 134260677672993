import { useEffect, useRef } from 'react';
import { WdiSelect, WdiSelectFormat } from '@/components/WdiControls/WdiSelect';
import { DatePicker, Form, Input, message } from 'antd';
import { ResponseStatus } from '@/utils/request';
import { WdiInputNumber } from '@/components/WdiControls/WdiInputNumber';
import { Product } from '@/services/mdm/product';
import { ProFormInstance } from '@ant-design/pro-form';
import { WdiModalAppProps, wdiCore } from '@/components/WdiControls';
import { HorzLayoutOpts, WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { FormSa } from '@/services/sales/sa';
import { getDetailPrevData } from '../../components/data';
import moment from 'moment';


const SellInDetailApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const { formSaDetail, formSa, rowIndex, rows } = props.data;

  /** 根据费用项的核算类型决定如何计算
    申请金额 = sellin单听补贴*sellin预估销量
   */
  const handleCalcAmount = () => {
    const fields = formRef.current?.getFieldsValue();
    const amount = fields.unitExpenseSellin * fields.quantityForecastSellin
    formRef.current?.setFieldsValue({
      amount: wdiCore.Number.ToNumber(amount, 2)
    });
  };

  useEffect(() => {
    if (formSaDetail) {
      formRef.current?.setFieldsValue({
        activityStartDate: formSaDetail.activityStartDate ? moment(formSaDetail.activityStartDate) : undefined,
        activityEndDate: formSaDetail.activityEndDate ? moment(formSaDetail.activityEndDate) : undefined,
        supplyStartDate: formSaDetail.supplyStartDate ? moment(formSaDetail.supplyStartDate) : undefined,
        supplyEndDate: formSaDetail.supplyEndDate ? moment(formSaDetail.supplyEndDate) : undefined,
        promotionDesc: formSaDetail.promotionDesc,
        unitExpenseSellin: formSaDetail.unitExpenseSellin,
        quantityForecastSellin: formSaDetail.quantityForecastSellin,
        productBuyIds: formSaDetail.productBuyIds,
        amount: formSaDetail.amount,
        ecName: formSaDetail.ecName,
        revenueTypeName: formSaDetail.revenueTypeName,
        soldToName: wdiCore.String.Join(formSaDetail.soldToCode, formSaDetail.soldToName),
        custSysName: wdiCore.String.Join(formSaDetail.custSysCode, formSaDetail.custSysName),
        remark: formSaDetail.remark,
      });

      if (formSaDetail.productBuyIds) {
        formRef.current?.setFieldsValue({
          productBuyIds: {
            value: formSaDetail.productBuyIds,
            label: formSaDetail.productBuyNames,
          }
        });
      }

    }
  }, [formSaDetail]);

  return (
    <WdiModalForm
      formRef={formRef}
      initialValues={{ undertakeRate: 1 }}
      title={props.title}
      visible={props.visible}
      modalProps={{ onCancel: () => { props.onClose?.() }, }}
      onFinish={async (values) => {
        const entity: API.FormSaDetail = {
          amount: values.amount,
          remark: values.remark,
          promotionDesc: values.promotionDesc,        
          unitExpenseSellin: values.unitExpenseSellin,
          quantityForecastSellin: values.quantityForecastSellin
        };

        entity.productBuyIds = WdiSelectFormat.getvalue(values.productBuyIds);

        if (entity.unitExpenseSellin && entity.unitExpenseSellin <= 0) {
          message.error("sellin单听补贴必须大于0!");
          return;
        }

        if (entity.quantityForecastSellin && entity.quantityForecastSellin <= 0) {
          message.error("sellin预估销量必须大于0!");
          return;
        }

        // if (entity.amount && entity.amount <= 0) {
        //   message.error("申请金额必须大于0!");
        //   return;
        // }
         
        if (values.activityStartDate) {
          entity.activityStartDate = moment(values.activityStartDate).format("YYYY-MM-DD");
        }
        if (values.activityEndDate) {
          entity.activityEndDate = moment(values.activityEndDate).format("YYYY-MM-DD");
        }
        if (values.supplyStartDate) {
          entity.supplyStartDate = moment(values.supplyStartDate).format("YYYY-MM-DD");
        }
        if (values.supplyEndDate) {
          entity.supplyEndDate = moment(values.supplyEndDate).format("YYYY-MM-DD");
        }

        const response = await FormSa.Buttons.validateDetail({
          rowIndex: rowIndex,
          formSa: { ...formSa },
          formSaDetail: Object.assign({}, getDetailPrevData(formSaDetail), entity),
          formSaDetails: rows
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (props.onSave) {
          const data = Object.assign({}, { rowIndex: rowIndex }, response.data);
          response.data = data;
          props.onSave(response);
        }
      }}
      {...HorzLayoutOpts}
    >
      <Form.Item name="soldToName" label="售达方">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="custSysName" label="客户集团">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="productBuyIds" label="产品组"
        rules={[{ required: true, message: '产品组不能为空!' }]}>
        <WdiSelect {...{
          placeholder: "请选择产品组",
          showSearch: true,
          remote: true,
          dataLoading: async (params) => {
            const response = await Product.ajaxSearchLevel4(Object.assign({
              productLevels: "4",
              bohqId: formSa.bohqId,
              isActive: true,
            }, params));
            return response.data.map((item) => ({ value: item.productId, label: item.codeOrName }));
          }
        }} />
      </Form.Item>
      <Form.Item name="promotionDesc" label="活动机制" rules={[{ required: true, message: '活动机制不能为空!' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="unitExpenseSellin" label="sellin单听补贴" rules={[{ required: true, message: 'sellin单听补贴不能为空!' }]}>
        <WdiInputNumber min={0} onChange={() => { handleCalcAmount(); }} />
      </Form.Item>
      <Form.Item name="quantityForecastSellin" label="sellin预估销量" rules={[{ required: true, message: 'sellin预估销量不能为空!' }]}>
        <WdiInputNumber min={0} onChange={() => { handleCalcAmount(); }} digits={0} />
      </Form.Item>
      <Form.Item name="amount" label="申请金额" rules={[{ required: true, message: '申请金额不能为空!' }]}>
        <WdiInputNumber readOnly={true} />
      </Form.Item>
      <Form.Item name="activityStartDate" label="明细活动开始日期" >
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>
      <Form.Item name="activityEndDate" label="明细活动截止日期" >
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>
      <Form.Item name="supplyStartDate" label="明细供货开始日期" >
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>
      <Form.Item name="supplyEndDate" label="明细供货截止日期" >
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <Input maxLength={2000} />
      </Form.Item>
      <Form.Item name="ecName" label="费用项">
        <Input readOnly />
      </Form.Item>
      <Form.Item name="revenueTypeName" label="核算类型" >
        <Input readOnly />
      </Form.Item>
    </WdiModalForm>
  );
}
export default SellInDetailApp;
