import React, { useEffect, useState } from 'react';
import { Button, Image, message, Progress, Upload } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { formatter, request, ResponseStatus } from '@/utils/request';
import { fileDownload, fileObjectURL, formatFileDownload, isAnImage, isInvalidFile } from '@/utils/download';
import type { UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/lib/upload/interface';
import { wdiCore } from './wdiCore';
import { Enum } from '@/enums/system';

export const parseUrl = async (file: any) => {
  if (file.url) {
    if (file.url?.indexOf(Enum.AttachmentCustomPrefix.FILE_CUSTOM_PREFIX) > -1) {
      return HOST_FILE_CUSTOM + file.url?.substring(Enum.AttachmentCustomPrefix.FILE_CUSTOM_PREFIX.length);
    } else if (file.url.indexOf(Enum.AttachmentCustomPrefix.OBS_CUSTOM_PREFIX) > -1) {
      return HOST_FILE_CUSTOM + file.url.substring(Enum.AttachmentCustomPrefix.OBS_CUSTOM_PREFIX.length);
    } else {
      return await fileObjectURL(formatFileDownload(file));
    }
  } else {
    return '';
  }
}

export type WdiUploadProps = {
  leftTopInfo?: string;
  addToAttachment?: boolean; //附件存储到数据库的附件表格
  preview?: boolean; //是否预览图片
  generateType?: number;//生成文件的类型，默认为1
} & UploadProps;

export const WdiFileUpload: React.FC<WdiUploadProps> = (props) => {
  const [previewUrl, setPreviewUrl] = useState<string>();
  const { leftTopInfo, addToAttachment, preview, generateType, ...newProps } = Object.assign({}, props);

  const showPreview = (file: UploadFile) => {
    const index = file.name?.lastIndexOf('.');
    const fnExtension = file.name?.substring(Number(index) + 1);
    if (isAnImage(fnExtension)) {
      parseUrl(file).then(url => {
        setPreviewUrl(url)
      })
    }
  }

  if (preview && wdiCore.Object.IsNullOrUndefined(previewUrl) && props.fileList && props.fileList.length > 0) {
    showPreview(props.fileList[0]);
  }

  const customRequest = (componentsData: any) => {
    const { file } = componentsData;
    const formData = new FormData();

    const index = file.name?.lastIndexOf('.');
    const fnExtension = file.name?.substring(Number(index) + 1);
    if (isInvalidFile(fnExtension)) {
      message.error("不允许上传的文件类型！")
    }
    else {
      formData.append('fileInfo', file);
      formData.append('generateType', String(generateType || Enum.AttachmentGenerateType.DEFAULT)); //用户上传附件
      formData.append('addToAttachment', addToAttachment === false ? 'false' : 'true');

      request<API.ResponseResult<API.FileItem>>(
        '/fileapi/file/upload',
        formatter({ method: 'POST', body: formData }),
      ).then(res => {
        if (res.code == ResponseStatus.SUCCESS) {
          file.url = res.data?.path;
          if (preview) {
            showPreview(file);
          }
          componentsData.onSuccess();
        } else {
          componentsData.onError();
        }
      }).catch((error) => {
        componentsData.onError();
      });

    }
  };

  const antdProps = Object.assign({}, newProps);
  const disabled = !!antdProps.disabled;

  if (!antdProps.action && !antdProps.customRequest) {
    antdProps.customRequest = customRequest;
  }
  if (!antdProps.maxCount || antdProps.maxCount > 1) {
    antdProps.multiple = true;
  }
  if (!antdProps.itemRender) {    
    antdProps.itemRender = (originNode: any, file: UploadFile, fileList: object[], actions: any) => {
      const index = file.originFileObj?.name?.lastIndexOf('.');
      const fnExtension = file.originFileObj?.name?.substring(Number(index) + 1);
      if (isInvalidFile(fnExtension)) {
        return null;
      }
      else {
        const itemClz = 'ant-upload-list-item ant-upload-list-item-' + file.status + ' ant-upload-list-item-list-type-text';
        return (
          <div className={itemClz} >
            <div className="ant-upload-list-item-info">
              <span className="ant-upload-span">
                <div className="ant-upload-text-icon">
                  {(file.status === 'uploading') && <LoadingOutlined />}
                  {(file.status !== 'uploading') && <PaperClipOutlined />}
                </div>
                {file.url && <a className="ant-upload-list-item-name" title={file.name} onClick={(e: any) => { if (actions && actions.download) { actions.download() } }}>{file.name}</a>}
                {!file.url && <span className="ant-upload-list-item-name" title={file.name}>{file.name}</span>}
                {!disabled && <span className="ant-upload-list-item-card-actions">
                  <Button
                    type="text"
                    title="删除文件"
                    size="small"
                    className="ant-btn-icon-only ant-upload-list-item-card-actions-btn"
                    onClick={(e: any) => {
                      if (actions && actions.remove) {
                        actions.remove()
                      }
                      setPreviewUrl('');
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </span>
                }
              </span>
            </div>
            {
              (file.status === 'uploading') &&
              <div className="ant-upload-list-item-progress">
                <Progress percent={file.percent} size="small" />
              </div>
            }
          </div>
        );
      }
    }
  }

  if (!antdProps.onDownload) {
    antdProps.onDownload = FileUploadUtility.download;
  }

  // if (!antdProps.maxCount) {
  //   if (wdiCore.Object.IsNullOrUndefined(antdProps.multiple)) {
  //     antdProps.multiple = true;
  //   }
  // }

  if (disabled) {
    return (
      <Upload {...antdProps}>
      </Upload>
    )
  }
  return (
    <div className='upload-container' style={{ display: 'inline' }}>
      <Upload {...antdProps}>
        <Button style={{ backgroundColor: '#efefef' }} icon={<UploadOutlined />}>浏览</Button>
        {leftTopInfo && <span style={{ paddingLeft: "24px", cursor: 'pointer' }}>{leftTopInfo}</span>}
      </Upload>
      {preview && previewUrl && <Image width={200} src={previewUrl} />}
    </div>
  )
};

export const FileUploadUtility = {
  download: (file: UploadFile) => {
    fileDownload(file);
  },
  parse: function (names: string | null | undefined, urls: string | null | undefined) {
    let fileNames: string[] = [];
    if (names) {
      fileNames = names.split(";#");
    }
    let filePaths: string[] = [];
    if (urls) {
      filePaths = urls.split(";#");
    }
    var values = [];
    if (fileNames.length === filePaths.length) {
      for (var index = 0; index < fileNames.length; index++) {
        values.push({
          uid: String(index + 1),
          name: fileNames[index],
          url: filePaths[index]
        });
      }
    }
    return values;
  },
  serialize: (fileList: UploadFile[] | undefined | null) => {
    var names = "";
    var urls = "";
    var spliter = ";#";
    if (fileList) {
      fileList.forEach((item, itemIndex) => {
        if (itemIndex !== 0) {
          names += spliter;
          urls += spliter;
        }
        names += item.name;
        urls += item.url;
      });
    }
    return { names: names, urls: urls };
  },
  getFileListByStatus: (fileList: UploadFile[] | undefined | null, status: string) => {
    const newFileList: UploadFile[] = [];
    if (fileList) {
      fileList.forEach((item) => {
        if (item.status == status) {
          newFileList.push(item);
        }
      })
    }
    return newFileList;
  },
  isUploading: (fileList: UploadFile[] | undefined | null) => {
    return FileUploadUtility.getFileListByStatus(fileList, 'uploading').length > 0;
  },
  isError: (fileList: UploadFile[] | undefined | null) => {
    return FileUploadUtility.getFileListByStatus(fileList, 'error').length > 0;
  }
};


type WdiFileListProps = {
  fileList?: UploadFile[];
  height?: number | string;
  allowDownloadAll?: boolean;
};

export const WdiFileList: React.FC<WdiFileListProps> = (props) => {
  const [imageState, setImageState] = useState<{ visible: boolean, url?: string }>();

  const onFileClick = (item: UploadFile) => {
    const index = item.name?.lastIndexOf('.');
    const fnExtension = item.name?.substring(Number(index) + 1);
    //图片附件直接预览
    if (isAnImage(fnExtension)) {
      return async (e: any) => {
        const url = await parseUrl(item);
        setImageState({ visible: true, url: url })
      };
    } else {
      return (e: any) => {
        FileUploadUtility.download(item);
      };
    }
  };

  async function waitAsync(ms: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  const downloadAll = async () => {
    if (props.fileList) {
      let i = 1;
      for (let item of props.fileList) {
        if (i % 10 == 0) {
          await waitAsync(1500);
        }
        FileUploadUtility.download(item);
        i++;
      }
    }
  };

  return (
    <>
      {props.fileList && props.fileList.length > 1 && (wdiCore.Object.IsNullOrUndefined(props.allowDownloadAll) || props.allowDownloadAll) &&
        <Button type="primary" shape="round" icon={<DownloadOutlined />} size='small' style={{ position: 'absolute', right: 0 }}
          onClick={() => { downloadAll(); }}
        >全部下载</Button>
      }
      <div className="ant-upload-list ant-upload-list-text" style={{ maxHeight: props.height || 100, overflowY: 'auto', width: '80%' }}>
        {
          (props.fileList ? props.fileList : []).map((item: UploadFile) => {
            const itemClz = 'ant-upload-list-item ant-upload-list-item-' + item.status + ' ant-upload-list-item-list-type-text';
            return (
              <div className="ant-upload-list-text-container" key={item.url}>
                <div className={itemClz}>
                  <div className="ant-upload-list-item-info">
                    <span className="ant-upload-span">
                      <div className="ant-upload-text-icon">
                        <PaperClipOutlined />
                      </div>
                      {item.url && <a className="ant-upload-list-item-name" title={item.name} onClick={onFileClick(item)}>{item.name}</a>}
                      {!item.url && <span className="ant-upload-list-item-name" title={item.name}>{item.name}</span>}
                    </span>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      {
        imageState?.visible && <Image
          width={400}
          style={{ display: 'none' }}
          src={imageState.url}
          preview={{
            visible: imageState.visible,
            src: imageState.url,
            onVisibleChange: (value: any) => {
              setImageState({ visible: value });
            },
          }}
        />
      }
    </>
  )
}

type WdiImageProps = {
  name?: string;
  url: string;
  width: number;
  height: number;
};

export const WdiAttachmentImage: React.FC<WdiImageProps> = (props: WdiImageProps) => {

  const [imgUrl, setImgUrl] = useState<string>();
  const [canPreview, setCanPreview] = useState(false);
  const previewStr = `?x-image-process=image/resize,m_lfit,h_${props.width},w_${props.height}`;

  useEffect(() => {
    if (props.url) {
      if (props.url.indexOf(Enum.AttachmentCustomPrefix.FILE_CUSTOM_PREFIX) > -1) {
        setImgUrl(HOST_FILE_CUSTOM + props.url.substring(Enum.AttachmentCustomPrefix.FILE_CUSTOM_PREFIX.length));
        setCanPreview(true);
      } else if (props.url.indexOf(Enum.AttachmentCustomPrefix.OBS_CUSTOM_PREFIX) > -1) {
        setImgUrl(HOST_FILE_CUSTOM + props.url.substring(Enum.AttachmentCustomPrefix.OBS_CUSTOM_PREFIX.length));
        setCanPreview(true);
      } else {
        fileObjectURL(formatFileDownload({ name: props.name, url: props.url })).then(response => {
          setImgUrl(response);
        });
      }
    } else {
      setImgUrl("");
    }
  }, [props.url]);

  return (
    <>
      {
        imgUrl &&
        <Image src={canPreview ? imgUrl + previewStr : imgUrl}
          width={props.width}
          height={props.height}
          preview={{ src: imgUrl }}
        />
      }
    </>
  )
}
