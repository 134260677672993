export namespace IconEnum {
  export enum IconType {
    //职务切换
    SWITCH = 'switch',
    //红绿灯-红
    RED_POINT = 'red-point',
    //红绿灯-绿
    GREEN_POINT = 'green-point',
    //红绿灯-黄
    YELLOW_POINT = 'yellow-point',
    //代理
    PROXY = 'proxy',
    //选中状态
    CHECKED = 'checked',
    //成功
    SUCCESS = 'success',
    //警告
    WARNING = 'warning',
    //错误
    ERROR = 'error',
    //申请人名片
    STAFF_CARD = 'staffcard',
    //通知公告
    ANNOUNCEMENT = 'announcement',
    //待我审批
    WAIT_FOR_MY_APPROVAL = 'waitformyapproval',
    //我已审批
    MY_APPROVED = 'myapproved',
    //我的草稿
    MY_DRAFT = 'mydraft',
    //我提交的
    MY_SUBMIT = 'mysubmit',
    //我收藏的
    MY_FAVORITE = 'myfavorite',
    //关闭
    CLOSE = 'close',
    //系统公告
    SMALL_ANNOUNCEMENT = 'smallannouncement',
    //提醒
    REMIND = 'remind',
    //刷新
    REFRESH = 'refresh',
    //基本信息
    BASE_INFO = 'baseinfo',
    //维度信息
    LIMITED_INFO = 'limitedinfo',
    //审批流
    APPROVAL_FLOW = 'approvalflow',
    //讨论版
    DISCUSSION = 'discussion',
    //申请和审批历史
    HISTORY = 'history',
    //合同信息
    CONTRACT = 'contract',
    //合同条款
    CONTRACT_TERM = 'contractterm',
    //发票信息
    INVOICE = 'invoice',
    //费用统计信息
    DETAIL_COUNT_INFO = 'detailcountinfo',
    //费用明细信息/费用分配信息
    DETAIL_INFO = 'detailinfo',
    //销量与费用
    DETAIL_SALES_INFO = 'detailsalesinfo',
    //讨论版头像
    AVATAR = 'avatar',
    //活动结案信息
    FORM_SS = 'formss',
    //活动核销信息/相关核销单
    FORM_SP = 'formsp',
    //下单管理
    DMS_SO = 'dmsso',
    //财务中心
    FINANCIAL_CENTER = 'financialcenter',
    //政策管理
    POLICY = 'policy',
    //电商对账
    DS_RECONCILIATION = 'dsreconciliation',
    //费用池管理
    POOL = 'pool',
    //信息中心
    INFORMATION_CENTER = 'informationcenter',
    //订单管理-操作日志
    SO_OPERATE_LOG = 'sooperatelog',
    //财务中心 - 资金查询 - 资金打款明细
    CASH_PAY_DETAIL = 'cashpaydetail',
    //发货金额
    AMOUNT_DELIVERED = 'amountdelivered',
    //在途订单
    ON_THE_WAY_SO = 'onthewayso',
    //政策管理 - 经销商通知公告 - 发布范围
    PUBLISH_RANGE = 'publishrange',
    //政策管理 - 产品政策 - 适用/不适用售达方列表
    SUITABLE_SOLD_TO = 'suitablesoldto',
    //政策变更历史
    POLICY_HISTORY = 'policyhistory',
    //政策管理 - 君币管理 - 报销单列表
    JB_PAYMET_LIST = 'jbpaymetlist',
    //财务中心 - 费用池对账 - 余额查询 - 费用池余额明细 / 余额占用明细
    POOL_AMOUNT_DETAIL = 'poolamountdetail',
    //财务中心 - 费用池对账 - 费用池账单 - 汇总信息
    POOL_SUMMARY = 'poolsummary',
    //追加池明细
    POOL_ADD_DETAIL = 'pooladddetail',
    //扣减池明细
    POOL_REDUCE_DETAIL = 'poolreducedetail',
  }
}
