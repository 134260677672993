import {useEffect, useMemo, useRef} from 'react';
import type {WdiModalAppProps} from '@/components/WdiControls';
import type {ProFormInstance} from '@ant-design/pro-form';
import {EC} from '@/services/mdm/ec';
import {WdiSelect} from '@/components/WdiControls/WdiSelect';
import {Form, Input, message} from 'antd';
import {WdiModalForm} from '@/components/WdiControls/WdiModalForm';
import {ResponseStatus} from '@/utils/request';
import {WdiInputNumber} from '@/components/WdiControls/WdiInputNumber';
import {Enum} from '@/enums/system';
import {SalesContractTerms} from '@/services/contract/salescontractterms';
import {DataOptions} from '@/services/basic/metadata';
import {isDupSalesContractTerms} from "@/pages/contract/SalesContractApply/components/data";

const {TextArea} = Input;

const ecDataLoadingFactory = (contractType: number) => {
  return (params: any) => {
    return EC.searchEc3(
      Object.assign(
        {
          withPermissions: true,
          isActive: true,
          pageTypeIds: [contractType == Enum.SalesContractType.SELLIN
            ? String(Enum.PageType.CONTRACT_REBATE)
            : String(Enum.PageType.CONTRACT_REBATE_LEVEL_2)].join()
        },
        params,
      ),
    ).then((response) => {
      return response.data.map((item) => ({value: item.ecId, label: item.ecName}));
    });
  };
};

const processModeDataLoading = (params: any) => {
  return DataOptions.search(Object.assign({typeGroup: 13}, params)).then((response) => {
    return response.data.map((item) => ({value: item.value, label: item.name}));
  });
};

const SalesContractTermsEditApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const ecDataLoading = useMemo(() => {
    return ecDataLoadingFactory(props.data?.contractType);
  }, [props.data?.contractType]);

  useEffect(() => {
    formRef.current?.setFieldsValue({
      processMode: props?.data?.row?.processMode,
    });
    if (props.data.row) {
      const detail = props.data.row;
      formRef.current?.setFieldsValue({
        salesContractTermsId: detail.salesContractTermsId,
        salesContractId: detail.salesContractId,
        ecId: detail.ecId,
        rateOriginal: detail.rateOriginal,
        remark: detail.remark,
      });
    }
  }, [props.data.row, props.type]);

  return (
    <WdiModalForm
      formRef={formRef}
      labelCol={{span: 6}}
      wrapperCol={{span: 12}}
      labelAlign="left"
      layout="horizontal"
      title={props.title}
      visible={props.visible}
      preserve={true}
      modalProps={{
        onCancel: () => {
          props.onClose ? props.onClose() : null;
        },
        destroyOnClose: true,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        const {...entity} = values;
        const response = await SalesContractTerms.validateDetail({
          salesContractTerms: entity,
          contractType:props.data?.contractType,
          soldToCode:props.data?.soldToCode.value ? props.data?.soldToCode.value : props.data?.soldToCode
        });
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
        } else {
          if (isDupSalesContractTerms(props.data.rows, response.data, props.data.rowIndex)) {
            message.error("合同条款已存在");
          } else {
            if (props.onSave) {
              props.onSave({
                code: ResponseStatus.SUCCESS,
                message: '',
                data: response.data,
              });
            }
          }
        }
      }}
    >
      <Form.Item name="salesContractTermsId" label="salesContractTermsId" hidden>
        <Input/>
      </Form.Item>
      <Form.Item name="salesContractId" label="salesContractId" hidden>
        <Input/>
      </Form.Item>
      <Form.Item
        required
        name="ecId"
        label="费用项"
        rules={[{required: true, message: '费用项不能为空!'}]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择费用项',
            dataLoading: ecDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        name="processMode"
        label="处理方式"
        rules={[{required: true, message: '处理方式不能为空!'}]}
      >
        <WdiSelect
          {...{
            placeholder: '请选择处理方式',
            disabled: true,
            dataLoading: processModeDataLoading,
          }}
        />
      </Form.Item>
      <Form.Item
        required
        name="rateOriginal"
        label="初始比例"
        rules={[{required: true, message: '初始比例不能为空!'}]}
      >
        <WdiInputNumber digits={4} min={0} max={1}/>
      </Form.Item>
      <Form.Item name="remark" label="备注">
        <TextArea rows={3} style={{width: '100%'}}/>
      </Form.Item>
    </WdiModalForm>
  );
};

export default SalesContractTermsEditApp;
