import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { Resizable } from "react-resizable";

export const ResizableTitle = (props: any) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      minConstraints={[50, 0]}
      maxConstraints={[800, 0]}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
}

export const FooterSummary = (formSchema: any, columns: any, dataSource: any, aggrProps: any, showExpandedRow?: boolean) => {
  return (data: any) => {
    return (
      <Table.Summary>
        <Table.Summary.Row style={{ background: '#fafafa', lineHeight: '12px' }}>
          {showExpandedRow && <Table.Summary.Cell index={-1} key="__ExpandedRow" />}
          {columns?.map((col: any, itemIndex: any) => {
            const field = formSchema.allFields[col.dataIndex];
            let aggrValue = '';
            if (field && field.aggr) {
              aggrValue = field.aggr(col, dataSource, aggrProps);
            }
            if (itemIndex === 0) {
              return (
                <Table.Summary.Cell index={itemIndex} key={col.dataIndex}>
                  合计: {aggrValue}
                </Table.Summary.Cell>
              );
            } else {
              return (
                <Table.Summary.Cell index={itemIndex} key={col.dataIndex}>
                  {aggrValue}
                </Table.Summary.Cell>
              );
            }
          })}
        </Table.Summary.Row>
      </Table.Summary>
    );
  };
}

const handleExpandedAllRow = (that: any) => {
  let allExpandedKeys = that.state.allAvailableKeys;
  if (allExpandedKeys.length == 0) {
    allExpandedKeys = (that.props.antdProps?.dataSource || []).map((record: any) => record.uid);
    that.setState({
      allAvailableKeys: allExpandedKeys,
    });
  }
  that.state.showAllExpanded
    ? that.setState({ expandedKeys: [], showAllExpanded: false })
    : that.setState({
      expandedKeys: allExpandedKeys,
      showAllExpanded: true,
    });
};
const toggleExpandedKeys = (key: any, that: any) => {
  const outArr = [...that.state.expandedKeys];
  if (outArr.includes(key)) {
    that.setState({ expandedKeys: outArr.filter((e) => e !== key) });
  } else {
    outArr.push(key);
    that.setState({ expandedKeys: outArr });
  }
};

export const ExpandedRow = (that: any) => {
  return ({
    dataIndex: '__ExpandedRow',
    key: '__ExpandedRow',
    title: () =>
      that.state.showAllExpanded ? (
        <CaretDownOutlined onClick={(e) => handleExpandedAllRow(that)} />
      ) : (
        <CaretRightOutlined onClick={(e) => handleExpandedAllRow(that)} />
      ),
    render: (_: any, record: any) =>
      that.state.expandedKeys.includes(record.uid) ? (
        <CaretDownOutlined onClick={() => toggleExpandedKeys(record.uid, that)} />
      ) : (
        <CaretRightOutlined onClick={() => toggleExpandedKeys(record.uid, that)} />
      ),
    width: 40,
  });
}