import React, {useEffect, useState} from 'react';
import {FormBudgetProject} from '@/services/sales/bp/formBudgetProject';
import {WdiGridApp} from '@/components/WdiBus/PanelForWarning';

const FormBudgetInfo: Record<string, any> = {
  bohqId: (_: any, record: any) => {
    return record['bohqName'];
  },
  boId: (_: any, record: any) => {
    return record['boName'];
  },
  ecId: (_: any, record: any) => {
    return record['ecName'];
  }
};

const BudgetInformation: React.FC<{ formId?: number }> = (props) => {
  const [budgetInfos, setBudgetInfos] = useState<API.WdiGrid[]>([]);

  useEffect(() => {
    (async () => {
      if (props.formId) {
        const response = await FormBudgetProject.Buttons.getFormBudgetInfos(props.formId);
        const newData = [];
        const currentGrid = Object.assign({}, response?.data?.current, {
          key: "CurrentHQBudgetAmount",
          displayName: '当前预算信息'
        });
        newData.push(currentGrid);
        const submitGrid = Object.assign({}, response?.data?.current, {
          key: "SubmitHQBudgetAmount",
          displayName: '提交时预算信息',
          items: response.data.submit
        });
        newData.push(submitGrid);
        setBudgetInfos(newData);
      }
    })();
  }, [props.formId]);

  const renderWdiGridApp = (props: API.WdiGrid) => {
    const columns: any[] = [];
    props.wdiColumns.map((wdiColumn) => {
      if (!wdiColumn.isHidden) {
        const column = {
          title: wdiColumn.displayName,
          dataIndex: wdiColumn.name,
          width: wdiColumn.width,
          ellipsis: true,
          render: FormBudgetInfo[wdiColumn.name]
        };
        columns.push(column);
      }
    });

    return (
      <WdiGridApp
        key={props.key}
        wdiGrid={props}
        columns={columns}
        dataSource={props.items}
        isExport={false}
        exportColumns={[]}
      />
    );
  };

  return (
    <>
      {budgetInfos.map((item) => {
        return (
          renderWdiGridApp(item)
        );
      })}
    </>
  );
};

export default BudgetInformation;
