import { formatter, request } from '@/utils/request';

export namespace SoldTo {
  export const Path = {
    Search: '/mdmapi/soldTo/search',
    AjaxSearch: '/mdmapi/soldTo/ajaxSearch',
    SearchAll: '/mdmapi/soldTo/searchAll',
    AjaxSearchAll: '/mdmapi/soldTo/ajaxSearchAll',
    SearchForSp: '/mdmapi/soldTo/searchForSp',
    Query: '/mdmapi/soldTo/query',
    Export: '/mdmapi/soldTo/export',
    Get: '/mdmapi/soldTo/get',
    Post: '/mdmapi/soldTo/post',
    Delete: '/MDMApi/SoldTo/Delete',
    QueryForSheet: '/mdmapi/soldTo/queryForSheet',
    QueryForPoolSheet: '/mdmapi/soldTo/queryForPoolSheet',
    SimpleQueryForSheet: '/mdmapi/soldTo/simpleQueryForSheet',
    GetPoolMasterSoldTo: '/mdmapi/soldTo/getPoolMasterSoldTo',
    DealerSearch: '/mdmapi/soldTo/masterSearch',
  };

  export async function query(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.QueryResult<any>>(Path.Query, formatter(options));
  }

  export async function exportEx(body: any) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any[]>>(Path.Export, formatter(options));
  }

  export async function search(body: API.SoldToSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SoldTo[]>>(Path.Search, formatter(options));
  }

  export async function ajaxSearch(body: API.SoldToSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SoldTo[]>>(Path.AjaxSearch, formatter(options));
  }

  export async function searchAll(body: API.SoldToSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SoldTo[]>>(Path.SearchAll, formatter(options));
  }

  export async function ajaxSearchAll(body: API.SoldToSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SoldTo[]>>(Path.AjaxSearchAll, formatter(options));
  }

  export async function searchForSp(body: API.SoldToSearchForSpParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<API.SoldTo[]>>(Path.SearchForSp, formatter(options));
  }

  export async function get(id: string) {
    const options = {
      method: 'GET',
      body: {
        code: id,
      },
    };
    return request<API.ResponseResult<API.SoldTo>>(Path.Get, formatter(options));
  }

  export async function post(body: API.SoldToEntity) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any>>(Path.Post, formatter(options));
  }

  export async function del(id: string) {
    const options = {
      method: 'GET',
      body: {
        id: id,
      },
    };
    return request<API.ResponseResult<any>>(Path.Delete, formatter(options));
  }

  export async function queryForSheet(body: API.SoldToSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any[]>>(Path.QueryForSheet, formatter(options));
  }

  export async function simpleQueryForSheet(body: API.SoldToSearchParam) {
    const options = {
      method: 'POST',
      body: {
        ...body
      },
    };
    return request<API.ResponseResult<any[]>>(Path.SimpleQueryForSheet, formatter(options));
  }
}
