import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Row, Tabs, message, Badge} from 'antd';
import ProForm, {ProFormInstance} from '@ant-design/pro-form';
import {FileUploadUtility, WdiFileList} from '@/components/WdiControls/WdiFileUpload';
import TabPane from '@ant-design/pro-card/lib/components/TabPane';
import {FormPageProps, WdiFormPageTitle} from '@/components/WdiBus/FormPage';
import {ResponseStatus} from '@/utils/request';
import DataStore from '@/components/GridWidget/DataStore';
import {DataOptions} from '@/services/basic/metadata';
import {Enum} from '@/enums/system';
import {initApprovalPageContext, ViewEntityProps} from '../../RebateReimbursementApply/components/data';
import DynamicInformation from './DynamicInformation';
import {RebateReimbursementDetailsForApprovalPanel} from '../../RebateReimbursementApply/components/detail';
import {WdiStaffCard, WdiTextArea} from '@/components/WdiControls';
import {FormRs} from "@/services/sales/rs";
import {RSWarningApprovalApp} from "@/pages/contract/RebateInfo/components/WarningInformation";

const moreConfig: any = {
  successParams: [
    {
      url: "/basicapi/favorite/getButtons",
      keys: []
    }, {
      url: "/activityapi/rsButtons/get",
      keys: [
        // "UndoButton",
        "ReeditButton",
        "CancelButton",
        "InterveneFlowButton",
        // "ExportLockRemoveButton",
      ]
    }
  ],
  errorParams: [
    {
      url: "/activityapi/rsButtons/get",
      keys: [
        "RepairButton",
        "InterveneFlowButton"
      ]
    }
  ]
};

const approvalConfig: any = {
  params: {
    url: "/activityapi/rsButtons/get",
    keys: [
      "ApprovalButton"
    ]
  }
};

const RebateReimbursementApprovalApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [entity, setEntity] = useState<ViewEntityProps>();

  const [warningCount, setWarningCount] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApprovalPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;

        if (viewEntity.formRs) {
          formRef.current?.setFieldsValue({
            remark: viewEntity.formRs.remark,
            attachment: FileUploadUtility.parse(viewEntity.formRs.fileName, viewEntity.formRs.filePath)
          });
        }

        if (viewEntity.formRsDetails) {
          setDataSource(DataStore.init(viewEntity.formRsDetails));
        }

        const formRsId = viewEntity.formRs?.formRsId ?? 0;

        FormRs.Buttons.countOfWarning(formRsId).then(response => {
          setWarningCount(response.data);
        });

        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = `合同返利核销单【${entity.form?.formNo}】`;
      DataOptions.search({typeGroup: 3, value: entity.form?.statusId}).then(response => {
        if (response.code !== ResponseStatus.SUCCESS) {
          message.error(response.message);
          return;
        }
        if (response.data && response.data.length > 0) {
          if (entity.form?.statusId != Enum.FormStatus.SCRAP && entity.form?.exportStatus == Enum.ExportStatus.EXPORT_SUCCESS) {
            title += `，已导出`;
          } else {
            title += `，${response.data[0].name}`;
          }
          if (props.setPageTitle) props.setPageTitle(
            <WdiFormPageTitle
              title={title}
              onClose={props.onClose}
              callback={props.callback}
              form={entity?.form}
              useCaseId={Enum.UseCase.RS}
              moreConfig={moreConfig}
              approvalConfig={approvalConfig}/>
          );
        }
      });
    }
  }, [props.setPageTitle, entity]);

  return (
    <>

      <WdiStaffCard formId={entity?.form?.formId}/>
      <Tabs defaultActiveKey="1" size="large"> <TabPane tab={<span>核销信息</span>} key="1">
        <ProForm
          formRef={formRef}
          submitter={{
            render: () => {
              return (<></>)
            },
          }}
        >
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16}>
              <Form.Item name="remark" label="核销备注">
                <WdiTextArea rows={4} readOnly/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="attachment"
                label="核销附件"
                valuePropName='fileList'
                getValueFromEvent={(e: any) => {
                  return e.fileList || [];
                }}
              >
                <WdiFileList/>
              </Form.Item>
            </Col>
          </Row>
        </ProForm>
        <RebateReimbursementDetailsForApprovalPanel dataSource={dataSource}/>
      </TabPane>
        <TabPane
          tab={
            <>
              <span>警示信息</span>
              <Badge count={warningCount}
                     style={{
                       color: "black",
                       boxShadow: "none",
                       background: "none",
                       top: "-20px",
                       right: "-15px",
                       position: 'absolute'
                     }}>
              </Badge>
            </>
          }
          key="2"
        >
          <RSWarningApprovalApp formId={entity?.form?.formId}/>
        </TabPane>
        <TabPane tab={<span>动态信息</span>} key="3">
          <DynamicInformation formId={entity?.form?.formId} formTypeId={entity?.form?.formTypeId}
                              pageTypeId={entity?.form?.pageTypeId} useCaseId={Enum.UseCase.RSP}/>
        </TabPane>
      </Tabs>
    </>
  );
};
export default RebateReimbursementApprovalApp;
