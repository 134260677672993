import { wdiCore } from '@/components/WdiControls';
import { formatter, request } from './request';

const download = async (url: string, option: any, name?: string) => {
  option['responseType'] = 'blob';
  option['getResponse'] = true;
  return await request(url, option).then((response) => {
    const url = URL.createObjectURL(response.data);
    const filename = response.response.headers.get('ajax-name') || name || `Export_${wdiCore.Date.ToString(new Date())}.xlsx`;
    const a = document.createElement('a');
    a.href = url;
    try {
      a.download = window.decodeURIComponent(filename);
    } catch {
      a.download = filename;
    }
    a.target = '_blank';
    a.click();
    URL.revokeObjectURL(url);
  });
};

export function formatFileDownload(file: any) {
  let url = '/fileapi/file/download?name=' + window.encodeURIComponent(file.name);
  if (file.url) {
    if (String(file.url).length <= 15 && /^[0-9]*$/g.test(String(file.url))) {
      url += '&id=' + file.url;
    } else {
      url += '&path=' + window.encodeURIComponent(file.url);
    }
  }
  return url;
}

export function fileDownload(file: any) {
  const url = formatFileDownload(file);
  download(url, formatter({ method: 'GET' }), file.name);
}

export async function fileObjectURL(url: string) {
  const option: Record<string, any> = {};
  option['method'] = 'GET';
  option['responseType'] = 'blob';
  option['getResponse'] = true;
  const response = await request(url, option);
  return URL.createObjectURL(response.data);
}

export function isAnImage(fnExtension?: string) {
  if (fnExtension) {
    return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(fnExtension.toLowerCase()) !== -1;
  }
  return false;
}


export function isInvalidFile(fnExtension?: string) {
  if (fnExtension) {
    return ['php', 'asp', 'aspx'].indexOf(fnExtension.toLowerCase()) !== -1;
  }
  return false;
}

export default download;
