import React, { useEffect, useRef, useState } from 'react';
import { getParameter, MenuItemProps, OnChangeType } from "./data"
import { Button, Form, Input, message } from 'antd';
import { formatter, request, ResponseStatus } from '@/utils/request';
import { wdiCore, WdiModalAppProps, WdiModalAppResult, WdiModalProps } from '@/components/WdiControls';
import PageControl from '@/components/WdiBus/FormPage';
import { ProFormInstance } from '@ant-design/pro-form';
import { WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import ApprovalModalApp from './ApprovalModal';
import MyActivityPrevApp from '@/pages/sales/sa/MyActivity/components/MyActivityPrev';
import { Enum } from '@/enums/system';
import PaymentPrevApp from '@/pages/sales/sp/components/PaymentPrev';
import { FormSs } from '@/services/sales/ss';
import ViewProcessModalApp from '@/pages/sales/sa/components/ViewProcess';
import CancelPdModalApp from '@/pages/sales/sa/components/CancelPd';
import ModifyPaymentMethodModalApp from '@/pages/sales/sa/components/ModifyPaymentMethod';
import PrePaymentPrevApp from '@/pages/sales/sp/components/PrePaymentPrev';
import ModifyAmountModalApp from '@/pages/sales/sa/components/ModifyAmount';
import { ErrorInfo } from '@/services/flow';
import DataStore from '@/components/GridWidget/DataStore';
import { ColumnsType } from 'antd/lib/table';
import { WdiTable } from '@/components/WdiControls/WdiTable';
import PrepayTypeEdit from "@/pages/sales/sp/components/PrepayTypeEdit";
import InvoiceNoEdit from "@/pages/sales/sp/components/InvoiceNoEdit";

type ButtonProps = {
    item: MenuItemProps;
    onChange?: (type: number, result?: WdiModalAppResult) => void;
    onClose?: (result?: WdiModalAppResult) => void;
    callback?: (result?: WdiModalAppResult) => void;
};

export const FavoriteButton: React.FC<ButtonProps> = (props: ButtonProps) => {

    const handleClick = (e: any) => {
        const { item, onChange } = props;
        const args = getParameter(item);
        args.items.push({ name: "favoriteId", value: String(item.props.favoriteId) });
        args.items.push({ name: "keyWords", value: item.form.formNo });
        args.items.push({ name: "summary", value: item.form.summary || "" });
        request(item.url, formatter({ method: "POST", body: args })).then(res => {
            if (res.code === ResponseStatus.SUCCESS) {
                if (item.props.favoriteId == 0) {
                    message.success("收藏成功");
                } else {
                    message.success("取消收藏成功");
                }
                const button: API.Button = {
                    key: item.key,
                    url: item.url,
                    label: res.data.label,
                    tooltip: "",
                    extendProperties: {
                        favoriteId: res.data.favoriteId
                    }
                };

                if (onChange) {
                    onChange(OnChangeType.Source, {
                        code: ResponseStatus.SUCCESS,
                        data: button
                    });
                }
            } else {
                message.error(res.message);
            }
        })
    }
    return (
        <div onClick={handleClick}>
            {props.item.label}
        </div>
    )
};

export const UndoButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const submit = () => {
        const { item, onChange } = props;
        const args = getParameter(item);
        request(item.url, formatter({ method: "POST", body: args })).then(res => {
            if (res.code === ResponseStatus.SUCCESS) {
                message.success("操作成功");
                let backupUrl = "/home/index/?Tabs=MyDraft";
                if (item.props && item.props.BackupUrl) {
                    backupUrl = item.props.BackupUrl;
                }
                if (onChange) {
                    onChange(OnChangeType.Callback, {
                        code: ResponseStatus.SUCCESS,
                        data: backupUrl
                    });
                }
            } else {
                message.error(res.message);
            }
        })
    }

    const handleClick = (e: any) => {
        if (props.item.tooltip) {
            if (window.confirm(props.item.tooltip)) {
                submit();
            }
        } else {
            submit();
        }
    }
    return (
        <div onClick={handleClick}>
            {props.item.label}
        </div>
    )
};

export const ReeditButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const submit = () => {
        const { item } = props;
        props?.onClose?.();
        PageControl.apply({
            formTypeId: item?.form?.formTypeId,
            pageTypeId: item?.form?.pageTypeId,
            data: { relatedFormId: item?.form?.formId, relationType: Enum.FormRelationType.REJECTED }
        });
    }

    const handleClick = (e: any) => {
        if (props.item.tooltip) {
            if (window.confirm(props.item.tooltip)) {
                submit();
            }
        } else {
            submit();
        }
    }
    return (
        <div onClick={handleClick}>
            {props.item.label}
        </div>
    )
};

const CancelModalApp: React.FC<WdiModalAppProps> = (props) => {
    const formRef = useRef<ProFormInstance>();

    return (
        <WdiModalForm
            formRef={formRef}
            labelAlign='left'
            layout="horizontal"
            title="作废"
            visible={props.visible}
            modalProps={{
                onCancel: () => {
                    props.onClose ? props.onClose() : null
                },
            }}
            onFinish={async (values) => {
                const { ...entity } = values;
                const { data } = props;
                const args = getParameter(data);
                args.items.push({ name: "cancelReason", value: entity.cancelReason });
                args.items.push({ name: "isIgnorePermission", value: data?.props?.isIgnorePermission });
                request(data.url, formatter({ method: 'POST', body: args })).then((response) => {
                    if (response.code == ResponseStatus.SUCCESS) {
                        message.success('操作成功');
                        if (props.onSave) {
                            props.onSave({
                                code: ResponseStatus.SUCCESS,
                                data: data
                            });
                        }
                    } else {
                        message.error(response.message);
                    }
                });
            }}
        >
            <Form.Item name="cancelReason" label="作废原因" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}
                rules={[{ required: true, message: '作废原因不能为空!' }]}>
                <Input.TextArea style={{ resize: 'both' }} />
            </Form.Item>
        </WdiModalForm>
    );
};
export const CancelButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });

    const onCancelClose = () => {
        setWdiModalProps({ visible: false, data: null });
    }

    const onCancelSave = (result: any) => {
        const { onClose, item } = props;
        PageControl.approval({
            formTypeId: item?.form?.formTypeId,
            pageTypeId: item?.form?.pageTypeId,
            data: { formId: item?.form?.formId }
        });
        if (onClose) {
            onClose();
        }
        setWdiModalProps({ visible: false, data: null });
    }

    const handleClick = (e: any) => {
        const { item } = props;
        if (item.tooltip) {
            window.confirm(item.tooltip);
        }

        setWdiModalProps({ visible: true, data: item });
    }
    return (
        <>
            {wdiModalProps.visible &&
                <CancelModalApp
                    {...wdiModalProps}
                    onClose={onCancelClose}
                    onSave={onCancelSave}
                />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

export const InterveneFlowButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });

    const onApprovalClose = () => {
        setWdiModalProps({ visible: false, data: null });
    }

    const onApprovalSave = (result: any) => {
        setWdiModalProps({ visible: false, data: null });
        if (props.onClose) {
            props.onClose();
        }
        if (props.callback) {
            props.callback();
        }
    }

    const handleClick = (e: any) => {
        const { item } = props;
        setWdiModalProps({ visible: true, data: Object.assign({}, item, { tooltip: "确定要干预此流程实例吗？" }) });
    }

    return (
        <>
            {wdiModalProps.visible &&
                <ApprovalModalApp
                    {...wdiModalProps}
                    onClose={onApprovalClose}
                    onSave={onApprovalSave}
                />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

const RepairDialogApp: React.FC<WdiModalAppProps> = (props) => {
    const formRef = useRef<ProFormInstance>();
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<API.FlowNode[]>([]);
    useEffect(() => {
        setLoading(true);
        let processId = props?.data?.form?.processId || 0;

        ErrorInfo.Search({ processId: processId }).then(response => {
            if (response.code === ResponseStatus.SUCCESS && response.data) {
                setDataSource(DataStore.init(response?.data));
            }
        });

        setLoading(false);
    }, [props.data]);

    const columns: ColumnsType<API.ErrorInfo> = [
        {
            title: '错误发生时间',
            dataIndex: 'errorTime',
            width: 150,
            ellipsis: true,
            render: (_, record) => (
                wdiCore.Date.MinuteToString(record.errorTime)
            ),
        },
        {
            title: '错误信息',
            dataIndex: 'errorMessage',
            ellipsis: true,
        },
    ];
    const submit = () => {
        const { data } = props;
        const args = getParameter(data);

        request(data.url, formatter({ method: "POST", body: args })).then(res => {
            if (res.code === ResponseStatus.SUCCESS) {
                message.success("操作成功");
            } else {
                message.error(res.message);
            }
            if (props.onSave) {
                props.onSave({
                    code: ResponseStatus.SUCCESS,
                    data: data
                });
            }
        })
    }

    return (
        <WdiModalForm
            formRef={formRef}
            labelAlign='left'
            layout="horizontal"
            title="流程修复"
            visible={props.visible}
            modalProps={{
                onCancel: () => {
                    props.onClose?.()
                },
            }}
            submitter={{
                render: (props) => {
                    return [
                        <Button key="Repair" type="primary" onClick={() => {
                            props.submit();
                        }}>
                            修复
                        </Button>
                    ];
                },
            }}
            onFinish={async (values) => {
                if (props?.data?.tooltip) {
                    if (window.confirm(props?.data?.tooltip)) {
                        submit();
                    }
                } else {
                    submit();
                }
            }}
        >
            <WdiTable
                columns={columns}
                showPageRows={3}
                dataSource={dataSource}
                pagination={false}
                loading={loading}
            />
        </WdiModalForm>
    );
}
export const RepairButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });

    const onClose = () => {
        setWdiModalProps({ visible: false, data: null });
    }

    const onSave = (result: any) => {
        setWdiModalProps({ visible: false, data: null });
        if (props.onClose) {
            props.onClose();
        }
        if (props.callback) {
            props.callback();
        }
    }

    const handleClick = (e: any) => {
        const { item } = props;
        setWdiModalProps({ visible: true, data: item });
    }

    return (
        <>
            {wdiModalProps.visible &&
                <RepairDialogApp
                    {...wdiModalProps}
                    onClose={onClose}
                    onSave={onSave}
                />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
}

export const CopyButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const { item } = props;
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false, title: "", id: null });
    const onPrevClose = (result?: WdiModalAppResult) => {
        setWdiModalProps({ visible: false, title: "", id: null });
    };
    const onPrevSave = (result?: WdiModalAppResult) => {
        if (result?.code === ResponseStatus.SUCCESS) {
            setWdiModalProps({ visible: false, title: "", id: null });
            props?.onClose?.();
            PageControl.apply({
                formTypeId: result.data?.formTypeId,
                pageTypeId: result.data?.pageTypeId,
                data: result.data
            });

        }
    };

    const handleClick = (e: any) => {
        if (item?.form?.formTypeId == Enum.FormType.ACTIVITY_MKT_APPLICATION) {
            props?.onClose?.();
            PageControl.apply({
                formTypeId: item?.form?.formTypeId,
                pageTypeId: item?.form?.pageTypeId,
                data: {
                    relatedFormId: item?.form?.formId, relatedType: Enum.FormRelationType.COPY, pageTypeId: item?.form?.pageTypeId,
                    formTypeId: item?.form?.formTypeId,
                }
            });
        } else {
            setWdiModalProps({
                visible: true, title: '活动申请', id: null, data: {
                    relatedFormId: item?.form?.formId,
                    relationType: Enum.FormRelationType.COPY
                }
            })
        }
    }
    return (
        <>
            {
                wdiModalProps.visible &&
                <MyActivityPrevApp
                    visible={wdiModalProps.visible}
                    title={wdiModalProps.title}
                    id={wdiModalProps.id}
                    data={wdiModalProps.data}
                    onClose={onPrevClose}
                    onSave={onPrevSave}
                />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

export const ClosedButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });
    const submit = () => {
        const { item } = props;
        const args = getParameter(item);
        args.items.push({ name: "doValidate", value: "true" });
        request(item.url, formatter({ method: "POST", body: args })).then(res => {
            if (res.code === ResponseStatus.SUCCESS) {
                const alterInfo = res.data.alertInfo ? res.data.alertInfo : '确定要关闭吗？';
                if (window.confirm(alterInfo)) {
                    if (res.data.doCancel) {
                        //作废
                        const item = Object.assign({}, props.item, { url: res.data.cancelUrl, });
                        item.props = { isIgnorePermission: true, ...item.props };
                        setWdiModalProps({ visible: true, data: item });
                    } else {
                        const argsSubmit = getParameter(item);
                        request(item.url, formatter({ method: "POST", body: argsSubmit })).then(response => {
                            if (response.code === ResponseStatus.SUCCESS) {
                                message.success("操作成功");
                                if (props.onClose) {
                                    props.onClose();
                                }
                                if (props.callback) {
                                    props.callback();
                                }
                            } else {
                                message.error(response.message);
                            }
                        })

                    }
                }
            } else {
                message.error(res.message);
            }
        })
    }

    const onCancelClose = () => {
        setWdiModalProps({ visible: false, data: null });
    }

    const onCancelSave = (result: any) => {
        const { onClose, item } = props;
        PageControl.approval({
            formTypeId: item?.form?.formTypeId,
            pageTypeId: item?.form?.pageTypeId,
            data: { formId: item?.form?.formId }
        });
        if (onClose) {
            onClose();
        }
        setWdiModalProps({ visible: false, data: null });
    }

    const handleClick = (e: any) => {
        submit();
    }
    return (
        <>
            {wdiModalProps.visible &&
                <CancelModalApp
                    {...wdiModalProps}
                    onClose={onCancelClose}
                    onSave={onCancelSave}
                />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

export const OpenedButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const submit = () => {
        const { item } = props;
        const args = getParameter(item);
        // args.items.push({ name: "doValidate", value: "true" });
        // request(item.url, formatter({ method: "POST", body: args })).then(res => {
        //   if (res.code === ResponseStatus.SUCCESS) {
        //     if (window.confirm(res.data.alertInfo)) {
        //       const argsSubmit = getParameter(item);
        request(item.url, formatter({ method: "POST", body: args })).then(response => {
            if (response.code === ResponseStatus.SUCCESS) {
                message.success("操作成功");
                if (props.onClose) {
                    props.onClose();
                }
                if (props.callback) {
                    props.callback();
                }
            } else {
                message.error(response.message);
            }
        })
        //     }
        //   }
        //   else {
        //     message.error(res.message);
        //   }
        // })
    }

    const handleClick = (e: any) => {
        if (confirm("确定要将此已经关闭的活动重新打开吗？")) {
            submit();
        }
    }
    return (
        <div onClick={handleClick}>
            {props.item.label}
        </div>
    )
};

export const UpdateApplyButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const { item } = props;

    const submit = () => {
        props?.onClose?.();
        PageControl.apply({
            formTypeId: item.form.formTypeId,
            pageTypeId: item.form.pageTypeId,
            data: { relatedFormId: item?.form?.formId, relationType: Enum.FormRelationType.MODIFIED }
        });
    }

    const handleClick = (e: any) => {
        if (item.tooltip) {
            if (confirm(item.tooltip)) {
                submit();
            }
        } else {
            submit();
        }
    }
    return (
        <div onClick={handleClick}>
            {props.item.label}
        </div>
    )
};

export const SettleButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const handleClick = (e: any) => {
        const { item } = props;
        props?.onClose?.();
        PageControl.apply({
            formTypeId: Enum.FormType.ACTIVITY_SETTLEMENT,
            pageTypeId: Enum.PageType.ACTIVITY_SETTLEMENT,
            data: { formSaId: item.form?.formId }
        });
    }
    return (
        <>
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

export const PrePaymentButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });

    const onPrevSave = (result?: WdiModalAppResult) => {
        if (result?.code === ResponseStatus.SUCCESS) {
            setWdiModalProps({ visible: false, title: "", id: null });
            props?.onClose?.();
            PageControl.apply({
                formTypeId: result.data?.formTypeId,
                pageTypeId: result.data?.pageTypeId,
                data: result.data
            });
        }
    };
    const onClose = (result?: WdiModalAppResult) => {
        setWdiModalProps({ visible: false, title: "", id: null });
    };

    const submit = () => {
        const { item } = props;
        const args = getParameter(item);
        request(item.url, formatter({ method: "POST", body: args })).then(res => {
            if (res.code === ResponseStatus.SUCCESS) {
                setWdiModalProps({ visible: true, title: '选择供应商', id: item?.form.formId, type: item?.form.pageTypeId })
            } else {
                message.error(res.message);
            }
        })
    }

    const handleClick = (e: any) => {
        submit();
    }
    return (
        <>
            {wdiModalProps.visible && wdiModalProps.type == Enum.PageType.ACTIVITY_APPLICATION &&
                <PrePaymentPrevApp
                    {...wdiModalProps}
                    onClose={onClose}
                    onSave={onPrevSave}
                />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

export const PaymentButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });

    const onPrevSave = (result?: WdiModalAppResult) => {
        if (result?.code === ResponseStatus.SUCCESS) {
            setWdiModalProps({ visible: false, title: "", id: null });
            props?.onClose?.();
            PageControl.apply({
                formTypeId: result.data?.formTypeId,
                pageTypeId: result.data?.pageTypeId,
                data: result.data
            });
        }
    };
    const onClose = (result?: WdiModalAppResult) => {
        setWdiModalProps({ visible: false, title: "", id: null });
    };

    const submit = () => {
        const { item } = props;
        const args = getParameter(item);
        request(item.url, formatter({ method: "POST", body: args })).then(res => {
            if (res.code === ResponseStatus.SUCCESS) {
                if (item?.form.formTypeId == Enum.FormType.ACTIVITY_APPLICATION) {
                    setWdiModalProps({
                        visible: true,
                        title: '筛选核销范围',
                        data: {
                            formSaId: item?.form.formId,
                            pageTypeId: item?.form.pageTypeId
                        },
                    })
                } else {
                    FormSs.get(item?.form.formId).then(response => {
                        setWdiModalProps({
                            visible: true,
                            title: '筛选核销范围',
                            data: {
                                formSaId: response?.data?.formSaId,
                                pageTypeId: response?.data?.pageTypeId
                            },
                        })
                    })
                }
            } else {
                message.error(res.message);
            }
        })
    }

    const handleClick = (e: any) => {
        submit();
    }
    return (
        <>
            {wdiModalProps.visible && props.item.form.formTypeId == Enum.FormType.ACTIVITY_APPLICATION &&
                <PaymentPrevApp
                    {...wdiModalProps}
                    onClose={onClose}
                    onSave={onPrevSave}
                />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

export const UnlockButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const submit = () => {
        const { item } = props;
        const args = getParameter(item);
        request(item.url, formatter({ method: "POST", body: args })).then(res => {
            if (res.code === ResponseStatus.SUCCESS) {
                message.success("解锁导出锁定成功");
                if (props.onClose) {
                    props.onClose();
                }
                if (props.callback) {
                    props.callback();
                }
            } else {
                message.error(res.message);
            }
        })
    }

    const handleClick = (e: any) => {
        if (window.confirm("解除锁定后可再次将本单据导出，请确认SAP没有接收过此单据，确定继续吗？")) {
            submit();
        }
    }
    return (
        <div onClick={handleClick}>
            {props.item.label}
        </div>
    )
};

export const ViewProcessButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });

    const onClose = (result?: WdiModalAppResult) => {
        setWdiModalProps({ visible: false, title: "", id: null });
    };

    const handleClick = (e: any) => {
        const { item } = props;
        setWdiModalProps({ visible: true, title: '活动执行情况', id: item?.form.formId, data: item });
    };

    return (
        <>
            {wdiModalProps.visible &&
                <ViewProcessModalApp {...wdiModalProps} onClose={onClose} />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

export const ViewSfaCollectionDetailsButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const submit = () => {
        const { item } = props;
        const args = getParameter(item);
        request(item.url, formatter({ method: "POST", body: args })).then(res => {
            if (res.code === ResponseStatus.SUCCESS) {
                window.open(res.data);
            } else {
                message.error(res.message);
            }
        })

    }
    const handleClick = (e: any) => {
        submit();
    }
    return (
        <>
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

export const ModifyPaymentMethodButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });

    const onClose = (result?: WdiModalAppResult) => {
        setWdiModalProps({ visible: false, title: "", id: null });
    };

    const handleClick = (e: any) => {
        const { item } = props;
        setWdiModalProps({ visible: true, title: '修改支付方式', id: item?.form.formId, data: item });
    };

    const onSave = () => {
        const { onClose, item } = props;
        PageControl.approval({
            formTypeId: item?.form?.formTypeId,
            pageTypeId: item?.form?.pageTypeId,
            data: { formId: item?.form?.formId }
        });
        if (onClose) {
            onClose();
        }
        setWdiModalProps({ visible: false });
    };

    return (
        <>
            {wdiModalProps.visible &&
                <ModifyPaymentMethodModalApp {...wdiModalProps} onClose={onClose} onSave={onSave} />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};


export const ModifyAmountButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });

    const onClose = (result?: WdiModalAppResult) => {
        setWdiModalProps({ visible: false, title: "", id: null });
    };

    const handleClick = (e: any) => {
        const { item } = props;
        setWdiModalProps({ visible: true, title: '修改申请金额', id: item?.form.formId, data: item });
    };

    const onSave = () => {
        const { onClose, item } = props;
        PageControl.approval({
            formTypeId: item?.form?.formTypeId,
            pageTypeId: item?.form?.pageTypeId,
            data: { formId: item?.form?.formId }
        });
        if (onClose) {
            onClose();
        }
        setWdiModalProps({ visible: false });
    };

    return (
        <>
            {wdiModalProps.visible && Enum.FormType.ACTIVITY_APPLICATION == props.item.form.formTypeId &&
                <ModifyAmountModalApp {...wdiModalProps} onClose={onClose} onSave={onSave} />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

export const CancelPdButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });
    const { onClose, item } = props;

    const onModalClose = (result?: WdiModalAppResult) => {
        setWdiModalProps({ visible: false });
    };

    const onSave = (result?: WdiModalAppResult) => {
        PageControl.approval({
            formTypeId: item?.form?.formTypeId,
            pageTypeId: item?.form?.pageTypeId,
            data: { formId: item?.form?.formId }
        });
        if (onClose) {
            onClose();
        }
        setWdiModalProps({ visible: false });
    }
    const handleClick = (e: any) => {
        setWdiModalProps({ visible: true, title: '取消随货活动（请选择你要取消的行）', id: item?.form.formId, data: item });
    };

    return (
        <>
            {wdiModalProps.visible &&
                <CancelPdModalApp {...wdiModalProps} onClose={onModalClose} onSave={onSave} />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};

export const CompleteDeliveryButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const submit = () => {
        const { item } = props;
        const args = getParameter(item);
        request(item.url, formatter({ method: "POST", body: args })).then(res => {
            if (res.code === ResponseStatus.SUCCESS) {
                message.success("操作成功");
                if (props.onClose) {
                    props.onClose();
                }
                if (props.callback) {
                    props.callback();
                }
            } else {
                message.error(res.message);
            }
        })
    }

    const handleClick = (e: any) => {
        if (window.confirm("确定要将此已经关闭的活动重新打开吗？")) {
            submit();
        }
    }
    return (
        <div onClick={handleClick}>
            {props.item.label}
        </div>
    )
};
export const ModifyPrepayButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });
    const { onClose, item } = props;
    const onModalClose = (result?: WdiModalAppResult) => {
        setWdiModalProps({ visible: false });
    };
    const onSave = () => {

        PageControl.approval({
            formTypeId: item?.form?.formTypeId,
            pageTypeId: item?.form?.pageTypeId,
            data: { formId: item?.form?.formId }
        });
        if (onClose) {
            onClose();
        }
        setWdiModalProps({ visible: false });
    }
    const handleClick = (e: any) => {
        setWdiModalProps({ visible: true, title: '修改是否冲销预付', id: item?.form.formId, data: item });
    };

    return (
        <>
            {wdiModalProps.visible &&
                <PrepayTypeEdit {...wdiModalProps} onClose={onModalClose} onSave={onSave} />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
}

export const ModifyInvoiceNoButton: React.FC<ButtonProps> = (props: ButtonProps) => {

    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });
    const { onClose, item } = props;
    const onModalClose = (result?: WdiModalAppResult) => {
        setWdiModalProps({ visible: false });
    };
    const onSave = () => {

        PageControl.approval({
            formTypeId: item?.form?.formTypeId,
            pageTypeId: item?.form?.pageTypeId,
            data: { formId: item?.form?.formId }
        });
        if (onClose) {
            onClose();
        }
        setWdiModalProps({ visible: false });
    }
    const handleClick = (e: any) => {
        setWdiModalProps({ visible: true, title: '发票信息导入', id: item?.form.formId, data: item });
    };

    return (
        <>
            {wdiModalProps.visible &&
                <InvoiceNoEdit {...wdiModalProps} onClose={onModalClose} onSave={onSave} />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
}


const TbdApp: React.FC<WdiModalAppProps> = (props) => {
    const formRef = useRef<ProFormInstance>();

    return (
        <WdiModalForm
            formRef={formRef}
            labelAlign='left'
            layout="horizontal"
            title="待定"
            visible={props.visible}
            modalProps={{
                onCancel: () => {
                    props.onClose ? props.onClose() : null
                },
            }}
            onFinish={async (values) => {
                const { ...entity } = values;
                const { data } = props;
                const args = getParameter(data);
                args.items.push({ name: "tbdReason", value: entity.tbdReason });
                args.items.push({ name: "isIgnorePermission", value: data?.props?.isIgnorePermission });
                request(data.url, formatter({ method: 'POST', body: args })).then((response) => {
                    if (response.code == ResponseStatus.SUCCESS) {
                        message.success('操作成功');
                        if (props.onSave) {
                            props.onSave({
                                code: ResponseStatus.SUCCESS,
                                data: data
                            });
                        }
                    } else {
                        message.error(response.message);
                    }
                });
            }}
        >
            <Form.Item name="tbdReason" label="待定原因" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                <Input.TextArea style={{ resize: 'both' }} />
            </Form.Item>
        </WdiModalForm>
    );
};
export const TbdButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [wdiModalProps, setWdiModalProps] = useState<WdiModalProps>({ visible: false });

    const onCancelClose = () => {
        setWdiModalProps({ visible: false, data: null });
    }

    const onCancelSave = (result: any) => {
        const { onClose, item } = props;
        PageControl.approval({
            formTypeId: item?.form?.formTypeId,
            pageTypeId: item?.form?.pageTypeId,
            data: { formId: item?.form?.formId }
        });
        if (onClose) {
            onClose();
        }
        setWdiModalProps({ visible: false, data: null });
    }

    const handleClick = (e: any) => {
        const { item } = props;

        setWdiModalProps({ visible: true, data: item });
    }
    return (
        <>
            {wdiModalProps.visible &&
                <TbdApp
                    {...wdiModalProps}
                    onClose={onCancelClose}
                    onSave={onCancelSave}
                />
            }
            <div onClick={handleClick}>
                {props.item.label}
            </div>
        </>
    )
};


export const CancelTbdButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const submit = () => {
        const { item } = props;
        const args = getParameter(item);
        request(item.url, formatter({ method: "POST", body: args })).then(res => {
            if (res.code === ResponseStatus.SUCCESS) {
                message.success("取消待定成功");
                if (props.onClose) {
                    props.onClose();
                }
                if (props.callback) {
                    props.callback();
                }
            } else {
                message.error(res.message);
            }
        })
    }

    const handleClick = (e: any) => {
        if (window.confirm("确定取消待定吗？")) {
            submit();
        }
    }
    return (
        <div onClick={handleClick}>
            {props.item.label}
        </div>
    )
};
