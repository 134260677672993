import React, {useRef} from 'react';
import {Form,} from 'antd';
import type {ProFormInstance} from '@ant-design/pro-form';
import type {WdiModalAppProps} from '@/components/WdiControls';
import {HorzLayoutOpts, WdiModalForm} from '@/components/WdiControls/WdiModalForm';
import {ResponseStatus} from '@/utils/request';
import {WdiSelect} from '@/components/WdiControls/WdiSelect';
import {BOHQ} from "@/services/mdm/bohq";
import {Enum} from "@/enums/system";

const PaymentPrevApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  return (
    <WdiModalForm
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      modalProps={{
        onCancel: () => {
          props.onClose ? props.onClose() : null
        },
        okText: '下一步'
      }}
      {...HorzLayoutOpts}
      onFinish={async (values) => {
        const entity = {
          formTypeId: Enum.FormType.ACTIVITY_PAYMENT,
          bohqId: values.bohqId,
        };
        const response: any = {
          code: ResponseStatus.SUCCESS,
          message: '成功',
          data: entity,
        };
        if (props.onSave) {
          props.onSave(response);
        }
      }}
    >

      <Form.Item name="bohqId" label='费用归属' rules={[{required: true}]}>
        <WdiSelect {...{
          placeholder: "请选择费用归属",
          showSearch: true,
          dataLoading: async (params) => {
            const response = await BOHQ.search(Object.assign({withPermissions: true}, params));
            return response?.data.map(item => {
              return {value: item.bohqId, label: item.bohqName}
            });
          }
        }} />
      </Form.Item>
    </WdiModalForm>
  );
};
export default PaymentPrevApp;
