import { Enum } from "@/enums/system";
import useWindowSize from "@/utils/useWindowSize";
import { Drawer, DrawerProps } from "antd";
import { wdiCore } from "./wdiCore";
import { WdiIcon } from "./WdiIcon";

export type WdiDrawerProps = {
  hasConfirm?: boolean;
  confirmmessage?: string;
} & DrawerProps;


export const getWdiDrawerDefaultProps = (props: WdiDrawerProps) => {
  const { width } = useWindowSize();
  const defaultProps: WdiDrawerProps = {
    headerStyle: { padding: '16px 18px' },
    width: width - 208,
    destroyOnClose: true,
    mask: false,
    bodyStyle: { padding: '0 24px', paddingBottom: 20 },
    hasConfirm: false,
    confirmmessage: '系统不会保存您所做的更改，确定要关闭此界面吗？',
    closeIcon: <WdiIcon type={Enum.IconType.CLOSE} />
  };
  if (props.width) {
    defaultProps.width = props.width;
  }
  if (props.confirmmessage) {
    defaultProps.confirmmessage = props.confirmmessage;
  }
  if (props.hasConfirm) {
    defaultProps.hasConfirm = props.hasConfirm;
  }
  if (!wdiCore.Object.IsNullOrUndefined(props.closable) && !props.closable) {
    defaultProps.headerStyle = { padding: '16px 22px' };
  }
  if (props.headerStyle) {
    defaultProps.headerStyle = props.headerStyle;
  }
  if (props.closeIcon) {
    defaultProps.closeIcon = props.closeIcon;
  }
  return defaultProps;
};

export const WdiDrawer: React.FC<WdiDrawerProps> = (props) => {
  const { children, hasConfirm, onClose, confirmmessage, ...newProps } = Object.assign({}, getWdiDrawerDefaultProps(props), props);
  const onConfirmClose = (e: any) => {
    if (hasConfirm) {
      if (window.confirm(confirmmessage)) {
        onClose?.(e);
      }
    } else {
      onClose?.(e);
    }
  }
  const antdProps = Object.assign({}, newProps);
  return (
    <Drawer {...antdProps} onClose={onConfirmClose}>
      {children}
    </Drawer>
  )
}
