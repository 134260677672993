import DataStore from "@/components/GridWidget/DataStore";
import { getParameter } from "@/components/WdiBus/Buttons/data";
import { WdiModalAppProps, wdiCore } from "@/components/WdiControls";
import { WdiModalForm } from "@/components/WdiControls/WdiModalForm";
import { WdiTable } from "@/components/WdiControls/WdiTable";
import { Enum } from "@/enums/system";
import { FormSaDetail } from "@/services/sales/sa";
import { formatter, request, ResponseStatus } from "@/utils/request";
import { ProFormInstance } from "@ant-design/pro-form";
import { message, Modal, TableColumnsType } from "antd";
import { Key, useEffect, useRef, useState } from "react";
const { error } = Modal;

const CancelPdModalApp: React.FC<WdiModalAppProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [reloadKey, setReloadKey] = useState(0);

  const columns: TableColumnsType<any> = [
    {
      title: '行号',
      width: 50,
      dataIndex: 'rowNumber',
    },
    {
      title: '售达方',
      dataIndex: 'soldToCode',
      ellipsis: true,
      width: 260,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.soldToCode, record.soldToName)}</span>
      ),
    },
    {
      title: '购买产品',
      dataIndex: 'productBuyNames',
      ellipsis: true,
      width: 260,
    },
    {
      title: '产品政策',
      dataIndex: 'productPolicyId',
      ellipsis: true,
      width: 200,
      render: (_, record) => (
        <span>{wdiCore.String.Join(record.policyCode, record.policyName)}</span>
      ),
    },
    {
      title: '费用项',
      dataIndex: 'ecName',
      width: 150,
      ellipsis: true,
    },
    {
      title: '预估销量',
      dataIndex: 'quantityForecast',
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <span>{wdiCore.Number.AmountToString(record.quantitySellinForecast ?? record.quantityPosForecast ?? 0)}</span>
      ),
    },
    {
      title: '预计销售额',
      dataIndex: 'revenueForecast',
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <span>{wdiCore.Number.AmountToString(record.revenueSellinForecast ?? record.revenuePosForecast ?? 0)}</span>
      ),
    },
    {
      title: '申请金额',
      dataIndex: 'amount',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
    {
      title: '实际销量',
      dataIndex: 'quantityActual',
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <span>{wdiCore.Number.AmountToString(record.quantitySellinActual ?? record.quantityPosActual ?? 0)}</span>
      ),
    },
    {
      title: '实际销售额',
      dataIndex: 'revenueActual',
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <span>{wdiCore.Number.AmountToString(record.revenueSellinActual ?? record.revenuePosActual ?? 0)}</span>
      ),
    },
    {
      title: '当前实际花费',
      dataIndex: 'amountActual',
      width: 100,
      ellipsis: true,
      render: (value) => (
        <span>{wdiCore.Number.AmountToString(value)}</span>
      ),
    },
  ]

  useEffect(() => {
    if (props.id) {
      const formSaId = Number(props.id);
      setLoading(true);
      FormSaDetail.getsEx(formSaId).then((response) => {
        if (response && response.data) {
          const formSaDetails = response.data.filter(m => m.syncStatus == Enum.SyncStatus.SYNC_SUCCESS);
          setDataSource(DataStore.init(formSaDetails));
          setLoading(false);
        }
      });
    }
  }, [props.id, reloadKey]);

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  return (
    <WdiModalForm
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      width={1350}
      modalProps={{
        onCancel: () => { props.onClose?.() },
      }}
      onFinish={async () => {
        const { data } = props;
        const args = getParameter(data);
        args.items.push({ name: "formSaDetailIds", value: selectedRowKeys.join() });
        if (confirm("确定要对这些行进行取消操作吗？取消后将立即生效")) {
          request(data.url, formatter({ method: 'POST', body: args })).then((response) => {
            if (response.code == ResponseStatus.SUCCESS) {
              message.success('取消成功');
              props.onSave?.();
            } else {
              error({
                content: response.message,
                okText: '确定',
                onOk: () => {
                  setReloadKey(reloadKey + 1);
                }
              });
            }
          });
        }
      }}
    >
      <WdiTable
        rowKey="formSaDetailId"
        showPageRows={10}
        rowSelection={{ selectedRowKeys, onChange: onSelectChange }}
        columns={columns}
        dataSource={dataSource}
        pagination={{ showSizeChanger: false }}
        loading={loading}
      />
    </WdiModalForm>
  );
};

export default CancelPdModalApp;
