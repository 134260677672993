import {wdiCore} from '@/components/WdiControls';
import {WdiInputNumber} from '@/components/WdiControls/WdiInputNumber';
import {Col, Row} from 'antd';
import {responseResultError, responseResultOk} from "@/utils/response";
import {useEffect} from "react";

export const ColumnType = {
  //临界金额
  MinValue1: 1,
  //临界数量
  MinValue2: 2,
  //临界比例
  MinValue3: 3,
  //返利比例
  StandardValue1: 4,
  //单件费用
  StandardValue2: 5,
};

type SCTRateGridHeaderProps = {};
const headerStyle: React.CSSProperties = {
  textAlign: 'left',
  height: '35px',
  lineHeight: '35px',
  paddingLeft: '2px',
  border: '1px solid #DDDDDD',
};
const SCTRateGridHeader: React.FC<SCTRateGridHeaderProps> = (props: SCTRateGridHeaderProps) => {
  return (
    <Row style={{border: '1px solid #DDDDDD', borderCollapse: 'collapse'}}>
      <Col span={5} style={headerStyle}>临界金额</Col>
      <Col span={5} style={headerStyle}>临界数量(听)</Col>
      <Col span={5} style={headerStyle}>临界比例(%)</Col>
      <Col span={5} style={headerStyle}>结案比例</Col>
      <Col span={4} style={headerStyle}>单位费用(听)</Col>
    </Row>
  );
};

type SCTRateGridRowValue = {
  minValue1?: number;
  minValue2?: number;
  minValue3?: number;
  standardValue1?: number;
  standardValue2?: number;
};

type SCTRateGridRowProps = {
  rowIndex: number;
  readonly?: boolean;
  criticalType?: number;
  standardType?: number;
  value?: SCTRateGridRowValue;
  onChange: (columnType: number, rowIndex: number, v: number | null) => void;
  isQuantity?: boolean;
};

const rowStyle: React.CSSProperties = {
  textAlign: 'left',
  height: '35px',
  lineHeight: '35px',
  paddingLeft: '1px',
  border: '1px solid #DDDDDD',
};

const isDisabled = (readonly: boolean, target: number, result?: number) => {
  if (readonly) {
    return true;
  }
  if (result === undefined || result === null || result === 0) {
    return false;
  }
  if (target === result) {
    return false;
  }
  return true;
};

const isNullOrEmpty = (value: any) => {
  if (value === null || value === undefined) {
    return true;
  }
  if (value === "") {
    return true;
  }
  return false;
};

const SCTRateGridRow: React.FC<SCTRateGridRowProps> = (props: SCTRateGridRowProps) => {
  return (
    <Row>
      <Col span={5} style={rowStyle}>
        <WdiInputNumber
          readOnly={isDisabled(!!props.readonly, ColumnType.MinValue1, props.criticalType) || props.isQuantity}
          min={0}
          value={props.value?.minValue1}
          onChange={
            (value: any) => {
              if (!wdiCore.Object.IsNullOrUndefined(value) && !wdiCore.String.IsNullOrEmpty(value)) {
                props.onChange(ColumnType.MinValue1, props.rowIndex, wdiCore.Number.ToNumber(value, 2));
              } else {
                props.onChange(ColumnType.MinValue1, props.rowIndex, null);
              }
            }
          }
        />
      </Col>
      <Col span={5} style={rowStyle}>
        <WdiInputNumber
          readOnly={isDisabled(!!props.readonly, ColumnType.MinValue2, props.criticalType) || !props.isQuantity}
          min={0}
          value={props.value?.minValue2}
          onChange={
            (value: any) => {
              if (!wdiCore.Object.IsNullOrUndefined(value) && !wdiCore.String.IsNullOrEmpty(value)) {
                props.onChange(ColumnType.MinValue2, props.rowIndex, wdiCore.Number.ToNumber(value, 2));
              } else {
                props.onChange(ColumnType.MinValue2, props.rowIndex, null);
              }
            }
          }
        />
      </Col>
      <Col span={5} style={rowStyle}>
        <WdiInputNumber
          readOnly={isDisabled(!!props.readonly, ColumnType.MinValue3, props.criticalType) || props.isQuantity}
          format="p"
          digits={4}
          min={0}
          value={props.value?.minValue3}
          onChange={
            (value: any) => {
              if (!wdiCore.Object.IsNullOrUndefined(value) && !wdiCore.String.IsNullOrEmpty(value)) {
                props.onChange(ColumnType.MinValue3, props.rowIndex, wdiCore.Number.ToNumber(value, 6));
              } else {
                props.onChange(ColumnType.MinValue3, props.rowIndex, null);
              }
            }
          }
        />
      </Col>
      <Col span={5} style={rowStyle}>
        <WdiInputNumber
          readOnly={isDisabled(!!props.readonly, ColumnType.StandardValue1, props.standardType) || props.isQuantity}
          format="p"
          digits={4}
          min={0}
          value={props.value?.standardValue1}
          onChange={
            (value: any) => {
              if (!wdiCore.Object.IsNullOrUndefined(value) && !wdiCore.String.IsNullOrEmpty(value)) {
                props.onChange(ColumnType.StandardValue1, props.rowIndex, wdiCore.Number.ToNumber(value, 6));
              } else {
                props.onChange(ColumnType.StandardValue1, props.rowIndex, null);
              }
            }
          }
        />
      </Col>
      <Col span={4} style={rowStyle}>
        <WdiInputNumber
          readOnly={isDisabled(!!props.readonly, ColumnType.StandardValue2, props.standardType) || !props.isQuantity}
          min={0}
          value={props.value?.standardValue2}
          onChange={
            (value: any) => {
              if (!wdiCore.Object.IsNullOrUndefined(value) && !wdiCore.String.IsNullOrEmpty(value)) {
                props.onChange(ColumnType.StandardValue2, props.rowIndex, wdiCore.Number.ToNumber(value, 2));
              } else {
                props.onChange(ColumnType.StandardValue2, props.rowIndex, null);
              }
            }
          }
        />
      </Col>
    </Row>
  );
};

type SCTRateGridValue = {
  criticalType?: number;
  standardType?: number;
  items: SCTRateGridRowValue [];
};

type SCTRateGridProps = {
  readonly?: boolean;
  value?: SCTRateGridValue;
  onChange?: (value?: SCTRateGridValue) => void;
  isQuantity?: boolean;
};

const SCTRateGrid: React.FC<SCTRateGridProps> = (props: SCTRateGridProps) => {
  const onValueChange = (columnType: number, rowIndex: number, v: number | null) => {
    const value: SCTRateGridValue = {
      standardType: props.value?.standardType,
      criticalType: props.value?.criticalType,
      items: []
    };
    let criticalType = 0, standardType = 0;
    props.value?.items?.forEach((item, itemIndex) => {
      let result = item;
      if (itemIndex === rowIndex) {
        if (columnType == ColumnType.MinValue1) {
          result.minValue1 = v
        }
        if (columnType == ColumnType.MinValue2) {
          result.minValue2 = v
        }
        if (columnType == ColumnType.MinValue3) {
          result.minValue3 = v
        }
        if (columnType == ColumnType.StandardValue1) {
          result.standardValue1 = v
        }
        if (columnType == ColumnType.StandardValue2) {
          result.standardValue2 = v
        }
      }
      const rateGridValue = rateGridTypeValue(result);
      if (criticalType === 0) {
        criticalType = rateGridValue.criticalType;
      }
      if (standardType === 0) {
        standardType = rateGridValue.standardType;
      }
      value.items.push(result);
    });
    value.criticalType = criticalType;
    value.standardType = standardType;
    props.onChange?.(value);
  };

  useEffect(() => {
    const {...v} = Object.assign({}, props.value);
    if (v) {
      v?.items?.map(item => {
        if (props.isQuantity) {
          item.minValue1 = undefined;
          item.minValue3 = undefined;
          item.standardValue1 = undefined;
        } else {
          item.minValue2 = undefined;
          item.standardValue2 = undefined;
        }
      })
      if (props.isQuantity) {
        v.criticalType = ColumnType.MinValue2;
        v.standardType = ColumnType.StandardValue2;
      } else if (v?.items) {
        if (v?.items[0].minValue1 != null) {
          v.criticalType = ColumnType.MinValue1;
          v.standardType = ColumnType.StandardValue1;
        } else if (v?.items[0].minValue3 != null) {
          v.criticalType = ColumnType.MinValue3;
          v.standardType = ColumnType.StandardValue1;
        } else {
          v.criticalType = undefined;
          v.standardType = ColumnType.StandardValue1;
        }
      }
      props?.onChange?.(v);
    }
  }, [props.isQuantity]);
  return (
    <>
      <SCTRateGridHeader/>
      {
        props.value &&
        props.value.items &&
        props.value.items?.map((item, itemIndex) => (
          <SCTRateGridRow
            key={itemIndex}
            rowIndex={itemIndex}
            readonly={props.readonly}
            criticalType={props.value?.criticalType}
            standardType={props.value?.standardType}
            value={item}
            onChange={onValueChange}
            isQuantity={props.isQuantity}
          />
        ))
      }
    </>
  );
};

const res = {
  rowNumberP1: (rowNumber: number) => {
    return "第" + rowNumber + "行，"
  },
  criticalMust0: "临界值必须为0",
  criticalOnly1: "临界值只能填写1个",
  resultOnly1: "结案比例/单位费用只能填写1个",
  rebateRateMust0_1: "结案比例必须在0～1之间",
  unitFeeMustGt0: "单位费用必须为正数",
  criticalMustNumber: "临界值必须为数字",
  currentMustGtLast: "临界值必须大于上一行的临界值",
};

const utils = {
  value1: (target: API.SalesContractRate, source: SCTRateGridRowValue) => {
    target.minValue = source.minValue1;
    target.minValue1 = source.minValue1;
  },
  value2: (target: API.SalesContractRate, source: SCTRateGridRowValue) => {
    target.minValue = source.minValue2;
    target.minValue2 = source.minValue2;
  },
  value3: (target: API.SalesContractRate, source: SCTRateGridRowValue) => {
    target.minValue = source.minValue3;
    target.minValue3 = source.minValue3;
  },
  result1: (target: API.SalesContractRate, source: SCTRateGridRowValue) => {
    target.standardValue = source.standardValue1;
    target.standardValue1 = source.standardValue1;
  },
  result2: (target: API.SalesContractRate, source: SCTRateGridRowValue) => {
    target.standardValue = source.standardValue2;
    target.standardValue2 = source.standardValue2;
  }
};

type RateGridTypeValue = {
  criticalType: number;
  standardType: number;
};

const rateGridTypeValue = (row: SCTRateGridRowValue) => {
  let criticalType = 0, standardType = 0;
  if (!isNullOrEmpty(row.minValue1)) {
    criticalType = ColumnType.MinValue1;
  }
  if (!isNullOrEmpty(row.minValue2)) {
    criticalType = ColumnType.MinValue2;
  }
  if (!isNullOrEmpty(row.minValue3)) {
    criticalType = ColumnType.MinValue3;
  }
  if (!isNullOrEmpty(row.standardValue1)) {
    standardType = ColumnType.StandardValue1;
  }
  if (!isNullOrEmpty(row.standardValue2)) {
    standardType = ColumnType.StandardValue2;
  }
  return {
    criticalType: criticalType,
    standardType: standardType
  }
};

const initSalesContractRateGrid = (values?: API.SalesContractRate[]) => {
  const rows: SCTRateGridRowValue[] = [];
  let criticalType = 0, standardType = 0;
  if (values) {
    values.forEach((item) => {
      const row: SCTRateGridRowValue = {
        minValue1: item.minValue1,
        minValue2: item.minValue2,
        minValue3: item.minValue3,
        standardValue1: item.standardValue1,
        standardValue2: item.standardValue2
      };
      const rateGridValue = rateGridTypeValue(row);
      if (criticalType === 0) {
        criticalType = rateGridValue.criticalType;
      }
      if (standardType === 0) {
        standardType = rateGridValue.standardType;
      }
      rows.push(row);
    });
  }
  // 返利标准必须是5行
  for (let i = rows.length; i < 5; i++) {
    rows.push({});
  }
  return {
    criticalType,
    standardType,
    items: rows
  };
};

const initRows = (rateGridValue?: SCTRateGridValue) => {
  const rows: any[] = [];
  if (rateGridValue?.items && rateGridValue.standardType && rateGridValue.criticalType) {
    const stantdardName = "standardValue" + (rateGridValue?.standardType - 3)
    const criticalName = "minValue" + rateGridValue?.criticalType
    rateGridValue?.items?.forEach((item: any, index) => {
      if (rateGridValue.standardType) {
        const obj: any = {};
        obj[stantdardName] = item[stantdardName]
        obj[criticalName] = item[criticalName]
        rows.push(obj)
      }
    })
  }
  return rows;
}

const convertToResult = (rateGridValue?: SCTRateGridValue) => {
  const rows = initRows(rateGridValue);
  const values: API.SalesContractRate[] = [];
  let criticalType = 0, standardType = 0;
  if (rows && rows.length) {
    let currentRow = rows[0];
    let currentValue: API.SalesContractRate = {};

    if (!isNullOrEmpty(currentRow.minValue1)) {
      criticalType = ColumnType.MinValue1;
      if (currentRow.minValue1 != 0) {
        return responseResultError(res.rowNumberP1(1) + res.criticalMust0);
      }
      utils.value1(currentValue, currentRow);
    }

    if (!isNullOrEmpty(currentRow.minValue2)) {
      if (criticalType != 0) {
        return responseResultError(res.rowNumberP1(1) + res.criticalOnly1);
      }
      criticalType = ColumnType.MinValue2;
      if (currentRow.minValue2 != 0) {
        return responseResultError(res.rowNumberP1(1) + res.criticalMust0);
      }
      utils.value2(currentValue, currentRow);
    }

    if (!isNullOrEmpty(currentRow.minValue3)) {
      if (criticalType != 0) {
        return responseResultError(res.rowNumberP1(1) + res.criticalOnly1);
      }
      criticalType = ColumnType.MinValue3;
      if (currentRow.minValue3 != 0) {
        return responseResultError(res.rowNumberP1(1) + res.criticalMust0);
      }
      utils.value3(currentValue, currentRow);
    }

    if (criticalType == 0) {
      return responseResultError(res.rowNumberP1(1) + res.criticalOnly1);
    }

    if (!isNullOrEmpty(currentRow.standardValue1)) {
      standardType = ColumnType.StandardValue1;
      if (currentRow.standardValue1 < 0 || 1 < currentRow.standardValue1) {
        return responseResultError(res.rowNumberP1(1) + res.rebateRateMust0_1);
      }
      utils.result1(currentValue, currentRow);
    }

    if (!isNullOrEmpty(currentRow.standardValue2)) {
      if (standardType != 0) {
        return responseResultError(res.rowNumberP1(1) + res.resultOnly1);
      }
      standardType = ColumnType.StandardValue2;
      if (currentRow.standardValue2 < 0) {
        return responseResultError(res.rowNumberP1(1) + res.unitFeeMustGt0);
      }
      utils.result2(currentValue, currentRow);
    }

    if (standardType == 0) {
      return responseResultError(res.rowNumberP1(1) + res.resultOnly1);
    }

    values.push(currentValue);

    let lastValue: API.ProductPolicyRate = currentValue;
    for (let i = 1; i < rows.length; i++) {
      currentRow = rows[i];
      currentValue = {};

      if (standardType === ColumnType.StandardValue1) {
        if (isNullOrEmpty(currentRow.standardValue1)) {
          break;
        }
        if (currentRow.standardValue1 < 0 || 1 < currentRow.standardValue1) {
          return responseResultError(res.rowNumberP1(i + 1) + res.rebateRateMust0_1);
        }
        utils.result1(currentValue, currentRow);
      }

      if (standardType === ColumnType.StandardValue2) {
        if (isNullOrEmpty(currentRow.standardValue2)) {
          break;
        }
        if (currentRow.standardValue2 < 0) {
          return responseResultError(res.rowNumberP1(i + 1) + res.unitFeeMustGt0);
        }
        utils.result2(currentValue, currentRow);
      }

      if (criticalType === ColumnType.MinValue1) {
        if (isNullOrEmpty(currentRow.minValue1)) {
          return responseResultError(res.rowNumberP1(i + 1) + res.criticalMustNumber)
        }
        utils.value1(currentValue, currentRow);
      }

      if (criticalType === ColumnType.MinValue2) {
        if (isNullOrEmpty(currentRow.minValue2)) {
          return responseResultError(res.rowNumberP1(i + 1) + res.criticalMustNumber)
        }
        utils.value2(currentValue, currentRow);
      }

      if (criticalType === ColumnType.MinValue3) {
        if (isNullOrEmpty(currentRow.minValue3)) {
          return responseResultError(res.rowNumberP1(i + 1) + res.criticalMustNumber)
        }
        utils.value3(currentValue, currentRow);
      }

      lastValue.maxValue = currentValue.minValue;
      if (lastValue.minValue >= lastValue.maxValue) {
        return responseResultError(res.rowNumberP1(i + 1) + res.currentMustGtLast)
      }
      values.push(currentValue);
      lastValue = currentValue;
    }
    lastValue.maxValue = 99999999;
  }
  return responseResultOk({criticalType, standardType, data: values});
};

export {convertToResult, initSalesContractRateGrid, SCTRateGrid};
