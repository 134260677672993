import React, { useState, useEffect, useMemo } from 'react';
import { Select, TreeSelect } from 'antd';
import { wdiCore } from './wdiCore';
import { FieldProps } from './FieldProps';
import type { SelectProps } from 'antd/es/select';
import { TreeSelectProps } from 'antd/es/tree-select';

/// remote 根据录入内容实时搜索
/// reloadKey 改变之后重新加载数据
/// dataLoading
/// antdProps: render中antd属性会覆盖掉默认属性
export type WdiSelectProps = {
  label?: string;
  remote?: boolean;
  reloadKey?: string | number;
  dataLoading?: (params: any) => Promise<any[]>;
} & SelectProps;

export const getWdiSelectDefaultProps = (props: WdiSelectProps) => {
  const defaultProps: WdiSelectProps = {
    maxTagCount: 'responsive'
  };
  defaultProps.showSearch = true;
  defaultProps.showArrow = false;
  if (props.remote) {
    defaultProps.filterOption = false;
  } else {
    defaultProps.filterOption = (inputValue, option) => {
      return (option && wdiCore.String.Match(option.label, inputValue)) ? true : false;
    }
  }
  if (props.mode === 'multiple') {
    defaultProps.autoClearSearchValue = false;
    defaultProps.maxTagPlaceholder = FieldProps.Select.createTooltipMaxTagPlaceholder();
  } else {
    defaultProps.allowClear = true;
  }
  if (props.value) {
    defaultProps.value = props.value;
  }
  if (wdiCore.Object.IsNullOrUndefined(props.dropdownMatchSelectWidth)) {
    defaultProps.dropdownMatchSelectWidth = false;
  }
  return defaultProps;
};

const timerRef: any = {
  current: {
    value: null,
    timerID: null
  }
};

export namespace WdiSelectFormat {
  export const getvalue = (value: any) => {
    if (wdiCore.Object.IsArray(value)) {
      return value.map((item: any) => {
        if (wdiCore.Object.IsObject(item)) {
          return item.value;
        }
        return item;
      }).join();
    } else if (wdiCore.Object.IsObject(value)) {
      return value['value'];
    } else {
      return value;
    }
  };
  export const gettext = (value: any) => {
    if (wdiCore.Object.IsArray(value)) {
      return value.map((item: any) => item.label).join();
    } else if (wdiCore.Object.IsObject(value)) {
      return value['label'];
    } else {
      return value;
    }
  };
};

/// remote
/// reloadKey
/// dataLoading
export const WdiSelect: React.FC<WdiSelectProps> = (props) => {
  const { remote, reloadKey, dataLoading, label, ...newProps } = Object.assign({}, getWdiSelectDefaultProps(props), props);
  const [options, setOptions] = useState<any[]>([]);

  const handleComboboxRemoteSearch = useMemo(() => {
    return (inputValue: any) => {
      if (timerRef.current && timerRef.current.timerID) {
        clearTimeout(timerRef.current.timerID);
        timerRef.current.timerID = null;
      }
      if (timerRef.current) {
        timerRef.current.value = inputValue;
        const fetchComboboxRemote = () => {
          if (dataLoading) {
            const params: any = { top: 100 };
            if (!wdiCore.String.IsNullOrEmpty(inputValue)) {
              params.filterValue = inputValue;
            }
            dataLoading(params).then((items: any) => {
              if (wdiCore.String.EqIngoreCase(inputValue, timerRef.current.value)) {
                setOptions(items)
              }
            });
          }
        };
        timerRef.current.timerID = window.setTimeout(fetchComboboxRemote, 300);
      }
    };
  }, [reloadKey, remote]);

  useEffect(() => {
    //解决组件卸载后更新问题
    let unmounted = false;
    if (dataLoading) {
      const params: any = {};
      if (remote) {
        params.top = 100;
      }
      dataLoading(params).then((items: any) => {
        if (unmounted) {
          return;
        }
        setOptions(items);
      });
    }

    return () => {
      unmounted = true;
    };
  }, [reloadKey, remote])

  const antdProps = Object.assign({ options }, newProps);
  if (remote) {
    antdProps.onSearch = handleComboboxRemoteSearch;
  }

  antdProps.onClear = () => {
    newProps?.onClear?.();
    if (remote) {
      handleComboboxRemoteSearch("");
    }
  }
  antdProps.onBlur = (e) => {
    newProps?.onBlur?.(e);
    if (remote) {
      handleComboboxRemoteSearch("");
    }
  }
  if (!antdProps.placeholder) {
    antdProps.placeholder = "请选择" + (label || "");
  }
  return (
    <Select {...antdProps} />
  )
};

export type WdiTreeSelectProps = {
  label?: string;
  reloadKey?: any;
  dataLoading?: (params: any) => Promise<any>;
  onExSelect?: (value: any, node: any, extra: any) => void;
  defaultExpandKeys?: string[];
  ref?: any;
  // 搜索框输入多少字符再检索，默认2个
  filterKeyLength?: number;
} & TreeSelectProps;

export const getWdiTreeSelectDefaultProps = (props: WdiTreeSelectProps) => {
  const defaultProps: WdiTreeSelectProps = {
    maxTagCount: 'responsive',
    treeNodeFilterProp: 'filterKey',
    showSearch: true,
    showArrow: false,
    autoClearSearchValue: false
  };
  if (props.filterTreeNode) {
    defaultProps.filterTreeNode = props.filterTreeNode;
  } else {
    const filterKeyLength = props.filterKeyLength || 2;
    defaultProps.filterTreeNode = (value, treeNode) => {
      if (value.length >= filterKeyLength) {
        return treeNode.filterKey?.includes(value);
      }
      return false;
    }
  }
  if (props.multiple) {
    defaultProps.treeCheckStrictly = true;
    defaultProps.treeCheckable = true;
    defaultProps.maxTagPlaceholder = FieldProps.Select.createTooltipMaxTagPlaceholder();
  } else {
    defaultProps.allowClear = true;
  }
  if (wdiCore.Object.IsNullOrUndefined(props.dropdownMatchSelectWidth)) {
    defaultProps.dropdownMatchSelectWidth = false;
  }
  return defaultProps;
}

export const WdiTreeSelect: React.FC<WdiTreeSelectProps> = React.forwardRef(
  (props, ref) => {
    const { reloadKey, dataLoading, onExSelect, defaultExpandKeys, label, ...newProps } = Object.assign({}, getWdiTreeSelectDefaultProps(props), props);
    const [treeData, setTreeData] = useState<any[]>([]);
    const [dict, setDict] = useState<any>({});
    const [treeDefaultExpandedKeys, setTreeDefaultExpandedKeys] = useState<any[]>([]);

    //导出ref 获取内部数据
    React.useImperativeHandle(ref,
      () => ({
        getTreeData: () => { return treeData; },
        getDict: () => { return dict; }
      })
    );

    useEffect(
      () => {
        //解决组件卸载后更新问题
        //devScripts.js:6523 Warning: Can't perform a React state update on an unmounted component.
        //This is a no-op, but it indicates a memory leak in your application.
        //To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
        let unmounted = false;
        (
          async () => {
            if (dataLoading) {
              const data = await dataLoading({});
              if (unmounted) {
                return;
              }

              setTreeData(data.treeData);
              setTreeDefaultExpandedKeys(FieldProps.TreeSelect.getTreeExpandedKeys(data.treeData));
              setDict(data.dict);
            } else if (props.treeData) {
              if (unmounted) {
                return;
              }

              setTreeDefaultExpandedKeys(FieldProps.TreeSelect.getTreeExpandedKeys(props.treeData));
              setDict(FieldProps.TreeSelect.buildDictMap(props.treeData));
            }
          }
        )();
        return () => {
          unmounted = true;
        }
      }, [reloadKey, props.treeData]);

    const antdProps = Object.assign({
      treeData,
      treeDefaultExpandedKeys: (defaultExpandKeys && defaultExpandKeys.length > 0) ? defaultExpandKeys : treeDefaultExpandedKeys
    }, newProps);
    if (onExSelect) {
      antdProps.onSelect = (value: any, node: any) => {
        if (onExSelect) {
          onExSelect(value, node, dict);
        }
      };
    }
    if (!antdProps.placeholder) {
      antdProps.placeholder = "请选择" + (label || "");
    }
    return (
      <TreeSelect {...antdProps} />
    )
  }
)
