import { useEffect, useRef, useState } from 'react';
import { DetailGrid, Utility, wdiCore } from '@/components/GridWidget';
import { NsGrid } from '@/components/GridWidget/GridDefProps';
import { WdiModalProps, WdiModalAppResult, WdiCardTitle, WdiModalAppProps, FieldProps } from '@/components/WdiControls';
import { Button, Dropdown, Form, Menu, Space, message } from 'antd';
import { TableColumnRanderFormats, initFieldDisplayFormater } from '@/components/WdiBus/TableColumnRanderFormats'
import DataStore from '@/components/GridWidget/DataStore'
import { ResponseStatus, formatter } from '@/utils/request';
import { WdiPanel } from '@/components/WdiControls/WdiPanel';
import { ImportModalApp } from '@/components/WdiControls/WdiImport';
import { last } from 'lodash';
import { Enum } from '@/enums/system';
import { GridApi } from '@/services/gridapi';
import { ToExcelProps, toExcel } from '@/components/WdiControls/WdiExcel';
import { Product } from '@/services/mdm/product';
import { EC } from '@/services/mdm/ec';
import FormSaDetailPrevApp from './detail-prev';
import { getAllHasValueColumns, getHasValueColumns, getRateColumns } from '../../components/data';
import FormSaDetailCompareModalApp from '../../components/FormSaDetailCompare';
import { SA } from '@/services/report/sa';
import { HorzLayoutOpts, WdiModalForm } from '@/components/WdiControls/WdiModalForm';
import { ProFormInstance } from '@ant-design/pro-form';
import { WdiSelectFormat, WdiTreeSelect } from '@/components/WdiControls/WdiSelect';
import { FormSa } from '@/services/sales/sa';
import download from '@/utils/download';
import DealerDetailApp from './detail-edit';
import { SoldTo } from '@/services/mdm/soldto';

const ModalTypeEnums = {
  None: 0,
  Prev: -1,
  Import: -2,
  Dealer: 4,
  AUTO_CALC_FOR_ACTIVITY: 5,
};

export type DetailForApplyProps = {
  data?: {
    form?: API.Form,
    formSa?: API.FormSa,
    ecFieldConfigs?: API.EcFieldConfig[]
  },
  dataSource: any[],
  onChange?: (dataSource: any) => void;
};

const ExportTemplateModalApp: React.FC<WdiModalAppProps> = (props: WdiModalAppProps) => {
  const formRef = useRef<ProFormInstance>();

  const { formSa } = props.data;

  return (
    <WdiModalForm
      formRef={formRef}
      title={props.title}
      visible={props.visible}
      modalProps={{
        onCancel: () => {
          props.onClose?.()
        },
      }}
      submitter={{ searchConfig: { submitText: '下载', }, }}
      onFinish={async (values) => {
        const { ecId, ...entity } = values;
        entity.ecId = WdiSelectFormat.getvalue(ecId);
        entity.pageTypeId = formSa.pageTypeId;
        entity.tempName = 'ActivityGeneralApply.xlsx';
        await download(FormSa.Buttons.Path.downloadTempByEc, formatter({ method: 'POST', body: { ...entity } }));
        props.onClose?.()
      }}
      {...HorzLayoutOpts}
    >
      <Form.Item name="ecId" label="费用项" rules={[{ required: true, message: '费用项不能为空!' }]}>
        <WdiTreeSelect {...{
          placeholder: "请选择费用项",
          dataLoading: async (params) => {
            const response = await EC.searchEc3(Object.assign({
              toParentLevel: Enum.ECLevel.EC2,
              bohqId: formSa.bohqId,
              pageTypeIds: String(formSa.pageTypeId),
              isActive: true
            }, params));
            const { treeData, dict } = FieldProps.TreeSelect.formatTreeData({
              textField: "ecName",
              valueField: "ecId",
              id: "ecId",
              pId: "parentId",
              level: "ecLevel",
              disabledLevel: [Enum.ECLevel.EC2],
            }, response.data);
            return { treeData, dict };
          }
        }} />
      </Form.Item>
    </WdiModalForm>
  )
}

/**
列表默认顺序：售达方，产品别，费用项，活动机制，分子平台，省份，申请金额
列表配置总体顺序为：售达方，产品别，费用项，活动机制（有值显示），分子平台（有值显示），
省份（有值显示），申请金额，明细活动开始日期，明细活动截止日期，明细供货开始日期，明细供货截止日期，核算类型，产品线，备注；
 */
const initRowFieldConfigs = (record: any, ecFieldConfigs: API.EcFieldConfig[] | undefined) => {
  const columns = [ "soldToCode", "productBuyIds", "ecId", "promotionDesc",  "amount",  "activityStartDate", "activityEndDate", "supplyStartDate", "supplyEndDate", "revenueType", "productLineId", "remark", "amountAccrual", "amountActual", "amountOriginal", "paymentMethodId", "formSaDetailId"];
  columns.push(...getAllHasValueColumns(record));

  return columns;
};

export const FormSaDetailsForApplyPanel: React.FC<DetailForApplyProps> = (props) => {
  // Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [importExcels, setImportExcels] = useState<ToExcelProps[]>([]);
  const [modalProps, setModalProps] = useState<WdiModalProps>({ visible: false });
  const [exportModalProps, setExportModalProps] = useState<WdiModalProps>({ visible: false });

  const { data, dataSource, onChange } = props;

  const onModalClose = () => {
    setModalProps({ visible: false, title: "", type: ModalTypeEnums.None, id: null });
  };

  const onImportSave = (uploadResult: any) => {
    if (uploadResult.code == ResponseStatus.SUCCESS) {
      const newDataSource = [...dataSource];
      const result = uploadResult?.data?.data;

      if (result && result.length > 0) {
        if (uploadResult?.data?.exProperty) {
          //是否清空
          onChange?.(DataStore.init(result));
        }
        else {
          onChange?.(DataStore.addMulti(newDataSource, result));
        }
      }
    }
    setModalProps({ visible: false, title: "", type: ModalTypeEnums.None, id: null });
  };

  const onPrevSave = (result?: WdiModalAppResult) => {
    const modalSetting = { visible: true, type: ModalTypeEnums.Dealer, data: result?.data };

    if (result?.data.rowIndex === -1) {
      setModalProps({ title: "新建费用明细", ...modalSetting });
    } else {
      setModalProps({ title: "编辑费用明细", ...modalSetting });
    }
  };

  const onEditSave = (result?: WdiModalAppResult) => {
    if (result && result.code === ResponseStatus.SUCCESS) {
      const row = result?.data;
      if (result?.data.rowIndex > -1) {
        const record = modalProps.data.row;
        const newItem = { ...row };
        newItem.uid = record.uid;
        const newDataSouce = DataStore.edit(dataSource, newItem);
        onChange?.(newDataSouce);
      } else {
        const newItem = { ...row };
        onChange?.(DataStore.add(dataSource, newItem));
      }
    }
    setModalProps({ visible: false, title: "", type: ModalTypeEnums.None, id: null });
  };

  useEffect(() => {
    GridApi.searchConfig({ id: 'FormSADetailForDealer', allowModifyColumns: true }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.Left,
        showAggr: true,
        showExpandedRow: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      // settings.formSchema.allFields.paymentMethodId.getDisplayFormater = (text: any, record: any, index: any) => {
      //   return record.paymentMethodName;
      // };
      // settings.formSchema.allFields.invoiceType.getDisplayFormater = (text: any, record: any, index: any) => {
      //   return record.invoiceTypeName;
      // };
      // settings.formSchema.allFields.vatTypeId.getDisplayFormater = (text: any, record: any, index: any) => {
      //   return record.vatTypeName;
      // };

      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.ecId.width = 200;
      settings.formSchema.allFields.amount.width = 120;
      settings.formSchema.allFields.amount.aggr = TableColumnRanderFormats.sumAggr('amount', 2);

      Utility.setActionCallback(settings.formSchema.actions, 'Update',
        async (grid: any, action: any, record: any) => {
          const context = grid.getContext();
          setModalProps({
            visible: true,
            title: '选择费用类型',
            type: ModalTypeEnums.Prev,
            id: record.formSaDetailId,
            data: {
              row: record,
              rows: context.dataSource,
              rowIndex: DataStore.getRowIndex(context.dataSource, record.uid),
              formSa: context.params.formSa,
            }
          });
        },
      );

      Utility.setActionCallback(settings.formSchema.actions, 'Copy',
        async (grid: any, action: any, record: any) => {
          const context = grid.getContext();
          setModalProps({
            visible: true,
            title: '选择费用类型',
            type: ModalTypeEnums.Prev,
            id: record.formSaDetailId,
            data: {
              row: record,
              rows: context.dataSource,
              rowIndex: -1,
              formSa: context.params.formSa,
            }
          });
        },
      );

      Utility.setActionCallback(settings.formSchema.actions, 'SystemDelete',
        async (grid: any, action: any, record: any) => {
          if (window.confirm("确定要删除吗？")) {
            const context = grid.getContext();
            onChange?.(DataStore.del(context.dataSource, record.uid));
            message.success("删除成功");
          }
        },
      );

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    const soldToSheet = {
      dataLoading: async (params: any) => {
        return await SoldTo.queryForSheet({
          isActive: true,
          withPermissions: true,
          bohqId: props.data?.formSa?.bohqId
        });
      },
      data: null,
      names: [
        { name: "soldToCode", displayName: "售达方编号" },
        { name: "soldToName", displayName: "售达方名称" }
      ],
      sheetName: "售达方",
    };

    const productSheet = {
      dataLoading: async () => {
        return await Product.queryForSheet({
          isActive: true,
          productLevel: Enum.ProductLevel.PRODUCT4,
          bohqId: props.data?.formSa?.bohqId
        });
      },
      data: null,
      names: [
        { name: "productCode4", displayName: "产品组编号" },
        { name: "productName4", displayName: "产品组名称" },
        { name: "productCode3", displayName: "产品段编号" },
        { name: "productName3", displayName: "产品段名称" },
        { name: "productCode2", displayName: "产品线编号" },
        { name: "productName2", displayName: "产品线名称" },
        { name: "productCode1", displayName: "品牌编号" },
        { name: "productName1", displayName: "品牌名称" },
      ],
      sheetName: "产品",
    };
    const ecSheet = {
      dataLoading: async (params: any) => {
        return await EC.queryForSheet({
          ecLevel: Enum.ECLevel.EC3,
          pageTypeId: Enum.PageType.ACTIVITY_APPLICATION_DEALER,
          bohqId: props.data?.formSa?.bohqId
        });
      },
      data: null,
      names: [
        { name: "ecName1", displayName: "费用大类名称" },
        { name: "ecName2", displayName: "费用小类名称" },
        { name: "ecName3", displayName: "费用项名称" },
        { name: "revenueTypeNames", displayName: "核算类型" },
      ],
      sheetName: "费用类别",
    };

    const importExcels = [
      {
        name: "全部下载",
        sheetProps: [soldToSheet, productSheet, ecSheet]
      }, {
        name: "售达方列表",
        sheetProps: [soldToSheet]
      }, {
        name: "产品列表",
        sheetProps: [productSheet]
      }, {
        name: "费用类别列表",
        sheetProps: [ecSheet]
      }
    ];
    setImportExcels(importExcels);
  }, [props.data]);

  const pageGridSettings = Object.assign({
    antdProps: {
      dataSource,
      initRowFieldConfigs: (record: any) => { return initRowFieldConfigs(record, data?.ecFieldConfigs); },
      pagination: {
        defaultPageSize: 10,
        showSizeChanger: true
      }
    }
  }, gridSettings);

  return (
    <WdiPanel
      title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用明细信息' />}
      extra={
          <Dropdown.Button
            type='primary'
            onClick={() => {
              const row: any = {};
              if (dataSource && dataSource.length > 0) {
                const lastrow = last(dataSource);
                row.ecId = lastrow.ecId;
                row.soldToCode = lastrow.soldToCode;
                row.soldToName = lastrow.soldToName;
                row.custSysCode = lastrow.custSysCode;
                row.custSysName = lastrow.custSysName;
              }
            
              setModalProps({
                visible: true,
                title: '选择费用类型',
                type: ModalTypeEnums.Prev,
                id: null,
                data: {
                  row: row,
                  rows: dataSource,
                  rowIndex: -1,
                  formSa: data?.formSa,
                }
              });
            }}
            overlay={
              <Menu items={
                [{
                  key: 'import',
                  label: '导入',
                  onClick: () => {                  
                    //活动日期必填
                    if (data?.formSa?.activityStartDate && data?.formSa?.activityEndDate) {
                      setModalProps({ title: '费用明细导入', visible: true, type: ModalTypeEnums.Import });
                    }
                    else {
                      message.error("请填写表头的活动期间");
                      return;
                    }
                  }
                }]}
              />
            }
            style={{ float: 'right' }}>
            新建
          </Dropdown.Button>
      }
    >
      {
        isLoaded && modalProps.visible && modalProps.type === ModalTypeEnums.Import
        && <ImportModalApp
          title={modalProps.title}
          visible={modalProps.visible}
          settingsUrl="/activityapi/saButtons/getImportSettings"
          uploadingUrl="/activityapi/saButtons/importUploading"
          onClose={onModalClose}
          onSave={onImportSave}
          clientParameters={{
            formSa: data?.formSa,
            formSaDetails: JSON.stringify(dataSource)
          }}
          importExcels={importExcels}
          events={{
            downloadTempByEc: () => {
              setExportModalProps({ visible: true, data: data, title: '下载数据模板' });
            },
          }}
        />
      }
      {exportModalProps.visible &&
        <ExportTemplateModalApp
          {...exportModalProps}
          onClose={() => {
            setExportModalProps({ visible: false });
          }}
        />
      }
      {
        isLoaded && modalProps.visible && modalProps.type === ModalTypeEnums.Prev
        && <FormSaDetailPrevApp {...modalProps} onClose={onModalClose} onSave={onPrevSave} />
      }
      {
        isLoaded && modalProps.visible && (modalProps.type === ModalTypeEnums.Dealer)
        && <DealerDetailApp {...modalProps} onClose={onModalClose} onSave={onEditSave} />
      }

      {
        isLoaded && <DetailGrid {...pageGridSettings} params={data} autoColumn={['rowNumber']} />
      }
    </WdiPanel>
  );
};

/**
 * 默认列：同申请；可配置列：同申请
 * 可配置字段按费用项+核算类型的申请所需内容来显示，加上原申请金额；）其中产品政策要变为超链接形式，点击后打开产品政策的查看页面（去掉more和动态信息）
 */
const initRowFieldConfigsApproval = (record: any, ecFieldConfigs: API.EcFieldConfig[] | undefined) => {
  const columns = initRowFieldConfigs(record, ecFieldConfigs);
  columns.push(...getHasValueColumns(record));
  columns.push(...getRateColumns(record, columns));
  return columns;
};

export const FormSaDetailsForApprovalPanel: React.FC<DetailForApplyProps> = (props) => {
  /// Grid初始化配置，GridSettings请求完数据之后再进行加载
  const [isLoaded, setIsLoaded] = useState(false);
  const [gridSettings, setGridSettings] = useState<any>({});
  const [modalProps, setModalProps] = useState<WdiModalProps>({ visible: false });

  useEffect(() => {
    GridApi.searchConfig({ id: 'FormSADetailForDealerApproval', allowModifyColumns: true }).then((response) => {
      const settings: any = Object.assign({}, response.data, {
        actionsAlign: NsGrid.ActionsAlign.None,
        showAggr: true,
        showExpandedRow: true,
      });

      initFieldDisplayFormater(settings.formSchema.allFields);

      settings.formSchema.allFields.rowNumber.width = 50;
      settings.formSchema.allFields.ecId.width = 200;
      settings.formSchema.allFields.amount.width = 120;
      settings.formSchema.allFields.amount.aggr = TableColumnRanderFormats.sumAggr('amount', 2);

      setGridSettings(settings);
      setIsLoaded(true);
    });
  }, []);

  const { dataSource, data } = props;

  const pageGridSettings = Object.assign({
    antdProps: {
      dataSource,
      initRowFieldConfigs: (record: any) => { return initRowFieldConfigsApproval(record, data?.ecFieldConfigs); },
      pagination: {
        defaultPageSize: 10,
        showSizeChanger: true
      }
    }
  }, gridSettings);

  useEffect(() => {
    if (data?.form) {
      setModalProps(Object.assign({}, modalProps, { title: "费用明细变更对比", data: { form: data.form } }));
    }
  }, [data]);

  const exportDetails = () => {
    const columns = [
      { name: "soldToCode", displayName: "售达方编号" },
      { name: "soldToName", displayName: "售达方名称" },
      { name: "productBuyNames", displayName: "产品别" },
      { name: "ecName", displayName: "费用项" },
      { name: "promotionDesc", displayName: "活动机制" },    
      { name: "amount", displayName: "申请金额" },
      { name: "activityStartDate", displayName: "明细活动开始日期", type: Enum.FieldType.DATE_TIME },
      { name: "activityEndDate", displayName: "明细活动截止日期", type: Enum.FieldType.DATE_TIME },
      { name: "supplyStartDate", displayName: "明细供货开始日期", type: Enum.FieldType.DATE_TIME },
      { name: "supplyEndDate", displayName: "明细供货截止日期", type: Enum.FieldType.DATE_TIME },
      { name: "revenueTypeName", displayName: "核算类型" },
      { name: "productLineCode", displayName: "产品线编号" },
      { name: "productLineName", displayName: "产品线名称" },
      { name: "paymentMethodName", displayName: "支付方式" },
      { name: "invoiceTypeName", displayName: "发票类型" },
      { name: "vatTypeName", displayName: "税率类型" },
      { name: "remark", displayName: "备注" },
      { name: "formSaDetailId", displayName: "活动明细编号" }
    ];
    SA.exportDealerSaDetails(Number(data?.form?.formId)).then(response => {
      const formSASheet = {
        data: response?.data,
        names: columns,
        sheetName: "活动明细",
        cells: {
        }
      };
      toExcel({
        name: "经销商投资类活动明细信息_" + wdiCore.Date.ToString(new Date()) + ".xlsx",
        sheetProps: [formSASheet]
      });
    });
  };

  return (
    <WdiPanel title={<WdiCardTitle iconType={Enum.IconType.DETAIL_INFO} title='费用明细信息' />} collapsed={true}
      extra={
        <Space>
          <Button type='primary' onClick={(e) => { e.stopPropagation(); exportDetails(); }}>
            导出
          </Button>
          <Button
            hidden={wdiCore.Object.IsNullOrUndefined(data?.form?.relatedFormId)}
            onClick={(e) => { e.stopPropagation(); setModalProps({ ...modalProps, ...{ visible: true } }); }}
            type='primary'>
            变更对比
          </Button>
        </Space>
      }>
      {isLoaded
        && modalProps.visible
        && data?.form?.relatedFormId
        && <FormSaDetailCompareModalApp {...modalProps} onClose={() => { setModalProps({ ...modalProps, ...{ visible: false } }); }} />}
      {isLoaded
        && <DetailGrid {...pageGridSettings} />}
    </WdiPanel>
  );
};
