import React from 'react';
import { Transfer, Tree } from 'antd';

// 根据当前选择过滤字段重新生成和计算过滤值
const findFilterOptions = (filterOptions: any, key: string) => {
  const items: FilterOption[] = [];
  if (filterOptions && filterOptions.length) {
    filterOptions.forEach((item: FilterOption) => {
      if (item.field == key) {
        items.push(item)
      }
    });
  }
  return items;
};

export const getTargetDataSource = (dataSource: TransferItem[], targetKeys: string[]) => {
  const items: TransferItem[] = [];
  targetKeys.forEach((itemKey) => {
    const result = dataSource.find((item) => item.key == itemKey);
    if (result) {
      items.push(Object.assign({}, result));
    }
  });
  return items;
};

export const isChecked = (selectedKeys: string[], eventKey: string) => selectedKeys.indexOf(eventKey) !== -1;

export type TransferItem = {
  key: string;
  title: string;
  isRequired: boolean;
  data?: any;
}

export type FilterOption = {
  title?: string;
  field: string;
  type?: string;
  opt: number | string;
  value: any;
};

type SortableTransfterProps = {
  height?: number;
  dataSource: TransferItem[];
  value?: string[];
  onChange?: (value: string[]) => void;
  // 数据列的默认值信息
  items?: any[];
  onColumnSetting?: (key: string) => void;
}

const SortableTransfter: React.FC<SortableTransfterProps> = (props) => {

  const handleChange = (targetKeys: string[], direction: any, moveKeys: string[]) => {
    if (direction === 'right') {
      const oldTargetkeys = targetKeys.filter((tk) => !moveKeys.includes(tk));
      if (props.onChange) {
        props.onChange([...oldTargetkeys, ...moveKeys]);
      }
    } else {
      if (props.onChange) {
        props.onChange(targetKeys);
      }
    }

  };

  const handleDrop = (info: any) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPosition = info.dropPosition;
    const targetKeys = props.value;
    const newTargetKeys: string[] = [];
    if (dropPosition == -1) {
      newTargetKeys.push(dragKey);
    }
    (targetKeys ? targetKeys : []).forEach((item) => {
      if (dragKey != item) {
        newTargetKeys.push(item);
        if (dropPosition != -1 && dropKey == item) {
          newTargetKeys.push(dragKey);
        }
      }
    });
    if (props.onChange) {
      props.onChange(newTargetKeys);
    }
  };

  const handleTreeNodeClick = (e: any) => {
    e.stopPropagation();
    if (props.onColumnSetting) {
      props.onColumnSetting(e.target.getAttribute('data-key'));
    }
  };

  const titleRender = (node: TransferItem) => {
    if (props.onColumnSetting) {
      return (
        <div key={node.key}>
          <span>
            {node.title}
          </span>
          {(node.data && node.data.length != 0) && <span>[...]</span>}
          <a onClick={handleTreeNodeClick} style={{ "float": "right" }} data-key={node.key}>设置</a>
        </div>
      );
    } else {
      return (
        <div key={node.key}>
          <span>{node.title}</span>
        </div>
      );
    }
  };

  return (
    <Transfer
      dataSource={props.dataSource}
      listStyle={{
        width: 300,
        height: props.height || 200,
      }}
      titles={['隐藏项', '显示项']}
      targetKeys={props.value}
      onChange={handleChange}
      render={(item) => item.title}
    >
      {({ direction, onItemSelect, selectedKeys }) => {
        if (direction === 'right') {
          const dataSource = props.dataSource;
          const targetKeys = props.value;
          let targetDataSource = getTargetDataSource(dataSource, targetKeys ? targetKeys : []);
          if (props.items) {
            targetDataSource = targetDataSource.map((transferItem) => {
              const item = findFilterOptions(props.items, transferItem.key);
              if (item) {
                transferItem.data = item;
              }
              return transferItem;
            });
          }
          return (
            <Tree
              blockNode
              checkable
              checkStrictly
              draggable
              defaultExpandAll
              checkedKeys={selectedKeys}
              treeData={targetDataSource}
              onCheck={(_, { node: { key } }) => {
                onItemSelect(String(key), !isChecked(selectedKeys, String(key)));
              }}
              onSelect={(_, { node: { key } }) => {
                onItemSelect(String(key), !isChecked(selectedKeys, String(key)));
              }}
              onDrop={handleDrop}
              titleRender={titleRender}
            />
          );
        }
        return undefined;
      }}
    </Transfer>
  );
}

export default SortableTransfter;
