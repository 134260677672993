import { MenuDataItem } from '@ant-design/pro-layout';
import {
  HomeOutlined, SettingOutlined, StarOutlined, FormOutlined,
  MoneyCollectOutlined, FileSearchOutlined, UserOutlined,
  TransactionOutlined, AppstoreOutlined, FundOutlined
} from '@ant-design/icons';
import { WdiIcon } from '@/components/WdiControls/WdiIcon';
import { Enum } from '@/enums/system';

export const toLayoutMenu = (menus: API.Menu[] | undefined) => {

  const iconStore: Record<string, any> = {
    HomeOutlined: <HomeOutlined />,
    StarOutlined: <StarOutlined />,
    MoneyCollectOutlined: <MoneyCollectOutlined />,
    FormOutlined: <FormOutlined />,
    FileSearchOutlined: <FileSearchOutlined />,
    UserOutlined: <UserOutlined />,
    TransactionOutlined: <TransactionOutlined />,
    AppstoreOutlined: <AppstoreOutlined />,
    SettingOutlined: <SettingOutlined />,
    FundOutlined: <FundOutlined />,
    DmsSoIcon: <WdiIcon type={Enum.IconType.DMS_SO} />,
    FinancialCenterIcon: <WdiIcon type={Enum.IconType.FINANCIAL_CENTER} />,
    PolicyIcon: <WdiIcon type={Enum.IconType.POLICY} />,
    DsReconciliationIcon: <WdiIcon type={Enum.IconType.DS_RECONCILIATION} />,
    PoolIcon: <WdiIcon type={Enum.IconType.POOL} />,
    InformationCenterIcon: <WdiIcon type={Enum.IconType.INFORMATION_CENTER} />,
  };

  const items: MenuDataItem[] = [];
  menus?.forEach((menu) => {
    let icon = <StarOutlined />
    if (iconStore[menu.icon]) {
      icon = iconStore[menu.icon];
    }
    const item: MenuDataItem = {
      name: menu.name,
      icon: icon,
      locale: false,
      menuID: menu.menuId,
      key: [menu.menuId].join("-"),
    };
    if (menu.url) {
      item.path = menu.url;
      item.component = '.' + menu.url;
    }
    if (menu.childMenus) {
      item.children = menu.childMenus.map((subMenu) => {
        const subItem: MenuDataItem = {
          name: subMenu.menuName,
          locale: false,
          menuID: subMenu.menuId,
          key: [menu.menuId, subMenu.menuId].join("-"),
        };
        if (subMenu.url) {
          subItem.path = subMenu.url;
          subItem.component = '.' + subMenu.url;
        }
        return subItem;
      })
    }
    items.push(item);
  });
  return items;
}

export function getLanguage(lcid?: number | string) {
  if (lcid === 1033 || lcid === '1033') {
    return 'en-US';
  }
  return 'zh-CN';
}
