import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Input, message, Row} from 'antd';
import ProForm, {ProFormInstance} from '@ant-design/pro-form';
import {FileUploadUtility, WdiFileUpload} from '@/components/WdiControls/WdiFileUpload';
import {PageType} from '@/services/basic/pagetype';
import {Enum} from '@/enums/system';
import {ResponseStatus} from '@/utils/request';
import {FormPageProps} from '@/components/WdiBus/FormPage';
import DataStore from '@/components/GridWidget/DataStore';
import {ApplyFooter, ApplyFooterType} from '@/components/WdiBus/ApplyFooter';
import {WdiModalAppResult, WdiCardTitle} from '@/components/WdiControls';
import RebateReimbursementDetailsForApplyPanel from './detail';
import {initApplyPageContext, ViewEntityProps} from './data';
import {WdiPanel} from '@/components/WdiControls/WdiPanel';
import {FormRs} from '@/services/sales/rs';
import {WarningInfoAppProps} from '@/components/WdiBus/PanelForWarning';
import RSWarningInfoApp from "@/pages/contract/RebateInfo/components/WarningInformation";

const {TextArea} = Input;

const getPageResult = (values: any, viewEntity: ViewEntityProps, pageType: API.PageType, statusId: number, dataSource: any[]) => {
  const result: WdiModalAppResult = {code: ResponseStatus.UNKNOWN};

  const {fileInfo, ...entity} = values;
  if (FileUploadUtility.isUploading(fileInfo)) {
    result.message = '附件正在上传中!';
    return result;
  }
  if (FileUploadUtility.isError(fileInfo)) {
    result.message = '附件存在错误文件!';
    return result;
  }
  const req: { form: any, formRs: any, formRsDetails: any[] } = {
    form: {
      statusId: statusId,
      pageTypeId: pageType.pageTypeId,
      pageGroupId: pageType.pageGroupId,
      formTypeId: viewEntity.formTypeId,
      userId: viewEntity.userId,
      positionId: viewEntity.positionId,
      deptId: viewEntity.deptId
    },
    formRs: {},
    formRsDetails: []
  };

  if (viewEntity.formRs) {
    if (viewEntity.relationType === Enum.FormRelationType.REJECTED) {
      // 退回待修改
      req.form.relatedFormId = viewEntity.relatedFormId;
      req.form.relationType = viewEntity.relationType;
    } else {
      // 从草稿打开
      req.form.formId = viewEntity.formId;
    }
  }

  entity.modifiedReason = '新建';

  const info = FileUploadUtility.serialize(fileInfo);
  entity.fileName = info?.names;
  entity.filePath = info?.urls;
  req.formRs = entity;

  if (!dataSource || dataSource.length == 0) {
    result.message = '核销明细不能为空!';
    return result;
  }
  dataSource.forEach((item, itemIndex) => {
    item.rowNumber = itemIndex + 1;
  });
  req.formRsDetails = dataSource;

  for (let i = 0; i < req.formRsDetails.length; i++) {
    const detailRow = req.formRsDetails[i];
    detailRow.formRsDetailId = detailRow.formRsDetailId || 0;
    detailRow.formRsId = detailRow.formRsId || 0;
    detailRow.amount = detailRow.amount ? Number(detailRow.amount) : 0;
  }

  result.data = req;
  result.code = ResponseStatus.SUCCESS;
  return result;
}

const RebateReimbursementApplyApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();

  const [entity, setEntity] = useState<ViewEntityProps>();
  const [pageType, setPageType] = useState<API.PageType>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [warningSettings, setWarningSettings] = useState<WarningInfoAppProps>({visible: false});

  const onDetailsChange = (data: any) => {
    setDataSource(data);
  };

  useEffect(() => {
    (async () => {
      const params = props.data;
      if (params) {
        const result = await initApplyPageContext(params);
        if (result.code !== ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;

        if (viewEntity.formRs) {
          formRef.current?.setFieldsValue({
            bohqId:viewEntity.formRs.bohqId,
            remark: viewEntity.formRs.remark,
            fileInfo: FileUploadUtility.parse(viewEntity.formRs.fileName, viewEntity.formRs.filePath)
          });
        }

        if (viewEntity.pageTypeId) {
          PageType.get(viewEntity.pageTypeId).then((response) => {
            setPageType(response.data);
          });
        }

        if (viewEntity.formRsDetails) {
          setDataSource(DataStore.init(viewEntity.formRsDetails));
        }

        setEntity(viewEntity);
      }
    })();
  }, [props.data]);

  useEffect(() => {
    if (props.setPageTitle && pageType && entity) {
      let title = "合同返利费用核销申请";
      if (entity.formRs) {
        title += '【编辑】'
      }
      props.setPageTitle(title);
    }
  }, [props.setPageTitle, pageType, entity]);

  return (
    <>
      <RSWarningInfoApp {...warningSettings} />
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (
              <ApplyFooter
                setLoading={props.setLoading}
                hiddenButton={[ApplyFooterType.Validate]}
                viewEntity={entity}
                submitBackUrl="/contract/myrebateinfo"
                doSubmit={async (type: number, forceSubmit: boolean) => {
                  if (!entity || !pageType) {
                    return;
                  }
                  const values = await formRef.current?.validateFieldsReturnFormatValue?.();
                  if (values && values.errorFields && values.errorFields.length) {
                    return;
                  }
                  const statusId = type === ApplyFooterType.Draft ? Enum.FormStatus.DRAFT : Enum.FormStatus.AWAITING;
                  const req = getPageResult(values, entity, pageType, statusId, dataSource);
                  if (req.code !== ResponseStatus.SUCCESS) {
                    message.error(req.message);
                    return;
                  }
                  props?.setLoading?.(true);
                  return await FormRs.Buttons.apply({
                    forceSubmit: forceSubmit,
                    ...req.data
                  });
                }}
                showWarning={(result: WdiModalAppResult, submitCallback: any) => {
                  setWarningSettings({
                    visible: true,
                    title: '警示信息',
                    wdiPanelList: result.data,
                    onClose: () => {
                      setWarningSettings({visible: false});
                    },
                    onSave: async () => {
                      return await submitCallback();
                    },
                  });
                }}
                del={async () => {
                  if (!entity || !entity.formId) {
                    return;
                  }
                  return await FormRs.Buttons.del(entity.formId);
                }}
              />
            )
          }
        }}
      >
        <WdiPanel title={<WdiCardTitle title='合同返利核销信息'/>}>
          <Row gutter={[16, 24]}>
            <Form.Item name="bohqId" label="bohqId" hidden>
              <Input/>
            </Form.Item>
            <Col className="gutter-row" span={16}>
              <Form.Item name="remark" label="核销备注">
                <TextArea rows={4}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="fileInfo"
                label="附件"
                valuePropName='fileList'
                getValueFromEvent={(e: any) => {
                  return e.fileList || [];
                }}
              >
                <WdiFileUpload leftTopInfo="请选择文件并上传"/>
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <RebateReimbursementDetailsForApplyPanel
          data={{pageTypeId: entity?.pageTypeId}}
          dataSource={dataSource}
          onChange={onDetailsChange}/>
      </ProForm>
    </>
  );
};
export default RebateReimbursementApplyApp;
