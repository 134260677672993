export namespace StatusEnum {
  export enum FormStatus {
    //模板
    TEMPLATE = -1,
    //草稿
    DRAFT = 0,
    //待审批
    AWAITING = 1,
    //已审批
    APPROVE_COMPLETED = 2,
    //退回待修改
    REJECTED = 3,
    //作废
    SCRAP = 4
  }

  export enum ExportStatus {
    //不需要导出
    EXPORT_NONE = 0,
    //需要导出
    EXPORT_PENDING = 1,
    //导出成功
    EXPORT_SUCCESS = 2,
    //导出失败
    EXPORT_FAILED = 3,
    //待再次导出
    EXPORT_AGAIN = 4
  }

  //1代表未传输，2代表传输成功，3代表传输失败，4代表已取消；
  export enum SyncStatus {
    NONE = 0,
    SYNC_PENDING = 1,
    SYNC_SUCCESS = 2,
    SYNC_FAILED = 3,
    SYNC_CANCEL = 4
  }

  export enum InvoiceStatus {
    // 无发票
    NON_INVOICE = 0,
    // 有发票
    WITH_INVOICE = 1
  }

  export enum RebateInfoStatus {
    // 待结案
    SETTLING = 0,
    // 已结案
    REIMBURSING = 1,
    // 已关闭
    CLOSED = 2,
    // 未关闭
    NOT_CLOSED = 3
  }

  export enum BudgetControlType {
    //1:控制
    CONTROL = 1,
    //2:不控制
    NOT_CONTROL = 2,
    //3:special
    SPECIAL = 3
  }
}
